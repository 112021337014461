import querystring from 'querystring';
import * as t from 'io-ts';
import BigNumber from 'bignumber.js';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import {
  ApiClient as BaseApiClient,
  ApiResponse,
  DateFromISOString,
  DateWithoutTimeFromISOString,
  NumberFromString,
  ErrorMiddleware,
  CodegenEnumOpen,
  CodegenEnumOpenValues,
  CodegenEnumClosed,
  OneOfOpen,
  OneOfOpenValues,
  OneOfOpenEncodedValues,
  OneOfClosed,
  SpeccedReqOptions,
  UnspeccedReqOptions,
  PermissiveSlug as Slug,
  ParcelDimensionsFromString,
} from '@mablemarket/api-client-support'
import { 
  Email,
  Gtin,
  SlugBrand,
  ExcludeUnexpected, 
  BoundedString, 
  makeBoundedStringCodec,
  ParcelDimensions,
  withExactlyOneKey,
  ExactlyOneKey,
} from '@mablemarket/common-lib';

// Like t.array, but can also decode a comma-separated string into an array.
export const StringArrayCodec = <C extends t.Mixed>(codec: C, name?: string) => {
  const arrayCodec = t.array(codec);
  return new t.Type<Array<t.TypeOf<C>>, Array<t.OutputOf<C>>, unknown>(
    `StringArray<${codec.name}>`,
    arrayCodec.is,
    (u, c) => arrayCodec.validate((typeof u === 'string') ? u.split(',') : u, c),
    arrayCodec.encode,
  )
}

// Like t.number, but can also decode strings into numbers.
export const StringNumberCodec = new t.Type<number, number, unknown>(
  'StringNumber',
  t.number.is,
  (u, c) => {
    if (typeof u !== 'string') {
      return t.number.validate(u, c);
    }
    const asNumber = Number(u);
    if (isNaN(asNumber)) { return t.failure(u, c) }
    return t.number.validate(asNumber, c);
  },
  t.number.encode,
);

// Like t.boolean, but can also decode the strings 'true' or 'false'. Any other strings fail to decode.
export const StringBooleanCodec = new t.Type<boolean, boolean, unknown>(
  'StringBoolean',
  t.boolean.is,
  (u, c) => {
    if (typeof u !== 'string') {
      return t.boolean.validate(u, c);
    }
    if (u !== 'true' && u !== 'false') {
      return t.failure(u, c);
    }
    return t.boolean.validate(u === 'true', c);
  },
  t.boolean.encode,
);

// Like t.string, but querystring.unescapes the value when decoding.
export const URLStringCodec = new t.Type<string, string, unknown>(
  'UrlString',
  t.string.is,
  (u, c) => {
    const s = t.string.validate(u, c);
    if ('left' in s) return t.failure(u, c);
    return t.success(querystring.unescape(s.right));
  },
  t.string.encode,
);

export type AiPredefinedPromptType = CodegenEnumOpenValues<'classifyImages' | 'getExtraProductDescriptions'>;
export type EncodedAiPredefinedPromptType = string;
export const AiPredefinedPromptType = CodegenEnumOpen({
  'classifyImages': null,
  'getExtraProductDescriptions': null,
}, 'AiPredefinedPromptType');
export const AiPredefinedPromptTypeValues = ['classifyImages', 'getExtraProductDescriptions'] as const;

export type AiPromptTargetType = CodegenEnumOpenValues<'usFoodsVariantsMissingDescription'>;
export type EncodedAiPromptTargetType = string;
export const AiPromptTargetType = CodegenEnumOpen({
  'usFoodsVariantsMissingDescription': null,
}, 'AiPromptTargetType');
export const AiPromptTargetTypeValues = ['usFoodsVariantsMissingDescription'] as const;

export type BooleanOrUnknown = CodegenEnumOpenValues<'unknown' | 'true' | 'false'>;
export type EncodedBooleanOrUnknown = string;
export const BooleanOrUnknown = CodegenEnumOpen({
  'unknown': null,
  'true': null,
  'false': null,
}, 'BooleanOrUnknown');
export const BooleanOrUnknownValues = ['unknown', 'true', 'false'] as const;

export type PartnerPriceReviewInfo = {
  'pricingRegionInfo': Array<{
    'regionId': string,
    /** the factor_discount value associated with the pricing_tier with the sign flipped */
    'markup'?: BigNumber,
    'regionPartnerIdentifier': string,
    'reviewed': boolean,
  }>,
  'variantInfo': Array<{
    'id': number,
    'productId': number,
    'name': string,
    'wholesalePrice': BigNumber,
    'reviewed': boolean,
  }>,
  /** The start date for the prices we are currently reviewing */
  'startAt'?: Date,
  /** If there are future prices for this seller and the markups have not changed we will add variants to the current and future eras when they are reviewed. This will be true if reviewed variants can be added to all eras. */
  'reviewingCurrentAndFutureVariants'?: boolean,
};
export type EncodedPartnerPriceReviewInfo = {
  'pricingRegionInfo': Array<{
    'regionId': string,
    'markup'?: string,
    'regionPartnerIdentifier': string,
    'reviewed': boolean,
  }>,
  'variantInfo': Array<{
    'id': number,
    'productId': number,
    'name': string,
    'wholesalePrice': string,
    'reviewed': boolean,
  }>,
  'startAt'?: string,
  'reviewingCurrentAndFutureVariants'?: boolean,
};
export const PartnerPriceReviewInfo: t.Type<PartnerPriceReviewInfo, EncodedPartnerPriceReviewInfo> = t.recursion('PartnerPriceReviewInfo', () => (
  t.exact(t.intersection([t.type({
    'pricingRegionInfo': t.array(t.exact(t.intersection([t.type({
      'regionId': t.string,
      'regionPartnerIdentifier': t.string,
      'reviewed': t.boolean,
    }), t.partial({
      'markup': NumberFromString,
    })]), 'PartnerPriceReviewInfoItem')),
    'variantInfo': t.array(t.exact(t.intersection([t.type({
      'id': t.number,
      'productId': t.number,
      'name': t.string,
      'wholesalePrice': NumberFromString,
      'reviewed': t.boolean,
    }), t.partial({
      
    })]), 'PartnerPriceReviewInfoItem')),
  }), t.partial({
    'startAt': DateFromISOString,
    'reviewingCurrentAndFutureVariants': t.boolean,
  })]), 'PartnerPriceReviewInfo')
));


export type SortOrder = 'asc' | 'desc';
export type EncodedSortOrder = string;
export const SortOrder = CodegenEnumClosed({
  'asc': null,
  'desc': null,
}, 'SortOrder');
export const SortOrderValues = ['asc', 'desc'] as const;

export type OrderModification = {
  'productVariantId': number,
  'quantity': number,
};
export type EncodedOrderModification = {
  'productVariantId': number,
  'quantity': number,
};
export const OrderModification: t.Type<OrderModification, EncodedOrderModification> = t.recursion('OrderModification', () => (
  t.exact(t.intersection([t.type({
    'productVariantId': t.number,
    'quantity': t.number,
  }), t.partial({
    
  })]), 'OrderModification')
));


export type StorePresenceType = CodegenEnumOpenValues<'physical' | 'online' | 'hybrid' | 'other'>;
export type EncodedStorePresenceType = string;
export const StorePresenceType = CodegenEnumOpen({
  'physical': null,
  'online': null,
  'hybrid': null,
  'other': null,
}, 'StorePresenceType');
export const StorePresenceTypeValues = ['physical', 'online', 'hybrid', 'other'] as const;

export type EdiConnectionType = CodegenEnumOpenValues<'mableSftp' | 'ftp' | 'ftps' | 'as2'>;
export type EncodedEdiConnectionType = string;
export const EdiConnectionType = CodegenEnumOpen({
  'mableSftp': null,
  'ftp': null,
  'ftps': null,
  'as2': null,
}, 'EdiConnectionType');
export const EdiConnectionTypeValues = ['mableSftp', 'ftp', 'ftps', 'as2'] as const;

/** How the invoice that is uploaded to the SFTP server should be formatted. */
export type EdiInvoiceFormat = CodegenEnumOpenValues<'edi-alltown' | 'edi-nouria' | 'edi-doordash' | 'pinnacle' | 'mclaneX12' | 'usfoodsX12' | 'hebX12'>;
export type EncodedEdiInvoiceFormat = string;
export const EdiInvoiceFormat = CodegenEnumOpen({
  'edi-alltown': null,
  'edi-nouria': null,
  'edi-doordash': null,
  'pinnacle': null,
  'mclaneX12': null,
  'usfoodsX12': null,
  'hebX12': null,
}, 'EdiInvoiceFormat');
export const EdiInvoiceFormatValues = ['edi-alltown', 'edi-nouria', 'edi-doordash', 'pinnacle', 'mclaneX12', 'usfoodsX12', 'hebX12'] as const;

/** The format used by the retailer for EDI purchase orders */
export type EdiPurchaseOrderFormat = CodegenEnumOpenValues<'mclane' | 'mclaneEnterprise' | 'mableJson' | 'doordash' | 'mclaneX12' | 'usfoodsX12' | 'hebX12'>;
export type EncodedEdiPurchaseOrderFormat = string;
export const EdiPurchaseOrderFormat = CodegenEnumOpen({
  'mclane': null,
  'mclaneEnterprise': null,
  'mableJson': null,
  'doordash': null,
  'mclaneX12': null,
  'usfoodsX12': null,
  'hebX12': null,
}, 'EdiPurchaseOrderFormat');
export const EdiPurchaseOrderFormatValues = ['mclane', 'mclaneEnterprise', 'mableJson', 'doordash', 'mclaneX12', 'usfoodsX12', 'hebX12'] as const;

/** The format used by the retailer for EDI credit requests */
export type EdiCreditRequestFormat = CodegenEnumOpenValues<'nouriaCsv' | 'mableJson' | 'doordash' | 'mclaneX12' | 'usfoodsX12' | 'hebX12'>;
export type EncodedEdiCreditRequestFormat = string;
export const EdiCreditRequestFormat = CodegenEnumOpen({
  'nouriaCsv': null,
  'mableJson': null,
  'doordash': null,
  'mclaneX12': null,
  'usfoodsX12': null,
  'hebX12': null,
}, 'EdiCreditRequestFormat');
export const EdiCreditRequestFormatValues = ['nouriaCsv', 'mableJson', 'doordash', 'mclaneX12', 'usfoodsX12', 'hebX12'] as const;

/** The format used by the retailer for EDI pricebook exports */
export type EdiPricebookFormat = CodegenEnumOpenValues<'pdi' | 'doordash' | 'mableCsv' | 'mclaneProductAuthorizationRequest'>;
export type EncodedEdiPricebookFormat = string;
export const EdiPricebookFormat = CodegenEnumOpen({
  'pdi': null,
  'doordash': null,
  'mableCsv': null,
  'mclaneProductAuthorizationRequest': null,
}, 'EdiPricebookFormat');
export const EdiPricebookFormatValues = ['pdi', 'doordash', 'mableCsv', 'mclaneProductAuthorizationRequest'] as const;

/** How the order confirmation that is uploaded to the SFTP server should be formatted. */
export type OrderConfirmationSftpFormat = CodegenEnumOpenValues<'myMarket'>;
export type EncodedOrderConfirmationSftpFormat = string;
export const OrderConfirmationSftpFormat = CodegenEnumOpen({
  'myMarket': null,
}, 'OrderConfirmationSftpFormat');
export const OrderConfirmationSftpFormatValues = ['myMarket'] as const;

export type BaseBuyerVerification = {
  /** Is the store only open during a specific period of the year */
  'isSeasonal'?: boolean,
  'facebookUrl'?: string,
  'instagramUrl'?: string,
  'websiteUrl'?: string,
};
export type EncodedBaseBuyerVerification = {
  'isSeasonal'?: boolean,
  'facebookUrl'?: string,
  'instagramUrl'?: string,
  'websiteUrl'?: string,
};
export const BaseBuyerVerification: t.Type<BaseBuyerVerification, EncodedBaseBuyerVerification> = t.recursion('BaseBuyerVerification', () => (
  t.exact(t.intersection([t.type({
    
  }), t.partial({
    'isSeasonal': t.boolean,
    'facebookUrl': t.string,
    'instagramUrl': t.string,
    'websiteUrl': t.string,
  })]), 'BaseBuyerVerification')
));


export type BuyerVerificationResponse = BaseBuyerVerification & {
  'distributorRelationships'?: string,
  'locationsRange'?: string,
  /** The S3 address of the resale certificate the buyer has uploaded */
  'resaleCertificateS3Key'?: string,
  'resaleCertificateS3Url'?: string,
  'retailLocationAddress'?: Address,
  'shipToAddress'?: Address,
  'storeCategory'?: string,
  /** The interface the store offers to customers */
  'storePresence'?: StorePresenceType,
};
export type EncodedBuyerVerificationResponse = EncodedBaseBuyerVerification & {
  'distributorRelationships'?: string,
  'locationsRange'?: string,
  'resaleCertificateS3Key'?: string,
  'resaleCertificateS3Url'?: string,
  'retailLocationAddress'?: EncodedAddress,
  'shipToAddress'?: EncodedAddress,
  'storeCategory'?: string,
  'storePresence'?: EncodedStorePresenceType,
};
export const BuyerVerificationResponse: t.Type<BuyerVerificationResponse, EncodedBuyerVerificationResponse> = t.recursion('BuyerVerificationResponse', () => (
  t.intersection([
    BaseBuyerVerification,
    t.exact(t.intersection([t.type({
      
    }), t.partial({
      'distributorRelationships': t.string,
      'locationsRange': t.string,
      'resaleCertificateS3Key': t.string,
      'resaleCertificateS3Url': t.string,
      'retailLocationAddress': Address,
      'shipToAddress': Address,
      'storeCategory': t.string,
      'storePresence': StorePresenceType,
    })]), 'BuyerVerificationResponse')
  ], 'BuyerVerificationResponse')
));


export type BuyerVerificationInput = BaseBuyerVerification & {
  'distributorRelationships'?: string,
  'locationsRange': string,
  /** The S3 address of the resale certificate the buyer has uploaded */
  'resaleCertificateS3Key': string,
  'retailLocationAddress'?: AddressInput,
  'storeCategory': string,
  /** The interface the store offers to customers */
  'storePresence': StorePresenceType,
};
export type EncodedBuyerVerificationInput = EncodedBaseBuyerVerification & {
  'distributorRelationships'?: string,
  'locationsRange': string,
  'resaleCertificateS3Key': string,
  'retailLocationAddress'?: EncodedAddressInput,
  'storeCategory': string,
  'storePresence': EncodedStorePresenceType,
};
export const BuyerVerificationInput: t.Type<BuyerVerificationInput, EncodedBuyerVerificationInput> = t.recursion('BuyerVerificationInput', () => (
  t.intersection([
    BaseBuyerVerification,
    t.exact(t.intersection([t.type({
      'locationsRange': t.string,
      'resaleCertificateS3Key': t.string,
      'storeCategory': t.string,
      'storePresence': StorePresenceType,
    }), t.partial({
      'distributorRelationships': t.string,
      'retailLocationAddress': AddressInput,
    })]), 'BuyerVerificationInput')
  ], 'BuyerVerificationInput')
));


export type Flag = CodegenEnumOpenValues<'allowSampleRequestWithoutOrder' | 'appEnableMessaging' | 'automaticPOs' | 'billingManagementFeature' | 'brandReferralFeature' | 'brandReferrals' | 'buyerManagedOrderGuide' | 'buyerMessagingFeature' | 'buyerPaymentPlan' | 'buyerSampleRequestsManagedByMable' | 'buyerSamplesFeature' | 'cartFeature' | 'checkoutWithFailingPaymentMethod' | 'deliverOrderConfirmationsWithSftp' | 'exemptFromCreditCardCheckoutRequirement' | 'exemptFromSellerOrderMinimums' | 'exposeInvoiceCsv' | 'externalPurchaseOrderNumberAtCheckout' | 'fastShippingRates' | 'fullSellerMediaDisplayFeature' | 'includeCsvInSellerPoEmail' | 'marginPreviewFeature' | 'marketplaceFeature' | 'mclaneTimelyCollectionDashboardDemo' | 'outForDeliveryEmail' | 'personalizedPricesOnly' | 'productsReviewed' | 'profileReviewed' | 'promoteUsFoodsProgram' | 'reorderFeature' | 'sellerDeliverPosWithSftp' | 'sellerEmailCampaignsEnabled' | 'sellerMediaDisplayFeature' | 'sellerOrderDataExport' | 'sellerShippingProgramApproved' | 'sellerShippingProgramJoined' | 'sellerShopifySync' | 'sellerWarehouseFulfillable' | 'sendBuyerInvoiceWhenShipmentOutForDelivery' | 'switchPricingRegion' | 'suppressInvoiceRelatedEmail' | 'suppressOrderCancellationEmail' | 'suppressOrderConfirmationEmail' | 'suppressOrderConfirmationEmailForEdiOrders' | 'suppressShippingNotificationEmail' | 'transactionCreation' | 'unrestrictedShipping' | 'uploadEdiPricebookFilesNightly' | 'useAttachmentsForPurchaseOrderEmail' | 'verboseShippingFormLogging'>;
export type EncodedFlag = string;
export const Flag = CodegenEnumOpen({
  'allowSampleRequestWithoutOrder': null,
  'appEnableMessaging': null,
  'automaticPOs': null,
  'billingManagementFeature': null,
  'brandReferralFeature': null,
  'brandReferrals': null,
  'buyerManagedOrderGuide': null,
  'buyerMessagingFeature': null,
  'buyerPaymentPlan': null,
  'buyerSampleRequestsManagedByMable': null,
  'buyerSamplesFeature': null,
  'cartFeature': null,
  'checkoutWithFailingPaymentMethod': null,
  'deliverOrderConfirmationsWithSftp': null,
  'exemptFromCreditCardCheckoutRequirement': null,
  'exemptFromSellerOrderMinimums': null,
  'exposeInvoiceCsv': null,
  'externalPurchaseOrderNumberAtCheckout': null,
  'fastShippingRates': null,
  'fullSellerMediaDisplayFeature': null,
  'includeCsvInSellerPoEmail': null,
  'marginPreviewFeature': null,
  'marketplaceFeature': null,
  'mclaneTimelyCollectionDashboardDemo': null,
  'outForDeliveryEmail': null,
  'personalizedPricesOnly': null,
  'productsReviewed': null,
  'profileReviewed': null,
  'promoteUsFoodsProgram': null,
  'reorderFeature': null,
  'sellerDeliverPosWithSftp': null,
  'sellerEmailCampaignsEnabled': null,
  'sellerMediaDisplayFeature': null,
  'sellerOrderDataExport': null,
  'sellerShippingProgramApproved': null,
  'sellerShippingProgramJoined': null,
  'sellerShopifySync': null,
  'sellerWarehouseFulfillable': null,
  'sendBuyerInvoiceWhenShipmentOutForDelivery': null,
  'switchPricingRegion': null,
  'suppressInvoiceRelatedEmail': null,
  'suppressOrderCancellationEmail': null,
  'suppressOrderConfirmationEmail': null,
  'suppressOrderConfirmationEmailForEdiOrders': null,
  'suppressShippingNotificationEmail': null,
  'transactionCreation': null,
  'unrestrictedShipping': null,
  'uploadEdiPricebookFilesNightly': null,
  'useAttachmentsForPurchaseOrderEmail': null,
  'verboseShippingFormLogging': null,
}, 'Flag');
export const FlagValues = ['allowSampleRequestWithoutOrder', 'appEnableMessaging', 'automaticPOs', 'billingManagementFeature', 'brandReferralFeature', 'brandReferrals', 'buyerManagedOrderGuide', 'buyerMessagingFeature', 'buyerPaymentPlan', 'buyerSampleRequestsManagedByMable', 'buyerSamplesFeature', 'cartFeature', 'checkoutWithFailingPaymentMethod', 'deliverOrderConfirmationsWithSftp', 'exemptFromCreditCardCheckoutRequirement', 'exemptFromSellerOrderMinimums', 'exposeInvoiceCsv', 'externalPurchaseOrderNumberAtCheckout', 'fastShippingRates', 'fullSellerMediaDisplayFeature', 'includeCsvInSellerPoEmail', 'marginPreviewFeature', 'marketplaceFeature', 'mclaneTimelyCollectionDashboardDemo', 'outForDeliveryEmail', 'personalizedPricesOnly', 'productsReviewed', 'profileReviewed', 'promoteUsFoodsProgram', 'reorderFeature', 'sellerDeliverPosWithSftp', 'sellerEmailCampaignsEnabled', 'sellerMediaDisplayFeature', 'sellerOrderDataExport', 'sellerShippingProgramApproved', 'sellerShippingProgramJoined', 'sellerShopifySync', 'sellerWarehouseFulfillable', 'sendBuyerInvoiceWhenShipmentOutForDelivery', 'switchPricingRegion', 'suppressInvoiceRelatedEmail', 'suppressOrderCancellationEmail', 'suppressOrderConfirmationEmail', 'suppressOrderConfirmationEmailForEdiOrders', 'suppressShippingNotificationEmail', 'transactionCreation', 'unrestrictedShipping', 'uploadEdiPricebookFilesNightly', 'useAttachmentsForPurchaseOrderEmail', 'verboseShippingFormLogging'] as const;

export type AccountFlagDefault = {
  'name': Flag,
  'defaultValue': boolean,
};
export type EncodedAccountFlagDefault = {
  'name': EncodedFlag,
  'defaultValue': boolean,
};
export const AccountFlagDefault: t.Type<AccountFlagDefault, EncodedAccountFlagDefault> = t.recursion('AccountFlagDefault', () => (
  t.exact(t.intersection([t.type({
    'name': Flag,
    'defaultValue': t.boolean,
  }), t.partial({
    
  })]), 'AccountFlagDefault')
));


export type AccountFlagStats = {
  'name': Flag,
  'detail': string,
  'defaultValue': boolean,
  'assignedTrue': number,
  'assignedFalse': number,
  'overrideTrue'?: number,
  'overrideFalse'?: number,
};
export type EncodedAccountFlagStats = {
  'name': EncodedFlag,
  'detail': string,
  'defaultValue': boolean,
  'assignedTrue': number,
  'assignedFalse': number,
  'overrideTrue'?: number,
  'overrideFalse'?: number,
};
export const AccountFlagStats: t.Type<AccountFlagStats, EncodedAccountFlagStats> = t.recursion('AccountFlagStats', () => (
  t.exact(t.intersection([t.type({
    'name': Flag,
    'detail': t.string,
    'defaultValue': t.boolean,
    'assignedTrue': t.number,
    'assignedFalse': t.number,
  }), t.partial({
    'overrideTrue': t.number,
    'overrideFalse': t.number,
  })]), 'AccountFlagStats')
));


export type AccountRelationship = {
  /** The types of relationships that exist between this buyer and seller. */
  'relationships'?: Array<string>,
  /** Whether orders between this buyer and seller should have commission applied. If undefined, PO's won't send automatically. */
  'commissioned'?: boolean,
  /** Custom commission amount for this relationship */
  'commissionMultiplier'?: null | BigNumber,
  /** Custom order minimum for this relationship */
  'orderMinimum'?: null | BigNumber,
  /** Whether orders this buyer and seller handle payment outside Mable. If undefined, PO's won't send automatically. */
  'billToBuyer'?: boolean,
  /** Emails to CC when sending a PO. */
  'poCc'?: Array<string>,
  /** If true, don't sent any automatic purchase orders for this relationship until another PO has been sent manually. */
  'disableAutomaticPoUntilNextPo'?: boolean,
  /** If true, automatically route purchase orders for this relationship to the seller, even if the seller has products in Mable's Warehouse. */
  'automaticallyRouteFulfillmentToSeller'?: null | boolean,
  'sellerNote'?: null | string,
  /** Custom free shipping minimum for this relationship. If seller isn't in Free Shipping Program, must also set freeShippingOverride */
  'freeShippingMinimum'?: null | BigNumber,
  /** Overrides the state of free shipping for this relationship. If the seller is in the Free Shipping Program, set this to `off` to disable it. If the seller is NOT in the Free Shipping Program you can turn this on to enable for just this relationship. Most likely paired with a custom sellerShippingPaymentMultiplier
   */
  'freeShippingOverride'?: FreeShippingOverride,
  /** Custom max % of PO buyer will pay for shipping */
  'buyerShippingCapMultiplier'?: null | BigNumber,
  /** Custom % of shipping total seller will pay on orders that meet the Free Shipping minimum */
  'sellerShippingPaymentMultiplier'?: null | BigNumber,
  /** Custom max amount mable will pay for shipping as a % of PO total */
  'mableShippingMaxPaymentMultiplier'?: null | BigNumber,
  /** Custom share of Cap Guarantee that seller will pay for shipping amounts over the buyerShippingCapMultiplier */
  'sellerShippingCapPaymentMultiplier'?: null | BigNumber,
  /** Specifies that the buyer has agreed to cover all shipping costs for orders from this seller */
  'buyerCoversShipping'?: null | boolean,
};
export type EncodedAccountRelationship = {
  'relationships'?: Array<string>,
  'commissioned'?: boolean,
  'commissionMultiplier'?: null | string,
  'orderMinimum'?: null | string,
  'billToBuyer'?: boolean,
  'poCc'?: Array<string>,
  'disableAutomaticPoUntilNextPo'?: boolean,
  'automaticallyRouteFulfillmentToSeller'?: null | boolean,
  'sellerNote'?: null | string,
  'freeShippingMinimum'?: null | string,
  'freeShippingOverride'?: EncodedFreeShippingOverride,
  'buyerShippingCapMultiplier'?: null | string,
  'sellerShippingPaymentMultiplier'?: null | string,
  'mableShippingMaxPaymentMultiplier'?: null | string,
  'sellerShippingCapPaymentMultiplier'?: null | string,
  'buyerCoversShipping'?: null | boolean,
};
export const AccountRelationship: t.Type<AccountRelationship, EncodedAccountRelationship> = t.recursion('AccountRelationship', () => (
  t.exact(t.intersection([t.type({
    
  }), t.partial({
    'relationships': t.array(t.string),
    'commissioned': t.boolean,
    'commissionMultiplier': t.union([t.null, NumberFromString]),
    'orderMinimum': t.union([t.null, NumberFromString]),
    'billToBuyer': t.boolean,
    'poCc': t.array(t.string),
    'disableAutomaticPoUntilNextPo': t.boolean,
    'automaticallyRouteFulfillmentToSeller': t.union([t.null, t.boolean]),
    'sellerNote': t.union([t.null, t.string]),
    'freeShippingMinimum': t.union([t.null, NumberFromString]),
    'freeShippingOverride': FreeShippingOverride,
    'buyerShippingCapMultiplier': t.union([t.null, NumberFromString]),
    'sellerShippingPaymentMultiplier': t.union([t.null, NumberFromString]),
    'mableShippingMaxPaymentMultiplier': t.union([t.null, NumberFromString]),
    'sellerShippingCapPaymentMultiplier': t.union([t.null, NumberFromString]),
    'buyerCoversShipping': t.union([t.null, t.boolean]),
  })]), 'AccountRelationship')
));


export type AdminReportParameterDefinition = OneOfOpenValues<AdminReportEnumParameterDefinition | AdminReportOmnisearchParameterDefinition | AdminReportTextParameterDefinition | AdminReportBooleanParameterDefinition>;
export type EncodedAdminReportParameterDefinition = OneOfOpenEncodedValues<EncodedAdminReportEnumParameterDefinition | EncodedAdminReportOmnisearchParameterDefinition | EncodedAdminReportTextParameterDefinition | EncodedAdminReportBooleanParameterDefinition>;
export const AdminReportParameterDefinition: t.Type<AdminReportParameterDefinition, EncodedAdminReportParameterDefinition> = t.recursion('AdminReportParameterDefinition', () => (
  OneOfOpen([AdminReportEnumParameterDefinition, AdminReportOmnisearchParameterDefinition, AdminReportTextParameterDefinition, AdminReportBooleanParameterDefinition])
));


export type AdminReportEnumParameterDefinition = {
  'id': string,
  'type': 'enum',
  'values': Array<string>,
  'description'?: string,
};
export type EncodedAdminReportEnumParameterDefinition = {
  'id': string,
  'type': string,
  'values': Array<string>,
  'description'?: string,
};
export const AdminReportEnumParameterDefinition: t.Type<AdminReportEnumParameterDefinition, EncodedAdminReportEnumParameterDefinition> = t.recursion('AdminReportEnumParameterDefinition', () => (
  t.exact(t.intersection([t.type({
    'id': t.string,
    'type': CodegenEnumClosed({
      'enum': null,
    }, 'AdminReportEnumParameterDefinition'),
    'values': t.array(t.string),
  }), t.partial({
    'description': t.string,
  })]), 'AdminReportEnumParameterDefinition')
));


export type AdminReportTextParameterDefinition = {
  'id': string,
  'type': 'text',
  'description'?: string,
};
export type EncodedAdminReportTextParameterDefinition = {
  'id': string,
  'type': string,
  'description'?: string,
};
export const AdminReportTextParameterDefinition: t.Type<AdminReportTextParameterDefinition, EncodedAdminReportTextParameterDefinition> = t.recursion('AdminReportTextParameterDefinition', () => (
  t.exact(t.intersection([t.type({
    'id': t.string,
    'type': CodegenEnumClosed({
      'text': null,
    }, 'AdminReportTextParameterDefinition'),
  }), t.partial({
    'description': t.string,
  })]), 'AdminReportTextParameterDefinition')
));


export type AdminReportOmnisearchParameterDefinition = {
  'id': string,
  'type': 'omnisearch',
  /** An omnisearch prefix (for example, "s") denoting a parameter that can be filled in with an omnisearchable entity */
  'omnisearchType': string,
  'description'?: string,
};
export type EncodedAdminReportOmnisearchParameterDefinition = {
  'id': string,
  'type': string,
  'omnisearchType': string,
  'description'?: string,
};
export const AdminReportOmnisearchParameterDefinition: t.Type<AdminReportOmnisearchParameterDefinition, EncodedAdminReportOmnisearchParameterDefinition> = t.recursion('AdminReportOmnisearchParameterDefinition', () => (
  t.exact(t.intersection([t.type({
    'id': t.string,
    'type': CodegenEnumClosed({
      'omnisearch': null,
    }, 'AdminReportOmnisearchParameterDefinition'),
    'omnisearchType': t.string,
  }), t.partial({
    'description': t.string,
  })]), 'AdminReportOmnisearchParameterDefinition')
));


export type AdminReportBooleanParameterDefinition = {
  'id': string,
  'type': 'boolean',
  'description'?: string,
};
export type EncodedAdminReportBooleanParameterDefinition = {
  'id': string,
  'type': string,
  'description'?: string,
};
export const AdminReportBooleanParameterDefinition: t.Type<AdminReportBooleanParameterDefinition, EncodedAdminReportBooleanParameterDefinition> = t.recursion('AdminReportBooleanParameterDefinition', () => (
  t.exact(t.intersection([t.type({
    'id': t.string,
    'type': CodegenEnumClosed({
      'boolean': null,
    }, 'AdminReportBooleanParameterDefinition'),
  }), t.partial({
    'description': t.string,
  })]), 'AdminReportBooleanParameterDefinition')
));


export type AdminReportDefinition = {
  'id': string,
  'name': string,
  'description': string,
  'parameters': Array<AdminReportParameterDefinition>,
};
export type EncodedAdminReportDefinition = {
  'id': string,
  'name': string,
  'description': string,
  'parameters': Array<EncodedAdminReportParameterDefinition>,
};
export const AdminReportDefinition: t.Type<AdminReportDefinition, EncodedAdminReportDefinition> = t.recursion('AdminReportDefinition', () => (
  t.exact(t.intersection([t.type({
    'id': t.string,
    'name': t.string,
    'description': t.string,
    'parameters': t.array(AdminReportParameterDefinition),
  }), t.partial({
    
  })]), 'AdminReportDefinition')
));


export type AdminReportResultType = CodegenEnumOpenValues<'display' | 'csv'>;
export type EncodedAdminReportResultType = string;
export const AdminReportResultType = CodegenEnumOpen({
  'display': null,
  'csv': null,
}, 'AdminReportResultType');
export const AdminReportResultTypeValues = ['display', 'csv'] as const;

export type AdminReportColumn = {
  'id': string,
  'name': string,
  'description'?: string,
  'forResultTypes'?: Array<AdminReportResultType>,
};
export type EncodedAdminReportColumn = {
  'id': string,
  'name': string,
  'description'?: string,
  'forResultTypes'?: Array<EncodedAdminReportResultType>,
};
export const AdminReportColumn: t.Type<AdminReportColumn, EncodedAdminReportColumn> = t.recursion('AdminReportColumn', () => (
  t.exact(t.intersection([t.type({
    'id': t.string,
    'name': t.string,
  }), t.partial({
    'description': t.string,
    'forResultTypes': t.array(AdminReportResultType),
  })]), 'AdminReportColumn')
));


export type AdminReport = AdminReportDisplay | AdminReportCsv;
export type EncodedAdminReport = EncodedAdminReportDisplay | EncodedAdminReportCsv;
export const AdminReport: t.Type<AdminReport, EncodedAdminReport> = t.recursion('AdminReport', () => (
  OneOfClosed([AdminReportDisplay, AdminReportCsv])
));


export type AdminReportDisplay = {
  /** The format of the AdminReport */
  'resultType': 'display',
  'columns': Array<AdminReportColumn>,
  'rows': Array<Array<string>>,
};
export type EncodedAdminReportDisplay = {
  'resultType': string,
  'columns': Array<EncodedAdminReportColumn>,
  'rows': Array<Array<string>>,
};
export const AdminReportDisplay: t.Type<AdminReportDisplay, EncodedAdminReportDisplay> = t.recursion('AdminReportDisplay', () => (
  t.exact(t.intersection([t.type({
    'resultType': CodegenEnumClosed({
      'display': null,
    }, 'AdminReportDisplay'),
    'columns': t.array(AdminReportColumn),
    'rows': t.array(t.array(t.string)),
  }), t.partial({
    
  })]), 'AdminReportDisplay')
));


export type AdminReportCsv = {
  /** The format of the AdminReport */
  'resultType': 'csv',
  'csvString': string,
};
export type EncodedAdminReportCsv = {
  'resultType': string,
  'csvString': string,
};
export const AdminReportCsv: t.Type<AdminReportCsv, EncodedAdminReportCsv> = t.recursion('AdminReportCsv', () => (
  t.exact(t.intersection([t.type({
    'resultType': CodegenEnumClosed({
      'csv': null,
    }, 'AdminReportCsv'),
    'csvString': t.string,
  }), t.partial({
    
  })]), 'AdminReportCsv')
));


/** An object that will work for display */
export type AdminAction = {
  'text'?: string,
  'url'?: string,
  /** The ID of the main entity returned in this search */
  'id'?: string,
  /** The command that returned this result. Useful if you Omnisearched with multiple commands. */
  'command'?: string,
};
export type EncodedAdminAction = {
  'text'?: string,
  'url'?: string,
  'id'?: string,
  'command'?: string,
};
export const AdminAction: t.Type<AdminAction, EncodedAdminAction> = t.recursion('AdminAction', () => (
  t.exact(t.intersection([t.type({
    
  }), t.partial({
    'text': t.string,
    'url': t.string,
    'id': t.string,
    'command': t.string,
  })]), 'AdminAction')
));


export type OmnisearchResult = {
  'adminAction': AdminAction,
  'object'?: OmnisearchResultObject,
};
export type EncodedOmnisearchResult = {
  'adminAction': EncodedAdminAction,
  'object'?: EncodedOmnisearchResultObject,
};
export const OmnisearchResult: t.Type<OmnisearchResult, EncodedOmnisearchResult> = t.recursion('OmnisearchResult', () => (
  t.exact(t.intersection([t.type({
    'adminAction': AdminAction,
  }), t.partial({
    'object': OmnisearchResultObject,
  })]), 'OmnisearchResult')
));


export type OmnisearchResultObject = OmnisearchResultObjectBuyer | OmnisearchResultObjectAccount;
export type EncodedOmnisearchResultObject = EncodedOmnisearchResultObjectBuyer | EncodedOmnisearchResultObjectAccount;
export const OmnisearchResultObject: t.Type<OmnisearchResultObject, EncodedOmnisearchResultObject> = t.recursion('OmnisearchResultObject', () => (
  OneOfClosed([OmnisearchResultObjectBuyer, OmnisearchResultObjectAccount])
));


export type OmnisearchResultObjectBuyer = {
  'buyer': {
    'id': number,
    'name': string,
    'accountId': number,
    'accountName'?: string,
  },
  'omnisearchResultType': 'buyer',
};
export type EncodedOmnisearchResultObjectBuyer = {
  'buyer': {
    'id': number,
    'name': string,
    'accountId': number,
    'accountName'?: string,
  },
  'omnisearchResultType': string,
};
export const OmnisearchResultObjectBuyer: t.Type<OmnisearchResultObjectBuyer, EncodedOmnisearchResultObjectBuyer> = t.recursion('OmnisearchResultObjectBuyer', () => (
  t.exact(t.intersection([t.type({
    'buyer': t.exact(t.intersection([t.type({
      'id': t.number,
      'name': t.string,
      'accountId': t.number,
    }), t.partial({
      'accountName': t.string,
    })]), 'OmnisearchResultObjectBuyer'),
    'omnisearchResultType': CodegenEnumClosed({
      'buyer': null,
    }, 'OmnisearchResultObjectBuyer'),
  }), t.partial({
    
  })]), 'OmnisearchResultObjectBuyer')
));


export type OmnisearchResultObjectAccount = {
  'account': AccountAdminDisplayInfo,
  'omnisearchResultType': 'account',
};
export type EncodedOmnisearchResultObjectAccount = {
  'account': EncodedAccountAdminDisplayInfo,
  'omnisearchResultType': string,
};
export const OmnisearchResultObjectAccount: t.Type<OmnisearchResultObjectAccount, EncodedOmnisearchResultObjectAccount> = t.recursion('OmnisearchResultObjectAccount', () => (
  t.exact(t.intersection([t.type({
    'account': AccountAdminDisplayInfo,
    'omnisearchResultType': CodegenEnumClosed({
      'account': null,
    }, 'OmnisearchResultObjectAccount'),
  }), t.partial({
    
  })]), 'OmnisearchResultObjectAccount')
));


/** Represents a physical address. */
export type AddressInput = {
  /** Street number & name */
  'line1': string,
  /** e.g. Company name, floor number, unit */
  'line2'?: string,
  /** The city or town of the address. */
  'locality': string,
  /** In the US, the State abbreviation. A civil entity within the address's country. */
  'administrativeAreaLevel1': string,
  /** In the US, this is the county. A civil entity within the address's `administrativeAreaLevel1`. */
  'administrativeAreaLevel2'?: string,
  /** The address's postal code. */
  'postalCode': string,
  /** The address's country in ISO 3166-1-alpha-2 format. e.g. 'US'. For all values see https://developer.squareup.com/docs/api/connect/v2#navsection-connectapibasics */
  'country'?: string,
  /** Optional first name when it's representing recipient. */
  'firstName'?: string,
  /** Optional last name when it's representing recipient. */
  'lastName'?: string,
  /** Optional organization name when it's representing recipient. */
  'organization'?: string,
  /** Optional delivery instructions specific to this address. */
  'deliveryNotes'?: string,
  /** Optional address-specific phone number, generally used for deliveries. */
  'phone'?: string,
  /** This address has been flagged as a Bill To address for its account. */
  'billTo'?: boolean,
  /** This address has been flagged as a Ship To address for its account. */
  'shipTo'?: boolean,
  /** This address has been flagged as a Ship From address for its account. */
  'shipFrom'?: boolean,
  'easypostId'?: string,
  'deliverability'?: AddressDeliverability,
  /** This address has been flagged as a retail location. */
  'isRetailLocation'?: boolean,
};
export type EncodedAddressInput = {
  'line1': string,
  'line2'?: string,
  'locality': string,
  'administrativeAreaLevel1': string,
  'administrativeAreaLevel2'?: string,
  'postalCode': string,
  'country'?: string,
  'firstName'?: string,
  'lastName'?: string,
  'organization'?: string,
  'deliveryNotes'?: string,
  'phone'?: string,
  'billTo'?: boolean,
  'shipTo'?: boolean,
  'shipFrom'?: boolean,
  'easypostId'?: string,
  'deliverability'?: EncodedAddressDeliverability,
  'isRetailLocation'?: boolean,
};
export const AddressInput: t.Type<AddressInput, EncodedAddressInput> = t.recursion('AddressInput', () => (
  t.exact(t.intersection([t.type({
    'line1': t.string,
    'locality': t.string,
    'administrativeAreaLevel1': t.string,
    'postalCode': t.string,
  }), t.partial({
    'line2': t.string,
    'administrativeAreaLevel2': t.string,
    'country': t.string,
    'firstName': t.string,
    'lastName': t.string,
    'organization': t.string,
    'deliveryNotes': t.string,
    'phone': t.string,
    'billTo': t.boolean,
    'shipTo': t.boolean,
    'shipFrom': t.boolean,
    'easypostId': t.string,
    'deliverability': AddressDeliverability,
    'isRetailLocation': t.boolean,
  })]), 'AddressInput')
));


export type Address = AddressInput & {
  'id': number,
  'accountId'?: number,
  'createdByUserId'?: number,
  'version': number,
  'status'?: CodegenEnumOpenValues<'available' | 'deleted' | 'archived'>,
  'buyerLocationIds'?: Array<string>,
};
export type EncodedAddress = EncodedAddressInput & {
  'id': number,
  'accountId'?: number,
  'createdByUserId'?: number,
  'version': number,
  'status'?: string,
  'buyerLocationIds'?: Array<string>,
};
export const Address: t.Type<Address, EncodedAddress> = t.recursion('Address', () => (
  t.intersection([
    AddressInput,
    t.exact(t.intersection([t.type({
      'id': t.number,
      'version': t.number,
    }), t.partial({
      'accountId': t.number,
      'createdByUserId': t.number,
      'status': CodegenEnumOpen({
        'available': null,
        'deleted': null,
        'archived': null,
      }, 'Address'),
      'buyerLocationIds': t.array(t.string),
    })]), 'Address')
  ], 'Address')
));


/** The deliverability of this address, according to the EasyPost API. */
export type AddressDeliverability = CodegenEnumOpenValues<'unknown' | 'deliverable' | 'undeliverable'>;
export type EncodedAddressDeliverability = string;
export const AddressDeliverability = CodegenEnumOpen({
  'unknown': null,
  'deliverable': null,
  'undeliverable': null,
}, 'AddressDeliverability');
export const AddressDeliverabilityValues = ['unknown', 'deliverable', 'undeliverable'] as const;

export type AddressType = CodegenEnumOpenValues<'shipTo' | 'billTo' | 'shipFrom' | 'isRetailLocation'>;
export type EncodedAddressType = string;
export const AddressType = CodegenEnumOpen({
  'shipTo': null,
  'billTo': null,
  'shipFrom': null,
  'isRetailLocation': null,
}, 'AddressType');
export const AddressTypeValues = ['shipTo', 'billTo', 'shipFrom', 'isRetailLocation'] as const;

/** Data to inform attribution */
export type AttributionData = {
  'attributionSource'?: string,
  'attributionMedium'?: string,
  'attributionCampaign'?: string,
};
export type EncodedAttributionData = {
  'attributionSource'?: string,
  'attributionMedium'?: string,
  'attributionCampaign'?: string,
};
export const AttributionData: t.Type<AttributionData, EncodedAttributionData> = t.recursion('AttributionData', () => (
  t.exact(t.intersection([t.type({
    
  }), t.partial({
    'attributionSource': t.string,
    'attributionMedium': t.string,
    'attributionCampaign': t.string,
  })]), 'AttributionData')
));


/** Represents a person's contact information. */
export type Contact = {
  'id'?: number,
  /** Contact's first name. */
  'firstName'?: string,
  /** Contact's last name. */
  'lastName'?: string,
  /** Contact's email address. */
  'email'?: string,
  /** Contact's phone number. */
  'phone'?: string,
  /** The HubSpot ID of the contact's record in our HubSpot account. This is the last numeric component of the HubSpot URL in your browser.
  e.g. For https://app.hubspot.com/contacts/5411323/contact/45501/ this should be "45501" Note that this is different from a company's HubSpot ID. */
  'hubSpotId'?: string,
  /** The user id that has this as the primary contact if any */
  'primaryContactUserId'?: number,
};
export type EncodedContact = {
  'id'?: number,
  'firstName'?: string,
  'lastName'?: string,
  'email'?: string,
  'phone'?: string,
  'hubSpotId'?: string,
  'primaryContactUserId'?: number,
};
export const Contact: t.Type<Contact, EncodedContact> = t.recursion('Contact', () => (
  t.exact(t.intersection([t.type({
    
  }), t.partial({
    'id': t.number,
    'firstName': t.string,
    'lastName': t.string,
    'email': t.string,
    'phone': t.string,
    'hubSpotId': t.string,
    'primaryContactUserId': t.number,
  })]), 'Contact')
));


export type ContactsInput = {
  'buyerId'?: number,
  'sellerId'?: number,
  'type': ContactType,
  'contacts': Array<Contact>,
};
export type EncodedContactsInput = {
  'buyerId'?: number,
  'sellerId'?: number,
  'type': EncodedContactType,
  'contacts': Array<EncodedContact>,
};
export const ContactsInput: t.Type<ContactsInput, EncodedContactsInput> = t.recursion('ContactsInput', () => (
  t.exact(t.intersection([t.type({
    'type': ContactType,
    'contacts': t.array(Contact),
  }), t.partial({
    'buyerId': t.number,
    'sellerId': t.number,
  })]), 'ContactsInput')
));


export type ContactWithRelationship = Contact & {
  'type'?: ContactType,
  'tags'?: Array<string>,
};
export type EncodedContactWithRelationship = EncodedContact & {
  'type'?: EncodedContactType,
  'tags'?: Array<string>,
};
export const ContactWithRelationship: t.Type<ContactWithRelationship, EncodedContactWithRelationship> = t.recursion('ContactWithRelationship', () => (
  t.intersection([
    Contact,
    t.exact(t.intersection([t.type({
      
    }), t.partial({
      'type': ContactType,
      'tags': t.array(t.string),
    })]), 'ContactWithRelationship')
  ], 'ContactWithRelationship')
));


export type ContactType = CodegenEnumOpenValues<'po' | 'billing' | 'primary' | 'mableSupport'>;
export type EncodedContactType = string;
export const ContactType = CodegenEnumOpen({
  'po': null,
  'billing': null,
  'primary': null,
  'mableSupport': null,
}, 'ContactType');
export const ContactTypeValues = ['po', 'billing', 'primary', 'mableSupport'] as const;

/** Used while inputting a contact that will be associated with a seller */
export type ContactRelationshipSellerInput = {
  'type': 'ContactRelationshipSellerInput',
  'sellerId': number,
  'contactType': CodegenEnumOpenValues<'billing' | 'po' | 'primary' | 'mableSupport'>,
};
export type EncodedContactRelationshipSellerInput = {
  'type': string,
  'sellerId': number,
  'contactType': string,
};
export const ContactRelationshipSellerInput: t.Type<ContactRelationshipSellerInput, EncodedContactRelationshipSellerInput> = t.recursion('ContactRelationshipSellerInput', () => (
  t.exact(t.intersection([t.type({
    'type': CodegenEnumClosed({
      'ContactRelationshipSellerInput': null,
    }, 'ContactRelationshipSellerInput'),
    'sellerId': t.number,
    'contactType': CodegenEnumOpen({
      'billing': null,
      'po': null,
      'primary': null,
      'mableSupport': null,
    }, 'ContactRelationshipSellerInput'),
  }), t.partial({
    
  })]), 'ContactRelationshipSellerInput')
));


/** Used while inputting a contact that will be associated with a buyer */
export type ContactRelationshipBuyerInput = {
  'type': 'ContactRelationshipBuyerInput',
  'buyerId': number,
  'contactType': CodegenEnumOpenValues<'billing' | 'po'>,
};
export type EncodedContactRelationshipBuyerInput = {
  'type': string,
  'buyerId': number,
  'contactType': string,
};
export const ContactRelationshipBuyerInput: t.Type<ContactRelationshipBuyerInput, EncodedContactRelationshipBuyerInput> = t.recursion('ContactRelationshipBuyerInput', () => (
  t.exact(t.intersection([t.type({
    'type': CodegenEnumClosed({
      'ContactRelationshipBuyerInput': null,
    }, 'ContactRelationshipBuyerInput'),
    'buyerId': t.number,
    'contactType': CodegenEnumOpen({
      'billing': null,
      'po': null,
    }, 'ContactRelationshipBuyerInput'),
  }), t.partial({
    
  })]), 'ContactRelationshipBuyerInput')
));


/** Used while inputting a contact that will be associated with a user as their primary contact. */
export type ContactRelationshipUserInput = {
  'type': 'ContactRelationshipUserInput',
  'userId': number,
};
export type EncodedContactRelationshipUserInput = {
  'type': string,
  'userId': number,
};
export const ContactRelationshipUserInput: t.Type<ContactRelationshipUserInput, EncodedContactRelationshipUserInput> = t.recursion('ContactRelationshipUserInput', () => (
  t.exact(t.intersection([t.type({
    'type': CodegenEnumClosed({
      'ContactRelationshipUserInput': null,
    }, 'ContactRelationshipUserInput'),
    'userId': t.number,
  }), t.partial({
    
  })]), 'ContactRelationshipUserInput')
));


/** Used while inputting a contact that will be associated with a buyer location */
export type ContactRelationshipBuyerLocationInput = {
  'type': 'ContactRelationshipBuyerLocationInput',
  'buyerLocationId': string,
  'contactType': CodegenEnumOpenValues<'billing' | 'po'>,
};
export type EncodedContactRelationshipBuyerLocationInput = {
  'type': string,
  'buyerLocationId': string,
  'contactType': string,
};
export const ContactRelationshipBuyerLocationInput: t.Type<ContactRelationshipBuyerLocationInput, EncodedContactRelationshipBuyerLocationInput> = t.recursion('ContactRelationshipBuyerLocationInput', () => (
  t.exact(t.intersection([t.type({
    'type': CodegenEnumClosed({
      'ContactRelationshipBuyerLocationInput': null,
    }, 'ContactRelationshipBuyerLocationInput'),
    'buyerLocationId': t.string,
    'contactType': CodegenEnumOpen({
      'billing': null,
      'po': null,
    }, 'ContactRelationshipBuyerLocationInput'),
  }), t.partial({
    
  })]), 'ContactRelationshipBuyerLocationInput')
));


export type ContactInput = {
  'relationship': OneOfOpenValues<ContactRelationshipSellerInput | ContactRelationshipBuyerInput | ContactRelationshipUserInput | ContactRelationshipBuyerLocationInput>,
  'contact': Contact,
};
export type EncodedContactInput = {
  'relationship': OneOfOpenEncodedValues<EncodedContactRelationshipSellerInput | EncodedContactRelationshipBuyerInput | EncodedContactRelationshipUserInput | EncodedContactRelationshipBuyerLocationInput>,
  'contact': EncodedContact,
};
export const ContactInput: t.Type<ContactInput, EncodedContactInput> = t.recursion('ContactInput', () => (
  t.exact(t.intersection([t.type({
    'relationship': OneOfOpen([ContactRelationshipSellerInput, ContactRelationshipBuyerInput, ContactRelationshipUserInput, ContactRelationshipBuyerLocationInput]),
    'contact': Contact,
  }), t.partial({
    
  })]), 'ContactInput')
));


export type SellerCustomerPatch = {
  /** If present, this is the minimum order total necessary for the buyer to purchase from this seller. */
  'orderMinimum'?: null | BigNumber,
  /** If present, updates the seller's note about the buyer */
  'sellerNote'?: null | string,
};
export type EncodedSellerCustomerPatch = {
  'orderMinimum'?: null | string,
  'sellerNote'?: null | string,
};
export const SellerCustomerPatch: t.Type<SellerCustomerPatch, EncodedSellerCustomerPatch> = t.recursion('SellerCustomerPatch', () => (
  t.exact(t.intersection([t.type({
    
  }), t.partial({
    'orderMinimum': t.union([t.null, NumberFromString]),
    'sellerNote': t.union([t.null, t.string]),
  })]), 'SellerCustomerPatch')
));


export type Buyer = {
  'id': number,
  'accountId': number,
  'name'?: string,
  'sellerRelationships'?: Array<BuyerSellerRelationship>,
  'contacts'?: Array<{
    'type'?: string,
    'tags'?: Array<string>,
  } & Contact>,
  'unredeemedDiscounts'?: Array<Discount>,
  'brandReferralCode'?: string,
  'brandReferralLink'?: string,
  'postalCode'?: string,
  'extraData'?: Record<string, unknown>,
  'firstOrderReferrerCode'?: string,
  'paymentTermsNetDays'?: number,
  'requestedPaymentTermsNetDays'?: number,
  'pendingPaymentTermsNetDays'?: number,
  'websiteUrl'?: string,
  'hubSpotCompanyId'?: string,
  'pauseAutoBilling'?: boolean,
  'analyticsInfo'?: BuyerAnalyticsInfo,
  'verified'?: boolean,
  'hasOrdered'?: boolean,
  'orderConfirmationSftpPath'?: string,
  'orderConfirmationSftpFormat'?: OrderConfirmationSftpFormat,
  'priority'?: boolean,
  'partnerProgramMemberships'?: Array<BuyerPartnerProgramMembership>,
  'pricingRegionId'?: string,
};
export type EncodedBuyer = {
  'id': number,
  'accountId': number,
  'name'?: string,
  'sellerRelationships'?: Array<EncodedBuyerSellerRelationship>,
  'contacts'?: Array<{
    'type'?: string,
    'tags'?: Array<string>,
  } & EncodedContact>,
  'unredeemedDiscounts'?: Array<EncodedDiscount>,
  'brandReferralCode'?: string,
  'brandReferralLink'?: string,
  'postalCode'?: string,
  'extraData'?: Record<string, unknown>,
  'firstOrderReferrerCode'?: string,
  'paymentTermsNetDays'?: number,
  'requestedPaymentTermsNetDays'?: number,
  'pendingPaymentTermsNetDays'?: number,
  'websiteUrl'?: string,
  'hubSpotCompanyId'?: string,
  'pauseAutoBilling'?: boolean,
  'analyticsInfo'?: EncodedBuyerAnalyticsInfo,
  'verified'?: boolean,
  'hasOrdered'?: boolean,
  'orderConfirmationSftpPath'?: string,
  'orderConfirmationSftpFormat'?: EncodedOrderConfirmationSftpFormat,
  'priority'?: boolean,
  'partnerProgramMemberships'?: Array<EncodedBuyerPartnerProgramMembership>,
  'pricingRegionId'?: string,
};
export const Buyer: t.Type<Buyer, EncodedBuyer> = t.recursion('Buyer', () => (
  t.exact(t.intersection([t.type({
    'id': t.number,
    'accountId': t.number,
  }), t.partial({
    'name': t.string,
    'sellerRelationships': t.array(BuyerSellerRelationship),
    'contacts': t.array(t.intersection([
      t.exact(t.intersection([t.type({
        
      }), t.partial({
        'type': t.string,
        'tags': t.array(t.string),
      })]), 'BuyerItem'),
      Contact
    ], 'BuyerItem')),
    'unredeemedDiscounts': t.array(Discount),
    'brandReferralCode': t.string,
    'brandReferralLink': t.string,
    'postalCode': t.string,
    'extraData': t.record(t.string, t.unknown),
    'firstOrderReferrerCode': t.string,
    'paymentTermsNetDays': t.number,
    'requestedPaymentTermsNetDays': t.number,
    'pendingPaymentTermsNetDays': t.number,
    'websiteUrl': t.string,
    'hubSpotCompanyId': t.string,
    'pauseAutoBilling': t.boolean,
    'analyticsInfo': BuyerAnalyticsInfo,
    'verified': t.boolean,
    'hasOrdered': t.boolean,
    'orderConfirmationSftpPath': t.string,
    'orderConfirmationSftpFormat': OrderConfirmationSftpFormat,
    'priority': t.boolean,
    'partnerProgramMemberships': t.array(BuyerPartnerProgramMembership),
    'pricingRegionId': t.string,
  })]), 'Buyer')
));


export type BuyerPartnerProgramMembership = {
  'partnerAccountId': number,
  /** The system (non-display) name of the partner account program */
  'partnerAccountName': string,
};
export type EncodedBuyerPartnerProgramMembership = {
  'partnerAccountId': number,
  'partnerAccountName': string,
};
export const BuyerPartnerProgramMembership: t.Type<BuyerPartnerProgramMembership, EncodedBuyerPartnerProgramMembership> = t.recursion('BuyerPartnerProgramMembership', () => (
  t.exact(t.intersection([t.type({
    'partnerAccountId': t.number,
    'partnerAccountName': t.string,
  }), t.partial({
    
  })]), 'BuyerPartnerProgramMembership')
));


/** Info about the buyer used for analytics purposes */
export type BuyerAnalyticsInfo = {
  /** Whether the buyer currently meets the criteria to be considered a "grocery" retailer */
  'isGrocery': boolean,
  /** Whether the buyer currently meets the criteria to be considered a partial "grocery" retailer */
  'isPartialGrocery': boolean,
  /** Whether the buyer currently has been given terms. (net terms > 0) */
  'hasTerms': boolean,
  /** What distributors the buyer has a relationship with */
  'distributorRelationships'?: Array<string>,
};
export type EncodedBuyerAnalyticsInfo = {
  'isGrocery': boolean,
  'isPartialGrocery': boolean,
  'hasTerms': boolean,
  'distributorRelationships'?: Array<string>,
};
export const BuyerAnalyticsInfo: t.Type<BuyerAnalyticsInfo, EncodedBuyerAnalyticsInfo> = t.recursion('BuyerAnalyticsInfo', () => (
  t.exact(t.intersection([t.type({
    'isGrocery': t.boolean,
    'isPartialGrocery': t.boolean,
    'hasTerms': t.boolean,
  }), t.partial({
    'distributorRelationships': t.array(t.string),
  })]), 'BuyerAnalyticsInfo')
));


/** The level of buyer conversion that should be reported to analytics during checkout */
export type BuyerConversionType = CodegenEnumOpenValues<'grocery' | 'partialGrocery' | 'other'>;
export type EncodedBuyerConversionType = string;
export const BuyerConversionType = CodegenEnumOpen({
  'grocery': null,
  'partialGrocery': null,
  'other': null,
}, 'BuyerConversionType');
export const BuyerConversionTypeValues = ['grocery', 'partialGrocery', 'other'] as const;

/** The type or external system this identifier is for */
export type BuyerLocationIdentifierType = CodegenEnumOpenValues<'mclane' | 'mclaneSrp' | 'mclaneDc' | 'usfoods' | 'keheStoreNumber' | 'keheCustomerNumber' | 'hebDuns'>;
export type EncodedBuyerLocationIdentifierType = string;
export const BuyerLocationIdentifierType = CodegenEnumOpen({
  'mclane': null,
  'mclaneSrp': null,
  'mclaneDc': null,
  'usfoods': null,
  'keheStoreNumber': null,
  'keheCustomerNumber': null,
  'hebDuns': null,
}, 'BuyerLocationIdentifierType');
export const BuyerLocationIdentifierTypeValues = ['mclane', 'mclaneSrp', 'mclaneDc', 'usfoods', 'keheStoreNumber', 'keheCustomerNumber', 'hebDuns'] as const;

/** Object used to create or display a BuyerLocationIdentifier. */
export type BuyerLocationIdentifierBase = {
  'type': BuyerLocationIdentifierType,
  'identifier': string,
  'partnerAccountId'?: number,
};
export type EncodedBuyerLocationIdentifierBase = {
  'type': EncodedBuyerLocationIdentifierType,
  'identifier': string,
  'partnerAccountId'?: number,
};
export const BuyerLocationIdentifierBase: t.Type<BuyerLocationIdentifierBase, EncodedBuyerLocationIdentifierBase> = t.recursion('BuyerLocationIdentifierBase', () => (
  t.exact(t.intersection([t.type({
    'type': BuyerLocationIdentifierType,
    'identifier': t.string,
  }), t.partial({
    'partnerAccountId': t.number,
  })]), 'BuyerLocationIdentifierBase')
));


export type BuyerLocationIdentifier = BuyerLocationIdentifierBase & {
  'id': number,
  'buyerLocationId': number,
  'createdAt': Date,
  'updatedAt': Date,
};
export type EncodedBuyerLocationIdentifier = EncodedBuyerLocationIdentifierBase & {
  'id': number,
  'buyerLocationId': number,
  'createdAt': string,
  'updatedAt': string,
};
export const BuyerLocationIdentifier: t.Type<BuyerLocationIdentifier, EncodedBuyerLocationIdentifier> = t.recursion('BuyerLocationIdentifier', () => (
  t.intersection([
    BuyerLocationIdentifierBase,
    t.exact(t.intersection([t.type({
      'id': t.number,
      'buyerLocationId': t.number,
      'createdAt': DateFromISOString,
      'updatedAt': DateFromISOString,
    }), t.partial({
      
    })]), 'BuyerLocationIdentifier')
  ], 'BuyerLocationIdentifier')
));


export type BuyerLocationContact = Contact & {
  'type': CodegenEnumOpenValues<'primary' | 'billing' | 'po'>,
};
export type EncodedBuyerLocationContact = EncodedContact & {
  'type': string,
};
export const BuyerLocationContact: t.Type<BuyerLocationContact, EncodedBuyerLocationContact> = t.recursion('BuyerLocationContact', () => (
  t.intersection([
    Contact,
    t.exact(t.intersection([t.type({
      'type': CodegenEnumOpen({
        'primary': null,
        'billing': null,
        'po': null,
      }, 'BuyerLocationContact'),
    }), t.partial({
      
    })]), 'BuyerLocationContact')
  ], 'BuyerLocationContact')
));


export type PricingRegion = {
  'id': string,
  'name': string,
  'partnerIdentifier': string,
  'partnerAccountId': number,
};
export type EncodedPricingRegion = {
  'id': string,
  'name': string,
  'partnerIdentifier': string,
  'partnerAccountId': number,
};
export const PricingRegion: t.Type<PricingRegion, EncodedPricingRegion> = t.recursion('PricingRegion', () => (
  t.exact(t.intersection([t.type({
    'id': t.string,
    'name': t.string,
    'partnerIdentifier': t.string,
    'partnerAccountId': t.number,
  }), t.partial({
    
  })]), 'PricingRegion')
));


export type BuyerLocation = {
  'id': string,
  'name': string,
  'accountId': number,
  'orderCount'?: number,
  /** The identifier of the store as the buyer defines it. */
  'storeIdentifier'?: string,
  /** The identifier of the subsidiary company that owns or manages this location. Used for accounts that represent a parent company with several subsidiaries under it. */
  'subsidiaryIdentifier'?: string,
  'ediShipToAddressId'?: number,
  /** Additional identifiers for this location, as defined by 3rd party companies that don't own or manage the store. */
  'additionalIdentifiers'?: Array<BuyerLocationIdentifier>,
  'contacts'?: Array<BuyerLocationContact>,
  'pricingRegion'?: PricingRegion,
  'deletedAt'?: Date,
};
export type EncodedBuyerLocation = {
  'id': string,
  'name': string,
  'accountId': number,
  'orderCount'?: number,
  'storeIdentifier'?: string,
  'subsidiaryIdentifier'?: string,
  'ediShipToAddressId'?: number,
  'additionalIdentifiers'?: Array<EncodedBuyerLocationIdentifier>,
  'contacts'?: Array<EncodedBuyerLocationContact>,
  'pricingRegion'?: EncodedPricingRegion,
  'deletedAt'?: string,
};
export const BuyerLocation: t.Type<BuyerLocation, EncodedBuyerLocation> = t.recursion('BuyerLocation', () => (
  t.exact(t.intersection([t.type({
    'id': t.string,
    'name': t.string,
    'accountId': t.number,
  }), t.partial({
    'orderCount': t.number,
    'storeIdentifier': t.string,
    'subsidiaryIdentifier': t.string,
    'ediShipToAddressId': t.number,
    'additionalIdentifiers': t.array(BuyerLocationIdentifier),
    'contacts': t.array(BuyerLocationContact),
    'pricingRegion': PricingRegion,
    'deletedAt': DateFromISOString,
  })]), 'BuyerLocation')
));


/** Data needed to create a buyer location */
export type BuyerLocationInput = {
  'name': string,
  'storeIdentifier'?: null | string,
  'subsidiaryIdentifier'?: null | string,
  'ediShipToAddressId'?: null | number,
  'additionalIdentifiers'?: Array<BuyerLocationIdentifierBase>,
  'pricingRegionId'?: string,
};
export type EncodedBuyerLocationInput = {
  'name': string,
  'storeIdentifier'?: null | string,
  'subsidiaryIdentifier'?: null | string,
  'ediShipToAddressId'?: null | number,
  'additionalIdentifiers'?: Array<EncodedBuyerLocationIdentifierBase>,
  'pricingRegionId'?: string,
};
export const BuyerLocationInput: t.Type<BuyerLocationInput, EncodedBuyerLocationInput> = t.recursion('BuyerLocationInput', () => (
  t.exact(t.intersection([t.type({
    'name': t.string,
  }), t.partial({
    'storeIdentifier': t.union([t.null, t.string]),
    'subsidiaryIdentifier': t.union([t.null, t.string]),
    'ediShipToAddressId': t.union([t.null, t.number]),
    'additionalIdentifiers': t.array(BuyerLocationIdentifierBase),
    'pricingRegionId': t.string,
  })]), 'BuyerLocationInput')
));


export type BuyerLocationPatch = {
  /** The id of the target buyer location */
  'id': string,
  'name'?: string,
  'storeIdentifier'?: null | string,
  'subsidiaryIdentifier'?: null | string,
  'ediShipToAddressId'?: null | number,
  'additionalIdentifiersToAdd'?: Array<BuyerLocationIdentifierBase>,
  /** IDs of existing buyer location identifiers to remove */
  'additionalIdentifiersIdsToRemove'?: Array<number>,
  'pricingRegionId'?: null | string,
  'deleted'?: boolean,
};
export type EncodedBuyerLocationPatch = {
  'id': string,
  'name'?: string,
  'storeIdentifier'?: null | string,
  'subsidiaryIdentifier'?: null | string,
  'ediShipToAddressId'?: null | number,
  'additionalIdentifiersToAdd'?: Array<EncodedBuyerLocationIdentifierBase>,
  'additionalIdentifiersIdsToRemove'?: Array<number>,
  'pricingRegionId'?: null | string,
  'deleted'?: boolean,
};
export const BuyerLocationPatch: t.Type<BuyerLocationPatch, EncodedBuyerLocationPatch> = t.recursion('BuyerLocationPatch', () => (
  t.exact(t.intersection([t.type({
    'id': t.string,
  }), t.partial({
    'name': t.string,
    'storeIdentifier': t.union([t.null, t.string]),
    'subsidiaryIdentifier': t.union([t.null, t.string]),
    'ediShipToAddressId': t.union([t.null, t.number]),
    'additionalIdentifiersToAdd': t.array(BuyerLocationIdentifierBase),
    'additionalIdentifiersIdsToRemove': t.array(t.number),
    'pricingRegionId': t.union([t.null, t.string]),
    'deleted': t.boolean,
  })]), 'BuyerLocationPatch')
));


/** The type or external system this identifier is for */
export type AccountIdentifierType = CodegenEnumOpenValues<'mclaneCorpCode' | 'mclaneSrp' | 'usFoodsCustomerNumber' | 'keheChainCode'>;
export type EncodedAccountIdentifierType = string;
export const AccountIdentifierType = CodegenEnumOpen({
  'mclaneCorpCode': null,
  'mclaneSrp': null,
  'usFoodsCustomerNumber': null,
  'keheChainCode': null,
}, 'AccountIdentifierType');
export const AccountIdentifierTypeValues = ['mclaneCorpCode', 'mclaneSrp', 'usFoodsCustomerNumber', 'keheChainCode'] as const;

/** An identifier for an account, used for associating accounts with 3rd party systems. */
export type AccountIdentifier = {
  'type': AccountIdentifierType,
  'identifier': string,
};
export type EncodedAccountIdentifier = {
  'type': EncodedAccountIdentifierType,
  'identifier': string,
};
export const AccountIdentifier: t.Type<AccountIdentifier, EncodedAccountIdentifier> = t.recursion('AccountIdentifier', () => (
  t.exact(t.intersection([t.type({
    'type': AccountIdentifierType,
    'identifier': t.string,
  }), t.partial({
    
  })]), 'AccountIdentifier')
));


export type AdminAccountPatch = {
  'sftpUsername'?: null | string,
  'supportContactId'?: null | number,
  'addTags'?: Array<string>,
  'removeTags'?: Array<string>,
  'addIdentifiers'?: Array<AccountIdentifier>,
  'removeIdentifiers'?: Array<AccountIdentifier>,
};
export type EncodedAdminAccountPatch = {
  'sftpUsername'?: null | string,
  'supportContactId'?: null | number,
  'addTags'?: Array<string>,
  'removeTags'?: Array<string>,
  'addIdentifiers'?: Array<EncodedAccountIdentifier>,
  'removeIdentifiers'?: Array<EncodedAccountIdentifier>,
};
export const AdminAccountPatch: t.Type<AdminAccountPatch, EncodedAdminAccountPatch> = t.recursion('AdminAccountPatch', () => (
  t.exact(t.intersection([t.type({
    
  }), t.partial({
    'sftpUsername': t.union([t.null, t.string]),
    'supportContactId': t.union([t.null, t.number]),
    'addTags': t.array(t.string),
    'removeTags': t.array(t.string),
    'addIdentifiers': t.array(AccountIdentifier),
    'removeIdentifiers': t.array(AccountIdentifier),
  })]), 'AdminAccountPatch')
));


export type Discount = {
  'id': number,
  'code': string,
  'name': string,
  'amount': BigNumber,
};
export type EncodedDiscount = {
  'id': number,
  'code': string,
  'name': string,
  'amount': string,
};
export const Discount: t.Type<Discount, EncodedDiscount> = t.recursion('Discount', () => (
  t.exact(t.intersection([t.type({
    'id': t.number,
    'code': t.string,
    'name': t.string,
    'amount': NumberFromString,
  }), t.partial({
    
  })]), 'Discount')
));


export type BuyerSellerRelationshipInput = {
  'buyerId': number,
  'sellerId': number,
  'relationship': string,
};
export type EncodedBuyerSellerRelationshipInput = {
  'buyerId': number,
  'sellerId': number,
  'relationship': string,
};
export const BuyerSellerRelationshipInput: t.Type<BuyerSellerRelationshipInput, EncodedBuyerSellerRelationshipInput> = t.recursion('BuyerSellerRelationshipInput', () => (
  t.exact(t.intersection([t.type({
    'buyerId': t.number,
    'sellerId': t.number,
    'relationship': t.string,
  }), t.partial({
    
  })]), 'BuyerSellerRelationshipInput')
));


export type BuyerSellerRelationship = {
  'id': number,
  'relationships': Array<string>,
  'seller': SellerDisplayInfo,
};
export type EncodedBuyerSellerRelationship = {
  'id': number,
  'relationships': Array<string>,
  'seller': EncodedSellerDisplayInfo,
};
export const BuyerSellerRelationship: t.Type<BuyerSellerRelationship, EncodedBuyerSellerRelationship> = t.recursion('BuyerSellerRelationship', () => (
  t.exact(t.intersection([t.type({
    'id': t.number,
    'relationships': t.array(t.string),
    'seller': SellerDisplayInfo,
  }), t.partial({
    
  })]), 'BuyerSellerRelationship')
));


export type QueueJob = {
  'id': string,
  'name': string,
  'priority'?: number,
  'data'?: Record<string, unknown>,
  'state': JobState,
  'retrylimit'?: number,
  'retrycount'?: number,
  'retrydelay'?: number,
  'retrybackoff'?: boolean,
  'startafter'?: Date,
  'startedon'?: Date,
  'singletonkey'?: string,
  'singletonon'?: Date,
  'createdon': Date,
  'completedon'?: Date,
  'honeycombLink'?: string,
};
export type EncodedQueueJob = {
  'id': string,
  'name': string,
  'priority'?: number,
  'data'?: Record<string, unknown>,
  'state': EncodedJobState,
  'retrylimit'?: number,
  'retrycount'?: number,
  'retrydelay'?: number,
  'retrybackoff'?: boolean,
  'startafter'?: string,
  'startedon'?: string,
  'singletonkey'?: string,
  'singletonon'?: string,
  'createdon': string,
  'completedon'?: string,
  'honeycombLink'?: string,
};
export const QueueJob: t.Type<QueueJob, EncodedQueueJob> = t.recursion('QueueJob', () => (
  t.exact(t.intersection([t.type({
    'id': t.string,
    'name': t.string,
    'state': JobState,
    'createdon': DateFromISOString,
  }), t.partial({
    'priority': t.number,
    'data': t.record(t.string, t.unknown),
    'retrylimit': t.number,
    'retrycount': t.number,
    'retrydelay': t.number,
    'retrybackoff': t.boolean,
    'startafter': DateFromISOString,
    'startedon': DateFromISOString,
    'singletonkey': t.string,
    'singletonon': DateFromISOString,
    'completedon': DateFromISOString,
    'honeycombLink': t.string,
  })]), 'QueueJob')
));


export type QueueJobSummary = {
  'name': string,
  'state': JobState,
  'lastCompletion'?: Date,
  'lastSuccess'?: Date,
};
export type EncodedQueueJobSummary = {
  'name': string,
  'state': EncodedJobState,
  'lastCompletion'?: string,
  'lastSuccess'?: string,
};
export const QueueJobSummary: t.Type<QueueJobSummary, EncodedQueueJobSummary> = t.recursion('QueueJobSummary', () => (
  t.exact(t.intersection([t.type({
    'name': t.string,
    'state': JobState,
  }), t.partial({
    'lastCompletion': DateFromISOString,
    'lastSuccess': DateFromISOString,
  })]), 'QueueJobSummary')
));


/** possible states for jobs according to pgboss, see https://github.com/timgit/pg-boss/blob/254fa0cf9ce679f98a9fc26ea9906d955eb30530/types.d.ts#L162 */
export type JobState = CodegenEnumOpenValues<'created' | 'retry' | 'active' | 'completed' | 'expired' | 'cancelled' | 'failed'>;
export type EncodedJobState = string;
export const JobState = CodegenEnumOpen({
  'created': null,
  'retry': null,
  'active': null,
  'completed': null,
  'expired': null,
  'cancelled': null,
  'failed': null,
}, 'JobState');
export const JobStateValues = ['created', 'retry', 'active', 'completed', 'expired', 'cancelled', 'failed'] as const;

/** named group of words and phrases to scan every user-sent message for */
export type ModerationList = {
  'id': string,
  'createdAt': Date,
  'updatedAt': Date,
  'name': string,
  'tokens': Array<string>,
};
export type EncodedModerationList = {
  'id': string,
  'createdAt': string,
  'updatedAt': string,
  'name': string,
  'tokens': Array<string>,
};
export const ModerationList: t.Type<ModerationList, EncodedModerationList> = t.recursion('ModerationList', () => (
  t.exact(t.intersection([t.type({
    'id': t.string,
    'createdAt': DateFromISOString,
    'updatedAt': DateFromISOString,
    'name': t.string,
    'tokens': t.array(t.string),
  }), t.partial({
    
  })]), 'ModerationList')
));


/** State machine for a seller */
export type SellerStatus = CodegenEnumOpenValues<'draft' | 'available' | 'deleted' | 'archived'>;
export type EncodedSellerStatus = string;
export const SellerStatus = CodegenEnumOpen({
  'draft': null,
  'available': null,
  'deleted': null,
  'archived': null,
}, 'SellerStatus');
export const SellerStatusValues = ['draft', 'available', 'deleted', 'archived'] as const;

/** Object used for updating sellers with PATCH behavior, with everything optional. */
export type SellerPatch = {
  'name'?: string,
  'shortDescription'?: null | string,
  'description'?: null | string,
  'status'?: SellerStatus,
  'profileImage'?: Image,
  'headerImage'?: null | Image,
  'city'?: null | string,
  'state'?: null | string,
  'primaryContact'?: Contact,
  'billingContact'?: Contact,
  /** The full URL of the seller's official website.
  e.g. "https://drinklococoffee.com/" */
  'websiteUrl'?: null | string,
  /** String Facebook ID, used to construct a Facebook URL.
  e.g. For https://www.facebook.com/DrinkLoco/ this should be "DrinkLoco" */
  'facebookId'?: null | string,
  /** Twitter username/handle. No @ symbol.
  e.g. For https://twitter.com/DrinkLocoCoffee this should be "DrinkLocoCoffee" */
  'twitterId'?: null | string,
  /** Instagram username/handle. No @ symbol.
  e.g. For https://www.instagram.com/drinkloco/ this should be "drinkloco" */
  'instagramId'?: null | string,
  /** TikTok username/handle. No @ symbol.
  e.g. For https://www.tiktok.com/@drinkloco this should be "drinkloco" */
  'tiktokId'?: null | string,
  /** The full URL of the seller's official YouTube channel.
  e.g. "https://www.youtube.com/channel/UC_nz59ZPH14cmGtIoIwRJ-Q" */
  'youTubeUrl'?: null | string,
  /** The range that the seller's annual gross revenue is in */
  'annualGrossRevenue'?: null | SellerAnnualGrossRevenueRange,
  /** The range that the sellers's annual marketing budget is in */
  'annualMarketingBudget'?: null | SellerAnnualMarketingBudgetRange,
  /** The range that the sellers amount of retail locations is in */
  'retailLocationCountRange'?: null | SellerRetailLocationCountRange,
  /** The year the seller's company was founded */
  'yearFounded'?: null | number,
  /** If present, this is the minimum order total necessary to purchase from this seller. */
  'orderMinimum'?: null | BigNumber,
  /** If present, this is the minimum order total necessary to enable free shipping from this seller. */
  'freeShippingMinimum'?: null | BigNumber,
  /** The minimum number of days it takes to get an order ready to ship */
  'shipLeadMin'?: null | BigNumber,
  /** The maximum number of days it takes to get an order ready to ship */
  'shipLeadMax'?: null | BigNumber,
  /** List of contacts that should receive PO emails */
  'purchaseOrderContacts'?: Array<Contact>,
  /** List of contacts that should be contacted by the Mable support team */
  'mableSupportContacts'?: Array<Contact>,
  'samplesOnMarketplaceEnabled'?: boolean,
  /** Whether or not to disable or enable the messaging feature. */
  'messagingEnabled'?: boolean,
  'addCategorySlugs'?: Array<SellerPatchAllowedCategory>,
  'removeCategorySlugs'?: Array<SellerPatchAllowedCategory>,
  /** Category to add this seller to. admin / dataworker only property. */
  'categoryMemberships'?: Array<CategoryMembershipInput>,
  /** Category IDs to remove this seller from. admin / dataworker only property. */
  'removeFromCategoryIds'?: Array<number>,
  /** The primary address that the seller ships from */
  'shipFromAddressId'?: null | number,
  /** The seller's billing address */
  'billingAddressId'?: null | number,
  /** Name of the individual or company name on the bank account that is receiving payments. */
  'payToName'?: null | string,
  /** DBA / doing business as name for this seller. */
  'doingBusinessAsName'?: null | string,
  /** The number of days of the terms the seller wants to be paid on. */
  'preferredPaymentTermsNetDays'?: null | number,
};
export type EncodedSellerPatch = {
  'name'?: string,
  'shortDescription'?: null | string,
  'description'?: null | string,
  'status'?: EncodedSellerStatus,
  'profileImage'?: EncodedImage,
  'headerImage'?: null | EncodedImage,
  'city'?: null | string,
  'state'?: null | string,
  'primaryContact'?: EncodedContact,
  'billingContact'?: EncodedContact,
  'websiteUrl'?: null | string,
  'facebookId'?: null | string,
  'twitterId'?: null | string,
  'instagramId'?: null | string,
  'tiktokId'?: null | string,
  'youTubeUrl'?: null | string,
  'annualGrossRevenue'?: null | EncodedSellerAnnualGrossRevenueRange,
  'annualMarketingBudget'?: null | EncodedSellerAnnualMarketingBudgetRange,
  'retailLocationCountRange'?: null | EncodedSellerRetailLocationCountRange,
  'yearFounded'?: null | number,
  'orderMinimum'?: null | string,
  'freeShippingMinimum'?: null | string,
  'shipLeadMin'?: null | string,
  'shipLeadMax'?: null | string,
  'purchaseOrderContacts'?: Array<EncodedContact>,
  'mableSupportContacts'?: Array<EncodedContact>,
  'samplesOnMarketplaceEnabled'?: boolean,
  'messagingEnabled'?: boolean,
  'addCategorySlugs'?: Array<EncodedSellerPatchAllowedCategory>,
  'removeCategorySlugs'?: Array<EncodedSellerPatchAllowedCategory>,
  'categoryMemberships'?: Array<EncodedCategoryMembershipInput>,
  'removeFromCategoryIds'?: Array<number>,
  'shipFromAddressId'?: null | number,
  'billingAddressId'?: null | number,
  'payToName'?: null | string,
  'doingBusinessAsName'?: null | string,
  'preferredPaymentTermsNetDays'?: null | number,
};
export const SellerPatch: t.Type<SellerPatch, EncodedSellerPatch> = t.recursion('SellerPatch', () => (
  t.exact(t.intersection([t.type({
    
  }), t.partial({
    'name': t.string,
    'shortDescription': t.union([t.null, t.string]),
    'description': t.union([t.null, t.string]),
    'status': SellerStatus,
    'profileImage': Image,
    'headerImage': t.union([t.null, Image]),
    'city': t.union([t.null, t.string]),
    'state': t.union([t.null, t.string]),
    'primaryContact': Contact,
    'billingContact': Contact,
    'websiteUrl': t.union([t.null, t.string]),
    'facebookId': t.union([t.null, t.string]),
    'twitterId': t.union([t.null, t.string]),
    'instagramId': t.union([t.null, t.string]),
    'tiktokId': t.union([t.null, t.string]),
    'youTubeUrl': t.union([t.null, t.string]),
    'annualGrossRevenue': t.union([t.null, SellerAnnualGrossRevenueRange]),
    'annualMarketingBudget': t.union([t.null, SellerAnnualMarketingBudgetRange]),
    'retailLocationCountRange': t.union([t.null, SellerRetailLocationCountRange]),
    'yearFounded': t.union([t.null, t.number]),
    'orderMinimum': t.union([t.null, NumberFromString]),
    'freeShippingMinimum': t.union([t.null, NumberFromString]),
    'shipLeadMin': t.union([t.null, NumberFromString]),
    'shipLeadMax': t.union([t.null, NumberFromString]),
    'purchaseOrderContacts': t.array(Contact),
    'mableSupportContacts': t.array(Contact),
    'samplesOnMarketplaceEnabled': t.boolean,
    'messagingEnabled': t.boolean,
    'addCategorySlugs': t.array(SellerPatchAllowedCategory),
    'removeCategorySlugs': t.array(SellerPatchAllowedCategory),
    'categoryMemberships': t.array(CategoryMembershipInput),
    'removeFromCategoryIds': t.array(t.number),
    'shipFromAddressId': t.union([t.null, t.number]),
    'billingAddressId': t.union([t.null, t.number]),
    'payToName': t.union([t.null, t.string]),
    'doingBusinessAsName': t.union([t.null, t.string]),
    'preferredPaymentTermsNetDays': t.union([t.null, t.number]),
  })]), 'SellerPatch')
));


export type DietCategory = CodegenEnumOpenValues<'certified-organic' | 'dairy-free' | 'gluten-free' | 'keto' | 'kosher' | 'non-gmo' | 'paleo' | 'vegan' | 'vegetarian' | 'whole-30-approved' | 'raw'>;
export type EncodedDietCategory = string;
export const DietCategory = CodegenEnumOpen({
  'certified-organic': null,
  'dairy-free': null,
  'gluten-free': null,
  'keto': null,
  'kosher': null,
  'non-gmo': null,
  'paleo': null,
  'vegan': null,
  'vegetarian': null,
  'whole-30-approved': null,
  'raw': null,
}, 'DietCategory');
export const DietCategoryValues = ['certified-organic', 'dairy-free', 'gluten-free', 'keto', 'kosher', 'non-gmo', 'paleo', 'vegan', 'vegetarian', 'whole-30-approved', 'raw'] as const;

export type ValueCategory = CodegenEnumOpenValues<'aapi-owned' | 'bipoc-owned' | 'lgbtqia-owned' | 'veteran-owned' | 'not-on-amazon' | 'black-owned' | 'eco-friendly' | 'hand-made' | 'made-in-usa' | 'small-batch' | 'upcycled' | 'woman-owned' | 'fair-trade'>;
export type EncodedValueCategory = string;
export const ValueCategory = CodegenEnumOpen({
  'aapi-owned': null,
  'bipoc-owned': null,
  'lgbtqia-owned': null,
  'veteran-owned': null,
  'not-on-amazon': null,
  'black-owned': null,
  'eco-friendly': null,
  'hand-made': null,
  'made-in-usa': null,
  'small-batch': null,
  'upcycled': null,
  'woman-owned': null,
  'fair-trade': null,
}, 'ValueCategory');
export const ValueCategoryValues = ['aapi-owned', 'bipoc-owned', 'lgbtqia-owned', 'veteran-owned', 'not-on-amazon', 'black-owned', 'eco-friendly', 'hand-made', 'made-in-usa', 'small-batch', 'upcycled', 'woman-owned', 'fair-trade'] as const;

export type SellerPatchAllowedCategory = OneOfOpenValues<DietCategory | ValueCategory>;
export type EncodedSellerPatchAllowedCategory = OneOfOpenEncodedValues<EncodedDietCategory | EncodedValueCategory>;
export const SellerPatchAllowedCategory: t.Type<SellerPatchAllowedCategory, EncodedSellerPatchAllowedCategory> = t.recursion('SellerPatchAllowedCategory', () => (
  OneOfOpen([DietCategory, ValueCategory])
));


export type Sellers = {
  'sellers': Array<SellerDisplayInfo>,
  /** Kind of a hack - if you called GET /v1/sellers with an invitation that's already consumed,
  this will let you know so you can avoid asking the user to signup with that invitation. */
  'invitationConsumed'?: boolean,
  /** An extension of the above hack that provides you with the email that consumed the invitation. */
  'invitationConsumedBy'?: string,
};
export type EncodedSellers = {
  'sellers': Array<EncodedSellerDisplayInfo>,
  'invitationConsumed'?: boolean,
  'invitationConsumedBy'?: string,
};
export const Sellers: t.Type<Sellers, EncodedSellers> = t.recursion('Sellers', () => (
  t.exact(t.intersection([t.type({
    'sellers': t.array(SellerDisplayInfo),
  }), t.partial({
    'invitationConsumed': t.boolean,
    'invitationConsumedBy': t.string,
  })]), 'Sellers')
));


export type SellerType = CodegenEnumOpenValues<'brand' | 'distributor'>;
export type EncodedSellerType = string;
export const SellerType = CodegenEnumOpen({
  'brand': null,
  'distributor': null,
}, 'SellerType');
export const SellerTypeValues = ['brand', 'distributor'] as const;

/** Represents an image URI plus some optional metadata */
export type ProductImageUri = {
  'uri': string,
  'width'?: number,
  'height'?: number,
  'sourceExternalId'?: string,
  'packageLevel'?: ImagePackageLevel,
};
export type EncodedProductImageUri = {
  'uri': string,
  'width'?: number,
  'height'?: number,
  'sourceExternalId'?: string,
  'packageLevel'?: EncodedImagePackageLevel,
};
export const ProductImageUri: t.Type<ProductImageUri, EncodedProductImageUri> = t.recursion('ProductImageUri', () => (
  t.exact(t.intersection([t.type({
    'uri': t.string,
  }), t.partial({
    'width': t.number,
    'height': t.number,
    'sourceExternalId': t.string,
    'packageLevel': ImagePackageLevel,
  })]), 'ProductImageUri')
));


/** A record of an image saved in our database */
export type Image = {
  'id'?: number,
  'type': string,
  'externalBucketId': string,
  'externalId': string,
  'version'?: string,
  'format'?: string,
  'width': number,
  'height': number,
  'source'?: ImageSource,
  /** An identifier associated with the source this image came from, especially if the source is a 3rd party. */
  'sourceExternalId'?: string,
};
export type EncodedImage = {
  'id'?: number,
  'type': string,
  'externalBucketId': string,
  'externalId': string,
  'version'?: string,
  'format'?: string,
  'width': number,
  'height': number,
  'source'?: EncodedImageSource,
  'sourceExternalId'?: string,
};
export const Image: t.Type<Image, EncodedImage> = t.recursion('Image', () => (
  t.exact(t.intersection([t.type({
    'type': t.string,
    'externalBucketId': t.string,
    'externalId': t.string,
    'width': t.number,
    'height': t.number,
  }), t.partial({
    'id': t.number,
    'version': t.string,
    'format': t.string,
    'source': ImageSource,
    'sourceExternalId': t.string,
  })]), 'Image')
));


export type ProductImage = Image & {
  'packageLevel'?: ImagePackageLevel,
};
export type EncodedProductImage = EncodedImage & {
  'packageLevel'?: EncodedImagePackageLevel,
};
export const ProductImage: t.Type<ProductImage, EncodedProductImage> = t.recursion('ProductImage', () => (
  t.intersection([
    Image,
    t.exact(t.intersection([t.type({
      
    }), t.partial({
      'packageLevel': ImagePackageLevel,
    })]), 'ProductImage')
  ], 'ProductImage')
));


/** Each enum value represents a property or set of properties that should be supplemented by third party data sources. */
export type ProductDataSupplementStrategy = CodegenEnumOpenValues<'description' | 'ingredients' | 'ingredientsIfBlank' | 'images' | 'barcodes' | 'categories' | 'name' | 'shelfLife' | 'packagings'>;
export type EncodedProductDataSupplementStrategy = string;
export const ProductDataSupplementStrategy = CodegenEnumOpen({
  'description': null,
  'ingredients': null,
  'ingredientsIfBlank': null,
  'images': null,
  'barcodes': null,
  'categories': null,
  'name': null,
  'shelfLife': null,
  'packagings': null,
}, 'ProductDataSupplementStrategy');
export const ProductDataSupplementStrategyValues = ['description', 'ingredients', 'ingredientsIfBlank', 'images', 'barcodes', 'categories', 'name', 'shelfLife', 'packagings'] as const;

/** State of the supplement process */
export type ProductDataSupplementStatus = CodegenEnumOpenValues<'waiting' | 'complete' | 'notfound' | 'error'>;
export type EncodedProductDataSupplementStatus = string;
export const ProductDataSupplementStatus = CodegenEnumOpen({
  'waiting': null,
  'complete': null,
  'notfound': null,
  'error': null,
}, 'ProductDataSupplementStatus');
export const ProductDataSupplementStatusValues = ['waiting', 'complete', 'notfound', 'error'] as const;

/** Where this product data came from */
export type ExternalProductSource = CodegenEnumOpenValues<'ixone' | 'upcitemdb' | 'faire' | 'rangeme'>;
export type EncodedExternalProductSource = string;
export const ExternalProductSource = CodegenEnumOpen({
  'ixone': null,
  'upcitemdb': null,
  'faire': null,
  'rangeme': null,
}, 'ExternalProductSource');
export const ExternalProductSourceValues = ['ixone', 'upcitemdb', 'faire', 'rangeme'] as const;

/** Where this image came from */
export type ImageSource = CodegenEnumOpenValues<'adminportal' | 'adminportalbulk' | 'csv' | 'faire' | 'ixone' | 'rangeme' | 'sellerportal' | 'upcitemdb'>;
export type EncodedImageSource = string;
export const ImageSource = CodegenEnumOpen({
  'adminportal': null,
  'adminportalbulk': null,
  'csv': null,
  'faire': null,
  'ixone': null,
  'rangeme': null,
  'sellerportal': null,
  'upcitemdb': null,
}, 'ImageSource');
export const ImageSourceValues = ['adminportal', 'adminportalbulk', 'csv', 'faire', 'ixone', 'rangeme', 'sellerportal', 'upcitemdb'] as const;

/** Used for images associated with products, this describes the context of the image in relationship to the product. "unit" is the product itself, "innerpack" is the unit in its display case or purchaseable case, "case" is for how it ships. */
export type ImagePackageLevel = CodegenEnumOpenValues<'unit' | 'innerpack' | 'case' | 'outofpkg' | 'opencase'>;
export type EncodedImagePackageLevel = string;
export const ImagePackageLevel = CodegenEnumOpen({
  'unit': null,
  'innerpack': null,
  'case': null,
  'outofpkg': null,
  'opencase': null,
}, 'ImagePackageLevel');
export const ImagePackageLevelValues = ['unit', 'innerpack', 'case', 'outofpkg', 'opencase'] as const;

/** State machine for a product */
export type ProductStatus = CodegenEnumOpenValues<'draft' | 'available' | 'deleted' | 'archived'>;
export type EncodedProductStatus = string;
export const ProductStatus = CodegenEnumOpen({
  'draft': null,
  'available': null,
  'deleted': null,
  'archived': null,
}, 'ProductStatus');
export const ProductStatusValues = ['draft', 'available', 'deleted', 'archived'] as const;

export type BaseProduct = {
  'id'?: number,
  'sellerId': number,
  'name': string,
  'slug': Slug,
  'description'?: string,
  /** A generic home for IDs from 3rd party systems the seller may already be using, or that we may import from. e.g. Square. */
  'externalId'?: string,
  'status'?: ProductStatus,
  'createdAt'?: Date,
  'updatedAt'?: Date,
  'images'?: Array<ProductImage>,
  'extraData'?: Record<string, unknown>,
  /** Admin-only notes + memos, possibly also used for storing catch-all properties */
  'memo'?: string,
  'supplementStatus'?: ProductDataSupplementStatus,
};
export type EncodedBaseProduct = {
  'id'?: number,
  'sellerId': number,
  'name': string,
  'slug': string,
  'description'?: string,
  'externalId'?: string,
  'status'?: EncodedProductStatus,
  'createdAt'?: string,
  'updatedAt'?: string,
  'images'?: Array<EncodedProductImage>,
  'extraData'?: Record<string, unknown>,
  'memo'?: string,
  'supplementStatus'?: EncodedProductDataSupplementStatus,
};
export const BaseProduct: t.Type<BaseProduct, EncodedBaseProduct> = t.recursion('BaseProduct', () => (
  t.exact(t.intersection([t.type({
    'sellerId': t.number,
    'name': t.string,
    'slug': Slug,
  }), t.partial({
    'id': t.number,
    'description': t.string,
    'externalId': t.string,
    'status': ProductStatus,
    'createdAt': DateFromISOString,
    'updatedAt': DateFromISOString,
    'images': t.array(ProductImage),
    'extraData': t.record(t.string, t.unknown),
    'memo': t.string,
    'supplementStatus': ProductDataSupplementStatus,
  })]), 'BaseProduct')
));


export type ProductInput = BaseProduct & {
  'position'?: number,
  'variants'?: Array<ProductVariantInput>,
  'options'?: Array<ProductOptionSetInput>,
  /** Image URLs that the backend will attempt to download and save to our CDN. */
  'imageUrls'?: Array<string>,
  /** Category slugs which will be attempted to be found in our DB. These categories will be associated with all variants of this product. */
  'categorySlugs'?: Array<Slug>,
  /** Brand name to be used for within seller filters. Used for distributors that carry multiple brands. These categories will be associated with all variants of this product. */
  'brandName'?: string,
  /** Human readable names of categories that will appear only for this seller. They will be created if they do not exist. These categories will be associated with all variants of this product. */
  'sellerDefinedCategoryNames'?: Array<string>,
  /** Human readable names of collections that will appear only for this seller. They will be created if they do not exist. These categories will be associated with all variants of this product. */
  'sellerDefinedCollectionNames'?: Array<string>,
};
export type EncodedProductInput = EncodedBaseProduct & {
  'position'?: number,
  'variants'?: Array<EncodedProductVariantInput>,
  'options'?: Array<EncodedProductOptionSetInput>,
  'imageUrls'?: Array<string>,
  'categorySlugs'?: Array<string>,
  'brandName'?: string,
  'sellerDefinedCategoryNames'?: Array<string>,
  'sellerDefinedCollectionNames'?: Array<string>,
};
export const ProductInput: t.Type<ProductInput, EncodedProductInput> = t.recursion('ProductInput', () => (
  t.intersection([
    BaseProduct,
    t.exact(t.intersection([t.type({
      
    }), t.partial({
      'position': t.number,
      'variants': t.array(ProductVariantInput),
      'options': t.array(ProductOptionSetInput),
      'imageUrls': t.array(t.string),
      'categorySlugs': t.array(Slug),
      'brandName': t.string,
      'sellerDefinedCategoryNames': t.array(t.string),
      'sellerDefinedCollectionNames': t.array(t.string),
    })]), 'ProductInput')
  ], 'ProductInput')
));


export type ProductLinks = {
  /** /product/{productId}/{sellerSlug}/{productSlug} */
  'detailPage': string,
  /** /{sellerSlug} */
  'sellerPage': string,
};
export type EncodedProductLinks = {
  'detailPage': string,
  'sellerPage': string,
};
export const ProductLinks: t.Type<ProductLinks, EncodedProductLinks> = t.recursion('ProductLinks', () => (
  t.exact(t.intersection([t.type({
    'detailPage': t.string,
    'sellerPage': t.string,
  }), t.partial({
    
  })]), 'ProductLinks')
));


export type Product = BaseProduct & {
  'id': number,
  'links': ProductLinks,
  /** A combination of all the categories this product's variants belong too, for backwards compatibility purposes. TODO: Remove after the app has been force updated */
  'categories'?: Array<CategoryMembership>,
  'variants': Array<ProductVariant>,
  'options': Array<ProductOptionSet>,
  /** Whether this product has any variants that are on an APL */
  'onApl'?: boolean,
};
export type EncodedProduct = EncodedBaseProduct & {
  'id': number,
  'links': EncodedProductLinks,
  'categories'?: Array<EncodedCategoryMembership>,
  'variants': Array<EncodedProductVariant>,
  'options': Array<EncodedProductOptionSet>,
  'onApl'?: boolean,
};
export const Product: t.Type<Product, EncodedProduct> = t.recursion('Product', () => (
  t.intersection([
    BaseProduct,
    t.exact(t.intersection([t.type({
      'id': t.number,
      'links': ProductLinks,
      'variants': t.array(ProductVariant),
      'options': t.array(ProductOptionSet),
    }), t.partial({
      'categories': t.array(CategoryMembership),
      'onApl': t.boolean,
    })]), 'Product')
  ], 'Product')
));


export type ProductOptionSetInput = {
  'name': string,
  'values': Array<string>,
  'id'?: number,
};
export type EncodedProductOptionSetInput = {
  'name': string,
  'values': Array<string>,
  'id'?: number,
};
export const ProductOptionSetInput: t.Type<ProductOptionSetInput, EncodedProductOptionSetInput> = t.recursion('ProductOptionSetInput', () => (
  t.exact(t.intersection([t.type({
    'name': t.string,
    'values': t.array(t.string),
  }), t.partial({
    'id': t.number,
  })]), 'ProductOptionSetInput')
));


export type ProductOptionSet = ProductOptionSetInput & {
  'id': number,
  'position': number,
};
export type EncodedProductOptionSet = EncodedProductOptionSetInput & {
  'id': number,
  'position': number,
};
export const ProductOptionSet: t.Type<ProductOptionSet, EncodedProductOptionSet> = t.recursion('ProductOptionSet', () => (
  t.intersection([
    ProductOptionSetInput,
    t.exact(t.intersection([t.type({
      'id': t.number,
      'position': t.number,
    }), t.partial({
      
    })]), 'ProductOptionSet')
  ], 'ProductOptionSet')
));


/** State machine for category & its listing status */
export type CategoryStatus = CodegenEnumOpenValues<'draft' | 'available' | 'deleted' | 'archived'>;
export type EncodedCategoryStatus = string;
export const CategoryStatus = CodegenEnumOpen({
  'draft': null,
  'available': null,
  'deleted': null,
  'archived': null,
}, 'CategoryStatus');
export const CategoryStatusValues = ['draft', 'available', 'deleted', 'archived'] as const;

/** An id of the external standard of categories we are replicating in our DB */
export type CategoryExternalTaxonomyId = CodegenEnumOpenValues<'gs1' | 'nacs'>;
export type EncodedCategoryExternalTaxonomyId = string;
export const CategoryExternalTaxonomyId = CodegenEnumOpen({
  'gs1': null,
  'nacs': null,
}, 'CategoryExternalTaxonomyId');
export const CategoryExternalTaxonomyIdValues = ['gs1', 'nacs'] as const;

export type CategoryInput = {
  'name': string,
  'slug': Slug,
  /** The specific seller this category should be associated with / limited to. */
  'sellerId'?: number,
  /** Is this category highlighted in the marketplace top navigation? */
  'navigable'?: boolean,
  /** Can this category appear in search suggestions? */
  'showInSearchSuggestions'?: boolean,
  /** The order index of the category appearing on the homepage. 0 means it does not appear. */
  'homepageIndex'?: number,
  /** Presidence of this category appearing in related sections. Currently only the PDP. 0 means it should not appear. */
  'displayPriority'?: number,
  'image'?: Image,
  'status'?: CategoryStatus,
  /** Is this category in a featured category list. */
  'featured'?: boolean,
  'childMemberships'?: Array<{
    'childCategoryId': number,
    'navigableIndex': number,
  }>,
  'parentMemberships'?: Array<{
    'parentCategoryId': number,
    'navigableIndex': number,
  }>,
  /** If true, child categories are allowed. */
  'canContainCategories'?: boolean,
  /** If true, products can be associated with this category. e.g. Categories meant for top level navigation or for sellers only might disallow products. */
  'canContainProducts'?: boolean,
  /** If true, sellers can be associated with this category. */
  'canContainSellers'?: boolean,
  /** Description of this category, meant for display of an editorial collection. */
  'description'?: string,
  /** Whether this category should be displayed as 'curated collection', meaning that we group the products under brands. */
  'isCuratedCollection'?: boolean,
  /** If `false`, there are more restrictions on who can view the details & contents of this category. */
  'isPublic'?: boolean,
  /** If `true`, users are allowed to export the data in this category, e.g. to a CSV file */
  'allowExport'?: boolean,
  /** If `true`, users are allowed to provide feedback to this category, e.g. on the proposed product list page */
  'allowFeedback'?: boolean,
  'externalTaxonomyId'?: null | CategoryExternalTaxonomyId,
  'externalTaxonomyCategoryId'?: null | string,
};
export type EncodedCategoryInput = {
  'name': string,
  'slug': string,
  'sellerId'?: number,
  'navigable'?: boolean,
  'showInSearchSuggestions'?: boolean,
  'homepageIndex'?: number,
  'displayPriority'?: number,
  'image'?: EncodedImage,
  'status'?: EncodedCategoryStatus,
  'featured'?: boolean,
  'childMemberships'?: Array<{
    'childCategoryId': number,
    'navigableIndex': number,
  }>,
  'parentMemberships'?: Array<{
    'parentCategoryId': number,
    'navigableIndex': number,
  }>,
  'canContainCategories'?: boolean,
  'canContainProducts'?: boolean,
  'canContainSellers'?: boolean,
  'description'?: string,
  'isCuratedCollection'?: boolean,
  'isPublic'?: boolean,
  'allowExport'?: boolean,
  'allowFeedback'?: boolean,
  'externalTaxonomyId'?: null | EncodedCategoryExternalTaxonomyId,
  'externalTaxonomyCategoryId'?: null | string,
};
export const CategoryInput: t.Type<CategoryInput, EncodedCategoryInput> = t.recursion('CategoryInput', () => (
  t.exact(t.intersection([t.type({
    'name': t.string,
    'slug': Slug,
  }), t.partial({
    'sellerId': t.number,
    'navigable': t.boolean,
    'showInSearchSuggestions': t.boolean,
    'homepageIndex': t.number,
    'displayPriority': t.number,
    'image': Image,
    'status': CategoryStatus,
    'featured': t.boolean,
    'childMemberships': t.array(t.exact(t.intersection([t.type({
      'childCategoryId': t.number,
      'navigableIndex': t.number,
    }), t.partial({
      
    })]), 'CategoryInputItem')),
    'parentMemberships': t.array(t.exact(t.intersection([t.type({
      'parentCategoryId': t.number,
      'navigableIndex': t.number,
    }), t.partial({
      
    })]), 'CategoryInputItem')),
    'canContainCategories': t.boolean,
    'canContainProducts': t.boolean,
    'canContainSellers': t.boolean,
    'description': t.string,
    'isCuratedCollection': t.boolean,
    'isPublic': t.boolean,
    'allowExport': t.boolean,
    'allowFeedback': t.boolean,
    'externalTaxonomyId': t.union([t.null, CategoryExternalTaxonomyId]),
    'externalTaxonomyCategoryId': t.union([t.null, t.string]),
  })]), 'CategoryInput')
));


export type CategoryInputBulk = CategoryInput & {
  /** When bulk creating categories, this property can be used to associate parent categories with the categories being created */
  'parentExternalTaxonomyCategoryId'?: null | string,
};
export type EncodedCategoryInputBulk = EncodedCategoryInput & {
  'parentExternalTaxonomyCategoryId'?: null | string,
};
export const CategoryInputBulk: t.Type<CategoryInputBulk, EncodedCategoryInputBulk> = t.recursion('CategoryInputBulk', () => (
  t.intersection([
    CategoryInput,
    t.exact(t.intersection([t.type({
      
    }), t.partial({
      'parentExternalTaxonomyCategoryId': t.union([t.null, t.string]),
    })]), 'CategoryInputBulk')
  ], 'CategoryInputBulk')
));


export type Category = CategoryInput & {
  'id': number,
  'productCount'?: number,
  'sellerCount'?: number,
};
export type EncodedCategory = EncodedCategoryInput & {
  'id': number,
  'productCount'?: number,
  'sellerCount'?: number,
};
export const Category: t.Type<Category, EncodedCategory> = t.recursion('Category', () => (
  t.intersection([
    CategoryInput,
    t.exact(t.intersection([t.type({
      'id': t.number,
    }), t.partial({
      'productCount': t.number,
      'sellerCount': t.number,
    })]), 'Category')
  ], 'Category')
));


export type CategoryDisplayInfo = Category & {
  'variants'?: Array<ProductVariantListDisplayInfo>,
};
export type EncodedCategoryDisplayInfo = EncodedCategory & {
  'variants'?: Array<EncodedProductVariantListDisplayInfo>,
};
export const CategoryDisplayInfo: t.Type<CategoryDisplayInfo, EncodedCategoryDisplayInfo> = t.recursion('CategoryDisplayInfo', () => (
  t.intersection([
    Category,
    t.exact(t.intersection([t.type({
      
    }), t.partial({
      'variants': t.array(ProductVariantListDisplayInfo),
    })]), 'CategoryDisplayInfo')
  ], 'CategoryDisplayInfo')
));


export type CategoryMembershipInput = {
  'categoryId': number,
  'isPrimary'?: boolean,
  'featuredIndex'?: number,
};
export type EncodedCategoryMembershipInput = {
  'categoryId': number,
  'isPrimary'?: boolean,
  'featuredIndex'?: number,
};
export const CategoryMembershipInput: t.Type<CategoryMembershipInput, EncodedCategoryMembershipInput> = t.recursion('CategoryMembershipInput', () => (
  t.exact(t.intersection([t.type({
    'categoryId': t.number,
  }), t.partial({
    'isPrimary': t.boolean,
    'featuredIndex': t.number,
  })]), 'CategoryMembershipInput')
));


export type CategoryMembership = Category & {
  'isPrimary': boolean,
  'featuredIndex': number,
  'navigable'?: boolean,
  'isValue'?: boolean,
  'isDiet'?: boolean,
  'isCuratedCollection'?: boolean,
};
export type EncodedCategoryMembership = EncodedCategory & {
  'isPrimary': boolean,
  'featuredIndex': number,
  'navigable'?: boolean,
  'isValue'?: boolean,
  'isDiet'?: boolean,
  'isCuratedCollection'?: boolean,
};
export const CategoryMembership: t.Type<CategoryMembership, EncodedCategoryMembership> = t.recursion('CategoryMembership', () => (
  t.intersection([
    Category,
    t.exact(t.intersection([t.type({
      'isPrimary': t.boolean,
      'featuredIndex': t.number,
    }), t.partial({
      'navigable': t.boolean,
      'isValue': t.boolean,
      'isDiet': t.boolean,
      'isCuratedCollection': t.boolean,
    })]), 'CategoryMembership')
  ], 'CategoryMembership')
));


export type FullCategory = {
  'category': Category,
  /** Only included if requested with `include`. */
  'variants'?: Array<ProductVariantListDisplayInfo>,
  /** Only included if requested with `include`. */
  'sellers'?: Array<SellerDisplayInfo>,
  /** Only included if requested with `include`. */
  'popularBrands'?: Array<SellerDisplayInfo>,
  'variantCount'?: number,
  'sellerCount'?: number,
  /** Only include if requested with `include`. */
  'childCategories'?: Array<FullCategory>,
  /** Only included if requested with `include`. */
  'parentCategory'?: Category,
  /** Only included if requested with `include`. */
  'siblingCategories'?: Array<Category>,
  'filters'?: Array<FilterGroup>,
  'sortMethods'?: Array<SearchSortMethod>,
  /** A list of regions that pertain to this category */
  'regions'?: Array<RegionSlug>,
  'showSearchFilterCards'?: boolean,
};
export type EncodedFullCategory = {
  'category': EncodedCategory,
  'variants'?: Array<EncodedProductVariantListDisplayInfo>,
  'sellers'?: Array<EncodedSellerDisplayInfo>,
  'popularBrands'?: Array<EncodedSellerDisplayInfo>,
  'variantCount'?: number,
  'sellerCount'?: number,
  'childCategories'?: Array<EncodedFullCategory>,
  'parentCategory'?: EncodedCategory,
  'siblingCategories'?: Array<EncodedCategory>,
  'filters'?: Array<EncodedFilterGroup>,
  'sortMethods'?: Array<EncodedSearchSortMethod>,
  'regions'?: Array<EncodedRegionSlug>,
  'showSearchFilterCards'?: boolean,
};
export const FullCategory: t.Type<FullCategory, EncodedFullCategory> = t.recursion('FullCategory', () => (
  t.exact(t.intersection([t.type({
    'category': Category,
  }), t.partial({
    'variants': t.array(ProductVariantListDisplayInfo),
    'sellers': t.array(SellerDisplayInfo),
    'popularBrands': t.array(SellerDisplayInfo),
    'variantCount': t.number,
    'sellerCount': t.number,
    'childCategories': t.array(FullCategory),
    'parentCategory': Category,
    'siblingCategories': t.array(Category),
    'filters': t.array(FilterGroup),
    'sortMethods': t.array(SearchSortMethod),
    'regions': t.array(RegionSlug),
    'showSearchFilterCards': t.boolean,
  })]), 'FullCategory')
));


export type ProductSizeUnit = CodegenEnumOpenValues<'oz' | 'lb' | 'floz' | 'ml' | 'l' | 'gal' | 'pt' | 'qt' | 'g' | 'kg' | 'ct'>;
export type EncodedProductSizeUnit = string;
export const ProductSizeUnit = CodegenEnumOpen({
  'oz': null,
  'lb': null,
  'floz': null,
  'ml': null,
  'l': null,
  'gal': null,
  'pt': null,
  'qt': null,
  'g': null,
  'kg': null,
  'ct': null,
}, 'ProductSizeUnit');
export const ProductSizeUnitValues = ['oz', 'lb', 'floz', 'ml', 'l', 'gal', 'pt', 'qt', 'g', 'kg', 'ct'] as const;

/** Enum describing availability of product variant, with regards to ability to purchase. */
export type ProductAvailability = CodegenEnumOpenValues<'inStock' | 'outOfStock' | 'lowStock' | 'comingSoon' | 'backordered'>;
export type EncodedProductAvailability = string;
export const ProductAvailability = CodegenEnumOpen({
  'inStock': null,
  'outOfStock': null,
  'lowStock': null,
  'comingSoon': null,
  'backordered': null,
}, 'ProductAvailability');
export const ProductAvailabilityValues = ['inStock', 'outOfStock', 'lowStock', 'comingSoon', 'backordered'] as const;

/** The different ways a product could be stored, affecting shelf life. */
export type ProductStorageType = CodegenEnumOpenValues<'ambient' | 'refrigerate' | 'frozen'>;
export type EncodedProductStorageType = string;
export const ProductStorageType = CodegenEnumOpen({
  'ambient': null,
  'refrigerate': null,
  'frozen': null,
}, 'ProductStorageType');
export const ProductStorageTypeValues = ['ambient', 'refrigerate', 'frozen'] as const;

/** Shelf life & storage information */
export type ProductStorage = {
  'storageType': ProductStorageType,
  'shelfLifeInDays': number,
  'shelfLifeGuaranteedInDays'?: number,
};
export type EncodedProductStorage = {
  'storageType': EncodedProductStorageType,
  'shelfLifeInDays': number,
  'shelfLifeGuaranteedInDays'?: number,
};
export const ProductStorage: t.Type<ProductStorage, EncodedProductStorage> = t.recursion('ProductStorage', () => (
  t.exact(t.intersection([t.type({
    'storageType': ProductStorageType,
    'shelfLifeInDays': t.number,
  }), t.partial({
    'shelfLifeGuaranteedInDays': t.number,
  })]), 'ProductStorage')
));


/** The type or external system this identifier is for */
export type ProductVariantIdentifierType = CodegenEnumOpenValues<'mableSku' | 'mclaneUin' | 'usFoodsApn'>;
export type EncodedProductVariantIdentifierType = string;
export const ProductVariantIdentifierType = CodegenEnumOpen({
  'mableSku': null,
  'mclaneUin': null,
  'usFoodsApn': null,
}, 'ProductVariantIdentifierType');
export const ProductVariantIdentifierTypeValues = ['mableSku', 'mclaneUin', 'usFoodsApn'] as const;

/** Object used to create a ProductVariantIdentifier */
export type ProductVariantIdentifierInput = {
  'type': ProductVariantIdentifierType,
  'identifier': string,
  'isPrimary'?: boolean,
};
export type EncodedProductVariantIdentifierInput = {
  'type': EncodedProductVariantIdentifierType,
  'identifier': string,
  'isPrimary'?: boolean,
};
export const ProductVariantIdentifierInput: t.Type<ProductVariantIdentifierInput, EncodedProductVariantIdentifierInput> = t.recursion('ProductVariantIdentifierInput', () => (
  t.exact(t.intersection([t.type({
    'type': ProductVariantIdentifierType,
    'identifier': t.string,
  }), t.partial({
    'isPrimary': t.boolean,
  })]), 'ProductVariantIdentifierInput')
));


/** Object used to create a ProductVariantIdentifier using a Mable SKU to identify the variant */
export type ProductVariantDataImporterInput = {
  /** What type of identifier to use when looking up the variant this new identifier should be associated with */
  'lookupType': CodegenEnumOpenValues<'mableSku'>,
  /** The value of the identifier used to lookup the variant this new identifier should be associated with */
  'lookupValue': string,
  'type': ProductVariantIdentifierType,
  /** additional identifier for the variant */
  'identifier': string,
  'isPrimary'?: boolean,
};
export type EncodedProductVariantDataImporterInput = {
  'lookupType': string,
  'lookupValue': string,
  'type': EncodedProductVariantIdentifierType,
  'identifier': string,
  'isPrimary'?: boolean,
};
export const ProductVariantDataImporterInput: t.Type<ProductVariantDataImporterInput, EncodedProductVariantDataImporterInput> = t.recursion('ProductVariantDataImporterInput', () => (
  t.exact(t.intersection([t.type({
    'lookupType': CodegenEnumOpen({
      'mableSku': null,
    }, 'ProductVariantDataImporterInput'),
    'lookupValue': t.string,
    'type': ProductVariantIdentifierType,
    'identifier': t.string,
  }), t.partial({
    'isPrimary': t.boolean,
  })]), 'ProductVariantDataImporterInput')
));


export type ProductVariantIdentifier = ProductVariantIdentifierInput & {
  'id': number,
  'productVariantId': number,
  'updatedAt': Date,
  'createdAt': Date,
  'deletedAt'?: Date,
};
export type EncodedProductVariantIdentifier = EncodedProductVariantIdentifierInput & {
  'id': number,
  'productVariantId': number,
  'updatedAt': string,
  'createdAt': string,
  'deletedAt'?: string,
};
export const ProductVariantIdentifier: t.Type<ProductVariantIdentifier, EncodedProductVariantIdentifier> = t.recursion('ProductVariantIdentifier', () => (
  t.intersection([
    ProductVariantIdentifierInput,
    t.exact(t.intersection([t.type({
      'id': t.number,
      'productVariantId': t.number,
      'updatedAt': DateFromISOString,
      'createdAt': DateFromISOString,
    }), t.partial({
      'deletedAt': DateFromISOString,
    })]), 'ProductVariantIdentifier')
  ], 'ProductVariantIdentifier')
));


/** Additional internal & external identifiers for a product variant, fit for display within a ProductVariantListDisplayInfo or ProductVariantDisplayInfo */
export type ProductVariantIdentifierDisplayInfo = {
  'type': ProductVariantIdentifierType,
  /** User-friendly name of the type to display */
  'typeName': string,
  'identifier': string,
  'isPrimary'?: boolean,
};
export type EncodedProductVariantIdentifierDisplayInfo = {
  'type': EncodedProductVariantIdentifierType,
  'typeName': string,
  'identifier': string,
  'isPrimary'?: boolean,
};
export const ProductVariantIdentifierDisplayInfo: t.Type<ProductVariantIdentifierDisplayInfo, EncodedProductVariantIdentifierDisplayInfo> = t.recursion('ProductVariantIdentifierDisplayInfo', () => (
  t.exact(t.intersection([t.type({
    'type': ProductVariantIdentifierType,
    'typeName': t.string,
    'identifier': t.string,
  }), t.partial({
    'isPrimary': t.boolean,
  })]), 'ProductVariantIdentifierDisplayInfo')
));


export type BaseProductVariant = {
  'id'?: number,
  'updatedAt'?: Date,
  'createdAt'?: Date,
  /** Stock Keeping Unit */
  'sku'?: string,
  /** Mable Specific Stock Keeping Unit */
  'mableSku'?: string,
  /** A generic home for IDs from 3rd party systems the seller may already be using, or that we may import from. e.g. Square. */
  'externalId'?: string,
  /** The price buyers will pay on Mable's products. In USD. e.g. '1.23' */
  'price'?: string,
  /** Suggested Retail Price (SRP). In USD, e.g. '9.87' */
  'priceRetail'?: string,
  /** The stated value of the product direct-to-customer */
  'wholesalePrice'?: BigNumber,
  'isLandedCost'?: boolean,
  'images'?: Array<ProductImage>,
  /** Mapping of ProductOptionSet name to ProductOptionValue name for this variant */
  'options'?: Record<string, string>,
  /** The number of individual items included in this product. e.g. 12 items per case. */
  'eachCount'?: number,
  /** Numeric size of an individual item included in this product. For display purposes. */
  'eachSize'?: BigNumber,
  /** Units of the size of an individual item included in this product. */
  'eachSizeUnit'?: ProductSizeUnit,
  /** Name for the unit of items. e.g. "Can" */
  'eachName'?: string,
  /** Plural name for the unit of items. e.g. "Cans" */
  'eachNamePlural'?: string,
  /** The number of items included in the each. e.g. 12 slices per pack of bacon. */
  'eachPieceCount'?: number,
  /** Name for the unit of the items included in the each. e.g. "Slice" */
  'eachPieceName'?: string,
  /** Plural name for the unit of the items included in the each. e.g. "Slices" */
  'eachPieceNamePlural'?: string,
  /** Name for the case/container of items. e.g. "Tray, Crate" */
  'caseName'?: string,
  /** Admin-only notes + memos, possibly also used for storing catch-all properties */
  'memo'?: string,
  /** Description of the product variant, to be displayed to the user. */
  'description'?: string,
  /** Description of the product variant that was imported from an external source. */
  'supplementalDescription'?: string,
  /** List of ingredients in the product variant. */
  'ingredients'?: string,
  /** Deprecated; List of comma separated allergens */
  'allergens'?: string,
  /** List of allergens objects */
  'allergensList'?: Array<Allergen>,
  'availability'?: ProductAvailability,
  'status'?: ProductStatus,
  /** The variant's shelf life. (e.g. 2 months, 1-3 days, 42 years) */
  'shelfLife'?: string,
  'storage'?: Array<ProductStorage>,
  /** The price of the variant when not on sale */
  'offSalePrice'?: BigNumber,
  'extraData'?: Record<string, unknown>,
  'backInStockDate'?: Date,
  /** Foreign key for the external id that was used to supplement the data, if any. */
  'externalProductId'?: number,
  'packagings'?: Array<ProductVariantPackagingWithId>,
  /** This variant is incapable of having a UPC and should be exempt from any logic requiring a variant to have one. */
  'upcExempt'?: boolean,
  /** This variant is incapable of having ingredients and should be exempt from any logic requiring a variant to have them. */
  'ingredientsExempt'?: boolean,
  'ingredientsOnFTLList'?: boolean,
  'isShipper'?: boolean,
  'isVarietyPack'?: boolean,
  'retailUnitId'?: number,
};
export type EncodedBaseProductVariant = {
  'id'?: number,
  'updatedAt'?: string,
  'createdAt'?: string,
  'sku'?: string,
  'mableSku'?: string,
  'externalId'?: string,
  'price'?: string,
  'priceRetail'?: string,
  'wholesalePrice'?: string,
  'isLandedCost'?: boolean,
  'images'?: Array<EncodedProductImage>,
  'options'?: Record<string, string>,
  'eachCount'?: number,
  'eachSize'?: string,
  'eachSizeUnit'?: EncodedProductSizeUnit,
  'eachName'?: string,
  'eachNamePlural'?: string,
  'eachPieceCount'?: number,
  'eachPieceName'?: string,
  'eachPieceNamePlural'?: string,
  'caseName'?: string,
  'memo'?: string,
  'description'?: string,
  'supplementalDescription'?: string,
  'ingredients'?: string,
  'allergens'?: string,
  'allergensList'?: Array<EncodedAllergen>,
  'availability'?: EncodedProductAvailability,
  'status'?: EncodedProductStatus,
  'shelfLife'?: string,
  'storage'?: Array<EncodedProductStorage>,
  'offSalePrice'?: string,
  'extraData'?: Record<string, unknown>,
  'backInStockDate'?: string,
  'externalProductId'?: number,
  'packagings'?: Array<EncodedProductVariantPackagingWithId>,
  'upcExempt'?: boolean,
  'ingredientsExempt'?: boolean,
  'ingredientsOnFTLList'?: boolean,
  'isShipper'?: boolean,
  'isVarietyPack'?: boolean,
  'retailUnitId'?: number,
};
export const BaseProductVariant: t.Type<BaseProductVariant, EncodedBaseProductVariant> = t.recursion('BaseProductVariant', () => (
  t.exact(t.intersection([t.type({
    
  }), t.partial({
    'id': t.number,
    'updatedAt': DateFromISOString,
    'createdAt': DateFromISOString,
    'sku': t.string,
    'mableSku': t.string,
    'externalId': t.string,
    'price': t.string,
    'priceRetail': t.string,
    'wholesalePrice': NumberFromString,
    'isLandedCost': t.boolean,
    'images': t.array(ProductImage),
    'options': t.record(t.string, t.string),
    'eachCount': t.number,
    'eachSize': NumberFromString,
    'eachSizeUnit': ProductSizeUnit,
    'eachName': t.string,
    'eachNamePlural': t.string,
    'eachPieceCount': t.number,
    'eachPieceName': t.string,
    'eachPieceNamePlural': t.string,
    'caseName': t.string,
    'memo': t.string,
    'description': t.string,
    'supplementalDescription': t.string,
    'ingredients': t.string,
    'allergens': t.string,
    'allergensList': t.array(Allergen),
    'availability': ProductAvailability,
    'status': ProductStatus,
    'shelfLife': t.string,
    'storage': t.array(ProductStorage),
    'offSalePrice': NumberFromString,
    'extraData': t.record(t.string, t.unknown),
    'backInStockDate': DateFromISOString,
    'externalProductId': t.number,
    'packagings': t.array(ProductVariantPackagingWithId),
    'upcExempt': t.boolean,
    'ingredientsExempt': t.boolean,
    'ingredientsOnFTLList': t.boolean,
    'isShipper': t.boolean,
    'isVarietyPack': t.boolean,
    'retailUnitId': t.number,
  })]), 'BaseProductVariant')
));


export type ProductVariantInput = BaseProductVariant & {
  /** Image URLs that the backend will attempt to download and saved to our CDN for this vaiant */
  'imageUrls'?: Array<string>,
  'categoryMemberships'?: Array<CategoryMembershipInput>,
  /** Unqiue category slugs which will be attempted to be found in our DB. */
  'categorySlugs'?: Array<Slug>,
  /** Brand name to be used for within seller filters. Used for distributors that carry multiple brands. */
  'brandName'?: string,
  /** Human readable names of categories that will appear only for this seller. */
  'sellerDefinedCategoryNames'?: Array<string>,
  /** Human readable names of collections that will appear only for this seller. They will be created if they do not exist. These categories will be associated with all variants of this product. */
  'sellerDefinedCollectionNames'?: Array<string>,
  /** The ordered position of the variant in relation to other variants in this product */
  'position'?: number,
  'gtin'?: Gtin,
  'caseGtin'?: Gtin,
};
export type EncodedProductVariantInput = EncodedBaseProductVariant & {
  'imageUrls'?: Array<string>,
  'categoryMemberships'?: Array<EncodedCategoryMembershipInput>,
  'categorySlugs'?: Array<string>,
  'brandName'?: string,
  'sellerDefinedCategoryNames'?: Array<string>,
  'sellerDefinedCollectionNames'?: Array<string>,
  'position'?: number,
  'gtin'?: string,
  'caseGtin'?: string,
};
export const ProductVariantInput: t.Type<ProductVariantInput, EncodedProductVariantInput> = t.recursion('ProductVariantInput', () => (
  t.intersection([
    BaseProductVariant,
    t.exact(t.intersection([t.type({
      
    }), t.partial({
      'imageUrls': t.array(t.string),
      'categoryMemberships': t.array(CategoryMembershipInput),
      'categorySlugs': t.array(Slug),
      'brandName': t.string,
      'sellerDefinedCategoryNames': t.array(t.string),
      'sellerDefinedCollectionNames': t.array(t.string),
      'position': t.number,
      'gtin': Gtin,
      'caseGtin': Gtin,
    })]), 'ProductVariantInput')
  ], 'ProductVariantInput')
));


export type ProductVariant = BaseProductVariant & {
  'name': string,
  'slug': string,
  /** Unit price for item included in this product. i.e. price / eachCount */
  'pricePerEach'?: string,
  'id': number,
  /** The categories this variant is associated with. */
  'categories'?: Array<CategoryMembership>,
  /** This is a list of tags that might be applied to a particular variant's display. The array is in priority order, and the clients should pick the first tag in the list to display */
  'tags'?: Array<DisplayVariantTag>,
  /** An array of the special lists this variant belongs to, unique to the current user. */
  'lists'?: Array<ListMembership>,
  'purchasable': VariantPurchasableStatus,
  /** Additional identifiers for this variant */
  'identifiers'?: Array<ProductVariantIdentifierDisplayInfo>,
  /** The status of the variant's sync with the external system */
  'syncStatus'?: {
    'oneWorldSync'?: Array<OneWorldSyncStatus>,
  },
  /** The reasons why editing is disabled for this variant */
  'editingDisabledReasons'?: Array<VariantEditingDisabledReason>,
  /** Global Trade Item Number; Barcode/ISBN/UPC for the unit */
  'gtin'?: Gtin,
  /** Global Trade Item Number; Barcode/ISBN/UPC for the case */
  'caseGtin'?: Gtin,
  /** The packaging level that this variant represents */
  'packagingLevel'?: ProductVariantPackagingLevel,
  /** The id of the packaging level that this variant represents */
  'unitPackagingId': number,
  'sellerId': number,
};
export type EncodedProductVariant = EncodedBaseProductVariant & {
  'name': string,
  'slug': string,
  'pricePerEach'?: string,
  'id': number,
  'categories'?: Array<EncodedCategoryMembership>,
  'tags'?: Array<EncodedDisplayVariantTag>,
  'lists'?: Array<EncodedListMembership>,
  'purchasable': EncodedVariantPurchasableStatus,
  'identifiers'?: Array<EncodedProductVariantIdentifierDisplayInfo>,
  'syncStatus'?: {
    'oneWorldSync'?: Array<EncodedOneWorldSyncStatus>,
  },
  'editingDisabledReasons'?: Array<EncodedVariantEditingDisabledReason>,
  'gtin'?: string,
  'caseGtin'?: string,
  'packagingLevel'?: EncodedProductVariantPackagingLevel,
  'unitPackagingId': number,
  'sellerId': number,
};
export const ProductVariant: t.Type<ProductVariant, EncodedProductVariant> = t.recursion('ProductVariant', () => (
  t.intersection([
    BaseProductVariant,
    t.exact(t.intersection([t.type({
      'name': t.string,
      'slug': t.string,
      'id': t.number,
      'purchasable': VariantPurchasableStatus,
      'unitPackagingId': t.number,
      'sellerId': t.number,
    }), t.partial({
      'pricePerEach': t.string,
      'categories': t.array(CategoryMembership),
      'tags': t.array(DisplayVariantTag),
      'lists': t.array(ListMembership),
      'identifiers': t.array(ProductVariantIdentifierDisplayInfo),
      'syncStatus': t.exact(t.intersection([t.type({
        
      }), t.partial({
        'oneWorldSync': t.array(OneWorldSyncStatus),
      })]), 'ProductVariant'),
      'editingDisabledReasons': t.array(VariantEditingDisabledReason),
      'gtin': Gtin,
      'caseGtin': Gtin,
      'packagingLevel': ProductVariantPackagingLevel,
    })]), 'ProductVariant')
  ], 'ProductVariant')
));


/** Abbreviated product variant information, sufficient for display of a product variant in lists & search results. */
export type ProductVariantListDisplayInfo = {
  'name': string,
  'price'?: string,
  'isLandedCost'?: boolean,
  'imageUrl'?: string,
  'image'?: ProductImage,
  'links': ProductLinks,
  'availability': ProductAvailability,
  /** e.g. "Case of 12 Bottles" */
  'caseOfEachCount'?: string,
  'variantId': number,
  'sellerName': string,
  'sellerSlug': Slug,
  'sellerState'?: string,
  'sellerOrderMinimum'?: BigNumber,
  'sellerFreeShippingMinimum'?: BigNumber,
  'sku'?: string,
  'gtin'?: string,
  'caseGtin'?: string,
  'sellerId': number,
  'productId': number,
  'eachCount'?: number,
  'eachSize'?: BigNumber,
  'eachSizeUnit'?: ProductSizeUnit,
  'eachName'?: string,
  'eachNamePlural'?: string,
  'eachPieceCount'?: number,
  'eachPieceName'?: string,
  'eachPieceNamePlural'?: string,
  'pricePerEach'?: string,
  'priceRetail'?: string,
  /** The stated value of the product direct-to-customer */
  'wholesalePrice'?: BigNumber,
  /** The variant's shelf life. (e.g. 2 months, 1-3 days, 42 years) */
  'shelfLife'?: string,
  /** The regular price for this variant outside of any sale or promotion */
  'offSalePrice'?: BigNumber,
  'backInStockDate'?: Date,
  'lastOrderedAt'?: Date,
  'lastOrderedQuantity'?: BigNumber,
  'mableSku'?: string,
  'identifiers'?: Array<ProductVariantIdentifierDisplayInfo>,
  /** This is a list of tags that might be applied to a particular variant's display. The array is in priority order, and the clients should pick the first they understand tag in the list to display */
  'tags'?: Array<DisplayVariantTag>,
  'productPosition'?: number,
  'position'?: number,
  'shippingPolicy': ShippingPolicy,
  /** An array of the special lists this variant belongs to, unique to the current user. */
  'lists'?: Array<ListMembership>,
  'packagings'?: Array<ProductVariantPackaging>,
  'upcExempt'?: boolean,
  'ingredientsExempt'?: boolean,
  'exclusiveProduct'?: boolean,
  'purchasable': VariantPurchasableStatus,
  /** The reasons why editing is disabled for this variant */
  'editingDisabledReasons'?: Array<VariantEditingDisabledReason>,
  /** If this brand is available on the public, non-partnered Mable marketplace at meetmable.com. */
  'mableMarketplaceEnabled'?: boolean,
};
export type EncodedProductVariantListDisplayInfo = {
  'name': string,
  'price'?: string,
  'isLandedCost'?: boolean,
  'imageUrl'?: string,
  'image'?: EncodedProductImage,
  'links': EncodedProductLinks,
  'availability': EncodedProductAvailability,
  'caseOfEachCount'?: string,
  'variantId': number,
  'sellerName': string,
  'sellerSlug': string,
  'sellerState'?: string,
  'sellerOrderMinimum'?: string,
  'sellerFreeShippingMinimum'?: string,
  'sku'?: string,
  'gtin'?: string,
  'caseGtin'?: string,
  'sellerId': number,
  'productId': number,
  'eachCount'?: number,
  'eachSize'?: string,
  'eachSizeUnit'?: EncodedProductSizeUnit,
  'eachName'?: string,
  'eachNamePlural'?: string,
  'eachPieceCount'?: number,
  'eachPieceName'?: string,
  'eachPieceNamePlural'?: string,
  'pricePerEach'?: string,
  'priceRetail'?: string,
  'wholesalePrice'?: string,
  'shelfLife'?: string,
  'offSalePrice'?: string,
  'backInStockDate'?: string,
  'lastOrderedAt'?: string,
  'lastOrderedQuantity'?: string,
  'mableSku'?: string,
  'identifiers'?: Array<EncodedProductVariantIdentifierDisplayInfo>,
  'tags'?: Array<EncodedDisplayVariantTag>,
  'productPosition'?: number,
  'position'?: number,
  'shippingPolicy': EncodedShippingPolicy,
  'lists'?: Array<EncodedListMembership>,
  'packagings'?: Array<EncodedProductVariantPackaging>,
  'upcExempt'?: boolean,
  'ingredientsExempt'?: boolean,
  'exclusiveProduct'?: boolean,
  'purchasable': EncodedVariantPurchasableStatus,
  'editingDisabledReasons'?: Array<EncodedVariantEditingDisabledReason>,
  'mableMarketplaceEnabled'?: boolean,
};
export const ProductVariantListDisplayInfo: t.Type<ProductVariantListDisplayInfo, EncodedProductVariantListDisplayInfo> = t.recursion('ProductVariantListDisplayInfo', () => (
  t.exact(t.intersection([t.type({
    'name': t.string,
    'links': ProductLinks,
    'availability': ProductAvailability,
    'variantId': t.number,
    'sellerName': t.string,
    'sellerSlug': Slug,
    'sellerId': t.number,
    'productId': t.number,
    'shippingPolicy': ShippingPolicy,
    'purchasable': VariantPurchasableStatus,
  }), t.partial({
    'price': t.string,
    'isLandedCost': t.boolean,
    'imageUrl': t.string,
    'image': ProductImage,
    'caseOfEachCount': t.string,
    'sellerState': t.string,
    'sellerOrderMinimum': NumberFromString,
    'sellerFreeShippingMinimum': NumberFromString,
    'sku': t.string,
    'gtin': t.string,
    'caseGtin': t.string,
    'eachCount': t.number,
    'eachSize': NumberFromString,
    'eachSizeUnit': ProductSizeUnit,
    'eachName': t.string,
    'eachNamePlural': t.string,
    'eachPieceCount': t.number,
    'eachPieceName': t.string,
    'eachPieceNamePlural': t.string,
    'pricePerEach': t.string,
    'priceRetail': t.string,
    'wholesalePrice': NumberFromString,
    'shelfLife': t.string,
    'offSalePrice': NumberFromString,
    'backInStockDate': DateFromISOString,
    'lastOrderedAt': DateFromISOString,
    'lastOrderedQuantity': NumberFromString,
    'mableSku': t.string,
    'identifiers': t.array(ProductVariantIdentifierDisplayInfo),
    'tags': t.array(DisplayVariantTag),
    'productPosition': t.number,
    'position': t.number,
    'lists': t.array(ListMembership),
    'packagings': t.array(ProductVariantPackaging),
    'upcExempt': t.boolean,
    'ingredientsExempt': t.boolean,
    'exclusiveProduct': t.boolean,
    'editingDisabledReasons': t.array(VariantEditingDisabledReason),
    'mableMarketplaceEnabled': t.boolean,
  })]), 'ProductVariantListDisplayInfo')
));


/** Product variant info with enough detail for product detail page */
export type ProductVariantDisplayInfo = ProductVariantListDisplayInfo & {
  /** Mapping of ProductOptionSet name to ProductOptionValue name for this variant */
  'options'?: Record<string, string>,
  'images'?: Array<ProductImage>,
  /** The categories this variant is associated with. */
  'categories'?: Array<CategoryMembership>,
  /** List of allergens objects */
  'allergensList'?: Array<Allergen>,
  /** Description of the product variant that was imported from an external source. */
  'supplementalDescription'?: string,
  /** Description of the product variant, to be displayed to the user. */
  'description'?: string,
  /** List of ingredients in the product variant. */
  'ingredients'?: string,
  'packagingsConverted'?: Array<ProductVariantPackagingConverted>,
  'marginPreviews'?: Array<ProductVariantMarginPreview>,
  'isPeggable'?: boolean,
};
export type EncodedProductVariantDisplayInfo = EncodedProductVariantListDisplayInfo & {
  'options'?: Record<string, string>,
  'images'?: Array<EncodedProductImage>,
  'categories'?: Array<EncodedCategoryMembership>,
  'allergensList'?: Array<EncodedAllergen>,
  'supplementalDescription'?: string,
  'description'?: string,
  'ingredients'?: string,
  'packagingsConverted'?: Array<EncodedProductVariantPackagingConverted>,
  'marginPreviews'?: Array<EncodedProductVariantMarginPreview>,
  'isPeggable'?: boolean,
};
export const ProductVariantDisplayInfo: t.Type<ProductVariantDisplayInfo, EncodedProductVariantDisplayInfo> = t.recursion('ProductVariantDisplayInfo', () => (
  t.intersection([
    ProductVariantListDisplayInfo,
    t.exact(t.intersection([t.type({
      
    }), t.partial({
      'options': t.record(t.string, t.string),
      'images': t.array(ProductImage),
      'categories': t.array(CategoryMembership),
      'allergensList': t.array(Allergen),
      'supplementalDescription': t.string,
      'description': t.string,
      'ingredients': t.string,
      'packagingsConverted': t.array(ProductVariantPackagingConverted),
      'marginPreviews': t.array(ProductVariantMarginPreview),
      'isPeggable': t.boolean,
    })]), 'ProductVariantDisplayInfo')
  ], 'ProductVariantDisplayInfo')
));


export type ProductDisplayInfo = BaseProduct & {
  'id': number,
  'links': ProductLinks,
  'variants': Array<ProductVariantDisplayInfo>,
  'options': Array<ProductOptionSet>,
};
export type EncodedProductDisplayInfo = EncodedBaseProduct & {
  'id': number,
  'links': EncodedProductLinks,
  'variants': Array<EncodedProductVariantDisplayInfo>,
  'options': Array<EncodedProductOptionSet>,
};
export const ProductDisplayInfo: t.Type<ProductDisplayInfo, EncodedProductDisplayInfo> = t.recursion('ProductDisplayInfo', () => (
  t.intersection([
    BaseProduct,
    t.exact(t.intersection([t.type({
      'id': t.number,
      'links': ProductLinks,
      'variants': t.array(ProductVariantDisplayInfo),
      'options': t.array(ProductOptionSet),
    }), t.partial({
      
    })]), 'ProductDisplayInfo')
  ], 'ProductDisplayInfo')
));


export type CustomerProductVariantDisplayInfo = {
  'id': number,
  'name': string,
  'productId': number,
  'retailUnitId': number,
  /** e.g. "Case of 12 Bottles" */
  'caseOfEachCount'?: string,
  'availability'?: ProductAvailability,
  'orderCount': number,
  'lastOrderedAt'?: Date,
};
export type EncodedCustomerProductVariantDisplayInfo = {
  'id': number,
  'name': string,
  'productId': number,
  'retailUnitId': number,
  'caseOfEachCount'?: string,
  'availability'?: EncodedProductAvailability,
  'orderCount': number,
  'lastOrderedAt'?: string,
};
export const CustomerProductVariantDisplayInfo: t.Type<CustomerProductVariantDisplayInfo, EncodedCustomerProductVariantDisplayInfo> = t.recursion('CustomerProductVariantDisplayInfo', () => (
  t.exact(t.intersection([t.type({
    'id': t.number,
    'name': t.string,
    'productId': t.number,
    'retailUnitId': t.number,
    'orderCount': t.number,
  }), t.partial({
    'caseOfEachCount': t.string,
    'availability': ProductAvailability,
    'lastOrderedAt': DateFromISOString,
  })]), 'CustomerProductVariantDisplayInfo')
));


export type CSVInput = {
  'csvString': string,
};
export type EncodedCSVInput = {
  'csvString': string,
};
export const CSVInput: t.Type<CSVInput, EncodedCSVInput> = t.recursion('CSVInput', () => (
  t.exact(t.intersection([t.type({
    'csvString': t.string,
  }), t.partial({
    
  })]), 'CSVInput')
));


export type PricingInput = {
  'sellerSlug': string,
  'tiers': Array<PricingTier>,
};
export type EncodedPricingInput = {
  'sellerSlug': string,
  'tiers': Array<EncodedPricingTier>,
};
export const PricingInput: t.Type<PricingInput, EncodedPricingInput> = t.recursion('PricingInput', () => (
  t.exact(t.intersection([t.type({
    'sellerSlug': t.string,
    'tiers': t.array(PricingTier),
  }), t.partial({
    
  })]), 'PricingInput')
));


export type PricingTier = {
  'name': string,
  'customers': Array<string>,
  'variants': Array<PricingTierVariant>,
};
export type EncodedPricingTier = {
  'name': string,
  'customers': Array<string>,
  'variants': Array<EncodedPricingTierVariant>,
};
export const PricingTier: t.Type<PricingTier, EncodedPricingTier> = t.recursion('PricingTier', () => (
  t.exact(t.intersection([t.type({
    'name': t.string,
    'customers': t.array(t.string),
    'variants': t.array(PricingTierVariant),
  }), t.partial({
    
  })]), 'PricingTier')
));


export type PricingTierVariant = {
  'externalId': string,
  'price': string,
};
export type EncodedPricingTierVariant = {
  'externalId': string,
  'price': string,
};
export const PricingTierVariant: t.Type<PricingTierVariant, EncodedPricingTierVariant> = t.recursion('PricingTierVariant', () => (
  t.exact(t.intersection([t.type({
    'externalId': t.string,
    'price': t.string,
  }), t.partial({
    
  })]), 'PricingTierVariant')
));


/** Data representing a product or a product & its variants from an external source, ready to be inputted and saved to the Mable database. */
export type ExternalProductInput = {
  'externalId': string,
  'source': ExternalProductSource,
  /** JSON representation of the product using the source's format, usually right what gets returned from their API. */
  'rawData': Record<string, unknown>,
  'externalBrandId'?: string,
  'extraData'?: Record<string, unknown>,
};
export type EncodedExternalProductInput = {
  'externalId': string,
  'source': EncodedExternalProductSource,
  'rawData': Record<string, unknown>,
  'externalBrandId'?: string,
  'extraData'?: Record<string, unknown>,
};
export const ExternalProductInput: t.Type<ExternalProductInput, EncodedExternalProductInput> = t.recursion('ExternalProductInput', () => (
  t.exact(t.intersection([t.type({
    'externalId': t.string,
    'source': ExternalProductSource,
    'rawData': t.record(t.string, t.unknown),
  }), t.partial({
    'externalBrandId': t.string,
    'extraData': t.record(t.string, t.unknown),
  })]), 'ExternalProductInput')
));


/** Data representing a product or a product & its variants from an external source. */
export type ExternalProduct = ExternalProductInput & {
  'id': number,
  'normalizedData'?: ExternalProductNormalizedData,
};
export type EncodedExternalProduct = EncodedExternalProductInput & {
  'id': number,
  'normalizedData'?: EncodedExternalProductNormalizedData,
};
export const ExternalProduct: t.Type<ExternalProduct, EncodedExternalProduct> = t.recursion('ExternalProduct', () => (
  t.intersection([
    ExternalProductInput,
    t.exact(t.intersection([t.type({
      'id': t.number,
    }), t.partial({
      'normalizedData': ExternalProductNormalizedData,
    })]), 'ExternalProduct')
  ], 'ExternalProduct')
));


/** Data for an external product formatted such that it could be used with Mable's product data format. */
export type ExternalProductNormalizedData = {
  /** Unique ID in Mable's database */
  'externalProductId': number,
  /** URL of this product on the source */
  'externalUrl'?: string,
  'name'?: string,
  'description'?: string,
  'vendorName'?: string,
  'images'?: Array<ProductImage>,
  'variants'?: Array<ExternalProductVariantNormalizedData>,
};
export type EncodedExternalProductNormalizedData = {
  'externalProductId': number,
  'externalUrl'?: string,
  'name'?: string,
  'description'?: string,
  'vendorName'?: string,
  'images'?: Array<EncodedProductImage>,
  'variants'?: Array<EncodedExternalProductVariantNormalizedData>,
};
export const ExternalProductNormalizedData: t.Type<ExternalProductNormalizedData, EncodedExternalProductNormalizedData> = t.recursion('ExternalProductNormalizedData', () => (
  t.exact(t.intersection([t.type({
    'externalProductId': t.number,
  }), t.partial({
    'externalUrl': t.string,
    'name': t.string,
    'description': t.string,
    'vendorName': t.string,
    'images': t.array(ProductImage),
    'variants': t.array(ExternalProductVariantNormalizedData),
  })]), 'ExternalProductNormalizedData')
));


/** Data for an external product formatted like our product + product variant data model. */
export type ExternalProductVariantNormalizedData = {
  /** The unique ID of this variant in the external source, if any. */
  'externalId'?: string,
  /** URL of this product variant on the source */
  'externalUrl'?: string,
  'name'?: string,
  'description'?: string,
  'ingredients'?: string,
  'categorySlugs'?: Array<string>,
  /** The best option for retail UPC */
  'upc'?: string,
  /** The best option for case UPC */
  'caseUpc'?: string,
  /** If there is more than one UPC code, the alternates will be in this property */
  'upcAlternates'?: Array<string>,
  'storage'?: Array<ProductStorage>,
  'packagings'?: Array<ProductVariantPackaging>,
};
export type EncodedExternalProductVariantNormalizedData = {
  'externalId'?: string,
  'externalUrl'?: string,
  'name'?: string,
  'description'?: string,
  'ingredients'?: string,
  'categorySlugs'?: Array<string>,
  'upc'?: string,
  'caseUpc'?: string,
  'upcAlternates'?: Array<string>,
  'storage'?: Array<EncodedProductStorage>,
  'packagings'?: Array<EncodedProductVariantPackaging>,
};
export const ExternalProductVariantNormalizedData: t.Type<ExternalProductVariantNormalizedData, EncodedExternalProductVariantNormalizedData> = t.recursion('ExternalProductVariantNormalizedData', () => (
  t.exact(t.intersection([t.type({
    
  }), t.partial({
    'externalId': t.string,
    'externalUrl': t.string,
    'name': t.string,
    'description': t.string,
    'ingredients': t.string,
    'categorySlugs': t.array(t.string),
    'upc': t.string,
    'caseUpc': t.string,
    'upcAlternates': t.array(t.string),
    'storage': t.array(ProductStorage),
    'packagings': t.array(ProductVariantPackaging),
  })]), 'ExternalProductVariantNormalizedData')
));


/** Purchase information regarding when products were purchased off Mable. Used to backfill which products retailers had been buying from one system into Mable's. */
export type ExternalProductPurchaseData = {
  /** If you want to use the same customer number for every row, specify it here. */
  'externalCustomerNumber'?: string,
  'csvInput': CSVInput,
  'type'?: CodegenEnumOpenValues<'orderedExternally' | 'exclusiveWhitelist'>,
  /** if true, we should clear existing relationships */
  'replace'?: boolean,
};
export type EncodedExternalProductPurchaseData = {
  'externalCustomerNumber'?: string,
  'csvInput': EncodedCSVInput,
  'type'?: string,
  'replace'?: boolean,
};
export const ExternalProductPurchaseData: t.Type<ExternalProductPurchaseData, EncodedExternalProductPurchaseData> = t.recursion('ExternalProductPurchaseData', () => (
  t.exact(t.intersection([t.type({
    'csvInput': CSVInput,
  }), t.partial({
    'externalCustomerNumber': t.string,
    'type': CodegenEnumOpen({
      'orderedExternally': null,
      'exclusiveWhitelist': null,
    }, 'ExternalProductPurchaseData'),
    'replace': t.boolean,
  })]), 'ExternalProductPurchaseData')
));


export type AdminDashboard = {
  'tiles': Array<AdminDashboardItem>,
};
export type EncodedAdminDashboard = {
  'tiles': Array<EncodedAdminDashboardItem>,
};
export const AdminDashboard: t.Type<AdminDashboard, EncodedAdminDashboard> = t.recursion('AdminDashboard', () => (
  t.exact(t.intersection([t.type({
    'tiles': t.array(AdminDashboardItem),
  }), t.partial({
    
  })]), 'AdminDashboard')
));


export type AdminDashboardItem = {
  'title': string,
  'value': number,
  'destination': string,
};
export type EncodedAdminDashboardItem = {
  'title': string,
  'value': number,
  'destination': string,
};
export const AdminDashboardItem: t.Type<AdminDashboardItem, EncodedAdminDashboardItem> = t.recursion('AdminDashboardItem', () => (
  t.exact(t.intersection([t.type({
    'title': t.string,
    'value': t.number,
    'destination': t.string,
  }), t.partial({
    
  })]), 'AdminDashboardItem')
));


export type Dimensions = {
  /** In inches */
  'length': number,
  /** In inches */
  'height': number,
  /** In inches */
  'width': number,
};
export type EncodedDimensions = {
  'length': number,
  'height': number,
  'width': number,
};
export const Dimensions: t.Type<Dimensions, EncodedDimensions> = t.recursion('Dimensions', () => (
  t.exact(t.intersection([t.type({
    'length': t.number,
    'height': t.number,
    'width': t.number,
  }), t.partial({
    
  })]), 'Dimensions')
));


export type Parcel = {
  'dimensions'?: Dimensions,
  'carrierDefinedParcelId'?: string,
  /** In ounces (oz) */
  'weight': number,
};
export type EncodedParcel = {
  'dimensions'?: EncodedDimensions,
  'carrierDefinedParcelId'?: string,
  'weight': number,
};
export const Parcel: t.Type<Parcel, EncodedParcel> = t.recursion('Parcel', () => (
  t.exact(t.intersection([t.type({
    'weight': t.number,
  }), t.partial({
    'dimensions': Dimensions,
    'carrierDefinedParcelId': t.string,
  })]), 'Parcel')
));


/** Represents a printable postage label for a given shipment */
export type PostageLabel = {
  /** URL of default printable format (PNG) */
  'labelUrl': string,
};
export type EncodedPostageLabel = {
  'labelUrl': string,
};
export const PostageLabel: t.Type<PostageLabel, EncodedPostageLabel> = t.recursion('PostageLabel', () => (
  t.exact(t.intersection([t.type({
    'labelUrl': t.string,
  }), t.partial({
    
  })]), 'PostageLabel')
));


/** A parcel / package type pre-defined for a specific carrier. e.g. a USPS "Small Flat Rate Box" */
export type CarrierDefinedParcel = {
  /** ID in Mable DB */
  'id': number,
  /** Identifer used by EasyPost. Not user friendly. e.g. "FedEx25kgBox" */
  'easypostId': string,
  /** User-friendly name, e.g. "FedEx 25kg Box" */
  'name': string,
  /** User-friendly name of the carrier, use for grouping pre-defined parcels. e.g. "USPS". */
  'carrierName': string,
  /** e.g. '8 ⅝" x 5 ⅜" x 1 ⅝"' */
  'size'?: string,
  /** Optional caveat or note about dimensions, e.g. "Maximum size is 108" in combined length and girth (distance around the thickest part). First-Class™ packages cannot exceed 22" x 18" x 15"." */
  'dimensionsNote'?: string,
  /** Optional caveat or restriction relating to weight, e.g. "Contents must weigh less than 70 lbs." */
  'weightNote'?: string,
  /** Any other general caveat or restriction on the parcel type. e.g. "Not for international shipping" */
  'note'?: string,
};
export type EncodedCarrierDefinedParcel = {
  'id': number,
  'easypostId': string,
  'name': string,
  'carrierName': string,
  'size'?: string,
  'dimensionsNote'?: string,
  'weightNote'?: string,
  'note'?: string,
};
export const CarrierDefinedParcel: t.Type<CarrierDefinedParcel, EncodedCarrierDefinedParcel> = t.recursion('CarrierDefinedParcel', () => (
  t.exact(t.intersection([t.type({
    'id': t.number,
    'easypostId': t.string,
    'name': t.string,
    'carrierName': t.string,
  }), t.partial({
    'size': t.string,
    'dimensionsNote': t.string,
    'weightNote': t.string,
    'note': t.string,
  })]), 'CarrierDefinedParcel')
));


/** Information used to help render the buy shipping flow. */
export type BuyShippingMetaData = {
  /** Parcels the user has explicitly saved. */
  'savedParcels'?: Array<Parcel>,
  /** Pre-defined package types, defined by carriers. Group by carrier name to organize for the UI. */
  'carrierDefinedParcels': Array<CarrierDefinedParcel>,
  /** Placeholder information used in rendering the UI before actual rates are fetched */
  'shipmentRatePlaceholders'?: Array<ShipmentRatePlaceholder>,
};
export type EncodedBuyShippingMetaData = {
  'savedParcels'?: Array<EncodedParcel>,
  'carrierDefinedParcels': Array<EncodedCarrierDefinedParcel>,
  'shipmentRatePlaceholders'?: Array<EncodedShipmentRatePlaceholder>,
};
export const BuyShippingMetaData: t.Type<BuyShippingMetaData, EncodedBuyShippingMetaData> = t.recursion('BuyShippingMetaData', () => (
  t.exact(t.intersection([t.type({
    'carrierDefinedParcels': t.array(CarrierDefinedParcel),
  }), t.partial({
    'savedParcels': t.array(Parcel),
    'shipmentRatePlaceholders': t.array(ShipmentRatePlaceholder),
  })]), 'BuyShippingMetaData')
));


/** Reliable constants for names that will get returned as carrierName by shipping services. May not be comprehensive. */
export type ShipmentCarrierNames = CodegenEnumOpenValues<'USPS' | 'UPS' | 'FedEx'>;
export type EncodedShipmentCarrierNames = string;
export const ShipmentCarrierNames = CodegenEnumOpen({
  'USPS': null,
  'UPS': null,
  'FedEx': null,
}, 'ShipmentCarrierNames');
export const ShipmentCarrierNamesValues = ['USPS', 'UPS', 'FedEx'] as const;

/** Represents a shipment of a parcel, currently only originating from a EasyPost integration. */
export type EasypostShipment = {
  'parcel': Parcel,
  'postageLabel'?: PostageLabel,
  'easypostShipmentId'?: string,
};
export type EncodedEasypostShipment = {
  'parcel': EncodedParcel,
  'postageLabel'?: EncodedPostageLabel,
  'easypostShipmentId'?: string,
};
export const EasypostShipment: t.Type<EasypostShipment, EncodedEasypostShipment> = t.recursion('EasypostShipment', () => (
  t.exact(t.intersection([t.type({
    'parcel': Parcel,
  }), t.partial({
    'postageLabel': PostageLabel,
    'easypostShipmentId': t.string,
  })]), 'EasypostShipment')
));


/** The status of a shipment according to EasyPost */
export type EasypostShipmentStatus = CodegenEnumOpenValues<'unknown' | 'pre_transit' | 'in_transit' | 'out_for_delivery' | 'delivered' | 'available_for_pickup' | 'return_to_sender' | 'failure' | 'cancelled' | 'error'>;
export type EncodedEasypostShipmentStatus = string;
export const EasypostShipmentStatus = CodegenEnumOpen({
  'unknown': null,
  'pre_transit': null,
  'in_transit': null,
  'out_for_delivery': null,
  'delivered': null,
  'available_for_pickup': null,
  'return_to_sender': null,
  'failure': null,
  'cancelled': null,
  'error': null,
}, 'EasypostShipmentStatus');
export const EasypostShipmentStatusValues = ['unknown', 'pre_transit', 'in_transit', 'out_for_delivery', 'delivered', 'available_for_pickup', 'return_to_sender', 'failure', 'cancelled', 'error'] as const;

/** Properties used to provide a placeholder for shipment rate selection UI, before we get actual rates returned to the front-end. */
export type ShipmentRatePlaceholder = {
  /** Identifier for carrier to send back when buying a rate. Not user-friendly. e.g. 'UPSDAP' */
  'carrierId': string,
  /** User-friendly name for rate's carrier, e.g. "UPS" */
  'carrierName': string,
  /** Service level/name ID to send back when buying a rate. Not user-friendly. e.g. 'FEDEX_2_DAY_AM' */
  'serviceId': string,
  /** User friendly name of the service, e.g. "2 day AM" */
  'serviceName': string,
};
export type EncodedShipmentRatePlaceholder = {
  'carrierId': string,
  'carrierName': string,
  'serviceId': string,
  'serviceName': string,
};
export const ShipmentRatePlaceholder: t.Type<ShipmentRatePlaceholder, EncodedShipmentRatePlaceholder> = t.recursion('ShipmentRatePlaceholder', () => (
  t.exact(t.intersection([t.type({
    'carrierId': t.string,
    'carrierName': t.string,
    'serviceId': t.string,
    'serviceName': t.string,
  }), t.partial({
    
  })]), 'ShipmentRatePlaceholder')
));


/** Rate object to be displayed to the user while they are buying shipping labels. */
export type ShipmentRate = {
  'easypostRateId': string,
  /** Identifier for carrier to send back when buying a rate. Not user-friendly. e.g. 'UPSDAP' */
  'carrierId': string,
  /** User-friendly name for rate's carrier, e.g. "UPS" */
  'carrierName': string,
  /** Service level/name ID to send back when buying a rate. Not user-friendly. e.g. 'FEDEX_2_DAY_AM' */
  'serviceId': string,
  /** User friendly name of the service, e.g. "2 day AM" */
  'serviceName': string,
  /** Display price of the rate, e.g. "$9.50" */
  'ratePrice'?: string,
  /** Like ratePrice, but it's a number */
  'ratePriceNumber': BigNumber,
  /** delivery days for this service */
  'deliveryDays'?: number,
  /** Date for delivery */
  'deliveryDate'?: string,
  /** Indicates if delivery window is guaranteed (true) or not (false) */
  'deliveryDateGuaranteed': boolean,
  /** If `true`, display a 'Recommended' badge in the UI. */
  'recommended'?: boolean,
};
export type EncodedShipmentRate = {
  'easypostRateId': string,
  'carrierId': string,
  'carrierName': string,
  'serviceId': string,
  'serviceName': string,
  'ratePrice'?: string,
  'ratePriceNumber': string,
  'deliveryDays'?: number,
  'deliveryDate'?: string,
  'deliveryDateGuaranteed': boolean,
  'recommended'?: boolean,
};
export const ShipmentRate: t.Type<ShipmentRate, EncodedShipmentRate> = t.recursion('ShipmentRate', () => (
  t.exact(t.intersection([t.type({
    'easypostRateId': t.string,
    'carrierId': t.string,
    'carrierName': t.string,
    'serviceId': t.string,
    'serviceName': t.string,
    'ratePriceNumber': NumberFromString,
    'deliveryDateGuaranteed': t.boolean,
  }), t.partial({
    'ratePrice': t.string,
    'deliveryDays': t.number,
    'deliveryDate': t.string,
    'recommended': t.boolean,
  })]), 'ShipmentRate')
));


/** Properties needed to create shipment group from shipping flow. */
export type ShipmentGroupCreateInput = {
  'fromAddressId': number,
  'parcels': Array<Parcel>,
  /** Send true if the user wants a signature to be required for delivery */
  'requireSignature': boolean,
};
export type EncodedShipmentGroupCreateInput = {
  'fromAddressId': number,
  'parcels': Array<EncodedParcel>,
  'requireSignature': boolean,
};
export const ShipmentGroupCreateInput: t.Type<ShipmentGroupCreateInput, EncodedShipmentGroupCreateInput> = t.recursion('ShipmentGroupCreateInput', () => (
  t.exact(t.intersection([t.type({
    'fromAddressId': t.number,
    'parcels': t.array(Parcel),
    'requireSignature': t.boolean,
  }), t.partial({
    
  })]), 'ShipmentGroupCreateInput')
));


/** Properties needed to buy a shipment group from shipping flow. */
export type ShipmentGroupBuyInput = {
  'shipmentGroupId': number,
  /** ID of carrier, passed back from the value in the returned Rate. e.g. "UPSDAP" */
  'carrierId': string,
  /** ID of service, passed back from the value in the returned Rate. e.g. "FEDEX_GROUND" */
  'serviceId': string,
};
export type EncodedShipmentGroupBuyInput = {
  'shipmentGroupId': number,
  'carrierId': string,
  'serviceId': string,
};
export const ShipmentGroupBuyInput: t.Type<ShipmentGroupBuyInput, EncodedShipmentGroupBuyInput> = t.recursion('ShipmentGroupBuyInput', () => (
  t.exact(t.intersection([t.type({
    'shipmentGroupId': t.number,
    'carrierId': t.string,
    'serviceId': t.string,
  }), t.partial({
    
  })]), 'ShipmentGroupBuyInput')
));


/** Status of shipment group */
export type ShipmentGroupStatus = CodegenEnumOpenValues<'created' | 'replaced' | 'purchased'>;
export type EncodedShipmentGroupStatus = string;
export const ShipmentGroupStatus = CodegenEnumOpen({
  'created': null,
  'replaced': null,
  'purchased': null,
}, 'ShipmentGroupStatus');
export const ShipmentGroupStatusValues = ['created', 'replaced', 'purchased'] as const;

/** Mapping for an EasyPost "Order" object, used while purchasing shipping. */
export type ShipmentGroup = {
  /** Mable DB id */
  'id': number,
  'status': ShipmentGroupStatus,
  /** The transaction / order this shipment group is for */
  'transactionId': number,
  /** ID of object on easy post */
  'easypostOrderId': string,
  'rates': Array<ShipmentRate>,
  'shipments'?: Array<EasypostShipment>,
};
export type EncodedShipmentGroup = {
  'id': number,
  'status': EncodedShipmentGroupStatus,
  'transactionId': number,
  'easypostOrderId': string,
  'rates': Array<EncodedShipmentRate>,
  'shipments'?: Array<EncodedEasypostShipment>,
};
export const ShipmentGroup: t.Type<ShipmentGroup, EncodedShipmentGroup> = t.recursion('ShipmentGroup', () => (
  t.exact(t.intersection([t.type({
    'id': t.number,
    'status': ShipmentGroupStatus,
    'transactionId': t.number,
    'easypostOrderId': t.string,
    'rates': t.array(ShipmentRate),
  }), t.partial({
    'shipments': t.array(EasypostShipment),
  })]), 'ShipmentGroup')
));


export type TransactionWithoutDisplayInfo = {
  'id': number,
  'accountId': number,
  'sellerName'?: string,
  'transactionGroupId': number,
  'createdAt': Date,
  'status': TransactionStatus,
  'buyerInvoiceStatus'?: InvoiceStatus,
  /** The status of our decision about who is going to fulfill this order. Usually the seller fulfills orders, but sometimes they're fulfilled by a Mable warehouse. */
  'fulfillmentRoutingStatus'?: FulfillmentRoutingStatus,
  'buyerInvoiceDueDate'?: Date,
  'buyerInvoiceIsStripe'?: boolean,
  /** The total amount that the buyer will be billed for this order */
  'billTotal': BigNumber,
  /** The maximum percentage of the purchase total that Mable will pay in shipping on this transaction */
  'mableShippingMaxPaymentMultiplier'?: BigNumber,
  /** The total amount that the seller will be remitted for this order */
  'remittanceTotal': BigNumber,
  /** Buyer has agreed to pay for all of shipping on this order */
  'buyerCoversShipping': boolean,
  /** Partner has agreed to pay for all of shipping on this order */
  'partnerCoversShipping': boolean,
  /** The sellers's free shipping minimum (if they have one) at the time of purchase */
  'sellerFreeShippingMinimum'?: BigNumber,
  /** The total amount from shipping that the seller will be remitted for this order */
  'sellerShippingTotal': BigNumber,
  /** In Buyer Shipping Max Guarantee only, the minimum percentage of the remaining shipping costs the seller will pay once the buyer has paid. They could pay more if we hit the mableShippingMaxPaymentMultiplier amount. */
  'sellerShippingCapPaymentMultiplier'?: BigNumber,
  /** The total amount the Seller saved on shipping for this order */
  'sellerShippingDiscount': BigNumber,
  /** The total amount the Seller has paid for shipping for this order */
  'sellerShippingPaymentTotal': BigNumber,
  /** The total amount Mable has paid for shipping for this order through Easypost */
  'shipWithMableTotal': BigNumber,
  /** The maximum percentage of an order total that buyer of this brand will pay for shipping */
  'shippingCapMultiplier': BigNumber,
  /** In Shipping Program only, the percentage of shipping costs that a seller will pay for on a order (the rest being paid by Mable) */
  'sellerShippingPaymentMultiplier'?: BigNumber,
  /** The total amount spent paid for shipping on this order */
  'shippingTotal': BigNumber,
  /** The total amount the partner has paid for shipping on this order */
  'partnerShippingPaymentTotal': BigNumber,
  /** The total amount the Buyer saved on shipping for this order */
  'buyerShippingDiscount': BigNumber,
  /** The total amount the buyer will pay for shipping on this order */
  'buyerShippingTotal': BigNumber,
  /** The total buyer facing price of items purchased in this order */
  'buyerPurchaseTotal': BigNumber,
  /** The total seller facing price of items purchased in this order */
  'sellerPurchaseTotal': BigNumber,
  'discountTotal': BigNumber,
  /** The total commission earned by Mable on this transaction. Generally zero or a positive number. Note that some of this money may then be sent to the partner, as reflected in partnershipRevenueShareTotal. */
  'commissionTotal': BigNumber,
  /** Amount of money going to Mable for landed cost markups */
  'landedCostMarkupTotal': BigNumber,
  /** Amount of money going to Mable for landed cost stability adjustments */
  'landedCostStabilityTotal': BigNumber,
  /** Amount of money going to the partner, if any */
  'partnershipRevenueShareTotal': BigNumber,
  'splits': Array<TransactionSplit>,
  'log': Array<TransactionLogEntry>,
  'order'?: Order,
  'buyerContact'?: Contact,
  /** deprecated */
  'buyerEmail'?: string,
  'shipToAddress'?: Address,
  'type': TransactionType,
  'buyerLocationId'?: string,
  'fullBuyerLocationName'?: string,
  'buyerLocationName'?: string,
  'buyerName'?: string,
  'buyerHasMultipleLocations'?: boolean,
  /** Whether the buyer has been marked as priority. */
  'buyerIsPriority'?: boolean,
  'storeIdentifier'?: string,
  'subsidiaryIdentifier'?: string,
  /** The external purchase order number that a buyer may have sent us when placing this order, typically through EDI. Not unique, even within a specific buyer. */
  'externalPurchaseOrderNumber'?: string,
  /** If this order is coming from a partner on behalf of a different retailer, this value may contain the retailer's purchase order number. */
  'buyerPurchaseOrderNumber'?: string,
  /** The commission rate used at the time the PO was sent */
  'commissionMultiplier'?: BigNumber,
  /** The first order commission rate used at the time the PO was sent */
  'firstOrderCommissionMultiplier'?: BigNumber,
  /** The order minimum that existed at the time the order was made */
  'orderMinimum'?: BigNumber,
  /** Whether this transaciton contains landed cost fees. */
  'isLandedCosts': boolean,
  'buyerPartnerProgramMemberships'?: Array<BuyerPartnerProgramMembership>,
  /** The date after which this transaction will be automatically cancelled if it has not been accepted by the seller. */
  'autoCancelAfterIfNotAccepted'?: Date,
  /** The date after which this transaction will be automatically cancelled if it has not been shipped by the seller. */
  'autoCancelAfterIfNotShipped'?: Date,
  /** The account ID of the buyer's partner that is associated with this transaction. */
  'partnerAccountId'?: number,
  /** The name of the partner that placed this order on behalf of the buyer. */
  'partnerName'?: string,
  /** The display name of the partner that placed this order on behalf of the buyer. */
  'partnerDisplayName'?: string,
};
export type EncodedTransactionWithoutDisplayInfo = {
  'id': number,
  'accountId': number,
  'sellerName'?: string,
  'transactionGroupId': number,
  'createdAt': string,
  'status': EncodedTransactionStatus,
  'buyerInvoiceStatus'?: EncodedInvoiceStatus,
  'fulfillmentRoutingStatus'?: EncodedFulfillmentRoutingStatus,
  'buyerInvoiceDueDate'?: string,
  'buyerInvoiceIsStripe'?: boolean,
  'billTotal': string,
  'mableShippingMaxPaymentMultiplier'?: string,
  'remittanceTotal': string,
  'buyerCoversShipping': boolean,
  'partnerCoversShipping': boolean,
  'sellerFreeShippingMinimum'?: string,
  'sellerShippingTotal': string,
  'sellerShippingCapPaymentMultiplier'?: string,
  'sellerShippingDiscount': string,
  'sellerShippingPaymentTotal': string,
  'shipWithMableTotal': string,
  'shippingCapMultiplier': string,
  'sellerShippingPaymentMultiplier'?: string,
  'shippingTotal': string,
  'partnerShippingPaymentTotal': string,
  'buyerShippingDiscount': string,
  'buyerShippingTotal': string,
  'buyerPurchaseTotal': string,
  'sellerPurchaseTotal': string,
  'discountTotal': string,
  'commissionTotal': string,
  'landedCostMarkupTotal': string,
  'landedCostStabilityTotal': string,
  'partnershipRevenueShareTotal': string,
  'splits': Array<EncodedTransactionSplit>,
  'log': Array<EncodedTransactionLogEntry>,
  'order'?: EncodedOrder,
  'buyerContact'?: EncodedContact,
  'buyerEmail'?: string,
  'shipToAddress'?: EncodedAddress,
  'type': EncodedTransactionType,
  'buyerLocationId'?: string,
  'fullBuyerLocationName'?: string,
  'buyerLocationName'?: string,
  'buyerName'?: string,
  'buyerHasMultipleLocations'?: boolean,
  'buyerIsPriority'?: boolean,
  'storeIdentifier'?: string,
  'subsidiaryIdentifier'?: string,
  'externalPurchaseOrderNumber'?: string,
  'buyerPurchaseOrderNumber'?: string,
  'commissionMultiplier'?: string,
  'firstOrderCommissionMultiplier'?: string,
  'orderMinimum'?: string,
  'isLandedCosts': boolean,
  'buyerPartnerProgramMemberships'?: Array<EncodedBuyerPartnerProgramMembership>,
  'autoCancelAfterIfNotAccepted'?: string,
  'autoCancelAfterIfNotShipped'?: string,
  'partnerAccountId'?: number,
  'partnerName'?: string,
  'partnerDisplayName'?: string,
};
export const TransactionWithoutDisplayInfo: t.Type<TransactionWithoutDisplayInfo, EncodedTransactionWithoutDisplayInfo> = t.recursion('TransactionWithoutDisplayInfo', () => (
  t.exact(t.intersection([t.type({
    'id': t.number,
    'accountId': t.number,
    'transactionGroupId': t.number,
    'createdAt': DateFromISOString,
    'status': TransactionStatus,
    'billTotal': NumberFromString,
    'remittanceTotal': NumberFromString,
    'buyerCoversShipping': t.boolean,
    'partnerCoversShipping': t.boolean,
    'sellerShippingTotal': NumberFromString,
    'sellerShippingDiscount': NumberFromString,
    'sellerShippingPaymentTotal': NumberFromString,
    'shipWithMableTotal': NumberFromString,
    'shippingCapMultiplier': NumberFromString,
    'shippingTotal': NumberFromString,
    'partnerShippingPaymentTotal': NumberFromString,
    'buyerShippingDiscount': NumberFromString,
    'buyerShippingTotal': NumberFromString,
    'buyerPurchaseTotal': NumberFromString,
    'sellerPurchaseTotal': NumberFromString,
    'discountTotal': NumberFromString,
    'commissionTotal': NumberFromString,
    'landedCostMarkupTotal': NumberFromString,
    'landedCostStabilityTotal': NumberFromString,
    'partnershipRevenueShareTotal': NumberFromString,
    'splits': t.array(TransactionSplit),
    'log': t.array(TransactionLogEntry),
    'type': TransactionType,
    'isLandedCosts': t.boolean,
  }), t.partial({
    'sellerName': t.string,
    'buyerInvoiceStatus': InvoiceStatus,
    'fulfillmentRoutingStatus': FulfillmentRoutingStatus,
    'buyerInvoiceDueDate': DateFromISOString,
    'buyerInvoiceIsStripe': t.boolean,
    'mableShippingMaxPaymentMultiplier': NumberFromString,
    'sellerFreeShippingMinimum': NumberFromString,
    'sellerShippingCapPaymentMultiplier': NumberFromString,
    'sellerShippingPaymentMultiplier': NumberFromString,
    'order': Order,
    'buyerContact': Contact,
    'buyerEmail': t.string,
    'shipToAddress': Address,
    'buyerLocationId': t.string,
    'fullBuyerLocationName': t.string,
    'buyerLocationName': t.string,
    'buyerName': t.string,
    'buyerHasMultipleLocations': t.boolean,
    'buyerIsPriority': t.boolean,
    'storeIdentifier': t.string,
    'subsidiaryIdentifier': t.string,
    'externalPurchaseOrderNumber': t.string,
    'buyerPurchaseOrderNumber': t.string,
    'commissionMultiplier': NumberFromString,
    'firstOrderCommissionMultiplier': NumberFromString,
    'orderMinimum': NumberFromString,
    'buyerPartnerProgramMemberships': t.array(BuyerPartnerProgramMembership),
    'autoCancelAfterIfNotAccepted': DateFromISOString,
    'autoCancelAfterIfNotShipped': DateFromISOString,
    'partnerAccountId': t.number,
    'partnerName': t.string,
    'partnerDisplayName': t.string,
  })]), 'TransactionWithoutDisplayInfo')
));


export type Transaction = TransactionWithoutDisplayInfo & {
  'displayInfo': TransactionDisplayInfo,
  'actionsRequired'?: Array<TransactionAction>,
};
export type EncodedTransaction = EncodedTransactionWithoutDisplayInfo & {
  'displayInfo': EncodedTransactionDisplayInfo,
  'actionsRequired'?: Array<EncodedTransactionAction>,
};
export const Transaction: t.Type<Transaction, EncodedTransaction> = t.recursion('Transaction', () => (
  t.intersection([
    TransactionWithoutDisplayInfo,
    t.exact(t.intersection([t.type({
      'displayInfo': TransactionDisplayInfo,
    }), t.partial({
      'actionsRequired': t.array(TransactionAction),
    })]), 'Transaction')
  ], 'Transaction')
));


export type TransactionSplitStatus = CodegenEnumOpenValues<'created' | 'authorized' | 'cancelled' | 'voided' | 'settled' | 'failed'>;
export type EncodedTransactionSplitStatus = string;
export const TransactionSplitStatus = CodegenEnumOpen({
  'created': null,
  'authorized': null,
  'cancelled': null,
  'voided': null,
  'settled': null,
  'failed': null,
}, 'TransactionSplitStatus');
export const TransactionSplitStatusValues = ['created', 'authorized', 'cancelled', 'voided', 'settled', 'failed'] as const;

export type TransactionSplitType = CodegenEnumOpenValues<'purchase' | 'payment' | 'discount' | 'fee' | 'commission' | 'earnings' | 'remittance' | 'shipping' | 'shippingPayment' | 'credit' | 'sampleRequest'>;
export type EncodedTransactionSplitType = string;
export const TransactionSplitType = CodegenEnumOpen({
  'purchase': null,
  'payment': null,
  'discount': null,
  'fee': null,
  'commission': null,
  'earnings': null,
  'remittance': null,
  'shipping': null,
  'shippingPayment': null,
  'credit': null,
  'sampleRequest': null,
}, 'TransactionSplitType');
export const TransactionSplitTypeValues = ['purchase', 'payment', 'discount', 'fee', 'commission', 'earnings', 'remittance', 'shipping', 'shippingPayment', 'credit', 'sampleRequest'] as const;

export type FulfillmentRoutingStatus = CodegenEnumOpenValues<'paused' | 'seller' | 'warehouse'>;
export type EncodedFulfillmentRoutingStatus = string;
export const FulfillmentRoutingStatus = CodegenEnumOpen({
  'paused': null,
  'seller': null,
  'warehouse': null,
}, 'FulfillmentRoutingStatus');
export const FulfillmentRoutingStatusValues = ['paused', 'seller', 'warehouse'] as const;

export type TransactionCounterpartyInfo = {
  'transactionAccountId': number,
  'name'?: string,
  'tags'?: Array<string>,
};
export type EncodedTransactionCounterpartyInfo = {
  'transactionAccountId': number,
  'name'?: string,
  'tags'?: Array<string>,
};
export const TransactionCounterpartyInfo: t.Type<TransactionCounterpartyInfo, EncodedTransactionCounterpartyInfo> = t.recursion('TransactionCounterpartyInfo', () => (
  t.exact(t.intersection([t.type({
    'transactionAccountId': t.number,
  }), t.partial({
    'name': t.string,
    'tags': t.array(t.string),
  })]), 'TransactionCounterpartyInfo')
));


export type TransactionSplit = {
  'id': number,
  'createdAt': Date,
  'splitGroupId': number,
  'status': TransactionSplitStatus,
  'amount': BigNumber,
  'quantity'?: BigNumber,
  'type': TransactionSplitType,
  'counterparty': TransactionCounterpartyInfo,
  'name'?: string,
  'productId'?: number,
  'productVariantId'?: number,
  'productSellerId'?: number,
  'image'?: Image,
  'externalTransactionPartner'?: string,
  'externalTransactionId'?: string,
  'tags'?: Array<string>,
  'extraData'?: Record<string, unknown>,
  'eachCount'?: BigNumber,
  'sellerSku'?: string,
  'mableSku'?: string,
  'gtin'?: string,
  'caseGtin'?: string,
  'ediLineItemId'?: string,
  'ediSku'?: string,
  'ediUpc'?: string,
};
export type EncodedTransactionSplit = {
  'id': number,
  'createdAt': string,
  'splitGroupId': number,
  'status': EncodedTransactionSplitStatus,
  'amount': string,
  'quantity'?: string,
  'type': EncodedTransactionSplitType,
  'counterparty': EncodedTransactionCounterpartyInfo,
  'name'?: string,
  'productId'?: number,
  'productVariantId'?: number,
  'productSellerId'?: number,
  'image'?: EncodedImage,
  'externalTransactionPartner'?: string,
  'externalTransactionId'?: string,
  'tags'?: Array<string>,
  'extraData'?: Record<string, unknown>,
  'eachCount'?: string,
  'sellerSku'?: string,
  'mableSku'?: string,
  'gtin'?: string,
  'caseGtin'?: string,
  'ediLineItemId'?: string,
  'ediSku'?: string,
  'ediUpc'?: string,
};
export const TransactionSplit: t.Type<TransactionSplit, EncodedTransactionSplit> = t.recursion('TransactionSplit', () => (
  t.exact(t.intersection([t.type({
    'id': t.number,
    'createdAt': DateFromISOString,
    'splitGroupId': t.number,
    'status': TransactionSplitStatus,
    'amount': NumberFromString,
    'type': TransactionSplitType,
    'counterparty': TransactionCounterpartyInfo,
  }), t.partial({
    'quantity': NumberFromString,
    'name': t.string,
    'productId': t.number,
    'productVariantId': t.number,
    'productSellerId': t.number,
    'image': Image,
    'externalTransactionPartner': t.string,
    'externalTransactionId': t.string,
    'tags': t.array(t.string),
    'extraData': t.record(t.string, t.unknown),
    'eachCount': NumberFromString,
    'sellerSku': t.string,
    'mableSku': t.string,
    'gtin': t.string,
    'caseGtin': t.string,
    'ediLineItemId': t.string,
    'ediSku': t.string,
    'ediUpc': t.string,
  })]), 'TransactionSplit')
));


export type TransactionSplitProductPurchase = TransactionSplit & {
  'type': CodegenEnumOpenValues<'purchase'>,
  'productVariantId': number,
  'quantity': BigNumber,
  'amount': BigNumber,
};
export type EncodedTransactionSplitProductPurchase = EncodedTransactionSplit & {
  'type': string,
  'productVariantId': number,
  'quantity': string,
  'amount': string,
};
export const TransactionSplitProductPurchase: t.Type<TransactionSplitProductPurchase, EncodedTransactionSplitProductPurchase> = t.recursion('TransactionSplitProductPurchase', () => (
  t.intersection([
    TransactionSplit,
    t.exact(t.intersection([t.type({
      'type': CodegenEnumOpen({
        'purchase': null,
      }, 'TransactionSplitProductPurchase'),
      'productVariantId': t.number,
      'quantity': NumberFromString,
      'amount': NumberFromString,
    }), t.partial({
      
    })]), 'TransactionSplitProductPurchase')
  ], 'TransactionSplitProductPurchase')
));


export type TransactionSplitProductPurchaseWithTransactionId = TransactionSplitProductPurchase & {
  'transactionId': number,
};
export type EncodedTransactionSplitProductPurchaseWithTransactionId = EncodedTransactionSplitProductPurchase & {
  'transactionId': number,
};
export const TransactionSplitProductPurchaseWithTransactionId: t.Type<TransactionSplitProductPurchaseWithTransactionId, EncodedTransactionSplitProductPurchaseWithTransactionId> = t.recursion('TransactionSplitProductPurchaseWithTransactionId', () => (
  t.intersection([
    TransactionSplitProductPurchase,
    t.exact(t.intersection([t.type({
      'transactionId': t.number,
    }), t.partial({
      
    })]), 'TransactionSplitProductPurchaseWithTransactionId')
  ], 'TransactionSplitProductPurchaseWithTransactionId')
));


export type CreateTransactionLogEntry = {
  'date'?: Date,
  'type': string,
  'extraData': Record<string, unknown>,
  'idempotencyId'?: string,
  'note'?: string,
};
export type EncodedCreateTransactionLogEntry = {
  'date'?: string,
  'type': string,
  'extraData': Record<string, unknown>,
  'idempotencyId'?: string,
  'note'?: string,
};
export const CreateTransactionLogEntry: t.Type<CreateTransactionLogEntry, EncodedCreateTransactionLogEntry> = t.recursion('CreateTransactionLogEntry', () => (
  t.exact(t.intersection([t.type({
    'type': t.string,
    'extraData': t.record(t.string, t.unknown),
  }), t.partial({
    'date': DateFromISOString,
    'idempotencyId': t.string,
    'note': t.string,
  })]), 'CreateTransactionLogEntry')
));


export type TransactionLogEntry = CreateTransactionLogEntry & {
  'id': number,
  'date': Date,
  'createdAt'?: Date,
  'createdByUserId'?: number,
  'createdByUserName'?: string,
  'createdByUserEmail'?: string,
  'createdByUserAccountId'?: number,
  'splitId'?: number,
};
export type EncodedTransactionLogEntry = EncodedCreateTransactionLogEntry & {
  'id': number,
  'date': string,
  'createdAt'?: string,
  'createdByUserId'?: number,
  'createdByUserName'?: string,
  'createdByUserEmail'?: string,
  'createdByUserAccountId'?: number,
  'splitId'?: number,
};
export const TransactionLogEntry: t.Type<TransactionLogEntry, EncodedTransactionLogEntry> = t.recursion('TransactionLogEntry', () => (
  t.intersection([
    CreateTransactionLogEntry,
    t.exact(t.intersection([t.type({
      'id': t.number,
      'date': DateFromISOString,
    }), t.partial({
      'createdAt': DateFromISOString,
      'createdByUserId': t.number,
      'createdByUserName': t.string,
      'createdByUserEmail': t.string,
      'createdByUserAccountId': t.number,
      'splitId': t.number,
    })]), 'TransactionLogEntry')
  ], 'TransactionLogEntry')
));


export type TransactionDisplayInfo = {
  /** The 'Sendbird URL' of the message channel that was created or fetched. Can be passed to Sendbird JS SDK. Not a URL to open directly. */
  'messageChannelUrl'?: string,
  'header': TransactionDisplayInfoHeader,
};
export type EncodedTransactionDisplayInfo = {
  'messageChannelUrl'?: string,
  'header': EncodedTransactionDisplayInfoHeader,
};
export const TransactionDisplayInfo: t.Type<TransactionDisplayInfo, EncodedTransactionDisplayInfo> = t.recursion('TransactionDisplayInfo', () => (
  t.exact(t.intersection([t.type({
    'header': TransactionDisplayInfoHeader,
  }), t.partial({
    'messageChannelUrl': t.string,
  })]), 'TransactionDisplayInfo')
));


export type TransactionDisplayInfoHeader = {
  'title'?: string,
  'subtitle'?: string,
  'image'?: Image,
};
export type EncodedTransactionDisplayInfoHeader = {
  'title'?: string,
  'subtitle'?: string,
  'image'?: EncodedImage,
};
export const TransactionDisplayInfoHeader: t.Type<TransactionDisplayInfoHeader, EncodedTransactionDisplayInfoHeader> = t.recursion('TransactionDisplayInfoHeader', () => (
  t.exact(t.intersection([t.type({
    
  }), t.partial({
    'title': t.string,
    'subtitle': t.string,
    'image': Image,
  })]), 'TransactionDisplayInfoHeader')
));


export type TransactionAction = CodegenEnumOpenValues<'sendPurchaseOrder' | 'followUpPurchaseOrder' | 'sendBuyerInvoice' | 'receiveBuyerPayment' | 'makeQuickBooksBill' | 'paySellerInvoice' | 'resolveComplications' | 'verifyBuyer'>;
export type EncodedTransactionAction = string;
export const TransactionAction = CodegenEnumOpen({
  'sendPurchaseOrder': null,
  'followUpPurchaseOrder': null,
  'sendBuyerInvoice': null,
  'receiveBuyerPayment': null,
  'makeQuickBooksBill': null,
  'paySellerInvoice': null,
  'resolveComplications': null,
  'verifyBuyer': null,
}, 'TransactionAction');
export const TransactionActionValues = ['sendPurchaseOrder', 'followUpPurchaseOrder', 'sendBuyerInvoice', 'receiveBuyerPayment', 'makeQuickBooksBill', 'paySellerInvoice', 'resolveComplications', 'verifyBuyer'] as const;

export type TransactionStatus = CodegenEnumOpenValues<'cold' | 'new' | 'accepted' | 'shipped' | 'invoiced' | 'cancelled' | 'paid' | 'completed'>;
export type EncodedTransactionStatus = string;
export const TransactionStatus = CodegenEnumOpen({
  'cold': null,
  'new': null,
  'accepted': null,
  'shipped': null,
  'invoiced': null,
  'cancelled': null,
  'paid': null,
  'completed': null,
}, 'TransactionStatus');
export const TransactionStatusValues = ['cold', 'new', 'accepted', 'shipped', 'invoiced', 'cancelled', 'paid', 'completed'] as const;

export type TransactionType = CodegenEnumOpenValues<'order' | 'sampleRequest' | 'ledger'>;
export type EncodedTransactionType = string;
export const TransactionType = CodegenEnumOpen({
  'order': null,
  'sampleRequest': null,
  'ledger': null,
}, 'TransactionType');
export const TransactionTypeValues = ['order', 'sampleRequest', 'ledger'] as const;

export type TransactionGroup = {
  'id': number,
  'discountClaims'?: Array<TransactionDiscountClaim>,
  /** Whether this transaction group uses consolidated invoicing */
  'usesConsolidatedInvoice'?: boolean,
};
export type EncodedTransactionGroup = {
  'id': number,
  'discountClaims'?: Array<EncodedTransactionDiscountClaim>,
  'usesConsolidatedInvoice'?: boolean,
};
export const TransactionGroup: t.Type<TransactionGroup, EncodedTransactionGroup> = t.recursion('TransactionGroup', () => (
  t.exact(t.intersection([t.type({
    'id': t.number,
  }), t.partial({
    'discountClaims': t.array(TransactionDiscountClaim),
    'usesConsolidatedInvoice': t.boolean,
  })]), 'TransactionGroup')
));


export type TransactionDiscountClaim = {
  'id': number,
  'discount': Discount,
};
export type EncodedTransactionDiscountClaim = {
  'id': number,
  'discount': EncodedDiscount,
};
export const TransactionDiscountClaim: t.Type<TransactionDiscountClaim, EncodedTransactionDiscountClaim> = t.recursion('TransactionDiscountClaim', () => (
  t.exact(t.intersection([t.type({
    'id': t.number,
    'discount': Discount,
  }), t.partial({
    
  })]), 'TransactionDiscountClaim')
));


export type OrderDisplayTrackingInfo = {
  /** Shipment tracking numbers */
  'trackingNumbers': Array<string>,
  /** Human-friendly name of courier, e.g. "FedEx" */
  'courierName'?: string,
  /** Stable ID of courier, for future fancy behavior. */
  'courierId': CodegenEnumOpenValues<'ups' | 'usps' | 'dhl' | 'fedex' | 'ontrac' | 'speedee' | 'unknown'>,
  /** URL to link the user to for tracking the trackingNumber, i.e. the UPS website. */
  'trackingUrl'?: string,
};
export type EncodedOrderDisplayTrackingInfo = {
  'trackingNumbers': Array<string>,
  'courierName'?: string,
  'courierId': string,
  'trackingUrl'?: string,
};
export const OrderDisplayTrackingInfo: t.Type<OrderDisplayTrackingInfo, EncodedOrderDisplayTrackingInfo> = t.recursion('OrderDisplayTrackingInfo', () => (
  t.exact(t.intersection([t.type({
    'trackingNumbers': t.array(t.string),
    'courierId': CodegenEnumOpen({
      'ups': null,
      'usps': null,
      'dhl': null,
      'fedex': null,
      'ontrac': null,
      'speedee': null,
      'unknown': null,
    }, 'OrderDisplayTrackingInfo'),
  }), t.partial({
    'courierName': t.string,
    'trackingUrl': t.string,
  })]), 'OrderDisplayTrackingInfo')
));


export type OrderDisplaySelfShipInfo = {
  'dates': Array<Date>,
};
export type EncodedOrderDisplaySelfShipInfo = {
  'dates': Array<string>,
};
export const OrderDisplaySelfShipInfo: t.Type<OrderDisplaySelfShipInfo, EncodedOrderDisplaySelfShipInfo> = t.recursion('OrderDisplaySelfShipInfo', () => (
  t.exact(t.intersection([t.type({
    'dates': t.array(DateFromISOString),
  }), t.partial({
    
  })]), 'OrderDisplaySelfShipInfo')
));


export type OrderDisplayShippingInfo = {
  'date': Date,
  /** What kind of shipping info this is. 'trackingInfo' type will have more information in the 'trackingInfo' property. */
  'type': CodegenEnumOpenValues<'trackingInfo' | 'selfShip'>,
  'trackingInfo'?: OrderDisplayTrackingInfo,
  'selfShipInfo'?: OrderDisplaySelfShipInfo,
};
export type EncodedOrderDisplayShippingInfo = {
  'date': string,
  'type': string,
  'trackingInfo'?: EncodedOrderDisplayTrackingInfo,
  'selfShipInfo'?: EncodedOrderDisplaySelfShipInfo,
};
export const OrderDisplayShippingInfo: t.Type<OrderDisplayShippingInfo, EncodedOrderDisplayShippingInfo> = t.recursion('OrderDisplayShippingInfo', () => (
  t.exact(t.intersection([t.type({
    'date': DateFromISOString,
    'type': CodegenEnumOpen({
      'trackingInfo': null,
      'selfShip': null,
    }, 'OrderDisplayShippingInfo'),
  }), t.partial({
    'trackingInfo': OrderDisplayTrackingInfo,
    'selfShipInfo': OrderDisplaySelfShipInfo,
  })]), 'OrderDisplayShippingInfo')
));


export type InvoiceStatus = CodegenEnumOpenValues<'pending' | 'overdue' | 'paymentPending' | 'paid' | 'cancelled'>;
export type EncodedInvoiceStatus = string;
export const InvoiceStatus = CodegenEnumOpen({
  'pending': null,
  'overdue': null,
  'paymentPending': null,
  'paid': null,
  'cancelled': null,
}, 'InvoiceStatus');
export const InvoiceStatusValues = ['pending', 'overdue', 'paymentPending', 'paid', 'cancelled'] as const;

export type AccountType = CodegenEnumOpenValues<'buyer' | 'seller'>;
export type EncodedAccountType = string;
export const AccountType = CodegenEnumOpen({
  'buyer': null,
  'seller': null,
}, 'AccountType');
export const AccountTypeValues = ['buyer', 'seller'] as const;

export type OrderPresentationType = CodegenEnumOpenValues<'order' | 'invoice'>;
export type EncodedOrderPresentationType = string;
export const OrderPresentationType = CodegenEnumOpen({
  'order': null,
  'invoice': null,
}, 'OrderPresentationType');
export const OrderPresentationTypeValues = ['order', 'invoice'] as const;

export type OrderInvoiceDisplayInfo = {
  'status': InvoiceStatus,
  'dueDate'?: Date,
  'scheduledPayment'?: {
    'date'?: Date,
  },
  'paidDate'?: Date,
  'paymentTermsNetDays'?: number,
};
export type EncodedOrderInvoiceDisplayInfo = {
  'status': EncodedInvoiceStatus,
  'dueDate'?: string,
  'scheduledPayment'?: {
    'date'?: string,
  },
  'paidDate'?: string,
  'paymentTermsNetDays'?: number,
};
export const OrderInvoiceDisplayInfo: t.Type<OrderInvoiceDisplayInfo, EncodedOrderInvoiceDisplayInfo> = t.recursion('OrderInvoiceDisplayInfo', () => (
  t.exact(t.intersection([t.type({
    'status': InvoiceStatus,
  }), t.partial({
    'dueDate': DateFromISOString,
    'scheduledPayment': t.exact(t.intersection([t.type({
      
    }), t.partial({
      'date': DateFromISOString,
    })]), 'OrderInvoiceDisplayInfo'),
    'paidDate': DateFromISOString,
    'paymentTermsNetDays': t.number,
  })]), 'OrderInvoiceDisplayInfo')
));


export type OrderDisplayInfo = TransactionDisplayInfo & {
  'id': number,
  'transactionGroupId': number,
  'status': TransactionStatus,
  /** A string clients should use when displaying a status. If it's undefined, don't display a status. */
  'statusDisplay'?: string,
  /** The status of our decision about who is going to fulfill this order. Usually the seller fulfills orders, but sometimes they're fulfilled by a Mable warehouse. */
  'fulfillmentRoutingStatus'?: FulfillmentRoutingStatus,
  'createdAt'?: Date,
  'seller'?: SellerOrderDisplayInfo,
  'lineItems': Array<OrderDisplayInfoLine>,
  /** Lines that show up in the 'final tally' section - subtotal, commission, total, etc. */
  'finalTallyItems': Array<OrderDisplayInfoFinalTallyLine>,
  /** The total, to be displayed when you want to reflect the size of the transaction with a single number. Value depends on whether this is the buyer or seller perspective. */
  'total': BigNumber,
  /** The total amount the order has been discounted. Only returned in the buyer perspective. */
  'discountTotal'?: BigNumber,
  'shipToAddress'?: Address,
  /** Only returned in the seller perspective. Currently not returned if this is a direct billing (i.e. billToBuyer) order. */
  'billToAddress'?: Address,
  'actions': Array<OrderDisplayInfoAction>,
  'log': Array<OrderDisplayInfoLog>,
  'buyerId'?: number,
  /** The business name of the buyer who this order is for */
  'buyerName': string,
  /** The note to the seller that the buyer entered during checkout. */
  'buyerNote'?: string,
  /** deprecated */
  'buyerEmail'?: string,
  'buyerContact'?: Contact,
  'fullBuyerLocationName'?: string,
  'buyerLocationName'?: string,
  'buyerHasMultipleLocations'?: boolean,
  /** The date this order 'paid'. The meaning of this changes based on the perspective. */
  'paidDate'?: Date,
  'preAuthorizationAmount'?: BigNumber,
  'shippingInfo'?: Array<OrderDisplayShippingInfo>,
  /** Markdown to show in an 'alert box' style above the order details. */
  'alert'?: string,
  /** Link to the buyer's stripe invoice. */
  'invoiceUrl'?: string,
  'invoice'?: OrderInvoiceDisplayInfo,
  'shipments'?: Array<Shipment>,
  'shopifyOrderId'?: string,
  'shopifyOrderState'?: ShopifyOrderState,
  'shopifyOrderStatus'?: ShopifyOrderStatus,
  /** If true, the buyer on this order needs verification. */
  'buyerRequiresVerification'?: boolean,
  /** The order has no shipping cost for the buyer */
  'buyerHasFreeShipping'?: boolean,
  /** Whether the buyer has been marked as priority. */
  'buyerIsPriority'?: boolean,
  'buyerLocationId'?: string,
  'externalPurchaseOrderNumber'?: string,
  'buyerPurchaseOrderNumber'?: string,
  'shouldPrintPackingSlip'?: boolean,
  'partnerName'?: string,
  'partnerDisplayName'?: string,
  /** The ship from address specified in an EDI purchase order */
  'ediShipFromAddress'?: Address,
  /** If we should show the partner specific fulfillment steps on the seller order detail page. */
  'showPartnerFulfillmentSteps': boolean,
  /** The date after which this order will be automatically cancelled if it has not been accepted by the seller. */
  'autoCancelAfterIfNotAccepted'?: Date,
  /** The date after which this order will be automatically cancelled if it has not been shipped by the seller. */
  'autoCancelAfterIfNotShipped'?: Date,
  /** List of allowed carriers for this order if not all. */
  'allowedCarriers'?: Array<string>,
  /** If true, the order contains items that require refrigeration. */
  'hasRefrigeratedItems': boolean,
  /** If true, the order can only be shipped with Mable. */
  'shipWithMableOnly'?: boolean,
  /** If true, the order does not require a signature and we should not allow the user to add the requirement. */
  'disallowSignatureOptionForShipping'?: boolean,
};
export type EncodedOrderDisplayInfo = EncodedTransactionDisplayInfo & {
  'id': number,
  'transactionGroupId': number,
  'status': EncodedTransactionStatus,
  'statusDisplay'?: string,
  'fulfillmentRoutingStatus'?: EncodedFulfillmentRoutingStatus,
  'createdAt'?: string,
  'seller'?: EncodedSellerOrderDisplayInfo,
  'lineItems': Array<EncodedOrderDisplayInfoLine>,
  'finalTallyItems': Array<EncodedOrderDisplayInfoFinalTallyLine>,
  'total': string,
  'discountTotal'?: string,
  'shipToAddress'?: EncodedAddress,
  'billToAddress'?: EncodedAddress,
  'actions': Array<EncodedOrderDisplayInfoAction>,
  'log': Array<EncodedOrderDisplayInfoLog>,
  'buyerId'?: number,
  'buyerName': string,
  'buyerNote'?: string,
  'buyerEmail'?: string,
  'buyerContact'?: EncodedContact,
  'fullBuyerLocationName'?: string,
  'buyerLocationName'?: string,
  'buyerHasMultipleLocations'?: boolean,
  'paidDate'?: string,
  'preAuthorizationAmount'?: string,
  'shippingInfo'?: Array<EncodedOrderDisplayShippingInfo>,
  'alert'?: string,
  'invoiceUrl'?: string,
  'invoice'?: EncodedOrderInvoiceDisplayInfo,
  'shipments'?: Array<EncodedShipment>,
  'shopifyOrderId'?: string,
  'shopifyOrderState'?: EncodedShopifyOrderState,
  'shopifyOrderStatus'?: EncodedShopifyOrderStatus,
  'buyerRequiresVerification'?: boolean,
  'buyerHasFreeShipping'?: boolean,
  'buyerIsPriority'?: boolean,
  'buyerLocationId'?: string,
  'externalPurchaseOrderNumber'?: string,
  'buyerPurchaseOrderNumber'?: string,
  'shouldPrintPackingSlip'?: boolean,
  'partnerName'?: string,
  'partnerDisplayName'?: string,
  'ediShipFromAddress'?: EncodedAddress,
  'showPartnerFulfillmentSteps': boolean,
  'autoCancelAfterIfNotAccepted'?: string,
  'autoCancelAfterIfNotShipped'?: string,
  'allowedCarriers'?: Array<string>,
  'hasRefrigeratedItems': boolean,
  'shipWithMableOnly'?: boolean,
  'disallowSignatureOptionForShipping'?: boolean,
};
export const OrderDisplayInfo: t.Type<OrderDisplayInfo, EncodedOrderDisplayInfo> = t.recursion('OrderDisplayInfo', () => (
  t.intersection([
    TransactionDisplayInfo,
    t.exact(t.intersection([t.type({
      'id': t.number,
      'transactionGroupId': t.number,
      'status': TransactionStatus,
      'lineItems': t.array(OrderDisplayInfoLine),
      'finalTallyItems': t.array(OrderDisplayInfoFinalTallyLine),
      'total': NumberFromString,
      'actions': t.array(OrderDisplayInfoAction),
      'log': t.array(OrderDisplayInfoLog),
      'buyerName': t.string,
      'showPartnerFulfillmentSteps': t.boolean,
      'hasRefrigeratedItems': t.boolean,
    }), t.partial({
      'statusDisplay': t.string,
      'fulfillmentRoutingStatus': FulfillmentRoutingStatus,
      'createdAt': DateFromISOString,
      'seller': SellerOrderDisplayInfo,
      'discountTotal': NumberFromString,
      'shipToAddress': Address,
      'billToAddress': Address,
      'buyerId': t.number,
      'buyerNote': t.string,
      'buyerEmail': t.string,
      'buyerContact': Contact,
      'fullBuyerLocationName': t.string,
      'buyerLocationName': t.string,
      'buyerHasMultipleLocations': t.boolean,
      'paidDate': DateFromISOString,
      'preAuthorizationAmount': NumberFromString,
      'shippingInfo': t.array(OrderDisplayShippingInfo),
      'alert': t.string,
      'invoiceUrl': t.string,
      'invoice': OrderInvoiceDisplayInfo,
      'shipments': t.array(Shipment),
      'shopifyOrderId': t.string,
      'shopifyOrderState': ShopifyOrderState,
      'shopifyOrderStatus': ShopifyOrderStatus,
      'buyerRequiresVerification': t.boolean,
      'buyerHasFreeShipping': t.boolean,
      'buyerIsPriority': t.boolean,
      'buyerLocationId': t.string,
      'externalPurchaseOrderNumber': t.string,
      'buyerPurchaseOrderNumber': t.string,
      'shouldPrintPackingSlip': t.boolean,
      'partnerName': t.string,
      'partnerDisplayName': t.string,
      'ediShipFromAddress': Address,
      'autoCancelAfterIfNotAccepted': DateFromISOString,
      'autoCancelAfterIfNotShipped': DateFromISOString,
      'allowedCarriers': t.array(t.string),
      'shipWithMableOnly': t.boolean,
      'disallowSignatureOptionForShipping': t.boolean,
    })]), 'OrderDisplayInfo')
  ], 'OrderDisplayInfo')
));


export type OrderDisplayInfoLine = {
  'name'?: string,
  'amount'?: BigNumber,
  'eachAmount'?: BigNumber,
  'quantity'?: BigNumber,
  'productId'?: number,
  'productVariantId'?: number,
  'productSellerId'?: number,
  /** The most relevant SKU to display, according to the perspective being used */
  'sku'?: string,
  /** The SKU the seller uses internally */
  'sellerSku'?: string,
  /** The SKU Mable maintains for this product variant */
  'mableSku'?: string,
  /** The ID of this product in the seller's external system. */
  'externalProductId'?: string,
  'gtin'?: string,
  'caseName'?: string,
  'eachName'?: string,
  'eachCount'?: number,
  'image'?: Image,
  /** e.g. "Case of 12 Bottles" */
  'caseOfEachCount'?: string,
  'backInStockDate'?: Date,
};
export type EncodedOrderDisplayInfoLine = {
  'name'?: string,
  'amount'?: string,
  'eachAmount'?: string,
  'quantity'?: string,
  'productId'?: number,
  'productVariantId'?: number,
  'productSellerId'?: number,
  'sku'?: string,
  'sellerSku'?: string,
  'mableSku'?: string,
  'externalProductId'?: string,
  'gtin'?: string,
  'caseName'?: string,
  'eachName'?: string,
  'eachCount'?: number,
  'image'?: EncodedImage,
  'caseOfEachCount'?: string,
  'backInStockDate'?: string,
};
export const OrderDisplayInfoLine: t.Type<OrderDisplayInfoLine, EncodedOrderDisplayInfoLine> = t.recursion('OrderDisplayInfoLine', () => (
  t.exact(t.intersection([t.type({
    
  }), t.partial({
    'name': t.string,
    'amount': NumberFromString,
    'eachAmount': NumberFromString,
    'quantity': NumberFromString,
    'productId': t.number,
    'productVariantId': t.number,
    'productSellerId': t.number,
    'sku': t.string,
    'sellerSku': t.string,
    'mableSku': t.string,
    'externalProductId': t.string,
    'gtin': t.string,
    'caseName': t.string,
    'eachName': t.string,
    'eachCount': t.number,
    'image': Image,
    'caseOfEachCount': t.string,
    'backInStockDate': DateFromISOString,
  })]), 'OrderDisplayInfoLine')
));


export type TallyLineItemTooltip = {
  'text': string,
  /** Which element of the line item to associate this tooltip with */
  'position': CodegenEnumOpenValues<'title' | 'value'>,
};
export type EncodedTallyLineItemTooltip = {
  'text': string,
  'position': string,
};
export const TallyLineItemTooltip: t.Type<TallyLineItemTooltip, EncodedTallyLineItemTooltip> = t.recursion('TallyLineItemTooltip', () => (
  t.exact(t.intersection([t.type({
    'text': t.string,
    'position': CodegenEnumOpen({
      'title': null,
      'value': null,
    }, 'TallyLineItemTooltip'),
  }), t.partial({
    
  })]), 'TallyLineItemTooltip')
));


export type CheckoutDisplayInfoFinalTallyLineItem = {
  /** Left side of line item, e.g. 'Subtotal' */
  'title': string,
  /** How you should display this line. Assume 'normal' if this is undefined. */
  'type'?: CodegenEnumOpenValues<'normal' | 'discount' | 'total' | 'guidance'>,
  /** The full-fledged discount object associated with this line item, if any. May not be populated in contexts where only display info is needed, like order details. */
  'discount'?: Discount,
  /** Right side of line item, e.g. '$100' */
  'value': string,
  'tooltip'?: TallyLineItemTooltip,
};
export type EncodedCheckoutDisplayInfoFinalTallyLineItem = {
  'title': string,
  'type'?: string,
  'discount'?: EncodedDiscount,
  'value': string,
  'tooltip'?: EncodedTallyLineItemTooltip,
};
export const CheckoutDisplayInfoFinalTallyLineItem: t.Type<CheckoutDisplayInfoFinalTallyLineItem, EncodedCheckoutDisplayInfoFinalTallyLineItem> = t.recursion('CheckoutDisplayInfoFinalTallyLineItem', () => (
  t.exact(t.intersection([t.type({
    'title': t.string,
    'value': t.string,
  }), t.partial({
    'type': CodegenEnumOpen({
      'normal': null,
      'discount': null,
      'total': null,
      'guidance': null,
    }, 'CheckoutDisplayInfoFinalTallyLineItem'),
    'discount': Discount,
    'tooltip': TallyLineItemTooltip,
  })]), 'CheckoutDisplayInfoFinalTallyLineItem')
));


export type OrderDisplayInfoFinalTallyLine = {
  /** Left side of line item, e.g. 'Subtotal' */
  'title': string,
  /** How you should display this line. Assume 'normal' if this is undefined. */
  'type'?: CodegenEnumOpenValues<'normal' | 'discount' | 'shipping' | 'shippingDiscount' | 'shipWithMable' | 'total' | 'guidance'>,
  /** Right side of line item, e.g. '$100' */
  'value': string,
  'tooltip'?: TallyLineItemTooltip,
};
export type EncodedOrderDisplayInfoFinalTallyLine = {
  'title': string,
  'type'?: string,
  'value': string,
  'tooltip'?: EncodedTallyLineItemTooltip,
};
export const OrderDisplayInfoFinalTallyLine: t.Type<OrderDisplayInfoFinalTallyLine, EncodedOrderDisplayInfoFinalTallyLine> = t.recursion('OrderDisplayInfoFinalTallyLine', () => (
  t.exact(t.intersection([t.type({
    'title': t.string,
    'value': t.string,
  }), t.partial({
    'type': CodegenEnumOpen({
      'normal': null,
      'discount': null,
      'shipping': null,
      'shippingDiscount': null,
      'shipWithMable': null,
      'total': null,
      'guidance': null,
    }, 'OrderDisplayInfoFinalTallyLine'),
    'tooltip': TallyLineItemTooltip,
  })]), 'OrderDisplayInfoFinalTallyLine')
));


export type OrderDisplayInfoActionId = CodegenEnumOpenValues<'viewPurchaseOrder' | 'viewPackingSlip' | 'uploadInvoice' | 'addShippingInfo' | 'enterShippingFlow' | 'modifyOrder' | 'reportIssue'>;
export type EncodedOrderDisplayInfoActionId = string;
export const OrderDisplayInfoActionId = CodegenEnumOpen({
  'viewPurchaseOrder': null,
  'viewPackingSlip': null,
  'uploadInvoice': null,
  'addShippingInfo': null,
  'enterShippingFlow': null,
  'modifyOrder': null,
  'reportIssue': null,
}, 'OrderDisplayInfoActionId');
export const OrderDisplayInfoActionIdValues = ['viewPurchaseOrder', 'viewPackingSlip', 'uploadInvoice', 'addShippingInfo', 'enterShippingFlow', 'modifyOrder', 'reportIssue'] as const;

export type OrderDisplayInfoAction = {
  'id': OrderDisplayInfoActionId,
  /** If true, display a disabled button for this action. */
  'disabled'?: boolean,
  /** Present iff disabled. An explanation to the user of why this action is disabled. */
  'disabledReason'?: string,
};
export type EncodedOrderDisplayInfoAction = {
  'id': EncodedOrderDisplayInfoActionId,
  'disabled'?: boolean,
  'disabledReason'?: string,
};
export const OrderDisplayInfoAction: t.Type<OrderDisplayInfoAction, EncodedOrderDisplayInfoAction> = t.recursion('OrderDisplayInfoAction', () => (
  t.exact(t.intersection([t.type({
    'id': OrderDisplayInfoActionId,
  }), t.partial({
    'disabled': t.boolean,
    'disabledReason': t.string,
  })]), 'OrderDisplayInfoAction')
));


export type OrderDisplayInfoLog = {
  'date': Date,
  'title': string,
  'body'?: string,
  'attachments'?: Array<OrderDisplayInfoLogAttachment>,
};
export type EncodedOrderDisplayInfoLog = {
  'date': string,
  'title': string,
  'body'?: string,
  'attachments'?: Array<EncodedOrderDisplayInfoLogAttachment>,
};
export const OrderDisplayInfoLog: t.Type<OrderDisplayInfoLog, EncodedOrderDisplayInfoLog> = t.recursion('OrderDisplayInfoLog', () => (
  t.exact(t.intersection([t.type({
    'date': DateFromISOString,
    'title': t.string,
  }), t.partial({
    'body': t.string,
    'attachments': t.array(OrderDisplayInfoLogAttachment),
  })]), 'OrderDisplayInfoLog')
));


export type OrderDisplayInfoLogAttachment = {
  /** This ID will be used to call an API that does not exist yet to get attachment contents. */
  'id': string,
  'label': string,
  'filename': string,
};
export type EncodedOrderDisplayInfoLogAttachment = {
  'id': string,
  'label': string,
  'filename': string,
};
export const OrderDisplayInfoLogAttachment: t.Type<OrderDisplayInfoLogAttachment, EncodedOrderDisplayInfoLogAttachment> = t.recursion('OrderDisplayInfoLogAttachment', () => (
  t.exact(t.intersection([t.type({
    'id': t.string,
    'label': t.string,
    'filename': t.string,
  }), t.partial({
    
  })]), 'OrderDisplayInfoLogAttachment')
));


export type Order = {
  'stripeCustomerId'?: string,
  'stripeInvoiceId'?: string,
  /** A date specified in an EDI purchase order */
  'ediDate'?: Date,
  /** The vendor ID specified in an EDI purchase order */
  'ediVendorId'?: string,
  /** The ship from address specified in an EDI purchase order */
  'ediShipFromAddressId'?: number,
  /** The version of the ship from address specified in an EDI purchase order */
  'ediShipFromAddressVersion'?: number,
  /** The ship from address specified in an EDI purchase order */
  'ediShipFromAddress'?: Address,
  /** The id of the bill to address specified in an EDI purchase order */
  'ediBillToAddressId'?: number,
  /** The version of the bill to address specified in an EDI purchase order */
  'ediBillToAddressVersion'?: number,
  /** The bill to address specified in an EDI purchase order */
  'ediBillToAddress'?: Address,
};
export type EncodedOrder = {
  'stripeCustomerId'?: string,
  'stripeInvoiceId'?: string,
  'ediDate'?: string,
  'ediVendorId'?: string,
  'ediShipFromAddressId'?: number,
  'ediShipFromAddressVersion'?: number,
  'ediShipFromAddress'?: EncodedAddress,
  'ediBillToAddressId'?: number,
  'ediBillToAddressVersion'?: number,
  'ediBillToAddress'?: EncodedAddress,
};
export const Order: t.Type<Order, EncodedOrder> = t.recursion('Order', () => (
  t.exact(t.intersection([t.type({
    
  }), t.partial({
    'stripeCustomerId': t.string,
    'stripeInvoiceId': t.string,
    'ediDate': DateFromISOString,
    'ediVendorId': t.string,
    'ediShipFromAddressId': t.number,
    'ediShipFromAddressVersion': t.number,
    'ediShipFromAddress': Address,
    'ediBillToAddressId': t.number,
    'ediBillToAddressVersion': t.number,
    'ediBillToAddress': Address,
  })]), 'Order')
));


export type TransactionWithAssociations = {
  'transaction': Transaction,
  'account'?: AccountAdminDisplayInfo,
  'productSeller'?: SellerAdminDisplayInfo,
  'products': Array<Product>,
};
export type EncodedTransactionWithAssociations = {
  'transaction': EncodedTransaction,
  'account'?: EncodedAccountAdminDisplayInfo,
  'productSeller'?: EncodedSellerAdminDisplayInfo,
  'products': Array<EncodedProduct>,
};
export const TransactionWithAssociations: t.Type<TransactionWithAssociations, EncodedTransactionWithAssociations> = t.recursion('TransactionWithAssociations', () => (
  t.exact(t.intersection([t.type({
    'transaction': Transaction,
    'products': t.array(Product),
  }), t.partial({
    'account': AccountAdminDisplayInfo,
    'productSeller': SellerAdminDisplayInfo,
  })]), 'TransactionWithAssociations')
));


export type TransactionCreditType = CodegenEnumOpenValues<'shippingDamage' | 'missingProduct' | 'other' | 'shipping'>;
export type EncodedTransactionCreditType = string;
export const TransactionCreditType = CodegenEnumOpen({
  'shippingDamage': null,
  'missingProduct': null,
  'other': null,
  'shipping': null,
}, 'TransactionCreditType');
export const TransactionCreditTypeValues = ['shippingDamage', 'missingProduct', 'other', 'shipping'] as const;

export type TransactionCredit = {
  /** The amount of money to credit. */
  'amount': BigNumber,
  /** What is this credit for (shipping damages or general credit). Determines whether the credit is debited from mable's shipping damages account or not. Should be 'shipping' if so, defaults to the main mable account. */
  'type': TransactionCreditType,
  'extraData'?: Record<string, unknown>,
  /** If this credit is for a product, how many retail units of the product it represents. This is not the same as the quantity of a line item, which could be for a case containing many "eaches". */
  'eachCount'?: BigNumber,
  /** If this credit is for a specific split on the transaction, its id. i.e. If this is a crediting for damaged product, this id should reference the existing split id for that product, from which other information will be copied. */
  'forSplitId'?: number,
};
export type EncodedTransactionCredit = {
  'amount': string,
  'type': EncodedTransactionCreditType,
  'extraData'?: Record<string, unknown>,
  'eachCount'?: string,
  'forSplitId'?: number,
};
export const TransactionCredit: t.Type<TransactionCredit, EncodedTransactionCredit> = t.recursion('TransactionCredit', () => (
  t.exact(t.intersection([t.type({
    'amount': NumberFromString,
    'type': TransactionCreditType,
  }), t.partial({
    'extraData': t.record(t.string, t.unknown),
    'eachCount': NumberFromString,
    'forSplitId': t.number,
  })]), 'TransactionCredit')
));


export type TransactionCreditApplicationInputTargetType = CodegenEnumOpenValues<'buyer' | 'seller'>;
export type EncodedTransactionCreditApplicationInputTargetType = string;
export const TransactionCreditApplicationInputTargetType = CodegenEnumOpen({
  'buyer': null,
  'seller': null,
}, 'TransactionCreditApplicationInputTargetType');
export const TransactionCreditApplicationInputTargetTypeValues = ['buyer', 'seller'] as const;

export type TransactionCreditApplicationInput = {
  'credits': Array<TransactionCredit>,
  /** An internal-only note about the reason for this credit. */
  'note': string,
  'targetAccount': TransactionCreditApplicationInputTargetType,
};
export type EncodedTransactionCreditApplicationInput = {
  'credits': Array<EncodedTransactionCredit>,
  'note': string,
  'targetAccount': EncodedTransactionCreditApplicationInputTargetType,
};
export const TransactionCreditApplicationInput: t.Type<TransactionCreditApplicationInput, EncodedTransactionCreditApplicationInput> = t.recursion('TransactionCreditApplicationInput', () => (
  t.exact(t.intersection([t.type({
    'credits': t.array(TransactionCredit),
    'note': t.string,
    'targetAccount': TransactionCreditApplicationInputTargetType,
  }), t.partial({
    
  })]), 'TransactionCreditApplicationInput')
));


export type Checkout = {
  'idempotencyId': string,
  /** The ids of the sellers you want to checkout for. */
  'sellerIds': Array<number>,
  /** The buyer this order is for. This determines the shipping address. Must be a buyer owned by your account. Otherwise, the shipping address will fallback to the first available buyer. */
  'buyerId'?: number,
  /** The buyer's specified shipTo address;  In the future, this will become required */
  'shipToAddressId': number,
  /** The buyer location this order should be associated with */
  'buyerLocationId'?: string,
  /** Buyer specified external PO number. Not unique, even within a specific buyer. */
  'externalPurchaseOrderNumber'?: string,
};
export type EncodedCheckout = {
  'idempotencyId': string,
  'sellerIds': Array<number>,
  'buyerId'?: number,
  'shipToAddressId': number,
  'buyerLocationId'?: string,
  'externalPurchaseOrderNumber'?: string,
};
export const Checkout: t.Type<Checkout, EncodedCheckout> = t.recursion('Checkout', () => (
  t.exact(t.intersection([t.type({
    'idempotencyId': t.string,
    'sellerIds': t.array(t.number),
    'shipToAddressId': t.number,
  }), t.partial({
    'buyerId': t.number,
    'buyerLocationId': t.string,
    'externalPurchaseOrderNumber': t.string,
  })]), 'Checkout')
));


export type CartOrder = {
  /** The buyer this order is for. This determines the shipping address. Must be a buyer owned by your account. Otherwise, the shipping address will fallback to the first available buyer. */
  'buyerId'?: number,
  'idempotencyId': string,
  /** The buyer's specified shipTo address;  In the future, this will become required */
  'shipToAddressId': number,
  'items': Array<CartItem>,
  'buyerNotes'?: Array<CartOrderBuyerNote>,
  'discountCodes'?: Array<string>,
  'buyerLocationId'?: string,
};
export type EncodedCartOrder = {
  'buyerId'?: number,
  'idempotencyId': string,
  'shipToAddressId': number,
  'items': Array<EncodedCartItem>,
  'buyerNotes'?: Array<EncodedCartOrderBuyerNote>,
  'discountCodes'?: Array<string>,
  'buyerLocationId'?: string,
};
export const CartOrder: t.Type<CartOrder, EncodedCartOrder> = t.recursion('CartOrder', () => (
  t.exact(t.intersection([t.type({
    'idempotencyId': t.string,
    'shipToAddressId': t.number,
    'items': t.array(CartItem),
  }), t.partial({
    'buyerId': t.number,
    'buyerNotes': t.array(CartOrderBuyerNote),
    'discountCodes': t.array(t.string),
    'buyerLocationId': t.string,
  })]), 'CartOrder')
));


export type CartOrderBuyerNote = {
  'sellerId': number,
  'buyerNote': string,
};
export type EncodedCartOrderBuyerNote = {
  'sellerId': number,
  'buyerNote': string,
};
export const CartOrderBuyerNote: t.Type<CartOrderBuyerNote, EncodedCartOrderBuyerNote> = t.recursion('CartOrderBuyerNote', () => (
  t.exact(t.intersection([t.type({
    'sellerId': t.number,
    'buyerNote': t.string,
  }), t.partial({
    
  })]), 'CartOrderBuyerNote')
));


export type CartItem = {
  'productVariantId': number,
  'quantity': number,
  /** Used when we recieve a purchase order via EDI */
  'ediLineItemId'?: string,
  /** Used when we recieve a purchase order via EDI */
  'ediSku'?: string,
  /** Used when we recieve a purchase order via EDI */
  'ediUpc'?: string,
};
export type EncodedCartItem = {
  'productVariantId': number,
  'quantity': number,
  'ediLineItemId'?: string,
  'ediSku'?: string,
  'ediUpc'?: string,
};
export const CartItem: t.Type<CartItem, EncodedCartItem> = t.recursion('CartItem', () => (
  t.exact(t.intersection([t.type({
    'productVariantId': t.number,
    'quantity': t.number,
  }), t.partial({
    'ediLineItemId': t.string,
    'ediSku': t.string,
    'ediUpc': t.string,
  })]), 'CartItem')
));


export type UserCredentials = {
  'email': string,
  'password': string,
  'invitationToken'?: string,
};
export type EncodedUserCredentials = {
  'email': string,
  'password': string,
  'invitationToken'?: string,
};
export const UserCredentials: t.Type<UserCredentials, EncodedUserCredentials> = t.recursion('UserCredentials', () => (
  t.exact(t.intersection([t.type({
    'email': t.string,
    'password': t.string,
  }), t.partial({
    'invitationToken': t.string,
  })]), 'UserCredentials')
));


export type InceptionMethod = CodegenEnumOpenValues<'sample' | 'vendor' | 'searchEngine' | 'socialMedia' | 'sales' | 'wordOfMouth' | 'other'>;
export type EncodedInceptionMethod = string;
export const InceptionMethod = CodegenEnumOpen({
  'sample': null,
  'vendor': null,
  'searchEngine': null,
  'socialMedia': null,
  'sales': null,
  'wordOfMouth': null,
  'other': null,
}, 'InceptionMethod');
export const InceptionMethodValues = ['sample', 'vendor', 'searchEngine', 'socialMedia', 'sales', 'wordOfMouth', 'other'] as const;

export type NonSensitiveUserSignup = {
  'type': CodegenEnumOpenValues<'buyer' | 'seller'>,
  'contact'?: Contact,
  'websiteUrl'?: string,
  'businessName'?: string,
  'referralCode'?: string,
  'storeCategory'?: string,
  'locationsRange'?: string,
  'externalCustomerNumber'?: string,
  /** The external partner who determined the customer number in externalPartnerId. Currently, this should be the slug of the seller. */
  'externalPartnerId'?: string,
  'postalCode'?: string,
  'ein'?: string,
  'inceptionMethod'?: InceptionMethod,
  'deviceId'?: string,
  'attribution'?: AttributionData,
};
export type EncodedNonSensitiveUserSignup = {
  'type': string,
  'contact'?: EncodedContact,
  'websiteUrl'?: string,
  'businessName'?: string,
  'referralCode'?: string,
  'storeCategory'?: string,
  'locationsRange'?: string,
  'externalCustomerNumber'?: string,
  'externalPartnerId'?: string,
  'postalCode'?: string,
  'ein'?: string,
  'inceptionMethod'?: EncodedInceptionMethod,
  'deviceId'?: string,
  'attribution'?: EncodedAttributionData,
};
export const NonSensitiveUserSignup: t.Type<NonSensitiveUserSignup, EncodedNonSensitiveUserSignup> = t.recursion('NonSensitiveUserSignup', () => (
  t.exact(t.intersection([t.type({
    'type': CodegenEnumOpen({
      'buyer': null,
      'seller': null,
    }, 'NonSensitiveUserSignup'),
  }), t.partial({
    'contact': Contact,
    'websiteUrl': t.string,
    'businessName': t.string,
    'referralCode': t.string,
    'storeCategory': t.string,
    'locationsRange': t.string,
    'externalCustomerNumber': t.string,
    'externalPartnerId': t.string,
    'postalCode': t.string,
    'ein': t.string,
    'inceptionMethod': InceptionMethod,
    'deviceId': t.string,
    'attribution': AttributionData,
  })]), 'NonSensitiveUserSignup')
));


export type UserSignup = NonSensitiveUserSignup & {
  'credentials': UserCredentials,
};
export type EncodedUserSignup = EncodedNonSensitiveUserSignup & {
  'credentials': EncodedUserCredentials,
};
export const UserSignup: t.Type<UserSignup, EncodedUserSignup> = t.recursion('UserSignup', () => (
  t.intersection([
    NonSensitiveUserSignup,
    t.exact(t.intersection([t.type({
      'credentials': UserCredentials,
    }), t.partial({
      
    })]), 'UserSignup')
  ], 'UserSignup')
));


export type UserIdentifier = {
  'id'?: number,
  'type'?: string,
  'identifier'?: string,
};
export type EncodedUserIdentifier = {
  'id'?: number,
  'type'?: string,
  'identifier'?: string,
};
export const UserIdentifier: t.Type<UserIdentifier, EncodedUserIdentifier> = t.recursion('UserIdentifier', () => (
  t.exact(t.intersection([t.type({
    
  }), t.partial({
    'id': t.number,
    'type': t.string,
    'identifier': t.string,
  })]), 'UserIdentifier')
));


export type AccountUser = {
  'id': number,
  'identifiers'?: Array<UserIdentifier>,
  'contact'?: Contact,
  'buyerLocations'?: Array<BuyerLocation>,
};
export type EncodedAccountUser = {
  'id': number,
  'identifiers'?: Array<EncodedUserIdentifier>,
  'contact'?: EncodedContact,
  'buyerLocations'?: Array<EncodedBuyerLocation>,
};
export const AccountUser: t.Type<AccountUser, EncodedAccountUser> = t.recursion('AccountUser', () => (
  t.exact(t.intersection([t.type({
    'id': t.number,
  }), t.partial({
    'identifiers': t.array(UserIdentifier),
    'contact': Contact,
    'buyerLocations': t.array(BuyerLocation),
  })]), 'AccountUser')
));


/** A small amount of info about a user that belongs to a buyer account */
export type BuyerUserInfo = {
  'userId': number,
  'buyerId': number,
  'businessName'?: string,
  'customerNumber'?: string,
};
export type EncodedBuyerUserInfo = {
  'userId': number,
  'buyerId': number,
  'businessName'?: string,
  'customerNumber'?: string,
};
export const BuyerUserInfo: t.Type<BuyerUserInfo, EncodedBuyerUserInfo> = t.recursion('BuyerUserInfo', () => (
  t.exact(t.intersection([t.type({
    'userId': t.number,
    'buyerId': t.number,
  }), t.partial({
    'businessName': t.string,
    'customerNumber': t.string,
  })]), 'BuyerUserInfo')
));


export type AccountInvitation = {
  'id': number,
  'invitedEmail'?: string,
  'buyerLocationId'?: string,
};
export type EncodedAccountInvitation = {
  'id': number,
  'invitedEmail'?: string,
  'buyerLocationId'?: string,
};
export const AccountInvitation: t.Type<AccountInvitation, EncodedAccountInvitation> = t.recursion('AccountInvitation', () => (
  t.exact(t.intersection([t.type({
    'id': t.number,
  }), t.partial({
    'invitedEmail': t.string,
    'buyerLocationId': t.string,
  })]), 'AccountInvitation')
));


export type AccountAdminDisplayInfo = {
  'id': number,
  'name'?: string,
  'users': Array<AccountUser>,
  'invitations': Array<AccountInvitation>,
  'stripeCustomerId'?: string,
  'buyer'?: Buyer,
  'seller'?: SellerAdminDisplayInfo,
  'partnerAccount'?: PartnerAccountAdminDisplayInfo,
  'flags'?: Array<AccountFlag>,
  'tags'?: Array<string>,
  'sftpUsername'?: string,
  'supportContact'?: Contact,
  'additionalIdentifiers'?: Array<AccountIdentifier>,
};
export type EncodedAccountAdminDisplayInfo = {
  'id': number,
  'name'?: string,
  'users': Array<EncodedAccountUser>,
  'invitations': Array<EncodedAccountInvitation>,
  'stripeCustomerId'?: string,
  'buyer'?: EncodedBuyer,
  'seller'?: EncodedSellerAdminDisplayInfo,
  'partnerAccount'?: EncodedPartnerAccountAdminDisplayInfo,
  'flags'?: Array<EncodedAccountFlag>,
  'tags'?: Array<string>,
  'sftpUsername'?: string,
  'supportContact'?: EncodedContact,
  'additionalIdentifiers'?: Array<EncodedAccountIdentifier>,
};
export const AccountAdminDisplayInfo: t.Type<AccountAdminDisplayInfo, EncodedAccountAdminDisplayInfo> = t.recursion('AccountAdminDisplayInfo', () => (
  t.exact(t.intersection([t.type({
    'id': t.number,
    'users': t.array(AccountUser),
    'invitations': t.array(AccountInvitation),
  }), t.partial({
    'name': t.string,
    'stripeCustomerId': t.string,
    'buyer': Buyer,
    'seller': SellerAdminDisplayInfo,
    'partnerAccount': PartnerAccountAdminDisplayInfo,
    'flags': t.array(AccountFlag),
    'tags': t.array(t.string),
    'sftpUsername': t.string,
    'supportContact': Contact,
    'additionalIdentifiers': t.array(AccountIdentifier),
  })]), 'AccountAdminDisplayInfo')
));


export type AccountFlag = {
  'name'?: Flag,
  'displayName'?: string,
  'detail'?: string,
  'defaultValue'?: boolean,
  'assignedValue'?: boolean,
  'overrideValue'?: boolean,
  'value'?: boolean,
  'userControlled'?: boolean,
  'tags'?: Array<string>,
};
export type EncodedAccountFlag = {
  'name'?: EncodedFlag,
  'displayName'?: string,
  'detail'?: string,
  'defaultValue'?: boolean,
  'assignedValue'?: boolean,
  'overrideValue'?: boolean,
  'value'?: boolean,
  'userControlled'?: boolean,
  'tags'?: Array<string>,
};
export const AccountFlag: t.Type<AccountFlag, EncodedAccountFlag> = t.recursion('AccountFlag', () => (
  t.exact(t.intersection([t.type({
    
  }), t.partial({
    'name': Flag,
    'displayName': t.string,
    'detail': t.string,
    'defaultValue': t.boolean,
    'assignedValue': t.boolean,
    'overrideValue': t.boolean,
    'value': t.boolean,
    'userControlled': t.boolean,
    'tags': t.array(t.string),
  })]), 'AccountFlag')
));


export type AccountFlagValue = {
  'name': Flag,
  'value'?: null | boolean,
};
export type EncodedAccountFlagValue = {
  'name': EncodedFlag,
  'value'?: null | boolean,
};
export const AccountFlagValue: t.Type<AccountFlagValue, EncodedAccountFlagValue> = t.recursion('AccountFlagValue', () => (
  t.exact(t.intersection([t.type({
    'name': Flag,
  }), t.partial({
    'value': t.union([t.null, t.boolean]),
  })]), 'AccountFlagValue')
));


export type AccountDisplayInfo = {
  'id'?: number,
  'buyer'?: Buyer,
  'seller'?: SellerPersonalDisplayInfo,
  'users'?: Array<UserDisplayInfo>,
  'flags'?: Array<AccountFlagValue>,
  /** Boolean only returned when requesting with an invitationToken
  that indicates if the invitation has already been used */
  'invitationConsumed'?: boolean,
  /** An extension invitationConsumed that provides you with the email that consumed the invitation. */
  'invitationConsumedBy'?: string,
  /** The region that prices displayed for the current user will come from. Note that these aren't necessarily the
  prices this account/user will actually pay, that depends on how the prices are calculated when an order happens. */
  'displayPricingRegion'?: PricingRegion,
};
export type EncodedAccountDisplayInfo = {
  'id'?: number,
  'buyer'?: EncodedBuyer,
  'seller'?: EncodedSellerPersonalDisplayInfo,
  'users'?: Array<EncodedUserDisplayInfo>,
  'flags'?: Array<EncodedAccountFlagValue>,
  'invitationConsumed'?: boolean,
  'invitationConsumedBy'?: string,
  'displayPricingRegion'?: EncodedPricingRegion,
};
export const AccountDisplayInfo: t.Type<AccountDisplayInfo, EncodedAccountDisplayInfo> = t.recursion('AccountDisplayInfo', () => (
  t.exact(t.intersection([t.type({
    
  }), t.partial({
    'id': t.number,
    'buyer': Buyer,
    'seller': SellerPersonalDisplayInfo,
    'users': t.array(UserDisplayInfo),
    'flags': t.array(AccountFlagValue),
    'invitationConsumed': t.boolean,
    'invitationConsumedBy': t.string,
    'displayPricingRegion': PricingRegion,
  })]), 'AccountDisplayInfo')
));


export type UserDisplayInfo = {
  /** The id of the user */
  'userId'?: number,
  /** The identifier (email) of the user */
  'identifier'?: string,
  'contact'?: Contact,
  /** Whether this actually represents a pending invitation that doesn't have a user yet. */
  'isInvitation': boolean,
  /** True if you, the authenticated user, are this user. */
  'isYou'?: boolean,
  /** If this user is provisioned for messaging, this will have a value. */
  'sendbirdUserId'?: string,
  /** If this user should auto join newly created channels */
  'addToNewSellerMessageChannels'?: boolean,
  'buyerLocations'?: Array<BuyerLocation>,
  /** The id of the buyer location the user was invited to join */
  'inviteBuyerLocationId'?: string,
};
export type EncodedUserDisplayInfo = {
  'userId'?: number,
  'identifier'?: string,
  'contact'?: EncodedContact,
  'isInvitation': boolean,
  'isYou'?: boolean,
  'sendbirdUserId'?: string,
  'addToNewSellerMessageChannels'?: boolean,
  'buyerLocations'?: Array<EncodedBuyerLocation>,
  'inviteBuyerLocationId'?: string,
};
export const UserDisplayInfo: t.Type<UserDisplayInfo, EncodedUserDisplayInfo> = t.recursion('UserDisplayInfo', () => (
  t.exact(t.intersection([t.type({
    'isInvitation': t.boolean,
  }), t.partial({
    'userId': t.number,
    'identifier': t.string,
    'contact': Contact,
    'isYou': t.boolean,
    'sendbirdUserId': t.string,
    'addToNewSellerMessageChannels': t.boolean,
    'buyerLocations': t.array(BuyerLocation),
    'inviteBuyerLocationId': t.string,
  })]), 'UserDisplayInfo')
));


export type FilterGroup = {
  'id': string,
  'name': string,
  /** How many values in this group can be selected at once - either 1 or 0 (radio buttons) or any number (checkboxes). */
  'select': CodegenEnumOpenValues<'one' | 'many'>,
  /** If true, display values of this filter group as individually-selectable buttons rather than a dropdown/accordion when there's enough screen space. For mobile, display the accordion section as intiailly expanded. */
  'explode'?: boolean,
  'values': Array<FilterGroupValue>,
  'hierarchy'?: Array<FilterGroupHierarchy>,
};
export type EncodedFilterGroup = {
  'id': string,
  'name': string,
  'select': string,
  'explode'?: boolean,
  'values': Array<EncodedFilterGroupValue>,
  'hierarchy'?: Array<EncodedFilterGroupHierarchy>,
};
export const FilterGroup: t.Type<FilterGroup, EncodedFilterGroup> = t.recursion('FilterGroup', () => (
  t.exact(t.intersection([t.type({
    'id': t.string,
    'name': t.string,
    'select': CodegenEnumOpen({
      'one': null,
      'many': null,
    }, 'FilterGroup'),
    'values': t.array(FilterGroupValue),
  }), t.partial({
    'explode': t.boolean,
    'hierarchy': t.array(FilterGroupHierarchy),
  })]), 'FilterGroup')
));


export type FilterGroupValue = {
  'id': string,
  'name': string,
};
export type EncodedFilterGroupValue = {
  'id': string,
  'name': string,
};
export const FilterGroupValue: t.Type<FilterGroupValue, EncodedFilterGroupValue> = t.recursion('FilterGroupValue', () => (
  t.exact(t.intersection([t.type({
    'id': t.string,
    'name': t.string,
  }), t.partial({
    
  })]), 'FilterGroupValue')
));


export type FilterGroupHierarchy = FilterGroupValue & {
  'childFilterIds': Array<string>,
};
export type EncodedFilterGroupHierarchy = EncodedFilterGroupValue & {
  'childFilterIds': Array<string>,
};
export const FilterGroupHierarchy: t.Type<FilterGroupHierarchy, EncodedFilterGroupHierarchy> = t.recursion('FilterGroupHierarchy', () => (
  t.intersection([
    FilterGroupValue,
    t.exact(t.intersection([t.type({
      'childFilterIds': t.array(t.string),
    }), t.partial({
      
    })]), 'FilterGroupHierarchy')
  ], 'FilterGroupHierarchy')
));


export type FilterGroupSelection = {
  /** An array of the FilterGroupValue ids selected for this FilterGroup */
  'selections': Array<string>,
};
export type EncodedFilterGroupSelection = {
  'selections': Array<string>,
};
export const FilterGroupSelection: t.Type<FilterGroupSelection, EncodedFilterGroupSelection> = t.recursion('FilterGroupSelection', () => (
  t.exact(t.intersection([t.type({
    'selections': t.array(t.string),
  }), t.partial({
    
  })]), 'FilterGroupSelection')
));


export type SearchSortMethod = {
  'id': string,
  'name': string,
};
export type EncodedSearchSortMethod = {
  'id': string,
  'name': string,
};
export const SearchSortMethod: t.Type<SearchSortMethod, EncodedSearchSortMethod> = t.recursion('SearchSortMethod', () => (
  t.exact(t.intersection([t.type({
    'id': t.string,
    'name': t.string,
  }), t.partial({
    
  })]), 'SearchSortMethod')
));


export type RelatedProductSectionMetadata = {
  'sellerSlug'?: Slug,
  'sellerId'?: number,
  'categorySlug'?: Slug,
  'categoryId'?: number,
  /** Used in link generation for category */
  'isCuratedCollection'?: boolean,
};
export type EncodedRelatedProductSectionMetadata = {
  'sellerSlug'?: string,
  'sellerId'?: number,
  'categorySlug'?: string,
  'categoryId'?: number,
  'isCuratedCollection'?: boolean,
};
export const RelatedProductSectionMetadata: t.Type<RelatedProductSectionMetadata, EncodedRelatedProductSectionMetadata> = t.recursion('RelatedProductSectionMetadata', () => (
  t.exact(t.intersection([t.type({
    
  }), t.partial({
    'sellerSlug': Slug,
    'sellerId': t.number,
    'categorySlug': Slug,
    'categoryId': t.number,
    'isCuratedCollection': t.boolean,
  })]), 'RelatedProductSectionMetadata')
));


export type RelatedProductSection = {
  'title': string,
  /** Limited to 6 */
  'variants': Array<ProductVariantListDisplayInfo>,
  'metadata': RelatedProductSectionMetadata,
};
export type EncodedRelatedProductSection = {
  'title': string,
  'variants': Array<EncodedProductVariantListDisplayInfo>,
  'metadata': EncodedRelatedProductSectionMetadata,
};
export const RelatedProductSection: t.Type<RelatedProductSection, EncodedRelatedProductSection> = t.recursion('RelatedProductSection', () => (
  t.exact(t.intersection([t.type({
    'title': t.string,
    'variants': t.array(ProductVariantListDisplayInfo),
    'metadata': RelatedProductSectionMetadata,
  }), t.partial({
    
  })]), 'RelatedProductSection')
));


export type UploadedInvoiceMetadata = {
  'invoiceId': string,
  'shippingAmount': BigNumber,
  'invoiceAmount': BigNumber,
  's3Key': string,
  's3Bucket': string,
};
export type EncodedUploadedInvoiceMetadata = {
  'invoiceId': string,
  'shippingAmount': string,
  'invoiceAmount': string,
  's3Key': string,
  's3Bucket': string,
};
export const UploadedInvoiceMetadata: t.Type<UploadedInvoiceMetadata, EncodedUploadedInvoiceMetadata> = t.recursion('UploadedInvoiceMetadata', () => (
  t.exact(t.intersection([t.type({
    'invoiceId': t.string,
    'shippingAmount': NumberFromString,
    'invoiceAmount': NumberFromString,
    's3Key': t.string,
    's3Bucket': t.string,
  }), t.partial({
    
  })]), 'UploadedInvoiceMetadata')
));


export type ShippingDetailsMetadata = {
  'trackingInfos': Array<TrackingInfo>,
  'selfShipInfos': Array<SelfShipInfo>,
};
export type EncodedShippingDetailsMetadata = {
  'trackingInfos': Array<EncodedTrackingInfo>,
  'selfShipInfos': Array<EncodedSelfShipInfo>,
};
export const ShippingDetailsMetadata: t.Type<ShippingDetailsMetadata, EncodedShippingDetailsMetadata> = t.recursion('ShippingDetailsMetadata', () => (
  t.exact(t.intersection([t.type({
    'trackingInfos': t.array(TrackingInfo),
    'selfShipInfos': t.array(SelfShipInfo),
  }), t.partial({
    
  })]), 'ShippingDetailsMetadata')
));


export type TrackingInfo = {
  'number': string,
  'cost': BigNumber,
  /** Specific URL for tracking, if we should use that instead of deducing */
  'url'?: string,
};
export type EncodedTrackingInfo = {
  'number': string,
  'cost': string,
  'url'?: string,
};
export const TrackingInfo: t.Type<TrackingInfo, EncodedTrackingInfo> = t.recursion('TrackingInfo', () => (
  t.exact(t.intersection([t.type({
    'number': t.string,
    'cost': NumberFromString,
  }), t.partial({
    'url': t.string,
  })]), 'TrackingInfo')
));


export type SelfShipInfo = {
  'date': Date,
  'cost': BigNumber,
};
export type EncodedSelfShipInfo = {
  'date': string,
  'cost': string,
};
export const SelfShipInfo: t.Type<SelfShipInfo, EncodedSelfShipInfo> = t.recursion('SelfShipInfo', () => (
  t.exact(t.intersection([t.type({
    'date': DateFromISOString,
    'cost': NumberFromString,
  }), t.partial({
    
  })]), 'SelfShipInfo')
));


export type EmailInviteInfo = {
  'inviteEmails': Array<string>,
  'buyerLocationId'?: string,
};
export type EncodedEmailInviteInfo = {
  'inviteEmails': Array<string>,
  'buyerLocationId'?: string,
};
export const EmailInviteInfo: t.Type<EmailInviteInfo, EncodedEmailInviteInfo> = t.recursion('EmailInviteInfo', () => (
  t.exact(t.intersection([t.type({
    'inviteEmails': t.array(t.string),
  }), t.partial({
    'buyerLocationId': t.string,
  })]), 'EmailInviteInfo')
));


/** Describes how the category memberships for any given entity (not specified here) should be updated, without needing to comprehensively know all the categories the entity belongs to. */
export type CategoryMembershipPatch = {
  /** If not 'none', this entity will be removed from the types of categories specified here. */
  'categoryResetBehavior': CodegenEnumOpenValues<'all' | 'non-curated' | 'none'>,
  /** The entity will be added to these categories. */
  'categoryMemberships': Array<CategoryMembershipInput>,
  /** The entity will definitely be removed from these categories. */
  'removeFromCategoryIds'?: Array<number>,
};
export type EncodedCategoryMembershipPatch = {
  'categoryResetBehavior': string,
  'categoryMemberships': Array<EncodedCategoryMembershipInput>,
  'removeFromCategoryIds'?: Array<number>,
};
export const CategoryMembershipPatch: t.Type<CategoryMembershipPatch, EncodedCategoryMembershipPatch> = t.recursion('CategoryMembershipPatch', () => (
  t.exact(t.intersection([t.type({
    'categoryResetBehavior': CodegenEnumOpen({
      'all': null,
      'non-curated': null,
      'none': null,
    }, 'CategoryMembershipPatch'),
    'categoryMemberships': t.array(CategoryMembershipInput),
  }), t.partial({
    'removeFromCategoryIds': t.array(t.number),
  })]), 'CategoryMembershipPatch')
));


export type SellerProductVariantPatch = {
  'variantId': number,
  'name'?: string,
  'description'?: string,
  'price'?: null | string,
  'priceRetail'?: null | string,
  'wholesalePrice'?: BigNumber,
  'availability'?: ProductAvailability,
  'backInStockDate'?: null | Date,
  'ingredients'?: null | string,
  'storage'?: null | Array<ProductStorage>,
  'sku'?: null | string,
  'gtin'?: null | Gtin,
  'caseGtin'?: null | Gtin,
  'externalId'?: null | string,
  'eachCount'?: null | number,
  'eachName'?: null | string,
  'eachNamePlural'?: null | string,
  'eachSize'?: null | BigNumber,
  'eachSizeUnit'?: null | ProductSizeUnit,
  'eachPieceCount'?: null | number,
  'eachPieceName'?: null | string,
  'eachPieceNamePlural'?: null | string,
  'categorySlugChanges'?: {
    'add'?: Array<SellerPatchAllowedCategory>,
    'remove'?: Array<SellerPatchAllowedCategory>,
  },
  'allergenChanges'?: {
    'add'?: Array<string>,
    'remove'?: Array<string>,
  },
  'imageChanges'?: {
    'set'?: Array<ProductImage>,
    'add'?: Array<ProductImage>,
    /** The ids of the images to remove */
    'remove'?: Array<number>,
  },
  'productImageChanges'?: {
    'set'?: Array<ProductImage>,
    'add'?: Array<ProductImage>,
    /** The ids of the images to remove */
    'remove'?: Array<number>,
  },
  'packagingChanges'?: {
    'set'?: Array<ProductVariantPackaging>,
    'add'?: Array<ProductVariantPackaging>,
  },
  'upcExempt'?: boolean,
  'ingredientsExempt'?: boolean,
};
export type EncodedSellerProductVariantPatch = {
  'variantId': number,
  'name'?: string,
  'description'?: string,
  'price'?: null | string,
  'priceRetail'?: null | string,
  'wholesalePrice'?: string,
  'availability'?: EncodedProductAvailability,
  'backInStockDate'?: null | string,
  'ingredients'?: null | string,
  'storage'?: null | Array<EncodedProductStorage>,
  'sku'?: null | string,
  'gtin'?: null | string,
  'caseGtin'?: null | string,
  'externalId'?: null | string,
  'eachCount'?: null | number,
  'eachName'?: null | string,
  'eachNamePlural'?: null | string,
  'eachSize'?: null | string,
  'eachSizeUnit'?: null | EncodedProductSizeUnit,
  'eachPieceCount'?: null | number,
  'eachPieceName'?: null | string,
  'eachPieceNamePlural'?: null | string,
  'categorySlugChanges'?: {
    'add'?: Array<EncodedSellerPatchAllowedCategory>,
    'remove'?: Array<EncodedSellerPatchAllowedCategory>,
  },
  'allergenChanges'?: {
    'add'?: Array<string>,
    'remove'?: Array<string>,
  },
  'imageChanges'?: {
    'set'?: Array<EncodedProductImage>,
    'add'?: Array<EncodedProductImage>,
    'remove'?: Array<number>,
  },
  'productImageChanges'?: {
    'set'?: Array<EncodedProductImage>,
    'add'?: Array<EncodedProductImage>,
    'remove'?: Array<number>,
  },
  'packagingChanges'?: {
    'set'?: Array<EncodedProductVariantPackaging>,
    'add'?: Array<EncodedProductVariantPackaging>,
  },
  'upcExempt'?: boolean,
  'ingredientsExempt'?: boolean,
};
export const SellerProductVariantPatch: t.Type<SellerProductVariantPatch, EncodedSellerProductVariantPatch> = t.recursion('SellerProductVariantPatch', () => (
  t.exact(t.intersection([t.type({
    'variantId': t.number,
  }), t.partial({
    'name': t.string,
    'description': t.string,
    'price': t.union([t.null, t.string]),
    'priceRetail': t.union([t.null, t.string]),
    'wholesalePrice': NumberFromString,
    'availability': ProductAvailability,
    'backInStockDate': t.union([t.null, DateFromISOString]),
    'ingredients': t.union([t.null, t.string]),
    'storage': t.union([t.null, t.array(ProductStorage)]),
    'sku': t.union([t.null, t.string]),
    'gtin': t.union([t.null, Gtin]),
    'caseGtin': t.union([t.null, Gtin]),
    'externalId': t.union([t.null, t.string]),
    'eachCount': t.union([t.null, t.number]),
    'eachName': t.union([t.null, t.string]),
    'eachNamePlural': t.union([t.null, t.string]),
    'eachSize': t.union([t.null, NumberFromString]),
    'eachSizeUnit': t.union([t.null, ProductSizeUnit]),
    'eachPieceCount': t.union([t.null, t.number]),
    'eachPieceName': t.union([t.null, t.string]),
    'eachPieceNamePlural': t.union([t.null, t.string]),
    'categorySlugChanges': t.exact(t.intersection([t.type({
      
    }), t.partial({
      'add': t.array(SellerPatchAllowedCategory),
      'remove': t.array(SellerPatchAllowedCategory),
    })]), 'SellerProductVariantPatch'),
    'allergenChanges': t.exact(t.intersection([t.type({
      
    }), t.partial({
      'add': t.array(t.string),
      'remove': t.array(t.string),
    })]), 'SellerProductVariantPatch'),
    'imageChanges': t.exact(t.intersection([t.type({
      
    }), t.partial({
      'set': t.array(ProductImage),
      'add': t.array(ProductImage),
      'remove': t.array(t.number),
    })]), 'SellerProductVariantPatch'),
    'productImageChanges': t.exact(t.intersection([t.type({
      
    }), t.partial({
      'set': t.array(ProductImage),
      'add': t.array(ProductImage),
      'remove': t.array(t.number),
    })]), 'SellerProductVariantPatch'),
    'packagingChanges': t.exact(t.intersection([t.type({
      
    }), t.partial({
      'set': t.array(ProductVariantPackaging),
      'add': t.array(ProductVariantPackaging),
    })]), 'SellerProductVariantPatch'),
    'upcExempt': t.boolean,
    'ingredientsExempt': t.boolean,
  })]), 'SellerProductVariantPatch')
));


export type ProductVariantPatch = SellerProductVariantPatch & {
  /** List of comma separated allergens */
  'allergens'?: null | string,
  'categoryPatch'?: CategoryMembershipPatch,
  'identifiers'?: Array<ProductVariantIdentifierInput>,
  /** Mable Specific Stock Keeping Unit */
  'mableSku'?: string,
};
export type EncodedProductVariantPatch = EncodedSellerProductVariantPatch & {
  'allergens'?: null | string,
  'categoryPatch'?: EncodedCategoryMembershipPatch,
  'identifiers'?: Array<EncodedProductVariantIdentifierInput>,
  'mableSku'?: string,
};
export const ProductVariantPatch: t.Type<ProductVariantPatch, EncodedProductVariantPatch> = t.recursion('ProductVariantPatch', () => (
  t.intersection([
    SellerProductVariantPatch,
    t.exact(t.intersection([t.type({
      
    }), t.partial({
      'allergens': t.union([t.null, t.string]),
      'categoryPatch': CategoryMembershipPatch,
      'identifiers': t.array(ProductVariantIdentifierInput),
      'mableSku': t.string,
    })]), 'ProductVariantPatch')
  ], 'ProductVariantPatch')
));


/** Input for adding/updated/removing a buyer note for a seller. */
export type BuyerNoteInput = {
  /** The ID of the seller this note is meant for. */
  'sellerId': number,
  /** The note to the seller, from the buyer. If empty string or undefined, the note will be deleted as if this were a 'remove' action, regardless of the action property. */
  'note'?: string,
  /** What the user intends to do with this note. 'append' will add it to an existing note, if there is one. */
  'action': CodegenEnumOpenValues<'update' | 'append' | 'remove'>,
};
export type EncodedBuyerNoteInput = {
  'sellerId': number,
  'note'?: string,
  'action': string,
};
export const BuyerNoteInput: t.Type<BuyerNoteInput, EncodedBuyerNoteInput> = t.recursion('BuyerNoteInput', () => (
  t.exact(t.intersection([t.type({
    'sellerId': t.number,
    'action': CodegenEnumOpen({
      'update': null,
      'append': null,
      'remove': null,
    }, 'BuyerNoteInput'),
  }), t.partial({
    'note': t.string,
  })]), 'BuyerNoteInput')
));


/** Input for adding/updating/removing an item to your cart. */
export type CartItemInput = {
  'productVariantId': number,
  'quantity': number,
  /** Specifies the approach for how to update the quantity of the specified item. Using 'relative' and '2' will add '2' to the existing quantity. 'absolute' and '2' */
  'quantityTactic': 'absolute' | 'relative',
};
export type EncodedCartItemInput = {
  'productVariantId': number,
  'quantity': number,
  'quantityTactic': string,
};
export const CartItemInput: t.Type<CartItemInput, EncodedCartItemInput> = t.recursion('CartItemInput', () => (
  t.exact(t.intersection([t.type({
    'productVariantId': t.number,
    'quantity': t.number,
    'quantityTactic': CodegenEnumClosed({
      'absolute': null,
      'relative': null,
    }, 'CartItemInput'),
  }), t.partial({
    
  })]), 'CartItemInput')
));


export type CartItemDisplay = {
  'quantity': number,
  'variant': ProductVariantListDisplayInfo,
  /** A display ready string for subtotal of this item, */
  'subtotalDisplay': string,
};
export type EncodedCartItemDisplay = {
  'quantity': number,
  'variant': EncodedProductVariantListDisplayInfo,
  'subtotalDisplay': string,
};
export const CartItemDisplay: t.Type<CartItemDisplay, EncodedCartItemDisplay> = t.recursion('CartItemDisplay', () => (
  t.exact(t.intersection([t.type({
    'quantity': t.number,
    'variant': ProductVariantListDisplayInfo,
    'subtotalDisplay': t.string,
  }), t.partial({
    
  })]), 'CartItemDisplay')
));


/** A subset of the Seller object */
export type CartSeller = {
  'id': number,
  'slug': Slug,
  'name': string,
  'cold'?: boolean,
  /** If present, this is the minimum order total necessary to purchase from this seller. */
  'orderMinimum'?: BigNumber,
  /** If present, this is the minimum order total necessary to offer free shipping. */
  'freeShippingMinimum'?: BigNumber,
  'profileImage'?: Image,
  /** If this seller is a distributor. */
  'distributor'?: boolean,
  'shippingPolicy': ShippingPolicy,
  /** If this brand is available on the public, non-partnered Mable marketplace at meetmable.com. */
  'mableMarketplaceEnabled'?: boolean,
};
export type EncodedCartSeller = {
  'id': number,
  'slug': string,
  'name': string,
  'cold'?: boolean,
  'orderMinimum'?: string,
  'freeShippingMinimum'?: string,
  'profileImage'?: EncodedImage,
  'distributor'?: boolean,
  'shippingPolicy': EncodedShippingPolicy,
  'mableMarketplaceEnabled'?: boolean,
};
export const CartSeller: t.Type<CartSeller, EncodedCartSeller> = t.recursion('CartSeller', () => (
  t.exact(t.intersection([t.type({
    'id': t.number,
    'slug': Slug,
    'name': t.string,
    'shippingPolicy': ShippingPolicy,
  }), t.partial({
    'cold': t.boolean,
    'orderMinimum': NumberFromString,
    'freeShippingMinimum': NumberFromString,
    'profileImage': Image,
    'distributor': t.boolean,
    'mableMarketplaceEnabled': t.boolean,
  })]), 'CartSeller')
));


export type CartSellerGroup = {
  'seller': CartSeller,
  /** A big number representing the total of all priced items. */
  'subtotal': BigNumber,
  /** A display ready string for subtotal, e.g. $123.45. Will be empty sting if there are no prices. */
  'subtotalDisplay': string,
  /** A string to display free shipping or TBD, maybe one day with estimates it will also return an estimate */
  'shippingCostDisplay'?: string,
  /** true if the order minimum is unmet for this seller. */
  'orderMinimumUnmet': boolean,
  'cartItems': Array<CartItemDisplay>,
  /** The note the buyer has written for this seller. */
  'buyerNote'?: string,
};
export type EncodedCartSellerGroup = {
  'seller': EncodedCartSeller,
  'subtotal': string,
  'subtotalDisplay': string,
  'shippingCostDisplay'?: string,
  'orderMinimumUnmet': boolean,
  'cartItems': Array<EncodedCartItemDisplay>,
  'buyerNote'?: string,
};
export const CartSellerGroup: t.Type<CartSellerGroup, EncodedCartSellerGroup> = t.recursion('CartSellerGroup', () => (
  t.exact(t.intersection([t.type({
    'seller': CartSeller,
    'subtotal': NumberFromString,
    'subtotalDisplay': t.string,
    'orderMinimumUnmet': t.boolean,
    'cartItems': t.array(CartItemDisplay),
  }), t.partial({
    'shippingCostDisplay': t.string,
    'buyerNote': t.string,
  })]), 'CartSellerGroup')
));


export type CartDisplay = {
  'sellerGroups': Array<CartSellerGroup>,
  /** A big number representing the total of all priced items. Does not take into account discounts. */
  'subtotal': BigNumber,
  /** String to display for subtotal of all items in cart. Does not take into account discounts. */
  'subtotalDisplay': string,
  /** String to display the shipping costs (Free or TBD). May be money format when estimates come in the future. */
  'shippingCostDisplay': string,
  'checkoutEnabled': boolean,
  /** The discounts the user has saved to their cart */
  'discounts': Array<Discount>,
};
export type EncodedCartDisplay = {
  'sellerGroups': Array<EncodedCartSellerGroup>,
  'subtotal': string,
  'subtotalDisplay': string,
  'shippingCostDisplay': string,
  'checkoutEnabled': boolean,
  'discounts': Array<EncodedDiscount>,
};
export const CartDisplay: t.Type<CartDisplay, EncodedCartDisplay> = t.recursion('CartDisplay', () => (
  t.exact(t.intersection([t.type({
    'sellerGroups': t.array(CartSellerGroup),
    'subtotal': NumberFromString,
    'subtotalDisplay': t.string,
    'shippingCostDisplay': t.string,
    'checkoutEnabled': t.boolean,
    'discounts': t.array(Discount),
  }), t.partial({
    
  })]), 'CartDisplay')
));


/** Object that returns after updating a user's cart */
export type CartUpdateResult = {
  /** The full cart. */
  'cart'?: CartDisplay,
  /** Cart items for products in the cart that were updated. */
  'items'?: Array<CartItemDisplay>,
  /** Optional message that gets returned in the event of partial success */
  'message'?: string,
};
export type EncodedCartUpdateResult = {
  'cart'?: EncodedCartDisplay,
  'items'?: Array<EncodedCartItemDisplay>,
  'message'?: string,
};
export const CartUpdateResult: t.Type<CartUpdateResult, EncodedCartUpdateResult> = t.recursion('CartUpdateResult', () => (
  t.exact(t.intersection([t.type({
    
  }), t.partial({
    'cart': CartDisplay,
    'items': t.array(CartItemDisplay),
    'message': t.string,
  })]), 'CartUpdateResult')
));


export type CheckoutTallyLineItem = {
  'name': string,
  'value': string,
  'type': CodegenEnumOpenValues<'normal' | 'discount' | 'grand'>,
  /** The discount code represented by this line item, if it is for a discount code. */
  'discountCode'?: string,
};
export type EncodedCheckoutTallyLineItem = {
  'name': string,
  'value': string,
  'type': string,
  'discountCode'?: string,
};
export const CheckoutTallyLineItem: t.Type<CheckoutTallyLineItem, EncodedCheckoutTallyLineItem> = t.recursion('CheckoutTallyLineItem', () => (
  t.exact(t.intersection([t.type({
    'name': t.string,
    'value': t.string,
    'type': CodegenEnumOpen({
      'normal': null,
      'discount': null,
      'grand': null,
    }, 'CheckoutTallyLineItem'),
  }), t.partial({
    'discountCode': t.string,
  })]), 'CheckoutTallyLineItem')
));


export type CheckoutInfo = {
  'cart': CartDisplay,
  'paymentTerms'?: string,
  'paymentTermsShort'?: string,
  /** Lines that show up in the 'final tally' section - subtotal, commission, total, etc. Use this for display. */
  'finalTallyItems': Array<CheckoutDisplayInfoFinalTallyLineItem>,
  /** Addresses to use at checkout */
  'addresses': Array<Address>,
  /** Billing contacts that will receive invoices */
  'contacts'?: Array<Contact>,
  /** The payment methods on file for the buyer to present at checkout time. */
  'paymentMethods'?: Array<PaymentMethod>,
  /** If `true`, the user requires a payment method in order to continue and successfully check out, and currently does not have one. Thus; they must provide a payment card. */
  'paymentCardCollectionRequired': boolean,
  /** If `false`, don't render any UI for collecting or selecting a payment card. e.g. A user is checking out for a distributor that settles their own orders; it would be confusing to ask for a payment method that isn't relevant for the order being checked out. */
  'renderPaymentCardInterface': boolean,
  /** The amount that may be authorized on the card, if the terms dictate that will be necessary. */
  'cardHoldAmount'?: BigNumber,
  /** Possible Buyer Locations this order can go to */
  'buyerLocations': Array<BuyerLocation>,
  /** If the user should be allowed to update the invoice recipients during checkout */
  'canUpdateInvoiceRecipients'?: boolean,
};
export type EncodedCheckoutInfo = {
  'cart': EncodedCartDisplay,
  'paymentTerms'?: string,
  'paymentTermsShort'?: string,
  'finalTallyItems': Array<EncodedCheckoutDisplayInfoFinalTallyLineItem>,
  'addresses': Array<EncodedAddress>,
  'contacts'?: Array<EncodedContact>,
  'paymentMethods'?: Array<EncodedPaymentMethod>,
  'paymentCardCollectionRequired': boolean,
  'renderPaymentCardInterface': boolean,
  'cardHoldAmount'?: string,
  'buyerLocations': Array<EncodedBuyerLocation>,
  'canUpdateInvoiceRecipients'?: boolean,
};
export const CheckoutInfo: t.Type<CheckoutInfo, EncodedCheckoutInfo> = t.recursion('CheckoutInfo', () => (
  t.exact(t.intersection([t.type({
    'cart': CartDisplay,
    'finalTallyItems': t.array(CheckoutDisplayInfoFinalTallyLineItem),
    'addresses': t.array(Address),
    'paymentCardCollectionRequired': t.boolean,
    'renderPaymentCardInterface': t.boolean,
    'buyerLocations': t.array(BuyerLocation),
  }), t.partial({
    'paymentTerms': t.string,
    'paymentTermsShort': t.string,
    'contacts': t.array(Contact),
    'paymentMethods': t.array(PaymentMethod),
    'cardHoldAmount': NumberFromString,
    'canUpdateInvoiceRecipients': t.boolean,
  })]), 'CheckoutInfo')
));


export type SearchResults = {
  'variants'?: Array<ProductVariantListDisplayInfo>,
  /** The total number of variants matching this search. */
  'totalVariantCount'?: number,
  /** Map from product ID to product info. Only included if requested with `productsForVariants` or
  `previouslyOrderedProductsForVariants`. Currently, variants of a single product may be split between
  pages, even if they would otherwise have been returned. */
  'productsForVariants'?: Record<string, SearchResultsProductInfoForVariant>,
  'sellers'?: Array<SellerDisplayInfo>,
  /** The total number of sellers matching this search. */
  'totalSellerCount'?: number,
  'categories'?: Array<Category>,
  'filters'?: Array<FilterGroup>,
  'sortMethods'?: Array<SearchSortMethod>,
};
export type EncodedSearchResults = {
  'variants'?: Array<EncodedProductVariantListDisplayInfo>,
  'totalVariantCount'?: number,
  'productsForVariants'?: Record<string, EncodedSearchResultsProductInfoForVariant>,
  'sellers'?: Array<EncodedSellerDisplayInfo>,
  'totalSellerCount'?: number,
  'categories'?: Array<EncodedCategory>,
  'filters'?: Array<EncodedFilterGroup>,
  'sortMethods'?: Array<EncodedSearchSortMethod>,
};
export const SearchResults: t.Type<SearchResults, EncodedSearchResults> = t.recursion('SearchResults', () => (
  t.exact(t.intersection([t.type({
    
  }), t.partial({
    'variants': t.array(ProductVariantListDisplayInfo),
    'totalVariantCount': t.number,
    'productsForVariants': t.record(t.string, SearchResultsProductInfoForVariant),
    'sellers': t.array(SellerDisplayInfo),
    'totalSellerCount': t.number,
    'categories': t.array(Category),
    'filters': t.array(FilterGroup),
    'sortMethods': t.array(SearchSortMethod),
  })]), 'SearchResults')
));


export type SearchResultsProductInfoForVariant = {
  'name'?: string,
  /** Whether you've ordred this product before. Only included if requested with `previouslyOrderedProductsForVariants`. */
  'previouslyOrdered'?: boolean,
};
export type EncodedSearchResultsProductInfoForVariant = {
  'name'?: string,
  'previouslyOrdered'?: boolean,
};
export const SearchResultsProductInfoForVariant: t.Type<SearchResultsProductInfoForVariant, EncodedSearchResultsProductInfoForVariant> = t.recursion('SearchResultsProductInfoForVariant', () => (
  t.exact(t.intersection([t.type({
    
  }), t.partial({
    'name': t.string,
    'previouslyOrdered': t.boolean,
  })]), 'SearchResultsProductInfoForVariant')
));


export type BarcodeSearchResults = {
  'variants'?: Array<ProductVariantListDisplayInfo>,
  'externalProducts'?: Array<ExternalProductDisplayInfo>,
};
export type EncodedBarcodeSearchResults = {
  'variants'?: Array<EncodedProductVariantListDisplayInfo>,
  'externalProducts'?: Array<EncodedExternalProductDisplayInfo>,
};
export const BarcodeSearchResults: t.Type<BarcodeSearchResults, EncodedBarcodeSearchResults> = t.recursion('BarcodeSearchResults', () => (
  t.exact(t.intersection([t.type({
    
  }), t.partial({
    'variants': t.array(ProductVariantListDisplayInfo),
    'externalProducts': t.array(ExternalProductDisplayInfo),
  })]), 'BarcodeSearchResults')
));


export type SuggestionSearchResults = {
  /** Suggested high yield search terms */
  'terms'?: Array<string>,
  /** Suggested categories by name */
  'categories'?: Array<{
    'id': number,
    'name': string,
    'slug': Slug,
    'navigable': boolean,
  }>,
  /** Suggested collections by name */
  'collections'?: Array<{
    'id': number,
    'name': string,
    'slug': Slug,
  }>,
  /** Suggested brands by name */
  'brands'?: Array<{
    'id': number,
    'name': string,
    'slug': Slug,
    'link'?: string,
    'profileImage'?: Image,
  }>,
};
export type EncodedSuggestionSearchResults = {
  'terms'?: Array<string>,
  'categories'?: Array<{
    'id': number,
    'name': string,
    'slug': string,
    'navigable': boolean,
  }>,
  'collections'?: Array<{
    'id': number,
    'name': string,
    'slug': string,
  }>,
  'brands'?: Array<{
    'id': number,
    'name': string,
    'slug': string,
    'link'?: string,
    'profileImage'?: EncodedImage,
  }>,
};
export const SuggestionSearchResults: t.Type<SuggestionSearchResults, EncodedSuggestionSearchResults> = t.recursion('SuggestionSearchResults', () => (
  t.exact(t.intersection([t.type({
    
  }), t.partial({
    'terms': t.array(t.string),
    'categories': t.array(t.exact(t.intersection([t.type({
      'id': t.number,
      'name': t.string,
      'slug': Slug,
      'navigable': t.boolean,
    }), t.partial({
      
    })]), 'SuggestionSearchResultsItem')),
    'collections': t.array(t.exact(t.intersection([t.type({
      'id': t.number,
      'name': t.string,
      'slug': Slug,
    }), t.partial({
      
    })]), 'SuggestionSearchResultsItem')),
    'brands': t.array(t.exact(t.intersection([t.type({
      'id': t.number,
      'name': t.string,
      'slug': Slug,
    }), t.partial({
      'link': t.string,
      'profileImage': Image,
    })]), 'SuggestionSearchResultsItem')),
  })]), 'SuggestionSearchResults')
));


export type SellerDashboardCard = {
  /** Specifies what card this is */
  'identifier': CodegenEnumOpenValues<'vendorReview' | 'liveProfileAnnouncement' | 'brandMarketingKit' | 'referralLink' | 'orderReadinessChecklist' | 'sellerOrderFulfillmentInfo' | 'sellerFaqs' | 'salesRepOrdering'>,
  /** An object keyed by identifier that I think is our workaround for the type system. */
  'metadata'?: SellerDashboardCardMetadata,
};
export type EncodedSellerDashboardCard = {
  'identifier': string,
  'metadata'?: EncodedSellerDashboardCardMetadata,
};
export const SellerDashboardCard: t.Type<SellerDashboardCard, EncodedSellerDashboardCard> = t.recursion('SellerDashboardCard', () => (
  t.exact(t.intersection([t.type({
    'identifier': CodegenEnumOpen({
      'vendorReview': null,
      'liveProfileAnnouncement': null,
      'brandMarketingKit': null,
      'referralLink': null,
      'orderReadinessChecklist': null,
      'sellerOrderFulfillmentInfo': null,
      'sellerFaqs': null,
      'salesRepOrdering': null,
    }, 'SellerDashboardCard'),
  }), t.partial({
    'metadata': SellerDashboardCardMetadata,
  })]), 'SellerDashboardCard')
));


export type SellerDashboardCardMetadata = {
  'liveProfileAnnouncement'?: {
    'text': string,
    'links': Array<{
      'link': string,
      'label': string,
      'external': boolean,
    }>,
  },
  'referralLink'?: SellerDashboardCardMetadataLink,
  'vendorReview'?: SellerDashboardCardMetadataLink,
};
export type EncodedSellerDashboardCardMetadata = {
  'liveProfileAnnouncement'?: {
    'text': string,
    'links': Array<{
      'link': string,
      'label': string,
      'external': boolean,
    }>,
  },
  'referralLink'?: EncodedSellerDashboardCardMetadataLink,
  'vendorReview'?: EncodedSellerDashboardCardMetadataLink,
};
export const SellerDashboardCardMetadata: t.Type<SellerDashboardCardMetadata, EncodedSellerDashboardCardMetadata> = t.recursion('SellerDashboardCardMetadata', () => (
  t.exact(t.intersection([t.type({
    
  }), t.partial({
    'liveProfileAnnouncement': t.exact(t.intersection([t.type({
      'text': t.string,
      'links': t.array(t.exact(t.intersection([t.type({
        'link': t.string,
        'label': t.string,
        'external': t.boolean,
      }), t.partial({
        
      })]), 'SellerDashboardCardMetadataItem')),
    }), t.partial({
      
    })]), 'SellerDashboardCardMetadata'),
    'referralLink': SellerDashboardCardMetadataLink,
    'vendorReview': SellerDashboardCardMetadataLink,
  })]), 'SellerDashboardCardMetadata')
));


export type SellerDashboardCardMetadataLink = {
  'link': string,
};
export type EncodedSellerDashboardCardMetadataLink = {
  'link': string,
};
export const SellerDashboardCardMetadataLink: t.Type<SellerDashboardCardMetadataLink, EncodedSellerDashboardCardMetadataLink> = t.recursion('SellerDashboardCardMetadataLink', () => (
  t.exact(t.intersection([t.type({
    'link': t.string,
  }), t.partial({
    
  })]), 'SellerDashboardCardMetadataLink')
));


export type SellerDashboardFlags = {
  'type': 'SellerDashboardFlags',
  'reviewedProducts'?: boolean,
  'reviewedBrand'?: boolean,
  'brandLiveReady'?: boolean,
  'productsReady'?: boolean,
  'goneLive': boolean,
};
export type EncodedSellerDashboardFlags = {
  'type': string,
  'reviewedProducts'?: boolean,
  'reviewedBrand'?: boolean,
  'brandLiveReady'?: boolean,
  'productsReady'?: boolean,
  'goneLive': boolean,
};
export const SellerDashboardFlags: t.Type<SellerDashboardFlags, EncodedSellerDashboardFlags> = t.recursion('SellerDashboardFlags', () => (
  t.exact(t.intersection([t.type({
    'type': CodegenEnumClosed({
      'SellerDashboardFlags': null,
    }, 'SellerDashboardFlags'),
    'goneLive': t.boolean,
  }), t.partial({
    'reviewedProducts': t.boolean,
    'reviewedBrand': t.boolean,
    'brandLiveReady': t.boolean,
    'productsReady': t.boolean,
  })]), 'SellerDashboardFlags')
));


export type ExternalProductDisplayInfo = {
  'name': string,
  'barcodes'?: Array<string>,
  'imageUris'?: Array<ProductImageUri>,
  'externalId': string,
  'source': ExternalProductSource,
  'vendorName'?: string,
  'vendorItemNumber'?: string,
};
export type EncodedExternalProductDisplayInfo = {
  'name': string,
  'barcodes'?: Array<string>,
  'imageUris'?: Array<EncodedProductImageUri>,
  'externalId': string,
  'source': EncodedExternalProductSource,
  'vendorName'?: string,
  'vendorItemNumber'?: string,
};
export const ExternalProductDisplayInfo: t.Type<ExternalProductDisplayInfo, EncodedExternalProductDisplayInfo> = t.recursion('ExternalProductDisplayInfo', () => (
  t.exact(t.intersection([t.type({
    'name': t.string,
    'externalId': t.string,
    'source': ExternalProductSource,
  }), t.partial({
    'barcodes': t.array(t.string),
    'imageUris': t.array(ProductImageUri),
    'vendorName': t.string,
    'vendorItemNumber': t.string,
  })]), 'ExternalProductDisplayInfo')
));


export type MobileConfig = {
  'sentryDsn': string,
  'stripePublishableKey': string,
  'mableAddress': Address,
  'daysToPay'?: string,
  /** The app ID to use with the Sendbird SDK. If undefined, hide the messaging feature. */
  'sendbirdAppId'?: string,
  'supportEmailAddress'?: string,
};
export type EncodedMobileConfig = {
  'sentryDsn': string,
  'stripePublishableKey': string,
  'mableAddress': EncodedAddress,
  'daysToPay'?: string,
  'sendbirdAppId'?: string,
  'supportEmailAddress'?: string,
};
export const MobileConfig: t.Type<MobileConfig, EncodedMobileConfig> = t.recursion('MobileConfig', () => (
  t.exact(t.intersection([t.type({
    'sentryDsn': t.string,
    'stripePublishableKey': t.string,
    'mableAddress': Address,
  }), t.partial({
    'daysToPay': t.string,
    'sendbirdAppId': t.string,
    'supportEmailAddress': t.string,
  })]), 'MobileConfig')
));


export type PaymentMethod = {
  'id': number,
  'type': CodegenEnumOpenValues<'card'>,
  /** Only returned for cards. */
  'last4'?: string,
  /** Only returned for cards. */
  'cardBrand'?: string,
  'isDefault'?: boolean,
  'isFailing'?: boolean,
};
export type EncodedPaymentMethod = {
  'id': number,
  'type': string,
  'last4'?: string,
  'cardBrand'?: string,
  'isDefault'?: boolean,
  'isFailing'?: boolean,
};
export const PaymentMethod: t.Type<PaymentMethod, EncodedPaymentMethod> = t.recursion('PaymentMethod', () => (
  t.exact(t.intersection([t.type({
    'id': t.number,
    'type': CodegenEnumOpen({
      'card': null,
    }, 'PaymentMethod'),
  }), t.partial({
    'last4': t.string,
    'cardBrand': t.string,
    'isDefault': t.boolean,
    'isFailing': t.boolean,
  })]), 'PaymentMethod')
));


export type DashboardDataCard = {
  'type': 'DashboardDataCard',
  'title': string,
  'subtitle'?: string,
  /** If defined, clicking on this card will take you here. */
  'link'?: string,
  /** List of data points to be displayed */
  'dataPoints': Array<DashboardCardDataPoint>,
};
export type EncodedDashboardDataCard = {
  'type': string,
  'title': string,
  'subtitle'?: string,
  'link'?: string,
  'dataPoints': Array<EncodedDashboardCardDataPoint>,
};
export const DashboardDataCard: t.Type<DashboardDataCard, EncodedDashboardDataCard> = t.recursion('DashboardDataCard', () => (
  t.exact(t.intersection([t.type({
    'type': CodegenEnumClosed({
      'DashboardDataCard': null,
    }, 'DashboardDataCard'),
    'title': t.string,
    'dataPoints': t.array(DashboardCardDataPoint),
  }), t.partial({
    'subtitle': t.string,
    'link': t.string,
  })]), 'DashboardDataCard')
));


export type DashboardCardDataPoint = {
  /** The name of this data point */
  'title': string,
  /** A formatted data point */
  'data': string,
  /** Whether this datapoint should have attention called to it */
  'danger'?: boolean,
};
export type EncodedDashboardCardDataPoint = {
  'title': string,
  'data': string,
  'danger'?: boolean,
};
export const DashboardCardDataPoint: t.Type<DashboardCardDataPoint, EncodedDashboardCardDataPoint> = t.recursion('DashboardCardDataPoint', () => (
  t.exact(t.intersection([t.type({
    'title': t.string,
    'data': t.string,
  }), t.partial({
    'danger': t.boolean,
  })]), 'DashboardCardDataPoint')
));


export type DashboardStageCard = {
  'type': 'DashboardStageCard',
  'title': string,
  'linkTitle': string,
  'url': string,
  'style'?: CodegenEnumOpenValues<'default' | 'timely-demo'>,
};
export type EncodedDashboardStageCard = {
  'type': string,
  'title': string,
  'linkTitle': string,
  'url': string,
  'style'?: string,
};
export const DashboardStageCard: t.Type<DashboardStageCard, EncodedDashboardStageCard> = t.recursion('DashboardStageCard', () => (
  t.exact(t.intersection([t.type({
    'type': CodegenEnumClosed({
      'DashboardStageCard': null,
    }, 'DashboardStageCard'),
    'title': t.string,
    'linkTitle': t.string,
    'url': t.string,
  }), t.partial({
    'style': CodegenEnumOpen({
      'default': null,
      'timely-demo': null,
    }, 'DashboardStageCard'),
  })]), 'DashboardStageCard')
));


/** Each object inside has at least one required value to differentiate and allow TS fallthrough */
export type DashboardContentItem = OneOfOpenValues<SellerDashboardLinks | DashboardDataCard | DashboardSellerRow | DashboardVariantRow | DashboardCards | DashboardTitle | DashboardSubtitle | DashboardPlaceholder | DashboardStageCard>;
export type EncodedDashboardContentItem = OneOfOpenEncodedValues<EncodedSellerDashboardLinks | EncodedDashboardDataCard | EncodedDashboardSellerRow | EncodedDashboardVariantRow | EncodedDashboardCards | EncodedDashboardTitle | EncodedDashboardSubtitle | EncodedDashboardPlaceholder | EncodedDashboardStageCard>;
export const DashboardContentItem: t.Type<DashboardContentItem, EncodedDashboardContentItem> = t.recursion('DashboardContentItem', () => (
  OneOfOpen([SellerDashboardLinks, DashboardDataCard, DashboardSellerRow, DashboardVariantRow, DashboardCards, DashboardTitle, DashboardSubtitle, DashboardPlaceholder, DashboardStageCard])
));


/** Big title */
export type DashboardTitle = {
  'type': 'DashboardTitle',
  'title': string,
};
export type EncodedDashboardTitle = {
  'type': string,
  'title': string,
};
export const DashboardTitle: t.Type<DashboardTitle, EncodedDashboardTitle> = t.recursion('DashboardTitle', () => (
  t.exact(t.intersection([t.type({
    'type': CodegenEnumClosed({
      'DashboardTitle': null,
    }, 'DashboardTitle'),
    'title': t.string,
  }), t.partial({
    
  })]), 'DashboardTitle')
));


/** Subtitle */
export type DashboardSubtitle = {
  'type': 'DashboardSubtitle',
  'subtitle': string,
};
export type EncodedDashboardSubtitle = {
  'type': string,
  'subtitle': string,
};
export const DashboardSubtitle: t.Type<DashboardSubtitle, EncodedDashboardSubtitle> = t.recursion('DashboardSubtitle', () => (
  t.exact(t.intersection([t.type({
    'type': CodegenEnumClosed({
      'DashboardSubtitle': null,
    }, 'DashboardSubtitle'),
    'subtitle': t.string,
  }), t.partial({
    
  })]), 'DashboardSubtitle')
));


/** List of links to display within a card */
export type SellerDashboardLinks = {
  'type': 'SellerDashboardLinks',
  'title': string,
  'identifier': string,
  'links': Array<DashboardLink>,
};
export type EncodedSellerDashboardLinks = {
  'type': string,
  'title': string,
  'identifier': string,
  'links': Array<EncodedDashboardLink>,
};
export const SellerDashboardLinks: t.Type<SellerDashboardLinks, EncodedSellerDashboardLinks> = t.recursion('SellerDashboardLinks', () => (
  t.exact(t.intersection([t.type({
    'type': CodegenEnumClosed({
      'SellerDashboardLinks': null,
    }, 'SellerDashboardLinks'),
    'title': t.string,
    'identifier': t.string,
    'links': t.array(DashboardLink),
  }), t.partial({
    
  })]), 'SellerDashboardLinks')
));


/** An object representing a link */
export type DashboardLink = {
  'url': string,
  'title': string,
};
export type EncodedDashboardLink = {
  'url': string,
  'title': string,
};
export const DashboardLink: t.Type<DashboardLink, EncodedDashboardLink> = t.recursion('DashboardLink', () => (
  t.exact(t.intersection([t.type({
    'url': t.string,
    'title': t.string,
  }), t.partial({
    
  })]), 'DashboardLink')
));


/** Represents a placeholder spot in the content that will be replaced by the client, using data or configuration from elsewhere. */
export type DashboardPlaceholder = {
  'type': 'DashboardPlaceholder',
  'id': CodegenEnumOpenValues<'regions' | 'categories' | 'diets' | 'popularByRegion'>,
};
export type EncodedDashboardPlaceholder = {
  'type': string,
  'id': string,
};
export const DashboardPlaceholder: t.Type<DashboardPlaceholder, EncodedDashboardPlaceholder> = t.recursion('DashboardPlaceholder', () => (
  t.exact(t.intersection([t.type({
    'type': CodegenEnumClosed({
      'DashboardPlaceholder': null,
    }, 'DashboardPlaceholder'),
    'id': CodegenEnumOpen({
      'regions': null,
      'categories': null,
      'diets': null,
      'popularByRegion': null,
    }, 'DashboardPlaceholder'),
  }), t.partial({
    
  })]), 'DashboardPlaceholder')
));


export type DashboardSellerRow = {
  'type': 'DashboardSellerRow',
  /** The display title of for this row */
  'title': string,
  /** Relative path to view the full list of sellers previewed by this row, if it exists */
  'url'?: string,
  /** Title for the link to the provided `url`. If not defined, use a fallback value (e.g. 'View all') */
  'linkTitle'?: string,
  /** Sellers to display in this row */
  'sellers': Array<SellerDisplayInfo>,
  'analytics'?: {
    'kind'?: string,
    'param'?: string,
  },
};
export type EncodedDashboardSellerRow = {
  'type': string,
  'title': string,
  'url'?: string,
  'linkTitle'?: string,
  'sellers': Array<EncodedSellerDisplayInfo>,
  'analytics'?: {
    'kind'?: string,
    'param'?: string,
  },
};
export const DashboardSellerRow: t.Type<DashboardSellerRow, EncodedDashboardSellerRow> = t.recursion('DashboardSellerRow', () => (
  t.exact(t.intersection([t.type({
    'type': CodegenEnumClosed({
      'DashboardSellerRow': null,
    }, 'DashboardSellerRow'),
    'title': t.string,
    'sellers': t.array(SellerDisplayInfo),
  }), t.partial({
    'url': t.string,
    'linkTitle': t.string,
    'analytics': t.exact(t.intersection([t.type({
      
    }), t.partial({
      'kind': t.string,
      'param': t.string,
    })]), 'DashboardSellerRow'),
  })]), 'DashboardSellerRow')
));


export type DashboardVariantRow = {
  'type': 'DashboardVariantRow',
  /** The display title of for this row */
  'title': string,
  /** Relative path to view the full list of variants previewed by this row, if it exists */
  'url'?: string,
  /** Title for the link to the provided `url`. If not defined, use a fallback value (e.g. 'View all') */
  'linkTitle'?: string,
  /** This will be `true` if this row should have a 'sale' UI style (e.g. red text) */
  'sale'?: boolean,
  /** Variants to display in this row */
  'variants': Array<ProductVariantListDisplayInfo>,
  'analytics'?: {
    'kind'?: string,
    'param'?: string,
  },
};
export type EncodedDashboardVariantRow = {
  'type': string,
  'title': string,
  'url'?: string,
  'linkTitle'?: string,
  'sale'?: boolean,
  'variants': Array<EncodedProductVariantListDisplayInfo>,
  'analytics'?: {
    'kind'?: string,
    'param'?: string,
  },
};
export const DashboardVariantRow: t.Type<DashboardVariantRow, EncodedDashboardVariantRow> = t.recursion('DashboardVariantRow', () => (
  t.exact(t.intersection([t.type({
    'type': CodegenEnumClosed({
      'DashboardVariantRow': null,
    }, 'DashboardVariantRow'),
    'title': t.string,
    'variants': t.array(ProductVariantListDisplayInfo),
  }), t.partial({
    'url': t.string,
    'linkTitle': t.string,
    'sale': t.boolean,
    'analytics': t.exact(t.intersection([t.type({
      
    }), t.partial({
      'kind': t.string,
      'param': t.string,
    })]), 'DashboardVariantRow'),
  })]), 'DashboardVariantRow')
));


export type DashboardCard = {
  'id'?: number,
  'type': CodegenEnumOpenValues<'DashboardCard' | 'SellerDashboardCard'>,
  'title': string,
  'subtitle'?: string,
  /** Only shows up for 'big' style cards. */
  'body'?: string,
  'images'?: Array<Image>,
  /** If `true`, open this link in a new tab */
  'newTab'?: boolean,
  'url': string,
  'color'?: CodegenEnumOpenValues<'white' | 'blue'>,
  'icon'?: CodegenEnumOpenValues<'circle-check' | 'triangle-warning'>,
  'badge'?: string,
  'countBadge'?: number,
  'primaryImageId'?: number,
  'analytics'?: {
    'kind'?: string,
    'param'?: string,
  },
};
export type EncodedDashboardCard = {
  'id'?: number,
  'type': string,
  'title': string,
  'subtitle'?: string,
  'body'?: string,
  'images'?: Array<EncodedImage>,
  'newTab'?: boolean,
  'url': string,
  'color'?: string,
  'icon'?: string,
  'badge'?: string,
  'countBadge'?: number,
  'primaryImageId'?: number,
  'analytics'?: {
    'kind'?: string,
    'param'?: string,
  },
};
export const DashboardCard: t.Type<DashboardCard, EncodedDashboardCard> = t.recursion('DashboardCard', () => (
  t.exact(t.intersection([t.type({
    'type': CodegenEnumOpen({
      'DashboardCard': null,
      'SellerDashboardCard': null,
    }, 'DashboardCard'),
    'title': t.string,
    'url': t.string,
  }), t.partial({
    'id': t.number,
    'subtitle': t.string,
    'body': t.string,
    'images': t.array(Image),
    'newTab': t.boolean,
    'color': CodegenEnumOpen({
      'white': null,
      'blue': null,
    }, 'DashboardCard'),
    'icon': CodegenEnumOpen({
      'circle-check': null,
      'triangle-warning': null,
    }, 'DashboardCard'),
    'badge': t.string,
    'countBadge': t.number,
    'primaryImageId': t.number,
    'analytics': t.exact(t.intersection([t.type({
      
    }), t.partial({
      'kind': t.string,
      'param': t.string,
    })]), 'DashboardCard'),
  })]), 'DashboardCard')
));


/** A collection of cards. On mobile these should be display in their own rows. On web, these should be in a horizontal stack. */
export type DashboardCards = {
  'type': 'DashboardCards',
  /** Specifies how to render the cards. 'normal' is smaller blue cards, 'big' is the big ones! */
  'style'?: CodegenEnumOpenValues<'normal' | 'big'>,
  'cards': Array<DashboardCard>,
};
export type EncodedDashboardCards = {
  'type': string,
  'style'?: string,
  'cards': Array<EncodedDashboardCard>,
};
export const DashboardCards: t.Type<DashboardCards, EncodedDashboardCards> = t.recursion('DashboardCards', () => (
  t.exact(t.intersection([t.type({
    'type': CodegenEnumClosed({
      'DashboardCards': null,
    }, 'DashboardCards'),
    'cards': t.array(DashboardCard),
  }), t.partial({
    'style': CodegenEnumOpen({
      'normal': null,
      'big': null,
    }, 'DashboardCards'),
  })]), 'DashboardCards')
));


export type DashboardProfileSlug = CodegenEnumOpenValues<'marketplace_tier_one' | 'return_marketplace_tier_one' | 'marketplace_tier_two' | 'marketplace_tier_three' | 'featured_mable_programs' | 'featured_in_marketplace' | 'return_distributor_cards' | 'upmarket_tier_one' | 'upmarket_tier_two' | 'mclane_tier_one' | 'mclane_tier_two' | 'kehe_tier_one' | 'kehe_tier_two'>;
export type EncodedDashboardProfileSlug = string;
export const DashboardProfileSlug = CodegenEnumOpen({
  'marketplace_tier_one': null,
  'return_marketplace_tier_one': null,
  'marketplace_tier_two': null,
  'marketplace_tier_three': null,
  'featured_mable_programs': null,
  'featured_in_marketplace': null,
  'return_distributor_cards': null,
  'upmarket_tier_one': null,
  'upmarket_tier_two': null,
  'mclane_tier_one': null,
  'mclane_tier_two': null,
  'kehe_tier_one': null,
  'kehe_tier_two': null,
}, 'DashboardProfileSlug');
export const DashboardProfileSlugValues = ['marketplace_tier_one', 'return_marketplace_tier_one', 'marketplace_tier_two', 'marketplace_tier_three', 'featured_mable_programs', 'featured_in_marketplace', 'return_distributor_cards', 'upmarket_tier_one', 'upmarket_tier_two', 'mclane_tier_one', 'mclane_tier_two', 'kehe_tier_one', 'kehe_tier_two'] as const;

export type DashboardProfile = {
  'id'?: number,
  'slug': DashboardProfileSlug,
  'description'?: string,
  'type': 'DashboardCards',
  'style': CodegenEnumOpenValues<'normal' | 'big'>,
  'isActive': boolean,
  'cards'?: Array<DashboardCard>,
};
export type EncodedDashboardProfile = {
  'id'?: number,
  'slug': EncodedDashboardProfileSlug,
  'description'?: string,
  'type': string,
  'style': string,
  'isActive': boolean,
  'cards'?: Array<EncodedDashboardCard>,
};
export const DashboardProfile: t.Type<DashboardProfile, EncodedDashboardProfile> = t.recursion('DashboardProfile', () => (
  t.exact(t.intersection([t.type({
    'slug': DashboardProfileSlug,
    'type': CodegenEnumClosed({
      'DashboardCards': null,
    }, 'DashboardProfile'),
    'style': CodegenEnumOpen({
      'normal': null,
      'big': null,
    }, 'DashboardProfile'),
    'isActive': t.boolean,
  }), t.partial({
    'id': t.number,
    'description': t.string,
    'cards': t.array(DashboardCard),
  })]), 'DashboardProfile')
));


export type Customer = {
  'accountId': number,
  /** The best known ship to address */
  'address'?: Address,
  'buyerId': number,
  /** The customer's identifier in the distributors system */
  'customerNumber'?: string,
  'facebookUrl'?: string,
  'instagramUrl'?: string,
  'lastMessageDate'?: Date,
  'lastOrderDate'?: Date,
  'lastOrderId'?: number,
  'lastOrderPurchaseTotal'?: BigNumber,
  'lastOrderStatus'?: TransactionStatus,
  'numberOfOrders'?: number,
  /** Whether the buyer has been marked as priority. */
  'priority'?: boolean,
  'sellerNote'?: string,
  'sellerNoteUpdatedAt'?: Date,
  'sendbirdChannelUrl'?: string,
  'messagingEnabled'?: boolean,
  /** The name of the store */
  'storeName': string,
  'storeCategory'?: string,
  'storeCategoryVerified'?: boolean,
  'totalSpend'?: BigNumber,
  'websiteUrl'?: string,
  'partnerProgramMemberships'?: Array<string>,
};
export type EncodedCustomer = {
  'accountId': number,
  'address'?: EncodedAddress,
  'buyerId': number,
  'customerNumber'?: string,
  'facebookUrl'?: string,
  'instagramUrl'?: string,
  'lastMessageDate'?: string,
  'lastOrderDate'?: string,
  'lastOrderId'?: number,
  'lastOrderPurchaseTotal'?: string,
  'lastOrderStatus'?: EncodedTransactionStatus,
  'numberOfOrders'?: number,
  'priority'?: boolean,
  'sellerNote'?: string,
  'sellerNoteUpdatedAt'?: string,
  'sendbirdChannelUrl'?: string,
  'messagingEnabled'?: boolean,
  'storeName': string,
  'storeCategory'?: string,
  'storeCategoryVerified'?: boolean,
  'totalSpend'?: string,
  'websiteUrl'?: string,
  'partnerProgramMemberships'?: Array<string>,
};
export const Customer: t.Type<Customer, EncodedCustomer> = t.recursion('Customer', () => (
  t.exact(t.intersection([t.type({
    'accountId': t.number,
    'buyerId': t.number,
    'storeName': t.string,
  }), t.partial({
    'address': Address,
    'customerNumber': t.string,
    'facebookUrl': t.string,
    'instagramUrl': t.string,
    'lastMessageDate': DateFromISOString,
    'lastOrderDate': DateFromISOString,
    'lastOrderId': t.number,
    'lastOrderPurchaseTotal': NumberFromString,
    'lastOrderStatus': TransactionStatus,
    'numberOfOrders': t.number,
    'priority': t.boolean,
    'sellerNote': t.string,
    'sellerNoteUpdatedAt': DateFromISOString,
    'sendbirdChannelUrl': t.string,
    'messagingEnabled': t.boolean,
    'storeCategory': t.string,
    'storeCategoryVerified': t.boolean,
    'totalSpend': NumberFromString,
    'websiteUrl': t.string,
    'partnerProgramMemberships': t.array(t.string),
  })]), 'Customer')
));


/** Detailed information on a customer */
export type CustomerDetail = Customer & {
  'commissionMultiplier': BigNumber,
  'lastOrderItemCount'?: number,
  'lastSample'?: DisplaySampleRequest,
  'orderMinimum'?: BigNumber,
  'sellerNote'?: string,
  'sellerNoteUpdatedAt'?: Date,
  'standardOrderMinimum'?: BigNumber,
  'topProductVariants': Array<CustomerProductVariantDisplayInfo>,
};
export type EncodedCustomerDetail = EncodedCustomer & {
  'commissionMultiplier': string,
  'lastOrderItemCount'?: number,
  'lastSample'?: EncodedDisplaySampleRequest,
  'orderMinimum'?: string,
  'sellerNote'?: string,
  'sellerNoteUpdatedAt'?: string,
  'standardOrderMinimum'?: string,
  'topProductVariants': Array<EncodedCustomerProductVariantDisplayInfo>,
};
export const CustomerDetail: t.Type<CustomerDetail, EncodedCustomerDetail> = t.recursion('CustomerDetail', () => (
  t.intersection([
    Customer,
    t.exact(t.intersection([t.type({
      'commissionMultiplier': NumberFromString,
      'topProductVariants': t.array(CustomerProductVariantDisplayInfo),
    }), t.partial({
      'lastOrderItemCount': t.number,
      'lastSample': DisplaySampleRequest,
      'orderMinimum': NumberFromString,
      'sellerNote': t.string,
      'sellerNoteUpdatedAt': DateFromISOString,
      'standardOrderMinimum': NumberFromString,
    })]), 'CustomerDetail')
  ], 'CustomerDetail')
));


export type CustomerDetailSort = 'lastMessageDate' | 'lastOrderDate' | 'lastOrderStatus' | 'address' | 'numberOfOrders' | 'storeName' | 'totalSpend';
export type EncodedCustomerDetailSort = string;
export const CustomerDetailSort = CodegenEnumClosed({
  'lastMessageDate': null,
  'lastOrderDate': null,
  'lastOrderStatus': null,
  'address': null,
  'numberOfOrders': null,
  'storeName': null,
  'totalSpend': null,
}, 'CustomerDetailSort');
export const CustomerDetailSortValues = ['lastMessageDate', 'lastOrderDate', 'lastOrderStatus', 'address', 'numberOfOrders', 'storeName', 'totalSpend'] as const;

/** Basic seller info and last ordered date by the buyer if applicable */
export type SellerReorderInfo = SellerDisplayInfo & {
  'lastOrderDate'?: Date,
  'orderCount'?: number,
};
export type EncodedSellerReorderInfo = EncodedSellerDisplayInfo & {
  'lastOrderDate'?: string,
  'orderCount'?: number,
};
export const SellerReorderInfo: t.Type<SellerReorderInfo, EncodedSellerReorderInfo> = t.recursion('SellerReorderInfo', () => (
  t.intersection([
    SellerDisplayInfo,
    t.exact(t.intersection([t.type({
      
    }), t.partial({
      'lastOrderDate': DateFromISOString,
      'orderCount': t.number,
    })]), 'SellerReorderInfo')
  ], 'SellerReorderInfo')
));


/** need to keep until we remove sample request backfill sync handler */
export type SampleRequestStatusDeprecated = CodegenEnumOpenValues<'new' | 'denied' | 'cancelled' | 'awaiting_feedback' | 'help_required' | 'completed' | 'awaiting_brand_response'>;
export type EncodedSampleRequestStatusDeprecated = string;
export const SampleRequestStatusDeprecated = CodegenEnumOpen({
  'new': null,
  'denied': null,
  'cancelled': null,
  'awaiting_feedback': null,
  'help_required': null,
  'completed': null,
  'awaiting_brand_response': null,
}, 'SampleRequestStatusDeprecated');
export const SampleRequestStatusDeprecatedValues = ['new', 'denied', 'cancelled', 'awaiting_feedback', 'help_required', 'completed', 'awaiting_brand_response'] as const;

export type SampleRequest = {
  'id': number,
  'buyerId': number,
  'sellerId': number,
  'status': TransactionStatus,
  'note': string,
  'noteObject': BuyerNote,
  'userId': number,
  'shipmentTrackingNumber'?: string,
  'feedback'?: Record<string, unknown>,
  'extraData'?: Record<string, unknown>,
  'createdAt': Date,
  'updatedAt'?: Date,
  'feedbackExternalEmailId'?: string,
  'transactionId': number,
  'shipToAddress'?: Address,
  'requiresManualIntervention': boolean,
  'log': Array<TransactionLogEntry>,
  'trackingCodes': Array<string>,
  /** URL of the message channel that was created or fetched. Can be passed to Sendbird JS SDK. */
  'messageChannelUrl'?: string,
  'buyerLocationId'?: string,
};
export type EncodedSampleRequest = {
  'id': number,
  'buyerId': number,
  'sellerId': number,
  'status': EncodedTransactionStatus,
  'note': string,
  'noteObject': EncodedBuyerNote,
  'userId': number,
  'shipmentTrackingNumber'?: string,
  'feedback'?: Record<string, unknown>,
  'extraData'?: Record<string, unknown>,
  'createdAt': string,
  'updatedAt'?: string,
  'feedbackExternalEmailId'?: string,
  'transactionId': number,
  'shipToAddress'?: EncodedAddress,
  'requiresManualIntervention': boolean,
  'log': Array<EncodedTransactionLogEntry>,
  'trackingCodes': Array<string>,
  'messageChannelUrl'?: string,
  'buyerLocationId'?: string,
};
export const SampleRequest: t.Type<SampleRequest, EncodedSampleRequest> = t.recursion('SampleRequest', () => (
  t.exact(t.intersection([t.type({
    'id': t.number,
    'buyerId': t.number,
    'sellerId': t.number,
    'status': TransactionStatus,
    'note': t.string,
    'noteObject': BuyerNote,
    'userId': t.number,
    'createdAt': DateFromISOString,
    'transactionId': t.number,
    'requiresManualIntervention': t.boolean,
    'log': t.array(TransactionLogEntry),
    'trackingCodes': t.array(t.string),
  }), t.partial({
    'shipmentTrackingNumber': t.string,
    'feedback': t.record(t.string, t.unknown),
    'extraData': t.record(t.string, t.unknown),
    'updatedAt': DateFromISOString,
    'feedbackExternalEmailId': t.string,
    'shipToAddress': Address,
    'messageChannelUrl': t.string,
    'buyerLocationId': t.string,
  })]), 'SampleRequest')
));


export type SampleRequestDisplayLog = {
  'id': number,
  'date': Date,
  'title': string,
  'body'?: string,
};
export type EncodedSampleRequestDisplayLog = {
  'id': number,
  'date': string,
  'title': string,
  'body'?: string,
};
export const SampleRequestDisplayLog: t.Type<SampleRequestDisplayLog, EncodedSampleRequestDisplayLog> = t.recursion('SampleRequestDisplayLog', () => (
  t.exact(t.intersection([t.type({
    'id': t.number,
    'date': DateFromISOString,
    'title': t.string,
  }), t.partial({
    'body': t.string,
  })]), 'SampleRequestDisplayLog')
));


export type DisplaySampleRequest = {
  'id': number,
  'buyerId': number,
  'sellerId': number,
  'status': TransactionStatus,
  'note': string,
  'noteObject': BuyerNote,
  'userId': number,
  'shipmentTrackingNumber'?: string,
  'feedback'?: Record<string, unknown>,
  'extraData'?: Record<string, unknown>,
  'createdAt': Date,
  'updatedAt'?: Date,
  'feedbackExternalEmailId'?: string,
  'transactionId': number,
  'requiresManualIntervention': boolean,
  'shipToAddress'?: Address,
  'sellerName': string,
  'sellerSlug': Slug,
  'sellerDescription'?: string,
  'profileImage'?: Image,
  'date': Date,
  /** A string clients should use when displaying a status. If it's undefined, don't display a status. */
  'statusDisplay'?: string,
  'log': Array<SampleRequestDisplayLog>,
  'trackingCodes': Array<string>,
  'buyerName': string,
  /** URL of the message channel that was created or fetched. Can be passed to Sendbird JS SDK. */
  'messageChannelUrl'?: string,
  'buyerLocationId'?: string,
  'fullBuyerLocationName'?: string,
  'address': string,
  /** If we should show the partner specific fulfillment steps on the seller sample detail page. */
  'showPartnerFulfillmentSteps'?: boolean,
  /** The account ID of the buyer's partner that is associated with this sample request. */
  'partnerAccountId'?: number,
  /** The name of the buyer's partner that is associated with this sample request. */
  'partnerName'?: string,
  /** The display name of the buyer's partner that is associated with this sample request. */
  'partnerDisplayName'?: string,
  /** Whether the buyer has been marked as priority. */
  'buyerIsPriority'?: boolean,
};
export type EncodedDisplaySampleRequest = {
  'id': number,
  'buyerId': number,
  'sellerId': number,
  'status': EncodedTransactionStatus,
  'note': string,
  'noteObject': EncodedBuyerNote,
  'userId': number,
  'shipmentTrackingNumber'?: string,
  'feedback'?: Record<string, unknown>,
  'extraData'?: Record<string, unknown>,
  'createdAt': string,
  'updatedAt'?: string,
  'feedbackExternalEmailId'?: string,
  'transactionId': number,
  'requiresManualIntervention': boolean,
  'shipToAddress'?: EncodedAddress,
  'sellerName': string,
  'sellerSlug': string,
  'sellerDescription'?: string,
  'profileImage'?: EncodedImage,
  'date': string,
  'statusDisplay'?: string,
  'log': Array<EncodedSampleRequestDisplayLog>,
  'trackingCodes': Array<string>,
  'buyerName': string,
  'messageChannelUrl'?: string,
  'buyerLocationId'?: string,
  'fullBuyerLocationName'?: string,
  'address': string,
  'showPartnerFulfillmentSteps'?: boolean,
  'partnerAccountId'?: number,
  'partnerName'?: string,
  'partnerDisplayName'?: string,
  'buyerIsPriority'?: boolean,
};
export const DisplaySampleRequest: t.Type<DisplaySampleRequest, EncodedDisplaySampleRequest> = t.recursion('DisplaySampleRequest', () => (
  t.exact(t.intersection([t.type({
    'id': t.number,
    'buyerId': t.number,
    'sellerId': t.number,
    'status': TransactionStatus,
    'note': t.string,
    'noteObject': BuyerNote,
    'userId': t.number,
    'createdAt': DateFromISOString,
    'transactionId': t.number,
    'requiresManualIntervention': t.boolean,
    'sellerName': t.string,
    'sellerSlug': Slug,
    'date': DateFromISOString,
    'log': t.array(SampleRequestDisplayLog),
    'trackingCodes': t.array(t.string),
    'buyerName': t.string,
    'address': t.string,
  }), t.partial({
    'shipmentTrackingNumber': t.string,
    'feedback': t.record(t.string, t.unknown),
    'extraData': t.record(t.string, t.unknown),
    'updatedAt': DateFromISOString,
    'feedbackExternalEmailId': t.string,
    'shipToAddress': Address,
    'sellerDescription': t.string,
    'profileImage': Image,
    'statusDisplay': t.string,
    'messageChannelUrl': t.string,
    'buyerLocationId': t.string,
    'fullBuyerLocationName': t.string,
    'showPartnerFulfillmentSteps': t.boolean,
    'partnerAccountId': t.number,
    'partnerName': t.string,
    'partnerDisplayName': t.string,
    'buyerIsPriority': t.boolean,
  })]), 'DisplaySampleRequest')
));


export type BuyerSampleRequest = {
  'sellerId': number,
  'note'?: string,
  'noteObject'?: BuyerNote,
  'address': string,
};
export type EncodedBuyerSampleRequest = {
  'sellerId': number,
  'note'?: string,
  'noteObject'?: EncodedBuyerNote,
  'address': string,
};
export const BuyerSampleRequest: t.Type<BuyerSampleRequest, EncodedBuyerSampleRequest> = t.recursion('BuyerSampleRequest', () => (
  t.exact(t.intersection([t.type({
    'sellerId': t.number,
    'address': t.string,
  }), t.partial({
    'note': t.string,
    'noteObject': BuyerNote,
  })]), 'BuyerSampleRequest')
));


export type AdminSampleRequest = SampleRequest & {
  'buyerName'?: string,
  'sellerName'?: string,
};
export type EncodedAdminSampleRequest = EncodedSampleRequest & {
  'buyerName'?: string,
  'sellerName'?: string,
};
export const AdminSampleRequest: t.Type<AdminSampleRequest, EncodedAdminSampleRequest> = t.recursion('AdminSampleRequest', () => (
  t.intersection([
    SampleRequest,
    t.exact(t.intersection([t.type({
      
    }), t.partial({
      'buyerName': t.string,
      'sellerName': t.string,
    })]), 'AdminSampleRequest')
  ], 'AdminSampleRequest')
));


export type BuyerSampleRequestInput = {
  'sellerId': number,
  'note'?: string,
  'noteObject'?: BuyerNote,
  'shipToAddressId': number,
  'buyerLocationId'?: string,
};
export type EncodedBuyerSampleRequestInput = {
  'sellerId': number,
  'note'?: string,
  'noteObject'?: EncodedBuyerNote,
  'shipToAddressId': number,
  'buyerLocationId'?: string,
};
export const BuyerSampleRequestInput: t.Type<BuyerSampleRequestInput, EncodedBuyerSampleRequestInput> = t.recursion('BuyerSampleRequestInput', () => (
  t.exact(t.intersection([t.type({
    'sellerId': t.number,
    'shipToAddressId': t.number,
  }), t.partial({
    'note': t.string,
    'noteObject': BuyerNote,
    'buyerLocationId': t.string,
  })]), 'BuyerSampleRequestInput')
));


export type SampleRequestEligibility = {
  'isEligible': boolean,
  'code'?: string,
  'displayTitle'?: string,
  'displayMessage'?: string,
  'openRequestCount'?: number,
  'maxRequestCount'?: number,
  'samples'?: Array<DisplaySampleRequest>,
};
export type EncodedSampleRequestEligibility = {
  'isEligible': boolean,
  'code'?: string,
  'displayTitle'?: string,
  'displayMessage'?: string,
  'openRequestCount'?: number,
  'maxRequestCount'?: number,
  'samples'?: Array<EncodedDisplaySampleRequest>,
};
export const SampleRequestEligibility: t.Type<SampleRequestEligibility, EncodedSampleRequestEligibility> = t.recursion('SampleRequestEligibility', () => (
  t.exact(t.intersection([t.type({
    'isEligible': t.boolean,
  }), t.partial({
    'code': t.string,
    'displayTitle': t.string,
    'displayMessage': t.string,
    'openRequestCount': t.number,
    'maxRequestCount': t.number,
    'samples': t.array(DisplaySampleRequest),
  })]), 'SampleRequestEligibility')
));


export type SampleCancellationReason = CodegenEnumOpenValues<'notAFit' | 'unableToFulfill' | 'noLongerOfferSamples' | 'other'>;
export type EncodedSampleCancellationReason = string;
export const SampleCancellationReason = CodegenEnumOpen({
  'notAFit': null,
  'unableToFulfill': null,
  'noLongerOfferSamples': null,
  'other': null,
}, 'SampleCancellationReason');
export const SampleCancellationReasonValues = ['notAFit', 'unableToFulfill', 'noLongerOfferSamples', 'other'] as const;

export type BuyerNote = {
  'note': string,
  'qualities'?: Array<string>,
  'extraOptions'?: Array<string>,
  'extraOptionsNote'?: string,
  'version': number,
};
export type EncodedBuyerNote = {
  'note': string,
  'qualities'?: Array<string>,
  'extraOptions'?: Array<string>,
  'extraOptionsNote'?: string,
  'version': number,
};
export const BuyerNote: t.Type<BuyerNote, EncodedBuyerNote> = t.recursion('BuyerNote', () => (
  t.exact(t.intersection([t.type({
    'note': t.string,
    'version': t.number,
  }), t.partial({
    'qualities': t.array(t.string),
    'extraOptions': t.array(t.string),
    'extraOptionsNote': t.string,
  })]), 'BuyerNote')
));


export type EasypostTrackable = {
  'trackingCode': string,
  'trackingUrl'?: string,
  'easypostTrackerId'?: string,
  'easypostStatus'?: EasypostShipmentStatus,
  'estimatedDeliveryDate'?: Date,
  'labelCreatedAt'?: Date,
  'carrierReceivedAt'?: Date,
  'deliveredAt'?: Date,
};
export type EncodedEasypostTrackable = {
  'trackingCode': string,
  'trackingUrl'?: string,
  'easypostTrackerId'?: string,
  'easypostStatus'?: EncodedEasypostShipmentStatus,
  'estimatedDeliveryDate'?: string,
  'labelCreatedAt'?: string,
  'carrierReceivedAt'?: string,
  'deliveredAt'?: string,
};
export const EasypostTrackable: t.Type<EasypostTrackable, EncodedEasypostTrackable> = t.recursion('EasypostTrackable', () => (
  t.exact(t.intersection([t.type({
    'trackingCode': t.string,
  }), t.partial({
    'trackingUrl': t.string,
    'easypostTrackerId': t.string,
    'easypostStatus': EasypostShipmentStatus,
    'estimatedDeliveryDate': DateFromISOString,
    'labelCreatedAt': DateFromISOString,
    'carrierReceivedAt': DateFromISOString,
    'deliveredAt': DateFromISOString,
  })]), 'EasypostTrackable')
));


export type ShipmentType = CodegenEnumOpenValues<'self_delivered' | 'shipped_external' | 'shipped_mable'>;
export type EncodedShipmentType = string;
export const ShipmentType = CodegenEnumOpen({
  'self_delivered': null,
  'shipped_external': null,
  'shipped_mable': null,
}, 'ShipmentType');
export const ShipmentTypeValues = ['self_delivered', 'shipped_external', 'shipped_mable'] as const;

export type ShipmentInputBase = {
  'type': ShipmentType,
  'transactionId': number,
  'cost'?: BigNumber,
  'parcelDimensions'?: Dimensions,
  /** In ounces (oz) */
  'parcelWeight'?: number,
};
export type EncodedShipmentInputBase = {
  'type': EncodedShipmentType,
  'transactionId': number,
  'cost'?: string,
  'parcelDimensions'?: EncodedDimensions,
  'parcelWeight'?: number,
};
export const ShipmentInputBase: t.Type<ShipmentInputBase, EncodedShipmentInputBase> = t.recursion('ShipmentInputBase', () => (
  t.exact(t.intersection([t.type({
    'type': ShipmentType,
    'transactionId': t.number,
  }), t.partial({
    'cost': NumberFromString,
    'parcelDimensions': Dimensions,
    'parcelWeight': t.number,
  })]), 'ShipmentInputBase')
));


export type ShipmentInputSelf = ShipmentInputBase & {
  'type': 'self_delivered',
  'selfDeliveryDate': Date,
};
export type EncodedShipmentInputSelf = EncodedShipmentInputBase & {
  'type': string,
  'selfDeliveryDate': string,
};
export const ShipmentInputSelf: t.Type<ShipmentInputSelf, EncodedShipmentInputSelf> = t.recursion('ShipmentInputSelf', () => (
  t.intersection([
    ShipmentInputBase,
    t.exact(t.intersection([t.type({
      'type': CodegenEnumClosed({
        'self_delivered': null,
      }, 'ShipmentInputSelf'),
      'selfDeliveryDate': DateFromISOString,
    }), t.partial({
      
    })]), 'ShipmentInputSelf')
  ], 'ShipmentInputSelf')
));


export type ShipmentInputExternal = ShipmentInputBase & EasypostTrackable;
export type EncodedShipmentInputExternal = EncodedShipmentInputBase & EncodedEasypostTrackable;
export const ShipmentInputExternal: t.Type<ShipmentInputExternal, EncodedShipmentInputExternal> = t.recursion('ShipmentInputExternal', () => (
  t.intersection([
    ShipmentInputBase,
    EasypostTrackable
  ], 'ShipmentInputExternal')
));


export type ShipmentInputMable = ShipmentInputBase & {
  'type': 'shipped_mable',
  'trackingCode': string,
  'trackingUrl': string,
  'shipmentGroupId'?: number,
  'easypostShipmentId'?: string,
  'labelUrl': string,
  'easypostTrackerId'?: string,
};
export type EncodedShipmentInputMable = EncodedShipmentInputBase & {
  'type': string,
  'trackingCode': string,
  'trackingUrl': string,
  'shipmentGroupId'?: number,
  'easypostShipmentId'?: string,
  'labelUrl': string,
  'easypostTrackerId'?: string,
};
export const ShipmentInputMable: t.Type<ShipmentInputMable, EncodedShipmentInputMable> = t.recursion('ShipmentInputMable', () => (
  t.intersection([
    ShipmentInputBase,
    t.exact(t.intersection([t.type({
      'type': CodegenEnumClosed({
        'shipped_mable': null,
      }, 'ShipmentInputMable'),
      'trackingCode': t.string,
      'trackingUrl': t.string,
      'labelUrl': t.string,
    }), t.partial({
      'shipmentGroupId': t.number,
      'easypostShipmentId': t.string,
      'easypostTrackerId': t.string,
    })]), 'ShipmentInputMable')
  ], 'ShipmentInputMable')
));


/** A shipment type that can be manually specified. Ship with Mable shipments must be purchased through the shipmentGroup API */
export type ShipmentInputOffMable = OneOfOpenValues<ShipmentInputSelf | ShipmentInputExternal>;
export type EncodedShipmentInputOffMable = OneOfOpenEncodedValues<EncodedShipmentInputSelf | EncodedShipmentInputExternal>;
export const ShipmentInputOffMable: t.Type<ShipmentInputOffMable, EncodedShipmentInputOffMable> = t.recursion('ShipmentInputOffMable', () => (
  OneOfOpen([ShipmentInputSelf, ShipmentInputExternal])
));


/** Type for a new shipment getting saved. */
export type ShipmentInput = OneOfOpenValues<ShipmentInputSelf | ShipmentInputExternal | ShipmentInputMable>;
export type EncodedShipmentInput = OneOfOpenEncodedValues<EncodedShipmentInputSelf | EncodedShipmentInputExternal | EncodedShipmentInputMable>;
export const ShipmentInput: t.Type<ShipmentInput, EncodedShipmentInput> = t.recursion('ShipmentInput', () => (
  OneOfOpen([ShipmentInputSelf, ShipmentInputExternal, ShipmentInputMable])
));


export type ShipmentBase = {
  'id': number,
  'transactionId': number,
  'cost'?: BigNumber,
  'createdAt': Date,
  /** Dimensions of the parcel (in inches) in the format "(length,height,width)" */
  'parcelDimensions'?: ParcelDimensions,
  /** Weight of the parcel (in ounces) */
  'parcelWeight'?: BigNumber,
};
export type EncodedShipmentBase = {
  'id': number,
  'transactionId': number,
  'cost'?: string,
  'createdAt': string,
  'parcelDimensions'?: string,
  'parcelWeight'?: string,
};
export const ShipmentBase: t.Type<ShipmentBase, EncodedShipmentBase> = t.recursion('ShipmentBase', () => (
  t.exact(t.intersection([t.type({
    'id': t.number,
    'transactionId': t.number,
    'createdAt': DateFromISOString,
  }), t.partial({
    'cost': NumberFromString,
    'parcelDimensions': ParcelDimensionsFromString,
    'parcelWeight': NumberFromString,
  })]), 'ShipmentBase')
));


export type ShipmentVoidable = ShipmentBase & {
  'voidedOn'?: Date,
};
export type EncodedShipmentVoidable = EncodedShipmentBase & {
  'voidedOn'?: string,
};
export const ShipmentVoidable: t.Type<ShipmentVoidable, EncodedShipmentVoidable> = t.recursion('ShipmentVoidable', () => (
  t.intersection([
    ShipmentBase,
    t.exact(t.intersection([t.type({
      
    }), t.partial({
      'voidedOn': DateFromISOString,
    })]), 'ShipmentVoidable')
  ], 'ShipmentVoidable')
));


export type ShipmentTracked = ShipmentBase & EasypostTrackable;
export type EncodedShipmentTracked = EncodedShipmentBase & EncodedEasypostTrackable;
export const ShipmentTracked: t.Type<ShipmentTracked, EncodedShipmentTracked> = t.recursion('ShipmentTracked', () => (
  t.intersection([
    ShipmentBase,
    EasypostTrackable
  ], 'ShipmentTracked')
));


export type ShipmentSelf = ShipmentVoidable & {
  'type': 'self_delivered',
  'selfDeliveryDate': Date,
};
export type EncodedShipmentSelf = EncodedShipmentVoidable & {
  'type': string,
  'selfDeliveryDate': string,
};
export const ShipmentSelf: t.Type<ShipmentSelf, EncodedShipmentSelf> = t.recursion('ShipmentSelf', () => (
  t.intersection([
    ShipmentVoidable,
    t.exact(t.intersection([t.type({
      'type': CodegenEnumClosed({
        'self_delivered': null,
      }, 'ShipmentSelf'),
      'selfDeliveryDate': DateFromISOString,
    }), t.partial({
      
    })]), 'ShipmentSelf')
  ], 'ShipmentSelf')
));


export type ShipmentExternal = ShipmentVoidable & ShipmentTracked & {
  'type': 'shipped_external',
};
export type EncodedShipmentExternal = EncodedShipmentVoidable & EncodedShipmentTracked & {
  'type': string,
};
export const ShipmentExternal: t.Type<ShipmentExternal, EncodedShipmentExternal> = t.recursion('ShipmentExternal', () => (
  t.intersection([
    ShipmentVoidable,
    ShipmentTracked,
    t.exact(t.intersection([t.type({
      'type': CodegenEnumClosed({
        'shipped_external': null,
      }, 'ShipmentExternal'),
    }), t.partial({
      
    })]), 'ShipmentExternal')
  ], 'ShipmentExternal')
));


export type ShipmentMable = ShipmentVoidable & ShipmentTracked & {
  'type': 'shipped_mable',
  'shipmentGroupId': number,
  'easypostShipmentId': string,
  'labelUrl': string,
};
export type EncodedShipmentMable = EncodedShipmentVoidable & EncodedShipmentTracked & {
  'type': string,
  'shipmentGroupId': number,
  'easypostShipmentId': string,
  'labelUrl': string,
};
export const ShipmentMable: t.Type<ShipmentMable, EncodedShipmentMable> = t.recursion('ShipmentMable', () => (
  t.intersection([
    ShipmentVoidable,
    ShipmentTracked,
    t.exact(t.intersection([t.type({
      'type': CodegenEnumClosed({
        'shipped_mable': null,
      }, 'ShipmentMable'),
      'shipmentGroupId': t.number,
      'easypostShipmentId': t.string,
      'labelUrl': t.string,
    }), t.partial({
      
    })]), 'ShipmentMable')
  ], 'ShipmentMable')
));


/** Each object inside has at least one required value to differentiate and allow TS fallthrough */
export type Shipment = OneOfOpenValues<ShipmentMable | ShipmentSelf | ShipmentExternal>;
export type EncodedShipment = OneOfOpenEncodedValues<EncodedShipmentMable | EncodedShipmentSelf | EncodedShipmentExternal>;
export const Shipment: t.Type<Shipment, EncodedShipment> = t.recursion('Shipment', () => (
  OneOfOpen([ShipmentMable, ShipmentSelf, ShipmentExternal])
));


export type Allergen = {
  'id'?: number,
  'allergen': string,
  'isSuggestionGenerated': boolean,
};
export type EncodedAllergen = {
  'id'?: number,
  'allergen': string,
  'isSuggestionGenerated': boolean,
};
export const Allergen: t.Type<Allergen, EncodedAllergen> = t.recursion('Allergen', () => (
  t.exact(t.intersection([t.type({
    'allergen': t.string,
    'isSuggestionGenerated': t.boolean,
  }), t.partial({
    'id': t.number,
  })]), 'Allergen')
));


export type AlertDetail = {
  /** Markdown text that should be displayed */
  'text'?: string,
  'icon'?: string,
  'type'?: CodegenEnumOpenValues<'banner'>,
  'link'?: string,
};
export type EncodedAlertDetail = {
  'text'?: string,
  'icon'?: string,
  'type'?: string,
  'link'?: string,
};
export const AlertDetail: t.Type<AlertDetail, EncodedAlertDetail> = t.recursion('AlertDetail', () => (
  t.exact(t.intersection([t.type({
    
  }), t.partial({
    'text': t.string,
    'icon': t.string,
    'type': CodegenEnumOpen({
      'banner': null,
    }, 'AlertDetail'),
    'link': t.string,
  })]), 'AlertDetail')
));


export type SellerSpreadsheet = {
  'id'?: number,
  'sellerId'?: number,
  'sheetUrl'?: string,
  'sheetType'?: CodegenEnumOpenValues<'full' | 'variantData'>,
  'emails'?: Array<string>,
  'hasIngestionError'?: boolean,
  'sheetLastChecked'?: Date,
  'sheetLastUpdated'?: Date,
  'createdAt'?: Date,
};
export type EncodedSellerSpreadsheet = {
  'id'?: number,
  'sellerId'?: number,
  'sheetUrl'?: string,
  'sheetType'?: string,
  'emails'?: Array<string>,
  'hasIngestionError'?: boolean,
  'sheetLastChecked'?: string,
  'sheetLastUpdated'?: string,
  'createdAt'?: string,
};
export const SellerSpreadsheet: t.Type<SellerSpreadsheet, EncodedSellerSpreadsheet> = t.recursion('SellerSpreadsheet', () => (
  t.exact(t.intersection([t.type({
    
  }), t.partial({
    'id': t.number,
    'sellerId': t.number,
    'sheetUrl': t.string,
    'sheetType': CodegenEnumOpen({
      'full': null,
      'variantData': null,
    }, 'SellerSpreadsheet'),
    'emails': t.array(t.string),
    'hasIngestionError': t.boolean,
    'sheetLastChecked': DateFromISOString,
    'sheetLastUpdated': DateFromISOString,
    'createdAt': DateFromISOString,
  })]), 'SellerSpreadsheet')
));


export type DisplayVariantTag = {
  'type'?: CodegenEnumOpenValues<'out-of-stock' | 'backordered' | 'sale' | 'high-sell-through' | 'coming-soon' | 'local' | 'bestseller'>,
};
export type EncodedDisplayVariantTag = {
  'type'?: string,
};
export const DisplayVariantTag: t.Type<DisplayVariantTag, EncodedDisplayVariantTag> = t.recursion('DisplayVariantTag', () => (
  t.exact(t.intersection([t.type({
    
  }), t.partial({
    'type': CodegenEnumOpen({
      'out-of-stock': null,
      'backordered': null,
      'sale': null,
      'high-sell-through': null,
      'coming-soon': null,
      'local': null,
      'bestseller': null,
    }, 'DisplayVariantTag'),
  })]), 'DisplayVariantTag')
));


export type DisplaySellerTag = {
  'type'?: CodegenEnumOpenValues<'local'>,
};
export type EncodedDisplaySellerTag = {
  'type'?: string,
};
export const DisplaySellerTag: t.Type<DisplaySellerTag, EncodedDisplaySellerTag> = t.recursion('DisplaySellerTag', () => (
  t.exact(t.intersection([t.type({
    
  }), t.partial({
    'type': CodegenEnumOpen({
      'local': null,
    }, 'DisplaySellerTag'),
  })]), 'DisplaySellerTag')
));


export type NavTreeNode = {
  'text': string,
  'decoration'?: CodegenEnumOpenValues<'red'>,
  'url'?: string,
  'children'?: Array<NavTreeNode>,
};
export type EncodedNavTreeNode = {
  'text': string,
  'decoration'?: string,
  'url'?: string,
  'children'?: Array<EncodedNavTreeNode>,
};
export const NavTreeNode: t.Type<NavTreeNode, EncodedNavTreeNode> = t.recursion('NavTreeNode', () => (
  t.exact(t.intersection([t.type({
    'text': t.string,
  }), t.partial({
    'decoration': CodegenEnumOpen({
      'red': null,
    }, 'NavTreeNode'),
    'url': t.string,
    'children': t.array(NavTreeNode),
  })]), 'NavTreeNode')
));


/** Brief information about each account that has a relationship to the given category */
export type CategoryAccountRelationship = {
  'categoryId'?: number,
  'categoryName'?: string,
  'accountId'?: number,
  /** Display name for the account (e.g. the buyer name) */
  'accountDisplayName'?: string,
  'type'?: CategoryAccountRelationshipType,
};
export type EncodedCategoryAccountRelationship = {
  'categoryId'?: number,
  'categoryName'?: string,
  'accountId'?: number,
  'accountDisplayName'?: string,
  'type'?: EncodedCategoryAccountRelationshipType,
};
export const CategoryAccountRelationship: t.Type<CategoryAccountRelationship, EncodedCategoryAccountRelationship> = t.recursion('CategoryAccountRelationship', () => (
  t.exact(t.intersection([t.type({
    
  }), t.partial({
    'categoryId': t.number,
    'categoryName': t.string,
    'accountId': t.number,
    'accountDisplayName': t.string,
    'type': CategoryAccountRelationshipType,
  })]), 'CategoryAccountRelationship')
));


/** The kind of relationship between the buyer location and category */
export type CategoryBuyerLocationRelationshipType = CodegenEnumOpenValues<'approvedProductList'>;
export type EncodedCategoryBuyerLocationRelationshipType = string;
export const CategoryBuyerLocationRelationshipType = CodegenEnumOpen({
  'approvedProductList': null,
}, 'CategoryBuyerLocationRelationshipType');
export const CategoryBuyerLocationRelationshipTypeValues = ['approvedProductList'] as const;

/** Brief information about each buyer location that has a relationship to the given category */
export type CategoryBuyerLocationRelationship = {
  'categoryId': number,
  'categoryName': string,
  'categorySlug': string,
  'buyerLocationId': string,
  /** Display name for the buyer location */
  'buyerLocationName': string,
  'type': CategoryBuyerLocationRelationshipType,
};
export type EncodedCategoryBuyerLocationRelationship = {
  'categoryId': number,
  'categoryName': string,
  'categorySlug': string,
  'buyerLocationId': string,
  'buyerLocationName': string,
  'type': EncodedCategoryBuyerLocationRelationshipType,
};
export const CategoryBuyerLocationRelationship: t.Type<CategoryBuyerLocationRelationship, EncodedCategoryBuyerLocationRelationship> = t.recursion('CategoryBuyerLocationRelationship', () => (
  t.exact(t.intersection([t.type({
    'categoryId': t.number,
    'categoryName': t.string,
    'categorySlug': t.string,
    'buyerLocationId': t.string,
    'buyerLocationName': t.string,
    'type': CategoryBuyerLocationRelationshipType,
  }), t.partial({
    
  })]), 'CategoryBuyerLocationRelationship')
));


/** The kind of relationship between the account and category */
export type CategoryAccountRelationshipType = CodegenEnumOpenValues<'personalized' | 'approvedProductList' | 'proposedProductList' | 'dashboardCard' | 'orderGuide' | 'partnerPriceUpdateExclusion'>;
export type EncodedCategoryAccountRelationshipType = string;
export const CategoryAccountRelationshipType = CodegenEnumOpen({
  'personalized': null,
  'approvedProductList': null,
  'proposedProductList': null,
  'dashboardCard': null,
  'orderGuide': null,
  'partnerPriceUpdateExclusion': null,
}, 'CategoryAccountRelationshipType');
export const CategoryAccountRelationshipTypeValues = ['personalized', 'approvedProductList', 'proposedProductList', 'dashboardCard', 'orderGuide', 'partnerPriceUpdateExclusion'] as const;

/** The kind of relationship between the user and category */
export type CategoryUserRelationshipType = CodegenEnumOpenValues<'favorites'>;
export type EncodedCategoryUserRelationshipType = string;
export const CategoryUserRelationshipType = CodegenEnumOpen({
  'favorites': null,
}, 'CategoryUserRelationshipType');
export const CategoryUserRelationshipTypeValues = ['favorites'] as const;

/** A simplified collection of information from a HubSpot company instance. */
export type HubSpotCompanyInfo = {
  'bio'?: string,
  'billingAddress'?: string,
  'values'?: Array<string>,
  'certifications'?: Array<string>,
  'companyName'?: string,
  'city'?: string,
  'state'?: string,
  'zip'?: string,
  'brandLogoAnswer'?: string,
  'brandBioAnswer'?: string,
  'productPhotosAnswer'?: string,
  'brandBannerAnswer'?: string,
  'firstName'?: string,
  'lastName'?: string,
  'phone'?: string,
  /** e.g. 'Boston, Massachusetts' */
  'location'?: string,
  /** For memo, or possibly public note */
  'additionalInfo'?: string,
  'poEmailAddress'?: string,
  'email'?: string,
  'orderMinimum'?: string,
  'personalizationTag'?: string,
  'website'?: string,
  /** Unstructured */
  'shippingLeadTime'?: string,
  /** No enforcement of image size or shape. Can't be trusted really. */
  'logoUrl'?: string,
  /** No enforcement of image size or shape. Can't be trusted really. */
  'bannerImageUrl'?: string,
  /** e.g. "Net 30". For memo. */
  'preferredPaymentTerms'?: string,
  /** Could be any type of file */
  'catalogFileUrl'?: string,
  'enableSamples'?: boolean,
  /** The referral code used on brand signup */
  'referredBy'?: string,
};
export type EncodedHubSpotCompanyInfo = {
  'bio'?: string,
  'billingAddress'?: string,
  'values'?: Array<string>,
  'certifications'?: Array<string>,
  'companyName'?: string,
  'city'?: string,
  'state'?: string,
  'zip'?: string,
  'brandLogoAnswer'?: string,
  'brandBioAnswer'?: string,
  'productPhotosAnswer'?: string,
  'brandBannerAnswer'?: string,
  'firstName'?: string,
  'lastName'?: string,
  'phone'?: string,
  'location'?: string,
  'additionalInfo'?: string,
  'poEmailAddress'?: string,
  'email'?: string,
  'orderMinimum'?: string,
  'personalizationTag'?: string,
  'website'?: string,
  'shippingLeadTime'?: string,
  'logoUrl'?: string,
  'bannerImageUrl'?: string,
  'preferredPaymentTerms'?: string,
  'catalogFileUrl'?: string,
  'enableSamples'?: boolean,
  'referredBy'?: string,
};
export const HubSpotCompanyInfo: t.Type<HubSpotCompanyInfo, EncodedHubSpotCompanyInfo> = t.recursion('HubSpotCompanyInfo', () => (
  t.exact(t.intersection([t.type({
    
  }), t.partial({
    'bio': t.string,
    'billingAddress': t.string,
    'values': t.array(t.string),
    'certifications': t.array(t.string),
    'companyName': t.string,
    'city': t.string,
    'state': t.string,
    'zip': t.string,
    'brandLogoAnswer': t.string,
    'brandBioAnswer': t.string,
    'productPhotosAnswer': t.string,
    'brandBannerAnswer': t.string,
    'firstName': t.string,
    'lastName': t.string,
    'phone': t.string,
    'location': t.string,
    'additionalInfo': t.string,
    'poEmailAddress': t.string,
    'email': t.string,
    'orderMinimum': t.string,
    'personalizationTag': t.string,
    'website': t.string,
    'shippingLeadTime': t.string,
    'logoUrl': t.string,
    'bannerImageUrl': t.string,
    'preferredPaymentTerms': t.string,
    'catalogFileUrl': t.string,
    'enableSamples': t.boolean,
    'referredBy': t.string,
  })]), 'HubSpotCompanyInfo')
));


/** The set of properties a buyer is allowed to change on themselves */
export type BuyerPatch = {
  /** The buyer's postal code */
  'postalCode'?: string,
  /** A referral code to suggest before first order */
  'firstOrderReferrerCode'?: string,
};
export type EncodedBuyerPatch = {
  'postalCode'?: string,
  'firstOrderReferrerCode'?: string,
};
export const BuyerPatch: t.Type<BuyerPatch, EncodedBuyerPatch> = t.recursion('BuyerPatch', () => (
  t.exact(t.intersection([t.type({
    
  }), t.partial({
    'postalCode': t.string,
    'firstOrderReferrerCode': t.string,
  })]), 'BuyerPatch')
));


/** The set of properties an admin is allowed to change */
export type AdminBuyerPatch = BuyerPatch & {
  'distributorRelationships'?: string,
  /** Update the buyer's extraData field with this value */
  'extraData'?: Record<string, unknown>,
  'facebookUrl'?: null | string,
  'instagramUrl'?: null | string,
  /** If the buyer is a seasonal location */
  'isSeasonal'?: boolean,
  /** The buyer's name */
  'name'?: string,
  'pauseAutoBilling'?: boolean,
  'paymentTermsNetDays'?: null | number,
  /** Set the terms that will take effect when next payment settles */
  'pendingPaymentTermsNetDays'?: null | number,
  'resaleCertificateS3Key'?: string,
  'storePresence'?: StorePresenceType,
  /** Whether ops has verified this buyer */
  'verified'?: boolean,
  'websiteUrl'?: null | string,
  'orderConfirmationSftpFormat'?: null | OrderConfirmationSftpFormat,
  /** If this buyer wants to recieve order confirmations via SFTP, this column will contain the path of where to place them. This is not the on/off flag for that feature. */
  'orderConfirmationSftpPath'?: null | string,
  'priority'?: boolean,
  /** The HubSpot ID of the buyer's company record in our HubSpot account. This is the last numeric component of the HubSpot URL in your browser.
  e.g. For https://app.hubspot.com/contacts/5411323/company/2209568622/ this should be "2209568622" */
  'hubSpotCompanyId'?: string,
  /** The identifier for the region that the buyer's prices are based on */
  'pricingRegionId'?: null | string,
};
export type EncodedAdminBuyerPatch = EncodedBuyerPatch & {
  'distributorRelationships'?: string,
  'extraData'?: Record<string, unknown>,
  'facebookUrl'?: null | string,
  'instagramUrl'?: null | string,
  'isSeasonal'?: boolean,
  'name'?: string,
  'pauseAutoBilling'?: boolean,
  'paymentTermsNetDays'?: null | number,
  'pendingPaymentTermsNetDays'?: null | number,
  'resaleCertificateS3Key'?: string,
  'storePresence'?: EncodedStorePresenceType,
  'verified'?: boolean,
  'websiteUrl'?: null | string,
  'orderConfirmationSftpFormat'?: null | EncodedOrderConfirmationSftpFormat,
  'orderConfirmationSftpPath'?: null | string,
  'priority'?: boolean,
  'hubSpotCompanyId'?: string,
  'pricingRegionId'?: null | string,
};
export const AdminBuyerPatch: t.Type<AdminBuyerPatch, EncodedAdminBuyerPatch> = t.recursion('AdminBuyerPatch', () => (
  t.intersection([
    BuyerPatch,
    t.exact(t.intersection([t.type({
      
    }), t.partial({
      'distributorRelationships': t.string,
      'extraData': t.record(t.string, t.unknown),
      'facebookUrl': t.union([t.null, t.string]),
      'instagramUrl': t.union([t.null, t.string]),
      'isSeasonal': t.boolean,
      'name': t.string,
      'pauseAutoBilling': t.boolean,
      'paymentTermsNetDays': t.union([t.null, t.number]),
      'pendingPaymentTermsNetDays': t.union([t.null, t.number]),
      'resaleCertificateS3Key': t.string,
      'storePresence': StorePresenceType,
      'verified': t.boolean,
      'websiteUrl': t.union([t.null, t.string]),
      'orderConfirmationSftpFormat': t.union([t.null, OrderConfirmationSftpFormat]),
      'orderConfirmationSftpPath': t.union([t.null, t.string]),
      'priority': t.boolean,
      'hubSpotCompanyId': t.string,
      'pricingRegionId': t.union([t.null, t.string]),
    })]), 'AdminBuyerPatch')
  ], 'AdminBuyerPatch')
));


export type RegionSlug = CodegenEnumOpenValues<'west' | 'southwest' | 'midwest' | 'south' | 'mid-atlantic' | 'new-england'>;
export type EncodedRegionSlug = string;
export const RegionSlug = CodegenEnumOpen({
  'west': null,
  'southwest': null,
  'midwest': null,
  'south': null,
  'mid-atlantic': null,
  'new-england': null,
}, 'RegionSlug');
export const RegionSlugValues = ['west', 'southwest', 'midwest', 'south', 'mid-atlantic', 'new-england'] as const;

export type AccountAuditLogInput = {
  'type': CodegenEnumOpenValues<'AccountCollectionsExtension' | 'Note'>,
  'date'?: Date,
  'note'?: string,
  'extraData'?: Record<string, unknown>,
};
export type EncodedAccountAuditLogInput = {
  'type': string,
  'date'?: string,
  'note'?: string,
  'extraData'?: Record<string, unknown>,
};
export const AccountAuditLogInput: t.Type<AccountAuditLogInput, EncodedAccountAuditLogInput> = t.recursion('AccountAuditLogInput', () => (
  t.exact(t.intersection([t.type({
    'type': CodegenEnumOpen({
      'AccountCollectionsExtension': null,
      'Note': null,
    }, 'AccountAuditLogInput'),
  }), t.partial({
    'date': DateFromISOString,
    'note': t.string,
    'extraData': t.record(t.string, t.unknown),
  })]), 'AccountAuditLogInput')
));


export type AccountWriteoffInput = {
  'note'?: string,
  'asOfDate'?: Date,
};
export type EncodedAccountWriteoffInput = {
  'note'?: string,
  'asOfDate'?: string,
};
export const AccountWriteoffInput: t.Type<AccountWriteoffInput, EncodedAccountWriteoffInput> = t.recursion('AccountWriteoffInput', () => (
  t.exact(t.intersection([t.type({
    
  }), t.partial({
    'note': t.string,
    'asOfDate': DateFromISOString,
  })]), 'AccountWriteoffInput')
));


export type SellerAuditLogEntry = {
  'id'?: number,
  'date'?: Date,
  'createdByUserId'?: number,
  'note'?: string,
  'extraData'?: Record<string, unknown>,
};
export type EncodedSellerAuditLogEntry = {
  'id'?: number,
  'date'?: string,
  'createdByUserId'?: number,
  'note'?: string,
  'extraData'?: Record<string, unknown>,
};
export const SellerAuditLogEntry: t.Type<SellerAuditLogEntry, EncodedSellerAuditLogEntry> = t.recursion('SellerAuditLogEntry', () => (
  t.exact(t.intersection([t.type({
    
  }), t.partial({
    'id': t.number,
    'date': DateFromISOString,
    'createdByUserId': t.number,
    'note': t.string,
    'extraData': t.record(t.string, t.unknown),
  })]), 'SellerAuditLogEntry')
));


export type AuditLogEntry = {
  'id': number,
  'type': string,
  'date': Date,
  'createdAt'?: Date,
  'createdByUserId'?: number,
  'createdByUserName'?: string,
  'createdByUserEmail'?: string,
  'createdByUserAccountId'?: number,
  'note'?: string,
  'extraData': Record<string, unknown>,
};
export type EncodedAuditLogEntry = {
  'id': number,
  'type': string,
  'date': string,
  'createdAt'?: string,
  'createdByUserId'?: number,
  'createdByUserName'?: string,
  'createdByUserEmail'?: string,
  'createdByUserAccountId'?: number,
  'note'?: string,
  'extraData': Record<string, unknown>,
};
export const AuditLogEntry: t.Type<AuditLogEntry, EncodedAuditLogEntry> = t.recursion('AuditLogEntry', () => (
  t.exact(t.intersection([t.type({
    'id': t.number,
    'type': t.string,
    'date': DateFromISOString,
    'extraData': t.record(t.string, t.unknown),
  }), t.partial({
    'createdAt': DateFromISOString,
    'createdByUserId': t.number,
    'createdByUserName': t.string,
    'createdByUserEmail': t.string,
    'createdByUserAccountId': t.number,
    'note': t.string,
  })]), 'AuditLogEntry')
));


export type SellerGoLiveVariantIssue = {
  'variantId': number,
  'productId': number,
  'variantName'?: string,
  'type': SellerGoLiveVariantIssueType,
};
export type EncodedSellerGoLiveVariantIssue = {
  'variantId': number,
  'productId': number,
  'variantName'?: string,
  'type': EncodedSellerGoLiveVariantIssueType,
};
export const SellerGoLiveVariantIssue: t.Type<SellerGoLiveVariantIssue, EncodedSellerGoLiveVariantIssue> = t.recursion('SellerGoLiveVariantIssue', () => (
  t.exact(t.intersection([t.type({
    'variantId': t.number,
    'productId': t.number,
    'type': SellerGoLiveVariantIssueType,
  }), t.partial({
    'variantName': t.string,
  })]), 'SellerGoLiveVariantIssue')
));


export type SellerGoLiveVariantIssueType = CodegenEnumOpenValues<'noImages' | 'noPrice' | 'noDescription' | 'noBackInStockDate' | 'noBarcodeVariants'>;
export type EncodedSellerGoLiveVariantIssueType = string;
export const SellerGoLiveVariantIssueType = CodegenEnumOpen({
  'noImages': null,
  'noPrice': null,
  'noDescription': null,
  'noBackInStockDate': null,
  'noBarcodeVariants': null,
}, 'SellerGoLiveVariantIssueType');
export const SellerGoLiveVariantIssueTypeValues = ['noImages', 'noPrice', 'noDescription', 'noBackInStockDate', 'noBarcodeVariants'] as const;

export type SellerGoLiveProfileIssueType = CodegenEnumOpenValues<'noProfileReview' | 'noProductsReview' | 'noDescription' | 'noImage' | 'notDraft'>;
export type EncodedSellerGoLiveProfileIssueType = string;
export const SellerGoLiveProfileIssueType = CodegenEnumOpen({
  'noProfileReview': null,
  'noProductsReview': null,
  'noDescription': null,
  'noImage': null,
  'notDraft': null,
}, 'SellerGoLiveProfileIssueType');
export const SellerGoLiveProfileIssueTypeValues = ['noProfileReview', 'noProductsReview', 'noDescription', 'noImage', 'notDraft'] as const;

export type ShippingSavings = {
  'savingsEstimate': BigNumber,
  'dismissed': boolean,
};
export type EncodedShippingSavings = {
  'savingsEstimate': string,
  'dismissed': boolean,
};
export const ShippingSavings: t.Type<ShippingSavings, EncodedShippingSavings> = t.recursion('ShippingSavings', () => (
  t.exact(t.intersection([t.type({
    'savingsEstimate': NumberFromString,
    'dismissed': t.boolean,
  }), t.partial({
    
  })]), 'ShippingSavings')
));


/** Changes to a list regarding product variants */
export type ListProductVariantChanges = {
  'addIds'?: Array<number>,
  'removeIds'?: Array<number>,
};
export type EncodedListProductVariantChanges = {
  'addIds'?: Array<number>,
  'removeIds'?: Array<number>,
};
export const ListProductVariantChanges: t.Type<ListProductVariantChanges, EncodedListProductVariantChanges> = t.recursion('ListProductVariantChanges', () => (
  t.exact(t.intersection([t.type({
    
  }), t.partial({
    'addIds': t.array(t.number),
    'removeIds': t.array(t.number),
  })]), 'ListProductVariantChanges')
));


export type FreeShippingOverride = CodegenEnumOpenValues<'unset' | 'on' | 'off'>;
export type EncodedFreeShippingOverride = string;
export const FreeShippingOverride = CodegenEnumOpen({
  'unset': null,
  'on': null,
  'off': null,
}, 'FreeShippingOverride');
export const FreeShippingOverrideValues = ['unset', 'on', 'off'] as const;

export type ShippingPolicy = CodegenEnumOpenValues<'none' | 'free' | 'sellerMinimum' | 'capped'>;
export type EncodedShippingPolicy = string;
export const ShippingPolicy = CodegenEnumOpen({
  'none': null,
  'free': null,
  'sellerMinimum': null,
  'capped': null,
}, 'ShippingPolicy');
export const ShippingPolicyValues = ['none', 'free', 'sellerMinimum', 'capped'] as const;

/** Describes what "list" an object belongs to */
export type ListMembership = {
  /** The display name of this list to show the user */
  'name': string,
  'identifier': ListIdentifier,
};
export type EncodedListMembership = {
  'name': string,
  'identifier': EncodedListIdentifier,
};
export const ListMembership: t.Type<ListMembership, EncodedListMembership> = t.recursion('ListMembership', () => (
  t.exact(t.intersection([t.type({
    'name': t.string,
    'identifier': ListIdentifier,
  }), t.partial({
    
  })]), 'ListMembership')
));


export type SellerEmailCampaignInput = {
  'name': string,
  'emails': Array<string>,
  'subject': string,
  'headerText': string,
  'headerImageUrl': string,
  'campaignDefinitionName': SellerEmailCampaignDefinitionName,
};
export type EncodedSellerEmailCampaignInput = {
  'name': string,
  'emails': Array<string>,
  'subject': string,
  'headerText': string,
  'headerImageUrl': string,
  'campaignDefinitionName': EncodedSellerEmailCampaignDefinitionName,
};
export const SellerEmailCampaignInput: t.Type<SellerEmailCampaignInput, EncodedSellerEmailCampaignInput> = t.recursion('SellerEmailCampaignInput', () => (
  t.exact(t.intersection([t.type({
    'name': t.string,
    'emails': t.array(t.string),
    'subject': t.string,
    'headerText': t.string,
    'headerImageUrl': t.string,
    'campaignDefinitionName': SellerEmailCampaignDefinitionName,
  }), t.partial({
    
  })]), 'SellerEmailCampaignInput')
));


/** Names of campaigns sellers are allowed to make enrollments in */
export type SellerEmailCampaignDefinitionName = CodegenEnumOpenValues<'samplesProspect'>;
export type EncodedSellerEmailCampaignDefinitionName = string;
export const SellerEmailCampaignDefinitionName = CodegenEnumOpen({
  'samplesProspect': null,
}, 'SellerEmailCampaignDefinitionName');
export const SellerEmailCampaignDefinitionNameValues = ['samplesProspect'] as const;

/** Admin only email campaign names that have a single, global campaign defintiion */
export type AdminGlobalEmailCampaignDefinitionName = CodegenEnumOpenValues<'sellerAccountSetup' | 'sellerPartnerOnboarding' | 'sampleRequestAcceptance' | 'sampleRequestShipping' | 'sampleRequestFeedback' | 'buyerVerification'>;
export type EncodedAdminGlobalEmailCampaignDefinitionName = string;
export const AdminGlobalEmailCampaignDefinitionName = CodegenEnumOpen({
  'sellerAccountSetup': null,
  'sellerPartnerOnboarding': null,
  'sampleRequestAcceptance': null,
  'sampleRequestShipping': null,
  'sampleRequestFeedback': null,
  'buyerVerification': null,
}, 'AdminGlobalEmailCampaignDefinitionName');
export const AdminGlobalEmailCampaignDefinitionNameValues = ['sellerAccountSetup', 'sellerPartnerOnboarding', 'sampleRequestAcceptance', 'sampleRequestShipping', 'sampleRequestFeedback', 'buyerVerification'] as const;

/** Names of all email campaigns, including those not directly accessible to non-admin users */
export type AdminEmailCampaignDefinitionName = SellerEmailCampaignDefinitionName | AdminGlobalEmailCampaignDefinitionName | CodegenEnumOpenValues<'brandReferral' | 'paymentCollections'>;
export type EncodedAdminEmailCampaignDefinitionName = EncodedSellerEmailCampaignDefinitionName | EncodedAdminGlobalEmailCampaignDefinitionName | string;
export const AdminEmailCampaignDefinitionName: t.Type<AdminEmailCampaignDefinitionName, EncodedAdminEmailCampaignDefinitionName> = t.recursion('AdminEmailCampaignDefinitionName', () => (
  OneOfClosed([SellerEmailCampaignDefinitionName, AdminGlobalEmailCampaignDefinitionName, CodegenEnumOpen({
    'brandReferral': null,
    'paymentCollections': null,
  }, 'AdminEmailCampaignDefinitionName')])
));


export type PricingVariantSpecification = OneOfOpenValues<PricingVariantSpecificationById | PricingVariantSpecificationAll>;
export type EncodedPricingVariantSpecification = OneOfOpenEncodedValues<EncodedPricingVariantSpecificationById | EncodedPricingVariantSpecificationAll>;
export const PricingVariantSpecification: t.Type<PricingVariantSpecification, EncodedPricingVariantSpecification> = t.recursion('PricingVariantSpecification', () => (
  OneOfOpen([PricingVariantSpecificationById, PricingVariantSpecificationAll])
));


/** List of variant IDs */
export type PricingVariantSpecificationById = {
  'type': 'PricingVariantSpecificationById',
  'variantIds': Array<number>,
};
export type EncodedPricingVariantSpecificationById = {
  'type': string,
  'variantIds': Array<number>,
};
export const PricingVariantSpecificationById: t.Type<PricingVariantSpecificationById, EncodedPricingVariantSpecificationById> = t.recursion('PricingVariantSpecificationById', () => (
  t.exact(t.intersection([t.type({
    'type': CodegenEnumClosed({
      'PricingVariantSpecificationById': null,
    }, 'PricingVariantSpecificationById'),
    'variantIds': t.array(t.number),
  }), t.partial({
    
  })]), 'PricingVariantSpecificationById')
));


/** An object representing to take action on all variants */
export type PricingVariantSpecificationAll = {
  'type': 'PricingVariantSpecificationAll',
};
export type EncodedPricingVariantSpecificationAll = {
  'type': string,
};
export const PricingVariantSpecificationAll: t.Type<PricingVariantSpecificationAll, EncodedPricingVariantSpecificationAll> = t.recursion('PricingVariantSpecificationAll', () => (
  t.exact(t.intersection([t.type({
    'type': CodegenEnumClosed({
      'PricingVariantSpecificationAll': null,
    }, 'PricingVariantSpecificationAll'),
  }), t.partial({
    
  })]), 'PricingVariantSpecificationAll')
));


export type ProductVariantMarginPreview = {
  'margin': BigNumber,
  'priceRetail': BigNumber,
};
export type EncodedProductVariantMarginPreview = {
  'margin': string,
  'priceRetail': string,
};
export const ProductVariantMarginPreview: t.Type<ProductVariantMarginPreview, EncodedProductVariantMarginPreview> = t.recursion('ProductVariantMarginPreview', () => (
  t.exact(t.intersection([t.type({
    'margin': NumberFromString,
    'priceRetail': NumberFromString,
  }), t.partial({
    
  })]), 'ProductVariantMarginPreview')
));


export type ProductVariantDisplayInfoWithShopify = ProductVariantListDisplayInfo & {
  'shopifyVariantId'?: string,
  'shopifyMatchType'?: ShopifyProductMatchType,
};
export type EncodedProductVariantDisplayInfoWithShopify = EncodedProductVariantListDisplayInfo & {
  'shopifyVariantId'?: string,
  'shopifyMatchType'?: EncodedShopifyProductMatchType,
};
export const ProductVariantDisplayInfoWithShopify: t.Type<ProductVariantDisplayInfoWithShopify, EncodedProductVariantDisplayInfoWithShopify> = t.recursion('ProductVariantDisplayInfoWithShopify', () => (
  t.intersection([
    ProductVariantListDisplayInfo,
    t.exact(t.intersection([t.type({
      
    }), t.partial({
      'shopifyVariantId': t.string,
      'shopifyMatchType': ShopifyProductMatchType,
    })]), 'ProductVariantDisplayInfoWithShopify')
  ], 'ProductVariantDisplayInfoWithShopify')
));


export type ShopifyAccountConnection = {
  'sellerName': string,
  'sellerId': number,
};
export type EncodedShopifyAccountConnection = {
  'sellerName': string,
  'sellerId': number,
};
export const ShopifyAccountConnection: t.Type<ShopifyAccountConnection, EncodedShopifyAccountConnection> = t.recursion('ShopifyAccountConnection', () => (
  t.exact(t.intersection([t.type({
    'sellerName': t.string,
    'sellerId': t.number,
  }), t.partial({
    
  })]), 'ShopifyAccountConnection')
));


export type ShopifyProductMatchVariant = {
  'shopifyVariantId': string,
  'name': string,
  'sku'?: null | string,
  'imageUrl'?: string,
};
export type EncodedShopifyProductMatchVariant = {
  'shopifyVariantId': string,
  'name': string,
  'sku'?: null | string,
  'imageUrl'?: string,
};
export const ShopifyProductMatchVariant: t.Type<ShopifyProductMatchVariant, EncodedShopifyProductMatchVariant> = t.recursion('ShopifyProductMatchVariant', () => (
  t.exact(t.intersection([t.type({
    'shopifyVariantId': t.string,
    'name': t.string,
  }), t.partial({
    'sku': t.union([t.null, t.string]),
    'imageUrl': t.string,
  })]), 'ShopifyProductMatchVariant')
));


/** The authorization + settings state of Shopify for a seller, all fields are required/non-null if authorized is true */
export type ShopifyAuthorization = {
  'authorized': boolean,
  'shopifyShop'?: string,
  'syncOrdersToShopify'?: boolean,
  'commissionRepresentation'?: ShopifyOrderCommissionRepresentation,
  'lineItemCountStyle'?: ShopifyOrderLineItemCountStyle,
  'orderSyncStyle'?: ShopifyOrderState,
  'customerStyle'?: ShopifyCustomerStyle,
  'shippingSyncStyle'?: ShopifyShippingSyncStyle,
};
export type EncodedShopifyAuthorization = {
  'authorized': boolean,
  'shopifyShop'?: string,
  'syncOrdersToShopify'?: boolean,
  'commissionRepresentation'?: EncodedShopifyOrderCommissionRepresentation,
  'lineItemCountStyle'?: EncodedShopifyOrderLineItemCountStyle,
  'orderSyncStyle'?: EncodedShopifyOrderState,
  'customerStyle'?: EncodedShopifyCustomerStyle,
  'shippingSyncStyle'?: EncodedShopifyShippingSyncStyle,
};
export const ShopifyAuthorization: t.Type<ShopifyAuthorization, EncodedShopifyAuthorization> = t.recursion('ShopifyAuthorization', () => (
  t.exact(t.intersection([t.type({
    'authorized': t.boolean,
  }), t.partial({
    'shopifyShop': t.string,
    'syncOrdersToShopify': t.boolean,
    'commissionRepresentation': ShopifyOrderCommissionRepresentation,
    'lineItemCountStyle': ShopifyOrderLineItemCountStyle,
    'orderSyncStyle': ShopifyOrderState,
    'customerStyle': ShopifyCustomerStyle,
    'shippingSyncStyle': ShopifyShippingSyncStyle,
  })]), 'ShopifyAuthorization')
));


export type ShopifyProductMatch = {
  'mableVariantId': number,
  'shopifyVariantId': string,
};
export type EncodedShopifyProductMatch = {
  'mableVariantId': number,
  'shopifyVariantId': string,
};
export const ShopifyProductMatch: t.Type<ShopifyProductMatch, EncodedShopifyProductMatch> = t.recursion('ShopifyProductMatch', () => (
  t.exact(t.intersection([t.type({
    'mableVariantId': t.number,
    'shopifyVariantId': t.string,
  }), t.partial({
    
  })]), 'ShopifyProductMatch')
));


export type ShopifyProductMatchType = CodegenEnumOpenValues<'automatic' | 'manual' | 'intentionallyUnmatched'>;
export type EncodedShopifyProductMatchType = string;
export const ShopifyProductMatchType = CodegenEnumOpen({
  'automatic': null,
  'manual': null,
  'intentionallyUnmatched': null,
}, 'ShopifyProductMatchType');
export const ShopifyProductMatchTypeValues = ['automatic', 'manual', 'intentionallyUnmatched'] as const;

export type ShopifyCustomerStyle = CodegenEnumOpenValues<'person' | 'business'>;
export type EncodedShopifyCustomerStyle = string;
export const ShopifyCustomerStyle = CodegenEnumOpen({
  'person': null,
  'business': null,
}, 'ShopifyCustomerStyle');
export const ShopifyCustomerStyleValues = ['person', 'business'] as const;

/** Whether to automatically sync shipping information from Shopify into Mable */
export type ShopifyShippingSyncStyle = CodegenEnumOpenValues<'dont_sync' | 'sync'>;
export type EncodedShopifyShippingSyncStyle = string;
export const ShopifyShippingSyncStyle = CodegenEnumOpen({
  'dont_sync': null,
  'sync': null,
}, 'ShopifyShippingSyncStyle');
export const ShopifyShippingSyncStyleValues = ['dont_sync', 'sync'] as const;

export type ShopifyOrderState = CodegenEnumOpenValues<'draft' | 'paid_order'>;
export type EncodedShopifyOrderState = string;
export const ShopifyOrderState = CodegenEnumOpen({
  'draft': null,
  'paid_order': null,
}, 'ShopifyOrderState');
export const ShopifyOrderStateValues = ['draft', 'paid_order'] as const;

export type ShopifyOrderLineItemCountStyle = CodegenEnumOpenValues<'case' | 'each'>;
export type EncodedShopifyOrderLineItemCountStyle = string;
export const ShopifyOrderLineItemCountStyle = CodegenEnumOpen({
  'case': null,
  'each': null,
}, 'ShopifyOrderLineItemCountStyle');
export const ShopifyOrderLineItemCountStyleValues = ['case', 'each'] as const;

export type ShopifyOrderCommissionRepresentation = CodegenEnumOpenValues<'note' | 'lineItem' | 'discount' | 'omit'>;
export type EncodedShopifyOrderCommissionRepresentation = string;
export const ShopifyOrderCommissionRepresentation = CodegenEnumOpen({
  'note': null,
  'lineItem': null,
  'discount': null,
  'omit': null,
}, 'ShopifyOrderCommissionRepresentation');
export const ShopifyOrderCommissionRepresentationValues = ['note', 'lineItem', 'discount', 'omit'] as const;

export type ShopifyOrderStatus = CodegenEnumOpenValues<'queued' | 'failed' | 'done'>;
export type EncodedShopifyOrderStatus = string;
export const ShopifyOrderStatus = CodegenEnumOpen({
  'queued': null,
  'failed': null,
  'done': null,
}, 'ShopifyOrderStatus');
export const ShopifyOrderStatusValues = ['queued', 'failed', 'done'] as const;

/** Type of JSON object that gets send to Sendbird for a channel's 'data' field. Gives more info about the users in the chat so presentation can be properly contextualized on the clients. */
export type SendbirdChannelMableMetadata = {
  'accounts': Array<SendbirdMableAccountMetadata>,
};
export type EncodedSendbirdChannelMableMetadata = {
  'accounts': Array<EncodedSendbirdMableAccountMetadata>,
};
export const SendbirdChannelMableMetadata: t.Type<SendbirdChannelMableMetadata, EncodedSendbirdChannelMableMetadata> = t.recursion('SendbirdChannelMableMetadata', () => (
  t.exact(t.intersection([t.type({
    'accounts': t.array(SendbirdMableAccountMetadata),
  }), t.partial({
    
  })]), 'SendbirdChannelMableMetadata')
));


export type SendbirdMableAccountMetadata = {
  'accountId': number,
  'seller'?: SendbirdMableSellerMetadata,
  'buyer'?: SendbirdMableBuyerMetadata,
};
export type EncodedSendbirdMableAccountMetadata = {
  'accountId': number,
  'seller'?: EncodedSendbirdMableSellerMetadata,
  'buyer'?: EncodedSendbirdMableBuyerMetadata,
};
export const SendbirdMableAccountMetadata: t.Type<SendbirdMableAccountMetadata, EncodedSendbirdMableAccountMetadata> = t.recursion('SendbirdMableAccountMetadata', () => (
  t.exact(t.intersection([t.type({
    'accountId': t.number,
  }), t.partial({
    'seller': SendbirdMableSellerMetadata,
    'buyer': SendbirdMableBuyerMetadata,
  })]), 'SendbirdMableAccountMetadata')
));


export type SendbirdMableSellerMetadata = {
  'sellerId': number,
  'slug': Slug,
  'name': string,
  'image'?: Image,
};
export type EncodedSendbirdMableSellerMetadata = {
  'sellerId': number,
  'slug': string,
  'name': string,
  'image'?: EncodedImage,
};
export const SendbirdMableSellerMetadata: t.Type<SendbirdMableSellerMetadata, EncodedSendbirdMableSellerMetadata> = t.recursion('SendbirdMableSellerMetadata', () => (
  t.exact(t.intersection([t.type({
    'sellerId': t.number,
    'slug': Slug,
    'name': t.string,
  }), t.partial({
    'image': Image,
  })]), 'SendbirdMableSellerMetadata')
));


export type SendbirdMableBuyerMetadata = {
  'buyerId': number,
  /** The name of the buyer's business */
  'name'?: string,
};
export type EncodedSendbirdMableBuyerMetadata = {
  'buyerId': number,
  'name'?: string,
};
export const SendbirdMableBuyerMetadata: t.Type<SendbirdMableBuyerMetadata, EncodedSendbirdMableBuyerMetadata> = t.recursion('SendbirdMableBuyerMetadata', () => (
  t.exact(t.intersection([t.type({
    'buyerId': t.number,
  }), t.partial({
    'name': t.string,
  })]), 'SendbirdMableBuyerMetadata')
));


export type SendbirdMableUserMetadata = {
  'userId': number,
  'sendbirdUserId': string,
};
export type EncodedSendbirdMableUserMetadata = {
  'userId': number,
  'sendbirdUserId': string,
};
export const SendbirdMableUserMetadata: t.Type<SendbirdMableUserMetadata, EncodedSendbirdMableUserMetadata> = t.recursion('SendbirdMableUserMetadata', () => (
  t.exact(t.intersection([t.type({
    'userId': t.number,
    'sendbirdUserId': t.string,
  }), t.partial({
    
  })]), 'SendbirdMableUserMetadata')
));


export type MessageChannelAdminDisplayInfo = {
  'id': string,
  'sendbirdChannelUrl'?: string,
  'buyer'?: Buyer,
  'seller'?: SellerDisplayInfo,
};
export type EncodedMessageChannelAdminDisplayInfo = {
  'id': string,
  'sendbirdChannelUrl'?: string,
  'buyer'?: EncodedBuyer,
  'seller'?: EncodedSellerDisplayInfo,
};
export const MessageChannelAdminDisplayInfo: t.Type<MessageChannelAdminDisplayInfo, EncodedMessageChannelAdminDisplayInfo> = t.recursion('MessageChannelAdminDisplayInfo', () => (
  t.exact(t.intersection([t.type({
    'id': t.string,
  }), t.partial({
    'sendbirdChannelUrl': t.string,
    'buyer': Buyer,
    'seller': SellerDisplayInfo,
  })]), 'MessageChannelAdminDisplayInfo')
));


/** Parameters needed to create a new channel with a buyer */
export type OpenMessageChannelWithBuyerParams = {
  'type': 'buyer',
  'buyerId': number,
  'messageText'?: string,
};
export type EncodedOpenMessageChannelWithBuyerParams = {
  'type': string,
  'buyerId': number,
  'messageText'?: string,
};
export const OpenMessageChannelWithBuyerParams: t.Type<OpenMessageChannelWithBuyerParams, EncodedOpenMessageChannelWithBuyerParams> = t.recursion('OpenMessageChannelWithBuyerParams', () => (
  t.exact(t.intersection([t.type({
    'type': CodegenEnumClosed({
      'buyer': null,
    }, 'OpenMessageChannelWithBuyerParams'),
    'buyerId': t.number,
  }), t.partial({
    'messageText': t.string,
  })]), 'OpenMessageChannelWithBuyerParams')
));


/** Parameters needed to create a new channel with a seller */
export type OpenMessageChannelWithSellerParams = {
  'type': 'seller',
  'sellerId': number,
  'messageText'?: string,
};
export type EncodedOpenMessageChannelWithSellerParams = {
  'type': string,
  'sellerId': number,
  'messageText'?: string,
};
export const OpenMessageChannelWithSellerParams: t.Type<OpenMessageChannelWithSellerParams, EncodedOpenMessageChannelWithSellerParams> = t.recursion('OpenMessageChannelWithSellerParams', () => (
  t.exact(t.intersection([t.type({
    'type': CodegenEnumClosed({
      'seller': null,
    }, 'OpenMessageChannelWithSellerParams'),
    'sellerId': t.number,
  }), t.partial({
    'messageText': t.string,
  })]), 'OpenMessageChannelWithSellerParams')
));


/** Parameters needed to create a new message channel */
export type OpenMessageChannelParams = OpenMessageChannelWithBuyerParams | OpenMessageChannelWithSellerParams;
export type EncodedOpenMessageChannelParams = EncodedOpenMessageChannelWithBuyerParams | EncodedOpenMessageChannelWithSellerParams;
export const OpenMessageChannelParams: t.Type<OpenMessageChannelParams, EncodedOpenMessageChannelParams> = t.recursion('OpenMessageChannelParams', () => (
  OneOfClosed([OpenMessageChannelWithBuyerParams, OpenMessageChannelWithSellerParams])
));


/** Display version of a sendbird message channel. */
export type SendbirdGroupMessageChannelDisplay = {
  'name': string,
  'sendbirdChannelUrl': string,
  'data'?: SendbirdChannelMableMetadata,
  'isMember': boolean,
};
export type EncodedSendbirdGroupMessageChannelDisplay = {
  'name': string,
  'sendbirdChannelUrl': string,
  'data'?: EncodedSendbirdChannelMableMetadata,
  'isMember': boolean,
};
export const SendbirdGroupMessageChannelDisplay: t.Type<SendbirdGroupMessageChannelDisplay, EncodedSendbirdGroupMessageChannelDisplay> = t.recursion('SendbirdGroupMessageChannelDisplay', () => (
  t.exact(t.intersection([t.type({
    'name': t.string,
    'sendbirdChannelUrl': t.string,
    'isMember': t.boolean,
  }), t.partial({
    'data': SendbirdChannelMableMetadata,
  })]), 'SendbirdGroupMessageChannelDisplay')
));


/** Details about the message channel */
export type MessageChannelDisplayInfo = {
  'opposingBuyer'?: {
    'potentiallyUnresponsive'?: boolean,
  },
};
export type EncodedMessageChannelDisplayInfo = {
  'opposingBuyer'?: {
    'potentiallyUnresponsive'?: boolean,
  },
};
export const MessageChannelDisplayInfo: t.Type<MessageChannelDisplayInfo, EncodedMessageChannelDisplayInfo> = t.recursion('MessageChannelDisplayInfo', () => (
  t.exact(t.intersection([t.type({
    
  }), t.partial({
    'opposingBuyer': t.exact(t.intersection([t.type({
      
    }), t.partial({
      'potentiallyUnresponsive': t.boolean,
    })]), 'MessageChannelDisplayInfo'),
  })]), 'MessageChannelDisplayInfo')
));


/** Information about the Sendbird message channel to help with managing the channel. */
export type SendbirdGroupMessageChannelAdminDisplay = {
  'name': string,
  'sendbirdChannelUrl': string,
  'accounts': Array<AccountAdminDisplayInfo>,
  'sendbirdUserIdsInChannel': Array<string>,
  'data'?: SendbirdChannelMableMetadata,
  /** The full JSON response from the Sendbird API */
  'sendbirdJson': Record<string, unknown>,
  /** Simplified message display. */
  'messages'?: Array<string>,
};
export type EncodedSendbirdGroupMessageChannelAdminDisplay = {
  'name': string,
  'sendbirdChannelUrl': string,
  'accounts': Array<EncodedAccountAdminDisplayInfo>,
  'sendbirdUserIdsInChannel': Array<string>,
  'data'?: EncodedSendbirdChannelMableMetadata,
  'sendbirdJson': Record<string, unknown>,
  'messages'?: Array<string>,
};
export const SendbirdGroupMessageChannelAdminDisplay: t.Type<SendbirdGroupMessageChannelAdminDisplay, EncodedSendbirdGroupMessageChannelAdminDisplay> = t.recursion('SendbirdGroupMessageChannelAdminDisplay', () => (
  t.exact(t.intersection([t.type({
    'name': t.string,
    'sendbirdChannelUrl': t.string,
    'accounts': t.array(AccountAdminDisplayInfo),
    'sendbirdUserIdsInChannel': t.array(t.string),
    'sendbirdJson': t.record(t.string, t.unknown),
  }), t.partial({
    'data': SendbirdChannelMableMetadata,
    'messages': t.array(t.string),
  })]), 'SendbirdGroupMessageChannelAdminDisplay')
));


export type SendbirdSessionToken = {
  'sessionToken': string,
  'expiresAt': Date,
};
export type EncodedSendbirdSessionToken = {
  'sessionToken': string,
  'expiresAt': string,
};
export const SendbirdSessionToken: t.Type<SendbirdSessionToken, EncodedSendbirdSessionToken> = t.recursion('SendbirdSessionToken', () => (
  t.exact(t.intersection([t.type({
    'sessionToken': t.string,
    'expiresAt': DateFromISOString,
  }), t.partial({
    
  })]), 'SendbirdSessionToken')
));


export type SellerUpdateConfirmationCode = CodegenEnumOpenValues<'cancelSamplesRequiresConfirmation'>;
export type EncodedSellerUpdateConfirmationCode = string;
export const SellerUpdateConfirmationCode = CodegenEnumOpen({
  'cancelSamplesRequiresConfirmation': null,
}, 'SellerUpdateConfirmationCode');
export const SellerUpdateConfirmationCodeValues = ['cancelSamplesRequiresConfirmation'] as const;

export type AccountBalances = {
  'outstandingBalance'?: BigNumber,
  'outstandingCount'?: number,
  'overdueBalance'?: BigNumber,
  'overdueCount'?: number,
};
export type EncodedAccountBalances = {
  'outstandingBalance'?: string,
  'outstandingCount'?: number,
  'overdueBalance'?: string,
  'overdueCount'?: number,
};
export const AccountBalances: t.Type<AccountBalances, EncodedAccountBalances> = t.recursion('AccountBalances', () => (
  t.exact(t.intersection([t.type({
    
  }), t.partial({
    'outstandingBalance': NumberFromString,
    'outstandingCount': t.number,
    'overdueBalance': NumberFromString,
    'overdueCount': t.number,
  })]), 'AccountBalances')
));


export type WatchdogMonitorName = CodegenEnumOpenValues<'buyerCoversShippingCheck' | 'buyerInMultiplePricingTiersPerSeller' | 'highPurchaseTotalPartnerships' | 'inconsistentlyDeletedVariants' | 'missingNACSCategoryInPartnerAPL' | 'noNewOrders' | 'nonReducingPriceReplacements' | 'oldAuthorizedPayments' | 'oldPausedFulfillmentRoutingOrders' | 'ordersWithShippingIssues' | 'orphanedProductVariantIdentifiers' | 'partnerAccountConfiguration' | 'partnerAPLIncludesBuyerAPLVariants' | 'partneredSellersMissingPartneredProducts' | 'personalizedPricingCheck' | 'sellerMissingNavigableCategory' | 'stuckEdiFileUploads' | 'unbalancedCompleteOrders' | 'unbalancedSplitGroups' | 'unsentBrandPOs' | 'unsentDistributorPOs' | 'unsentPartnerInvoices' | 'unshippedPriorityOrder' | 'whitelistCheckForDistributorBuyerRelationships'>;
export type EncodedWatchdogMonitorName = string;
export const WatchdogMonitorName = CodegenEnumOpen({
  'buyerCoversShippingCheck': null,
  'buyerInMultiplePricingTiersPerSeller': null,
  'highPurchaseTotalPartnerships': null,
  'inconsistentlyDeletedVariants': null,
  'missingNACSCategoryInPartnerAPL': null,
  'noNewOrders': null,
  'nonReducingPriceReplacements': null,
  'oldAuthorizedPayments': null,
  'oldPausedFulfillmentRoutingOrders': null,
  'ordersWithShippingIssues': null,
  'orphanedProductVariantIdentifiers': null,
  'partnerAccountConfiguration': null,
  'partnerAPLIncludesBuyerAPLVariants': null,
  'partneredSellersMissingPartneredProducts': null,
  'personalizedPricingCheck': null,
  'sellerMissingNavigableCategory': null,
  'stuckEdiFileUploads': null,
  'unbalancedCompleteOrders': null,
  'unbalancedSplitGroups': null,
  'unsentBrandPOs': null,
  'unsentDistributorPOs': null,
  'unsentPartnerInvoices': null,
  'unshippedPriorityOrder': null,
  'whitelistCheckForDistributorBuyerRelationships': null,
}, 'WatchdogMonitorName');
export const WatchdogMonitorNameValues = ['buyerCoversShippingCheck', 'buyerInMultiplePricingTiersPerSeller', 'highPurchaseTotalPartnerships', 'inconsistentlyDeletedVariants', 'missingNACSCategoryInPartnerAPL', 'noNewOrders', 'nonReducingPriceReplacements', 'oldAuthorizedPayments', 'oldPausedFulfillmentRoutingOrders', 'ordersWithShippingIssues', 'orphanedProductVariantIdentifiers', 'partnerAccountConfiguration', 'partnerAPLIncludesBuyerAPLVariants', 'partneredSellersMissingPartneredProducts', 'personalizedPricingCheck', 'sellerMissingNavigableCategory', 'stuckEdiFileUploads', 'unbalancedCompleteOrders', 'unbalancedSplitGroups', 'unsentBrandPOs', 'unsentDistributorPOs', 'unsentPartnerInvoices', 'unshippedPriorityOrder', 'whitelistCheckForDistributorBuyerRelationships'] as const;

/** The type of the task that needs to be manually reviewed in priority order */
export type ManualTaskType = CodegenEnumOpenValues<'buyerCoversShippingCheck' | 'buyerInMultiplePricingTiersPerSeller' | 'buyerRequestedOrderGuideUpdate' | 'buyerRequestedSample' | 'deletedUser' | 'ediAS2FileTransferFailed' | 'ediCreditRequested' | 'ediFileProcessingError' | 'ediNegativeFunctionalAckReceived' | 'futureLandedCostsUpdated' | 'highPurchaseTotalPartnerships' | 'inconsistentlyDeletedVariants' | 'missingFreeShippingMin' | 'missingNACSCategoryInPartnerAPL' | 'noExternalLocationInformationForPurchaseOrder' | 'noNewOrders' | 'nonReducingPriceReplacements' | 'oldAuthorizedPayments' | 'oldPausedFulfillmentRoutingOrders' | 'ordersWithShippingIssues' | 'oneWorldSyncProblem' | 'orphanedProductVariantIdentifiers' | 'parsingErrorForX12FunctionalAck' | 'partnerAccountConfiguration' | 'partnerAPLIncludesBuyerAPLVariants' | 'partneredSellersMissingPartneredProducts' | 'partnerPriceReview' | 'partnerPricingMismatch' | 'personalizedPricingCheck' | 'processExternalLocationInformationForPurchaseOrder' | 'productAuthorizationRequestFailure' | 'retailUnitNeedsUsFoodsImageClassification' | 'sellerMissingNavigableCategory' | 'sellerPartnerProgramApplicationNeedsReview' | 'sellerShippingCharges' | 'stuckEdiFileUploads' | 'transactionAdjustmentRequest' | 'unbalancedCompleteOrders' | 'unbalancedSplitGroups' | 'undeliverableEmail' | 'unsentBrandPOs' | 'unsentDistributorPOs' | 'unsentPartnerInvoices' | 'unshippedPriorityOrder' | 'whitelistCheckForDistributorBuyerRelationships'>;
export type EncodedManualTaskType = string;
export const ManualTaskType = CodegenEnumOpen({
  'buyerCoversShippingCheck': null,
  'buyerInMultiplePricingTiersPerSeller': null,
  'buyerRequestedOrderGuideUpdate': null,
  'buyerRequestedSample': null,
  'deletedUser': null,
  'ediAS2FileTransferFailed': null,
  'ediCreditRequested': null,
  'ediFileProcessingError': null,
  'ediNegativeFunctionalAckReceived': null,
  'futureLandedCostsUpdated': null,
  'highPurchaseTotalPartnerships': null,
  'inconsistentlyDeletedVariants': null,
  'missingFreeShippingMin': null,
  'missingNACSCategoryInPartnerAPL': null,
  'noExternalLocationInformationForPurchaseOrder': null,
  'noNewOrders': null,
  'nonReducingPriceReplacements': null,
  'oldAuthorizedPayments': null,
  'oldPausedFulfillmentRoutingOrders': null,
  'ordersWithShippingIssues': null,
  'oneWorldSyncProblem': null,
  'orphanedProductVariantIdentifiers': null,
  'parsingErrorForX12FunctionalAck': null,
  'partnerAccountConfiguration': null,
  'partnerAPLIncludesBuyerAPLVariants': null,
  'partneredSellersMissingPartneredProducts': null,
  'partnerPriceReview': null,
  'partnerPricingMismatch': null,
  'personalizedPricingCheck': null,
  'processExternalLocationInformationForPurchaseOrder': null,
  'productAuthorizationRequestFailure': null,
  'retailUnitNeedsUsFoodsImageClassification': null,
  'sellerMissingNavigableCategory': null,
  'sellerPartnerProgramApplicationNeedsReview': null,
  'sellerShippingCharges': null,
  'stuckEdiFileUploads': null,
  'transactionAdjustmentRequest': null,
  'unbalancedCompleteOrders': null,
  'unbalancedSplitGroups': null,
  'undeliverableEmail': null,
  'unsentBrandPOs': null,
  'unsentDistributorPOs': null,
  'unsentPartnerInvoices': null,
  'unshippedPriorityOrder': null,
  'whitelistCheckForDistributorBuyerRelationships': null,
}, 'ManualTaskType');
export const ManualTaskTypeValues = ['buyerCoversShippingCheck', 'buyerInMultiplePricingTiersPerSeller', 'buyerRequestedOrderGuideUpdate', 'buyerRequestedSample', 'deletedUser', 'ediAS2FileTransferFailed', 'ediCreditRequested', 'ediFileProcessingError', 'ediNegativeFunctionalAckReceived', 'futureLandedCostsUpdated', 'highPurchaseTotalPartnerships', 'inconsistentlyDeletedVariants', 'missingFreeShippingMin', 'missingNACSCategoryInPartnerAPL', 'noExternalLocationInformationForPurchaseOrder', 'noNewOrders', 'nonReducingPriceReplacements', 'oldAuthorizedPayments', 'oldPausedFulfillmentRoutingOrders', 'ordersWithShippingIssues', 'oneWorldSyncProblem', 'orphanedProductVariantIdentifiers', 'parsingErrorForX12FunctionalAck', 'partnerAccountConfiguration', 'partnerAPLIncludesBuyerAPLVariants', 'partneredSellersMissingPartneredProducts', 'partnerPriceReview', 'partnerPricingMismatch', 'personalizedPricingCheck', 'processExternalLocationInformationForPurchaseOrder', 'productAuthorizationRequestFailure', 'retailUnitNeedsUsFoodsImageClassification', 'sellerMissingNavigableCategory', 'sellerPartnerProgramApplicationNeedsReview', 'sellerShippingCharges', 'stuckEdiFileUploads', 'transactionAdjustmentRequest', 'unbalancedCompleteOrders', 'unbalancedSplitGroups', 'undeliverableEmail', 'unsentBrandPOs', 'unsentDistributorPOs', 'unsentPartnerInvoices', 'unshippedPriorityOrder', 'whitelistCheckForDistributorBuyerRelationships'] as const;

/** The status of the task */
export type ManualTaskStatus = CodegenEnumOpenValues<'new' | 'inProgress' | 'completed'>;
export type EncodedManualTaskStatus = string;
export const ManualTaskStatus = CodegenEnumOpen({
  'new': null,
  'inProgress': null,
  'completed': null,
}, 'ManualTaskStatus');
export const ManualTaskStatusValues = ['new', 'inProgress', 'completed'] as const;

/** A task that needs manual action to be taken by a Mable employee. */
export type ManualTaskDisplayInfo = {
  'id'?: string,
  'type': ManualTaskType,
  /** One line summary of task */
  'title': string,
  /** Longer explanation of task, to be rendered as markdown. */
  'description': string,
  'status': ManualTaskStatus,
  'createdAt': Date,
  'updatedAt': Date,
  'inProgressAt'?: Date,
  'completedAt'?: Date,
  'extraData'?: Record<string, unknown>,
  /** An object with info specific to the task, with a strict structure determined by the task type. */
  'data'?: Record<string, unknown>,
};
export type EncodedManualTaskDisplayInfo = {
  'id'?: string,
  'type': EncodedManualTaskType,
  'title': string,
  'description': string,
  'status': EncodedManualTaskStatus,
  'createdAt': string,
  'updatedAt': string,
  'inProgressAt'?: string,
  'completedAt'?: string,
  'extraData'?: Record<string, unknown>,
  'data'?: Record<string, unknown>,
};
export const ManualTaskDisplayInfo: t.Type<ManualTaskDisplayInfo, EncodedManualTaskDisplayInfo> = t.recursion('ManualTaskDisplayInfo', () => (
  t.exact(t.intersection([t.type({
    'type': ManualTaskType,
    'title': t.string,
    'description': t.string,
    'status': ManualTaskStatus,
    'createdAt': DateFromISOString,
    'updatedAt': DateFromISOString,
  }), t.partial({
    'id': t.string,
    'inProgressAt': DateFromISOString,
    'completedAt': DateFromISOString,
    'extraData': t.record(t.string, t.unknown),
    'data': t.record(t.string, t.unknown),
  })]), 'ManualTaskDisplayInfo')
));


/** Object used to update a manual task. */
export type ManualTaskPatch = {
  'status'?: ManualTaskStatus,
};
export type EncodedManualTaskPatch = {
  'status'?: EncodedManualTaskStatus,
};
export const ManualTaskPatch: t.Type<ManualTaskPatch, EncodedManualTaskPatch> = t.recursion('ManualTaskPatch', () => (
  t.exact(t.intersection([t.type({
    
  }), t.partial({
    'status': ManualTaskStatus,
  })]), 'ManualTaskPatch')
));


export type Auth0User = {
  'email': string,
  'name'?: string,
  'nickname'?: string,
  'picture'?: string,
  'updated_at'?: string,
  'email_verified'?: boolean,
  'sub'?: string,
  'aud'?: string,
  'iss'?: string,
  'nonce'?: string,
  'iat'?: number,
  'exp'?: number,
};
export type EncodedAuth0User = {
  'email': string,
  'name'?: string,
  'nickname'?: string,
  'picture'?: string,
  'updated_at'?: string,
  'email_verified'?: boolean,
  'sub'?: string,
  'aud'?: string,
  'iss'?: string,
  'nonce'?: string,
  'iat'?: number,
  'exp'?: number,
};
export const Auth0User: t.Type<Auth0User, EncodedAuth0User> = t.recursion('Auth0User', () => (
  t.exact(t.intersection([t.type({
    'email': t.string,
  }), t.partial({
    'name': t.string,
    'nickname': t.string,
    'picture': t.string,
    'updated_at': t.string,
    'email_verified': t.boolean,
    'sub': t.string,
    'aud': t.string,
    'iss': t.string,
    'nonce': t.string,
    'iat': t.number,
    'exp': t.number,
  })]), 'Auth0User')
));


/** Public attributes shared among all seller types viewable to any user */
export type _PublicSellerProps = {
  'name': string,
  'slug': Slug,
  /** The referral code used for tracking referrals between buyers and sellers */
  'referralCode': string,
  'status'?: SellerStatus,
  'description'?: string,
  'shortDescription'?: string,
  /** Main image representing this seller. Typically a logo. */
  'profileImage'?: Image,
  /** Banner image at the top of a seller's detail page. */
  'headerImage'?: Image,
  /** Lifestyle type image, used when featuring the seller in certain contexts. */
  'featuredImage'?: Image,
  'fallbackProductImage'?: Image,
  /** The full URL of the seller's official website.
  e.g. "https://drinklococoffee.com/" */
  'websiteUrl'?: string,
  'city'?: string,
  'state'?: string,
  /** Location for the business. Display purposes only.
  e.g. "Montpelier, VT" */
  'displayLocation'?: string,
  /** String Facebook ID, used to construct a Facebook URL.
  e.g. For https://www.facebook.com/DrinkLoco/ this should be "DrinkLoco" */
  'facebookId'?: string,
  /** Twitter username/handle. No @ symbol.
  e.g. For https://twitter.com/DrinkLocoCoffee this should be "DrinkLocoCoffee" */
  'twitterId'?: string,
  /** Instagram username/handle. No @ symbol.
  e.g. For https://www.instagram.com/drinkloco/ this should be "drinkloco" */
  'instagramId'?: string,
  /** TikTok username/handle. No @ symbol.
  e.g. For https://www.tiktok.com/@drinkloco this should be "drinkloco" */
  'tiktokId'?: string,
  /** The full URL of the seller's official YouTube channel.
  e.g. "https://www.youtube.com/channel/UC_nz59ZPH14cmGtIoIwRJ-Q" */
  'youTubeUrl'?: string,
  /** Is this seller featured in the marketplace? */
  'featured'?: boolean,
  /** Is this seller in the marketplace navigation? */
  'navigable'?: boolean,
  /** Render this seller's profile w/ alternative layout */
  'useAltLayout'?: boolean,
  /** If present, this is the minimum order total necessary to purchase from this seller. */
  'orderMinimum'?: BigNumber,
  /** If present, this is the minimum order total necessary to offer free shipping. */
  'freeShippingMinimum'?: BigNumber,
  /** The minimum number of days it takes to get an order ready to ship */
  'shipLeadMin'?: BigNumber,
  /** The maximum number of days it takes to get an order ready to ship */
  'shipLeadMax'?: BigNumber,
  /** If the seller's products are affected by heat, especially in warm weather. e.g. Cheese or chocolate that may melt during shipping if it takes too long. */
  'productsAffectedByHeat'?: boolean,
  /** If true, clients should include the SKU in the product list view. */
  'displaySkuOnList'?: boolean,
  /** If true, this seller has no formal agreement with Mable, and was onboarded without any interaction between them and Mable. i.e. A "cold start" vendor. */
  'cold'?: boolean,
  /** If this seller is a distributor. */
  'distributor'?: boolean,
  /** If true, the products of this seller are unable to be purchased. */
  'comingSoon'?: boolean,
  /** If true, this seller will not appear in the marketplace for every user. */
  'unlisted'?: boolean,
  /** If the messaging feature is enabled for this seller */
  'messagingEnabled'?: boolean,
  /** The maximum percentage of an order total that buyer of this brand will pay for shipping */
  'shippingCapMultiplier'?: BigNumber,
  /** If true, this seller's sales reps can order on behalf of their customers */
  'salesRepOrdering'?: boolean,
};
export type Encoded_PublicSellerProps = {
  'name': string,
  'slug': string,
  'referralCode': string,
  'status'?: EncodedSellerStatus,
  'description'?: string,
  'shortDescription'?: string,
  'profileImage'?: EncodedImage,
  'headerImage'?: EncodedImage,
  'featuredImage'?: EncodedImage,
  'fallbackProductImage'?: EncodedImage,
  'websiteUrl'?: string,
  'city'?: string,
  'state'?: string,
  'displayLocation'?: string,
  'facebookId'?: string,
  'twitterId'?: string,
  'instagramId'?: string,
  'tiktokId'?: string,
  'youTubeUrl'?: string,
  'featured'?: boolean,
  'navigable'?: boolean,
  'useAltLayout'?: boolean,
  'orderMinimum'?: string,
  'freeShippingMinimum'?: string,
  'shipLeadMin'?: string,
  'shipLeadMax'?: string,
  'productsAffectedByHeat'?: boolean,
  'displaySkuOnList'?: boolean,
  'cold'?: boolean,
  'distributor'?: boolean,
  'comingSoon'?: boolean,
  'unlisted'?: boolean,
  'messagingEnabled'?: boolean,
  'shippingCapMultiplier'?: string,
  'salesRepOrdering'?: boolean,
};
export const _PublicSellerProps: t.Type<_PublicSellerProps, Encoded_PublicSellerProps> = t.recursion('_PublicSellerProps', () => (
  t.exact(t.intersection([t.type({
    'name': t.string,
    'slug': Slug,
    'referralCode': t.string,
  }), t.partial({
    'status': SellerStatus,
    'description': t.string,
    'shortDescription': t.string,
    'profileImage': Image,
    'headerImage': Image,
    'featuredImage': Image,
    'fallbackProductImage': Image,
    'websiteUrl': t.string,
    'city': t.string,
    'state': t.string,
    'displayLocation': t.string,
    'facebookId': t.string,
    'twitterId': t.string,
    'instagramId': t.string,
    'tiktokId': t.string,
    'youTubeUrl': t.string,
    'featured': t.boolean,
    'navigable': t.boolean,
    'useAltLayout': t.boolean,
    'orderMinimum': NumberFromString,
    'freeShippingMinimum': NumberFromString,
    'shipLeadMin': NumberFromString,
    'shipLeadMax': NumberFromString,
    'productsAffectedByHeat': t.boolean,
    'displaySkuOnList': t.boolean,
    'cold': t.boolean,
    'distributor': t.boolean,
    'comingSoon': t.boolean,
    'unlisted': t.boolean,
    'messagingEnabled': t.boolean,
    'shippingCapMultiplier': NumberFromString,
    'salesRepOrdering': t.boolean,
  })]), '_PublicSellerProps')
));


/** Seller properties viewable only to the seller themselves and admins */
export type _PersonalSellerProps = {
  /** Only present on cold start sellers */
  'billingContact'?: Contact,
  'primaryContact'?: Contact,
  /** List of contacts that should receive PO emails */
  'purchaseOrderContacts'?: Array<Contact>,
  /** List of contacts that should be contacted by the Mable support team */
  'mableSupportContacts'?: Array<Contact>,
  /** A list of the partner programs this seller is a member of. */
  'partnerProgramMemberships'?: Array<PartnerProgramMembership>,
  /** If this seller allows sample requests on the typical, non-whitelabeled Mable marketplace */
  'samplesOnMarketplaceEnabled'?: boolean,
  /** Name of the individual or company name on the bank account that is receiving payments. */
  'payToName'?: string,
  /** DBA / doing business as name for this seller. */
  'doingBusinessAsName'?: string,
  /** The number of days of the terms the seller wants to be paid on. */
  'preferredPaymentTermsNetDays'?: number,
  'productSubmissions'?: Array<SellerProductSubmissionFileUpload>,
  'certifications'?: Array<SellerCertificationFileUpload>,
  /** Full detail of media */
  'media'?: Array<Media>,
  /** The range that the seller's annual gross revenue is in */
  'annualGrossRevenue'?: SellerAnnualGrossRevenueRange,
  /** The range that the sellers's annual marketing budget is in */
  'annualMarketingBudget'?: SellerAnnualMarketingBudgetRange,
  /** The range that the sellers amount of retail locations is in */
  'retailLocationCountRange'?: SellerRetailLocationCountRange,
  /** The year the seller's company was founded */
  'yearFounded'?: number,
};
export type Encoded_PersonalSellerProps = {
  'billingContact'?: EncodedContact,
  'primaryContact'?: EncodedContact,
  'purchaseOrderContacts'?: Array<EncodedContact>,
  'mableSupportContacts'?: Array<EncodedContact>,
  'partnerProgramMemberships'?: Array<EncodedPartnerProgramMembership>,
  'samplesOnMarketplaceEnabled'?: boolean,
  'payToName'?: string,
  'doingBusinessAsName'?: string,
  'preferredPaymentTermsNetDays'?: number,
  'productSubmissions'?: Array<EncodedSellerProductSubmissionFileUpload>,
  'certifications'?: Array<EncodedSellerCertificationFileUpload>,
  'media'?: Array<EncodedMedia>,
  'annualGrossRevenue'?: EncodedSellerAnnualGrossRevenueRange,
  'annualMarketingBudget'?: EncodedSellerAnnualMarketingBudgetRange,
  'retailLocationCountRange'?: EncodedSellerRetailLocationCountRange,
  'yearFounded'?: number,
};
export const _PersonalSellerProps: t.Type<_PersonalSellerProps, Encoded_PersonalSellerProps> = t.recursion('_PersonalSellerProps', () => (
  t.exact(t.intersection([t.type({
    
  }), t.partial({
    'billingContact': Contact,
    'primaryContact': Contact,
    'purchaseOrderContacts': t.array(Contact),
    'mableSupportContacts': t.array(Contact),
    'partnerProgramMemberships': t.array(PartnerProgramMembership),
    'samplesOnMarketplaceEnabled': t.boolean,
    'payToName': t.string,
    'doingBusinessAsName': t.string,
    'preferredPaymentTermsNetDays': t.number,
    'productSubmissions': t.array(SellerProductSubmissionFileUpload),
    'certifications': t.array(SellerCertificationFileUpload),
    'media': t.array(Media),
    'annualGrossRevenue': SellerAnnualGrossRevenueRange,
    'annualMarketingBudget': SellerAnnualMarketingBudgetRange,
    'retailLocationCountRange': SellerRetailLocationCountRange,
    'yearFounded': t.number,
  })]), '_PersonalSellerProps')
));


export type SellerProductSubmissionFileUpload = FileUploadDisplay & {
  'id': number,
  'resolvedAt'?: Date,
};
export type EncodedSellerProductSubmissionFileUpload = EncodedFileUploadDisplay & {
  'id': number,
  'resolvedAt'?: string,
};
export const SellerProductSubmissionFileUpload: t.Type<SellerProductSubmissionFileUpload, EncodedSellerProductSubmissionFileUpload> = t.recursion('SellerProductSubmissionFileUpload', () => (
  t.intersection([
    FileUploadDisplay,
    t.exact(t.intersection([t.type({
      'id': t.number,
    }), t.partial({
      'resolvedAt': DateFromISOString,
    })]), 'SellerProductSubmissionFileUpload')
  ], 'SellerProductSubmissionFileUpload')
));


export type SellerCertificationFileUpload = FileUploadDisplay & {
  'id': number,
  'type': SellerCertificationType,
  'sellerId': number,
};
export type EncodedSellerCertificationFileUpload = EncodedFileUploadDisplay & {
  'id': number,
  'type': EncodedSellerCertificationType,
  'sellerId': number,
};
export const SellerCertificationFileUpload: t.Type<SellerCertificationFileUpload, EncodedSellerCertificationFileUpload> = t.recursion('SellerCertificationFileUpload', () => (
  t.intersection([
    FileUploadDisplay,
    t.exact(t.intersection([t.type({
      'id': t.number,
      'type': SellerCertificationType,
      'sellerId': t.number,
    }), t.partial({
      
    })]), 'SellerCertificationFileUpload')
  ], 'SellerCertificationFileUpload')
));


export type SellerProductSubmissionFileUploadPatch = {
  /** If this submission has been resolved. */
  'resolved'?: boolean,
};
export type EncodedSellerProductSubmissionFileUploadPatch = {
  'resolved'?: boolean,
};
export const SellerProductSubmissionFileUploadPatch: t.Type<SellerProductSubmissionFileUploadPatch, EncodedSellerProductSubmissionFileUploadPatch> = t.recursion('SellerProductSubmissionFileUploadPatch', () => (
  t.exact(t.intersection([t.type({
    
  }), t.partial({
    'resolved': t.boolean,
  })]), 'SellerProductSubmissionFileUploadPatch')
));


/** Seller properties viewable to admins only */
export type _AdminSellerProps = {
  /** The HubSpot ID of the seller's company record in our HubSpot account. This is the last numeric component of the HubSpot URL in your browser.
  e.g. For https://app.hubspot.com/contacts/5411323/company/2209568622/ this should be "2209568622" */
  'hubSpotCompanyId'?: string,
  /** Admin-only notes + memos, possibly also used for storing catch-all properties */
  'memo'?: string,
  'shipWithMableEnabled'?: boolean,
  'manualReputationScore'?: number,
  /** The code the seller used when filling out the signup form. */
  'referredBy'?: string,
  /** In Shipping Program only, the percentage of shipping costs that a seller will pay for on a order (the rest being paid by Mable) */
  'shippingPaymentMultiplier'?: BigNumber,
  /** In Buyer Shipping Max Guarantee only, the minimum percentage of the remaining shipping costs the seller will pay once the buyer has paid. They could pay more if we hit the mableShippingMaxPaymentMultiplier amount. */
  'shippingCapPaymentMultiplier'?: BigNumber,
  /** The percentage of purcahse total that is the maximum amount of money Mable will pay for shipping on an order */
  'mableShippingMaxPaymentMultiplier'?: BigNumber,
  'previewKey'?: string,
  'manualAlert'?: string,
  'faireId'?: string,
  'rangeMeId'?: string,
  'dataQualityImportance'?: SellerDataQualityImportance,
  'extraData'?: Record<string, unknown>,
  'remittanceAccountId'?: number,
  /** If this brand is available on the public, non-partnered Mable marketplace at meetmable.com. */
  'mableMarketplaceEnabled'?: boolean,
};
export type Encoded_AdminSellerProps = {
  'hubSpotCompanyId'?: string,
  'memo'?: string,
  'shipWithMableEnabled'?: boolean,
  'manualReputationScore'?: number,
  'referredBy'?: string,
  'shippingPaymentMultiplier'?: string,
  'shippingCapPaymentMultiplier'?: string,
  'mableShippingMaxPaymentMultiplier'?: string,
  'previewKey'?: string,
  'manualAlert'?: string,
  'faireId'?: string,
  'rangeMeId'?: string,
  'dataQualityImportance'?: EncodedSellerDataQualityImportance,
  'extraData'?: Record<string, unknown>,
  'remittanceAccountId'?: number,
  'mableMarketplaceEnabled'?: boolean,
};
export const _AdminSellerProps: t.Type<_AdminSellerProps, Encoded_AdminSellerProps> = t.recursion('_AdminSellerProps', () => (
  t.exact(t.intersection([t.type({
    
  }), t.partial({
    'hubSpotCompanyId': t.string,
    'memo': t.string,
    'shipWithMableEnabled': t.boolean,
    'manualReputationScore': t.number,
    'referredBy': t.string,
    'shippingPaymentMultiplier': NumberFromString,
    'shippingCapPaymentMultiplier': NumberFromString,
    'mableShippingMaxPaymentMultiplier': NumberFromString,
    'previewKey': t.string,
    'manualAlert': t.string,
    'faireId': t.string,
    'rangeMeId': t.string,
    'dataQualityImportance': SellerDataQualityImportance,
    'extraData': t.record(t.string, t.unknown),
    'remittanceAccountId': t.number,
    'mableMarketplaceEnabled': t.boolean,
  })]), '_AdminSellerProps')
));


/** Used in creation of a Seller object. */
export type SellerInput = _PublicSellerProps & _PersonalSellerProps & _AdminSellerProps & {
  'categoryMemberships'?: Array<CategoryMembershipInput>,
  'shipFromAddressId'?: number,
  'billingAddressId'?: number,
};
export type EncodedSellerInput = Encoded_PublicSellerProps & Encoded_PersonalSellerProps & Encoded_AdminSellerProps & {
  'categoryMemberships'?: Array<EncodedCategoryMembershipInput>,
  'shipFromAddressId'?: number,
  'billingAddressId'?: number,
};
export const SellerInput: t.Type<SellerInput, EncodedSellerInput> = t.recursion('SellerInput', () => (
  t.intersection([
    _PublicSellerProps,
    _PersonalSellerProps,
    _AdminSellerProps,
    t.exact(t.intersection([t.type({
      
    }), t.partial({
      'categoryMemberships': t.array(CategoryMembershipInput),
      'shipFromAddressId': t.number,
      'billingAddressId': t.number,
    })]), 'SellerInput')
  ], 'SellerInput')
));


/** Main seller type for public visibility */
export type SellerDisplayInfo = _PublicSellerProps & {
  'id': number,
  /** A list of displayable variants. Not guaranteed to be an exhaustive list. */
  'variants'?: Array<ProductVariantListDisplayInfo>,
  /** A count of the total number of variants for this seller. */
  'variantCount'?: number,
  /** This is a list of tags that might be applied to a particular sellers's display in certain contexts. The array is in priority order, and the clients should pick the first tag they understand in the list to display */
  'tags'?: Array<DisplaySellerTag>,
  'shippingLeadTime'?: string,
  /** Denotes whether the seller has opted in to the order guide feature and should show it. */
  'usingOrderGuide'?: boolean,
  /** The websiteUrl as it was entered by the user and how it is stored in the db. You might need this if websiteUrl is formatted in way that is inconvenient. */
  'websiteUrlRaw'?: string,
  /** The youTubeUrl as it was entered by the user and how it is stored in the db. You might need this if youTubeUrl is formatted in way that is inconvenient. */
  'youTubeUrlRaw'?: string,
  'shippingPolicy': ShippingPolicy,
  'categories'?: Array<CategoryMembership>,
  'alert'?: string,
  /** The range that the seller's annual gross revenue is in */
  'annualGrossRevenueDisplay'?: string,
  /** The range that the sellers's annual marketing budget is in */
  'annualMarketingBudgetDisplay'?: string,
  /** The range that the seller's amount of retail locations is in */
  'retailLocationCountRangeDisplay'?: string,
  /** The year the seller's company was founded */
  'yearFounded'?: number,
  /** The billing contact if this is a cold start seller */
  'coldContact'?: Contact,
  'buyerSendbirdChannel'?: SellerToBuyerChannelStatus,
  /** If samples are allowed for this seller, accounting for the seller's configuration or the partner program requirements. */
  'allowsSamples': boolean,
  /** If this brand is available on the public, non-partnered Mable marketplace at meetmable.com. */
  'mableMarketplaceEnabled'?: boolean,
  /** Details of media, optimized for display */
  'mediaDisplayInfo'?: Array<MediaDisplayInfo>,
};
export type EncodedSellerDisplayInfo = Encoded_PublicSellerProps & {
  'id': number,
  'variants'?: Array<EncodedProductVariantListDisplayInfo>,
  'variantCount'?: number,
  'tags'?: Array<EncodedDisplaySellerTag>,
  'shippingLeadTime'?: string,
  'usingOrderGuide'?: boolean,
  'websiteUrlRaw'?: string,
  'youTubeUrlRaw'?: string,
  'shippingPolicy': EncodedShippingPolicy,
  'categories'?: Array<EncodedCategoryMembership>,
  'alert'?: string,
  'annualGrossRevenueDisplay'?: string,
  'annualMarketingBudgetDisplay'?: string,
  'retailLocationCountRangeDisplay'?: string,
  'yearFounded'?: number,
  'coldContact'?: EncodedContact,
  'buyerSendbirdChannel'?: EncodedSellerToBuyerChannelStatus,
  'allowsSamples': boolean,
  'mableMarketplaceEnabled'?: boolean,
  'mediaDisplayInfo'?: Array<EncodedMediaDisplayInfo>,
};
export const SellerDisplayInfo: t.Type<SellerDisplayInfo, EncodedSellerDisplayInfo> = t.recursion('SellerDisplayInfo', () => (
  t.intersection([
    _PublicSellerProps,
    t.exact(t.intersection([t.type({
      'id': t.number,
      'shippingPolicy': ShippingPolicy,
      'allowsSamples': t.boolean,
    }), t.partial({
      'variants': t.array(ProductVariantListDisplayInfo),
      'variantCount': t.number,
      'tags': t.array(DisplaySellerTag),
      'shippingLeadTime': t.string,
      'usingOrderGuide': t.boolean,
      'websiteUrlRaw': t.string,
      'youTubeUrlRaw': t.string,
      'categories': t.array(CategoryMembership),
      'alert': t.string,
      'annualGrossRevenueDisplay': t.string,
      'annualMarketingBudgetDisplay': t.string,
      'retailLocationCountRangeDisplay': t.string,
      'yearFounded': t.number,
      'coldContact': Contact,
      'buyerSendbirdChannel': SellerToBuyerChannelStatus,
      'mableMarketplaceEnabled': t.boolean,
      'mediaDisplayInfo': t.array(MediaDisplayInfo),
    })]), 'SellerDisplayInfo')
  ], 'SellerDisplayInfo')
));


/** SellerDisplayInfo plus sensitive data viewable to only that seller */
export type SellerPersonalDisplayInfo = SellerDisplayInfo & _PersonalSellerProps & {
  'shipFromAddress'?: Address,
  'billingAddress'?: Address,
};
export type EncodedSellerPersonalDisplayInfo = EncodedSellerDisplayInfo & Encoded_PersonalSellerProps & {
  'shipFromAddress'?: EncodedAddress,
  'billingAddress'?: EncodedAddress,
};
export const SellerPersonalDisplayInfo: t.Type<SellerPersonalDisplayInfo, EncodedSellerPersonalDisplayInfo> = t.recursion('SellerPersonalDisplayInfo', () => (
  t.intersection([
    SellerDisplayInfo,
    _PersonalSellerProps,
    t.exact(t.intersection([t.type({
      
    }), t.partial({
      'shipFromAddress': Address,
      'billingAddress': Address,
    })]), 'SellerPersonalDisplayInfo')
  ], 'SellerPersonalDisplayInfo')
));


/** SellerDisplayInfo plus sensitive data viewable to admins only */
export type SellerAdminDisplayInfo = SellerPersonalDisplayInfo & _AdminSellerProps & {
  'accountId': number,
};
export type EncodedSellerAdminDisplayInfo = EncodedSellerPersonalDisplayInfo & Encoded_AdminSellerProps & {
  'accountId': number,
};
export const SellerAdminDisplayInfo: t.Type<SellerAdminDisplayInfo, EncodedSellerAdminDisplayInfo> = t.recursion('SellerAdminDisplayInfo', () => (
  t.intersection([
    SellerPersonalDisplayInfo,
    _AdminSellerProps,
    t.exact(t.intersection([t.type({
      'accountId': t.number,
    }), t.partial({
      
    })]), 'SellerAdminDisplayInfo')
  ], 'SellerAdminDisplayInfo')
));


/** SellerDisplayInfo plus sensitive data only to be exposed to customers who have ordered from the seller */
export type SellerOrderDisplayInfo = SellerDisplayInfo & {
  'primaryContact'?: Contact,
  'billingContact'?: Contact,
  'shipFromAddress'?: Address,
};
export type EncodedSellerOrderDisplayInfo = EncodedSellerDisplayInfo & {
  'primaryContact'?: EncodedContact,
  'billingContact'?: EncodedContact,
  'shipFromAddress'?: EncodedAddress,
};
export const SellerOrderDisplayInfo: t.Type<SellerOrderDisplayInfo, EncodedSellerOrderDisplayInfo> = t.recursion('SellerOrderDisplayInfo', () => (
  t.intersection([
    SellerDisplayInfo,
    t.exact(t.intersection([t.type({
      
    }), t.partial({
      'primaryContact': Contact,
      'billingContact': Contact,
      'shipFromAddress': Address,
    })]), 'SellerOrderDisplayInfo')
  ], 'SellerOrderDisplayInfo')
));


/** Buyer may create a channel with the seller */
export type SellerToBuyerChannelCreatable = {
  'type': 'creatable',
};
export type EncodedSellerToBuyerChannelCreatable = {
  'type': string,
};
export const SellerToBuyerChannelCreatable: t.Type<SellerToBuyerChannelCreatable, EncodedSellerToBuyerChannelCreatable> = t.recursion('SellerToBuyerChannelCreatable', () => (
  t.exact(t.intersection([t.type({
    'type': CodegenEnumClosed({
      'creatable': null,
    }, 'SellerToBuyerChannelCreatable'),
  }), t.partial({
    
  })]), 'SellerToBuyerChannelCreatable')
));


/** Buyer may not create a channel with the seller */
export type SellerToBuyerChannelDisabled = {
  'type': 'disabled',
  'reason'?: string,
};
export type EncodedSellerToBuyerChannelDisabled = {
  'type': string,
  'reason'?: string,
};
export const SellerToBuyerChannelDisabled: t.Type<SellerToBuyerChannelDisabled, EncodedSellerToBuyerChannelDisabled> = t.recursion('SellerToBuyerChannelDisabled', () => (
  t.exact(t.intersection([t.type({
    'type': CodegenEnumClosed({
      'disabled': null,
    }, 'SellerToBuyerChannelDisabled'),
  }), t.partial({
    'reason': t.string,
  })]), 'SellerToBuyerChannelDisabled')
));


/** Messaging channel already exists between buyer and seller */
export type SellerToBuyerChannelExists = {
  'type': 'exists',
  'messageChannelUrl': string,
};
export type EncodedSellerToBuyerChannelExists = {
  'type': string,
  'messageChannelUrl': string,
};
export const SellerToBuyerChannelExists: t.Type<SellerToBuyerChannelExists, EncodedSellerToBuyerChannelExists> = t.recursion('SellerToBuyerChannelExists', () => (
  t.exact(t.intersection([t.type({
    'type': CodegenEnumClosed({
      'exists': null,
    }, 'SellerToBuyerChannelExists'),
    'messageChannelUrl': t.string,
  }), t.partial({
    
  })]), 'SellerToBuyerChannelExists')
));


export type SellerToBuyerChannelStatus = OneOfOpenValues<SellerToBuyerChannelCreatable | SellerToBuyerChannelDisabled | SellerToBuyerChannelExists>;
export type EncodedSellerToBuyerChannelStatus = OneOfOpenEncodedValues<EncodedSellerToBuyerChannelCreatable | EncodedSellerToBuyerChannelDisabled | EncodedSellerToBuyerChannelExists>;
export const SellerToBuyerChannelStatus: t.Type<SellerToBuyerChannelStatus, EncodedSellerToBuyerChannelStatus> = t.recursion('SellerToBuyerChannelStatus', () => (
  OneOfOpen([SellerToBuyerChannelCreatable, SellerToBuyerChannelDisabled, SellerToBuyerChannelExists])
));


/** How important the quality of the data is for this brand. We might put more importance on one brand over another due to a relationship with a large buyer with strict data requirements. */
export type SellerDataQualityImportance = CodegenEnumOpenValues<'undetermined' | 'none' | 'important' | 'critical'>;
export type EncodedSellerDataQualityImportance = string;
export const SellerDataQualityImportance = CodegenEnumOpen({
  'undetermined': null,
  'none': null,
  'important': null,
  'critical': null,
}, 'SellerDataQualityImportance');
export const SellerDataQualityImportanceValues = ['undetermined', 'none', 'important', 'critical'] as const;

/** The unit used to measure the package's weight */
export type ProductVariantPackagingWeightUnit = CodegenEnumOpenValues<'oz' | 'lb' | 'g' | 'kg'>;
export type EncodedProductVariantPackagingWeightUnit = string;
export const ProductVariantPackagingWeightUnit = CodegenEnumOpen({
  'oz': null,
  'lb': null,
  'g': null,
  'kg': null,
}, 'ProductVariantPackagingWeightUnit');
export const ProductVariantPackagingWeightUnitValues = ['oz', 'lb', 'g', 'kg'] as const;

/** The unit used to measure the package's width, length, and height */
export type ProductVariantPackagingDistanceUnit = CodegenEnumOpenValues<'in' | 'cm'>;
export type EncodedProductVariantPackagingDistanceUnit = string;
export const ProductVariantPackagingDistanceUnit = CodegenEnumOpen({
  'in': null,
  'cm': null,
}, 'ProductVariantPackagingDistanceUnit');
export const ProductVariantPackagingDistanceUnitValues = ['in', 'cm'] as const;

/** The standardized level of packaging we are describing. Inner packs contain units; master cases contain inner packs; pallets contain master cases */
export type ProductVariantPackagingLevel = CodegenEnumOpenValues<'unit' | 'case' | 'master_case' | 'pallet'>;
export type EncodedProductVariantPackagingLevel = string;
export const ProductVariantPackagingLevel = CodegenEnumOpen({
  'unit': null,
  'case': null,
  'master_case': null,
  'pallet': null,
}, 'ProductVariantPackagingLevel');
export const ProductVariantPackagingLevelValues = ['unit', 'case', 'master_case', 'pallet'] as const;

/** Physical descriptions of hierarchical packaging levels for a product variant */
export type ProductVariantPackaging = {
  'level': ProductVariantPackagingLevel,
  'itemCount'?: number,
  /** The packaging level of the items described by itemCount. Must be a lower level than the current packaging. */
  'itemLevel'?: ProductVariantPackagingLevel,
  'weight'?: BigNumber,
  'weightUnit'?: ProductVariantPackagingWeightUnit,
  'width'?: BigNumber,
  'height'?: BigNumber,
  'depth'?: BigNumber,
  'distanceUnit'?: ProductVariantPackagingDistanceUnit,
  'itemsPerLayer'?: number,
  'layerCount'?: number,
  'gtin'?: Gtin,
  'isShippable'?: boolean,
};
export type EncodedProductVariantPackaging = {
  'level': EncodedProductVariantPackagingLevel,
  'itemCount'?: number,
  'itemLevel'?: EncodedProductVariantPackagingLevel,
  'weight'?: string,
  'weightUnit'?: EncodedProductVariantPackagingWeightUnit,
  'width'?: string,
  'height'?: string,
  'depth'?: string,
  'distanceUnit'?: EncodedProductVariantPackagingDistanceUnit,
  'itemsPerLayer'?: number,
  'layerCount'?: number,
  'gtin'?: string,
  'isShippable'?: boolean,
};
export const ProductVariantPackaging: t.Type<ProductVariantPackaging, EncodedProductVariantPackaging> = t.recursion('ProductVariantPackaging', () => (
  t.exact(t.intersection([t.type({
    'level': ProductVariantPackagingLevel,
  }), t.partial({
    'itemCount': t.number,
    'itemLevel': ProductVariantPackagingLevel,
    'weight': NumberFromString,
    'weightUnit': ProductVariantPackagingWeightUnit,
    'width': NumberFromString,
    'height': NumberFromString,
    'depth': NumberFromString,
    'distanceUnit': ProductVariantPackagingDistanceUnit,
    'itemsPerLayer': t.number,
    'layerCount': t.number,
    'gtin': Gtin,
    'isShippable': t.boolean,
  })]), 'ProductVariantPackaging')
));


/** The packaging with it's measurments convereted to standard units */
export type ProductVariantPackagingWithId = ProductVariantPackaging & {
  'id'?: number,
};
export type EncodedProductVariantPackagingWithId = EncodedProductVariantPackaging & {
  'id'?: number,
};
export const ProductVariantPackagingWithId: t.Type<ProductVariantPackagingWithId, EncodedProductVariantPackagingWithId> = t.recursion('ProductVariantPackagingWithId', () => (
  t.intersection([
    ProductVariantPackaging,
    t.exact(t.intersection([t.type({
      
    }), t.partial({
      'id': t.number,
    })]), 'ProductVariantPackagingWithId')
  ], 'ProductVariantPackagingWithId')
));


/** The packaging with it's measurments convereted to standard units */
export type ProductVariantPackagingConverted = ProductVariantPackaging & {
  'weightConverted'?: BigNumber,
  'weightUnitConverted'?: ProductVariantPackagingWeightUnit,
  'widthConverted'?: BigNumber,
  'heightConverted'?: BigNumber,
  'depthConverted'?: BigNumber,
  'distanceUnitConverted'?: ProductVariantPackagingDistanceUnit,
};
export type EncodedProductVariantPackagingConverted = EncodedProductVariantPackaging & {
  'weightConverted'?: string,
  'weightUnitConverted'?: EncodedProductVariantPackagingWeightUnit,
  'widthConverted'?: string,
  'heightConverted'?: string,
  'depthConverted'?: string,
  'distanceUnitConverted'?: EncodedProductVariantPackagingDistanceUnit,
};
export const ProductVariantPackagingConverted: t.Type<ProductVariantPackagingConverted, EncodedProductVariantPackagingConverted> = t.recursion('ProductVariantPackagingConverted', () => (
  t.intersection([
    ProductVariantPackaging,
    t.exact(t.intersection([t.type({
      
    }), t.partial({
      'weightConverted': NumberFromString,
      'weightUnitConverted': ProductVariantPackagingWeightUnit,
      'widthConverted': NumberFromString,
      'heightConverted': NumberFromString,
      'depthConverted': NumberFromString,
      'distanceUnitConverted': ProductVariantPackagingDistanceUnit,
    })]), 'ProductVariantPackagingConverted')
  ], 'ProductVariantPackagingConverted')
));


/** Possible levels of interest for brand in category feedback */
export type CategoryFeedbackInterest = CodegenEnumOpenValues<'yes' | 'no' | 'maybe'>;
export type EncodedCategoryFeedbackInterest = string;
export const CategoryFeedbackInterest = CodegenEnumOpen({
  'yes': null,
  'no': null,
  'maybe': null,
}, 'CategoryFeedbackInterest');
export const CategoryFeedbackInterestValues = ['yes', 'no', 'maybe'] as const;

export type CategoryFeedback = {
  'id': number,
  'sellerId': number,
  'categoryId': number,
  'interest'?: CategoryFeedbackInterest,
  'note'?: string,
  'lastUpdatedByUserId': number,
  'lastUpdatedByUserEmail'?: string,
};
export type EncodedCategoryFeedback = {
  'id': number,
  'sellerId': number,
  'categoryId': number,
  'interest'?: EncodedCategoryFeedbackInterest,
  'note'?: string,
  'lastUpdatedByUserId': number,
  'lastUpdatedByUserEmail'?: string,
};
export const CategoryFeedback: t.Type<CategoryFeedback, EncodedCategoryFeedback> = t.recursion('CategoryFeedback', () => (
  t.exact(t.intersection([t.type({
    'id': t.number,
    'sellerId': t.number,
    'categoryId': t.number,
    'lastUpdatedByUserId': t.number,
  }), t.partial({
    'interest': CategoryFeedbackInterest,
    'note': t.string,
    'lastUpdatedByUserEmail': t.string,
  })]), 'CategoryFeedback')
));


export type EdiConfigurationPatch = {
  'name'?: string,
  'connectionType'?: EdiConnectionType,
  'username'?: null | string,
  'server'?: null | string,
  'password'?: null | string,
  'mableSupplierId'?: null | string,
  'receiverQualifier'?: null | string,
  'receiverId'?: null | string,
  'applicationReceiverCode'?: null | string,
  'inputPath'?: null | string,
  'outputPath'?: null | string,
  'invoicePath'?: null | string,
  'pricebookPath'?: null | string,
  'ediPurchaseOrdersEnabled'?: boolean,
  'deliverEdiInvoices'?: boolean,
  /** If this config should send 855 style acknowledgement files with details about the order and products being accepted or rejected. */
  'sendEdiAcknowledgementFiles'?: boolean,
  /** If this config should send 997 style acknowledgement files, which are sent in response to successful receipt and processing of an incoming EDI file. */
  'sendEdiFunctionalAcknowledgementFiles'?: boolean,
  'sendEdiAcknowledgementEmails'?: boolean,
  'sendEdiOnlyForEdiOrders'?: boolean,
  'invoiceFormat'?: null | EdiInvoiceFormat,
  'purchaseOrderFormat'?: null | EdiPurchaseOrderFormat,
  'creditRequestFormat'?: null | EdiCreditRequestFormat,
  'pricebookFormat'?: null | EdiPricebookFormat,
  'allowPartialOrders'?: boolean,
  'shouldHonorPurchaseOrderPrice'?: boolean,
  'sendEdiAcknowledgementOnlyWhenAllSettled'?: boolean,
  'sendEdiInvoiceOnlyWhenAllSettled'?: boolean,
  'connectorId'?: null | string,
  'agreementId'?: null | string,
};
export type EncodedEdiConfigurationPatch = {
  'name'?: string,
  'connectionType'?: EncodedEdiConnectionType,
  'username'?: null | string,
  'server'?: null | string,
  'password'?: null | string,
  'mableSupplierId'?: null | string,
  'receiverQualifier'?: null | string,
  'receiverId'?: null | string,
  'applicationReceiverCode'?: null | string,
  'inputPath'?: null | string,
  'outputPath'?: null | string,
  'invoicePath'?: null | string,
  'pricebookPath'?: null | string,
  'ediPurchaseOrdersEnabled'?: boolean,
  'deliverEdiInvoices'?: boolean,
  'sendEdiAcknowledgementFiles'?: boolean,
  'sendEdiFunctionalAcknowledgementFiles'?: boolean,
  'sendEdiAcknowledgementEmails'?: boolean,
  'sendEdiOnlyForEdiOrders'?: boolean,
  'invoiceFormat'?: null | EncodedEdiInvoiceFormat,
  'purchaseOrderFormat'?: null | EncodedEdiPurchaseOrderFormat,
  'creditRequestFormat'?: null | EncodedEdiCreditRequestFormat,
  'pricebookFormat'?: null | EncodedEdiPricebookFormat,
  'allowPartialOrders'?: boolean,
  'shouldHonorPurchaseOrderPrice'?: boolean,
  'sendEdiAcknowledgementOnlyWhenAllSettled'?: boolean,
  'sendEdiInvoiceOnlyWhenAllSettled'?: boolean,
  'connectorId'?: null | string,
  'agreementId'?: null | string,
};
export const EdiConfigurationPatch: t.Type<EdiConfigurationPatch, EncodedEdiConfigurationPatch> = t.recursion('EdiConfigurationPatch', () => (
  t.exact(t.intersection([t.type({
    
  }), t.partial({
    'name': t.string,
    'connectionType': EdiConnectionType,
    'username': t.union([t.null, t.string]),
    'server': t.union([t.null, t.string]),
    'password': t.union([t.null, t.string]),
    'mableSupplierId': t.union([t.null, t.string]),
    'receiverQualifier': t.union([t.null, t.string]),
    'receiverId': t.union([t.null, t.string]),
    'applicationReceiverCode': t.union([t.null, t.string]),
    'inputPath': t.union([t.null, t.string]),
    'outputPath': t.union([t.null, t.string]),
    'invoicePath': t.union([t.null, t.string]),
    'pricebookPath': t.union([t.null, t.string]),
    'ediPurchaseOrdersEnabled': t.boolean,
    'deliverEdiInvoices': t.boolean,
    'sendEdiAcknowledgementFiles': t.boolean,
    'sendEdiFunctionalAcknowledgementFiles': t.boolean,
    'sendEdiAcknowledgementEmails': t.boolean,
    'sendEdiOnlyForEdiOrders': t.boolean,
    'invoiceFormat': t.union([t.null, EdiInvoiceFormat]),
    'purchaseOrderFormat': t.union([t.null, EdiPurchaseOrderFormat]),
    'creditRequestFormat': t.union([t.null, EdiCreditRequestFormat]),
    'pricebookFormat': t.union([t.null, EdiPricebookFormat]),
    'allowPartialOrders': t.boolean,
    'shouldHonorPurchaseOrderPrice': t.boolean,
    'sendEdiAcknowledgementOnlyWhenAllSettled': t.boolean,
    'sendEdiInvoiceOnlyWhenAllSettled': t.boolean,
    'connectorId': t.union([t.null, t.string]),
    'agreementId': t.union([t.null, t.string]),
  })]), 'EdiConfigurationPatch')
));


export type EdiConfigurationInput = EdiConfigurationPatch & {
  'accountId': number,
  'name': string,
  'connectionType': EdiConnectionType,
};
export type EncodedEdiConfigurationInput = EncodedEdiConfigurationPatch & {
  'accountId': number,
  'name': string,
  'connectionType': EncodedEdiConnectionType,
};
export const EdiConfigurationInput: t.Type<EdiConfigurationInput, EncodedEdiConfigurationInput> = t.recursion('EdiConfigurationInput', () => (
  t.intersection([
    EdiConfigurationPatch,
    t.exact(t.intersection([t.type({
      'accountId': t.number,
      'name': t.string,
      'connectionType': EdiConnectionType,
    }), t.partial({
      
    })]), 'EdiConfigurationInput')
  ], 'EdiConfigurationInput')
));


export type EdiConfiguration = EdiConfigurationPatch & {
  'id': string,
  'connectionType': EdiConnectionType,
  'createdAt'?: Date,
  'updatedAt'?: Date,
};
export type EncodedEdiConfiguration = EncodedEdiConfigurationPatch & {
  'id': string,
  'connectionType': EncodedEdiConnectionType,
  'createdAt'?: string,
  'updatedAt'?: string,
};
export const EdiConfiguration: t.Type<EdiConfiguration, EncodedEdiConfiguration> = t.recursion('EdiConfiguration', () => (
  t.intersection([
    EdiConfigurationPatch,
    t.exact(t.intersection([t.type({
      'id': t.string,
      'connectionType': EdiConnectionType,
    }), t.partial({
      'createdAt': DateFromISOString,
      'updatedAt': DateFromISOString,
    })]), 'EdiConfiguration')
  ], 'EdiConfiguration')
));


export type EdiConfigurationX12 = EdiConfiguration & {
  'mableSupplierId': string,
  'receiverQualifier': string,
  'receiverId': string,
  'applicationReceiverCode': string,
};
export type EncodedEdiConfigurationX12 = EncodedEdiConfiguration & {
  'mableSupplierId': string,
  'receiverQualifier': string,
  'receiverId': string,
  'applicationReceiverCode': string,
};
export const EdiConfigurationX12: t.Type<EdiConfigurationX12, EncodedEdiConfigurationX12> = t.recursion('EdiConfigurationX12', () => (
  t.intersection([
    EdiConfiguration,
    t.exact(t.intersection([t.type({
      'mableSupplierId': t.string,
      'receiverQualifier': t.string,
      'receiverId': t.string,
      'applicationReceiverCode': t.string,
    }), t.partial({
      
    })]), 'EdiConfigurationX12')
  ], 'EdiConfigurationX12')
));


export type EdiConfigurationValidated = EdiConfiguration & {
  'name': string,
  'accountId': number,
  'mableSupplierId': string,
  'outputPath': string,
};
export type EncodedEdiConfigurationValidated = EncodedEdiConfiguration & {
  'name': string,
  'accountId': number,
  'mableSupplierId': string,
  'outputPath': string,
};
export const EdiConfigurationValidated: t.Type<EdiConfigurationValidated, EncodedEdiConfigurationValidated> = t.recursion('EdiConfigurationValidated', () => (
  t.intersection([
    EdiConfiguration,
    t.exact(t.intersection([t.type({
      'name': t.string,
      'accountId': t.number,
      'mableSupplierId': t.string,
      'outputPath': t.string,
    }), t.partial({
      
    })]), 'EdiConfigurationValidated')
  ], 'EdiConfigurationValidated')
));


export type AS2EdiConfiguration = EdiConfigurationValidated & {
  'connectionType': 'as2',
  'agreementId': string,
  'connectorId': string,
};
export type EncodedAS2EdiConfiguration = EncodedEdiConfigurationValidated & {
  'connectionType': string,
  'agreementId': string,
  'connectorId': string,
};
export const AS2EdiConfiguration: t.Type<AS2EdiConfiguration, EncodedAS2EdiConfiguration> = t.recursion('AS2EdiConfiguration', () => (
  t.intersection([
    EdiConfigurationValidated,
    t.exact(t.intersection([t.type({
      'connectionType': CodegenEnumClosed({
        'as2': null,
      }, 'AS2EdiConfiguration'),
      'agreementId': t.string,
      'connectorId': t.string,
    }), t.partial({
      
    })]), 'AS2EdiConfiguration')
  ], 'AS2EdiConfiguration')
));


export type BaseFTPEdiConfiguration = EdiConfigurationValidated & {
  'inputPath': string,
  'outputPath'?: string,
  'username': string,
};
export type EncodedBaseFTPEdiConfiguration = EncodedEdiConfigurationValidated & {
  'inputPath': string,
  'outputPath'?: string,
  'username': string,
};
export const BaseFTPEdiConfiguration: t.Type<BaseFTPEdiConfiguration, EncodedBaseFTPEdiConfiguration> = t.recursion('BaseFTPEdiConfiguration', () => (
  t.intersection([
    EdiConfigurationValidated,
    t.exact(t.intersection([t.type({
      'inputPath': t.string,
      'username': t.string,
    }), t.partial({
      'outputPath': t.string,
    })]), 'BaseFTPEdiConfiguration')
  ], 'BaseFTPEdiConfiguration')
));


export type MableSFTPEdiConfiguration = BaseFTPEdiConfiguration & {
  'connectionType': 'mableSftp',
};
export type EncodedMableSFTPEdiConfiguration = EncodedBaseFTPEdiConfiguration & {
  'connectionType': string,
};
export const MableSFTPEdiConfiguration: t.Type<MableSFTPEdiConfiguration, EncodedMableSFTPEdiConfiguration> = t.recursion('MableSFTPEdiConfiguration', () => (
  t.intersection([
    BaseFTPEdiConfiguration,
    t.exact(t.intersection([t.type({
      'connectionType': CodegenEnumClosed({
        'mableSftp': null,
      }, 'MableSFTPEdiConfiguration'),
    }), t.partial({
      
    })]), 'MableSFTPEdiConfiguration')
  ], 'MableSFTPEdiConfiguration')
));


export type FTPEdiConfiguration = BaseFTPEdiConfiguration & {
  'connectionType': 'ftp',
  'password': string,
  'server': string,
};
export type EncodedFTPEdiConfiguration = EncodedBaseFTPEdiConfiguration & {
  'connectionType': string,
  'password': string,
  'server': string,
};
export const FTPEdiConfiguration: t.Type<FTPEdiConfiguration, EncodedFTPEdiConfiguration> = t.recursion('FTPEdiConfiguration', () => (
  t.intersection([
    BaseFTPEdiConfiguration,
    t.exact(t.intersection([t.type({
      'connectionType': CodegenEnumClosed({
        'ftp': null,
      }, 'FTPEdiConfiguration'),
      'password': t.string,
      'server': t.string,
    }), t.partial({
      
    })]), 'FTPEdiConfiguration')
  ], 'FTPEdiConfiguration')
));


export type FTPSEdiConfiguration = BaseFTPEdiConfiguration & {
  'connectionType': 'ftps',
  'password': string,
  'server': string,
};
export type EncodedFTPSEdiConfiguration = EncodedBaseFTPEdiConfiguration & {
  'connectionType': string,
  'password': string,
  'server': string,
};
export const FTPSEdiConfiguration: t.Type<FTPSEdiConfiguration, EncodedFTPSEdiConfiguration> = t.recursion('FTPSEdiConfiguration', () => (
  t.intersection([
    BaseFTPEdiConfiguration,
    t.exact(t.intersection([t.type({
      'connectionType': CodegenEnumClosed({
        'ftps': null,
      }, 'FTPSEdiConfiguration'),
      'password': t.string,
      'server': t.string,
    }), t.partial({
      
    })]), 'FTPSEdiConfiguration')
  ], 'FTPSEdiConfiguration')
));


export type EdiConfigurationWithConnection = OneOfOpenValues<AS2EdiConfiguration | MableSFTPEdiConfiguration | FTPEdiConfiguration | FTPSEdiConfiguration>;
export type EncodedEdiConfigurationWithConnection = OneOfOpenEncodedValues<EncodedAS2EdiConfiguration | EncodedMableSFTPEdiConfiguration | EncodedFTPEdiConfiguration | EncodedFTPSEdiConfiguration>;
export const EdiConfigurationWithConnection: t.Type<EdiConfigurationWithConnection, EncodedEdiConfigurationWithConnection> = t.recursion('EdiConfigurationWithConnection', () => (
  OneOfOpen([AS2EdiConfiguration, MableSFTPEdiConfiguration, FTPEdiConfiguration, FTPSEdiConfiguration])
));


export type EdiFileDirection = CodegenEnumOpenValues<'incoming' | 'outgoing'>;
export type EncodedEdiFileDirection = string;
export const EdiFileDirection = CodegenEnumOpen({
  'incoming': null,
  'outgoing': null,
}, 'EdiFileDirection');
export const EdiFileDirectionValues = ['incoming', 'outgoing'] as const;

/** pending: Waiting to be uploaded
inProgress: Currently being uploaded
success: Successfully uploaded
failure: Failed to upload */
export type EdiFileUploadStatus = CodegenEnumOpenValues<'pending' | 'inProgress' | 'success' | 'failure'>;
export type EncodedEdiFileUploadStatus = string;
export const EdiFileUploadStatus = CodegenEnumOpen({
  'pending': null,
  'inProgress': null,
  'success': null,
  'failure': null,
}, 'EdiFileUploadStatus');
export const EdiFileUploadStatusValues = ['pending', 'inProgress', 'success', 'failure'] as const;

/** pending: Waiting to be processed
inProgress: Currently being processed
complete: Successfully processed
error: Error occured while processing. Needs intervention. */
export type EdiFileProcessingStatus = CodegenEnumOpenValues<'pending' | 'inProgress' | 'complete' | 'error'>;
export type EncodedEdiFileProcessingStatus = string;
export const EdiFileProcessingStatus = CodegenEnumOpen({
  'pending': null,
  'inProgress': null,
  'complete': null,
  'error': null,
}, 'EdiFileProcessingStatus');
export const EdiFileProcessingStatusValues = ['pending', 'inProgress', 'complete', 'error'] as const;

export type EdiFile = {
  'id': number,
  'createdAt': Date,
  'updatedAt': Date,
  'filePath': string,
  'fileContents'?: string,
  'accountId': number,
  'direction': EdiFileDirection,
  'ediConfigurationId': string,
  'ediConfigurationSnapShot': Record<string, unknown>,
  'lastError'?: Record<string, unknown>,
  'uploadStatus'?: EdiFileUploadStatus,
  'processingStatus'?: EdiFileProcessingStatus,
  /** Location of the backup of this file */
  'archive'?: string,
};
export type EncodedEdiFile = {
  'id': number,
  'createdAt': string,
  'updatedAt': string,
  'filePath': string,
  'fileContents'?: string,
  'accountId': number,
  'direction': EncodedEdiFileDirection,
  'ediConfigurationId': string,
  'ediConfigurationSnapShot': Record<string, unknown>,
  'lastError'?: Record<string, unknown>,
  'uploadStatus'?: EncodedEdiFileUploadStatus,
  'processingStatus'?: EncodedEdiFileProcessingStatus,
  'archive'?: string,
};
export const EdiFile: t.Type<EdiFile, EncodedEdiFile> = t.recursion('EdiFile', () => (
  t.exact(t.intersection([t.type({
    'id': t.number,
    'createdAt': DateFromISOString,
    'updatedAt': DateFromISOString,
    'filePath': t.string,
    'accountId': t.number,
    'direction': EdiFileDirection,
    'ediConfigurationId': t.string,
    'ediConfigurationSnapShot': t.record(t.string, t.unknown),
  }), t.partial({
    'fileContents': t.string,
    'lastError': t.record(t.string, t.unknown),
    'uploadStatus': EdiFileUploadStatus,
    'processingStatus': EdiFileProcessingStatus,
    'archive': t.string,
  })]), 'EdiFile')
));


export type EdiFileFull = EdiFile & {
  'documents': Array<EdiDocument>,
};
export type EncodedEdiFileFull = EncodedEdiFile & {
  'documents': Array<EncodedEdiDocument>,
};
export const EdiFileFull: t.Type<EdiFileFull, EncodedEdiFileFull> = t.recursion('EdiFileFull', () => (
  t.intersection([
    EdiFile,
    t.exact(t.intersection([t.type({
      'documents': t.array(EdiDocument),
    }), t.partial({
      
    })]), 'EdiFileFull')
  ], 'EdiFileFull')
));


export type EdiDocumentType = CodegenEnumOpenValues<'purchaseOrder' | 'creditRequest' | 'functionalAcknowledgement' | 'invoice' | 'acknowledgement' | 'advancedShipNotice' | 'adjustmentRequest' | 'remittanceAdvice' | 'deliveryBaseRecord'>;
export type EncodedEdiDocumentType = string;
export const EdiDocumentType = CodegenEnumOpen({
  'purchaseOrder': null,
  'creditRequest': null,
  'functionalAcknowledgement': null,
  'invoice': null,
  'acknowledgement': null,
  'advancedShipNotice': null,
  'adjustmentRequest': null,
  'remittanceAdvice': null,
  'deliveryBaseRecord': null,
}, 'EdiDocumentType');
export const EdiDocumentTypeValues = ['purchaseOrder', 'creditRequest', 'functionalAcknowledgement', 'invoice', 'acknowledgement', 'advancedShipNotice', 'adjustmentRequest', 'remittanceAdvice', 'deliveryBaseRecord'] as const;

export type EdiDocument = {
  'id': number,
  'createdAt': Date,
  'updatedAt': Date,
  'ediFileId': number,
  'type': EdiDocumentType,
  'mableJson'?: Record<string, unknown>,
  'externalPurchaseOrderNumber'?: string,
  'transactionGroupId'?: number,
  'transactionId'?: number,
  'lastError'?: Record<string, unknown>,
};
export type EncodedEdiDocument = {
  'id': number,
  'createdAt': string,
  'updatedAt': string,
  'ediFileId': number,
  'type': EncodedEdiDocumentType,
  'mableJson'?: Record<string, unknown>,
  'externalPurchaseOrderNumber'?: string,
  'transactionGroupId'?: number,
  'transactionId'?: number,
  'lastError'?: Record<string, unknown>,
};
export const EdiDocument: t.Type<EdiDocument, EncodedEdiDocument> = t.recursion('EdiDocument', () => (
  t.exact(t.intersection([t.type({
    'id': t.number,
    'createdAt': DateFromISOString,
    'updatedAt': DateFromISOString,
    'ediFileId': t.number,
    'type': EdiDocumentType,
  }), t.partial({
    'mableJson': t.record(t.string, t.unknown),
    'externalPurchaseOrderNumber': t.string,
    'transactionGroupId': t.number,
    'transactionId': t.number,
    'lastError': t.record(t.string, t.unknown),
  })]), 'EdiDocument')
));


export type LandedCostCalculatorInput = {
  'shipFromAddress': AddressInput,
  'shipToAddresses': Array<AddressInput>,
  'parcel': Parcel,
};
export type EncodedLandedCostCalculatorInput = {
  'shipFromAddress': EncodedAddressInput,
  'shipToAddresses': Array<EncodedAddressInput>,
  'parcel': EncodedParcel,
};
export const LandedCostCalculatorInput: t.Type<LandedCostCalculatorInput, EncodedLandedCostCalculatorInput> = t.recursion('LandedCostCalculatorInput', () => (
  t.exact(t.intersection([t.type({
    'shipFromAddress': AddressInput,
    'shipToAddresses': t.array(AddressInput),
    'parcel': Parcel,
  }), t.partial({
    
  })]), 'LandedCostCalculatorInput')
));


export type LandedCostCalculatorResult = {
  'ratesForAddresses': Array<{
    'shipToAddress': AddressInput,
    'rates'?: Array<ShipmentRate>,
  }>,
};
export type EncodedLandedCostCalculatorResult = {
  'ratesForAddresses': Array<{
    'shipToAddress': EncodedAddressInput,
    'rates'?: Array<EncodedShipmentRate>,
  }>,
};
export const LandedCostCalculatorResult: t.Type<LandedCostCalculatorResult, EncodedLandedCostCalculatorResult> = t.recursion('LandedCostCalculatorResult', () => (
  t.exact(t.intersection([t.type({
    'ratesForAddresses': t.array(t.exact(t.intersection([t.type({
      'shipToAddress': AddressInput,
    }), t.partial({
      'rates': t.array(ShipmentRate),
    })]), 'LandedCostCalculatorResultItem')),
  }), t.partial({
    
  })]), 'LandedCostCalculatorResult')
));


export type PricingRuleType = CodegenEnumOpenValues<'wholesale' | 'base' | 'price_replacement' | 'flat_discount' | 'factor_discount'>;
export type EncodedPricingRuleType = string;
export const PricingRuleType = CodegenEnumOpen({
  'wholesale': null,
  'base': null,
  'price_replacement': null,
  'flat_discount': null,
  'factor_discount': null,
}, 'PricingRuleType');
export const PricingRuleTypeValues = ['wholesale', 'base', 'price_replacement', 'flat_discount', 'factor_discount'] as const;

/** Just a subset of PricingRuleType that excludes base */
export type DiscountPricingRuleType = CodegenEnumOpenValues<'price_replacement' | 'flat_discount' | 'factor_discount'>;
export type EncodedDiscountPricingRuleType = string;
export const DiscountPricingRuleType = CodegenEnumOpen({
  'price_replacement': null,
  'flat_discount': null,
  'factor_discount': null,
}, 'DiscountPricingRuleType');
export const DiscountPricingRuleTypeValues = ['price_replacement', 'flat_discount', 'factor_discount'] as const;

export type PricingRule = {
  'id': number,
  'value': BigNumber,
  'ruleType': PricingRuleType,
  'variantId'?: number,
  'variantName'?: string,
  'productId'?: number,
  'sellerId'?: number,
  'tierId'?: number,
  'tierName'?: string,
};
export type EncodedPricingRule = {
  'id': number,
  'value': string,
  'ruleType': EncodedPricingRuleType,
  'variantId'?: number,
  'variantName'?: string,
  'productId'?: number,
  'sellerId'?: number,
  'tierId'?: number,
  'tierName'?: string,
};
export const PricingRule: t.Type<PricingRule, EncodedPricingRule> = t.recursion('PricingRule', () => (
  t.exact(t.intersection([t.type({
    'id': t.number,
    'value': NumberFromString,
    'ruleType': PricingRuleType,
  }), t.partial({
    'variantId': t.number,
    'variantName': t.string,
    'productId': t.number,
    'sellerId': t.number,
    'tierId': t.number,
    'tierName': t.string,
  })]), 'PricingRule')
));


export type PricingRuleInput = {
  'value': BigNumber,
  'ruleType': PricingRuleType,
  'variantId'?: null | number,
  'sellerId'?: null | number,
  'tierId'?: null | number,
  'eraId'?: BigNumber,
};
export type EncodedPricingRuleInput = {
  'value': string,
  'ruleType': EncodedPricingRuleType,
  'variantId'?: null | number,
  'sellerId'?: null | number,
  'tierId'?: null | number,
  'eraId'?: string,
};
export const PricingRuleInput: t.Type<PricingRuleInput, EncodedPricingRuleInput> = t.recursion('PricingRuleInput', () => (
  t.exact(t.intersection([t.type({
    'value': NumberFromString,
    'ruleType': PricingRuleType,
  }), t.partial({
    'variantId': t.union([t.null, t.number]),
    'sellerId': t.union([t.null, t.number]),
    'tierId': t.union([t.null, t.number]),
    'eraId': NumberFromString,
  })]), 'PricingRuleInput')
));


export type AdminPricingTierInput = {
  'name': string,
  'isLandedCosts'?: boolean,
};
export type EncodedAdminPricingTierInput = {
  'name': string,
  'isLandedCosts'?: boolean,
};
export const AdminPricingTierInput: t.Type<AdminPricingTierInput, EncodedAdminPricingTierInput> = t.recursion('AdminPricingTierInput', () => (
  t.exact(t.intersection([t.type({
    'name': t.string,
  }), t.partial({
    'isLandedCosts': t.boolean,
  })]), 'AdminPricingTierInput')
));


export type AdminSellerPricingTier = {
  'id': number,
  'sellerId': number,
  'name': string,
  'isLandedCosts': boolean,
  'buyers'?: Array<{
    'id': number,
    'name': string,
  }>,
  'eras': Array<AdminSellerPricingEra>,
};
export type EncodedAdminSellerPricingTier = {
  'id': number,
  'sellerId': number,
  'name': string,
  'isLandedCosts': boolean,
  'buyers'?: Array<{
    'id': number,
    'name': string,
  }>,
  'eras': Array<EncodedAdminSellerPricingEra>,
};
export const AdminSellerPricingTier: t.Type<AdminSellerPricingTier, EncodedAdminSellerPricingTier> = t.recursion('AdminSellerPricingTier', () => (
  t.exact(t.intersection([t.type({
    'id': t.number,
    'sellerId': t.number,
    'name': t.string,
    'isLandedCosts': t.boolean,
    'eras': t.array(AdminSellerPricingEra),
  }), t.partial({
    'buyers': t.array(t.exact(t.intersection([t.type({
      'id': t.number,
      'name': t.string,
    }), t.partial({
      
    })]), 'AdminSellerPricingTierItem')),
  })]), 'AdminSellerPricingTier')
));


export type AdminSellerPricingEra = {
  'id': BigNumber,
  'sellerId': number,
  'tierId'?: number,
  'startAt': Date,
  /** A list of discount types that have been applied in this tier */
  'discountTypes'?: Array<DiscountPricingRuleType>,
  'rules': Array<PricingRule>,
};
export type EncodedAdminSellerPricingEra = {
  'id': string,
  'sellerId': number,
  'tierId'?: number,
  'startAt': string,
  'discountTypes'?: Array<EncodedDiscountPricingRuleType>,
  'rules': Array<EncodedPricingRule>,
};
export const AdminSellerPricingEra: t.Type<AdminSellerPricingEra, EncodedAdminSellerPricingEra> = t.recursion('AdminSellerPricingEra', () => (
  t.exact(t.intersection([t.type({
    'id': NumberFromString,
    'sellerId': t.number,
    'startAt': DateFromISOString,
    'rules': t.array(PricingRule),
  }), t.partial({
    'tierId': t.number,
    'discountTypes': t.array(DiscountPricingRuleType),
  })]), 'AdminSellerPricingEra')
));


export type AdminPricingEraPatch = {
  'addRules'?: Array<PricingRuleInput>,
  'removeRuleIds'?: Array<number>,
};
export type EncodedAdminPricingEraPatch = {
  'addRules'?: Array<EncodedPricingRuleInput>,
  'removeRuleIds'?: Array<number>,
};
export const AdminPricingEraPatch: t.Type<AdminPricingEraPatch, EncodedAdminPricingEraPatch> = t.recursion('AdminPricingEraPatch', () => (
  t.exact(t.intersection([t.type({
    
  }), t.partial({
    'addRules': t.array(PricingRuleInput),
    'removeRuleIds': t.array(t.number),
  })]), 'AdminPricingEraPatch')
));


export type AdminBuyerPricingTier = {
  'id': number,
  'tierId': number,
  'tierName': string,
  'sellerId': number,
  'sellerName': string,
  'buyerId': number,
  'buyerName': string,
  'isLandedCosts': boolean,
  /** A list of discount types that have been applied in this tier */
  'discountTypes': Array<DiscountPricingRuleType>,
};
export type EncodedAdminBuyerPricingTier = {
  'id': number,
  'tierId': number,
  'tierName': string,
  'sellerId': number,
  'sellerName': string,
  'buyerId': number,
  'buyerName': string,
  'isLandedCosts': boolean,
  'discountTypes': Array<EncodedDiscountPricingRuleType>,
};
export const AdminBuyerPricingTier: t.Type<AdminBuyerPricingTier, EncodedAdminBuyerPricingTier> = t.recursion('AdminBuyerPricingTier', () => (
  t.exact(t.intersection([t.type({
    'id': t.number,
    'tierId': t.number,
    'tierName': t.string,
    'sellerId': t.number,
    'sellerName': t.string,
    'buyerId': t.number,
    'buyerName': t.string,
    'isLandedCosts': t.boolean,
    'discountTypes': t.array(DiscountPricingRuleType),
  }), t.partial({
    
  })]), 'AdminBuyerPricingTier')
));


export type AdminBuyerPricingTierInput = {
  'tierId': number,
  'buyerId': number,
};
export type EncodedAdminBuyerPricingTierInput = {
  'tierId': number,
  'buyerId': number,
};
export const AdminBuyerPricingTierInput: t.Type<AdminBuyerPricingTierInput, EncodedAdminBuyerPricingTierInput> = t.recursion('AdminBuyerPricingTierInput', () => (
  t.exact(t.intersection([t.type({
    'tierId': t.number,
    'buyerId': t.number,
  }), t.partial({
    
  })]), 'AdminBuyerPricingTierInput')
));


export type AdminPriceInfo = {
  'variantId': number,
  'variantName': string,
  'productId': number,
  /** The final/actual price the buyer will pay for the product */
  'price'?: string,
  /** The stated value of the product direct-to-customer */
  'wholesalePrice'?: string,
  /** This is the amount we pay the seller for the product. */
  'sellerRemittancePrice'?: string,
  /** The price of the product when not on sale */
  'offSalePrice'?: string,
  /** The manufacturer's suggested retail price */
  'msrp'?: string,
  /** The percent profit a retailer can expect to make when selling the product */
  'margin'?: number,
  'rules': Array<PricingRule>,
};
export type EncodedAdminPriceInfo = {
  'variantId': number,
  'variantName': string,
  'productId': number,
  'price'?: string,
  'wholesalePrice'?: string,
  'sellerRemittancePrice'?: string,
  'offSalePrice'?: string,
  'msrp'?: string,
  'margin'?: number,
  'rules': Array<EncodedPricingRule>,
};
export const AdminPriceInfo: t.Type<AdminPriceInfo, EncodedAdminPriceInfo> = t.recursion('AdminPriceInfo', () => (
  t.exact(t.intersection([t.type({
    'variantId': t.number,
    'variantName': t.string,
    'productId': t.number,
    'rules': t.array(PricingRule),
  }), t.partial({
    'price': t.string,
    'wholesalePrice': t.string,
    'sellerRemittancePrice': t.string,
    'offSalePrice': t.string,
    'msrp': t.string,
    'margin': t.number,
  })]), 'AdminPriceInfo')
));


export type PartnerProgramMembership = {
  'sellerId': number,
  'sellerAccountId': number,
  'partnerAccountId': number,
  'partnerAccountName': string,
  /** The name of the program for this partner. This might be different from the partner name, e.g. McLane Emerging Brands vs McLane */
  'programName': string,
  /** The name of the partner that should be displayed to users */
  'displayName': string,
  'membershipStatus'?: PartnerProgramMembershipStatus,
  'hubspotLifecycleStatus'?: string,
  'termsAccepted'?: boolean,
  'productsPrepared'?: boolean,
  'dataReviewed'?: boolean,
  'verificationProductShipped'?: boolean,
  /** If this is true, the seller has at least one product that has had a unique ID assigned in the partner's system (e.g. UIN). */
  'productsAssignedPartnerIds'?: boolean,
  'serviceLevelAgreementURL'?: string,
  'productDataRequirementsURL'?: string,
  'brandFAQURL'?: string,
  'productDataIssuesCount'?: number,
  /** If this is true, we are stuck in trying to get this seller onboarded. */
  'stuck'?: boolean,
};
export type EncodedPartnerProgramMembership = {
  'sellerId': number,
  'sellerAccountId': number,
  'partnerAccountId': number,
  'partnerAccountName': string,
  'programName': string,
  'displayName': string,
  'membershipStatus'?: EncodedPartnerProgramMembershipStatus,
  'hubspotLifecycleStatus'?: string,
  'termsAccepted'?: boolean,
  'productsPrepared'?: boolean,
  'dataReviewed'?: boolean,
  'verificationProductShipped'?: boolean,
  'productsAssignedPartnerIds'?: boolean,
  'serviceLevelAgreementURL'?: string,
  'productDataRequirementsURL'?: string,
  'brandFAQURL'?: string,
  'productDataIssuesCount'?: number,
  'stuck'?: boolean,
};
export const PartnerProgramMembership: t.Type<PartnerProgramMembership, EncodedPartnerProgramMembership> = t.recursion('PartnerProgramMembership', () => (
  t.exact(t.intersection([t.type({
    'sellerId': t.number,
    'sellerAccountId': t.number,
    'partnerAccountId': t.number,
    'partnerAccountName': t.string,
    'programName': t.string,
    'displayName': t.string,
  }), t.partial({
    'membershipStatus': PartnerProgramMembershipStatus,
    'hubspotLifecycleStatus': t.string,
    'termsAccepted': t.boolean,
    'productsPrepared': t.boolean,
    'dataReviewed': t.boolean,
    'verificationProductShipped': t.boolean,
    'productsAssignedPartnerIds': t.boolean,
    'serviceLevelAgreementURL': t.string,
    'productDataRequirementsURL': t.string,
    'brandFAQURL': t.string,
    'productDataIssuesCount': t.number,
    'stuck': t.boolean,
  })]), 'PartnerProgramMembership')
));


/** The current status that a seller is in for a given program
inProcess   : A prospect to join the program, but isn't in yet
joined      : In the program
unqualified : Not in the program, either because Mable has determined them to not be a fit, or they have declined joining. */
export type PartnerProgramMembershipStatus = CodegenEnumOpenValues<'inProcess' | 'joined' | 'unqualified'>;
export type EncodedPartnerProgramMembershipStatus = string;
export const PartnerProgramMembershipStatus = CodegenEnumOpen({
  'inProcess': null,
  'joined': null,
  'unqualified': null,
}, 'PartnerProgramMembershipStatus');
export const PartnerProgramMembershipStatusValues = ['inProcess', 'joined', 'unqualified'] as const;

export type AdminProductVariantAplMembership = {
  'accountId': number,
  'accountName'?: string,
  'categoryId': number,
  'categoryName'?: string,
  'productVariantId': number,
};
export type EncodedAdminProductVariantAplMembership = {
  'accountId': number,
  'accountName'?: string,
  'categoryId': number,
  'categoryName'?: string,
  'productVariantId': number,
};
export const AdminProductVariantAplMembership: t.Type<AdminProductVariantAplMembership, EncodedAdminProductVariantAplMembership> = t.recursion('AdminProductVariantAplMembership', () => (
  t.exact(t.intersection([t.type({
    'accountId': t.number,
    'categoryId': t.number,
    'productVariantId': t.number,
  }), t.partial({
    'accountName': t.string,
    'categoryName': t.string,
  })]), 'AdminProductVariantAplMembership')
));


export type VariantPurchasableStatus = CodegenEnumOpenValues<'yes' | 'offApl' | 'nonPersonalizedPrice' | 'outOfStock' | 'sellerNotTakingOrders'>;
export type EncodedVariantPurchasableStatus = string;
export const VariantPurchasableStatus = CodegenEnumOpen({
  'yes': null,
  'offApl': null,
  'nonPersonalizedPrice': null,
  'outOfStock': null,
  'sellerNotTakingOrders': null,
}, 'VariantPurchasableStatus');
export const VariantPurchasableStatusValues = ['yes', 'offApl', 'nonPersonalizedPrice', 'outOfStock', 'sellerNotTakingOrders'] as const;

export type VariantPartnerMembership = {
  'productVariantId': number,
  'manuallyExcludedReasons': Array<string>,
  'manuallyIgnoreAutomaticallyExcludedReasons': Array<string>,
  'automaticallyExcludedReasons': Array<string>,
  'thirdPartyTags': Array<string>,
};
export type EncodedVariantPartnerMembership = {
  'productVariantId': number,
  'manuallyExcludedReasons': Array<string>,
  'manuallyIgnoreAutomaticallyExcludedReasons': Array<string>,
  'automaticallyExcludedReasons': Array<string>,
  'thirdPartyTags': Array<string>,
};
export const VariantPartnerMembership: t.Type<VariantPartnerMembership, EncodedVariantPartnerMembership> = t.recursion('VariantPartnerMembership', () => (
  t.exact(t.intersection([t.type({
    'productVariantId': t.number,
    'manuallyExcludedReasons': t.array(t.string),
    'manuallyIgnoreAutomaticallyExcludedReasons': t.array(t.string),
    'automaticallyExcludedReasons': t.array(t.string),
    'thirdPartyTags': t.array(t.string),
  }), t.partial({
    
  })]), 'VariantPartnerMembership')
));


export type AdminPartnerPricingRegion = {
  'partnerAccountId': number,
  'partnerName': string,
  'pricingRegionName': string,
  'pricingRegionId': string,
  'partnerIdentifier': string,
};
export type EncodedAdminPartnerPricingRegion = {
  'partnerAccountId': number,
  'partnerName': string,
  'pricingRegionName': string,
  'pricingRegionId': string,
  'partnerIdentifier': string,
};
export const AdminPartnerPricingRegion: t.Type<AdminPartnerPricingRegion, EncodedAdminPartnerPricingRegion> = t.recursion('AdminPartnerPricingRegion', () => (
  t.exact(t.intersection([t.type({
    'partnerAccountId': t.number,
    'partnerName': t.string,
    'pricingRegionName': t.string,
    'pricingRegionId': t.string,
    'partnerIdentifier': t.string,
  }), t.partial({
    
  })]), 'AdminPartnerPricingRegion')
));


export type AdminOneWorldSyncResult = {
  'result': string,
};
export type EncodedAdminOneWorldSyncResult = {
  'result': string,
};
export const AdminOneWorldSyncResult: t.Type<AdminOneWorldSyncResult, EncodedAdminOneWorldSyncResult> = t.recursion('AdminOneWorldSyncResult', () => (
  t.exact(t.intersection([t.type({
    'result': t.string,
  }), t.partial({
    
  })]), 'AdminOneWorldSyncResult')
));


export type OneWorldSyncStatus = {
  'type': CodegenEnumOpenValues<'item' | 'link' | 'publish'>,
  'status': CodegenEnumOpenValues<'notSynced' | 'synced' | 'error' | 'withdrawing' | 'withdrawn' | 'deleted' | 'excluded'>,
};
export type EncodedOneWorldSyncStatus = {
  'type': string,
  'status': string,
};
export const OneWorldSyncStatus: t.Type<OneWorldSyncStatus, EncodedOneWorldSyncStatus> = t.recursion('OneWorldSyncStatus', () => (
  t.exact(t.intersection([t.type({
    'type': CodegenEnumOpen({
      'item': null,
      'link': null,
      'publish': null,
    }, 'OneWorldSyncStatus'),
    'status': CodegenEnumOpen({
      'notSynced': null,
      'synced': null,
      'error': null,
      'withdrawing': null,
      'withdrawn': null,
      'deleted': null,
      'excluded': null,
    }, 'OneWorldSyncStatus'),
  }), t.partial({
    
  })]), 'OneWorldSyncStatus')
));


export type BaseLandedCostParameters = {
  'maxMarkup': BigNumber,
  'maxHistoricalDifference': BigNumber,
  'minMargin'?: BigNumber,
  'pricedBrandAction': LandedCostPricedBrandAction,
  'startAt': Date,
};
export type EncodedBaseLandedCostParameters = {
  'maxMarkup': string,
  'maxHistoricalDifference': string,
  'minMargin'?: string,
  'pricedBrandAction': EncodedLandedCostPricedBrandAction,
  'startAt': string,
};
export const BaseLandedCostParameters: t.Type<BaseLandedCostParameters, EncodedBaseLandedCostParameters> = t.recursion('BaseLandedCostParameters', () => (
  t.exact(t.intersection([t.type({
    'maxMarkup': NumberFromString,
    'maxHistoricalDifference': NumberFromString,
    'pricedBrandAction': LandedCostPricedBrandAction,
    'startAt': DateFromISOString,
  }), t.partial({
    'minMargin': NumberFromString,
  })]), 'BaseLandedCostParameters')
));


/** Options for how to handle a brand that already has prices when generating landed costs
- exclude: ignore the brand entirely
- reevaluate: recalculate new markups
- basePriceUpdate: preserve the previous markups and get updated variant prices */
export type LandedCostPricedBrandAction = 'exclude' | 'reevaluate' | 'basePriceUpdate';
export type EncodedLandedCostPricedBrandAction = string;
export const LandedCostPricedBrandAction = CodegenEnumClosed({
  'exclude': null,
  'reevaluate': null,
  'basePriceUpdate': null,
}, 'LandedCostPricedBrandAction');
export const LandedCostPricedBrandActionValues = ['exclude', 'reevaluate', 'basePriceUpdate'] as const;

/** All of the different whitelabel branding experienecs we support */
export type WhitelabelIdentifier = 'mable' | 'mclane' | 'kehe' | 'kehe-demo';
export type EncodedWhitelabelIdentifier = string;
export const WhitelabelIdentifier = CodegenEnumClosed({
  'mable': null,
  'mclane': null,
  'kehe': null,
  'kehe-demo': null,
}, 'WhitelabelIdentifier');
export const WhitelabelIdentifierValues = ['mable', 'mclane', 'kehe', 'kehe-demo'] as const;

export type SlackFile = {
  /** Comma-separated list of channel names or IDs where the file will be shared. e.g. '#channel-one,#channel-two' */
  'channels': string,
  'filename': string,
  'content': string,
  'filetype'?: string,
  'title'?: string,
  'initial_comment'?: string,
};
export type EncodedSlackFile = {
  'channels': string,
  'filename': string,
  'content': string,
  'filetype'?: string,
  'title'?: string,
  'initial_comment'?: string,
};
export const SlackFile: t.Type<SlackFile, EncodedSlackFile> = t.recursion('SlackFile', () => (
  t.exact(t.intersection([t.type({
    'channels': t.string,
    'filename': t.string,
    'content': t.string,
  }), t.partial({
    'filetype': t.string,
    'title': t.string,
    'initial_comment': t.string,
  })]), 'SlackFile')
));


/** Payload to postMessage API to https://api.slack.com/methods/chat.postMessage. There are still more properties provided by the API that are not covered by this API. */
export type SlackMessage = {
  'channel': string,
  'text'?: string,
  /** A JSON-based array of structured blocks. */
  'blocks'?: Array<Record<string, unknown>>,
  /** Disable Slack markup parsing by setting to false. Enabled by default. */
  'mrkdwn'?: boolean,
  /** Emoji to use as the icon for this message. Overrides icon_url. Example: :chart_with_upwards_trend: */
  'icon_emoji'?: string,
  /** URL to an image to use as the icon for this message. */
  'icon_url'?: string,
};
export type EncodedSlackMessage = {
  'channel': string,
  'text'?: string,
  'blocks'?: Array<Record<string, unknown>>,
  'mrkdwn'?: boolean,
  'icon_emoji'?: string,
  'icon_url'?: string,
};
export const SlackMessage: t.Type<SlackMessage, EncodedSlackMessage> = t.recursion('SlackMessage', () => (
  t.exact(t.intersection([t.type({
    'channel': t.string,
  }), t.partial({
    'text': t.string,
    'blocks': t.array(t.record(t.string, t.unknown)),
    'mrkdwn': t.boolean,
    'icon_emoji': t.string,
    'icon_url': t.string,
  })]), 'SlackMessage')
));


/** All of the different list types we support */
export type ListIdentifier = CodegenEnumOpenValues<'@favorites' | '@order-guide'>;
export type EncodedListIdentifier = string;
export const ListIdentifier = CodegenEnumOpen({
  '@favorites': null,
  '@order-guide': null,
}, 'ListIdentifier');
export const ListIdentifierValues = ['@favorites', '@order-guide'] as const;

export type VariantEditingDisabledReason = CodegenEnumOpenValues<'1WS'>;
export type EncodedVariantEditingDisabledReason = string;
export const VariantEditingDisabledReason = CodegenEnumOpen({
  '1WS': null,
}, 'VariantEditingDisabledReason');
export const VariantEditingDisabledReasonValues = ['1WS'] as const;

/** Different options for what data to include in pricing file previews */
export type PricingFilePreviewMode = 'standard' | 'full';
export type EncodedPricingFilePreviewMode = string;
export const PricingFilePreviewMode = CodegenEnumClosed({
  'standard': null,
  'full': null,
}, 'PricingFilePreviewMode');
export const PricingFilePreviewModeValues = ['standard', 'full'] as const;

/** Different options for what data to include in pricing file previews */
export type PricingFileIncludedVariants = 'new' | 'updated' | 'all';
export type EncodedPricingFileIncludedVariants = string;
export const PricingFileIncludedVariants = CodegenEnumClosed({
  'new': null,
  'updated': null,
  'all': null,
}, 'PricingFileIncludedVariants');
export const PricingFileIncludedVariantsValues = ['new', 'updated', 'all'] as const;

export type LambdaMableEnvironmentContext = {
  /** e.g. "https://api.meetmable.com" */
  'baseUrl': string,
  /** The full value of what we pass in the `Authorization` header, e.g. "Bearer sadihaskdjhsad" */
  'jwt': string,
  'correlationId'?: string,
};
export type EncodedLambdaMableEnvironmentContext = {
  'baseUrl': string,
  'jwt': string,
  'correlationId'?: string,
};
export const LambdaMableEnvironmentContext: t.Type<LambdaMableEnvironmentContext, EncodedLambdaMableEnvironmentContext> = t.recursion('LambdaMableEnvironmentContext', () => (
  t.exact(t.intersection([t.type({
    'baseUrl': t.string,
    'jwt': t.string,
  }), t.partial({
    'correlationId': t.string,
  })]), 'LambdaMableEnvironmentContext')
));


export type RangeMeScrapeRequest = {
  /** RangeMe's unique ID of the brand being scraped */
  'rangeMeBrandId': string,
  'rangeMeUsername': string,
  'rangeMePassword': string,
  /** If provided, this auth token will used after verifying it's still valid */
  'rangeMeAuthToken'?: string,
};
export type EncodedRangeMeScrapeRequest = {
  'rangeMeBrandId': string,
  'rangeMeUsername': string,
  'rangeMePassword': string,
  'rangeMeAuthToken'?: string,
};
export const RangeMeScrapeRequest: t.Type<RangeMeScrapeRequest, EncodedRangeMeScrapeRequest> = t.recursion('RangeMeScrapeRequest', () => (
  t.exact(t.intersection([t.type({
    'rangeMeBrandId': t.string,
    'rangeMeUsername': t.string,
    'rangeMePassword': t.string,
  }), t.partial({
    'rangeMeAuthToken': t.string,
  })]), 'RangeMeScrapeRequest')
));


export type OneWorldSyncUinRequestProductInfo = {
  'productVariantId': number,
  'caseGtin': string,
  'gtin'?: string,
};
export type EncodedOneWorldSyncUinRequestProductInfo = {
  'productVariantId': number,
  'caseGtin': string,
  'gtin'?: string,
};
export const OneWorldSyncUinRequestProductInfo: t.Type<OneWorldSyncUinRequestProductInfo, EncodedOneWorldSyncUinRequestProductInfo> = t.recursion('OneWorldSyncUinRequestProductInfo', () => (
  t.exact(t.intersection([t.type({
    'productVariantId': t.number,
    'caseGtin': t.string,
  }), t.partial({
    'gtin': t.string,
  })]), 'OneWorldSyncUinRequestProductInfo')
));


export type OneWorldSyncUinRequest = {
  'oneWorldSyncUsername': string,
  'oneWorldSyncPassword': string,
  'products': Array<OneWorldSyncUinRequestProductInfo>,
  'mclaneAccountId': number,
  'chunkProducts': boolean,
};
export type EncodedOneWorldSyncUinRequest = {
  'oneWorldSyncUsername': string,
  'oneWorldSyncPassword': string,
  'products': Array<EncodedOneWorldSyncUinRequestProductInfo>,
  'mclaneAccountId': number,
  'chunkProducts': boolean,
};
export const OneWorldSyncUinRequest: t.Type<OneWorldSyncUinRequest, EncodedOneWorldSyncUinRequest> = t.recursion('OneWorldSyncUinRequest', () => (
  t.exact(t.intersection([t.type({
    'oneWorldSyncUsername': t.string,
    'oneWorldSyncPassword': t.string,
    'products': t.array(OneWorldSyncUinRequestProductInfo),
    'mclaneAccountId': t.number,
    'chunkProducts': t.boolean,
  }), t.partial({
    
  })]), 'OneWorldSyncUinRequest')
));


export type LambdaPingRequest = {
  'message': string,
  'slackChannel': string,
};
export type EncodedLambdaPingRequest = {
  'message': string,
  'slackChannel': string,
};
export const LambdaPingRequest: t.Type<LambdaPingRequest, EncodedLambdaPingRequest> = t.recursion('LambdaPingRequest', () => (
  t.exact(t.intersection([t.type({
    'message': t.string,
    'slackChannel': t.string,
  }), t.partial({
    
  })]), 'LambdaPingRequest')
));


export type LambdaInvocationPayload = {
  'mableEnvironmentContext': LambdaMableEnvironmentContext,
  'rangeMeScrapeRequest'?: RangeMeScrapeRequest,
  'oneWorldSyncUinRequest'?: OneWorldSyncUinRequest,
  'pingRequest'?: LambdaPingRequest,
};
export type EncodedLambdaInvocationPayload = {
  'mableEnvironmentContext': EncodedLambdaMableEnvironmentContext,
  'rangeMeScrapeRequest'?: EncodedRangeMeScrapeRequest,
  'oneWorldSyncUinRequest'?: EncodedOneWorldSyncUinRequest,
  'pingRequest'?: EncodedLambdaPingRequest,
};
export const LambdaInvocationPayload: t.Type<LambdaInvocationPayload, EncodedLambdaInvocationPayload> = t.recursion('LambdaInvocationPayload', () => (
  t.exact(t.intersection([t.type({
    'mableEnvironmentContext': LambdaMableEnvironmentContext,
  }), t.partial({
    'rangeMeScrapeRequest': RangeMeScrapeRequest,
    'oneWorldSyncUinRequest': OneWorldSyncUinRequest,
    'pingRequest': LambdaPingRequest,
  })]), 'LambdaInvocationPayload')
));


export type LambdaAdminInvocationPayload = {
  'payload': LambdaInvocationPayload,
};
export type EncodedLambdaAdminInvocationPayload = {
  'payload': EncodedLambdaInvocationPayload,
};
export const LambdaAdminInvocationPayload: t.Type<LambdaAdminInvocationPayload, EncodedLambdaAdminInvocationPayload> = t.recursion('LambdaAdminInvocationPayload', () => (
  t.exact(t.intersection([t.type({
    'payload': LambdaInvocationPayload,
  }), t.partial({
    
  })]), 'LambdaAdminInvocationPayload')
));


export type LambdaCallback = {
  'invocationPayload': LambdaInvocationPayload,
  'slackFiles'?: Array<SlackFile>,
  'slackMessages'?: Array<SlackMessage>,
  'oneWorldSyncUinRequestResult'?: {
    /** If the scraper didn't check for all the products it was asked for, the unchecked products will be returned here so it can invoke itself with the remaining ones. */
    'remainingProducts'?: Array<OneWorldSyncUinRequestProductInfo>,
  },
};
export type EncodedLambdaCallback = {
  'invocationPayload': EncodedLambdaInvocationPayload,
  'slackFiles'?: Array<EncodedSlackFile>,
  'slackMessages'?: Array<EncodedSlackMessage>,
  'oneWorldSyncUinRequestResult'?: {
    'remainingProducts'?: Array<EncodedOneWorldSyncUinRequestProductInfo>,
  },
};
export const LambdaCallback: t.Type<LambdaCallback, EncodedLambdaCallback> = t.recursion('LambdaCallback', () => (
  t.exact(t.intersection([t.type({
    'invocationPayload': LambdaInvocationPayload,
  }), t.partial({
    'slackFiles': t.array(SlackFile),
    'slackMessages': t.array(SlackMessage),
    'oneWorldSyncUinRequestResult': t.exact(t.intersection([t.type({
      
    }), t.partial({
      'remainingProducts': t.array(OneWorldSyncUinRequestProductInfo),
    })]), 'LambdaCallback'),
  })]), 'LambdaCallback')
));


export type PackagingVariant = {
  'variantId': number,
  'productId': number,
  'sku'?: string,
  'mableSku'?: string,
  'status': ProductStatus,
  'availability': ProductAvailability,
  'backInStockDate'?: Date,
  'price': BigNumber,
  'wholesalePrice': BigNumber,
};
export type EncodedPackagingVariant = {
  'variantId': number,
  'productId': number,
  'sku'?: string,
  'mableSku'?: string,
  'status': EncodedProductStatus,
  'availability': EncodedProductAvailability,
  'backInStockDate'?: string,
  'price': string,
  'wholesalePrice': string,
};
export const PackagingVariant: t.Type<PackagingVariant, EncodedPackagingVariant> = t.recursion('PackagingVariant', () => (
  t.exact(t.intersection([t.type({
    'variantId': t.number,
    'productId': t.number,
    'status': ProductStatus,
    'availability': ProductAvailability,
    'price': NumberFromString,
    'wholesalePrice': NumberFromString,
  }), t.partial({
    'sku': t.string,
    'mableSku': t.string,
    'backInStockDate': DateFromISOString,
  })]), 'PackagingVariant')
));


export type PackagingVariantAdd = {
  'sku'?: null | string,
  'availability'?: ProductAvailability,
  'backInStockDate'?: null | Date,
  'price': BigNumber,
  'wholesalePrice'?: BigNumber,
};
export type EncodedPackagingVariantAdd = {
  'sku'?: null | string,
  'availability'?: EncodedProductAvailability,
  'backInStockDate'?: null | string,
  'price': string,
  'wholesalePrice'?: string,
};
export const PackagingVariantAdd: t.Type<PackagingVariantAdd, EncodedPackagingVariantAdd> = t.recursion('PackagingVariantAdd', () => (
  t.exact(t.intersection([t.type({
    'price': NumberFromString,
  }), t.partial({
    'sku': t.union([t.null, t.string]),
    'availability': ProductAvailability,
    'backInStockDate': t.union([t.null, DateFromISOString]),
    'wholesalePrice': NumberFromString,
  })]), 'PackagingVariantAdd')
));


export type AdminPackagingVariantAdd = PackagingVariantAdd & {
  'status'?: ProductStatus,
};
export type EncodedAdminPackagingVariantAdd = EncodedPackagingVariantAdd & {
  'status'?: EncodedProductStatus,
};
export const AdminPackagingVariantAdd: t.Type<AdminPackagingVariantAdd, EncodedAdminPackagingVariantAdd> = t.recursion('AdminPackagingVariantAdd', () => (
  t.intersection([
    PackagingVariantAdd,
    t.exact(t.intersection([t.type({
      
    }), t.partial({
      'status': ProductStatus,
    })]), 'AdminPackagingVariantAdd')
  ], 'AdminPackagingVariantAdd')
));


export type PackagingVariantPatch = {
  'sku'?: null | string,
  'availability'?: ProductAvailability,
  'backInStockDate'?: null | Date,
  'price'?: BigNumber,
  'wholesalePrice'?: BigNumber,
};
export type EncodedPackagingVariantPatch = {
  'sku'?: null | string,
  'availability'?: EncodedProductAvailability,
  'backInStockDate'?: null | string,
  'price'?: string,
  'wholesalePrice'?: string,
};
export const PackagingVariantPatch: t.Type<PackagingVariantPatch, EncodedPackagingVariantPatch> = t.recursion('PackagingVariantPatch', () => (
  t.exact(t.intersection([t.type({
    
  }), t.partial({
    'sku': t.union([t.null, t.string]),
    'availability': ProductAvailability,
    'backInStockDate': t.union([t.null, DateFromISOString]),
    'price': NumberFromString,
    'wholesalePrice': NumberFromString,
  })]), 'PackagingVariantPatch')
));


export type AdminPackagingVariantPatch = PackagingVariantPatch & {
  'status'?: ProductStatus,
};
export type EncodedAdminPackagingVariantPatch = EncodedPackagingVariantPatch & {
  'status'?: EncodedProductStatus,
};
export const AdminPackagingVariantPatch: t.Type<AdminPackagingVariantPatch, EncodedAdminPackagingVariantPatch> = t.recursion('AdminPackagingVariantPatch', () => (
  t.intersection([
    PackagingVariantPatch,
    t.exact(t.intersection([t.type({
      
    }), t.partial({
      'status': ProductStatus,
    })]), 'AdminPackagingVariantPatch')
  ], 'AdminPackagingVariantPatch')
));


export type PackagingBase = {
  'gtin'?: Gtin,
  'weight'?: BigNumber,
  'weightUnit'?: ProductVariantPackagingWeightUnit,
  'width'?: BigNumber,
  'height'?: BigNumber,
  'depth'?: BigNumber,
  'distanceUnit'?: ProductVariantPackagingDistanceUnit,
  /** If this packaging is currently available for shipping, as itself, or as part of a larger packaging */
  'isShippable'?: boolean,
  /** If this packacging may currently be purchased (more specific than shippable). If true will have an associated variant */
  'isPurchasable'?: boolean,
  /** If this packaging has been shared with external sources, e.g. OneWorldSync or some other upmarket client */
  'isExternallyPublished'?: boolean,
};
export type EncodedPackagingBase = {
  'gtin'?: string,
  'weight'?: string,
  'weightUnit'?: EncodedProductVariantPackagingWeightUnit,
  'width'?: string,
  'height'?: string,
  'depth'?: string,
  'distanceUnit'?: EncodedProductVariantPackagingDistanceUnit,
  'isShippable'?: boolean,
  'isPurchasable'?: boolean,
  'isExternallyPublished'?: boolean,
};
export const PackagingBase: t.Type<PackagingBase, EncodedPackagingBase> = t.recursion('PackagingBase', () => (
  t.exact(t.intersection([t.type({
    
  }), t.partial({
    'gtin': Gtin,
    'weight': NumberFromString,
    'weightUnit': ProductVariantPackagingWeightUnit,
    'width': NumberFromString,
    'height': NumberFromString,
    'depth': NumberFromString,
    'distanceUnit': ProductVariantPackagingDistanceUnit,
    'isShippable': t.boolean,
    'isPurchasable': t.boolean,
    'isExternallyPublished': t.boolean,
  })]), 'PackagingBase')
));


export type PackagingPatchBase = {
  'gtin'?: null | Gtin,
  'weight'?: null | BigNumber,
  'weightUnit'?: null | ProductVariantPackagingWeightUnit,
  'width'?: null | BigNumber,
  'height'?: null | BigNumber,
  'depth'?: null | BigNumber,
  'distanceUnit'?: null | ProductVariantPackagingDistanceUnit,
  /** If this packaging is currently available for shipping, as itself, or as part of a larger packaging */
  'isShippable'?: boolean,
  /** If this packacging may currently be purchased (more specific than shippable). If true will have an associated variant */
  'isPurchasable'?: boolean,
};
export type EncodedPackagingPatchBase = {
  'gtin'?: null | string,
  'weight'?: null | string,
  'weightUnit'?: null | EncodedProductVariantPackagingWeightUnit,
  'width'?: null | string,
  'height'?: null | string,
  'depth'?: null | string,
  'distanceUnit'?: null | EncodedProductVariantPackagingDistanceUnit,
  'isShippable'?: boolean,
  'isPurchasable'?: boolean,
};
export const PackagingPatchBase: t.Type<PackagingPatchBase, EncodedPackagingPatchBase> = t.recursion('PackagingPatchBase', () => (
  t.exact(t.intersection([t.type({
    
  }), t.partial({
    'gtin': t.union([t.null, Gtin]),
    'weight': t.union([t.null, NumberFromString]),
    'weightUnit': t.union([t.null, ProductVariantPackagingWeightUnit]),
    'width': t.union([t.null, NumberFromString]),
    'height': t.union([t.null, NumberFromString]),
    'depth': t.union([t.null, NumberFromString]),
    'distanceUnit': t.union([t.null, ProductVariantPackagingDistanceUnit]),
    'isShippable': t.boolean,
    'isPurchasable': t.boolean,
  })]), 'PackagingPatchBase')
));


export type PackagingMonopackContainer = PackagingBase & {
  'id': number,
  'displayName': string,
  'packType': 'monopack',
  'itemPackagingId': number,
  /** The total number of sub-packagings, e.g. a Master Case that has 4 Cases of 3 Pouches would have an itemCount of 4 */
  'itemCount': number,
  'itemDisplayName': string,
  /** The total number of retailUnits within this packaging. e.g. a Master Case that has 4 Cases of 3 Pouches would have a totalUnits of 12 */
  'totalUnits': number,
  'variant'?: PackagingVariant,
};
export type EncodedPackagingMonopackContainer = EncodedPackagingBase & {
  'id': number,
  'displayName': string,
  'packType': string,
  'itemPackagingId': number,
  'itemCount': number,
  'itemDisplayName': string,
  'totalUnits': number,
  'variant'?: EncodedPackagingVariant,
};
export const PackagingMonopackContainer: t.Type<PackagingMonopackContainer, EncodedPackagingMonopackContainer> = t.recursion('PackagingMonopackContainer', () => (
  t.intersection([
    PackagingBase,
    t.exact(t.intersection([t.type({
      'id': t.number,
      'displayName': t.string,
      'packType': CodegenEnumClosed({
        'monopack': null,
      }, 'PackagingMonopackContainer'),
      'itemPackagingId': t.number,
      'itemCount': t.number,
      'itemDisplayName': t.string,
      'totalUnits': t.number,
    }), t.partial({
      'variant': PackagingVariant,
    })]), 'PackagingMonopackContainer')
  ], 'PackagingMonopackContainer')
));


export type PackagingMonopackContainerPatchAdd = PackagingPatchBase & {
  /** When updating packages on a retail unit, they sometimes need to be able to refer to other packages that haven't yet been created and therefore don't have an id yet.
  When adding new packages, set their referenceKey to a unique-within-that-set string and then use that as a reference from within other packages. */
  'referenceKey': string,
  /** itemPackagingReferenceKey can either refer to the referenceKey of an uncreated package, or the real id of a pre-existing package */
  'itemPackagingReferenceKey': OneOfOpenValues<number | string>,
};
export type EncodedPackagingMonopackContainerPatchAdd = EncodedPackagingPatchBase & {
  'referenceKey': string,
  'itemPackagingReferenceKey': OneOfOpenEncodedValues<number | string>,
};
export const PackagingMonopackContainerPatchAdd: t.Type<PackagingMonopackContainerPatchAdd, EncodedPackagingMonopackContainerPatchAdd> = t.recursion('PackagingMonopackContainerPatchAdd', () => (
  t.intersection([
    PackagingPatchBase,
    t.exact(t.intersection([t.type({
      'referenceKey': t.string,
      'itemPackagingReferenceKey': OneOfOpen([t.number, t.string]),
    }), t.partial({
      
    })]), 'PackagingMonopackContainerPatchAdd')
  ], 'PackagingMonopackContainerPatchAdd')
));


export type PackagingVariantAddProperty = {
  'variant'?: PackagingVariantAdd,
};
export type EncodedPackagingVariantAddProperty = {
  'variant'?: EncodedPackagingVariantAdd,
};
export const PackagingVariantAddProperty: t.Type<PackagingVariantAddProperty, EncodedPackagingVariantAddProperty> = t.recursion('PackagingVariantAddProperty', () => (
  t.exact(t.intersection([t.type({
    
  }), t.partial({
    'variant': PackagingVariantAdd,
  })]), 'PackagingVariantAddProperty')
));


export type AdminPackagingVariantAddProperty = {
  'variant'?: AdminPackagingVariantAdd,
};
export type EncodedAdminPackagingVariantAddProperty = {
  'variant'?: EncodedAdminPackagingVariantAdd,
};
export const AdminPackagingVariantAddProperty: t.Type<AdminPackagingVariantAddProperty, EncodedAdminPackagingVariantAddProperty> = t.recursion('AdminPackagingVariantAddProperty', () => (
  t.exact(t.intersection([t.type({
    
  }), t.partial({
    'variant': AdminPackagingVariantAdd,
  })]), 'AdminPackagingVariantAddProperty')
));


export type PackagingMonopackContainerPatchUpdate = PackagingPatchBase & {
  'id': number,
  /** itemPackagingReferenceKey can either refer to the referenceKey of an uncreated package, or the real id of a pre-existing package */
  'itemPackagingReferenceKey'?: OneOfOpenValues<number | string>,
};
export type EncodedPackagingMonopackContainerPatchUpdate = EncodedPackagingPatchBase & {
  'id': number,
  'itemPackagingReferenceKey'?: OneOfOpenEncodedValues<number | string>,
};
export const PackagingMonopackContainerPatchUpdate: t.Type<PackagingMonopackContainerPatchUpdate, EncodedPackagingMonopackContainerPatchUpdate> = t.recursion('PackagingMonopackContainerPatchUpdate', () => (
  t.intersection([
    PackagingPatchBase,
    t.exact(t.intersection([t.type({
      'id': t.number,
    }), t.partial({
      'itemPackagingReferenceKey': OneOfOpen([t.number, t.string]),
    })]), 'PackagingMonopackContainerPatchUpdate')
  ], 'PackagingMonopackContainerPatchUpdate')
));


export type PackagingVariantPatchProperty = {
  'variant'?: PackagingVariantPatch,
};
export type EncodedPackagingVariantPatchProperty = {
  'variant'?: EncodedPackagingVariantPatch,
};
export const PackagingVariantPatchProperty: t.Type<PackagingVariantPatchProperty, EncodedPackagingVariantPatchProperty> = t.recursion('PackagingVariantPatchProperty', () => (
  t.exact(t.intersection([t.type({
    
  }), t.partial({
    'variant': PackagingVariantPatch,
  })]), 'PackagingVariantPatchProperty')
));


export type AdminPackagingVariantPatchProperty = {
  'variant'?: AdminPackagingVariantPatch,
};
export type EncodedAdminPackagingVariantPatchProperty = {
  'variant'?: EncodedAdminPackagingVariantPatch,
};
export const AdminPackagingVariantPatchProperty: t.Type<AdminPackagingVariantPatchProperty, EncodedAdminPackagingVariantPatchProperty> = t.recursion('AdminPackagingVariantPatchProperty', () => (
  t.exact(t.intersection([t.type({
    
  }), t.partial({
    'variant': AdminPackagingVariantPatch,
  })]), 'AdminPackagingVariantPatchProperty')
));


export type PackagingMonopackContainerItemCountPatchAdd = {
  'itemCount': number,
};
export type EncodedPackagingMonopackContainerItemCountPatchAdd = {
  'itemCount': number,
};
export const PackagingMonopackContainerItemCountPatchAdd: t.Type<PackagingMonopackContainerItemCountPatchAdd, EncodedPackagingMonopackContainerItemCountPatchAdd> = t.recursion('PackagingMonopackContainerItemCountPatchAdd', () => (
  t.exact(t.intersection([t.type({
    'itemCount': t.number,
  }), t.partial({
    
  })]), 'PackagingMonopackContainerItemCountPatchAdd')
));


export type PackagingMonopackContainerItemCountPatchUpdate = {
  'itemCount'?: number,
};
export type EncodedPackagingMonopackContainerItemCountPatchUpdate = {
  'itemCount'?: number,
};
export const PackagingMonopackContainerItemCountPatchUpdate: t.Type<PackagingMonopackContainerItemCountPatchUpdate, EncodedPackagingMonopackContainerItemCountPatchUpdate> = t.recursion('PackagingMonopackContainerItemCountPatchUpdate', () => (
  t.exact(t.intersection([t.type({
    
  }), t.partial({
    'itemCount': t.number,
  })]), 'PackagingMonopackContainerItemCountPatchUpdate')
));


export type PackagingUnit = PackagingBase & {
  'id': number,
  'displayName': string,
  'level': 'unit',
  'totalUnits': number,
  'variant'?: PackagingVariant,
};
export type EncodedPackagingUnit = EncodedPackagingBase & {
  'id': number,
  'displayName': string,
  'level': string,
  'totalUnits': number,
  'variant'?: EncodedPackagingVariant,
};
export const PackagingUnit: t.Type<PackagingUnit, EncodedPackagingUnit> = t.recursion('PackagingUnit', () => (
  t.intersection([
    PackagingBase,
    t.exact(t.intersection([t.type({
      'id': t.number,
      'displayName': t.string,
      'level': CodegenEnumClosed({
        'unit': null,
      }, 'PackagingUnit'),
      'totalUnits': t.number,
    }), t.partial({
      'variant': PackagingVariant,
    })]), 'PackagingUnit')
  ], 'PackagingUnit')
));


export type PackagingUnitPatchBase = PackagingPatchBase & {
  'id': number,
  'level': 'unit',
};
export type EncodedPackagingUnitPatchBase = EncodedPackagingPatchBase & {
  'id': number,
  'level': string,
};
export const PackagingUnitPatchBase: t.Type<PackagingUnitPatchBase, EncodedPackagingUnitPatchBase> = t.recursion('PackagingUnitPatchBase', () => (
  t.intersection([
    PackagingPatchBase,
    t.exact(t.intersection([t.type({
      'id': t.number,
      'level': CodegenEnumClosed({
        'unit': null,
      }, 'PackagingUnitPatchBase'),
    }), t.partial({
      
    })]), 'PackagingUnitPatchBase')
  ], 'PackagingUnitPatchBase')
));


export type PackagingUnitPatch = PackagingUnitPatchBase & PackagingVariantPatchProperty;
export type EncodedPackagingUnitPatch = EncodedPackagingUnitPatchBase & EncodedPackagingVariantPatchProperty;
export const PackagingUnitPatch: t.Type<PackagingUnitPatch, EncodedPackagingUnitPatch> = t.recursion('PackagingUnitPatch', () => (
  t.intersection([
    PackagingUnitPatchBase,
    PackagingVariantPatchProperty
  ], 'PackagingUnitPatch')
));


export type AdminPackagingUnitPatch = PackagingUnitPatchBase & AdminPackagingVariantPatchProperty;
export type EncodedAdminPackagingUnitPatch = EncodedPackagingUnitPatchBase & EncodedAdminPackagingVariantPatchProperty;
export const AdminPackagingUnitPatch: t.Type<AdminPackagingUnitPatch, EncodedAdminPackagingUnitPatch> = t.recursion('AdminPackagingUnitPatch', () => (
  t.intersection([
    PackagingUnitPatchBase,
    AdminPackagingVariantPatchProperty
  ], 'AdminPackagingUnitPatch')
));


export type AdminPackagingUnitCreate = PackagingPatchBase & AdminPackagingVariantAddProperty & {
  'level': 'unit',
};
export type EncodedAdminPackagingUnitCreate = EncodedPackagingPatchBase & EncodedAdminPackagingVariantAddProperty & {
  'level': string,
};
export const AdminPackagingUnitCreate: t.Type<AdminPackagingUnitCreate, EncodedAdminPackagingUnitCreate> = t.recursion('AdminPackagingUnitCreate', () => (
  t.intersection([
    PackagingPatchBase,
    AdminPackagingVariantAddProperty,
    t.exact(t.intersection([t.type({
      'level': CodegenEnumClosed({
        'unit': null,
      }, 'AdminPackagingUnitCreate'),
    }), t.partial({
      
    })]), 'AdminPackagingUnitCreate')
  ], 'AdminPackagingUnitCreate')
));


export type PackagingCaseLevel = {
  'level': 'case',
};
export type EncodedPackagingCaseLevel = {
  'level': string,
};
export const PackagingCaseLevel: t.Type<PackagingCaseLevel, EncodedPackagingCaseLevel> = t.recursion('PackagingCaseLevel', () => (
  t.exact(t.intersection([t.type({
    'level': CodegenEnumClosed({
      'case': null,
    }, 'PackagingCaseLevel'),
  }), t.partial({
    
  })]), 'PackagingCaseLevel')
));


export type PackagingMasterCaseLevel = {
  'level': 'master_case',
};
export type EncodedPackagingMasterCaseLevel = {
  'level': string,
};
export const PackagingMasterCaseLevel: t.Type<PackagingMasterCaseLevel, EncodedPackagingMasterCaseLevel> = t.recursion('PackagingMasterCaseLevel', () => (
  t.exact(t.intersection([t.type({
    'level': CodegenEnumClosed({
      'master_case': null,
    }, 'PackagingMasterCaseLevel'),
  }), t.partial({
    
  })]), 'PackagingMasterCaseLevel')
));


export type PackagingPalletLevel = {
  'level': CodegenEnumOpenValues<'pallet'>,
};
export type EncodedPackagingPalletLevel = {
  'level': string,
};
export const PackagingPalletLevel: t.Type<PackagingPalletLevel, EncodedPackagingPalletLevel> = t.recursion('PackagingPalletLevel', () => (
  t.exact(t.intersection([t.type({
    'level': CodegenEnumOpen({
      'pallet': null,
    }, 'PackagingPalletLevel'),
  }), t.partial({
    
  })]), 'PackagingPalletLevel')
));


export type PackagingCase = PackagingMonopackContainer & PackagingCaseLevel;
export type EncodedPackagingCase = EncodedPackagingMonopackContainer & EncodedPackagingCaseLevel;
export const PackagingCase: t.Type<PackagingCase, EncodedPackagingCase> = t.recursion('PackagingCase', () => (
  t.intersection([
    PackagingMonopackContainer,
    PackagingCaseLevel
  ], 'PackagingCase')
));


export type PackagingCasePatchAdd = PackagingMonopackContainerPatchAdd & PackagingMonopackContainerItemCountPatchAdd & PackagingCaseLevel & PackagingVariantAddProperty;
export type EncodedPackagingCasePatchAdd = EncodedPackagingMonopackContainerPatchAdd & EncodedPackagingMonopackContainerItemCountPatchAdd & EncodedPackagingCaseLevel & EncodedPackagingVariantAddProperty;
export const PackagingCasePatchAdd: t.Type<PackagingCasePatchAdd, EncodedPackagingCasePatchAdd> = t.recursion('PackagingCasePatchAdd', () => (
  t.intersection([
    PackagingMonopackContainerPatchAdd,
    PackagingMonopackContainerItemCountPatchAdd,
    PackagingCaseLevel,
    PackagingVariantAddProperty
  ], 'PackagingCasePatchAdd')
));


export type PackagingCasePatchUpdate = PackagingMonopackContainerPatchUpdate & PackagingMonopackContainerItemCountPatchUpdate & PackagingCaseLevel & PackagingVariantPatchProperty;
export type EncodedPackagingCasePatchUpdate = EncodedPackagingMonopackContainerPatchUpdate & EncodedPackagingMonopackContainerItemCountPatchUpdate & EncodedPackagingCaseLevel & EncodedPackagingVariantPatchProperty;
export const PackagingCasePatchUpdate: t.Type<PackagingCasePatchUpdate, EncodedPackagingCasePatchUpdate> = t.recursion('PackagingCasePatchUpdate', () => (
  t.intersection([
    PackagingMonopackContainerPatchUpdate,
    PackagingMonopackContainerItemCountPatchUpdate,
    PackagingCaseLevel,
    PackagingVariantPatchProperty
  ], 'PackagingCasePatchUpdate')
));


export type AdminPackagingMonopackCaseCreate = PackagingPatchBase & PackagingMonopackContainerItemCountPatchAdd & PackagingCaseLevel & AdminPackagingVariantAddProperty & {
  /** When updating packages on a retail unit, they sometimes need to be able to refer to other packages that haven't yet been created and therefore don't have an id yet.
  When adding new packages, set their referenceKey to a unique-within-that-set string and then use that as a reference from within other packages. */
  'referenceKey': string,
};
export type EncodedAdminPackagingMonopackCaseCreate = EncodedPackagingPatchBase & EncodedPackagingMonopackContainerItemCountPatchAdd & EncodedPackagingCaseLevel & EncodedAdminPackagingVariantAddProperty & {
  'referenceKey': string,
};
export const AdminPackagingMonopackCaseCreate: t.Type<AdminPackagingMonopackCaseCreate, EncodedAdminPackagingMonopackCaseCreate> = t.recursion('AdminPackagingMonopackCaseCreate', () => (
  t.intersection([
    PackagingPatchBase,
    PackagingMonopackContainerItemCountPatchAdd,
    PackagingCaseLevel,
    AdminPackagingVariantAddProperty,
    t.exact(t.intersection([t.type({
      'referenceKey': t.string,
    }), t.partial({
      
    })]), 'AdminPackagingMonopackCaseCreate')
  ], 'AdminPackagingMonopackCaseCreate')
));


export type AdminPackagingCasePatchAdd = PackagingMonopackContainerPatchAdd & PackagingMonopackContainerItemCountPatchAdd & PackagingCaseLevel & AdminPackagingVariantAddProperty;
export type EncodedAdminPackagingCasePatchAdd = EncodedPackagingMonopackContainerPatchAdd & EncodedPackagingMonopackContainerItemCountPatchAdd & EncodedPackagingCaseLevel & EncodedAdminPackagingVariantAddProperty;
export const AdminPackagingCasePatchAdd: t.Type<AdminPackagingCasePatchAdd, EncodedAdminPackagingCasePatchAdd> = t.recursion('AdminPackagingCasePatchAdd', () => (
  t.intersection([
    PackagingMonopackContainerPatchAdd,
    PackagingMonopackContainerItemCountPatchAdd,
    PackagingCaseLevel,
    AdminPackagingVariantAddProperty
  ], 'AdminPackagingCasePatchAdd')
));


export type AdminPackagingCasePatchUpdate = PackagingMonopackContainerPatchUpdate & PackagingMonopackContainerItemCountPatchUpdate & PackagingCaseLevel & AdminPackagingVariantPatchProperty;
export type EncodedAdminPackagingCasePatchUpdate = EncodedPackagingMonopackContainerPatchUpdate & EncodedPackagingMonopackContainerItemCountPatchUpdate & EncodedPackagingCaseLevel & EncodedAdminPackagingVariantPatchProperty;
export const AdminPackagingCasePatchUpdate: t.Type<AdminPackagingCasePatchUpdate, EncodedAdminPackagingCasePatchUpdate> = t.recursion('AdminPackagingCasePatchUpdate', () => (
  t.intersection([
    PackagingMonopackContainerPatchUpdate,
    PackagingMonopackContainerItemCountPatchUpdate,
    PackagingCaseLevel,
    AdminPackagingVariantPatchProperty
  ], 'AdminPackagingCasePatchUpdate')
));


export type PackagingMasterCase = PackagingMonopackContainer & PackagingMasterCaseLevel;
export type EncodedPackagingMasterCase = EncodedPackagingMonopackContainer & EncodedPackagingMasterCaseLevel;
export const PackagingMasterCase: t.Type<PackagingMasterCase, EncodedPackagingMasterCase> = t.recursion('PackagingMasterCase', () => (
  t.intersection([
    PackagingMonopackContainer,
    PackagingMasterCaseLevel
  ], 'PackagingMasterCase')
));


export type PackagingMasterCasePatchAdd = PackagingMonopackContainerPatchAdd & PackagingMonopackContainerItemCountPatchAdd & PackagingMasterCaseLevel & PackagingVariantAddProperty;
export type EncodedPackagingMasterCasePatchAdd = EncodedPackagingMonopackContainerPatchAdd & EncodedPackagingMonopackContainerItemCountPatchAdd & EncodedPackagingMasterCaseLevel & EncodedPackagingVariantAddProperty;
export const PackagingMasterCasePatchAdd: t.Type<PackagingMasterCasePatchAdd, EncodedPackagingMasterCasePatchAdd> = t.recursion('PackagingMasterCasePatchAdd', () => (
  t.intersection([
    PackagingMonopackContainerPatchAdd,
    PackagingMonopackContainerItemCountPatchAdd,
    PackagingMasterCaseLevel,
    PackagingVariantAddProperty
  ], 'PackagingMasterCasePatchAdd')
));


export type PackagingMasterCasePatchUpdate = PackagingMonopackContainerPatchUpdate & PackagingMonopackContainerItemCountPatchUpdate & PackagingMasterCaseLevel & PackagingVariantPatchProperty;
export type EncodedPackagingMasterCasePatchUpdate = EncodedPackagingMonopackContainerPatchUpdate & EncodedPackagingMonopackContainerItemCountPatchUpdate & EncodedPackagingMasterCaseLevel & EncodedPackagingVariantPatchProperty;
export const PackagingMasterCasePatchUpdate: t.Type<PackagingMasterCasePatchUpdate, EncodedPackagingMasterCasePatchUpdate> = t.recursion('PackagingMasterCasePatchUpdate', () => (
  t.intersection([
    PackagingMonopackContainerPatchUpdate,
    PackagingMonopackContainerItemCountPatchUpdate,
    PackagingMasterCaseLevel,
    PackagingVariantPatchProperty
  ], 'PackagingMasterCasePatchUpdate')
));


export type AdminPackagingMasterCasePatchAdd = PackagingMonopackContainerPatchAdd & PackagingMonopackContainerItemCountPatchAdd & PackagingMasterCaseLevel & AdminPackagingVariantAddProperty;
export type EncodedAdminPackagingMasterCasePatchAdd = EncodedPackagingMonopackContainerPatchAdd & EncodedPackagingMonopackContainerItemCountPatchAdd & EncodedPackagingMasterCaseLevel & EncodedAdminPackagingVariantAddProperty;
export const AdminPackagingMasterCasePatchAdd: t.Type<AdminPackagingMasterCasePatchAdd, EncodedAdminPackagingMasterCasePatchAdd> = t.recursion('AdminPackagingMasterCasePatchAdd', () => (
  t.intersection([
    PackagingMonopackContainerPatchAdd,
    PackagingMonopackContainerItemCountPatchAdd,
    PackagingMasterCaseLevel,
    AdminPackagingVariantAddProperty
  ], 'AdminPackagingMasterCasePatchAdd')
));


export type AdminPackagingMasterCasePatchUpdate = PackagingMonopackContainerPatchUpdate & PackagingMonopackContainerItemCountPatchUpdate & PackagingMasterCaseLevel & AdminPackagingVariantPatchProperty;
export type EncodedAdminPackagingMasterCasePatchUpdate = EncodedPackagingMonopackContainerPatchUpdate & EncodedPackagingMonopackContainerItemCountPatchUpdate & EncodedPackagingMasterCaseLevel & EncodedAdminPackagingVariantPatchProperty;
export const AdminPackagingMasterCasePatchUpdate: t.Type<AdminPackagingMasterCasePatchUpdate, EncodedAdminPackagingMasterCasePatchUpdate> = t.recursion('AdminPackagingMasterCasePatchUpdate', () => (
  t.intersection([
    PackagingMonopackContainerPatchUpdate,
    PackagingMonopackContainerItemCountPatchUpdate,
    PackagingMasterCaseLevel,
    AdminPackagingVariantPatchProperty
  ], 'AdminPackagingMasterCasePatchUpdate')
));


export type PackagingPallet = PackagingMonopackContainer & PackagingPalletLevel & {
  /** Also known as HI */
  'layerCount': number,
  /** Also known as TI */
  'itemsPerLayer': number,
};
export type EncodedPackagingPallet = EncodedPackagingMonopackContainer & EncodedPackagingPalletLevel & {
  'layerCount': number,
  'itemsPerLayer': number,
};
export const PackagingPallet: t.Type<PackagingPallet, EncodedPackagingPallet> = t.recursion('PackagingPallet', () => (
  t.intersection([
    PackagingMonopackContainer,
    PackagingPalletLevel,
    t.exact(t.intersection([t.type({
      'layerCount': t.number,
      'itemsPerLayer': t.number,
    }), t.partial({
      
    })]), 'PackagingPallet')
  ], 'PackagingPallet')
));


export type PackagingPalletPatchAddBase = PackagingMonopackContainerPatchAdd & PackagingPalletLevel & {
  'layerCount': number,
  'itemsPerLayer': number,
};
export type EncodedPackagingPalletPatchAddBase = EncodedPackagingMonopackContainerPatchAdd & EncodedPackagingPalletLevel & {
  'layerCount': number,
  'itemsPerLayer': number,
};
export const PackagingPalletPatchAddBase: t.Type<PackagingPalletPatchAddBase, EncodedPackagingPalletPatchAddBase> = t.recursion('PackagingPalletPatchAddBase', () => (
  t.intersection([
    PackagingMonopackContainerPatchAdd,
    PackagingPalletLevel,
    t.exact(t.intersection([t.type({
      'layerCount': t.number,
      'itemsPerLayer': t.number,
    }), t.partial({
      
    })]), 'PackagingPalletPatchAddBase')
  ], 'PackagingPalletPatchAddBase')
));


export type PackagingPalletPatchAdd = PackagingPalletPatchAddBase & PackagingVariantAddProperty;
export type EncodedPackagingPalletPatchAdd = EncodedPackagingPalletPatchAddBase & EncodedPackagingVariantAddProperty;
export const PackagingPalletPatchAdd: t.Type<PackagingPalletPatchAdd, EncodedPackagingPalletPatchAdd> = t.recursion('PackagingPalletPatchAdd', () => (
  t.intersection([
    PackagingPalletPatchAddBase,
    PackagingVariantAddProperty
  ], 'PackagingPalletPatchAdd')
));


export type AdminPackagingPalletPatchAdd = PackagingPalletPatchAddBase & AdminPackagingVariantAddProperty;
export type EncodedAdminPackagingPalletPatchAdd = EncodedPackagingPalletPatchAddBase & EncodedAdminPackagingVariantAddProperty;
export const AdminPackagingPalletPatchAdd: t.Type<AdminPackagingPalletPatchAdd, EncodedAdminPackagingPalletPatchAdd> = t.recursion('AdminPackagingPalletPatchAdd', () => (
  t.intersection([
    PackagingPalletPatchAddBase,
    AdminPackagingVariantAddProperty
  ], 'AdminPackagingPalletPatchAdd')
));


export type PackagingPalletPatchUpdateBase = PackagingMonopackContainerPatchUpdate & PackagingPalletLevel & {
  'layerCount'?: number,
  'itemsPerLayer'?: number,
};
export type EncodedPackagingPalletPatchUpdateBase = EncodedPackagingMonopackContainerPatchUpdate & EncodedPackagingPalletLevel & {
  'layerCount'?: number,
  'itemsPerLayer'?: number,
};
export const PackagingPalletPatchUpdateBase: t.Type<PackagingPalletPatchUpdateBase, EncodedPackagingPalletPatchUpdateBase> = t.recursion('PackagingPalletPatchUpdateBase', () => (
  t.intersection([
    PackagingMonopackContainerPatchUpdate,
    PackagingPalletLevel,
    t.exact(t.intersection([t.type({
      
    }), t.partial({
      'layerCount': t.number,
      'itemsPerLayer': t.number,
    })]), 'PackagingPalletPatchUpdateBase')
  ], 'PackagingPalletPatchUpdateBase')
));


export type PackagingPalletPatchUpdate = PackagingPalletPatchUpdateBase & PackagingVariantPatchProperty;
export type EncodedPackagingPalletPatchUpdate = EncodedPackagingPalletPatchUpdateBase & EncodedPackagingVariantPatchProperty;
export const PackagingPalletPatchUpdate: t.Type<PackagingPalletPatchUpdate, EncodedPackagingPalletPatchUpdate> = t.recursion('PackagingPalletPatchUpdate', () => (
  t.intersection([
    PackagingPalletPatchUpdateBase,
    PackagingVariantPatchProperty
  ], 'PackagingPalletPatchUpdate')
));


export type AdminPackagingPalletPatchUpdate = PackagingPalletPatchUpdateBase & AdminPackagingVariantPatchProperty;
export type EncodedAdminPackagingPalletPatchUpdate = EncodedPackagingPalletPatchUpdateBase & EncodedAdminPackagingVariantPatchProperty;
export const AdminPackagingPalletPatchUpdate: t.Type<AdminPackagingPalletPatchUpdate, EncodedAdminPackagingPalletPatchUpdate> = t.recursion('AdminPackagingPalletPatchUpdate', () => (
  t.intersection([
    PackagingPalletPatchUpdateBase,
    AdminPackagingVariantPatchProperty
  ], 'AdminPackagingPalletPatchUpdate')
));


export type Packaging = OneOfOpenValues<PackagingUnit | PackagingCase | PackagingMasterCase | PackagingPallet>;
export type EncodedPackaging = OneOfOpenEncodedValues<EncodedPackagingUnit | EncodedPackagingCase | EncodedPackagingMasterCase | EncodedPackagingPallet>;
export const Packaging: t.Type<Packaging, EncodedPackaging> = t.recursion('Packaging', () => (
  OneOfOpen([PackagingUnit, PackagingCase, PackagingMasterCase, PackagingPallet])
));


export type PackagingPatchAdd = OneOfOpenValues<PackagingCasePatchAdd | PackagingMasterCasePatchAdd | PackagingPalletPatchAdd>;
export type EncodedPackagingPatchAdd = OneOfOpenEncodedValues<EncodedPackagingCasePatchAdd | EncodedPackagingMasterCasePatchAdd | EncodedPackagingPalletPatchAdd>;
export const PackagingPatchAdd: t.Type<PackagingPatchAdd, EncodedPackagingPatchAdd> = t.recursion('PackagingPatchAdd', () => (
  OneOfOpen([PackagingCasePatchAdd, PackagingMasterCasePatchAdd, PackagingPalletPatchAdd])
));


export type AdminPackagingPatchAdd = OneOfOpenValues<AdminPackagingCasePatchAdd | AdminPackagingMasterCasePatchAdd | AdminPackagingPalletPatchAdd>;
export type EncodedAdminPackagingPatchAdd = OneOfOpenEncodedValues<EncodedAdminPackagingCasePatchAdd | EncodedAdminPackagingMasterCasePatchAdd | EncodedAdminPackagingPalletPatchAdd>;
export const AdminPackagingPatchAdd: t.Type<AdminPackagingPatchAdd, EncodedAdminPackagingPatchAdd> = t.recursion('AdminPackagingPatchAdd', () => (
  OneOfOpen([AdminPackagingCasePatchAdd, AdminPackagingMasterCasePatchAdd, AdminPackagingPalletPatchAdd])
));


export type AdminPackagingCreate = OneOfOpenValues<AdminPackagingUnitCreate | AdminPackagingMonopackCaseCreate | AdminPackagingMasterCasePatchAdd | AdminPackagingPalletPatchAdd>;
export type EncodedAdminPackagingCreate = OneOfOpenEncodedValues<EncodedAdminPackagingUnitCreate | EncodedAdminPackagingMonopackCaseCreate | EncodedAdminPackagingMasterCasePatchAdd | EncodedAdminPackagingPalletPatchAdd>;
export const AdminPackagingCreate: t.Type<AdminPackagingCreate, EncodedAdminPackagingCreate> = t.recursion('AdminPackagingCreate', () => (
  OneOfOpen([AdminPackagingUnitCreate, AdminPackagingMonopackCaseCreate, AdminPackagingMasterCasePatchAdd, AdminPackagingPalletPatchAdd])
));


export type PackagingPatchUpdate = OneOfOpenValues<PackagingUnitPatch | PackagingCasePatchUpdate | PackagingMasterCasePatchUpdate | PackagingPalletPatchUpdate>;
export type EncodedPackagingPatchUpdate = OneOfOpenEncodedValues<EncodedPackagingUnitPatch | EncodedPackagingCasePatchUpdate | EncodedPackagingMasterCasePatchUpdate | EncodedPackagingPalletPatchUpdate>;
export const PackagingPatchUpdate: t.Type<PackagingPatchUpdate, EncodedPackagingPatchUpdate> = t.recursion('PackagingPatchUpdate', () => (
  OneOfOpen([PackagingUnitPatch, PackagingCasePatchUpdate, PackagingMasterCasePatchUpdate, PackagingPalletPatchUpdate])
));


export type AdminPackagingPatchUpdate = OneOfOpenValues<AdminPackagingUnitPatch | AdminPackagingCasePatchUpdate | AdminPackagingMasterCasePatchUpdate | AdminPackagingPalletPatchUpdate>;
export type EncodedAdminPackagingPatchUpdate = OneOfOpenEncodedValues<EncodedAdminPackagingUnitPatch | EncodedAdminPackagingCasePatchUpdate | EncodedAdminPackagingMasterCasePatchUpdate | EncodedAdminPackagingPalletPatchUpdate>;
export const AdminPackagingPatchUpdate: t.Type<AdminPackagingPatchUpdate, EncodedAdminPackagingPatchUpdate> = t.recursion('AdminPackagingPatchUpdate', () => (
  OneOfOpen([AdminPackagingUnitPatch, AdminPackagingCasePatchUpdate, AdminPackagingMasterCasePatchUpdate, AdminPackagingPalletPatchUpdate])
));


export type RetailUnit = {
  'id': number,
  'updatedAt': Date,
  'name'?: string,
  'fullName': string,
  'sellerId': number,
  'description'?: string,
  'ingredients'?: string,
  /** This variant is incapable of having ingredients and should be exempt from any logic requiring a variant to have them. */
  'ingredientsExempt'?: boolean,
  'ingredientsOnFTLList'?: boolean,
  'eachName'?: string,
  'eachNamePlural'?: string,
  /** Numeric size of the unit. */
  'eachSize'?: BigNumber,
  /** Units of the size of the unit */
  'eachSizeUnit'?: ProductSizeUnit,
  /** The number of items included in the each. e.g. 12 slices per pack of bacon. */
  'eachPieceCount'?: number,
  /** Name for the unit of the items included in the each. e.g. "Slice" */
  'eachPieceName'?: string,
  /** Plural name for the unit of the items included in the each. e.g. "Slices" */
  'eachPieceNamePlural'?: string,
  'images'?: Array<ProductImage>,
  /** List of allergens objects */
  'allergens'?: Array<Allergen>,
  'storage'?: Array<ProductStorage>,
  'msrp'?: BigNumber,
  'packagings': Array<Packaging>,
  'monopackProductId': number,
  'upcExempt'?: boolean,
  'isArchived'?: boolean,
  'status': RetailUnitStatus,
  'isBestseller': boolean,
  'isPeggable': BooleanOrUnknown,
};
export type EncodedRetailUnit = {
  'id': number,
  'updatedAt': string,
  'name'?: string,
  'fullName': string,
  'sellerId': number,
  'description'?: string,
  'ingredients'?: string,
  'ingredientsExempt'?: boolean,
  'ingredientsOnFTLList'?: boolean,
  'eachName'?: string,
  'eachNamePlural'?: string,
  'eachSize'?: string,
  'eachSizeUnit'?: EncodedProductSizeUnit,
  'eachPieceCount'?: number,
  'eachPieceName'?: string,
  'eachPieceNamePlural'?: string,
  'images'?: Array<EncodedProductImage>,
  'allergens'?: Array<EncodedAllergen>,
  'storage'?: Array<EncodedProductStorage>,
  'msrp'?: string,
  'packagings': Array<EncodedPackaging>,
  'monopackProductId': number,
  'upcExempt'?: boolean,
  'isArchived'?: boolean,
  'status': EncodedRetailUnitStatus,
  'isBestseller': boolean,
  'isPeggable': EncodedBooleanOrUnknown,
};
export const RetailUnit: t.Type<RetailUnit, EncodedRetailUnit> = t.recursion('RetailUnit', () => (
  t.exact(t.intersection([t.type({
    'id': t.number,
    'updatedAt': DateFromISOString,
    'fullName': t.string,
    'sellerId': t.number,
    'packagings': t.array(Packaging),
    'monopackProductId': t.number,
    'status': RetailUnitStatus,
    'isBestseller': t.boolean,
    'isPeggable': BooleanOrUnknown,
  }), t.partial({
    'name': t.string,
    'description': t.string,
    'ingredients': t.string,
    'ingredientsExempt': t.boolean,
    'ingredientsOnFTLList': t.boolean,
    'eachName': t.string,
    'eachNamePlural': t.string,
    'eachSize': NumberFromString,
    'eachSizeUnit': ProductSizeUnit,
    'eachPieceCount': t.number,
    'eachPieceName': t.string,
    'eachPieceNamePlural': t.string,
    'images': t.array(ProductImage),
    'allergens': t.array(Allergen),
    'storage': t.array(ProductStorage),
    'msrp': NumberFromString,
    'upcExempt': t.boolean,
    'isArchived': t.boolean,
  })]), 'RetailUnit')
));


export type RetailUnitStatus = CodegenEnumOpenValues<'draft' | 'published' | 'archived'>;
export type EncodedRetailUnitStatus = string;
export const RetailUnitStatus = CodegenEnumOpen({
  'draft': null,
  'published': null,
  'archived': null,
}, 'RetailUnitStatus');
export const RetailUnitStatusValues = ['draft', 'published', 'archived'] as const;

export type RetailUnitProduct = Product & {
  'retailUnits': Array<RetailUnit>,
};
export type EncodedRetailUnitProduct = EncodedProduct & {
  'retailUnits': Array<EncodedRetailUnit>,
};
export const RetailUnitProduct: t.Type<RetailUnitProduct, EncodedRetailUnitProduct> = t.recursion('RetailUnitProduct', () => (
  t.intersection([
    Product,
    t.exact(t.intersection([t.type({
      'retailUnits': t.array(RetailUnit),
    }), t.partial({
      
    })]), 'RetailUnitProduct')
  ], 'RetailUnitProduct')
));


export type RetailUnitProductPatch = {
  'id': number,
  'images': {
    'set'?: Array<ProductImage>,
    'add'?: Array<ProductImage>,
    /** The ids of the images to remove */
    'remove'?: Array<number>,
  },
};
export type EncodedRetailUnitProductPatch = {
  'id': number,
  'images': {
    'set'?: Array<EncodedProductImage>,
    'add'?: Array<EncodedProductImage>,
    'remove'?: Array<number>,
  },
};
export const RetailUnitProductPatch: t.Type<RetailUnitProductPatch, EncodedRetailUnitProductPatch> = t.recursion('RetailUnitProductPatch', () => (
  t.exact(t.intersection([t.type({
    'id': t.number,
    'images': t.exact(t.intersection([t.type({
      
    }), t.partial({
      'set': t.array(ProductImage),
      'add': t.array(ProductImage),
      'remove': t.array(t.number),
    })]), 'RetailUnitProductPatch'),
  }), t.partial({
    
  })]), 'RetailUnitProductPatch')
));


export type RetailUnitDietPatch = {
  'add'?: Array<DietCategory>,
  'remove'?: Array<DietCategory>,
};
export type EncodedRetailUnitDietPatch = {
  'add'?: Array<EncodedDietCategory>,
  'remove'?: Array<EncodedDietCategory>,
};
export const RetailUnitDietPatch: t.Type<RetailUnitDietPatch, EncodedRetailUnitDietPatch> = t.recursion('RetailUnitDietPatch', () => (
  t.exact(t.intersection([t.type({
    
  }), t.partial({
    'add': t.array(DietCategory),
    'remove': t.array(DietCategory),
  })]), 'RetailUnitDietPatch')
));


export type RetailUnitPublicCategory = {
  'slug': Slug,
  'name': string,
};
export type EncodedRetailUnitPublicCategory = {
  'slug': string,
  'name': string,
};
export const RetailUnitPublicCategory: t.Type<RetailUnitPublicCategory, EncodedRetailUnitPublicCategory> = t.recursion('RetailUnitPublicCategory', () => (
  t.exact(t.intersection([t.type({
    'slug': Slug,
    'name': t.string,
  }), t.partial({
    
  })]), 'RetailUnitPublicCategory')
));


export type RetailUnitPublicCategoryPatch = OneOfOpenValues<{
  'set': Array<Slug>,
} | {
  'add'?: Array<Slug>,
  'remove'?: Array<Slug>,
}>;
export type EncodedRetailUnitPublicCategoryPatch = OneOfOpenEncodedValues<{
  'set': Array<string>,
} | {
  'add'?: Array<string>,
  'remove'?: Array<string>,
}>;
export const RetailUnitPublicCategoryPatch: t.Type<RetailUnitPublicCategoryPatch, EncodedRetailUnitPublicCategoryPatch> = t.recursion('RetailUnitPublicCategoryPatch', () => (
  OneOfOpen([t.exact(t.intersection([t.type({
    'set': t.array(Slug),
  }), t.partial({
    
  })]), 'RetailUnitPublicCategoryPatch'), t.exact(t.intersection([t.type({
    
  }), t.partial({
    'add': t.array(Slug),
    'remove': t.array(Slug),
  })]), 'RetailUnitPublicCategoryPatch')])
));


/** Manages option values on all monopack variants within the retail unit */
export type RetailUnitOptionPatch = OneOfOpenValues<{
  'set': Array<number>,
} | {
  'add'?: Array<number>,
  'remove'?: Array<number>,
}>;
export type EncodedRetailUnitOptionPatch = OneOfOpenEncodedValues<{
  'set': Array<number>,
} | {
  'add'?: Array<number>,
  'remove'?: Array<number>,
}>;
export const RetailUnitOptionPatch: t.Type<RetailUnitOptionPatch, EncodedRetailUnitOptionPatch> = t.recursion('RetailUnitOptionPatch', () => (
  OneOfOpen([t.exact(t.intersection([t.type({
    'set': t.array(t.number),
  }), t.partial({
    
  })]), 'RetailUnitOptionPatch'), t.exact(t.intersection([t.type({
    
  }), t.partial({
    'add': t.array(t.number),
    'remove': t.array(t.number),
  })]), 'RetailUnitOptionPatch')])
));


export type RetailUnitPackagingPatch = {
  'add'?: Array<PackagingPatchAdd>,
  'update'?: Array<PackagingPatchUpdate>,
};
export type EncodedRetailUnitPackagingPatch = {
  'add'?: Array<EncodedPackagingPatchAdd>,
  'update'?: Array<EncodedPackagingPatchUpdate>,
};
export const RetailUnitPackagingPatch: t.Type<RetailUnitPackagingPatch, EncodedRetailUnitPackagingPatch> = t.recursion('RetailUnitPackagingPatch', () => (
  t.exact(t.intersection([t.type({
    
  }), t.partial({
    'add': t.array(PackagingPatchAdd),
    'update': t.array(PackagingPatchUpdate),
  })]), 'RetailUnitPackagingPatch')
));


export type AdminRetailUnitPackagingPatch = {
  'add'?: Array<AdminPackagingPatchAdd>,
  'update'?: Array<AdminPackagingPatchUpdate>,
};
export type EncodedAdminRetailUnitPackagingPatch = {
  'add'?: Array<EncodedAdminPackagingPatchAdd>,
  'update'?: Array<EncodedAdminPackagingPatchUpdate>,
};
export const AdminRetailUnitPackagingPatch: t.Type<AdminRetailUnitPackagingPatch, EncodedAdminRetailUnitPackagingPatch> = t.recursion('AdminRetailUnitPackagingPatch', () => (
  t.exact(t.intersection([t.type({
    
  }), t.partial({
    'add': t.array(AdminPackagingPatchAdd),
    'update': t.array(AdminPackagingPatchUpdate),
  })]), 'AdminRetailUnitPackagingPatch')
));


export type RetailUnitSubEntitiesPatch = {
  /** Images to associate with the retail unit */
  'images'?: {
    'set'?: Array<ProductImage>,
    'add'?: Array<ProductImage>,
    /** The ids of the images to remove */
    'remove'?: Array<number>,
  },
  /** List of allergens objects */
  'allergens'?: OneOfOpenValues<{
    'set': Array<string>,
  } | {
    'add'?: Array<string>,
    'remove'?: Array<string>,
  }>,
  'storage'?: Array<ProductStorage>,
};
export type EncodedRetailUnitSubEntitiesPatch = {
  'images'?: {
    'set'?: Array<EncodedProductImage>,
    'add'?: Array<EncodedProductImage>,
    'remove'?: Array<number>,
  },
  'allergens'?: OneOfOpenEncodedValues<{
    'set': Array<string>,
  } | {
    'add'?: Array<string>,
    'remove'?: Array<string>,
  }>,
  'storage'?: Array<EncodedProductStorage>,
};
export const RetailUnitSubEntitiesPatch: t.Type<RetailUnitSubEntitiesPatch, EncodedRetailUnitSubEntitiesPatch> = t.recursion('RetailUnitSubEntitiesPatch', () => (
  t.exact(t.intersection([t.type({
    
  }), t.partial({
    'images': t.exact(t.intersection([t.type({
      
    }), t.partial({
      'set': t.array(ProductImage),
      'add': t.array(ProductImage),
      'remove': t.array(t.number),
    })]), 'RetailUnitSubEntitiesPatch'),
    'allergens': OneOfOpen([t.exact(t.intersection([t.type({
      'set': t.array(t.string),
    }), t.partial({
      
    })]), 'RetailUnitSubEntitiesPatch'), t.exact(t.intersection([t.type({
      
    }), t.partial({
      'add': t.array(t.string),
      'remove': t.array(t.string),
    })]), 'RetailUnitSubEntitiesPatch')]),
    'storage': t.array(ProductStorage),
  })]), 'RetailUnitSubEntitiesPatch')
));


export type RetailUnitPatchBase = RetailUnitSubEntitiesPatch & {
  'description'?: null | string,
  'ingredients'?: null | string,
  /** This variant is incapable of having ingredients and should be exempt from any logic requiring a variant to have them. */
  'ingredientsExempt'?: boolean,
  'ingredientsOnFTLList'?: null | boolean,
  'eachName'?: null | string,
  'eachNamePlural'?: null | string,
  /** Numeric size of the unit. */
  'eachSize'?: null | BigNumber,
  /** Units of the size of the unit */
  'eachSizeUnit'?: null | ProductSizeUnit,
  /** The number of items included in the each. e.g. 12 slices per pack of bacon. */
  'eachPieceCount'?: null | number,
  /** Name for the unit of the items included in the each. e.g. "Slice" */
  'eachPieceName'?: null | string,
  /** Plural name for the unit of the items included in the each. e.g. "Slices" */
  'eachPieceNamePlural'?: null | string,
  'msrp'?: null | BigNumber,
  'isArchived'?: boolean,
  'isBestseller'?: boolean,
  'isPeggable'?: BooleanOrUnknown,
};
export type EncodedRetailUnitPatchBase = EncodedRetailUnitSubEntitiesPatch & {
  'description'?: null | string,
  'ingredients'?: null | string,
  'ingredientsExempt'?: boolean,
  'ingredientsOnFTLList'?: null | boolean,
  'eachName'?: null | string,
  'eachNamePlural'?: null | string,
  'eachSize'?: null | string,
  'eachSizeUnit'?: null | EncodedProductSizeUnit,
  'eachPieceCount'?: null | number,
  'eachPieceName'?: null | string,
  'eachPieceNamePlural'?: null | string,
  'msrp'?: null | string,
  'isArchived'?: boolean,
  'isBestseller'?: boolean,
  'isPeggable'?: EncodedBooleanOrUnknown,
};
export const RetailUnitPatchBase: t.Type<RetailUnitPatchBase, EncodedRetailUnitPatchBase> = t.recursion('RetailUnitPatchBase', () => (
  t.intersection([
    RetailUnitSubEntitiesPatch,
    t.exact(t.intersection([t.type({
      
    }), t.partial({
      'description': t.union([t.null, t.string]),
      'ingredients': t.union([t.null, t.string]),
      'ingredientsExempt': t.boolean,
      'ingredientsOnFTLList': t.union([t.null, t.boolean]),
      'eachName': t.union([t.null, t.string]),
      'eachNamePlural': t.union([t.null, t.string]),
      'eachSize': t.union([t.null, NumberFromString]),
      'eachSizeUnit': t.union([t.null, ProductSizeUnit]),
      'eachPieceCount': t.union([t.null, t.number]),
      'eachPieceName': t.union([t.null, t.string]),
      'eachPieceNamePlural': t.union([t.null, t.string]),
      'msrp': t.union([t.null, NumberFromString]),
      'isArchived': t.boolean,
      'isBestseller': t.boolean,
      'isPeggable': BooleanOrUnknown,
    })]), 'RetailUnitPatchBase')
  ], 'RetailUnitPatchBase')
));


export type RetailUnitPatch = RetailUnitPatchBase & {
  'packagings'?: RetailUnitPackagingPatch,
};
export type EncodedRetailUnitPatch = EncodedRetailUnitPatchBase & {
  'packagings'?: EncodedRetailUnitPackagingPatch,
};
export const RetailUnitPatch: t.Type<RetailUnitPatch, EncodedRetailUnitPatch> = t.recursion('RetailUnitPatch', () => (
  t.intersection([
    RetailUnitPatchBase,
    t.exact(t.intersection([t.type({
      
    }), t.partial({
      'packagings': RetailUnitPackagingPatch,
    })]), 'RetailUnitPatch')
  ], 'RetailUnitPatch')
));


export type AdminRetailUnitPatch = RetailUnitPatchBase & {
  'packagings'?: AdminRetailUnitPackagingPatch,
};
export type EncodedAdminRetailUnitPatch = EncodedRetailUnitPatchBase & {
  'packagings'?: EncodedAdminRetailUnitPackagingPatch,
};
export const AdminRetailUnitPatch: t.Type<AdminRetailUnitPatch, EncodedAdminRetailUnitPatch> = t.recursion('AdminRetailUnitPatch', () => (
  t.intersection([
    RetailUnitPatchBase,
    t.exact(t.intersection([t.type({
      
    }), t.partial({
      'packagings': AdminRetailUnitPackagingPatch,
    })]), 'AdminRetailUnitPatch')
  ], 'AdminRetailUnitPatch')
));


export type RetailUnitOptionValue = {
  'productOptionSetId': number,
  'optionName': string,
  'productOptionValueId'?: number,
  'valueName': string,
  'position': number,
};
export type EncodedRetailUnitOptionValue = {
  'productOptionSetId': number,
  'optionName': string,
  'productOptionValueId'?: number,
  'valueName': string,
  'position': number,
};
export const RetailUnitOptionValue: t.Type<RetailUnitOptionValue, EncodedRetailUnitOptionValue> = t.recursion('RetailUnitOptionValue', () => (
  t.exact(t.intersection([t.type({
    'productOptionSetId': t.number,
    'optionName': t.string,
    'valueName': t.string,
    'position': t.number,
  }), t.partial({
    'productOptionValueId': t.number,
  })]), 'RetailUnitOptionValue')
));


/** Creates a new Product Option Set with given Option Values */
export type ProductOptionActionAddOptionSet = {
  'action': 'addSet',
  /** Name of the Option Set to add */
  'name': string,
  /** Position of this set relative to other sets in this product */
  'position': number,
  /** Option Values to add to this set. Must have at least 1 */
  'values': Array<string>,
};
export type EncodedProductOptionActionAddOptionSet = {
  'action': string,
  'name': string,
  'position': number,
  'values': Array<string>,
};
export const ProductOptionActionAddOptionSet: t.Type<ProductOptionActionAddOptionSet, EncodedProductOptionActionAddOptionSet> = t.recursion('ProductOptionActionAddOptionSet', () => (
  t.exact(t.intersection([t.type({
    'action': CodegenEnumClosed({
      'addSet': null,
    }, 'ProductOptionActionAddOptionSet'),
    'name': t.string,
    'position': t.number,
    'values': t.array(t.string),
  }), t.partial({
    
  })]), 'ProductOptionActionAddOptionSet')
));


/** Modifies an existing Product Option Set's values by adding or removing */
export type ProductOptionActionModifyOptionValues = {
  'action': 'modifyValues',
  'optionSetName': string,
  /** Option Values to add */
  'add'?: Array<string>,
  /** Option Values to remove */
  'remove'?: Array<string>,
};
export type EncodedProductOptionActionModifyOptionValues = {
  'action': string,
  'optionSetName': string,
  'add'?: Array<string>,
  'remove'?: Array<string>,
};
export const ProductOptionActionModifyOptionValues: t.Type<ProductOptionActionModifyOptionValues, EncodedProductOptionActionModifyOptionValues> = t.recursion('ProductOptionActionModifyOptionValues', () => (
  t.exact(t.intersection([t.type({
    'action': CodegenEnumClosed({
      'modifyValues': null,
    }, 'ProductOptionActionModifyOptionValues'),
    'optionSetName': t.string,
  }), t.partial({
    'add': t.array(t.string),
    'remove': t.array(t.string),
  })]), 'ProductOptionActionModifyOptionValues')
));


export type ProductOptionAction = OneOfOpenValues<ProductOptionActionAddOptionSet | ProductOptionActionModifyOptionValues>;
export type EncodedProductOptionAction = OneOfOpenEncodedValues<EncodedProductOptionActionAddOptionSet | EncodedProductOptionActionModifyOptionValues>;
export const ProductOptionAction: t.Type<ProductOptionAction, EncodedProductOptionAction> = t.recursion('ProductOptionAction', () => (
  OneOfOpen([ProductOptionActionAddOptionSet, ProductOptionActionModifyOptionValues])
));


export type ModifyProductInitialState = {
  'productName'?: string,
  'optionSets': Array<ProductOptionSet>,
  'retailUnits': Array<{
    'retailUnit': RetailUnit,
    'options': Array<RetailUnitOptionValue>,
  }>,
};
export type EncodedModifyProductInitialState = {
  'productName'?: string,
  'optionSets': Array<EncodedProductOptionSet>,
  'retailUnits': Array<{
    'retailUnit': EncodedRetailUnit,
    'options': Array<EncodedRetailUnitOptionValue>,
  }>,
};
export const ModifyProductInitialState: t.Type<ModifyProductInitialState, EncodedModifyProductInitialState> = t.recursion('ModifyProductInitialState', () => (
  t.exact(t.intersection([t.type({
    'optionSets': t.array(ProductOptionSet),
    'retailUnits': t.array(t.exact(t.intersection([t.type({
      'retailUnit': RetailUnit,
      'options': t.array(RetailUnitOptionValue),
    }), t.partial({
      
    })]), 'ModifyProductInitialStateItem')),
  }), t.partial({
    'productName': t.string,
  })]), 'ModifyProductInitialState')
));


export type ModifyProductRetailUnitAction = CodegenEnumOpenValues<'none' | 'create' | 'assign' | 'keep' | 'archive' | 'republish'>;
export type EncodedModifyProductRetailUnitAction = string;
export const ModifyProductRetailUnitAction = CodegenEnumOpen({
  'none': null,
  'create': null,
  'assign': null,
  'keep': null,
  'archive': null,
  'republish': null,
}, 'ModifyProductRetailUnitAction');
export const ModifyProductRetailUnitActionValues = ['none', 'create', 'assign', 'keep', 'archive', 'republish'] as const;

export type SimpleOptionValue = {
  'optionName': string,
  'valueName': string,
  'position'?: number,
};
export type EncodedSimpleOptionValue = {
  'optionName': string,
  'valueName': string,
  'position'?: number,
};
export const SimpleOptionValue: t.Type<SimpleOptionValue, EncodedSimpleOptionValue> = t.recursion('SimpleOptionValue', () => (
  t.exact(t.intersection([t.type({
    'optionName': t.string,
    'valueName': t.string,
  }), t.partial({
    'position': t.number,
  })]), 'SimpleOptionValue')
));


export type UnitValueActionBase = {
  'values': Array<SimpleOptionValue>,
};
export type EncodedUnitValueActionBase = {
  'values': Array<EncodedSimpleOptionValue>,
};
export const UnitValueActionBase: t.Type<UnitValueActionBase, EncodedUnitValueActionBase> = t.recursion('UnitValueActionBase', () => (
  t.exact(t.intersection([t.type({
    'values': t.array(SimpleOptionValue),
  }), t.partial({
    
  })]), 'UnitValueActionBase')
));


export type UnitValueActionNone = UnitValueActionBase & {
  'action': 'none',
};
export type EncodedUnitValueActionNone = EncodedUnitValueActionBase & {
  'action': string,
};
export const UnitValueActionNone: t.Type<UnitValueActionNone, EncodedUnitValueActionNone> = t.recursion('UnitValueActionNone', () => (
  t.intersection([
    UnitValueActionBase,
    t.exact(t.intersection([t.type({
      'action': CodegenEnumClosed({
        'none': null,
      }, 'UnitValueActionNone'),
    }), t.partial({
      
    })]), 'UnitValueActionNone')
  ], 'UnitValueActionNone')
));


export type UnitValueActionKeep = UnitValueActionBase & {
  'action': 'keep',
  'retailUnitId': number,
};
export type EncodedUnitValueActionKeep = EncodedUnitValueActionBase & {
  'action': string,
  'retailUnitId': number,
};
export const UnitValueActionKeep: t.Type<UnitValueActionKeep, EncodedUnitValueActionKeep> = t.recursion('UnitValueActionKeep', () => (
  t.intersection([
    UnitValueActionBase,
    t.exact(t.intersection([t.type({
      'action': CodegenEnumClosed({
        'keep': null,
      }, 'UnitValueActionKeep'),
      'retailUnitId': t.number,
    }), t.partial({
      
    })]), 'UnitValueActionKeep')
  ], 'UnitValueActionKeep')
));


export type UnitValueActionCreate = UnitValueActionBase & {
  'action': 'create',
};
export type EncodedUnitValueActionCreate = EncodedUnitValueActionBase & {
  'action': string,
};
export const UnitValueActionCreate: t.Type<UnitValueActionCreate, EncodedUnitValueActionCreate> = t.recursion('UnitValueActionCreate', () => (
  t.intersection([
    UnitValueActionBase,
    t.exact(t.intersection([t.type({
      'action': CodegenEnumClosed({
        'create': null,
      }, 'UnitValueActionCreate'),
    }), t.partial({
      
    })]), 'UnitValueActionCreate')
  ], 'UnitValueActionCreate')
));


export type UnitValueActionAssign = UnitValueActionBase & {
  'action': 'assign',
  'retailUnitId': number,
};
export type EncodedUnitValueActionAssign = EncodedUnitValueActionBase & {
  'action': string,
  'retailUnitId': number,
};
export const UnitValueActionAssign: t.Type<UnitValueActionAssign, EncodedUnitValueActionAssign> = t.recursion('UnitValueActionAssign', () => (
  t.intersection([
    UnitValueActionBase,
    t.exact(t.intersection([t.type({
      'action': CodegenEnumClosed({
        'assign': null,
      }, 'UnitValueActionAssign'),
      'retailUnitId': t.number,
    }), t.partial({
      
    })]), 'UnitValueActionAssign')
  ], 'UnitValueActionAssign')
));


export type UnitValueActionArchive = UnitValueActionBase & {
  'action': 'archive',
  'retailUnitId': number,
};
export type EncodedUnitValueActionArchive = EncodedUnitValueActionBase & {
  'action': string,
  'retailUnitId': number,
};
export const UnitValueActionArchive: t.Type<UnitValueActionArchive, EncodedUnitValueActionArchive> = t.recursion('UnitValueActionArchive', () => (
  t.intersection([
    UnitValueActionBase,
    t.exact(t.intersection([t.type({
      'action': CodegenEnumClosed({
        'archive': null,
      }, 'UnitValueActionArchive'),
      'retailUnitId': t.number,
    }), t.partial({
      
    })]), 'UnitValueActionArchive')
  ], 'UnitValueActionArchive')
));


export type UnitValueActionRepublish = UnitValueActionBase & {
  'action': 'republish',
  'retailUnitId': number,
};
export type EncodedUnitValueActionRepublish = EncodedUnitValueActionBase & {
  'action': string,
  'retailUnitId': number,
};
export const UnitValueActionRepublish: t.Type<UnitValueActionRepublish, EncodedUnitValueActionRepublish> = t.recursion('UnitValueActionRepublish', () => (
  t.intersection([
    UnitValueActionBase,
    t.exact(t.intersection([t.type({
      'action': CodegenEnumClosed({
        'republish': null,
      }, 'UnitValueActionRepublish'),
      'retailUnitId': t.number,
    }), t.partial({
      
    })]), 'UnitValueActionRepublish')
  ], 'UnitValueActionRepublish')
));


export type UnitValueAction = OneOfOpenValues<UnitValueActionNone | UnitValueActionKeep | UnitValueActionCreate | UnitValueActionAssign | UnitValueActionArchive | UnitValueActionRepublish>;
export type EncodedUnitValueAction = OneOfOpenEncodedValues<EncodedUnitValueActionNone | EncodedUnitValueActionKeep | EncodedUnitValueActionCreate | EncodedUnitValueActionAssign | EncodedUnitValueActionArchive | EncodedUnitValueActionRepublish>;
export const UnitValueAction: t.Type<UnitValueAction, EncodedUnitValueAction> = t.recursion('UnitValueAction', () => (
  OneOfOpen([UnitValueActionNone, UnitValueActionKeep, UnitValueActionCreate, UnitValueActionAssign, UnitValueActionArchive, UnitValueActionRepublish])
));


export type ModifyProductRequestBase = {
  'name'?: string,
  'optionActions': Array<ProductOptionAction>,
  'unitActions': Array<UnitValueAction>,
};
export type EncodedModifyProductRequestBase = {
  'name'?: string,
  'optionActions': Array<EncodedProductOptionAction>,
  'unitActions': Array<EncodedUnitValueAction>,
};
export const ModifyProductRequestBase: t.Type<ModifyProductRequestBase, EncodedModifyProductRequestBase> = t.recursion('ModifyProductRequestBase', () => (
  t.exact(t.intersection([t.type({
    'optionActions': t.array(ProductOptionAction),
    'unitActions': t.array(UnitValueAction),
  }), t.partial({
    'name': t.string,
  })]), 'ModifyProductRequestBase')
));


export type ModifyProductNewProductRequest = ModifyProductRequestBase & {
  'name': string,
  'type': 'new',
};
export type EncodedModifyProductNewProductRequest = EncodedModifyProductRequestBase & {
  'name': string,
  'type': string,
};
export const ModifyProductNewProductRequest: t.Type<ModifyProductNewProductRequest, EncodedModifyProductNewProductRequest> = t.recursion('ModifyProductNewProductRequest', () => (
  t.intersection([
    ModifyProductRequestBase,
    t.exact(t.intersection([t.type({
      'name': t.string,
      'type': CodegenEnumClosed({
        'new': null,
      }, 'ModifyProductNewProductRequest'),
    }), t.partial({
      
    })]), 'ModifyProductNewProductRequest')
  ], 'ModifyProductNewProductRequest')
));


export type ModifyProductEditProductRequest = ModifyProductRequestBase & {
  'productId': number,
  'name'?: string,
  'type': 'edit',
};
export type EncodedModifyProductEditProductRequest = EncodedModifyProductRequestBase & {
  'productId': number,
  'name'?: string,
  'type': string,
};
export const ModifyProductEditProductRequest: t.Type<ModifyProductEditProductRequest, EncodedModifyProductEditProductRequest> = t.recursion('ModifyProductEditProductRequest', () => (
  t.intersection([
    ModifyProductRequestBase,
    t.exact(t.intersection([t.type({
      'productId': t.number,
      'type': CodegenEnumClosed({
        'edit': null,
      }, 'ModifyProductEditProductRequest'),
    }), t.partial({
      'name': t.string,
    })]), 'ModifyProductEditProductRequest')
  ], 'ModifyProductEditProductRequest')
));


export type ModifyProductRequest = OneOfOpenValues<ModifyProductNewProductRequest | ModifyProductEditProductRequest>;
export type EncodedModifyProductRequest = OneOfOpenEncodedValues<EncodedModifyProductNewProductRequest | EncodedModifyProductEditProductRequest>;
export const ModifyProductRequest: t.Type<ModifyProductRequest, EncodedModifyProductRequest> = t.recursion('ModifyProductRequest', () => (
  OneOfOpen([ModifyProductNewProductRequest, ModifyProductEditProductRequest])
));


export type OneWorldSyncLogType = CodegenEnumOpenValues<'OneWSAck' | 'OneWSCaseAdded' | 'OneWSCaseError' | 'OneWSCaseUpdated' | 'OneWSEachAdded' | 'OneWSEachError' | 'OneWSEachUpdated' | 'OneWSError' | 'OneWSLinked' | 'OneWSLinkError' | 'OneWSPublicationDeleted' | 'OneWSPublicationError' | 'OneWSPublicationWithdrawn' | 'OneWSPublished' | 'OneWSUpdated'>;
export type EncodedOneWorldSyncLogType = string;
export const OneWorldSyncLogType = CodegenEnumOpen({
  'OneWSAck': null,
  'OneWSCaseAdded': null,
  'OneWSCaseError': null,
  'OneWSCaseUpdated': null,
  'OneWSEachAdded': null,
  'OneWSEachError': null,
  'OneWSEachUpdated': null,
  'OneWSError': null,
  'OneWSLinked': null,
  'OneWSLinkError': null,
  'OneWSPublicationDeleted': null,
  'OneWSPublicationError': null,
  'OneWSPublicationWithdrawn': null,
  'OneWSPublished': null,
  'OneWSUpdated': null,
}, 'OneWorldSyncLogType');
export const OneWorldSyncLogTypeValues = ['OneWSAck', 'OneWSCaseAdded', 'OneWSCaseError', 'OneWSCaseUpdated', 'OneWSEachAdded', 'OneWSEachError', 'OneWSEachUpdated', 'OneWSError', 'OneWSLinked', 'OneWSLinkError', 'OneWSPublicationDeleted', 'OneWSPublicationError', 'OneWSPublicationWithdrawn', 'OneWSPublished', 'OneWSUpdated'] as const;

export type OneWorldSyncUnPublishType = CodegenEnumOpenValues<'withdraw' | 'delete'>;
export type EncodedOneWorldSyncUnPublishType = string;
export const OneWorldSyncUnPublishType = CodegenEnumOpen({
  'withdraw': null,
  'delete': null,
}, 'OneWorldSyncUnPublishType');
export const OneWorldSyncUnPublishTypeValues = ['withdraw', 'delete'] as const;

export type OneWorldSyncLogKind = CodegenEnumOpenValues<'item' | 'link' | 'publish' | 'withdraw' | 'delete'>;
export type EncodedOneWorldSyncLogKind = string;
export const OneWorldSyncLogKind = CodegenEnumOpen({
  'item': null,
  'link': null,
  'publish': null,
  'withdraw': null,
  'delete': null,
}, 'OneWorldSyncLogKind');
export const OneWorldSyncLogKindValues = ['item', 'link', 'publish', 'withdraw', 'delete'] as const;

export type OneWorldSyncLogResult = CodegenEnumOpenValues<'success' | 'failure'>;
export type EncodedOneWorldSyncLogResult = string;
export const OneWorldSyncLogResult = CodegenEnumOpen({
  'success': null,
  'failure': null,
}, 'OneWorldSyncLogResult');
export const OneWorldSyncLogResultValues = ['success', 'failure'] as const;

export type ProductIssueType = CodegenEnumOpenValues<'duplicateGtin' | 'missingAdditionalTradeItemDescription' | 'missingAllergens' | 'missingBackInStockDate' | 'missingCountryOfOrigin' | 'missingDescription' | 'missingDietTags' | 'missingDimensions' | 'missingDraftOrPublished' | 'missingFoodTraceabilityList' | 'missingFunctionalName' | 'missingGs1Category' | 'missingGtin' | 'missingIngredients' | 'missingInnerPackCount' | 'missingIsHemp' | 'missingMableSku' | 'missingMsrp' | 'missingNetContent' | 'missingNetWeight' | 'missingOptionGroups' | 'missingPackagingTypeCode' | 'missingPhotos' | 'missingPhotoSpecs' | 'missingPrice' | 'missingPurchasablePackaging' | 'missingShelfLife' | 'missingSize' | 'missingSku' | 'missingStorageTemp' | 'missingUnitGtin' | 'missingWeight' | 'packagingWeightLessThanContents'>;
export type EncodedProductIssueType = string;
export const ProductIssueType = CodegenEnumOpen({
  'duplicateGtin': null,
  'missingAdditionalTradeItemDescription': null,
  'missingAllergens': null,
  'missingBackInStockDate': null,
  'missingCountryOfOrigin': null,
  'missingDescription': null,
  'missingDietTags': null,
  'missingDimensions': null,
  'missingDraftOrPublished': null,
  'missingFoodTraceabilityList': null,
  'missingFunctionalName': null,
  'missingGs1Category': null,
  'missingGtin': null,
  'missingIngredients': null,
  'missingInnerPackCount': null,
  'missingIsHemp': null,
  'missingMableSku': null,
  'missingMsrp': null,
  'missingNetContent': null,
  'missingNetWeight': null,
  'missingOptionGroups': null,
  'missingPackagingTypeCode': null,
  'missingPhotos': null,
  'missingPhotoSpecs': null,
  'missingPrice': null,
  'missingPurchasablePackaging': null,
  'missingShelfLife': null,
  'missingSize': null,
  'missingSku': null,
  'missingStorageTemp': null,
  'missingUnitGtin': null,
  'missingWeight': null,
  'packagingWeightLessThanContents': null,
}, 'ProductIssueType');
export const ProductIssueTypeValues = ['duplicateGtin', 'missingAdditionalTradeItemDescription', 'missingAllergens', 'missingBackInStockDate', 'missingCountryOfOrigin', 'missingDescription', 'missingDietTags', 'missingDimensions', 'missingDraftOrPublished', 'missingFoodTraceabilityList', 'missingFunctionalName', 'missingGs1Category', 'missingGtin', 'missingIngredients', 'missingInnerPackCount', 'missingIsHemp', 'missingMableSku', 'missingMsrp', 'missingNetContent', 'missingNetWeight', 'missingOptionGroups', 'missingPackagingTypeCode', 'missingPhotos', 'missingPhotoSpecs', 'missingPrice', 'missingPurchasablePackaging', 'missingShelfLife', 'missingSize', 'missingSku', 'missingStorageTemp', 'missingUnitGtin', 'missingWeight', 'packagingWeightLessThanContents'] as const;

export type ProductIssueIssueInfo = {
  'type': ProductIssueType,
  'reasons': Array<string>,
  'packagingId'?: number,
  'variantId'?: number,
};
export type EncodedProductIssueIssueInfo = {
  'type': EncodedProductIssueType,
  'reasons': Array<string>,
  'packagingId'?: number,
  'variantId'?: number,
};
export const ProductIssueIssueInfo: t.Type<ProductIssueIssueInfo, EncodedProductIssueIssueInfo> = t.recursion('ProductIssueIssueInfo', () => (
  t.exact(t.intersection([t.type({
    'type': ProductIssueType,
    'reasons': t.array(t.string),
  }), t.partial({
    'packagingId': t.number,
    'variantId': t.number,
  })]), 'ProductIssueIssueInfo')
));


export type ProductIssueProductInfo = {
  'name': string,
  'productId': number,
  'retailUnitId': number,
};
export type EncodedProductIssueProductInfo = {
  'name': string,
  'productId': number,
  'retailUnitId': number,
};
export const ProductIssueProductInfo: t.Type<ProductIssueProductInfo, EncodedProductIssueProductInfo> = t.recursion('ProductIssueProductInfo', () => (
  t.exact(t.intersection([t.type({
    'name': t.string,
    'productId': t.number,
    'retailUnitId': t.number,
  }), t.partial({
    
  })]), 'ProductIssueProductInfo')
));


export type ProductIssue = {
  'productInfo': ProductIssueProductInfo,
  'issues': Array<ProductIssueIssueInfo>,
};
export type EncodedProductIssue = {
  'productInfo': EncodedProductIssueProductInfo,
  'issues': Array<EncodedProductIssueIssueInfo>,
};
export const ProductIssue: t.Type<ProductIssue, EncodedProductIssue> = t.recursion('ProductIssue', () => (
  t.exact(t.intersection([t.type({
    'productInfo': ProductIssueProductInfo,
    'issues': t.array(ProductIssueIssueInfo),
  }), t.partial({
    
  })]), 'ProductIssue')
));


export type SellerIssueType = 'missingDescription' | 'missingProfileImage' | 'missingShipFromAddress';
export type EncodedSellerIssueType = string;
export const SellerIssueType = CodegenEnumClosed({
  'missingDescription': null,
  'missingProfileImage': null,
  'missingShipFromAddress': null,
}, 'SellerIssueType');
export const SellerIssueTypeValues = ['missingDescription', 'missingProfileImage', 'missingShipFromAddress'] as const;

export type SellerIssue = {
  'type': SellerIssueType,
  'reasons': Array<string>,
};
export type EncodedSellerIssue = {
  'type': EncodedSellerIssueType,
  'reasons': Array<string>,
};
export const SellerIssue: t.Type<SellerIssue, EncodedSellerIssue> = t.recursion('SellerIssue', () => (
  t.exact(t.intersection([t.type({
    'type': SellerIssueType,
    'reasons': t.array(t.string),
  }), t.partial({
    
  })]), 'SellerIssue')
));


export type SellerIssues = {
  'sellerId': number,
  'sellerIssues': Array<SellerIssue>,
  'productIssues': Array<ProductIssue>,
};
export type EncodedSellerIssues = {
  'sellerId': number,
  'sellerIssues': Array<EncodedSellerIssue>,
  'productIssues': Array<EncodedProductIssue>,
};
export const SellerIssues: t.Type<SellerIssues, EncodedSellerIssues> = t.recursion('SellerIssues', () => (
  t.exact(t.intersection([t.type({
    'sellerId': t.number,
    'sellerIssues': t.array(SellerIssue),
    'productIssues': t.array(ProductIssue),
  }), t.partial({
    
  })]), 'SellerIssues')
));


export type ExternalBuyerLocationOperationActionType = 'add' | 'delete' | 'change';
export type EncodedExternalBuyerLocationOperationActionType = string;
export const ExternalBuyerLocationOperationActionType = CodegenEnumClosed({
  'add': null,
  'delete': null,
  'change': null,
}, 'ExternalBuyerLocationOperationActionType');
export const ExternalBuyerLocationOperationActionTypeValues = ['add', 'delete', 'change'] as const;

export type ExternalBuyerLocationOperationPurchaseOrderStatus = 'act' | 'hld';
export type EncodedExternalBuyerLocationOperationPurchaseOrderStatus = string;
export const ExternalBuyerLocationOperationPurchaseOrderStatus = CodegenEnumClosed({
  'act': null,
  'hld': null,
}, 'ExternalBuyerLocationOperationPurchaseOrderStatus');
export const ExternalBuyerLocationOperationPurchaseOrderStatusValues = ['act', 'hld'] as const;

export type ExternalBuyerLocationOperationInput = {
  /** The account ID of the partner this data comes from */
  'partnerAccountId': number,
  /** The action to take when processing this data, as specified by the partner */
  'action': ExternalBuyerLocationOperationActionType,
  /** Identifies a grouping of stores that may be a subset of a larger chain. e.g. McLane SRP */
  'storeGroupingIdentifier': string,
  /** Identifier for the buyer/retailer. Ideally unique per partner. e.g. McLane Corp code, which unfortunately is not unique per buyer account. */
  'buyerIdentifier': string,
  'buyerName': string,
  /** Unique ID of the partner's distribution center this location is served by. e.g. "NT" for a McLane Division */
  'distributionCenterId': string,
  /** Display name of the partner's distribution center this location is served by */
  'distributionCenterName': string,
  /** How the partner distributor identifies this store, e.g. McLane CBR */
  'partnerStoreIdentifier': string,
  /** How the buyer (e.g. 7-Eleven) identifies the store */
  'buyerStoreIdentifier'?: string,
  'storeName': string,
  'addressLine1': string,
  'addressLine2'?: string,
  'city': string,
  'state': string,
  'zip': string,
  'email'?: string,
  /** The name of the source file we received this location data from */
  'sourceFile': string,
  /** The current ordering status of this location. e.g. "act" for active, "hld" for on hold
   */
  'purchaseOrderStatus'?: ExternalBuyerLocationOperationPurchaseOrderStatus,
};
export type EncodedExternalBuyerLocationOperationInput = {
  'partnerAccountId': number,
  'action': EncodedExternalBuyerLocationOperationActionType,
  'storeGroupingIdentifier': string,
  'buyerIdentifier': string,
  'buyerName': string,
  'distributionCenterId': string,
  'distributionCenterName': string,
  'partnerStoreIdentifier': string,
  'buyerStoreIdentifier'?: string,
  'storeName': string,
  'addressLine1': string,
  'addressLine2'?: string,
  'city': string,
  'state': string,
  'zip': string,
  'email'?: string,
  'sourceFile': string,
  'purchaseOrderStatus'?: EncodedExternalBuyerLocationOperationPurchaseOrderStatus,
};
export const ExternalBuyerLocationOperationInput: t.Type<ExternalBuyerLocationOperationInput, EncodedExternalBuyerLocationOperationInput> = t.recursion('ExternalBuyerLocationOperationInput', () => (
  t.exact(t.intersection([t.type({
    'partnerAccountId': t.number,
    'action': ExternalBuyerLocationOperationActionType,
    'storeGroupingIdentifier': t.string,
    'buyerIdentifier': t.string,
    'buyerName': t.string,
    'distributionCenterId': t.string,
    'distributionCenterName': t.string,
    'partnerStoreIdentifier': t.string,
    'storeName': t.string,
    'addressLine1': t.string,
    'city': t.string,
    'state': t.string,
    'zip': t.string,
    'sourceFile': t.string,
  }), t.partial({
    'buyerStoreIdentifier': t.string,
    'addressLine2': t.string,
    'email': t.string,
    'purchaseOrderStatus': ExternalBuyerLocationOperationPurchaseOrderStatus,
  })]), 'ExternalBuyerLocationOperationInput')
));


export type ExternalBuyerLocationOperation = ExternalBuyerLocationOperationInput & {
  'id': number,
  'createdAt': Date,
  'updatedAt': Date,
  /** The buyer location ID that was acted upon when processing this location data, if any */
  'buyerLocationId'?: string,
  /** If not NULL, the time this row was processed by our back-end */
  'processedAt'?: Date,
  'addressBlocked'?: boolean,
};
export type EncodedExternalBuyerLocationOperation = EncodedExternalBuyerLocationOperationInput & {
  'id': number,
  'createdAt': string,
  'updatedAt': string,
  'buyerLocationId'?: string,
  'processedAt'?: string,
  'addressBlocked'?: boolean,
};
export const ExternalBuyerLocationOperation: t.Type<ExternalBuyerLocationOperation, EncodedExternalBuyerLocationOperation> = t.recursion('ExternalBuyerLocationOperation', () => (
  t.intersection([
    ExternalBuyerLocationOperationInput,
    t.exact(t.intersection([t.type({
      'id': t.number,
      'createdAt': DateFromISOString,
      'updatedAt': DateFromISOString,
    }), t.partial({
      'buyerLocationId': t.string,
      'processedAt': DateFromISOString,
      'addressBlocked': t.boolean,
    })]), 'ExternalBuyerLocationOperation')
  ], 'ExternalBuyerLocationOperation')
));


export type PartnerAccountAdminDisplayInfo = {
  /** The name of the partner that should be displayed to users */
  'displayName': string,
  /** The name of the program for this partner. This might be different from the partner name, e.g. McLane Emerging Brands vs McLane */
  'programName': string,
  'commissionMultiplier'?: BigNumber,
  'productVariantIdentifierTypes'?: Array<ProductVariantIdentifierType>,
};
export type EncodedPartnerAccountAdminDisplayInfo = {
  'displayName': string,
  'programName': string,
  'commissionMultiplier'?: string,
  'productVariantIdentifierTypes'?: Array<EncodedProductVariantIdentifierType>,
};
export const PartnerAccountAdminDisplayInfo: t.Type<PartnerAccountAdminDisplayInfo, EncodedPartnerAccountAdminDisplayInfo> = t.recursion('PartnerAccountAdminDisplayInfo', () => (
  t.exact(t.intersection([t.type({
    'displayName': t.string,
    'programName': t.string,
  }), t.partial({
    'commissionMultiplier': NumberFromString,
    'productVariantIdentifierTypes': t.array(ProductVariantIdentifierType),
  })]), 'PartnerAccountAdminDisplayInfo')
));


/** Identifier of a DataStandardProperty used when transmitting it to a computer, e.g. 'packagingTypeCode' */
export type DataStandardPropertyName = CodegenEnumOpenValues<'packagingTypeCode' | 'countryCode' | 'functionalName' | 'additionalTradeItemDescription' | 'speciesForFisheryStatisticsPurposesName' | 'productShipmentCondition' | 'flavorDescription' | 'imageInformationType' | 'imageStateCode' | 'imageFacing' | 'imageBackground' | 'imageOrientationTypeCode' | 'imageRenderedIndicator' | 'usFoodsImageStateCode'>;
export type EncodedDataStandardPropertyName = string;
export const DataStandardPropertyName = CodegenEnumOpen({
  'packagingTypeCode': null,
  'countryCode': null,
  'functionalName': null,
  'additionalTradeItemDescription': null,
  'speciesForFisheryStatisticsPurposesName': null,
  'productShipmentCondition': null,
  'flavorDescription': null,
  'imageInformationType': null,
  'imageStateCode': null,
  'imageFacing': null,
  'imageBackground': null,
  'imageOrientationTypeCode': null,
  'imageRenderedIndicator': null,
  'usFoodsImageStateCode': null,
}, 'DataStandardPropertyName');
export const DataStandardPropertyNameValues = ['packagingTypeCode', 'countryCode', 'functionalName', 'additionalTradeItemDescription', 'speciesForFisheryStatisticsPurposesName', 'productShipmentCondition', 'flavorDescription', 'imageInformationType', 'imageStateCode', 'imageFacing', 'imageBackground', 'imageOrientationTypeCode', 'imageRenderedIndicator', 'usFoodsImageStateCode'] as const;

export type DataStandardPropertyAssociableEntity = CodegenEnumOpenValues<'retail_unit' | 'unit_packaging' | 'image'>;
export type EncodedDataStandardPropertyAssociableEntity = string;
export const DataStandardPropertyAssociableEntity = CodegenEnumOpen({
  'retail_unit': null,
  'unit_packaging': null,
  'image': null,
}, 'DataStandardPropertyAssociableEntity');
export const DataStandardPropertyAssociableEntityValues = ['retail_unit', 'unit_packaging', 'image'] as const;

export type DataStandardPropertyValueType = CodegenEnumOpenValues<'enum' | 'text' | 'boolean'>;
export type EncodedDataStandardPropertyValueType = string;
export const DataStandardPropertyValueType = CodegenEnumOpen({
  'enum': null,
  'text': null,
  'boolean': null,
}, 'DataStandardPropertyValueType');
export const DataStandardPropertyValueTypeValues = ['enum', 'text', 'boolean'] as const;

export type DataStandardPropertyEditableBy = CodegenEnumOpenValues<'dataworker' | 'seller'>;
export type EncodedDataStandardPropertyEditableBy = string;
export const DataStandardPropertyEditableBy = CodegenEnumOpen({
  'dataworker': null,
  'seller': null,
}, 'DataStandardPropertyEditableBy');
export const DataStandardPropertyEditableByValues = ['dataworker', 'seller'] as const;

/** A property defined in a data standard like GDSN */
export type BaseDataStandardProperty = {
  'id': number,
  /** Name of the data standard that defines this property, e.g. 'gdsn' */
  'dataStandardName': CodegenEnumOpenValues<'gdsn' | 'usfoods'>,
  /** Which Mable entities can have values of this property assigned to them */
  'associableEntities': Array<DataStandardPropertyAssociableEntity>,
  /** Identifier of this value used when transmitting it to a computer, e.g. 'packagingTypeCode' */
  'name': DataStandardPropertyName,
  /** Human-readable name of this property, e.g. 'Packaging Type Code' */
  'displayName': string,
  /** Human-readable description of the meaning of this property */
  'description'?: string,
  /** Which kinds of users should see this property as an editable field in UI */
  'editableBy'?: Array<DataStandardPropertyEditableBy>,
};
export type EncodedBaseDataStandardProperty = {
  'id': number,
  'dataStandardName': string,
  'associableEntities': Array<EncodedDataStandardPropertyAssociableEntity>,
  'name': EncodedDataStandardPropertyName,
  'displayName': string,
  'description'?: string,
  'editableBy'?: Array<EncodedDataStandardPropertyEditableBy>,
};
export const BaseDataStandardProperty: t.Type<BaseDataStandardProperty, EncodedBaseDataStandardProperty> = t.recursion('BaseDataStandardProperty', () => (
  t.exact(t.intersection([t.type({
    'id': t.number,
    'dataStandardName': CodegenEnumOpen({
      'gdsn': null,
      'usfoods': null,
    }, 'BaseDataStandardProperty'),
    'associableEntities': t.array(DataStandardPropertyAssociableEntity),
    'name': DataStandardPropertyName,
    'displayName': t.string,
  }), t.partial({
    'description': t.string,
    'editableBy': t.array(DataStandardPropertyEditableBy),
  })]), 'BaseDataStandardProperty')
));


export type EnumDataStandardProperty = {
  'valueType': 'enum',
  'values': Array<{
    /** Identifier of this value used when transmitting it to a computer, e.g. 'BA' */
    'name': string,
    /** Human-readable name of this value, e.g. 'Barrel' */
    'displayName': string,
    /** Human-readable description of the meaning of this value */
    'description': string,
  }>,
};
export type EncodedEnumDataStandardProperty = {
  'valueType': string,
  'values': Array<{
    'name': string,
    'displayName': string,
    'description': string,
  }>,
};
export const EnumDataStandardProperty: t.Type<EnumDataStandardProperty, EncodedEnumDataStandardProperty> = t.recursion('EnumDataStandardProperty', () => (
  t.exact(t.intersection([t.type({
    'valueType': CodegenEnumClosed({
      'enum': null,
    }, 'EnumDataStandardProperty'),
    'values': t.array(t.exact(t.intersection([t.type({
      'name': t.string,
      'displayName': t.string,
      'description': t.string,
    }), t.partial({
      
    })]), 'EnumDataStandardPropertyItem')),
  }), t.partial({
    
  })]), 'EnumDataStandardProperty')
));


export type TextDataStandardProperty = {
  'valueType': 'text',
};
export type EncodedTextDataStandardProperty = {
  'valueType': string,
};
export const TextDataStandardProperty: t.Type<TextDataStandardProperty, EncodedTextDataStandardProperty> = t.recursion('TextDataStandardProperty', () => (
  t.exact(t.intersection([t.type({
    'valueType': CodegenEnumClosed({
      'text': null,
    }, 'TextDataStandardProperty'),
  }), t.partial({
    
  })]), 'TextDataStandardProperty')
));


export type BooleanDataStandardProperty = {
  'valueType': CodegenEnumOpenValues<'boolean'>,
};
export type EncodedBooleanDataStandardProperty = {
  'valueType': string,
};
export const BooleanDataStandardProperty: t.Type<BooleanDataStandardProperty, EncodedBooleanDataStandardProperty> = t.recursion('BooleanDataStandardProperty', () => (
  t.exact(t.intersection([t.type({
    'valueType': CodegenEnumOpen({
      'boolean': null,
    }, 'BooleanDataStandardProperty'),
  }), t.partial({
    
  })]), 'BooleanDataStandardProperty')
));


export type DataStandardProperty = BaseDataStandardProperty & OneOfOpenValues<EnumDataStandardProperty | TextDataStandardProperty | BooleanDataStandardProperty>;
export type EncodedDataStandardProperty = EncodedBaseDataStandardProperty & OneOfOpenEncodedValues<EncodedEnumDataStandardProperty | EncodedTextDataStandardProperty | EncodedBooleanDataStandardProperty>;
export const DataStandardProperty: t.Type<DataStandardProperty, EncodedDataStandardProperty> = t.recursion('DataStandardProperty', () => (
  t.intersection([
    BaseDataStandardProperty,
    OneOfOpen([EnumDataStandardProperty, TextDataStandardProperty, BooleanDataStandardProperty])
  ], 'DataStandardProperty')
));


export type DataStandardProperties = Array<DataStandardProperty>;
export type EncodedDataStandardProperties = Array<EncodedDataStandardProperty>;
export const DataStandardProperties: t.Type<DataStandardProperties, EncodedDataStandardProperties> = t.recursion('DataStandardProperties', () => (
  t.array(DataStandardProperty)
));


/** The part of DataStandardPropertyValue that stores just the actual value */
export type DataStandardPropertyValueValue = {
  /** The value assigned to this property for this entity, if it's a boolean property */
  'dataStandardPropertyBooleanValue'?: boolean,
  /** The value assigned to this property for this entity, if it's a text property */
  'dataStandardPropertyTextValue'?: string,
  /** The value assigned to this property for this entity, if it's an enum property */
  'dataStandardPropertyEnumValueId'?: number,
  /** The name of the enum value assigned to this property for this entity, if it's an enum property */
  'dataStandardPropertyEnumValueName'?: string,
};
export type EncodedDataStandardPropertyValueValue = {
  'dataStandardPropertyBooleanValue'?: boolean,
  'dataStandardPropertyTextValue'?: string,
  'dataStandardPropertyEnumValueId'?: number,
  'dataStandardPropertyEnumValueName'?: string,
};
export const DataStandardPropertyValueValue: t.Type<DataStandardPropertyValueValue, EncodedDataStandardPropertyValueValue> = t.recursion('DataStandardPropertyValueValue', () => (
  t.exact(t.intersection([t.type({
    
  }), t.partial({
    'dataStandardPropertyBooleanValue': t.boolean,
    'dataStandardPropertyTextValue': t.string,
    'dataStandardPropertyEnumValueId': t.number,
    'dataStandardPropertyEnumValueName': t.string,
  })]), 'DataStandardPropertyValueValue')
));


/** An assignment of a value for a data standard property on an entity. For example, a DataStandardPropertyValue could indicate "retail_unit \#4 has a countryCode of 'US'". */
export type DataStandardPropertyValue = DataStandardPropertyValueValue & {
  /** ID of the data_standard_property that we're assigning a value to an entity for */
  'dataStandardPropertyId': number,
  /** Name of the data_standard_property that we're assigning a value to an entity for */
  'dataStandardPropertyName': DataStandardPropertyName,
  /** The retail unit to assign this value to for this property. Only one entity ID will ever be non-null for a given DataStandardPropertyValue. */
  'retailUnitId'?: number,
  /** The unit packaging to assign this value to for this property. Only one entity ID will ever be non-null for a given DataStandardPropertyValue. */
  'unitPackagingId'?: number,
  /** The image to assign this value to for this property. Only one entity ID will ever be non-null for a given DataStandardPropertyValue. */
  'imageId'?: number,
};
export type EncodedDataStandardPropertyValue = EncodedDataStandardPropertyValueValue & {
  'dataStandardPropertyId': number,
  'dataStandardPropertyName': EncodedDataStandardPropertyName,
  'retailUnitId'?: number,
  'unitPackagingId'?: number,
  'imageId'?: number,
};
export const DataStandardPropertyValue: t.Type<DataStandardPropertyValue, EncodedDataStandardPropertyValue> = t.recursion('DataStandardPropertyValue', () => (
  t.intersection([
    DataStandardPropertyValueValue,
    t.exact(t.intersection([t.type({
      'dataStandardPropertyId': t.number,
      'dataStandardPropertyName': DataStandardPropertyName,
    }), t.partial({
      'retailUnitId': t.number,
      'unitPackagingId': t.number,
      'imageId': t.number,
    })]), 'DataStandardPropertyValue')
  ], 'DataStandardPropertyValue')
));


export type DataStandardPropertyValues = Array<DataStandardPropertyValue>;
export type EncodedDataStandardPropertyValues = Array<EncodedDataStandardPropertyValue>;
export const DataStandardPropertyValues: t.Type<DataStandardPropertyValues, EncodedDataStandardPropertyValues> = t.recursion('DataStandardPropertyValues', () => (
  t.array(DataStandardPropertyValue)
));


export type SetDataStandardPropertyValues = {
  'dataStandardPropertyValues': Array<SetDataStandardPropertyValue>,
};
export type EncodedSetDataStandardPropertyValues = {
  'dataStandardPropertyValues': Array<EncodedSetDataStandardPropertyValue>,
};
export const SetDataStandardPropertyValues: t.Type<SetDataStandardPropertyValues, EncodedSetDataStandardPropertyValues> = t.recursion('SetDataStandardPropertyValues', () => (
  t.exact(t.intersection([t.type({
    'dataStandardPropertyValues': t.array(SetDataStandardPropertyValue),
  }), t.partial({
    
  })]), 'SetDataStandardPropertyValues')
));


export type SetDataStandardPropertyValueEntityId = ExactlyOneKey<{
  'retailUnitId': number,
  'unitPackagingId': number,
  'imageId': number,
}>;
export type EncodedSetDataStandardPropertyValueEntityId = ExactlyOneKey<{
  'retailUnitId': number,
  'unitPackagingId': number,
  'imageId': number,
}>;
export const SetDataStandardPropertyValueEntityId: t.Type<SetDataStandardPropertyValueEntityId, EncodedSetDataStandardPropertyValueEntityId> = t.recursion('SetDataStandardPropertyValueEntityId', () => (
  withExactlyOneKey(t.intersection([t.type({
    'retailUnitId': t.number,
    'unitPackagingId': t.number,
    'imageId': t.number,
  }), t.partial({
    
  })]), 'SetDataStandardPropertyValueEntityId')
));


export type SetDataStandardPropertyValue = ExactlyOneKey<{
  'dataStandardPropertyId': number,
  'dataStandardPropertyName': string,
}> & SetDataStandardPropertyValueEntityId & ExactlyOneKey<{
  'dataStandardPropertyBooleanValue': boolean,
  'dataStandardPropertyTextValue': string,
  'dataStandardPropertyEnumValueId': number,
  'dataStandardPropertyEnumValueName': string,
  /** Don't use this unless you are an AI who is incapable of using a real identifier */
  'dataStandardPropertyEnumValueDisplayName': string,
}>;
export type EncodedSetDataStandardPropertyValue = ExactlyOneKey<{
  'dataStandardPropertyId': number,
  'dataStandardPropertyName': string,
}> & EncodedSetDataStandardPropertyValueEntityId & ExactlyOneKey<{
  'dataStandardPropertyBooleanValue': boolean,
  'dataStandardPropertyTextValue': string,
  'dataStandardPropertyEnumValueId': number,
  'dataStandardPropertyEnumValueName': string,
  'dataStandardPropertyEnumValueDisplayName': string,
}>;
export const SetDataStandardPropertyValue: t.Type<SetDataStandardPropertyValue, EncodedSetDataStandardPropertyValue> = t.recursion('SetDataStandardPropertyValue', () => (
  t.intersection([
    withExactlyOneKey(t.intersection([t.type({
      'dataStandardPropertyId': t.number,
      'dataStandardPropertyName': t.string,
    }), t.partial({
      
    })]), 'SetDataStandardPropertyValue'),
    SetDataStandardPropertyValueEntityId,
    withExactlyOneKey(t.intersection([t.type({
      'dataStandardPropertyBooleanValue': t.boolean,
      'dataStandardPropertyTextValue': t.string,
      'dataStandardPropertyEnumValueId': t.number,
      'dataStandardPropertyEnumValueName': t.string,
      'dataStandardPropertyEnumValueDisplayName': t.string,
    }), t.partial({
      
    })]), 'SetDataStandardPropertyValue')
  ], 'SetDataStandardPropertyValue')
));


export type PricingReport = {
  'id': number,
  'partnerAccountId': number,
  'correlationId'?: string,
  'reportedAt': Date,
};
export type EncodedPricingReport = {
  'id': number,
  'partnerAccountId': number,
  'correlationId'?: string,
  'reportedAt': string,
};
export const PricingReport: t.Type<PricingReport, EncodedPricingReport> = t.recursion('PricingReport', () => (
  t.exact(t.intersection([t.type({
    'id': t.number,
    'partnerAccountId': t.number,
    'reportedAt': DateFromISOString,
  }), t.partial({
    'correlationId': t.string,
  })]), 'PricingReport')
));


export type UsFoodsImageArchivePlan = {
  /** The number of variants attempted to be included in the archive */
  'totalVariantCount': number,
  /** The ids of the variants that were included but are not associated with an image that can be named for US Foods (likely because we need to add DSPs to the images */
  'noImageVariantIds': Array<number>,
  /** Comprehensive information about the variants, images and their names considered for the archive */
  'fullPlan': Record<string, unknown>,
};
export type EncodedUsFoodsImageArchivePlan = {
  'totalVariantCount': number,
  'noImageVariantIds': Array<number>,
  'fullPlan': Record<string, unknown>,
};
export const UsFoodsImageArchivePlan: t.Type<UsFoodsImageArchivePlan, EncodedUsFoodsImageArchivePlan> = t.recursion('UsFoodsImageArchivePlan', () => (
  t.exact(t.intersection([t.type({
    'totalVariantCount': t.number,
    'noImageVariantIds': t.array(t.number),
    'fullPlan': t.record(t.string, t.unknown),
  }), t.partial({
    
  })]), 'UsFoodsImageArchivePlan')
));


export type PackagingOneWorldSyncStatus = {
  'packagingId'?: number,
  'variantId'?: number,
  'status'?: Array<OneWorldSyncStatus>,
};
export type EncodedPackagingOneWorldSyncStatus = {
  'packagingId'?: number,
  'variantId'?: number,
  'status'?: Array<EncodedOneWorldSyncStatus>,
};
export const PackagingOneWorldSyncStatus: t.Type<PackagingOneWorldSyncStatus, EncodedPackagingOneWorldSyncStatus> = t.recursion('PackagingOneWorldSyncStatus', () => (
  t.exact(t.intersection([t.type({
    
  }), t.partial({
    'packagingId': t.number,
    'variantId': t.number,
    'status': t.array(OneWorldSyncStatus),
  })]), 'PackagingOneWorldSyncStatus')
));


export type S3SignedLocation = {
  'filename': string,
  'signedUrl': string,
  's3Key': string,
};
export type EncodedS3SignedLocation = {
  'filename': string,
  'signedUrl': string,
  's3Key': string,
};
export const S3SignedLocation: t.Type<S3SignedLocation, EncodedS3SignedLocation> = t.recursion('S3SignedLocation', () => (
  t.exact(t.intersection([t.type({
    'filename': t.string,
    'signedUrl': t.string,
    's3Key': t.string,
  }), t.partial({
    
  })]), 'S3SignedLocation')
));


export type SellerCertificationType = CodegenEnumOpenValues<'certifiedOrganic' | 'other'>;
export type EncodedSellerCertificationType = string;
export const SellerCertificationType = CodegenEnumOpen({
  'certifiedOrganic': null,
  'other': null,
}, 'SellerCertificationType');
export const SellerCertificationTypeValues = ['certifiedOrganic', 'other'] as const;

export type FileUploadReference = {
  'fileUploadId': number,
  'accountId': number,
  'externalBucketId': string,
  'externalId': string,
  'filename': string,
  'uploadedByUserId': number,
};
export type EncodedFileUploadReference = {
  'fileUploadId': number,
  'accountId': number,
  'externalBucketId': string,
  'externalId': string,
  'filename': string,
  'uploadedByUserId': number,
};
export const FileUploadReference: t.Type<FileUploadReference, EncodedFileUploadReference> = t.recursion('FileUploadReference', () => (
  t.exact(t.intersection([t.type({
    'fileUploadId': t.number,
    'accountId': t.number,
    'externalBucketId': t.string,
    'externalId': t.string,
    'filename': t.string,
    'uploadedByUserId': t.number,
  }), t.partial({
    
  })]), 'FileUploadReference')
));


export type FileDownload = {
  'url': string,
  'filename': string,
};
export type EncodedFileDownload = {
  'url': string,
  'filename': string,
};
export const FileDownload: t.Type<FileDownload, EncodedFileDownload> = t.recursion('FileDownload', () => (
  t.exact(t.intersection([t.type({
    'url': t.string,
    'filename': t.string,
  }), t.partial({
    
  })]), 'FileDownload')
));


/** A user facing description of a file upload. Generally as a part of a more specific file object (e.g. product submission) */
export type FileUploadDisplay = {
  'fileUploadId': number,
  'filename': string,
  'createdAt': Date,
  'uploadedByUser'?: UserDisplayInfo,
};
export type EncodedFileUploadDisplay = {
  'fileUploadId': number,
  'filename': string,
  'createdAt': string,
  'uploadedByUser'?: EncodedUserDisplayInfo,
};
export const FileUploadDisplay: t.Type<FileUploadDisplay, EncodedFileUploadDisplay> = t.recursion('FileUploadDisplay', () => (
  t.exact(t.intersection([t.type({
    'fileUploadId': t.number,
    'filename': t.string,
    'createdAt': DateFromISOString,
  }), t.partial({
    'uploadedByUser': UserDisplayInfo,
  })]), 'FileUploadDisplay')
));


export type MediaBase = {
  'type': MediaType,
  'status': MediaStatus,
  'title'?: string,
  'shortDescription'?: string,
  'sellerId': number,
  /** If this content is only relevant for a specific partner, the ID of that partner account. */
  'partnerAccountId'?: number,
  /** The URL of the media, used in different ways depending on the media type. */
  'url'?: string,
};
export type EncodedMediaBase = {
  'type': EncodedMediaType,
  'status': EncodedMediaStatus,
  'title'?: string,
  'shortDescription'?: string,
  'sellerId': number,
  'partnerAccountId'?: number,
  'url'?: string,
};
export const MediaBase: t.Type<MediaBase, EncodedMediaBase> = t.recursion('MediaBase', () => (
  t.exact(t.intersection([t.type({
    'type': MediaType,
    'status': MediaStatus,
    'sellerId': t.number,
  }), t.partial({
    'title': t.string,
    'shortDescription': t.string,
    'partnerAccountId': t.number,
    'url': t.string,
  })]), 'MediaBase')
));


export type MediaInput = MediaBase & {
  's3FileInfo'?: S3SignedLocation,
};
export type EncodedMediaInput = EncodedMediaBase & {
  's3FileInfo'?: EncodedS3SignedLocation,
};
export const MediaInput: t.Type<MediaInput, EncodedMediaInput> = t.recursion('MediaInput', () => (
  t.intersection([
    MediaBase,
    t.exact(t.intersection([t.type({
      
    }), t.partial({
      's3FileInfo': S3SignedLocation,
    })]), 'MediaInput')
  ], 'MediaInput')
));


export type MediaPatch = {
  'type'?: MediaType,
  'status'?: MediaStatus,
  'title'?: null | string,
  'url'?: null | string,
  'shortDescription'?: null | string,
  'partnerAccountId'?: null | number,
};
export type EncodedMediaPatch = {
  'type'?: EncodedMediaType,
  'status'?: EncodedMediaStatus,
  'title'?: null | string,
  'url'?: null | string,
  'shortDescription'?: null | string,
  'partnerAccountId'?: null | number,
};
export const MediaPatch: t.Type<MediaPatch, EncodedMediaPatch> = t.recursion('MediaPatch', () => (
  t.exact(t.intersection([t.type({
    
  }), t.partial({
    'type': MediaType,
    'status': MediaStatus,
    'title': t.union([t.null, t.string]),
    'url': t.union([t.null, t.string]),
    'shortDescription': t.union([t.null, t.string]),
    'partnerAccountId': t.union([t.null, t.number]),
  })]), 'MediaPatch')
));


/** Represents the full detail of a piece of media that may be an uploaded file or some other content, typically used as marketing collateral. e.g a PDF of a sell sheet, or a PPT of a pitch deck. */
export type Media = MediaBase & {
  'id': number,
  'createdAt': Date,
  'updatedAt': Date,
  'createdByUserId': number,
  /** If this content is only relevant for a specific partner, the ID of that partner account. */
  'partnerAccountId'?: number,
  'fileUpload'?: FileUploadDisplay,
};
export type EncodedMedia = EncodedMediaBase & {
  'id': number,
  'createdAt': string,
  'updatedAt': string,
  'createdByUserId': number,
  'partnerAccountId'?: number,
  'fileUpload'?: EncodedFileUploadDisplay,
};
export const Media: t.Type<Media, EncodedMedia> = t.recursion('Media', () => (
  t.intersection([
    MediaBase,
    t.exact(t.intersection([t.type({
      'id': t.number,
      'createdAt': DateFromISOString,
      'updatedAt': DateFromISOString,
      'createdByUserId': t.number,
    }), t.partial({
      'partnerAccountId': t.number,
      'fileUpload': FileUploadDisplay,
    })]), 'Media')
  ], 'Media')
));


export type MediaSocialMediaDisplayInfo = {
  'displayInfoType': 'socialMediaEmbed',
  'id': number,
  'createdAt': Date,
  'partnerAccountId'?: number,
  'type': 'facebook' | 'instagram' | 'linkedIn' | 'tiktok' | 'twitter' | 'vimeo' | 'youTube',
  /** A short description appropriate for a list view */
  'shortDescription'?: string,
  /** A URL associated with certain types of media, like 'externalLink'. */
  'url': string,
};
export type EncodedMediaSocialMediaDisplayInfo = {
  'displayInfoType': string,
  'id': number,
  'createdAt': string,
  'partnerAccountId'?: number,
  'type': string,
  'shortDescription'?: string,
  'url': string,
};
export const MediaSocialMediaDisplayInfo: t.Type<MediaSocialMediaDisplayInfo, EncodedMediaSocialMediaDisplayInfo> = t.recursion('MediaSocialMediaDisplayInfo', () => (
  t.exact(t.intersection([t.type({
    'displayInfoType': CodegenEnumClosed({
      'socialMediaEmbed': null,
    }, 'MediaSocialMediaDisplayInfo'),
    'id': t.number,
    'createdAt': DateFromISOString,
    'type': CodegenEnumClosed({
      'facebook': null,
      'instagram': null,
      'linkedIn': null,
      'tiktok': null,
      'twitter': null,
      'vimeo': null,
      'youTube': null,
    }, 'MediaSocialMediaDisplayInfo'),
    'url': t.string,
  }), t.partial({
    'partnerAccountId': t.number,
    'shortDescription': t.string,
  })]), 'MediaSocialMediaDisplayInfo')
));


export type MediaFileDownloadDisplayInfo = {
  'displayInfoType': 'fileDownload',
  'id': number,
  'createdAt': Date,
  'partnerAccountId'?: number,
  'type': 'digitalAsset' | 'merchandizingInfo' | 'pitchDeck' | 'sellSheet' | 'signage',
  /** A short description appropriate for a list view */
  'shortDescription'?: string,
  'fileUpload': FileUploadDisplay,
  'title'?: string,
};
export type EncodedMediaFileDownloadDisplayInfo = {
  'displayInfoType': string,
  'id': number,
  'createdAt': string,
  'partnerAccountId'?: number,
  'type': string,
  'shortDescription'?: string,
  'fileUpload': EncodedFileUploadDisplay,
  'title'?: string,
};
export const MediaFileDownloadDisplayInfo: t.Type<MediaFileDownloadDisplayInfo, EncodedMediaFileDownloadDisplayInfo> = t.recursion('MediaFileDownloadDisplayInfo', () => (
  t.exact(t.intersection([t.type({
    'displayInfoType': CodegenEnumClosed({
      'fileDownload': null,
    }, 'MediaFileDownloadDisplayInfo'),
    'id': t.number,
    'createdAt': DateFromISOString,
    'type': CodegenEnumClosed({
      'digitalAsset': null,
      'merchandizingInfo': null,
      'pitchDeck': null,
      'sellSheet': null,
      'signage': null,
    }, 'MediaFileDownloadDisplayInfo'),
    'fileUpload': FileUploadDisplay,
  }), t.partial({
    'partnerAccountId': t.number,
    'shortDescription': t.string,
    'title': t.string,
  })]), 'MediaFileDownloadDisplayInfo')
));


export type MediaExternalLinkDisplayInfo = {
  'displayInfoType': CodegenEnumOpenValues<'externalLink'>,
  'id': number,
  'createdAt': Date,
  'partnerAccountId'?: number,
  'type': 'externalLink',
  /** A short description appropriate for a list view */
  'shortDescription'?: string,
  'title': string,
  'url': string,
};
export type EncodedMediaExternalLinkDisplayInfo = {
  'displayInfoType': string,
  'id': number,
  'createdAt': string,
  'partnerAccountId'?: number,
  'type': string,
  'shortDescription'?: string,
  'title': string,
  'url': string,
};
export const MediaExternalLinkDisplayInfo: t.Type<MediaExternalLinkDisplayInfo, EncodedMediaExternalLinkDisplayInfo> = t.recursion('MediaExternalLinkDisplayInfo', () => (
  t.exact(t.intersection([t.type({
    'displayInfoType': CodegenEnumOpen({
      'externalLink': null,
    }, 'MediaExternalLinkDisplayInfo'),
    'id': t.number,
    'createdAt': DateFromISOString,
    'type': CodegenEnumClosed({
      'externalLink': null,
    }, 'MediaExternalLinkDisplayInfo'),
    'title': t.string,
    'url': t.string,
  }), t.partial({
    'partnerAccountId': t.number,
    'shortDescription': t.string,
  })]), 'MediaExternalLinkDisplayInfo')
));


/** Representation of a Media optimized for front-end APIs */
export type MediaDisplayInfo = MediaSocialMediaDisplayInfo | MediaFileDownloadDisplayInfo | MediaExternalLinkDisplayInfo;
export type EncodedMediaDisplayInfo = EncodedMediaSocialMediaDisplayInfo | EncodedMediaFileDownloadDisplayInfo | EncodedMediaExternalLinkDisplayInfo;
export const MediaDisplayInfo: t.Type<MediaDisplayInfo, EncodedMediaDisplayInfo> = t.recursion('MediaDisplayInfo', () => (
  OneOfClosed([MediaSocialMediaDisplayInfo, MediaFileDownloadDisplayInfo, MediaExternalLinkDisplayInfo])
));


/** The type of the media, pertaining to its information, not file type. e.g. sell sheet or pitch deck. Matches the database. */
export type MediaType = CodegenEnumOpenValues<'digitalAsset' | 'externalLink' | 'merchandizingInfo' | 'pitchDeck' | 'sellSheet' | 'signage' | 'socialMediaEmbed'>;
export type EncodedMediaType = string;
export const MediaType = CodegenEnumOpen({
  'digitalAsset': null,
  'externalLink': null,
  'merchandizingInfo': null,
  'pitchDeck': null,
  'sellSheet': null,
  'signage': null,
  'socialMediaEmbed': null,
}, 'MediaType');
export const MediaTypeValues = ['digitalAsset', 'externalLink', 'merchandizingInfo', 'pitchDeck', 'sellSheet', 'signage', 'socialMediaEmbed'] as const;

/** How this media should be displayed. */
export type MediaDisplayType = CodegenEnumOpenValues<'digitalAsset' | 'externalLink' | 'facebook' | 'instagram' | 'linkedIn' | 'merchandizingInfo' | 'pitchDeck' | 'sellSheet' | 'signage' | 'tiktok' | 'twitter' | 'vimeo' | 'youTube'>;
export type EncodedMediaDisplayType = string;
export const MediaDisplayType = CodegenEnumOpen({
  'digitalAsset': null,
  'externalLink': null,
  'facebook': null,
  'instagram': null,
  'linkedIn': null,
  'merchandizingInfo': null,
  'pitchDeck': null,
  'sellSheet': null,
  'signage': null,
  'tiktok': null,
  'twitter': null,
  'vimeo': null,
  'youTube': null,
}, 'MediaDisplayType');
export const MediaDisplayTypeValues = ['digitalAsset', 'externalLink', 'facebook', 'instagram', 'linkedIn', 'merchandizingInfo', 'pitchDeck', 'sellSheet', 'signage', 'tiktok', 'twitter', 'vimeo', 'youTube'] as const;

export type MediaStatus = CodegenEnumOpenValues<'draft' | 'published'>;
export type EncodedMediaStatus = string;
export const MediaStatus = CodegenEnumOpen({
  'draft': null,
  'published': null,
}, 'MediaStatus');
export const MediaStatusValues = ['draft', 'published'] as const;

/** View model for rendering a planogram on a front-end */
export type PlanogramDisplayInfo = {
  'id': number,
  'name': string,
  /** The size of the overall canvas */
  'canvas': {
    'width': BigNumber,
    'height': BigNumber,
  },
  'fixtures': Array<PlanogramFixture>,
};
export type EncodedPlanogramDisplayInfo = {
  'id': number,
  'name': string,
  'canvas': {
    'width': string,
    'height': string,
  },
  'fixtures': Array<EncodedPlanogramFixture>,
};
export const PlanogramDisplayInfo: t.Type<PlanogramDisplayInfo, EncodedPlanogramDisplayInfo> = t.recursion('PlanogramDisplayInfo', () => (
  t.exact(t.intersection([t.type({
    'id': t.number,
    'name': t.string,
    'canvas': t.exact(t.intersection([t.type({
      'width': NumberFromString,
      'height': NumberFromString,
    }), t.partial({
      
    })]), 'PlanogramDisplayInfo'),
    'fixtures': t.array(PlanogramFixture),
  }), t.partial({
    
  })]), 'PlanogramDisplayInfo')
));


/** A section of the planogram */
export type PlanogramFixture = {
  'layouts': Array<PlanogramVariantAndLayout>,
  /** The ID from the planogram defintion */
  'id': string,
  'x': BigNumber,
  'y': BigNumber,
  /** The background color that should be used when rendering the fixture. */
  'color': string,
  /** A mysterious numerical type definition that seems to define the type of fixture this is, e.g. pegboard, shelf. */
  'type': string,
  'width': BigNumber,
  'height': BigNumber,
  /** Unsure what this is */
  'horizontalSpacing': BigNumber,
  /** Unsure what this is */
  'verticalSpacing': BigNumber,
  /** Unsure what this is */
  'horizontalStart': BigNumber,
  /** Unsure what this is */
  'verticalStart': BigNumber,
};
export type EncodedPlanogramFixture = {
  'layouts': Array<EncodedPlanogramVariantAndLayout>,
  'id': string,
  'x': string,
  'y': string,
  'color': string,
  'type': string,
  'width': string,
  'height': string,
  'horizontalSpacing': string,
  'verticalSpacing': string,
  'horizontalStart': string,
  'verticalStart': string,
};
export const PlanogramFixture: t.Type<PlanogramFixture, EncodedPlanogramFixture> = t.recursion('PlanogramFixture', () => (
  t.exact(t.intersection([t.type({
    'layouts': t.array(PlanogramVariantAndLayout),
    'id': t.string,
    'x': NumberFromString,
    'y': NumberFromString,
    'color': t.string,
    'type': t.string,
    'width': NumberFromString,
    'height': NumberFromString,
    'horizontalSpacing': NumberFromString,
    'verticalSpacing': NumberFromString,
    'horizontalStart': NumberFromString,
    'verticalStart': NumberFromString,
  }), t.partial({
    
  })]), 'PlanogramFixture')
));


export type PlanogramProductLayout = {
  'x': BigNumber,
  'y': BigNumber,
  'width': BigNumber,
  'height': BigNumber,
};
export type EncodedPlanogramProductLayout = {
  'x': string,
  'y': string,
  'width': string,
  'height': string,
};
export const PlanogramProductLayout: t.Type<PlanogramProductLayout, EncodedPlanogramProductLayout> = t.recursion('PlanogramProductLayout', () => (
  t.exact(t.intersection([t.type({
    'x': NumberFromString,
    'y': NumberFromString,
    'width': NumberFromString,
    'height': NumberFromString,
  }), t.partial({
    
  })]), 'PlanogramProductLayout')
));


/** Variant & planogram layout info (position and size) */
export type PlanogramVariantAndLayout = {
  'variant': ProductVariantListDisplayInfo,
  'layout': PlanogramProductLayout,
};
export type EncodedPlanogramVariantAndLayout = {
  'variant': EncodedProductVariantListDisplayInfo,
  'layout': EncodedPlanogramProductLayout,
};
export const PlanogramVariantAndLayout: t.Type<PlanogramVariantAndLayout, EncodedPlanogramVariantAndLayout> = t.recursion('PlanogramVariantAndLayout', () => (
  t.exact(t.intersection([t.type({
    'variant': ProductVariantListDisplayInfo,
    'layout': PlanogramProductLayout,
  }), t.partial({
    
  })]), 'PlanogramVariantAndLayout')
));


export type PlanogramStatus = CodegenEnumOpenValues<'draft' | 'published'>;
export type EncodedPlanogramStatus = string;
export const PlanogramStatus = CodegenEnumOpen({
  'draft': null,
  'published': null,
}, 'PlanogramStatus');
export const PlanogramStatusValues = ['draft', 'published'] as const;

export type PlanogramBase = {
  'status': PlanogramStatus,
  /** The name of the planogram for display */
  'name': string,
  /** If this content is only relevant for a specific partner, the ID of that partner account. */
  'partnerAccountId'?: number,
  /** The exported Spaceman file contents from OPENCatman */
  'plnExportFileContents': string,
};
export type EncodedPlanogramBase = {
  'status': EncodedPlanogramStatus,
  'name': string,
  'partnerAccountId'?: number,
  'plnExportFileContents': string,
};
export const PlanogramBase: t.Type<PlanogramBase, EncodedPlanogramBase> = t.recursion('PlanogramBase', () => (
  t.exact(t.intersection([t.type({
    'status': PlanogramStatus,
    'name': t.string,
    'plnExportFileContents': t.string,
  }), t.partial({
    'partnerAccountId': t.number,
  })]), 'PlanogramBase')
));


/** Holds export of planogram definitions, which can be parsed for display */
export type Planogram = PlanogramBase & {
  'id': number,
  'createdAt': Date,
  'updatedAt': Date,
};
export type EncodedPlanogram = EncodedPlanogramBase & {
  'id': number,
  'createdAt': string,
  'updatedAt': string,
};
export const Planogram: t.Type<Planogram, EncodedPlanogram> = t.recursion('Planogram', () => (
  t.intersection([
    PlanogramBase,
    t.exact(t.intersection([t.type({
      'id': t.number,
      'createdAt': DateFromISOString,
      'updatedAt': DateFromISOString,
    }), t.partial({
      
    })]), 'Planogram')
  ], 'Planogram')
));


export type PlanogramPatch = {
  'status'?: PlanogramStatus,
  'name'?: string,
  'partnerAccountId'?: null | number,
  'plnExportFileContents'?: string,
};
export type EncodedPlanogramPatch = {
  'status'?: EncodedPlanogramStatus,
  'name'?: string,
  'partnerAccountId'?: null | number,
  'plnExportFileContents'?: string,
};
export const PlanogramPatch: t.Type<PlanogramPatch, EncodedPlanogramPatch> = t.recursion('PlanogramPatch', () => (
  t.exact(t.intersection([t.type({
    
  }), t.partial({
    'status': PlanogramStatus,
    'name': t.string,
    'partnerAccountId': t.union([t.null, t.number]),
    'plnExportFileContents': t.string,
  })]), 'PlanogramPatch')
));


export type SellerAnnualGrossRevenueRange = CodegenEnumOpenValues<'refusedToAnswer' | '$0M - $1M' | '$1M - $5M' | '$5M - $10M' | '$10M - $50M' | '$50M+'>;
export type EncodedSellerAnnualGrossRevenueRange = string;
export const SellerAnnualGrossRevenueRange = CodegenEnumOpen({
  'refusedToAnswer': null,
  '$0M - $1M': null,
  '$1M - $5M': null,
  '$5M - $10M': null,
  '$10M - $50M': null,
  '$50M+': null,
}, 'SellerAnnualGrossRevenueRange');
export const SellerAnnualGrossRevenueRangeValues = ['refusedToAnswer', '$0M - $1M', '$1M - $5M', '$5M - $10M', '$10M - $50M', '$50M+'] as const;

export type SellerAnnualMarketingBudgetRange = CodegenEnumOpenValues<'Zero' | '$1 - $25K' | '$25k - $50K' | '$50k - $200K' | '$200k - $500K' | '$500k - $750K' | '$750k - $1M' | '$1M - $2M' | '$2M - $5M' | '$5M+'>;
export type EncodedSellerAnnualMarketingBudgetRange = string;
export const SellerAnnualMarketingBudgetRange = CodegenEnumOpen({
  'Zero': null,
  '$1 - $25K': null,
  '$25k - $50K': null,
  '$50k - $200K': null,
  '$200k - $500K': null,
  '$500k - $750K': null,
  '$750k - $1M': null,
  '$1M - $2M': null,
  '$2M - $5M': null,
  '$5M+': null,
}, 'SellerAnnualMarketingBudgetRange');
export const SellerAnnualMarketingBudgetRangeValues = ['Zero', '$1 - $25K', '$25k - $50K', '$50k - $200K', '$200k - $500K', '$500k - $750K', '$750k - $1M', '$1M - $2M', '$2M - $5M', '$5M+'] as const;

export type SellerRetailLocationCountRange = CodegenEnumOpenValues<'1 - 10' | '11 - 50' | '51 - 100' | '100 - 500' | '501 - 1,000' | '1,001+'>;
export type EncodedSellerRetailLocationCountRange = string;
export const SellerRetailLocationCountRange = CodegenEnumOpen({
  '1 - 10': null,
  '11 - 50': null,
  '51 - 100': null,
  '100 - 500': null,
  '501 - 1,000': null,
  '1,001+': null,
}, 'SellerRetailLocationCountRange');
export const SellerRetailLocationCountRangeValues = ['1 - 10', '11 - 50', '51 - 100', '100 - 500', '501 - 1,000', '1,001+'] as const;

export const routeInfo = {
  'GET /v1/addresses': {
    method: 'get',
    path: '/v1/addresses',
    accept: 'application/json',
    inputLocations: {
      'status': 'query',
      'type': 'query',
    },
    inputCodec: t.exact(t.intersection([
      t.type({
        
      }),
      t.partial({
        'status': StringArrayCodec(URLStringCodec),
        'type': StringArrayCodec(AddressType),
      })
    ]), 'GET /v1/addresses Input Codec'),
    responseCodec: t.exact(t.intersection([t.type({
      'addresses': t.array(Address),
    }), t.partial({
      
    })])),
    responseCodecs: {
      200: t.exact(t.intersection([t.type({
        'addresses': t.array(Address),
      }), t.partial({
        
      })])),
    },
    
    security: [{"MableOauth2":["admin","buyer","seller"]}],
  },
  'POST /v1/addresses': {
    method: 'post',
    path: '/v1/addresses',
    accept: 'application/json',
    inputLocations: {
      'addressInput': 'body',
      'userId': 'query',
      'accountId': 'query',
    },
    inputCodec: t.exact(t.intersection([
      t.type({
        'addressInput': t.intersection([
          AddressInput,
          t.exact(t.intersection([t.type({
            
          }), t.partial({
            /** Optional buyer Location to associate this address with */
            'buyerLocationId': t.string,
          })]), 'addressInput')
        ], 'addressInput'),
      }),
      t.partial({
        /** The user this address should belong to. Defaults to the logged in user. Only allowed for proxies & admins. */
        'userId': StringNumberCodec,
        /** The account this address should belong to. Defaults to the logged in account or the account of the given userId. Only allowed for proxies & admins. */
        'accountId': StringNumberCodec,
      })
    ]), 'POST /v1/addresses Input Codec'),
    responseCodec: Address,
    responseCodecs: {
      201: Address,
    },
    
    security: [{"MableOauth2":["admin","buyer","seller"]}],
  },
  'POST /v1/verifyAddress': {
    method: 'post',
    path: '/v1/verifyAddress',
    accept: 'application/json',
    inputLocations: {
      'addressInput': 'body',
    },
    inputCodec: t.exact(t.intersection([
      t.type({
        'addressInput': AddressInput,
      }),
      t.partial({
        
      })
    ]), 'POST /v1/verifyAddress Input Codec'),
    responseCodec: t.exact(t.intersection([t.type({
      'deliverability': AddressDeliverability,
      'originalAddress': AddressInput,
    }), t.partial({
      'suggestedAddress': AddressInput,
      /** If verification fails and this is present, display it to the user. */
      'verficationErrorMessage': t.string,
      /** If the changes in the suggested address are minimal, and can be accepted without user input, this will be `true`. */
      'canAutoAcceptSuggestion': t.boolean,
    })])),
    responseCodecs: {
      200: t.exact(t.intersection([t.type({
        'deliverability': AddressDeliverability,
        'originalAddress': AddressInput,
      }), t.partial({
        'suggestedAddress': AddressInput,
        /** If verification fails and this is present, display it to the user. */
        'verficationErrorMessage': t.string,
        /** If the changes in the suggested address are minimal, and can be accepted without user input, this will be `true`. */
        'canAutoAcceptSuggestion': t.boolean,
      })])),
    },
    
    security: [{"MableOauth2":["admin","buyer","seller"]}],
  },
  'GET /v1/addresses/{addressId}': {
    method: 'get',
    path: '/v1/addresses/{addressId}',
    accept: 'application/json',
    inputLocations: {
      'addressId': 'path',
    },
    inputCodec: t.exact(t.intersection([
      t.type({
        'addressId': StringNumberCodec,
      }),
      t.partial({
        
      })
    ]), 'GET /v1/addresses/{addressId} Input Codec'),
    responseCodec: Address,
    responseCodecs: {
      200: Address,
    },
    
    security: [{"MableOauth2":["admin","buyer","seller"]}],
  },
  'PUT /v1/addresses/{addressId}': {
    method: 'put',
    path: '/v1/addresses/{addressId}',
    accept: 'application/json',
    inputLocations: {
      'addressId': 'path',
      'addressInput': 'body',
      'userId': 'query',
      'accountId': 'query',
    },
    inputCodec: t.exact(t.intersection([
      t.type({
        'addressId': StringNumberCodec,
        'addressInput': AddressInput,
      }),
      t.partial({
        /** The user id for which this address belongs to. Defaults to the logged in user. Only allowed for proxies & admins. */
        'userId': StringNumberCodec,
        /** The account which this address belongs to. Defaults to the logged in account or the account of the given userId. Only allowed for proxies & admins. */
        'accountId': StringNumberCodec,
      })
    ]), 'PUT /v1/addresses/{addressId} Input Codec'),
    responseCodec: Address,
    responseCodecs: {
      200: Address,
    },
    
    security: [{"MableOauth2":["admin","buyer","seller"]}],
  },
  'DELETE /v1/addresses/{addressId}': {
    method: 'delete',
    path: '/v1/addresses/{addressId}',
    accept: 'undefined',
    inputLocations: {
      'addressId': 'path',
    },
    inputCodec: t.exact(t.intersection([
      t.type({
        'addressId': StringNumberCodec,
      }),
      t.partial({
        
      })
    ]), 'DELETE /v1/addresses/{addressId} Input Codec'),
    responseCodec: t.void,
    responseCodecs: {
      204: undefined,
    },
    
    security: [{"MableOauth2":["admin","buyer","seller"]}],
  },
  'PUT /v1/addresses/{addressId}/confirm': {
    method: 'put',
    path: '/v1/addresses/{addressId}/confirm',
    accept: 'application/json',
    inputLocations: {
      'addressId': 'path',
      'userId': 'query',
      'type': 'query',
    },
    inputCodec: t.exact(t.intersection([
      t.type({
        'addressId': StringNumberCodec,
        /** Specifies which address is being confirmed. 'original' signifies the user wants to use their originally entered address. 'correction' signifies we should use the corrected address and update our address on record. */
        'type': CodegenEnumOpen({
          'original': null,
          'correction': null,
        }, 'type'),
      }),
      t.partial({
        /** The user id for which this address belongs to. Defaults to the logged in user. */
        'userId': StringNumberCodec,
      })
    ]), 'PUT /v1/addresses/{addressId}/confirm Input Codec'),
    responseCodec: Address,
    responseCodecs: {
      200: Address,
    },
    
    security: [{"MableOauth2":["admin","buyer","seller"]}],
  },
  'GET /v1/account': {
    method: 'get',
    path: '/v1/account',
    accept: 'application/json',
    inputLocations: {
      'accountId': 'query',
      'invitationToken': 'query',
      'include': 'query',
    },
    inputCodec: t.exact(t.intersection([
      t.type({
        
      }),
      t.partial({
        /** Get the account info for this account instead of the authenticated user's. */
        'accountId': StringNumberCodec,
        /** Get the account info associated with the invitation. */
        'invitationToken': URLStringCodec,
        'include': StringArrayCodec(CodegenEnumClosed({
          'buyer': null,
          'seller': null,
          'users': null,
        }, 'includeItem')),
      })
    ]), 'GET /v1/account Input Codec'),
    responseCodec: AccountDisplayInfo,
    responseCodecs: {
      200: AccountDisplayInfo,
    },
    
    security: [{"MableOauth2":["admin","buyer","seller"]},{"NoAuth":[]}],
  },
  'POST /v1/account/emailInvitations': {
    method: 'post',
    path: '/v1/account/emailInvitations',
    accept: 'undefined',
    inputLocations: {
      'emailInviteInfo': 'body',
      'type': 'query',
    },
    inputCodec: t.exact(t.intersection([
      t.type({
        'emailInviteInfo': EmailInviteInfo,
        'type': CodegenEnumClosed({
          'buyer': null,
          'seller': null,
        }, 'type'),
      }),
      t.partial({
        
      })
    ]), 'POST /v1/account/emailInvitations Input Codec'),
    responseCodec: t.void,
    responseCodecs: {
      204: undefined,
    },
    
    security: [{"MableOauth2":["admin","seller","buyer"]}],
  },
  'PUT /v1/account/emailInvite/{email}': {
    method: 'put',
    path: '/v1/account/emailInvite/{email}',
    accept: 'undefined',
    inputLocations: {
      'email': 'path',
      'body': 'body',
      'type': 'query',
    },
    inputCodec: t.exact(t.intersection([
      t.type({
        'email': URLStringCodec,
        'type': CodegenEnumClosed({
          'buyer': null,
          'seller': null,
        }, 'type'),
      }),
      t.partial({
        'body': t.exact(t.intersection([t.type({
          
        }), t.partial({
          'buyerLocationId': t.string,
        })]), 'body'),
      })
    ]), 'PUT /v1/account/emailInvite/{email} Input Codec'),
    responseCodec: t.void,
    responseCodecs: {
      204: undefined,
    },
    
    security: [{"MableOauth2":["admin","seller","buyer"]}],
  },
  'DELETE /v1/account/emailInvite/{email}': {
    method: 'delete',
    path: '/v1/account/emailInvite/{email}',
    accept: 'undefined',
    inputLocations: {
      'email': 'path',
      'body': 'body',
    },
    inputCodec: t.exact(t.intersection([
      t.type({
        'email': URLStringCodec,
      }),
      t.partial({
        'body': t.exact(t.intersection([t.type({
          
        }), t.partial({
          'buyerLocationId': t.string,
        })]), 'body'),
      })
    ]), 'DELETE /v1/account/emailInvite/{email} Input Codec'),
    responseCodec: t.void,
    responseCodecs: {
      204: undefined,
    },
    
    security: [{"MableOauth2":["admin","seller","buyer"]}],
  },
  'DELETE /v1/account/@me/{userId}': {
    method: 'delete',
    path: '/v1/account/@me/{userId}',
    accept: 'undefined',
    inputLocations: {
      'userId': 'path',
    },
    inputCodec: t.exact(t.intersection([
      t.type({
        'userId': StringNumberCodec,
      }),
      t.partial({
        
      })
    ]), 'DELETE /v1/account/@me/{userId} Input Codec'),
    responseCodec: t.void,
    responseCodecs: {
      204: undefined,
    },
    
    security: [{"MableOauth2":["admin","seller","buyer"]}],
  },
  'PUT /v1/account/mergeByInvitation': {
    method: 'put',
    path: '/v1/account/mergeByInvitation',
    accept: 'undefined',
    inputLocations: {
      'invitationToken': 'query',
    },
    inputCodec: t.exact(t.intersection([
      t.type({
        'invitationToken': URLStringCodec,
      }),
      t.partial({
        
      })
    ]), 'PUT /v1/account/mergeByInvitation Input Codec'),
    responseCodec: t.void,
    responseCodecs: {
      204: undefined,
    },
    
    security: [{"MableOauth2":["buyer"]}],
  },
  'POST /v1/account/users/autoAddToSellerMessageChannels': {
    method: 'post',
    path: '/v1/account/users/autoAddToSellerMessageChannels',
    accept: 'undefined',
    inputLocations: {
      'body': 'body',
    },
    inputCodec: t.exact(t.intersection([
      t.type({
        'body': t.exact(t.intersection([t.type({
          /** IDs of the users you want to turn auto-add on for */
          'userIds': t.array(t.number),
        }), t.partial({
          
        })]), 'body'),
      }),
      t.partial({
        
      })
    ]), 'POST /v1/account/users/autoAddToSellerMessageChannels Input Codec'),
    responseCodec: t.void,
    responseCodecs: {
      200: undefined,
    },
    
    security: [{"MableOauth2":["seller","admin"]}],
  },
  'POST /v1/auth/login': {
    method: 'post',
    path: '/v1/auth/login',
    accept: 'application/json',
    inputLocations: {
      'body': 'body',
    },
    inputCodec: t.exact(t.intersection([
      t.type({
        'body': t.exact(t.intersection([t.type({
          'email': t.string,
          'password': t.string,
        }), t.partial({
          
        })]), 'body'),
      }),
      t.partial({
        
      })
    ]), 'POST /v1/auth/login Input Codec'),
    responseCodec: t.exact(t.intersection([t.type({
      'accessToken': t.string,
      'user': Auth0User,
      'permissions': t.array(t.string),
    }), t.partial({
      
    })])),
    responseCodecs: {
      200: t.exact(t.intersection([t.type({
        'accessToken': t.string,
        'user': Auth0User,
        'permissions': t.array(t.string),
      }), t.partial({
        
      })])),
    },
    
    security: [{"MableOauth2":[]},{"NoAuth":[]}],
  },
  'POST /v1/auth/session': {
    method: 'post',
    path: '/v1/auth/session',
    accept: 'application/json',
    inputLocations: {
      
    },
    inputCodec: t.exact(t.intersection([
      t.type({
        
      }),
      t.partial({
        
      })
    ]), 'POST /v1/auth/session Input Codec'),
    responseCodec: t.exact(t.intersection([t.type({
      'accessToken': t.string,
      'user': Auth0User,
      'permissions': t.array(t.string),
    }), t.partial({
      
    })])),
    responseCodecs: {
      200: t.exact(t.intersection([t.type({
        'accessToken': t.string,
        'user': Auth0User,
        'permissions': t.array(t.string),
      }), t.partial({
        
      })])),
    },
    
    security: [{"MableOauth2":[]},{"NoAuth":[]},{"ExpiredAuth":[]}],
  },
  'GET /v1/auth/logout': {
    method: 'get',
    path: '/v1/auth/logout',
    accept: 'application/json',
    inputLocations: {
      
    },
    inputCodec: t.exact(t.intersection([
      t.type({
        
      }),
      t.partial({
        
      })
    ]), 'GET /v1/auth/logout Input Codec'),
    responseCodec: t.void,
    responseCodecs: {
      302: undefined,
    },
    
    security: [{"MableOauth2":[]},{"NoAuth":[]},{"ExpiredAuth":[]}],
  },
  'POST /v1/auth/resetPassword': {
    method: 'post',
    path: '/v1/auth/resetPassword',
    accept: 'undefined',
    inputLocations: {
      'body': 'body',
    },
    inputCodec: t.exact(t.intersection([
      t.type({
        'body': t.exact(t.intersection([t.type({
          'email': t.string,
        }), t.partial({
          
        })]), 'body'),
      }),
      t.partial({
        
      })
    ]), 'POST /v1/auth/resetPassword Input Codec'),
    responseCodec: t.void,
    responseCodecs: {
      204: undefined,
    },
    
    security: [{"MableOauth2":[]},{"NoAuth":[]}],
  },
  'POST /v1/auth/passwordless/start': {
    method: 'post',
    path: '/v1/auth/passwordless/start',
    accept: 'undefined',
    inputLocations: {
      'body': 'body',
    },
    inputCodec: t.exact(t.intersection([
      t.type({
        'body': t.exact(t.intersection([t.type({
          'email': t.string,
        }), t.partial({
          
        })]), 'body'),
      }),
      t.partial({
        
      })
    ]), 'POST /v1/auth/passwordless/start Input Codec'),
    responseCodec: t.void,
    responseCodecs: {
      204: undefined,
    },
    
    security: [{"MableOauth2":[]},{"NoAuth":[]}],
  },
  'POST /v1/auth/oauth/token': {
    method: 'post',
    path: '/v1/auth/oauth/token',
    accept: 'undefined',
    inputLocations: {
      'body': 'body',
    },
    inputCodec: t.exact(t.intersection([
      t.type({
        'body': t.exact(t.intersection([t.type({
          'code': t.string,
          'email': t.string,
        }), t.partial({
          
        })]), 'body'),
      }),
      t.partial({
        
      })
    ]), 'POST /v1/auth/oauth/token Input Codec'),
    responseCodec: t.void,
    responseCodecs: {
      204: undefined,
    },
    
    security: [{"MableOauth2":[]},{"NoAuth":[]}],
  },
  'POST /v1/coldStartSeller': {
    method: 'post',
    path: '/v1/coldStartSeller',
    accept: 'application/json',
    inputLocations: {
      'body': 'body',
    },
    inputCodec: t.exact(t.intersection([
      t.type({
        'body': t.exact(t.intersection([t.type({
          'name': t.string,
          'email': t.string,
        }), t.partial({
          
        })]), 'body'),
      }),
      t.partial({
        
      })
    ]), 'POST /v1/coldStartSeller Input Codec'),
    responseCodec: SellerDisplayInfo,
    responseCodecs: {
      201: SellerDisplayInfo,
    },
    
    security: [{"MableOauth2":["admin","buyer"]}],
  },
  'PATCH /v1/coldStartSeller/{sellerIdOrSlug}': {
    method: 'patch',
    path: '/v1/coldStartSeller/{sellerIdOrSlug}',
    accept: 'application/json',
    inputLocations: {
      'sellerIdOrSlug': 'path',
      'body': 'body',
    },
    inputCodec: t.exact(t.intersection([
      t.type({
        'sellerIdOrSlug': URLStringCodec,
        'body': t.exact(t.intersection([t.type({
          
        }), t.partial({
          'name': t.string,
          'email': t.string,
        })]), 'body'),
      }),
      t.partial({
        
      })
    ]), 'PATCH /v1/coldStartSeller/{sellerIdOrSlug} Input Codec'),
    responseCodec: SellerDisplayInfo,
    responseCodecs: {
      201: SellerDisplayInfo,
    },
    
    security: [{"MableOauth2":["admin","buyer"]}],
  },
  'GET /v1/contacts': {
    method: 'get',
    path: '/v1/contacts',
    accept: 'application/json',
    inputLocations: {
      'type': 'query',
    },
    inputCodec: t.exact(t.intersection([
      t.type({
        
      }),
      t.partial({
        'type': ContactType,
      })
    ]), 'GET /v1/contacts Input Codec'),
    responseCodec: t.array(Contact),
    responseCodecs: {
      200: t.array(Contact),
    },
    
    security: [{"MableOauth2":["admin","buyer"]}],
  },
  'PUT /v1/contacts': {
    method: 'put',
    path: '/v1/contacts',
    accept: 'application/json',
    inputLocations: {
      'contactsInput': 'body',
    },
    inputCodec: t.exact(t.intersection([
      t.type({
        'contactsInput': t.array(ContactsInput),
      }),
      t.partial({
        
      })
    ]), 'PUT /v1/contacts Input Codec'),
    responseCodec: t.array(Contact),
    responseCodecs: {
      200: t.array(Contact),
    },
    
    security: [{"MableOauth2":["admin","buyer"]}],
  },
  'POST /v1/admin/bulkImportAccounts': {
    method: 'post',
    path: '/v1/admin/bulkImportAccounts',
    accept: 'undefined',
    inputLocations: {
      'body': 'body',
    },
    inputCodec: t.exact(t.intersection([
      t.type({
        'body': t.exact(t.intersection([t.type({
          'csv': t.string,
        }), t.partial({
          /** Account to make all locations for. Do not use when CSV has account rows */
          'accountId': t.number,
          /** User to make addresses for. Do not use when CSV has account rows */
          'userId': t.number,
        })]), 'body'),
      }),
      t.partial({
        
      })
    ]), 'POST /v1/admin/bulkImportAccounts Input Codec'),
    responseCodec: t.void,
    responseCodecs: {
      200: undefined,
    },
    
    security: [{"MableOauth2":["admin"]}],
  },
  'POST /v1/admin/bulkImportVariantIdentifiers': {
    method: 'post',
    path: '/v1/admin/bulkImportVariantIdentifiers',
    accept: 'undefined',
    inputLocations: {
      'body': 'body',
    },
    inputCodec: t.exact(t.intersection([
      t.type({
        'body': t.exact(t.intersection([t.type({
          'csv': t.string,
        }), t.partial({
          
        })]), 'body'),
      }),
      t.partial({
        
      })
    ]), 'POST /v1/admin/bulkImportVariantIdentifiers Input Codec'),
    responseCodec: t.void,
    responseCodecs: {
      200: undefined,
    },
    
    security: [{"MableOauth2":["admin"]}],
  },
  'GET /v1/admin/contacts': {
    method: 'get',
    path: '/v1/admin/contacts',
    accept: 'application/json',
    inputLocations: {
      'buyerId': 'query',
      'sellerId': 'query',
      'buyerLocationId': 'query',
      'type': 'query',
    },
    inputCodec: t.exact(t.intersection([
      t.type({
        
      }),
      t.partial({
        'buyerId': StringNumberCodec,
        'sellerId': StringNumberCodec,
        'buyerLocationId': URLStringCodec,
        'type': ContactType,
      })
    ]), 'GET /v1/admin/contacts Input Codec'),
    responseCodec: t.array(ContactWithRelationship),
    responseCodecs: {
      200: t.array(ContactWithRelationship),
    },
    
    security: [{"MableOauth2":["admin"]}],
  },
  'POST /v1/admin/contacts': {
    method: 'post',
    path: '/v1/admin/contacts',
    accept: 'application/json',
    inputLocations: {
      'contactInput': 'body',
    },
    inputCodec: t.exact(t.intersection([
      t.type({
        'contactInput': ContactInput,
      }),
      t.partial({
        
      })
    ]), 'POST /v1/admin/contacts Input Codec'),
    responseCodec: t.exact(t.intersection([t.type({
      'contact': Contact,
    }), t.partial({
      
    })])),
    responseCodecs: {
      200: t.exact(t.intersection([t.type({
        'contact': Contact,
      }), t.partial({
        
      })])),
    },
    
    security: [{"MableOauth2":["admin"]}],
  },
  'GET /v1/admin/contacts/{contactId}': {
    method: 'get',
    path: '/v1/admin/contacts/{contactId}',
    accept: 'application/json',
    inputLocations: {
      'contactId': 'path',
    },
    inputCodec: t.exact(t.intersection([
      t.type({
        'contactId': StringNumberCodec,
      }),
      t.partial({
        
      })
    ]), 'GET /v1/admin/contacts/{contactId} Input Codec'),
    responseCodec: t.exact(t.intersection([t.type({
      'contact': Contact,
    }), t.partial({
      
    })])),
    responseCodecs: {
      200: t.exact(t.intersection([t.type({
        'contact': Contact,
      }), t.partial({
        
      })])),
    },
    
    security: [{"MableOauth2":["admin"]}],
  },
  'DELETE /v1/admin/contacts/{contactId}': {
    method: 'delete',
    path: '/v1/admin/contacts/{contactId}',
    accept: 'undefined',
    inputLocations: {
      'contactId': 'path',
    },
    inputCodec: t.exact(t.intersection([
      t.type({
        'contactId': StringNumberCodec,
      }),
      t.partial({
        
      })
    ]), 'DELETE /v1/admin/contacts/{contactId} Input Codec'),
    responseCodec: t.void,
    responseCodecs: {
      204: undefined,
    },
    
    security: [{"MableOauth2":["admin"]}],
  },
  'PUT /v1/admin/contacts/{contactId}': {
    method: 'put',
    path: '/v1/admin/contacts/{contactId}',
    accept: 'application/json',
    inputLocations: {
      'contactInput': 'body',
      'contactId': 'path',
    },
    inputCodec: t.exact(t.intersection([
      t.type({
        'contactInput': ContactInput,
        'contactId': StringNumberCodec,
      }),
      t.partial({
        
      })
    ]), 'PUT /v1/admin/contacts/{contactId} Input Codec'),
    responseCodec: t.exact(t.intersection([t.type({
      'contact': Contact,
    }), t.partial({
      
    })])),
    responseCodecs: {
      200: t.exact(t.intersection([t.type({
        'contact': Contact,
      }), t.partial({
        
      })])),
    },
    
    security: [{"MableOauth2":["admin"]}],
  },
  'DELETE /v1/admin/contacts/business/{contactId}': {
    method: 'delete',
    path: '/v1/admin/contacts/business/{contactId}',
    accept: 'undefined',
    inputLocations: {
      'contactId': 'path',
      'body': 'body',
    },
    inputCodec: t.exact(t.intersection([
      t.type({
        'contactId': StringNumberCodec,
        'body': t.exact(t.intersection([t.type({
          
        }), t.partial({
          'buyerId': t.number,
          'sellerId': t.number,
        })]), 'body'),
      }),
      t.partial({
        
      })
    ]), 'DELETE /v1/admin/contacts/business/{contactId} Input Codec'),
    responseCodec: t.void,
    responseCodecs: {
      204: undefined,
    },
    
    security: [{"MableOauth2":["admin"]}],
  },
  'GET /v1/homepage': {
    method: 'get',
    path: '/v1/homepage',
    accept: 'application/json',
    inputLocations: {
      'limitCategories': 'query',
    },
    inputCodec: t.exact(t.intersection([
      t.type({
        
      }),
      t.partial({
        /** Number of results per page */
        'limitCategories': StringNumberCodec,
      })
    ]), 'GET /v1/homepage Input Codec'),
    responseCodec: t.exact(t.intersection([t.type({
      'categories': t.array(t.exact(t.intersection([t.type({
        'category': Category,
        /** Limited to 6 */
        'variants': t.array(ProductVariantListDisplayInfo),
      }), t.partial({
        
      })]))),
      'topCategories': t.array(Category),
    }), t.partial({
      
    })])),
    responseCodecs: {
      200: t.exact(t.intersection([t.type({
        'categories': t.array(t.exact(t.intersection([t.type({
          'category': Category,
          /** Limited to 6 */
          'variants': t.array(ProductVariantListDisplayInfo),
        }), t.partial({
          
        })]))),
        'topCategories': t.array(Category),
      }), t.partial({
        
      })])),
    },
    
    security: [{"NoAuth":[]},{"MableOauth2":["admin","buyer","seller"]}],
  },
  'GET /v1/categories': {
    method: 'get',
    path: '/v1/categories',
    accept: 'application/json',
    inputLocations: {
      'include': 'query',
      'navigable': 'query',
    },
    inputCodec: t.exact(t.intersection([
      t.type({
        
      }),
      t.partial({
        'include': StringArrayCodec(CodegenEnumClosed({
          'parentMemberships': null,
          'childMemberships': null,
          'productCount': null,
          'sellerCount': null,
        }, 'includeItem')),
        'navigable': StringBooleanCodec,
      })
    ]), 'GET /v1/categories Input Codec'),
    responseCodec: t.exact(t.intersection([t.type({
      'categories': t.array(Category),
    }), t.partial({
      
    })])),
    responseCodecs: {
      200: t.exact(t.intersection([t.type({
        'categories': t.array(Category),
      }), t.partial({
        
      })])),
    },
    
    security: [{"NoAuth":[]},{"MableOauth2":["admin","buyer","seller"]}],
  },
  'GET /v1/categories/{categoryIdOrSlug}': {
    method: 'get',
    path: '/v1/categories/{categoryIdOrSlug}',
    accept: 'application/json',
    inputLocations: {
      'categoryIdOrSlug': 'path',
      'include': 'query',
      'childCategoryVariantLimit': 'query',
      'childCategorySellerLimit': 'query',
      'appliedFilters': 'query',
      'sortMethodId': 'query',
      'page': 'query',
      'pageSize': 'query',
    },
    inputCodec: t.exact(t.intersection([
      t.type({
        'categoryIdOrSlug': URLStringCodec,
      }),
      t.partial({
        'include': StringArrayCodec(CodegenEnumClosed({
          'variants': null,
          'sellers': null,
          'variantsFromSellers': null,
          'childCategories': null,
          'parentCategory': null,
          'siblingCategories': null,
          'popularBrands': null,
          'filters': null,
        }, 'includeItem')),
        /** The maximum number of variants returned for child categories if variants and childCategories are included */
        'childCategoryVariantLimit': StringNumberCodec,
        /** The maximum number of sellers returned for child categories if sellers and childCategories are included */
        'childCategorySellerLimit': StringNumberCodec,
        /** Encoded string of applied filters */
        'appliedFilters': URLStringCodec,
        /** How variant results should be sorted. Acceptable searchMethods are returned in the search response. Defaults to 'recommended' */
        'sortMethodId': URLStringCodec,
        /** Current page of variants if requesting a non-parent category */
        'page': StringNumberCodec,
        /** Variants per page if requesting a non-parent category */
        'pageSize': StringNumberCodec,
      })
    ]), 'GET /v1/categories/{categoryIdOrSlug} Input Codec'),
    responseCodec: FullCategory,
    responseCodecs: {
      200: FullCategory,
    },
    
    security: [{"NoAuth":[]},{"MableOauth2":["admin","buyer","seller"]}],
  },
  'GET /v1/categories/{categoryIdOrSlug}/csv': {
    method: 'get',
    path: '/v1/categories/{categoryIdOrSlug}/csv',
    accept: 'application/json',
    inputLocations: {
      'categoryIdOrSlug': 'path',
    },
    inputCodec: t.exact(t.intersection([
      t.type({
        'categoryIdOrSlug': URLStringCodec,
      }),
      t.partial({
        
      })
    ]), 'GET /v1/categories/{categoryIdOrSlug}/csv Input Codec'),
    responseCodec: t.exact(t.intersection([t.type({
      'csvString': t.string,
    }), t.partial({
      
    })])),
    responseCodecs: {
      200: t.exact(t.intersection([t.type({
        'csvString': t.string,
      }), t.partial({
        
      })])),
    },
    
    security: [{"MableOauth2":["buyer"]}],
  },
  'GET /v1/admin/categories/{categoryIdOrSlug}/csv': {
    method: 'get',
    path: '/v1/admin/categories/{categoryIdOrSlug}/csv',
    accept: 'application/json',
    inputLocations: {
      'categoryIdOrSlug': 'path',
    },
    inputCodec: t.exact(t.intersection([
      t.type({
        'categoryIdOrSlug': URLStringCodec,
      }),
      t.partial({
        
      })
    ]), 'GET /v1/admin/categories/{categoryIdOrSlug}/csv Input Codec'),
    responseCodec: t.exact(t.intersection([t.type({
      'csvString': t.string,
    }), t.partial({
      
    })])),
    responseCodecs: {
      200: t.exact(t.intersection([t.type({
        'csvString': t.string,
      }), t.partial({
        
      })])),
    },
    
    security: [{"MableOauth2":["admin"]}],
  },
  'GET /v1/admin/categories/{categoryIdOrSlug}/ediPricebook': {
    method: 'get',
    path: '/v1/admin/categories/{categoryIdOrSlug}/ediPricebook',
    accept: 'application/json',
    inputLocations: {
      'categoryIdOrSlug': 'path',
      'accountId': 'query',
    },
    inputCodec: t.exact(t.intersection([
      t.type({
        'categoryIdOrSlug': URLStringCodec,
        'accountId': StringNumberCodec,
      }),
      t.partial({
        
      })
    ]), 'GET /v1/admin/categories/{categoryIdOrSlug}/ediPricebook Input Codec'),
    responseCodec: t.exact(t.intersection([t.type({
      'contents': t.string,
      'filename': t.string,
    }), t.partial({
      
    })])),
    responseCodecs: {
      200: t.exact(t.intersection([t.type({
        'contents': t.string,
        'filename': t.string,
      }), t.partial({
        
      })])),
    },
    
    security: [{"MableOauth2":["admin"]}],
  },
  'GET /v1/admin/categories/{categoryId}/feedback': {
    method: 'get',
    path: '/v1/admin/categories/{categoryId}/feedback',
    accept: 'application/json',
    inputLocations: {
      'categoryId': 'path',
    },
    inputCodec: t.exact(t.intersection([
      t.type({
        'categoryId': StringNumberCodec,
      }),
      t.partial({
        
      })
    ]), 'GET /v1/admin/categories/{categoryId}/feedback Input Codec'),
    responseCodec: t.exact(t.intersection([t.type({
      'category': Category,
      'feedback': t.array(CategoryFeedback),
      'users': t.array(t.exact(t.intersection([t.type({
        'userId': t.number,
        'accountId': t.number,
      }), t.partial({
        'firstName': t.string,
        'lastName': t.string,
      })]))),
      'buyers': t.array(t.exact(t.intersection([t.type({
        'id': t.number,
        'accountId': t.number,
        'name': t.string,
      }), t.partial({
        
      })]))),
      'sellers': t.array(t.exact(t.intersection([t.type({
        'id': t.number,
        'name': t.string,
      }), t.partial({
        
      })]))),
    }), t.partial({
      
    })])),
    responseCodecs: {
      200: t.exact(t.intersection([t.type({
        'category': Category,
        'feedback': t.array(CategoryFeedback),
        'users': t.array(t.exact(t.intersection([t.type({
          'userId': t.number,
          'accountId': t.number,
        }), t.partial({
          'firstName': t.string,
          'lastName': t.string,
        })]))),
        'buyers': t.array(t.exact(t.intersection([t.type({
          'id': t.number,
          'accountId': t.number,
          'name': t.string,
        }), t.partial({
          
        })]))),
        'sellers': t.array(t.exact(t.intersection([t.type({
          'id': t.number,
          'name': t.string,
        }), t.partial({
          
        })]))),
      }), t.partial({
        
      })])),
    },
    
    security: [{"MableOauth2":["admin"]}],
  },
  'GET /v1/collections/{categoryIdOrSlug}': {
    method: 'get',
    path: '/v1/collections/{categoryIdOrSlug}',
    accept: 'application/json',
    inputLocations: {
      'categoryIdOrSlug': 'path',
      'categoriesVariantPageSize': 'query',
      'include': 'query',
    },
    inputCodec: t.exact(t.intersection([
      t.type({
        'categoryIdOrSlug': URLStringCodec,
      }),
      t.partial({
        'categoriesVariantPageSize': StringNumberCodec,
        'include': StringArrayCodec(CodegenEnumClosed({
          'sellerCategoryMemberships': null,
        }, 'includeItem')),
      })
    ]), 'GET /v1/collections/{categoryIdOrSlug} Input Codec'),
    responseCodec: t.exact(t.intersection([t.type({
      'category': Category,
    }), t.partial({
      'variants': t.array(ProductVariantListDisplayInfo),
      'sellers': t.array(SellerDisplayInfo),
      'categories': t.array(CategoryDisplayInfo),
    })])),
    responseCodecs: {
      200: t.exact(t.intersection([t.type({
        'category': Category,
      }), t.partial({
        'variants': t.array(ProductVariantListDisplayInfo),
        'sellers': t.array(SellerDisplayInfo),
        'categories': t.array(CategoryDisplayInfo),
      })])),
    },
    
    security: [{"NoAuth":[]},{"MableOauth2":["admin","buyer","seller"]}],
  },
  'PUT /v1/categories/{categoryId}/sellers/{sellerId}/feedback': {
    method: 'put',
    path: '/v1/categories/{categoryId}/sellers/{sellerId}/feedback',
    accept: 'undefined',
    inputLocations: {
      'categoryId': 'path',
      'sellerId': 'path',
      'body': 'body',
    },
    inputCodec: t.exact(t.intersection([
      t.type({
        'categoryId': StringNumberCodec,
        'sellerId': StringNumberCodec,
        'body': t.exact(t.intersection([t.type({
          
        }), t.partial({
          'interest': CategoryFeedbackInterest,
          'note': t.string,
        })]), 'body'),
      }),
      t.partial({
        
      })
    ]), 'PUT /v1/categories/{categoryId}/sellers/{sellerId}/feedback Input Codec'),
    responseCodec: t.void,
    responseCodecs: {
      204: undefined,
    },
    
    security: [{"MableOauth2":["buyer"]}],
  },
  'GET /v1/categories/{categoryIdOrSlug}/feedback': {
    method: 'get',
    path: '/v1/categories/{categoryIdOrSlug}/feedback',
    accept: 'application/json',
    inputLocations: {
      'categoryIdOrSlug': 'path',
    },
    inputCodec: t.exact(t.intersection([
      t.type({
        'categoryIdOrSlug': URLStringCodec,
      }),
      t.partial({
        
      })
    ]), 'GET /v1/categories/{categoryIdOrSlug}/feedback Input Codec'),
    responseCodec: t.exact(t.intersection([t.type({
      'feedback': t.array(CategoryFeedback),
    }), t.partial({
      
    })])),
    responseCodecs: {
      200: t.exact(t.intersection([t.type({
        'feedback': t.array(CategoryFeedback),
      }), t.partial({
        
      })])),
    },
    
    security: [{"MableOauth2":["admin","buyer"]}],
  },
  'GET /v1/buyers/@me/orderGuide': {
    method: 'get',
    path: '/v1/buyers/@me/orderGuide',
    accept: 'application/json',
    inputLocations: {
      
    },
    inputCodec: t.exact(t.intersection([
      t.type({
        
      }),
      t.partial({
        
      })
    ]), 'GET /v1/buyers/@me/orderGuide Input Codec'),
    responseCodec: t.exact(t.intersection([t.type({
      
    }), t.partial({
      'category': Category,
      'sellers': t.array(SellerDisplayInfo),
      'categories': t.array(CategoryDisplayInfo),
      'ppls': t.array(t.exact(t.intersection([t.type({
        'slug': Slug,
        'name': t.string,
      }), t.partial({
        
      })]))),
    })])),
    responseCodecs: {
      200: t.exact(t.intersection([t.type({
        
      }), t.partial({
        'category': Category,
        'sellers': t.array(SellerDisplayInfo),
        'categories': t.array(CategoryDisplayInfo),
        'ppls': t.array(t.exact(t.intersection([t.type({
          'slug': Slug,
          'name': t.string,
        }), t.partial({
          
        })]))),
      })])),
    },
    
    security: [{"MableOauth2":["buyer"]}],
  },
  'POST /v1/buyers/@me/orderGuide/requestAuthorization': {
    method: 'post',
    path: '/v1/buyers/@me/orderGuide/requestAuthorization',
    accept: 'undefined',
    inputLocations: {
      
    },
    inputCodec: t.exact(t.intersection([
      t.type({
        
      }),
      t.partial({
        
      })
    ]), 'POST /v1/buyers/@me/orderGuide/requestAuthorization Input Codec'),
    responseCodec: t.void,
    responseCodecs: {
      201: undefined,
    },
    
    security: [{"MableOauth2":["buyer"]}],
  },
  'POST /v1/admin/backfillTransactionSalesOwners': {
    method: 'post',
    path: '/v1/admin/backfillTransactionSalesOwners',
    accept: 'undefined',
    inputLocations: {
      'body': 'body',
    },
    inputCodec: t.exact(t.intersection([
      t.type({
        'body': t.exact(t.intersection([t.type({
          'start': DateFromISOString,
        }), t.partial({
          'end': DateFromISOString,
        })]), 'body'),
      }),
      t.partial({
        
      })
    ]), 'POST /v1/admin/backfillTransactionSalesOwners Input Codec'),
    responseCodec: t.void,
    responseCodecs: {
      204: undefined,
    },
    
    security: [{"MableOauth2":["admin"]}],
  },
  'POST /v1/admin/attributeBuyers': {
    method: 'post',
    path: '/v1/admin/attributeBuyers',
    accept: 'undefined',
    inputLocations: {
      'body': 'body',
    },
    inputCodec: t.exact(t.intersection([
      t.type({
        'body': t.exact(t.intersection([t.type({
          
        }), t.partial({
          'reattribute': t.boolean,
          'accountIds': t.array(t.number),
          'created': t.exact(t.intersection([t.type({
            'start': DateFromISOString,
            'end': DateFromISOString,
          }), t.partial({
            
          })]), 'body'),
        })]), 'body'),
      }),
      t.partial({
        
      })
    ]), 'POST /v1/admin/attributeBuyers Input Codec'),
    responseCodec: t.void,
    responseCodecs: {
      204: undefined,
    },
    
    security: [{"MableOauth2":["admin"]}],
  },
  'POST /v1/admin/backfillCommission': {
    method: 'post',
    path: '/v1/admin/backfillCommission',
    accept: 'undefined',
    inputLocations: {
      'body': 'body',
    },
    inputCodec: t.exact(t.intersection([
      t.type({
        'body': t.exact(t.intersection([t.type({
          'transactionIds': t.array(t.number),
        }), t.partial({
          
        })]), 'body'),
      }),
      t.partial({
        
      })
    ]), 'POST /v1/admin/backfillCommission Input Codec'),
    responseCodec: t.void,
    responseCodecs: {
      204: undefined,
    },
    
    security: [{"MableOauth2":["admin"]}],
  },
  'GET /v1/admin/account_flag_definitions': {
    method: 'get',
    path: '/v1/admin/account_flag_definitions',
    accept: 'application/json',
    inputLocations: {
      
    },
    inputCodec: t.exact(t.intersection([
      t.type({
        
      }),
      t.partial({
        
      })
    ]), 'GET /v1/admin/account_flag_definitions Input Codec'),
    responseCodec: t.exact(t.intersection([t.type({
      'flags': t.array(AccountFlagStats),
    }), t.partial({
      
    })])),
    responseCodecs: {
      200: t.exact(t.intersection([t.type({
        'flags': t.array(AccountFlagStats),
      }), t.partial({
        
      })])),
    },
    
    security: [{"MableOauth2":["admin"]}],
  },
  'PATCH /v1/admin/account_flag_definitions': {
    method: 'patch',
    path: '/v1/admin/account_flag_definitions',
    accept: 'undefined',
    inputLocations: {
      'body': 'body',
    },
    inputCodec: t.exact(t.intersection([
      t.type({
        'body': t.exact(t.intersection([t.type({
          'flags': t.array(AccountFlagDefault),
        }), t.partial({
          
        })]), 'body'),
      }),
      t.partial({
        
      })
    ]), 'PATCH /v1/admin/account_flag_definitions Input Codec'),
    responseCodec: t.void,
    responseCodecs: {
      204: undefined,
    },
    
    security: [{"MableOauth2":["superadmin"]}],
  },
  'GET /v1/admin/account_tags': {
    method: 'get',
    path: '/v1/admin/account_tags',
    accept: 'application/json',
    inputLocations: {
      
    },
    inputCodec: t.exact(t.intersection([
      t.type({
        
      }),
      t.partial({
        
      })
    ]), 'GET /v1/admin/account_tags Input Codec'),
    responseCodec: t.exact(t.intersection([t.type({
      'tags': t.array(t.string),
    }), t.partial({
      
    })])),
    responseCodecs: {
      200: t.exact(t.intersection([t.type({
        'tags': t.array(t.string),
      }), t.partial({
        
      })])),
    },
    
    security: [{"MableOauth2":["admin"]}],
  },
  'GET /v1/admin/categories': {
    method: 'get',
    path: '/v1/admin/categories',
    accept: 'application/json',
    inputLocations: {
      'status': 'query',
      'ignorePrivacy': 'query',
      'canContainProducts': 'query',
      'canContainSellers': 'query',
      'canContainCategories': 'query',
      'sellerIds': 'query',
      'categoryIds': 'query',
      'slugs': 'query',
      'include': 'query',
      'navigable': 'query',
      'global': 'query',
      'sortBy': 'query',
      'externalTaxonomyId': 'query',
      'externalTaxonomyCategoryId': 'query',
    },
    inputCodec: t.exact(t.intersection([
      t.type({
        
      }),
      t.partial({
        'status': StringArrayCodec(CodegenEnumClosed({
          'available': null,
          'deleted': null,
          'archived': null,
          'draft': null,
          'all': null,
        }, 'statusItem')),
        'ignorePrivacy': StringBooleanCodec,
        'canContainProducts': StringBooleanCodec,
        'canContainSellers': StringBooleanCodec,
        'canContainCategories': StringBooleanCodec,
        /** The seller IDs to restrict this search for. */
        'sellerIds': StringArrayCodec(StringNumberCodec),
        /** The category IDs to restrict this search for. */
        'categoryIds': StringArrayCodec(StringNumberCodec),
        /** The category slugs to restrict this search for. */
        'slugs': StringArrayCodec(URLStringCodec),
        'include': StringArrayCodec(CodegenEnumClosed({
          'parentMemberships': null,
          'childMemberships': null,
          'productCount': null,
          'sellerCount': null,
        }, 'includeItem')),
        'navigable': StringBooleanCodec,
        /** Whether or not to include or exclude 'global' categories that are not restricted to a specific seller. */
        'global': StringBooleanCodec,
        'sortBy': CodegenEnumClosed({
          'name': null,
          'homepageIndex': null,
          'displayPriority': null,
        }, 'sortBy'),
        'externalTaxonomyId': t.union([t.null, CategoryExternalTaxonomyId]),
        'externalTaxonomyCategoryId': URLStringCodec,
      })
    ]), 'GET /v1/admin/categories Input Codec'),
    responseCodec: t.exact(t.intersection([t.type({
      'categories': t.array(Category),
    }), t.partial({
      
    })])),
    responseCodecs: {
      200: t.exact(t.intersection([t.type({
        'categories': t.array(Category),
      }), t.partial({
        
      })])),
    },
    
    security: [{"MableOauth2":["admin","dataworker"]}],
  },
  'POST /v1/admin/categories': {
    method: 'post',
    path: '/v1/admin/categories',
    accept: 'application/json',
    inputLocations: {
      'categoryInput': 'body',
    },
    inputCodec: t.exact(t.intersection([
      t.type({
        'categoryInput': CategoryInput,
      }),
      t.partial({
        
      })
    ]), 'POST /v1/admin/categories Input Codec'),
    responseCodec: t.exact(t.intersection([t.type({
      'category': Category,
    }), t.partial({
      
    })])),
    responseCodecs: {
      201: t.exact(t.intersection([t.type({
        'category': Category,
      }), t.partial({
        
      })])),
    },
    
    security: [{"MableOauth2":["admin"]}],
  },
  'GET /v1/admin/categories/{categoryId}': {
    method: 'get',
    path: '/v1/admin/categories/{categoryId}',
    accept: 'application/json',
    inputLocations: {
      'categoryId': 'path',
      'include': 'query',
    },
    inputCodec: t.exact(t.intersection([
      t.type({
        'categoryId': StringNumberCodec,
      }),
      t.partial({
        'include': StringArrayCodec(CodegenEnumClosed({
          'variants': null,
        }, 'includeItem')),
      })
    ]), 'GET /v1/admin/categories/{categoryId} Input Codec'),
    responseCodec: t.exact(t.intersection([t.type({
      'category': Category,
    }), t.partial({
      /** Only included if requested with `include`. */
      'variants': t.array(ProductVariantListDisplayInfo),
      /** Note for display to admins */
      'note': t.string,
    })])),
    responseCodecs: {
      200: t.exact(t.intersection([t.type({
        'category': Category,
      }), t.partial({
        /** Only included if requested with `include`. */
        'variants': t.array(ProductVariantListDisplayInfo),
        /** Note for display to admins */
        'note': t.string,
      })])),
    },
    
    security: [{"MableOauth2":["admin","dataworker"]}],
  },
  'PUT /v1/admin/categories/{categoryId}': {
    method: 'put',
    path: '/v1/admin/categories/{categoryId}',
    accept: 'application/json',
    inputLocations: {
      'categoryId': 'path',
      'categoryInput': 'body',
    },
    inputCodec: t.exact(t.intersection([
      t.type({
        'categoryId': StringNumberCodec,
        'categoryInput': CategoryInput,
      }),
      t.partial({
        
      })
    ]), 'PUT /v1/admin/categories/{categoryId} Input Codec'),
    responseCodec: t.exact(t.intersection([t.type({
      'category': Category,
    }), t.partial({
      
    })])),
    responseCodecs: {
      200: t.exact(t.intersection([t.type({
        'category': Category,
      }), t.partial({
        
      })])),
    },
    
    security: [{"MableOauth2":["admin"]}],
  },
  'PUT /v1/admin/categories/{categoryId}/migrate': {
    method: 'put',
    path: '/v1/admin/categories/{categoryId}/migrate',
    accept: 'application/json',
    inputLocations: {
      'categoryId': 'path',
      'destinationCategoryId': 'query',
    },
    inputCodec: t.exact(t.intersection([
      t.type({
        'categoryId': StringNumberCodec,
        'destinationCategoryId': StringNumberCodec,
      }),
      t.partial({
        
      })
    ]), 'PUT /v1/admin/categories/{categoryId}/migrate Input Codec'),
    responseCodec: t.exact(t.intersection([t.type({
      'category': Category,
    }), t.partial({
      
    })])),
    responseCodecs: {
      200: t.exact(t.intersection([t.type({
        'category': Category,
      }), t.partial({
        
      })])),
    },
    
    security: [{"MableOauth2":["admin"]}],
  },
  'PUT /v1/admin/categories/{categoryId}/clear': {
    method: 'put',
    path: '/v1/admin/categories/{categoryId}/clear',
    accept: 'application/json',
    inputLocations: {
      'categoryId': 'path',
      'relationships': 'query',
    },
    inputCodec: t.exact(t.intersection([
      t.type({
        'categoryId': StringNumberCodec,
        /** Specify which relationships to clear */
        'relationships': StringArrayCodec(CodegenEnumClosed({
          'sellers': null,
          'products': null,
        }, 'relationshipsItem')),
      }),
      t.partial({
        
      })
    ]), 'PUT /v1/admin/categories/{categoryId}/clear Input Codec'),
    responseCodec: t.exact(t.intersection([t.type({
      'category': Category,
    }), t.partial({
      
    })])),
    responseCodecs: {
      200: t.exact(t.intersection([t.type({
        'category': Category,
      }), t.partial({
        
      })])),
    },
    
    security: [{"MableOauth2":["admin"]}],
  },
  'PATCH /v1/admin/categories/{categoryId}/variantRelationships': {
    method: 'patch',
    path: '/v1/admin/categories/{categoryId}/variantRelationships',
    accept: 'undefined',
    inputLocations: {
      'categoryId': 'path',
      'addVariantIds': 'query',
      'removeVariantIds': 'query',
    },
    inputCodec: t.exact(t.intersection([
      t.type({
        'categoryId': StringNumberCodec,
      }),
      t.partial({
        /** This category will be added to the given product variants. */
        'addVariantIds': StringArrayCodec(StringNumberCodec),
        /** This category will be removed from the given product variants. */
        'removeVariantIds': StringArrayCodec(StringNumberCodec),
      })
    ]), 'PATCH /v1/admin/categories/{categoryId}/variantRelationships Input Codec'),
    responseCodec: t.void,
    responseCodecs: {
      204: undefined,
    },
    
    security: [{"MableOauth2":["admin"]}],
  },
  'GET /v1/admin/categoryAccountRelationships': {
    method: 'get',
    path: '/v1/admin/categoryAccountRelationships',
    accept: 'application/json',
    inputLocations: {
      'categoryId': 'query',
      'accountId': 'query',
    },
    inputCodec: t.exact(t.intersection([
      t.type({
        
      }),
      t.partial({
        'categoryId': StringNumberCodec,
        'accountId': StringNumberCodec,
      })
    ]), 'GET /v1/admin/categoryAccountRelationships Input Codec'),
    responseCodec: t.exact(t.intersection([t.type({
      'relationships': t.array(CategoryAccountRelationship),
      'relatedAccounts': t.array(AccountAdminDisplayInfo),
    }), t.partial({
      
    })])),
    responseCodecs: {
      200: t.exact(t.intersection([t.type({
        'relationships': t.array(CategoryAccountRelationship),
        'relatedAccounts': t.array(AccountAdminDisplayInfo),
      }), t.partial({
        
      })])),
    },
    
    security: [{"MableOauth2":["admin"]}],
  },
  'PATCH /v1/admin/categories/{categoryId}/accountRelationships': {
    method: 'patch',
    path: '/v1/admin/categories/{categoryId}/accountRelationships',
    accept: 'undefined',
    inputLocations: {
      'categoryId': 'query',
      'type': 'query',
      'addAccountIds': 'query',
      'removeAccountIds': 'query',
    },
    inputCodec: t.exact(t.intersection([
      t.type({
        'categoryId': StringNumberCodec,
        'type': CategoryAccountRelationshipType,
      }),
      t.partial({
        /** This category will be added to the given accounts. */
        'addAccountIds': StringArrayCodec(StringNumberCodec),
        /** This category will be removed from the given accounts. */
        'removeAccountIds': StringArrayCodec(StringNumberCodec),
      })
    ]), 'PATCH /v1/admin/categories/{categoryId}/accountRelationships Input Codec'),
    responseCodec: t.void,
    responseCodecs: {
      204: undefined,
    },
    
    security: [{"MableOauth2":["admin"]}],
  },
  'GET /v1/admin/categoryBuyerLocationRelationships': {
    method: 'get',
    path: '/v1/admin/categoryBuyerLocationRelationships',
    accept: 'application/json',
    inputLocations: {
      'categoryId': 'query',
      'buyerLocationId': 'query',
      'accountId': 'query',
    },
    inputCodec: t.exact(t.intersection([
      t.type({
        
      }),
      t.partial({
        'categoryId': StringNumberCodec,
        'buyerLocationId': URLStringCodec,
        'accountId': StringNumberCodec,
      })
    ]), 'GET /v1/admin/categoryBuyerLocationRelationships Input Codec'),
    responseCodec: t.exact(t.intersection([t.type({
      
    }), t.partial({
      'relationships': t.array(CategoryBuyerLocationRelationship),
    })])),
    responseCodecs: {
      200: t.exact(t.intersection([t.type({
        
      }), t.partial({
        'relationships': t.array(CategoryBuyerLocationRelationship),
      })])),
    },
    
    security: [{"MableOauth2":["admin"]}],
  },
  'PATCH /v1/admin/categories/{categoryId}/buyerLocationRelationships': {
    method: 'patch',
    path: '/v1/admin/categories/{categoryId}/buyerLocationRelationships',
    accept: 'undefined',
    inputLocations: {
      'categoryId': 'path',
      'body': 'body',
    },
    inputCodec: t.exact(t.intersection([
      t.type({
        'categoryId': StringNumberCodec,
        'body': t.exact(t.intersection([t.type({
          'type': CategoryBuyerLocationRelationshipType,
        }), t.partial({
          /** This category will be added to the given buyer locations. */
          'addBuyerLocationIds': t.array(t.string),
          /** This category will be removed from the given buyer locations. */
          'removeBuyerLocationIds': t.array(t.string),
        })]), 'body'),
      }),
      t.partial({
        
      })
    ]), 'PATCH /v1/admin/categories/{categoryId}/buyerLocationRelationships Input Codec'),
    responseCodec: t.void,
    responseCodecs: {
      204: undefined,
    },
    
    security: [{"MableOauth2":["admin"]}],
  },
  'POST /v1/admin/categories/{categoryId}/duplicate': {
    method: 'post',
    path: '/v1/admin/categories/{categoryId}/duplicate',
    accept: 'application/json',
    inputLocations: {
      'categoryId': 'path',
    },
    inputCodec: t.exact(t.intersection([
      t.type({
        'categoryId': StringNumberCodec,
      }),
      t.partial({
        
      })
    ]), 'POST /v1/admin/categories/{categoryId}/duplicate Input Codec'),
    responseCodec: t.exact(t.intersection([t.type({
      'newCategoryId': t.number,
    }), t.partial({
      
    })])),
    responseCodecs: {
      200: t.exact(t.intersection([t.type({
        'newCategoryId': t.number,
      }), t.partial({
        
      })])),
    },
    
    security: [{"MableOauth2":["admin"]}],
  },
  'PUT /v1/admin/bulkCategories': {
    method: 'put',
    path: '/v1/admin/bulkCategories',
    accept: 'application/json',
    inputLocations: {
      'bulkCategoryCreateInput': 'body',
    },
    inputCodec: t.exact(t.intersection([
      t.type({
        'bulkCategoryCreateInput': t.exact(t.intersection([t.type({
          'categoryInputs': t.array(CategoryInputBulk),
        }), t.partial({
          
        })]), 'bulkCategoryCreateInput'),
      }),
      t.partial({
        
      })
    ]), 'PUT /v1/admin/bulkCategories Input Codec'),
    responseCodec: t.exact(t.intersection([t.type({
      'categories': t.array(Category),
    }), t.partial({
      
    })])),
    responseCodecs: {
      200: t.exact(t.intersection([t.type({
        'categories': t.array(Category),
      }), t.partial({
        
      })])),
    },
    
    security: [{"MableOauth2":["admin"]}],
  },
  'GET /v1/admin/partner/{partnerAccountName}/pricingReports': {
    method: 'get',
    path: '/v1/admin/partner/{partnerAccountName}/pricingReports',
    accept: 'application/json',
    inputLocations: {
      'partnerAccountName': 'path',
    },
    inputCodec: t.exact(t.intersection([
      t.type({
        'partnerAccountName': URLStringCodec,
      }),
      t.partial({
        
      })
    ]), 'GET /v1/admin/partner/{partnerAccountName}/pricingReports Input Codec'),
    responseCodec: t.exact(t.intersection([t.type({
      'pricingReports': t.array(PricingReport),
    }), t.partial({
      
    })])),
    responseCodecs: {
      200: t.exact(t.intersection([t.type({
        'pricingReports': t.array(PricingReport),
      }), t.partial({
        
      })])),
    },
    
    security: [{"MableOauth2":["admin"]}],
  },
  'POST /v1/admin/partner/{partnerAccountName}/renderPricingCsv': {
    method: 'post',
    path: '/v1/admin/partner/{partnerAccountName}/renderPricingCsv',
    accept: 'application/json',
    inputLocations: {
      'partnerAccountName': 'path',
      'body': 'body',
    },
    inputCodec: t.exact(t.intersection([
      t.type({
        'partnerAccountName': URLStringCodec,
        'body': t.exact(t.intersection([t.type({
          
        }), t.partial({
          'atTime': DateFromISOString,
          'includedVariants': PricingFileIncludedVariants,
          'previewMode': PricingFilePreviewMode,
        })]), 'body'),
      }),
      t.partial({
        
      })
    ]), 'POST /v1/admin/partner/{partnerAccountName}/renderPricingCsv Input Codec'),
    responseCodec: t.exact(t.intersection([t.type({
      'csv': t.string,
      'fileName': t.string,
    }), t.partial({
      
    })])),
    responseCodecs: {
      200: t.exact(t.intersection([t.type({
        'csv': t.string,
        'fileName': t.string,
      }), t.partial({
        
      })])),
    },
    
    security: [{"MableOauth2":["admin"]}],
  },
  'GET /v1/admin/partner/{partnerAccountName}/renderPricingCsv/{pricingReportId}': {
    method: 'get',
    path: '/v1/admin/partner/{partnerAccountName}/renderPricingCsv/{pricingReportId}',
    accept: 'application/json',
    inputLocations: {
      'partnerAccountName': 'path',
      'pricingReportId': 'query',
    },
    inputCodec: t.exact(t.intersection([
      t.type({
        'partnerAccountName': URLStringCodec,
        'pricingReportId': StringNumberCodec,
      }),
      t.partial({
        
      })
    ]), 'GET /v1/admin/partner/{partnerAccountName}/renderPricingCsv/{pricingReportId} Input Codec'),
    responseCodec: t.exact(t.intersection([t.type({
      'csv': t.string,
      'fileName': t.string,
    }), t.partial({
      
    })])),
    responseCodecs: {
      200: t.exact(t.intersection([t.type({
        'csv': t.string,
        'fileName': t.string,
      }), t.partial({
        
      })])),
    },
    
    security: [{"MableOauth2":["admin"]}],
  },
  'POST /v1/admin/mclaneCommissionAuditCSV': {
    method: 'post',
    path: '/v1/admin/mclaneCommissionAuditCSV',
    accept: 'application/json',
    inputLocations: {
      'body': 'body',
    },
    inputCodec: t.exact(t.intersection([
      t.type({
        'body': t.exact(t.intersection([t.type({
          
        }), t.partial({
          'atTime': DateFromISOString,
        })]), 'body'),
      }),
      t.partial({
        
      })
    ]), 'POST /v1/admin/mclaneCommissionAuditCSV Input Codec'),
    responseCodec: t.exact(t.intersection([t.type({
      'csv': t.string,
    }), t.partial({
      
    })])),
    responseCodecs: {
      200: t.exact(t.intersection([t.type({
        'csv': t.string,
      }), t.partial({
        
      })])),
    },
    
    security: [{"MableOauth2":["admin"]}],
  },
  'POST /v1/admin/{partnerName}/priceConfirmation': {
    method: 'post',
    path: '/v1/admin/{partnerName}/priceConfirmation',
    accept: 'application/json',
    inputLocations: {
      'partnerName': 'path',
      'body': 'body',
    },
    inputCodec: t.exact(t.intersection([
      t.type({
        'partnerName': URLStringCodec,
        'body': t.exact(t.intersection([t.type({
          'csv': t.string,
        }), t.partial({
          
        })]), 'body'),
      }),
      t.partial({
        
      })
    ]), 'POST /v1/admin/{partnerName}/priceConfirmation Input Codec'),
    responseCodec: t.exact(t.intersection([t.type({
      
    }), t.partial({
      'mismatchesCsvString': t.string,
      'unmatchedProductVariantIdentifiers': t.array(t.string),
    })])),
    responseCodecs: {
      200: t.exact(t.intersection([t.type({
        
      }), t.partial({
        'mismatchesCsvString': t.string,
        'unmatchedProductVariantIdentifiers': t.array(t.string),
      })])),
    },
    
    security: [{"MableOauth2":["admin"]}],
  },
  'POST /v1/admin/mclaneUinImport': {
    method: 'post',
    path: '/v1/admin/mclaneUinImport',
    accept: 'application/json',
    inputLocations: {
      'body': 'body',
    },
    inputCodec: t.exact(t.intersection([
      t.type({
        'body': t.exact(t.intersection([t.type({
          /** This should the contents of the McLane Price Confirmation CSV */
          'csv': t.string,
        }), t.partial({
          
        })]), 'body'),
      }),
      t.partial({
        
      })
    ]), 'POST /v1/admin/mclaneUinImport Input Codec'),
    responseCodec: t.exact(t.intersection([t.type({
      
    }), t.partial({
      'problems': t.array(t.record(t.string, t.unknown)),
    })])),
    responseCodecs: {
      201: t.exact(t.intersection([t.type({
        
      }), t.partial({
        'problems': t.array(t.record(t.string, t.unknown)),
      })])),
    },
    
    security: [{"MableOauth2":["admin"]}],
  },
  'GET /v1/admin/dashboard': {
    method: 'get',
    path: '/v1/admin/dashboard',
    accept: 'application/json',
    inputLocations: {
      
    },
    inputCodec: t.exact(t.intersection([
      t.type({
        
      }),
      t.partial({
        
      })
    ]), 'GET /v1/admin/dashboard Input Codec'),
    responseCodec: AdminDashboard,
    responseCodecs: {
      200: AdminDashboard,
    },
    
    security: [{"MableOauth2":["admin","dataworker"]}],
  },
  'GET /v1/admin/jobsSummary': {
    method: 'get',
    path: '/v1/admin/jobsSummary',
    accept: 'application/json',
    inputLocations: {
      'includeArchived': 'query',
    },
    inputCodec: t.exact(t.intersection([
      t.type({
        
      }),
      t.partial({
        'includeArchived': StringBooleanCodec,
      })
    ]), 'GET /v1/admin/jobsSummary Input Codec'),
    responseCodec: t.array(QueueJobSummary),
    responseCodecs: {
      200: t.array(QueueJobSummary),
    },
    
    security: [{"MableOauth2":["admin"]}],
  },
  'GET /v1/admin/jobs': {
    method: 'get',
    path: '/v1/admin/jobs',
    accept: 'application/json',
    inputLocations: {
      'name': 'query',
      'status': 'query',
      'createdAfter': 'query',
      'createdBefore': 'query',
      'includeArchived': 'query',
    },
    inputCodec: t.exact(t.intersection([
      t.type({
        
      }),
      t.partial({
        'name': URLStringCodec,
        'status': JobState,
        'createdAfter': DateFromISOString,
        'createdBefore': DateFromISOString,
        'includeArchived': StringBooleanCodec,
      })
    ]), 'GET /v1/admin/jobs Input Codec'),
    responseCodec: t.array(QueueJob),
    responseCodecs: {
      200: t.array(QueueJob),
    },
    
    security: [{"MableOauth2":["admin"]}],
  },
  'POST /v1/admin/jobs': {
    method: 'post',
    path: '/v1/admin/jobs',
    accept: 'undefined',
    inputLocations: {
      'body': 'body',
    },
    inputCodec: t.exact(t.intersection([
      t.type({
        'body': t.exact(t.intersection([t.type({
          'jobName': t.string,
        }), t.partial({
          'jobArgs': t.record(t.string, t.unknown),
          'jobOpts': t.record(t.string, t.unknown),
        })]), 'body'),
      }),
      t.partial({
        
      })
    ]), 'POST /v1/admin/jobs Input Codec'),
    responseCodec: t.void,
    responseCodecs: {
      204: undefined,
    },
    
    security: [{"MableOauth2":["admin"]}],
  },
  'POST /v1/admin/fileUploads/{fileUploadId}/signedDownloadUrl': {
    method: 'post',
    path: '/v1/admin/fileUploads/{fileUploadId}/signedDownloadUrl',
    accept: 'application/json',
    inputLocations: {
      'fileUploadId': 'path',
    },
    inputCodec: t.exact(t.intersection([
      t.type({
        /** The file_upload ID to generate a download URL for */
        'fileUploadId': StringNumberCodec,
      }),
      t.partial({
        
      })
    ]), 'POST /v1/admin/fileUploads/{fileUploadId}/signedDownloadUrl Input Codec'),
    responseCodec: FileDownload,
    responseCodecs: {
      200: FileDownload,
    },
    
    security: [{"MableOauth2":["admin"]}],
  },
  'POST /v1/mobile-config': {
    method: 'post',
    path: '/v1/mobile-config',
    accept: 'application/json',
    inputLocations: {
      
    },
    inputCodec: t.exact(t.intersection([
      t.type({
        
      }),
      t.partial({
        
      })
    ]), 'POST /v1/mobile-config Input Codec'),
    responseCodec: MobileConfig,
    responseCodecs: {
      200: MobileConfig,
    },
    
    security: [{"NoAuth":[]}],
  },
  'POST /v1/orders': {
    method: 'post',
    path: '/v1/orders',
    accept: 'application/json',
    inputLocations: {
      'input': 'body',
    },
    inputCodec: t.exact(t.intersection([
      t.type({
        'input': CartOrder,
      }),
      t.partial({
        
      })
    ]), 'POST /v1/orders Input Codec'),
    responseCodec: t.exact(t.intersection([t.type({
      'orders': t.array(OrderDisplayInfo),
    }), t.partial({
      'groups': t.record(t.string, TransactionGroup),
    })])),
    responseCodecs: {
      200: t.exact(t.intersection([t.type({
        'orders': t.array(OrderDisplayInfo),
      }), t.partial({
        'groups': t.record(t.string, TransactionGroup),
      })])),
    },
    
    security: [{"MableOauth2":["buyer"]}],
  },
  'GET /v1/orders': {
    method: 'get',
    path: '/v1/orders',
    accept: 'application/json',
    inputLocations: {
      'perspective': 'query',
      'customerAccountId': 'query',
      'productSellerIds': 'query',
      'page': 'query',
      'pageSize': 'query',
      'include': 'query',
      'invoiceStatuses': 'query',
      'sortBy': 'query',
      'orderBy': 'query',
    },
    inputCodec: t.exact(t.intersection([
      t.type({
        'perspective': CodegenEnumClosed({
          'buyer': null,
          'seller': null,
        }, 'perspective'),
      }),
      t.partial({
        /** Get orders for a single buyer account. Only applicable from seller perspective */
        'customerAccountId': StringNumberCodec,
        /** Filter orders by a list of product sellers. Only applicable from buyer perspective */
        'productSellerIds': StringArrayCodec(StringNumberCodec),
        /** Page */
        'page': StringNumberCodec,
        /** Number of results per page */
        'pageSize': StringNumberCodec,
        'include': StringArrayCodec(CodegenEnumClosed({
          'seller': null,
        }, 'includeItem')),
        /** If defined, only return orders with a paid/unpaid invoice. Any orders without invoices will be filtered out. */
        'invoiceStatuses': StringArrayCodec(CodegenEnumClosed({
          'unpaid': null,
          'paid': null,
        }, 'invoiceStatusesItem')),
        'sortBy': CodegenEnumClosed({
          'dueDate': null,
          'paidDate': null,
        }, 'sortBy'),
        'orderBy': CodegenEnumClosed({
          'asc': null,
          'desc': null,
        }, 'orderBy'),
      })
    ]), 'GET /v1/orders Input Codec'),
    responseCodec: t.exact(t.intersection([t.type({
      'orders': t.array(OrderDisplayInfo),
    }), t.partial({
      'groups': t.record(t.string, TransactionGroup),
      /** Currently only returned in the buyer perspective. */
      'balances': AccountBalances,
      'totalCount': t.number,
      /** If true, the buyer on this order needs verification. */
      'buyerRequiresVerification': t.boolean,
    })])),
    responseCodecs: {
      200: t.exact(t.intersection([t.type({
        'orders': t.array(OrderDisplayInfo),
      }), t.partial({
        'groups': t.record(t.string, TransactionGroup),
        /** Currently only returned in the buyer perspective. */
        'balances': AccountBalances,
        'totalCount': t.number,
        /** If true, the buyer on this order needs verification. */
        'buyerRequiresVerification': t.boolean,
      })])),
    },
    
    security: [{"MableOauth2":["admin","buyer","seller"]}],
  },
  'GET /v1/orders/{orderId}': {
    method: 'get',
    path: '/v1/orders/{orderId}',
    accept: 'application/json',
    inputLocations: {
      'orderId': 'path',
      'perspective': 'query',
      'view': 'query',
    },
    inputCodec: t.exact(t.intersection([
      t.type({
        'orderId': StringNumberCodec,
        'perspective': CodegenEnumClosed({
          'buyer': null,
          'seller': null,
        }, 'perspective'),
      }),
      t.partial({
        /** Optional value that specifies the presentation of this order. Default is `order`. */
        'view': CodegenEnumClosed({
          'order': null,
          'invoice': null,
        }, 'view'),
      })
    ]), 'GET /v1/orders/{orderId} Input Codec'),
    responseCodec: t.intersection([
      OrderDisplayInfo,
      t.exact(t.intersection([t.type({
        'order': OrderDisplayInfo,
        'group': TransactionGroup,
      }), t.partial({
        'shipmentGroups': t.array(ShipmentGroup),
      })]))
    ]),
    responseCodecs: {
      200: t.intersection([
        OrderDisplayInfo,
        t.exact(t.intersection([t.type({
          'order': OrderDisplayInfo,
          'group': TransactionGroup,
        }), t.partial({
          'shipmentGroups': t.array(ShipmentGroup),
        })]))
      ]),
    },
    
    security: [{"MableOauth2":["admin","buyer","seller"]}],
  },
  'GET /v1/orders/{orderId}/attachments/{attachmentId}/signedUrl': {
    method: 'get',
    path: '/v1/orders/{orderId}/attachments/{attachmentId}/signedUrl',
    accept: 'application/json',
    inputLocations: {
      'orderId': 'path',
      'attachmentId': 'path',
    },
    inputCodec: t.exact(t.intersection([
      t.type({
        'orderId': StringNumberCodec,
        'attachmentId': URLStringCodec,
      }),
      t.partial({
        
      })
    ]), 'GET /v1/orders/{orderId}/attachments/{attachmentId}/signedUrl Input Codec'),
    responseCodec: t.exact(t.intersection([t.type({
      'url': t.string,
    }), t.partial({
      
    })])),
    responseCodecs: {
      200: t.exact(t.intersection([t.type({
        'url': t.string,
      }), t.partial({
        
      })])),
    },
    
    security: [{"MableOauth2":["admin","seller"]}],
  },
  'POST /v1/admin/orders/{orderId}/sendPurchaseOrder/email': {
    method: 'post',
    path: '/v1/admin/orders/{orderId}/sendPurchaseOrder/email',
    accept: 'undefined',
    inputLocations: {
      'orderId': 'path',
      'billToBuyer': 'query',
      'testEmail': 'query',
      'ccEmails': 'query',
    },
    inputCodec: t.exact(t.intersection([
      t.type({
        'orderId': StringNumberCodec,
      }),
      t.partial({
        'billToBuyer': StringBooleanCodec,
        /** If provided, send the PO to this email instead of the seller's billing contact, and don't record the send in the DB. */
        'testEmail': URLStringCodec,
        'ccEmails': StringArrayCodec(URLStringCodec),
      })
    ]), 'POST /v1/admin/orders/{orderId}/sendPurchaseOrder/email Input Codec'),
    responseCodec: t.void,
    responseCodecs: {
      204: undefined,
    },
    
    security: [{"MableOauth2":["admin"]}],
  },
  'POST /v1/orders/{orderId}/shipToAddress': {
    method: 'post',
    path: '/v1/orders/{orderId}/shipToAddress',
    accept: 'application/json',
    inputLocations: {
      'orderId': 'path',
      'addressInput': 'body',
    },
    inputCodec: t.exact(t.intersection([
      t.type({
        'orderId': StringNumberCodec,
        'addressInput': AddressInput,
      }),
      t.partial({
        
      })
    ]), 'POST /v1/orders/{orderId}/shipToAddress Input Codec'),
    responseCodec: t.exact(t.intersection([t.type({
      'shipToAddress': Address,
    }), t.partial({
      
    })])),
    responseCodecs: {
      200: t.exact(t.intersection([t.type({
        'shipToAddress': Address,
      }), t.partial({
        
      })])),
    },
    
    security: [{"MableOauth2":["admin","seller"]}],
  },
  'GET /v1/orders/{orderId}/buyShippingMetaData': {
    method: 'get',
    path: '/v1/orders/{orderId}/buyShippingMetaData',
    accept: 'application/json',
    inputLocations: {
      'orderId': 'path',
    },
    inputCodec: t.exact(t.intersection([
      t.type({
        'orderId': StringNumberCodec,
      }),
      t.partial({
        
      })
    ]), 'GET /v1/orders/{orderId}/buyShippingMetaData Input Codec'),
    responseCodec: t.exact(t.intersection([t.type({
      'metadata': BuyShippingMetaData,
    }), t.partial({
      
    })])),
    responseCodecs: {
      200: t.exact(t.intersection([t.type({
        'metadata': BuyShippingMetaData,
      }), t.partial({
        
      })])),
    },
    
    security: [{"MableOauth2":["admin","seller"]}],
  },
  'POST /v1/orders/{orderId}/shipmentGroup': {
    method: 'post',
    path: '/v1/orders/{orderId}/shipmentGroup',
    accept: 'application/json',
    inputLocations: {
      'orderId': 'path',
      'shipmentGroupInput': 'body',
    },
    inputCodec: t.exact(t.intersection([
      t.type({
        'orderId': StringNumberCodec,
        'shipmentGroupInput': ShipmentGroupCreateInput,
      }),
      t.partial({
        
      })
    ]), 'POST /v1/orders/{orderId}/shipmentGroup Input Codec'),
    responseCodec: t.exact(t.intersection([t.type({
      'shipmentGroup': ShipmentGroup,
    }), t.partial({
      
    })])),
    responseCodecs: {
      200: t.exact(t.intersection([t.type({
        'shipmentGroup': ShipmentGroup,
      }), t.partial({
        
      })])),
    },
    
    security: [{"MableOauth2":["admin","seller"]}],
  },
  'POST /v1/orders/{orderId}/shipmentGroup/buy': {
    method: 'post',
    path: '/v1/orders/{orderId}/shipmentGroup/buy',
    accept: 'application/json',
    inputLocations: {
      'orderId': 'path',
      'dontNotify': 'query',
      'buyShipmentGroupInput': 'body',
    },
    inputCodec: t.exact(t.intersection([
      t.type({
        'orderId': StringNumberCodec,
        'buyShipmentGroupInput': ShipmentGroupBuyInput,
      }),
      t.partial({
        /** If true, don't send internal (Slack) notifications about this event. Admins only. */
        'dontNotify': StringBooleanCodec,
      })
    ]), 'POST /v1/orders/{orderId}/shipmentGroup/buy Input Codec'),
    responseCodec: t.exact(t.intersection([t.type({
      'shipmentGroup': ShipmentGroup,
    }), t.partial({
      
    })])),
    responseCodecs: {
      200: t.exact(t.intersection([t.type({
        'shipmentGroup': ShipmentGroup,
      }), t.partial({
        
      })])),
    },
    
    security: [{"MableOauth2":["admin","seller"]}],
  },
  'POST /v1/admin/orders/{orderId}/sendBuyerConfirmation': {
    method: 'post',
    path: '/v1/admin/orders/{orderId}/sendBuyerConfirmation',
    accept: 'undefined',
    inputLocations: {
      'orderId': 'path',
      'updated': 'query',
    },
    inputCodec: t.exact(t.intersection([
      t.type({
        'orderId': StringNumberCodec,
      }),
      t.partial({
        /** If true, append some copy to the top of the email explaining that the order has been updated. */
        'updated': StringBooleanCodec,
      })
    ]), 'POST /v1/admin/orders/{orderId}/sendBuyerConfirmation Input Codec'),
    responseCodec: t.void,
    responseCodecs: {
      204: undefined,
    },
    
    security: [{"MableOauth2":["admin"]}],
  },
  'POST /v1/admin/orders/{orderId}/shipToAddress': {
    method: 'post',
    path: '/v1/admin/orders/{orderId}/shipToAddress',
    accept: 'application/json',
    inputLocations: {
      'orderId': 'path',
      'addressInput': 'body',
    },
    inputCodec: t.exact(t.intersection([
      t.type({
        'orderId': StringNumberCodec,
        'addressInput': AddressInput,
      }),
      t.partial({
        
      })
    ]), 'POST /v1/admin/orders/{orderId}/shipToAddress Input Codec'),
    responseCodec: t.exact(t.intersection([t.type({
      'shipToAddress': Address,
    }), t.partial({
      
    })])),
    responseCodecs: {
      200: t.exact(t.intersection([t.type({
        'shipToAddress': Address,
      }), t.partial({
        
      })])),
    },
    
    security: [{"MableOauth2":["admin"]}],
  },
  'POST /v1/admin/orders/{orderId}/sendBuyerConfirmation/sftp': {
    method: 'post',
    path: '/v1/admin/orders/{orderId}/sendBuyerConfirmation/sftp',
    accept: 'undefined',
    inputLocations: {
      'orderId': 'path',
    },
    inputCodec: t.exact(t.intersection([
      t.type({
        'orderId': StringNumberCodec,
      }),
      t.partial({
        
      })
    ]), 'POST /v1/admin/orders/{orderId}/sendBuyerConfirmation/sftp Input Codec'),
    responseCodec: t.void,
    responseCodecs: {
      204: undefined,
    },
    
    security: [{"MableOauth2":["admin"]}],
  },
  'POST /v1/admin/orders/{orderId}/sendPurchaseOrder/sftp': {
    method: 'post',
    path: '/v1/admin/orders/{orderId}/sendPurchaseOrder/sftp',
    accept: 'undefined',
    inputLocations: {
      'orderId': 'path',
    },
    inputCodec: t.exact(t.intersection([
      t.type({
        'orderId': StringNumberCodec,
      }),
      t.partial({
        
      })
    ]), 'POST /v1/admin/orders/{orderId}/sendPurchaseOrder/sftp Input Codec'),
    responseCodec: t.void,
    responseCodecs: {
      204: undefined,
    },
    
    security: [{"MableOauth2":["admin"]}],
  },
  'POST /v1/orders/{orderId}/forwardInvoice': {
    method: 'post',
    path: '/v1/orders/{orderId}/forwardInvoice',
    accept: 'undefined',
    inputLocations: {
      'orderId': 'path',
      'emails': 'query',
    },
    inputCodec: t.exact(t.intersection([
      t.type({
        'orderId': StringNumberCodec,
        /** A list of emails to send this email to */
        'emails': StringArrayCodec(URLStringCodec),
      }),
      t.partial({
        
      })
    ]), 'POST /v1/orders/{orderId}/forwardInvoice Input Codec'),
    responseCodec: t.void,
    responseCodecs: {
      204: undefined,
    },
    
    security: [{"MableOauth2":["admin","buyer"]}],
  },
  'POST /v1/orders/{orderId}/draftInvoice': {
    method: 'post',
    path: '/v1/orders/{orderId}/draftInvoice',
    accept: 'application/json',
    inputLocations: {
      'orderId': 'path',
      'stripeCustomerId': 'query',
    },
    inputCodec: t.exact(t.intersection([
      t.type({
        'orderId': StringNumberCodec,
        'stripeCustomerId': URLStringCodec,
      }),
      t.partial({
        
      })
    ]), 'POST /v1/orders/{orderId}/draftInvoice Input Codec'),
    responseCodec: t.exact(t.intersection([t.type({
      'invoiceId': t.string,
    }), t.partial({
      
    })])),
    responseCodecs: {
      201: t.exact(t.intersection([t.type({
        'invoiceId': t.string,
      }), t.partial({
        
      })])),
    },
    
    security: [{"MableOauth2":["admin"]}],
  },
  'PUT /v1/orders/{orderId}/invoiceUploaded': {
    method: 'put',
    path: '/v1/orders/{orderId}/invoiceUploaded',
    accept: 'undefined',
    inputLocations: {
      'orderId': 'path',
      'metadata': 'body',
    },
    inputCodec: t.exact(t.intersection([
      t.type({
        'orderId': StringNumberCodec,
        'metadata': UploadedInvoiceMetadata,
      }),
      t.partial({
        
      })
    ]), 'PUT /v1/orders/{orderId}/invoiceUploaded Input Codec'),
    responseCodec: t.void,
    responseCodecs: {
      204: undefined,
    },
    
    security: [{"MableOauth2":["admin","seller"]}],
  },
  'PUT /v1/orders/{orderId}/accept': {
    method: 'put',
    path: '/v1/orders/{orderId}/accept',
    accept: 'undefined',
    inputLocations: {
      'orderId': 'path',
    },
    inputCodec: t.exact(t.intersection([
      t.type({
        'orderId': StringNumberCodec,
      }),
      t.partial({
        
      })
    ]), 'PUT /v1/orders/{orderId}/accept Input Codec'),
    responseCodec: t.void,
    responseCodecs: {
      204: undefined,
    },
    
    security: [{"MableOauth2":["admin","seller"]}],
  },
  'POST /v1/orders/{orderId}/modify': {
    method: 'post',
    path: '/v1/orders/{orderId}/modify',
    accept: 'undefined',
    inputLocations: {
      'orderId': 'path',
      'modifications': 'body',
    },
    inputCodec: t.exact(t.intersection([
      t.type({
        'orderId': StringNumberCodec,
        'modifications': t.array(OrderModification),
      }),
      t.partial({
        
      })
    ]), 'POST /v1/orders/{orderId}/modify Input Codec'),
    responseCodec: t.void,
    responseCodecs: {
      204: undefined,
    },
    
    security: [{"MableOauth2":["admin","seller"]}],
  },
  'GET /v1/sellers/{sellerIdOrMe}/retailUnits': {
    method: 'get',
    path: '/v1/sellers/{sellerIdOrMe}/retailUnits',
    accept: 'application/json',
    inputLocations: {
      'sellerIdOrMe': 'path',
      'name': 'query',
      'statuses': 'query',
      'page': 'query',
      'pageSize': 'query',
    },
    inputCodec: t.exact(t.intersection([
      t.type({
        'sellerIdOrMe': URLStringCodec,
      }),
      t.partial({
        /** Search by name */
        'name': URLStringCodec,
        'statuses': StringArrayCodec(RetailUnitStatus),
        /** What page of results to fetch (default 1) */
        'page': StringNumberCodec,
        /** How many units per page to fetch (default 20) */
        'pageSize': StringNumberCodec,
      })
    ]), 'GET /v1/sellers/{sellerIdOrMe}/retailUnits Input Codec'),
    responseCodec: t.exact(t.intersection([t.type({
      'retailUnits': t.array(RetailUnit),
      'totalCount': t.number,
    }), t.partial({
      
    })])),
    responseCodecs: {
      200: t.exact(t.intersection([t.type({
        'retailUnits': t.array(RetailUnit),
        'totalCount': t.number,
      }), t.partial({
        
      })])),
    },
    
    security: [{"MableOauth2":["admin","seller","dataworker"]}],
  },
  'GET /v1/sellers/{sellerIdOrMe}/retailUnits/{retailUnitId}': {
    method: 'get',
    path: '/v1/sellers/{sellerIdOrMe}/retailUnits/{retailUnitId}',
    accept: 'application/json',
    inputLocations: {
      'sellerIdOrMe': 'path',
      'retailUnitId': 'path',
      'include': 'query',
    },
    inputCodec: t.exact(t.intersection([
      t.type({
        'sellerIdOrMe': URLStringCodec,
        'retailUnitId': StringNumberCodec,
      }),
      t.partial({
        'include': StringArrayCodec(CodegenEnumOpen({
          'dataStandardPropertyValues': null,
        }, 'includeItem')),
      })
    ]), 'GET /v1/sellers/{sellerIdOrMe}/retailUnits/{retailUnitId} Input Codec'),
    responseCodec: t.exact(t.intersection([t.type({
      'retailUnit': RetailUnit,
      'product': RetailUnitProduct,
      'dietCategories': t.array(DietCategory),
    }), t.partial({
      /** Admins/DataWorkers only. List of all public, non-cureated categories shared by the variants in this heirarchy */
      'publicCategories': t.array(RetailUnitPublicCategory),
      'options': t.array(RetailUnitOptionValue),
      'dataStandardPropertyValues': DataStandardPropertyValues,
    })])),
    responseCodecs: {
      200: t.exact(t.intersection([t.type({
        'retailUnit': RetailUnit,
        'product': RetailUnitProduct,
        'dietCategories': t.array(DietCategory),
      }), t.partial({
        /** Admins/DataWorkers only. List of all public, non-cureated categories shared by the variants in this heirarchy */
        'publicCategories': t.array(RetailUnitPublicCategory),
        'options': t.array(RetailUnitOptionValue),
        'dataStandardPropertyValues': DataStandardPropertyValues,
      })])),
    },
    
    security: [{"MableOauth2":["admin","seller","dataworker"]}],
  },
  'PATCH /v1/sellers/{sellerIdOrMe}/retailUnits/{retailUnitId}': {
    method: 'patch',
    path: '/v1/sellers/{sellerIdOrMe}/retailUnits/{retailUnitId}',
    accept: 'undefined',
    inputLocations: {
      'sellerIdOrMe': 'path',
      'retailUnitId': 'path',
      'body': 'body',
    },
    inputCodec: t.exact(t.intersection([
      t.type({
        'sellerIdOrMe': URLStringCodec,
        'retailUnitId': StringNumberCodec,
        'body': t.exact(t.intersection([t.type({
          
        }), t.partial({
          'retailUnit': RetailUnitPatch,
          'product': RetailUnitProductPatch,
          'dietCategories': RetailUnitDietPatch,
          /** Admin/Dataworkers only. Modifies any of the shared categories between all of the variants in a retail unit. */
          'publicCategories': RetailUnitPublicCategoryPatch,
          'options': RetailUnitOptionPatch,
          'dataStandardPropertyValues': t.array(SetDataStandardPropertyValue),
        })]), 'body'),
      }),
      t.partial({
        
      })
    ]), 'PATCH /v1/sellers/{sellerIdOrMe}/retailUnits/{retailUnitId} Input Codec'),
    responseCodec: t.void,
    responseCodecs: {
      204: undefined,
    },
    
    security: [{"MableOauth2":["admin","seller","dataworker"]}],
  },
  'GET /v1/sellers/{sellerIdOrMe}/dataIssues': {
    method: 'get',
    path: '/v1/sellers/{sellerIdOrMe}/dataIssues',
    accept: 'application/json',
    inputLocations: {
      'sellerIdOrMe': 'path',
    },
    inputCodec: t.exact(t.intersection([
      t.type({
        'sellerIdOrMe': URLStringCodec,
      }),
      t.partial({
        
      })
    ]), 'GET /v1/sellers/{sellerIdOrMe}/dataIssues Input Codec'),
    responseCodec: t.exact(t.intersection([t.type({
      'issues': SellerIssues,
    }), t.partial({
      
    })])),
    responseCodecs: {
      200: t.exact(t.intersection([t.type({
        'issues': SellerIssues,
      }), t.partial({
        
      })])),
    },
    
    security: [{"MableOauth2":["admin","seller","dataworker"]}],
  },
  'GET /v1/sellers/{sellerIdOrMe}/dataIssues/retailUnits/{retailUnitId}': {
    method: 'get',
    path: '/v1/sellers/{sellerIdOrMe}/dataIssues/retailUnits/{retailUnitId}',
    accept: 'application/json',
    inputLocations: {
      'sellerIdOrMe': 'path',
      'retailUnitId': 'path',
    },
    inputCodec: t.exact(t.intersection([
      t.type({
        'sellerIdOrMe': URLStringCodec,
        'retailUnitId': StringNumberCodec,
      }),
      t.partial({
        
      })
    ]), 'GET /v1/sellers/{sellerIdOrMe}/dataIssues/retailUnits/{retailUnitId} Input Codec'),
    responseCodec: t.exact(t.intersection([t.type({
      'issues': t.array(ProductIssue),
    }), t.partial({
      
    })])),
    responseCodecs: {
      200: t.exact(t.intersection([t.type({
        'issues': t.array(ProductIssue),
      }), t.partial({
        
      })])),
    },
    
    security: [{"MableOauth2":["admin","seller","dataworker"]}],
  },
  'GET /v1/products': {
    method: 'get',
    path: '/v1/products',
    accept: 'application/json',
    inputLocations: {
      'id': 'query',
      'name': 'query',
      'sellerId': 'query',
      'gtin': 'query',
      'gtins': 'query',
      'include': 'query',
      'includeUnavailable': 'query',
      'ignoreWhitelist': 'query',
      'status': 'query',
      'page': 'query',
      'pageSize': 'query',
    },
    inputCodec: t.exact(t.intersection([
      t.type({
        
      }),
      t.partial({
        /** Specify a list of products by ID */
        'id': StringArrayCodec(StringNumberCodec),
        /** Search products by name (any match) */
        'name': URLStringCodec,
        /** Get products from a specific seller */
        'sellerId': StringNumberCodec,
        /** When provided, an exact match on gtin will be attempted. */
        'gtin': URLStringCodec,
        /** When provided, an exact match on gtin will be attempted. */
        'gtins': StringArrayCodec(URLStringCodec),
        'include': StringArrayCodec(CodegenEnumClosed({
          'seller': null,
        }, 'includeItem')),
        /** If true, include products with a status other than available. Admins only. */
        'includeUnavailable': StringBooleanCodec,
        /** If true, include products from unlisted sellers. Dataworkers only. */
        'ignoreWhitelist': StringBooleanCodec,
        /** Product status. Dataworkers only. */
        'status': URLStringCodec,
        /** Page */
        'page': StringNumberCodec,
        /** Number of results per page */
        'pageSize': StringNumberCodec,
      })
    ]), 'GET /v1/products Input Codec'),
    responseCodec: t.exact(t.intersection([t.type({
      'products': t.array(Product),
    }), t.partial({
      'sellers': t.array(SellerDisplayInfo),
    })])),
    responseCodecs: {
      200: t.exact(t.intersection([t.type({
        'products': t.array(Product),
      }), t.partial({
        'sellers': t.array(SellerDisplayInfo),
      })])),
    },
    
    security: [{"MableOauth2":["admin","buyer","seller","dataworker"]},{"NoAuth":[]}],
  },
  'POST /v1/products': {
    method: 'post',
    path: '/v1/products',
    accept: 'application/json',
    inputLocations: {
      'productInput': 'body',
    },
    inputCodec: t.exact(t.intersection([
      t.type({
        'productInput': ProductInput,
      }),
      t.partial({
        
      })
    ]), 'POST /v1/products Input Codec'),
    responseCodec: Product,
    responseCodecs: {
      201: Product,
    },
    
    security: [{"MableOauth2":["admin","dataworker","buyer","seller"]}],
  },
  'PATCH /v1/productVariants': {
    method: 'patch',
    path: '/v1/productVariants',
    accept: 'application/json',
    inputLocations: {
      'patches': 'body',
    },
    inputCodec: t.exact(t.intersection([
      t.type({
        'patches': t.array(SellerProductVariantPatch),
      }),
      t.partial({
        
      })
    ]), 'PATCH /v1/productVariants Input Codec'),
    responseCodec: t.exact(t.intersection([t.type({
      'variants': t.array(ProductVariantListDisplayInfo),
    }), t.partial({
      
    })])),
    responseCodecs: {
      200: t.exact(t.intersection([t.type({
        'variants': t.array(ProductVariantListDisplayInfo),
      }), t.partial({
        
      })])),
    },
    
    security: [{"MableOauth2":["admin","seller"]}],
  },
  'PATCH /v1/admin/productVariants': {
    method: 'patch',
    path: '/v1/admin/productVariants',
    accept: 'undefined',
    inputLocations: {
      'patches': 'body',
    },
    inputCodec: t.exact(t.intersection([
      t.type({
        'patches': t.array(ProductVariantPatch),
      }),
      t.partial({
        
      })
    ]), 'PATCH /v1/admin/productVariants Input Codec'),
    responseCodec: t.void,
    responseCodecs: {
      201: undefined,
    },
    
    security: [{"MableOauth2":["admin"]}],
  },
  'GET /v1/products/{productId}/detail': {
    method: 'get',
    path: '/v1/products/{productId}/detail',
    accept: 'application/json',
    inputLocations: {
      'productId': 'path',
      'include': 'query',
      'previewKey': 'query',
    },
    inputCodec: t.exact(t.intersection([
      t.type({
        'productId': StringNumberCodec,
      }),
      t.partial({
        'include': StringArrayCodec(CodegenEnumClosed({
          'seller': null,
          'relatedProducts': null,
        }, 'includeItem')),
        /** A Magic Link type key to allow viewing price information even when logged out. */
        'previewKey': URLStringCodec,
      })
    ]), 'GET /v1/products/{productId}/detail Input Codec'),
    responseCodec: t.exact(t.intersection([t.type({
      'product': ProductDisplayInfo,
    }), t.partial({
      'seller': SellerDisplayInfo,
      'relatedProductSections': t.array(RelatedProductSection),
    })])),
    responseCodecs: {
      200: t.exact(t.intersection([t.type({
        'product': ProductDisplayInfo,
      }), t.partial({
        'seller': SellerDisplayInfo,
        'relatedProductSections': t.array(RelatedProductSection),
      })])),
    },
    
    security: [{"MableOauth2":["admin","buyer","seller","dataworker"]},{"NoAuth":[]}],
  },
  'GET /v1/products/{productId}': {
    method: 'get',
    path: '/v1/products/{productId}',
    accept: 'application/json',
    inputLocations: {
      'productId': 'path',
      'include': 'query',
      'previewKey': 'query',
    },
    inputCodec: t.exact(t.intersection([
      t.type({
        'productId': StringNumberCodec,
      }),
      t.partial({
        'include': StringArrayCodec(CodegenEnumClosed({
          'seller': null,
          'relatedProducts': null,
        }, 'includeItem')),
        /** A Magic Link type key to allow viewing price information even when logged out. */
        'previewKey': URLStringCodec,
      })
    ]), 'GET /v1/products/{productId} Input Codec'),
    responseCodec: t.exact(t.intersection([t.type({
      'product': Product,
    }), t.partial({
      'seller': SellerDisplayInfo,
      'relatedProductSections': t.array(RelatedProductSection),
    })])),
    responseCodecs: {
      200: t.exact(t.intersection([t.type({
        'product': Product,
      }), t.partial({
        'seller': SellerDisplayInfo,
        'relatedProductSections': t.array(RelatedProductSection),
      })])),
    },
    
    security: [{"MableOauth2":["admin","buyer","seller","dataworker"]},{"NoAuth":[]}],
  },
  'PUT /v1/products/{productId}': {
    method: 'put',
    path: '/v1/products/{productId}',
    accept: 'application/json',
    inputLocations: {
      'productId': 'path',
      'productInput': 'body',
    },
    inputCodec: t.exact(t.intersection([
      t.type({
        'productId': StringNumberCodec,
        'productInput': ProductInput,
      }),
      t.partial({
        
      })
    ]), 'PUT /v1/products/{productId} Input Codec'),
    responseCodec: Product,
    responseCodecs: {
      200: Product,
    },
    
    security: [{"MableOauth2":["admin","dataworker","buyer","seller"]}],
  },
  'PATCH /v1/products/{productId}': {
    method: 'patch',
    path: '/v1/products/{productId}',
    accept: 'application/json',
    inputLocations: {
      'productId': 'path',
      'productInput': 'body',
    },
    inputCodec: t.exact(t.intersection([
      t.type({
        'productId': StringNumberCodec,
        'productInput': ProductInput,
      }),
      t.partial({
        
      })
    ]), 'PATCH /v1/products/{productId} Input Codec'),
    responseCodec: Product,
    responseCodecs: {
      200: Product,
    },
    
    security: [{"MableOauth2":["admin","dataworker","buyer","seller"]}],
  },
  'GET /v1/products/legacy/{productSlug}': {
    method: 'get',
    path: '/v1/products/legacy/{productSlug}',
    accept: 'application/json',
    inputLocations: {
      'productSlug': 'path',
    },
    inputCodec: t.exact(t.intersection([
      t.type({
        'productSlug': URLStringCodec,
      }),
      t.partial({
        
      })
    ]), 'GET /v1/products/legacy/{productSlug} Input Codec'),
    responseCodec: t.exact(t.intersection([t.type({
      'product': Product,
    }), t.partial({
      
    })])),
    responseCodecs: {
      200: t.exact(t.intersection([t.type({
        'product': Product,
      }), t.partial({
        
      })])),
    },
    
    security: [{"MableOauth2":["admin","buyer","seller"]},{"NoAuth":[]}],
  },
  'POST /v1/referral': {
    method: 'post',
    path: '/v1/referral',
    accept: 'undefined',
    inputLocations: {
      'referralInput': 'body',
    },
    inputCodec: t.exact(t.intersection([
      t.type({
        'referralInput': t.exact(t.intersection([t.type({
          'emailAddresses': t.array(t.string),
          'referralText': t.string,
        }), t.partial({
          
        })]), 'referralInput'),
      }),
      t.partial({
        
      })
    ]), 'POST /v1/referral Input Codec'),
    responseCodec: t.void,
    responseCodecs: {
      204: undefined,
    },
    
    security: [{"MableOauth2":["admin","seller"]}],
  },
  'POST /v1/buyerReferral': {
    method: 'post',
    path: '/v1/buyerReferral',
    accept: 'undefined',
    inputLocations: {
      'referralInput': 'body',
    },
    inputCodec: t.exact(t.intersection([
      t.type({
        'referralInput': t.exact(t.intersection([t.type({
          'emailAddresses': t.array(t.string),
        }), t.partial({
          
        })]), 'referralInput'),
      }),
      t.partial({
        
      })
    ]), 'POST /v1/buyerReferral Input Codec'),
    responseCodec: t.void,
    responseCodecs: {
      204: undefined,
    },
    
    security: [{"MableOauth2":["admin","buyer"]}],
  },
  'POST /v1/cloudinaryUploadSignature': {
    method: 'post',
    path: '/v1/cloudinaryUploadSignature',
    accept: 'application/json',
    inputLocations: {
      'params': 'body',
    },
    inputCodec: t.exact(t.intersection([
      t.type({
        'params': t.record(t.string, t.unknown),
      }),
      t.partial({
        
      })
    ]), 'POST /v1/cloudinaryUploadSignature Input Codec'),
    responseCodec: t.exact(t.intersection([t.type({
      'signature': t.string,
    }), t.partial({
      
    })])),
    responseCodecs: {
      200: t.exact(t.intersection([t.type({
        'signature': t.string,
      }), t.partial({
        
      })])),
    },
    
    security: [{"MableOauth2":["admin","seller","dataworker"]}],
  },
  'POST /v1/s3SignedUrl': {
    method: 'post',
    path: '/v1/s3SignedUrl',
    accept: 'application/json',
    inputLocations: {
      'params': 'body',
      'operation': 'query',
    },
    inputCodec: t.exact(t.intersection([
      t.type({
        'params': t.record(t.string, t.unknown),
        'operation': CodegenEnumClosed({
          'getObject': null,
          'putObject': null,
        }, 'operation'),
      }),
      t.partial({
        
      })
    ]), 'POST /v1/s3SignedUrl Input Codec'),
    responseCodec: t.exact(t.intersection([t.type({
      'signedUrl': t.string,
    }), t.partial({
      
    })])),
    responseCodecs: {
      200: t.exact(t.intersection([t.type({
        'signedUrl': t.string,
      }), t.partial({
        
      })])),
    },
    
    security: [{"MableOauth2":["admin"]}],
  },
  'POST /v1/invoiceSignedUrl': {
    method: 'post',
    path: '/v1/invoiceSignedUrl',
    accept: 'application/json',
    inputLocations: {
      'params': 'body',
    },
    inputCodec: t.exact(t.intersection([
      t.type({
        'params': t.exact(t.intersection([t.type({
          'orderId': t.string,
          'extension': t.string,
        }), t.partial({
          
        })]), 'params'),
      }),
      t.partial({
        
      })
    ]), 'POST /v1/invoiceSignedUrl Input Codec'),
    responseCodec: t.exact(t.intersection([t.type({
      'signedUrl': t.string,
      's3Key': t.string,
      's3Bucket': t.string,
    }), t.partial({
      
    })])),
    responseCodecs: {
      200: t.exact(t.intersection([t.type({
        'signedUrl': t.string,
        's3Key': t.string,
        's3Bucket': t.string,
      }), t.partial({
        
      })])),
    },
    
    security: [{"MableOauth2":["admin","seller"]}],
  },
  'POST /v1/search': {
    method: 'post',
    path: '/v1/search',
    accept: 'application/json',
    inputLocations: {
      'q': 'query',
      'categoryIds': 'query',
      'include': 'query',
      'page': 'query',
      'pageSize': 'query',
      'sellerId': 'query',
      'sellerSlug': 'query',
      'respectCustomSellerFilters': 'query',
      'previewKey': 'query',
      'useNewSearch': 'query',
      'variantHistoryForAccountId': 'query',
      'excludeVariantsWithNoHistory': 'query',
      'sortMethodId': 'query',
      'ignoreExclusiveWhitelist': 'query',
      'ignoreStatus': 'query',
      'body': 'body',
    },
    inputCodec: t.exact(t.intersection([
      t.type({
        /** `include=previouslyOrderedProductsForVariants`  means the product info in `productsForVariants`
        will also include a `previouslyOrdered` property that tells you whether that product has been
        ordered by your account before. Also, any variants of previously-ordered products will always
        show up at the beginning of the `variants` list, so clients can display previously-ordered
        products in a separate section before the rest of the list. */
        'include': StringArrayCodec(CodegenEnumClosed({
          'variants': null,
          'sellers': null,
          'filters': null,
          'productsForVariants': null,
          'previouslyOrderedProductsForVariants': null,
          'categoriesForIds': null,
          'onlyDirects': null,
        }, 'includeItem')),
      }),
      t.partial({
        'q': URLStringCodec,
        /** List of category IDs to search in, returns results in at least one of the given categories. */
        'categoryIds': StringArrayCodec(StringNumberCodec),
        /** Page */
        'page': StringNumberCodec,
        /** Number of results per page */
        'pageSize': StringNumberCodec,
        /** Restrict results to a particular seller */
        'sellerId': StringNumberCodec,
        /** Restrict results to a particular seller */
        'sellerSlug': URLStringCodec,
        /** If true, and if there's no q or filter selections, apply custom per-seller filters, like Associated Buyers' order history filtering. Basically just make this true on the seller detail page. */
        'respectCustomSellerFilters': StringBooleanCodec,
        /** Only matters if `sellerId` or `sellerSlug` is passed. If they are, and if this key matches
        the seller's `previewKey`, then don't redact prices, even if we would have otherwise. */
        'previewKey': URLStringCodec,
        /** Use new search methods designed for user facing searches */
        'useNewSearch': StringBooleanCodec,
        /** Only matters if `sellerId` is passed and the calling user has that sellerId. If they are,
        the last ordered date and quantity information for the given account for each variant will
        be attached */
        'variantHistoryForAccountId': StringNumberCodec,
        /** When variantHistoryForAccountId is provided, exclude variants without order history. This defaults to true to support existing app versions. */
        'excludeVariantsWithNoHistory': StringBooleanCodec,
        /** How variant results should be sorted. Acceptable searchMethods are returned in the search response. Defaults to 'recommended' */
        'sortMethodId': URLStringCodec,
        /** Admin-only - Set this to ignore any variant whitelisting and return all products/variants regardless of whether they are marked exclusive or not.
        If a non-admin sets this flag it is just ignored. */
        'ignoreExclusiveWhitelist': StringBooleanCodec,
        /** Admin-only - If true, include variants with a status other than available.
        If a non-admin sets this flag it is just ignored and the default behavior (status = 'available') is used. */
        'ignoreStatus': StringBooleanCodec,
        'body': t.exact(t.intersection([t.type({
          
        }), t.partial({
          /** A map from FilterGroup id to an array of the FilterGroupValue ids selected for that filter group. */
          'filters': t.record(t.string, FilterGroupSelection),
        })]), 'body'),
      })
    ]), 'POST /v1/search Input Codec'),
    responseCodec: SearchResults,
    responseCodecs: {
      200: SearchResults,
    },
    
    security: [{"MableOauth2":["admin","buyer","seller","dataworker"]},{"NoAuth":[]}],
  },
  'GET /v1/search/barcode': {
    method: 'get',
    path: '/v1/search/barcode',
    accept: 'application/json',
    inputLocations: {
      'barcodes': 'query',
    },
    inputCodec: t.exact(t.intersection([
      t.type({
        /** Barcodes you are searching for. */
        'barcodes': StringArrayCodec(URLStringCodec),
      }),
      t.partial({
        
      })
    ]), 'GET /v1/search/barcode Input Codec'),
    responseCodec: BarcodeSearchResults,
    responseCodecs: {
      200: BarcodeSearchResults,
    },
    
    security: [{"MableOauth2":["admin","buyer","seller","dataworker"]},{"NoAuth":[]}],
  },
  'GET /v1/search/suggest': {
    method: 'get',
    path: '/v1/search/suggest',
    accept: 'application/json',
    inputLocations: {
      'q': 'query',
    },
    inputCodec: t.exact(t.intersection([
      t.type({
        'q': URLStringCodec,
      }),
      t.partial({
        
      })
    ]), 'GET /v1/search/suggest Input Codec'),
    responseCodec: SuggestionSearchResults,
    responseCodecs: {
      200: SuggestionSearchResults,
    },
    
    security: [{"MableOauth2":["admin","buyer","seller"]},{"NoAuth":[]}],
  },
  'GET /v1/admin/search/suggestion': {
    method: 'get',
    path: '/v1/admin/search/suggestion',
    accept: 'application/json',
    inputLocations: {
      'q': 'query',
    },
    inputCodec: t.exact(t.intersection([
      t.type({
        'q': URLStringCodec,
      }),
      t.partial({
        
      })
    ]), 'GET /v1/admin/search/suggestion Input Codec'),
    responseCodec: t.exact(t.intersection([t.type({
      'terms': t.array(t.string),
    }), t.partial({
      
    })])),
    responseCodecs: {
      200: t.exact(t.intersection([t.type({
        'terms': t.array(t.string),
      }), t.partial({
        
      })])),
    },
    
    security: [{"MableOauth2":["admin"]}],
  },
  'POST /v1/admin/search/suggestion': {
    method: 'post',
    path: '/v1/admin/search/suggestion',
    accept: 'undefined',
    inputLocations: {
      'term': 'query',
    },
    inputCodec: t.exact(t.intersection([
      t.type({
        'term': URLStringCodec,
      }),
      t.partial({
        
      })
    ]), 'POST /v1/admin/search/suggestion Input Codec'),
    responseCodec: t.void,
    responseCodecs: {
      201: undefined,
    },
    
    security: [{"MableOauth2":["admin"]}],
  },
  'DELETE /v1/admin/search/suggestion': {
    method: 'delete',
    path: '/v1/admin/search/suggestion',
    accept: 'undefined',
    inputLocations: {
      'term': 'query',
    },
    inputCodec: t.exact(t.intersection([
      t.type({
        'term': URLStringCodec,
      }),
      t.partial({
        
      })
    ]), 'DELETE /v1/admin/search/suggestion Input Codec'),
    responseCodec: t.void,
    responseCodecs: {
      204: undefined,
    },
    
    security: [{"MableOauth2":["admin"]}],
  },
  'POST /v1/users': {
    method: 'post',
    path: '/v1/users',
    accept: 'undefined',
    inputLocations: {
      'body': 'body',
    },
    inputCodec: t.exact(t.intersection([
      t.type({
        'body': UserSignup,
      }),
      t.partial({
        
      })
    ]), 'POST /v1/users Input Codec'),
    responseCodec: t.void,
    responseCodecs: {
      204: undefined,
    },
    
    security: [{"MableOauth2":["admin","buyer","seller"]},{"NoAuth":[]}],
  },
  'GET /v1/admin/accounts': {
    method: 'get',
    path: '/v1/admin/accounts',
    accept: 'application/json',
    inputLocations: {
      'buyerIds': 'query',
      'sellerIds': 'query',
      'email': 'query',
    },
    inputCodec: t.exact(t.intersection([
      t.type({
        
      }),
      t.partial({
        'buyerIds': StringArrayCodec(StringNumberCodec),
        'sellerIds': StringArrayCodec(StringNumberCodec),
        'email': URLStringCodec,
      })
    ]), 'GET /v1/admin/accounts Input Codec'),
    responseCodec: t.exact(t.intersection([t.type({
      'accounts': t.array(AccountAdminDisplayInfo),
    }), t.partial({
      
    })])),
    responseCodecs: {
      200: t.exact(t.intersection([t.type({
        'accounts': t.array(AccountAdminDisplayInfo),
      }), t.partial({
        
      })])),
    },
    
    security: [{"MableOauth2":["admin"]}],
  },
  'GET /v1/admin/account/{accountIdOrName}': {
    method: 'get',
    path: '/v1/admin/account/{accountIdOrName}',
    accept: 'application/json',
    inputLocations: {
      'accountIdOrName': 'path',
    },
    inputCodec: t.exact(t.intersection([
      t.type({
        'accountIdOrName': URLStringCodec,
      }),
      t.partial({
        
      })
    ]), 'GET /v1/admin/account/{accountIdOrName} Input Codec'),
    responseCodec: t.exact(t.intersection([t.type({
      'account': AccountAdminDisplayInfo,
    }), t.partial({
      /** Transactions where this account is the account id. Currently, that means orders where this account was the buyer */
      'transactions': t.array(Transaction),
      'transactionGroups': t.record(t.string, TransactionGroup),
      /** Transactions where this account's seller is the productSellerId, i.e. orders received by this seller. */
      'receivedTransactions': t.array(Transaction),
      /** Relationships between this account's buyer or seller and other sellers or buyers */
      'relationships': t.array(t.intersection([
        AccountRelationship,
        t.exact(t.intersection([t.type({
          
        }), t.partial({
          /** Only defined if this is a relationship between the account's seller and a buyer. */
          'buyer': Buyer,
          /** Only defined if this is a relationship between the account's buyer and a seller. */
          'seller': SellerAdminDisplayInfo,
        })]))
      ])),
      /** A list of account related issues preventing buyer checkout */
      'checkoutIssues': t.array(t.string),
    })])),
    responseCodecs: {
      200: t.exact(t.intersection([t.type({
        'account': AccountAdminDisplayInfo,
      }), t.partial({
        /** Transactions where this account is the account id. Currently, that means orders where this account was the buyer */
        'transactions': t.array(Transaction),
        'transactionGroups': t.record(t.string, TransactionGroup),
        /** Transactions where this account's seller is the productSellerId, i.e. orders received by this seller. */
        'receivedTransactions': t.array(Transaction),
        /** Relationships between this account's buyer or seller and other sellers or buyers */
        'relationships': t.array(t.intersection([
          AccountRelationship,
          t.exact(t.intersection([t.type({
            
          }), t.partial({
            /** Only defined if this is a relationship between the account's seller and a buyer. */
            'buyer': Buyer,
            /** Only defined if this is a relationship between the account's buyer and a seller. */
            'seller': SellerAdminDisplayInfo,
          })]))
        ])),
        /** A list of account related issues preventing buyer checkout */
        'checkoutIssues': t.array(t.string),
      })])),
    },
    
    security: [{"MableOauth2":["admin","dataworker"]}],
  },
  'PATCH /v1/admin/account/{accountId}': {
    method: 'patch',
    path: '/v1/admin/account/{accountId}',
    accept: 'undefined',
    inputLocations: {
      'accountId': 'path',
      'body': 'body',
    },
    inputCodec: t.exact(t.intersection([
      t.type({
        'accountId': StringNumberCodec,
        'body': AdminAccountPatch,
      }),
      t.partial({
        
      })
    ]), 'PATCH /v1/admin/account/{accountId} Input Codec'),
    responseCodec: t.void,
    responseCodecs: {
      204: undefined,
    },
    
    security: [{"MableOauth2":["admin"]}],
  },
  'POST /v1/admin/account/{accountId}/relationships': {
    method: 'post',
    path: '/v1/admin/account/{accountId}/relationships',
    accept: 'undefined',
    inputLocations: {
      'accountId': 'path',
      'buyerId': 'query',
      'sellerId': 'query',
      'body': 'body',
    },
    inputCodec: t.exact(t.intersection([
      t.type({
        'accountId': StringNumberCodec,
        'body': AccountRelationship,
      }),
      t.partial({
        'buyerId': StringNumberCodec,
        'sellerId': StringNumberCodec,
      })
    ]), 'POST /v1/admin/account/{accountId}/relationships Input Codec'),
    responseCodec: t.void,
    responseCodecs: {
      201: undefined,
    },
    
    security: [{"MableOauth2":["admin"]}],
  },
  'PUT /v1/admin/account/{accountId}/relationships': {
    method: 'put',
    path: '/v1/admin/account/{accountId}/relationships',
    accept: 'undefined',
    inputLocations: {
      'accountId': 'path',
      'buyerId': 'query',
      'sellerId': 'query',
      'body': 'body',
    },
    inputCodec: t.exact(t.intersection([
      t.type({
        'accountId': StringNumberCodec,
        'body': AccountRelationship,
      }),
      t.partial({
        'buyerId': StringNumberCodec,
        'sellerId': StringNumberCodec,
      })
    ]), 'PUT /v1/admin/account/{accountId}/relationships Input Codec'),
    responseCodec: t.void,
    responseCodecs: {
      204: undefined,
    },
    
    security: [{"MableOauth2":["admin"]}],
  },
  'DELETE /v1/admin/account/{accountId}/relationships': {
    method: 'delete',
    path: '/v1/admin/account/{accountId}/relationships',
    accept: 'undefined',
    inputLocations: {
      'accountId': 'path',
      'buyerId': 'query',
      'sellerId': 'query',
    },
    inputCodec: t.exact(t.intersection([
      t.type({
        'accountId': StringNumberCodec,
        'buyerId': StringNumberCodec,
        'sellerId': StringNumberCodec,
      }),
      t.partial({
        
      })
    ]), 'DELETE /v1/admin/account/{accountId}/relationships Input Codec'),
    responseCodec: t.void,
    responseCodecs: {
      204: undefined,
    },
    
    security: [{"MableOauth2":["admin"]}],
  },
  'POST /v1/admin/account/{accountId}/oneTimeInvitationLink': {
    method: 'post',
    path: '/v1/admin/account/{accountId}/oneTimeInvitationLink',
    accept: 'application/json',
    inputLocations: {
      'accountId': 'path',
      'body': 'body',
    },
    inputCodec: t.exact(t.intersection([
      t.type({
        'accountId': StringNumberCodec,
        'body': t.exact(t.intersection([t.type({
          /** The type of link that should be generated. */
          'type': CodegenEnumClosed({
            'buyer': null,
            'seller': null,
          }, 'body'),
        }), t.partial({
          
        })]), 'body'),
      }),
      t.partial({
        
      })
    ]), 'POST /v1/admin/account/{accountId}/oneTimeInvitationLink Input Codec'),
    responseCodec: t.exact(t.intersection([t.type({
      'link': t.string,
    }), t.partial({
      
    })])),
    responseCodecs: {
      200: t.exact(t.intersection([t.type({
        'link': t.string,
      }), t.partial({
        
      })])),
    },
    
    security: [{"MableOauth2":["admin"]}],
  },
  'PATCH /v1/admin/account/{accountId}/flagOverrides': {
    method: 'patch',
    path: '/v1/admin/account/{accountId}/flagOverrides',
    accept: 'undefined',
    inputLocations: {
      'accountId': 'path',
      'body': 'body',
    },
    inputCodec: t.exact(t.intersection([
      t.type({
        'accountId': StringNumberCodec,
        'body': t.exact(t.intersection([t.type({
          'overrides': t.array(AccountFlagValue),
          /** The note that gets recorded while changing an account's flags */
          'note': t.string,
        }), t.partial({
          
        })]), 'body'),
      }),
      t.partial({
        
      })
    ]), 'PATCH /v1/admin/account/{accountId}/flagOverrides Input Codec'),
    responseCodec: t.void,
    responseCodecs: {
      204: undefined,
    },
    
    security: [{"MableOauth2":["admin"]}],
  },
  'POST /v1/admin/account/{accountId}/applyConfiguration': {
    method: 'post',
    path: '/v1/admin/account/{accountId}/applyConfiguration',
    accept: 'undefined',
    inputLocations: {
      'accountId': 'path',
      'body': 'body',
    },
    inputCodec: t.exact(t.intersection([
      t.type({
        'accountId': StringNumberCodec,
        'body': t.exact(t.intersection([t.type({
          /** The identifier of the configuration to apply */
          'configurationIdentifier': t.string,
        }), t.partial({
          
        })]), 'body'),
      }),
      t.partial({
        
      })
    ]), 'POST /v1/admin/account/{accountId}/applyConfiguration Input Codec'),
    responseCodec: t.void,
    responseCodecs: {
      204: undefined,
    },
    
    security: [{"MableOauth2":["admin"]}],
  },
  'POST /v1/admin/account/{accountId}/applyPartnerConfigurationToPartneredBuyers': {
    method: 'post',
    path: '/v1/admin/account/{accountId}/applyPartnerConfigurationToPartneredBuyers',
    accept: 'application/json',
    inputLocations: {
      'accountId': 'path',
    },
    inputCodec: t.exact(t.intersection([
      t.type({
        'accountId': StringNumberCodec,
      }),
      t.partial({
        
      })
    ]), 'POST /v1/admin/account/{accountId}/applyPartnerConfigurationToPartneredBuyers Input Codec'),
    responseCodec: t.exact(t.intersection([t.type({
      
    }), t.partial({
      'summary': t.string,
    })])),
    responseCodecs: {
      200: t.exact(t.intersection([t.type({
        
      }), t.partial({
        'summary': t.string,
      })])),
    },
    
    security: [{"MableOauth2":["admin"]}],
  },
  'POST /v1/admin/account/{accountId}/createMessagingUsers': {
    method: 'post',
    path: '/v1/admin/account/{accountId}/createMessagingUsers',
    accept: 'undefined',
    inputLocations: {
      'accountId': 'path',
    },
    inputCodec: t.exact(t.intersection([
      t.type({
        'accountId': StringNumberCodec,
      }),
      t.partial({
        
      })
    ]), 'POST /v1/admin/account/{accountId}/createMessagingUsers Input Codec'),
    responseCodec: t.void,
    responseCodecs: {
      201: undefined,
    },
    
    security: [{"MableOauth2":["admin"]}],
  },
  'POST /v1/admin/account/{accountId}/updateMessagingProfiles': {
    method: 'post',
    path: '/v1/admin/account/{accountId}/updateMessagingProfiles',
    accept: 'undefined',
    inputLocations: {
      'accountId': 'path',
    },
    inputCodec: t.exact(t.intersection([
      t.type({
        'accountId': StringNumberCodec,
      }),
      t.partial({
        
      })
    ]), 'POST /v1/admin/account/{accountId}/updateMessagingProfiles Input Codec'),
    responseCodec: t.void,
    responseCodecs: {
      204: undefined,
    },
    
    security: [{"MableOauth2":["admin"]}],
  },
  'GET /v1/admin/account/{accountId}/messageChannels': {
    method: 'get',
    path: '/v1/admin/account/{accountId}/messageChannels',
    accept: 'application/json',
    inputLocations: {
      'accountId': 'path',
      'withOtherSellerId': 'query',
    },
    inputCodec: t.exact(t.intersection([
      t.type({
        'accountId': StringNumberCodec,
      }),
      t.partial({
        /** If included, return channels present containing at least one user from the target account and this seller. */
        'withOtherSellerId': StringNumberCodec,
      })
    ]), 'GET /v1/admin/account/{accountId}/messageChannels Input Codec'),
    responseCodec: t.exact(t.intersection([t.type({
      'messageChannels': t.array(MessageChannelAdminDisplayInfo),
    }), t.partial({
      
    })])),
    responseCodecs: {
      200: t.exact(t.intersection([t.type({
        'messageChannels': t.array(MessageChannelAdminDisplayInfo),
      }), t.partial({
        
      })])),
    },
    
    security: [{"MableOauth2":["admin"]}],
  },
  'DELETE /v1/admin/account/{accountId}/messageChannels': {
    method: 'delete',
    path: '/v1/admin/account/{accountId}/messageChannels',
    accept: 'undefined',
    inputLocations: {
      'accountId': 'path',
    },
    inputCodec: t.exact(t.intersection([
      t.type({
        'accountId': StringNumberCodec,
      }),
      t.partial({
        
      })
    ]), 'DELETE /v1/admin/account/{accountId}/messageChannels Input Codec'),
    responseCodec: t.void,
    responseCodecs: {
      204: undefined,
    },
    
    security: [{"MableOauth2":["admin"]}],
  },
  'GET /v1/admin/account/{accountId}/buyerLocations': {
    method: 'get',
    path: '/v1/admin/account/{accountId}/buyerLocations',
    accept: 'application/json',
    inputLocations: {
      'accountId': 'path',
    },
    inputCodec: t.exact(t.intersection([
      t.type({
        'accountId': StringNumberCodec,
      }),
      t.partial({
        
      })
    ]), 'GET /v1/admin/account/{accountId}/buyerLocations Input Codec'),
    responseCodec: t.exact(t.intersection([t.type({
      'buyerLocations': t.array(BuyerLocation),
    }), t.partial({
      
    })])),
    responseCodecs: {
      200: t.exact(t.intersection([t.type({
        'buyerLocations': t.array(BuyerLocation),
      }), t.partial({
        
      })])),
    },
    
    security: [{"MableOauth2":["admin"]}],
  },
  'POST /v1/admin/account/{accountId}/buyerLocations': {
    method: 'post',
    path: '/v1/admin/account/{accountId}/buyerLocations',
    accept: 'application/json',
    inputLocations: {
      'accountId': 'path',
      'body': 'body',
    },
    inputCodec: t.exact(t.intersection([
      t.type({
        'accountId': StringNumberCodec,
        'body': BuyerLocationInput,
      }),
      t.partial({
        
      })
    ]), 'POST /v1/admin/account/{accountId}/buyerLocations Input Codec'),
    responseCodec: BuyerLocation,
    responseCodecs: {
      201: BuyerLocation,
    },
    
    security: [{"MableOauth2":["admin"]}],
  },
  'GET /v1/admin/account/{accountId}/addresses': {
    method: 'get',
    path: '/v1/admin/account/{accountId}/addresses',
    accept: 'application/json',
    inputLocations: {
      'accountId': 'path',
      'status': 'query',
      'type': 'query',
    },
    inputCodec: t.exact(t.intersection([
      t.type({
        'accountId': StringNumberCodec,
      }),
      t.partial({
        'status': StringArrayCodec(URLStringCodec),
        'type': StringArrayCodec(AddressType),
      })
    ]), 'GET /v1/admin/account/{accountId}/addresses Input Codec'),
    responseCodec: t.exact(t.intersection([t.type({
      'addresses': t.array(Address),
    }), t.partial({
      
    })])),
    responseCodecs: {
      200: t.exact(t.intersection([t.type({
        'addresses': t.array(Address),
      }), t.partial({
        
      })])),
    },
    
    security: [{"MableOauth2":["admin"]}],
  },
  'GET /v1/admin/account/{accountId}/ediConfiguration': {
    method: 'get',
    path: '/v1/admin/account/{accountId}/ediConfiguration',
    accept: 'application/json',
    inputLocations: {
      'accountId': 'path',
    },
    inputCodec: t.exact(t.intersection([
      t.type({
        'accountId': StringNumberCodec,
      }),
      t.partial({
        
      })
    ]), 'GET /v1/admin/account/{accountId}/ediConfiguration Input Codec'),
    responseCodec: t.exact(t.intersection([t.type({
      'ediConfiguration': EdiConfiguration,
    }), t.partial({
      
    })])),
    responseCodecs: {
      200: t.exact(t.intersection([t.type({
        'ediConfiguration': EdiConfiguration,
      }), t.partial({
        
      })])),
    },
    
    security: [{"MableOauth2":["admin"]}],
  },
  'PUT /v1/admin/account/{accountId}/ediConfiguration': {
    method: 'put',
    path: '/v1/admin/account/{accountId}/ediConfiguration',
    accept: 'undefined',
    inputLocations: {
      'accountId': 'path',
      'body': 'body',
    },
    inputCodec: t.exact(t.intersection([
      t.type({
        'accountId': StringNumberCodec,
        'body': t.exact(t.intersection([t.type({
          'ediConfigurationId': t.union([t.null, t.string]),
        }), t.partial({
          
        })]), 'body'),
      }),
      t.partial({
        
      })
    ]), 'PUT /v1/admin/account/{accountId}/ediConfiguration Input Codec'),
    responseCodec: t.void,
    responseCodecs: {
      204: undefined,
    },
    
    security: [{"MableOauth2":["admin"]}],
  },
  'POST /v1/admin/account/{accountId}/bulkLandedCosts': {
    method: 'post',
    path: '/v1/admin/account/{accountId}/bulkLandedCosts',
    accept: 'undefined',
    inputLocations: {
      'accountId': 'path',
      'body': 'body',
    },
    inputCodec: t.exact(t.intersection([
      t.type({
        'accountId': StringNumberCodec,
        'body': t.intersection([
          BaseLandedCostParameters,
          t.exact(t.intersection([t.type({
            
          }), t.partial({
            'sellerIds': t.array(t.number),
            'sellersFromCategoryIds': t.array(t.number),
          })]), 'body')
        ], 'body'),
      }),
      t.partial({
        
      })
    ]), 'POST /v1/admin/account/{accountId}/bulkLandedCosts Input Codec'),
    responseCodec: t.void,
    responseCodecs: {
      202: undefined,
    },
    
    security: [{"MableOauth2":["admin"]}],
  },
  'GET /v1/admin/account/{accountId}/variantPartnerships': {
    method: 'get',
    path: '/v1/admin/account/{accountId}/variantPartnerships',
    accept: 'application/json',
    inputLocations: {
      'accountId': 'path',
      'sellerIds': 'query',
    },
    inputCodec: t.exact(t.intersection([
      t.type({
        'accountId': StringNumberCodec,
      }),
      t.partial({
        'sellerIds': StringArrayCodec(StringNumberCodec),
      })
    ]), 'GET /v1/admin/account/{accountId}/variantPartnerships Input Codec'),
    responseCodec: t.exact(t.intersection([t.type({
      
    }), t.partial({
      'variantPartnerships': t.array(VariantPartnerMembership),
      'variants': t.array(ProductVariantListDisplayInfo),
    })])),
    responseCodecs: {
      200: t.exact(t.intersection([t.type({
        
      }), t.partial({
        'variantPartnerships': t.array(VariantPartnerMembership),
        'variants': t.array(ProductVariantListDisplayInfo),
      })])),
    },
    
    security: [{"MableOauth2":["admin"]}],
  },
  'PATCH /v1/admin/account/{accountId}/variantPartnerships': {
    method: 'patch',
    path: '/v1/admin/account/{accountId}/variantPartnerships',
    accept: 'undefined',
    inputLocations: {
      'accountId': 'path',
      'body': 'body',
    },
    inputCodec: t.exact(t.intersection([
      t.type({
        'accountId': StringNumberCodec,
        'body': t.exact(t.intersection([t.type({
          'variantPartnerships': t.array(t.exact(t.intersection([t.type({
            'productVariantId': t.number,
          }), t.partial({
            /** If provided, the manuallyExcludedReasons will be replaced with this value. */
            'manuallyExcludedReasons': t.union([t.null, t.array(t.string)]),
            /** If provided, the manuallyIgnoreAutomaticallyExcludedReasons will be replaced with this value. */
            'manuallyIgnoreAutomaticallyExcludedReasons': t.union([t.null, t.array(t.string)]),
            /** If provided, the thirdPartyTags will be replaced with this value. */
            'thirdPartyTags': t.union([t.null, t.array(t.string)]),
            'thirdPartyTagsToAdd': t.union([t.null, t.array(t.string)]),
            'thirdPartyTagsToRemove': t.union([t.null, t.array(t.string)]),
          })]), 'bodyItem')),
        }), t.partial({
          
        })]), 'body'),
      }),
      t.partial({
        
      })
    ]), 'PATCH /v1/admin/account/{accountId}/variantPartnerships Input Codec'),
    responseCodec: t.void,
    responseCodecs: {
      204: undefined,
    },
    
    security: [{"MableOauth2":["admin"]}],
  },
  'POST /v1/admin/account/{accountId}/variantPartnerships/refresh': {
    method: 'post',
    path: '/v1/admin/account/{accountId}/variantPartnerships/refresh',
    accept: 'undefined',
    inputLocations: {
      'accountId': 'path',
      'sellerIds': 'query',
    },
    inputCodec: t.exact(t.intersection([
      t.type({
        'accountId': StringNumberCodec,
      }),
      t.partial({
        'sellerIds': StringArrayCodec(StringNumberCodec),
      })
    ]), 'POST /v1/admin/account/{accountId}/variantPartnerships/refresh Input Codec'),
    responseCodec: t.void,
    responseCodecs: {
      204: undefined,
    },
    
    security: [{"MableOauth2":["admin"]}],
  },
  'POST /v1/admin/partnerProgram/{partnerName}/updateLandedCosts': {
    method: 'post',
    path: '/v1/admin/partnerProgram/{partnerName}/updateLandedCosts',
    accept: 'undefined',
    inputLocations: {
      'partnerName': 'path',
      'body': 'body',
    },
    inputCodec: t.exact(t.intersection([
      t.type({
        'partnerName': URLStringCodec,
        'body': t.exact(t.intersection([t.type({
          'startAt': DateFromISOString,
        }), t.partial({
          'sellerWhitelistCategoryId': t.number,
          'sellerBlacklistCategoryId': t.number,
        })]), 'body'),
      }),
      t.partial({
        
      })
    ]), 'POST /v1/admin/partnerProgram/{partnerName}/updateLandedCosts Input Codec'),
    responseCodec: t.void,
    responseCodecs: {
      202: undefined,
    },
    
    security: [{"MableOauth2":["admin"]}],
  },
  'POST /v1/admin/buyers/{buyerId}/updateLandedCosts': {
    method: 'post',
    path: '/v1/admin/buyers/{buyerId}/updateLandedCosts',
    accept: 'undefined',
    inputLocations: {
      'buyerId': 'path',
      'body': 'body',
    },
    inputCodec: t.exact(t.intersection([
      t.type({
        'buyerId': StringNumberCodec,
        'body': t.exact(t.intersection([t.type({
          'startAt': DateFromISOString,
        }), t.partial({
          
        })]), 'body'),
      }),
      t.partial({
        
      })
    ]), 'POST /v1/admin/buyers/{buyerId}/updateLandedCosts Input Codec'),
    responseCodec: t.void,
    responseCodecs: {
      202: undefined,
    },
    
    security: [{"MableOauth2":["admin"]}],
  },
  'GET /v1/admin/account/{accountId}/auditLog': {
    method: 'get',
    path: '/v1/admin/account/{accountId}/auditLog',
    accept: 'application/json',
    inputLocations: {
      'accountId': 'path',
      'page': 'query',
      'pageSize': 'query',
    },
    inputCodec: t.exact(t.intersection([
      t.type({
        'accountId': StringNumberCodec,
      }),
      t.partial({
        /** Page */
        'page': StringNumberCodec,
        /** Number of results per page */
        'pageSize': StringNumberCodec,
      })
    ]), 'GET /v1/admin/account/{accountId}/auditLog Input Codec'),
    responseCodec: t.exact(t.intersection([t.type({
      
    }), t.partial({
      'totalCount': t.number,
      'logs': t.array(AuditLogEntry),
    })])),
    responseCodecs: {
      200: t.exact(t.intersection([t.type({
        
      }), t.partial({
        'totalCount': t.number,
        'logs': t.array(AuditLogEntry),
      })])),
    },
    
    security: [{"MableOauth2":["admin"]}],
  },
  'PATCH /v1/admin/ediConfigurations/{ediConfigurationId}': {
    method: 'patch',
    path: '/v1/admin/ediConfigurations/{ediConfigurationId}',
    accept: 'application/json',
    inputLocations: {
      'ediConfigurationId': 'path',
      'body': 'body',
    },
    inputCodec: t.exact(t.intersection([
      t.type({
        'ediConfigurationId': URLStringCodec,
        'body': EdiConfigurationPatch,
      }),
      t.partial({
        
      })
    ]), 'PATCH /v1/admin/ediConfigurations/{ediConfigurationId} Input Codec'),
    responseCodec: t.exact(t.intersection([t.type({
      'ediConfiguration': EdiConfiguration,
    }), t.partial({
      
    })])),
    responseCodecs: {
      200: t.exact(t.intersection([t.type({
        'ediConfiguration': EdiConfiguration,
      }), t.partial({
        
      })])),
    },
    
    security: [{"MableOauth2":["admin"]}],
  },
  'GET /v1/admin/ediConfigurations': {
    method: 'get',
    path: '/v1/admin/ediConfigurations',
    accept: 'application/json',
    inputLocations: {
      
    },
    inputCodec: t.exact(t.intersection([
      t.type({
        
      }),
      t.partial({
        
      })
    ]), 'GET /v1/admin/ediConfigurations Input Codec'),
    responseCodec: t.exact(t.intersection([t.type({
      'ediConfigurations': t.array(EdiConfiguration),
    }), t.partial({
      
    })])),
    responseCodecs: {
      200: t.exact(t.intersection([t.type({
        'ediConfigurations': t.array(EdiConfiguration),
      }), t.partial({
        
      })])),
    },
    
    security: [{"MableOauth2":["admin"]}],
  },
  'POST /v1/admin/ediConfigurations': {
    method: 'post',
    path: '/v1/admin/ediConfigurations',
    accept: 'application/json',
    inputLocations: {
      'body': 'body',
    },
    inputCodec: t.exact(t.intersection([
      t.type({
        'body': EdiConfigurationInput,
      }),
      t.partial({
        
      })
    ]), 'POST /v1/admin/ediConfigurations Input Codec'),
    responseCodec: t.exact(t.intersection([t.type({
      'ediConfiguration': EdiConfiguration,
    }), t.partial({
      
    })])),
    responseCodecs: {
      201: t.exact(t.intersection([t.type({
        'ediConfiguration': EdiConfiguration,
      }), t.partial({
        
      })])),
    },
    
    security: [{"MableOauth2":["admin"]}],
  },
  'GET /v1/admin/buyerLocations/{buyerLocationId}': {
    method: 'get',
    path: '/v1/admin/buyerLocations/{buyerLocationId}',
    accept: 'application/json',
    inputLocations: {
      'buyerLocationId': 'path',
    },
    inputCodec: t.exact(t.intersection([
      t.type({
        'buyerLocationId': URLStringCodec,
      }),
      t.partial({
        
      })
    ]), 'GET /v1/admin/buyerLocations/{buyerLocationId} Input Codec'),
    responseCodec: BuyerLocation,
    responseCodecs: {
      200: BuyerLocation,
    },
    
    security: [{"MableOauth2":["admin"]}],
  },
  'PATCH /v1/admin/buyerLocations/{buyerLocationId}': {
    method: 'patch',
    path: '/v1/admin/buyerLocations/{buyerLocationId}',
    accept: 'application/json',
    inputLocations: {
      'buyerLocationId': 'path',
      'body': 'body',
    },
    inputCodec: t.exact(t.intersection([
      t.type({
        'buyerLocationId': URLStringCodec,
        'body': BuyerLocationPatch,
      }),
      t.partial({
        
      })
    ]), 'PATCH /v1/admin/buyerLocations/{buyerLocationId} Input Codec'),
    responseCodec: BuyerLocation,
    responseCodecs: {
      200: BuyerLocation,
    },
    
    security: [{"MableOauth2":["admin"]}],
  },
  'GET /v1/admin/products/{productId}': {
    method: 'get',
    path: '/v1/admin/products/{productId}',
    accept: 'application/json',
    inputLocations: {
      'productId': 'path',
    },
    inputCodec: t.exact(t.intersection([
      t.type({
        'productId': StringNumberCodec,
      }),
      t.partial({
        
      })
    ]), 'GET /v1/admin/products/{productId} Input Codec'),
    responseCodec: t.exact(t.intersection([t.type({
      'product': Product,
    }), t.partial({
      'aplMemberships': t.array(AdminProductVariantAplMembership),
    })])),
    responseCodecs: {
      200: t.exact(t.intersection([t.type({
        'product': Product,
      }), t.partial({
        'aplMemberships': t.array(AdminProductVariantAplMembership),
      })])),
    },
    
    security: [{"MableOauth2":["admin","dataworker"]}],
  },
  'POST /v1/admin/orders/{orderId}/cancel': {
    method: 'post',
    path: '/v1/admin/orders/{orderId}/cancel',
    accept: 'application/json',
    inputLocations: {
      'orderId': 'path',
      'input': 'body',
    },
    inputCodec: t.exact(t.intersection([
      t.type({
        'orderId': StringNumberCodec,
        'input': t.exact(t.intersection([t.type({
          'reason': t.string,
        }), t.partial({
          
        })]), 'input'),
      }),
      t.partial({
        
      })
    ]), 'POST /v1/admin/orders/{orderId}/cancel Input Codec'),
    responseCodec: TransactionWithAssociations,
    responseCodecs: {
      200: TransactionWithAssociations,
    },
    
    security: [{"MableOauth2":["admin"]}],
  },
  'POST /v1/admin/orders/{orderId}/void': {
    method: 'post',
    path: '/v1/admin/orders/{orderId}/void',
    accept: 'application/json',
    inputLocations: {
      'orderId': 'path',
      'input': 'body',
    },
    inputCodec: t.exact(t.intersection([
      t.type({
        'orderId': StringNumberCodec,
        'input': t.exact(t.intersection([t.type({
          'reason': t.string,
        }), t.partial({
          
        })]), 'input'),
      }),
      t.partial({
        
      })
    ]), 'POST /v1/admin/orders/{orderId}/void Input Codec'),
    responseCodec: TransactionWithAssociations,
    responseCodecs: {
      200: TransactionWithAssociations,
    },
    
    security: [{"MableOauth2":["admin"]}],
  },
  'POST /v1/admin/orders/{orderId}/uncancel': {
    method: 'post',
    path: '/v1/admin/orders/{orderId}/uncancel',
    accept: 'application/json',
    inputLocations: {
      'orderId': 'path',
      'input': 'body',
    },
    inputCodec: t.exact(t.intersection([
      t.type({
        'orderId': StringNumberCodec,
        'input': t.exact(t.intersection([t.type({
          'reason': t.string,
        }), t.partial({
          
        })]), 'input'),
      }),
      t.partial({
        
      })
    ]), 'POST /v1/admin/orders/{orderId}/uncancel Input Codec'),
    responseCodec: TransactionWithAssociations,
    responseCodecs: {
      200: TransactionWithAssociations,
    },
    
    security: [{"MableOauth2":["admin"]}],
  },
  'GET /v1/account/flags': {
    method: 'get',
    path: '/v1/account/flags',
    accept: 'application/json',
    inputLocations: {
      
    },
    inputCodec: t.exact(t.intersection([
      t.type({
        
      }),
      t.partial({
        
      })
    ]), 'GET /v1/account/flags Input Codec'),
    responseCodec: t.exact(t.intersection([t.type({
      'flags': t.array(AccountFlag),
    }), t.partial({
      
    })])),
    responseCodecs: {
      200: t.exact(t.intersection([t.type({
        'flags': t.array(AccountFlag),
      }), t.partial({
        
      })])),
    },
    
    security: [{"MableOauth2":["buyer","seller"]}],
  },
  'PATCH /v1/account/flags': {
    method: 'patch',
    path: '/v1/account/flags',
    accept: 'undefined',
    inputLocations: {
      'body': 'body',
    },
    inputCodec: t.exact(t.intersection([
      t.type({
        'body': t.exact(t.intersection([t.type({
          'values': t.array(AccountFlagValue),
        }), t.partial({
          
        })]), 'body'),
      }),
      t.partial({
        
      })
    ]), 'PATCH /v1/account/flags Input Codec'),
    responseCodec: t.void,
    responseCodecs: {
      204: undefined,
    },
    
    security: [{"MableOauth2":["buyer","seller"]}],
  },
  'DELETE /v1/admin/users/{userId}': {
    method: 'delete',
    path: '/v1/admin/users/{userId}',
    accept: 'undefined',
    inputLocations: {
      'userId': 'path',
    },
    inputCodec: t.exact(t.intersection([
      t.type({
        'userId': StringNumberCodec,
      }),
      t.partial({
        
      })
    ]), 'DELETE /v1/admin/users/{userId} Input Codec'),
    responseCodec: t.void,
    responseCodecs: {
      200: undefined,
    },
    
    security: [{"MableOauth2":["admin"]}],
  },
  'POST /v1/admin/users/{userId}/updateEmail': {
    method: 'post',
    path: '/v1/admin/users/{userId}/updateEmail',
    accept: 'undefined',
    inputLocations: {
      'userId': 'path',
      'body': 'body',
    },
    inputCodec: t.exact(t.intersection([
      t.type({
        'userId': StringNumberCodec,
        'body': t.exact(t.intersection([t.type({
          'newEmail': t.string,
        }), t.partial({
          
        })]), 'body'),
      }),
      t.partial({
        
      })
    ]), 'POST /v1/admin/users/{userId}/updateEmail Input Codec'),
    responseCodec: t.void,
    responseCodecs: {
      200: undefined,
    },
    
    security: [{"MableOauth2":["admin"]}],
  },
  'PUT /v1/admin/users/{userId}/userLocations': {
    method: 'put',
    path: '/v1/admin/users/{userId}/userLocations',
    accept: 'undefined',
    inputLocations: {
      'userId': 'path',
      'body': 'body',
    },
    inputCodec: t.exact(t.intersection([
      t.type({
        'userId': StringNumberCodec,
        'body': t.exact(t.intersection([t.type({
          'buyerLocationIds': t.array(t.string),
        }), t.partial({
          
        })]), 'body'),
      }),
      t.partial({
        
      })
    ]), 'PUT /v1/admin/users/{userId}/userLocations Input Codec'),
    responseCodec: t.void,
    responseCodecs: {
      200: undefined,
    },
    
    security: [{"MableOauth2":["admin"]}],
  },
  'POST /v1/admin/users/{userId}/migrate': {
    method: 'post',
    path: '/v1/admin/users/{userId}/migrate',
    accept: 'undefined',
    inputLocations: {
      'userId': 'path',
      'sourceAccountId': 'query',
      'targetAccountId': 'query',
    },
    inputCodec: t.exact(t.intersection([
      t.type({
        'userId': StringNumberCodec,
        'sourceAccountId': StringNumberCodec,
        'targetAccountId': StringNumberCodec,
      }),
      t.partial({
        
      })
    ]), 'POST /v1/admin/users/{userId}/migrate Input Codec'),
    responseCodec: t.void,
    responseCodecs: {
      200: undefined,
    },
    
    security: [{"MableOauth2":["superadmin"]}],
  },
  'POST /v1/admin/sellers': {
    method: 'post',
    path: '/v1/admin/sellers',
    accept: 'application/json',
    inputLocations: {
      'sellerInput': 'body',
    },
    inputCodec: t.exact(t.intersection([
      t.type({
        'sellerInput': SellerInput,
      }),
      t.partial({
        
      })
    ]), 'POST /v1/admin/sellers Input Codec'),
    responseCodec: SellerAdminDisplayInfo,
    responseCodecs: {
      201: SellerAdminDisplayInfo,
    },
    
    security: [{"MableOauth2":["admin"]}],
  },
  'GET /v1/admin/sellers/{sellerId}/pricing/{buyerId}': {
    method: 'get',
    path: '/v1/admin/sellers/{sellerId}/pricing/{buyerId}',
    accept: 'application/json',
    inputLocations: {
      'sellerId': 'path',
      'buyerId': 'path',
    },
    inputCodec: t.exact(t.intersection([
      t.type({
        'sellerId': StringNumberCodec,
        'buyerId': StringNumberCodec,
      }),
      t.partial({
        
      })
    ]), 'GET /v1/admin/sellers/{sellerId}/pricing/{buyerId} Input Codec'),
    responseCodec: t.array(AdminPriceInfo),
    responseCodecs: {
      200: t.array(AdminPriceInfo),
    },
    
    security: [{"MableOauth2":["admin"]}],
  },
  'GET /v1/admin/sellers/{sellerId}/pricingtiers': {
    method: 'get',
    path: '/v1/admin/sellers/{sellerId}/pricingtiers',
    accept: 'application/json',
    inputLocations: {
      'sellerId': 'path',
    },
    inputCodec: t.exact(t.intersection([
      t.type({
        'sellerId': StringNumberCodec,
      }),
      t.partial({
        
      })
    ]), 'GET /v1/admin/sellers/{sellerId}/pricingtiers Input Codec'),
    responseCodec: t.exact(t.intersection([t.type({
      
    }), t.partial({
      'tiers': t.array(AdminSellerPricingTier),
    })])),
    responseCodecs: {
      200: t.exact(t.intersection([t.type({
        
      }), t.partial({
        'tiers': t.array(AdminSellerPricingTier),
      })])),
    },
    
    security: [{"MableOauth2":["admin"]}],
  },
  'POST /v1/admin/sellers/{sellerId}/pricingtiers': {
    method: 'post',
    path: '/v1/admin/sellers/{sellerId}/pricingtiers',
    accept: 'application/json',
    inputLocations: {
      'sellerId': 'path',
      'input': 'body',
    },
    inputCodec: t.exact(t.intersection([
      t.type({
        'sellerId': StringNumberCodec,
        'input': AdminPricingTierInput,
      }),
      t.partial({
        
      })
    ]), 'POST /v1/admin/sellers/{sellerId}/pricingtiers Input Codec'),
    responseCodec: t.exact(t.intersection([t.type({
      
    }), t.partial({
      'tier': AdminSellerPricingTier,
    })])),
    responseCodecs: {
      201: t.exact(t.intersection([t.type({
        
      }), t.partial({
        'tier': AdminSellerPricingTier,
      })])),
    },
    
    security: [{"MableOauth2":["admin"]}],
  },
  'PATCH /v1/admin/pricingtiers/{tierId}': {
    method: 'patch',
    path: '/v1/admin/pricingtiers/{tierId}',
    accept: 'application/json',
    inputLocations: {
      'tierId': 'path',
      'input': 'body',
    },
    inputCodec: t.exact(t.intersection([
      t.type({
        'tierId': StringNumberCodec,
        'input': AdminPricingTierInput,
      }),
      t.partial({
        
      })
    ]), 'PATCH /v1/admin/pricingtiers/{tierId} Input Codec'),
    responseCodec: t.exact(t.intersection([t.type({
      
    }), t.partial({
      'tier': AdminSellerPricingTier,
    })])),
    responseCodecs: {
      200: t.exact(t.intersection([t.type({
        
      }), t.partial({
        'tier': AdminSellerPricingTier,
      })])),
    },
    
    security: [{"MableOauth2":["admin"]}],
  },
  'DELETE /v1/admin/pricingtiers/{tierId}': {
    method: 'delete',
    path: '/v1/admin/pricingtiers/{tierId}',
    accept: 'undefined',
    inputLocations: {
      'tierId': 'path',
    },
    inputCodec: t.exact(t.intersection([
      t.type({
        'tierId': StringNumberCodec,
      }),
      t.partial({
        
      })
    ]), 'DELETE /v1/admin/pricingtiers/{tierId} Input Codec'),
    responseCodec: t.void,
    responseCodecs: {
      204: undefined,
    },
    
    security: [{"MableOauth2":["admin"]}],
  },
  'PATCH /v1/admin/pricingtiers/{tierId}/buyerRelationships': {
    method: 'patch',
    path: '/v1/admin/pricingtiers/{tierId}/buyerRelationships',
    accept: 'undefined',
    inputLocations: {
      'tierId': 'path',
      'body': 'body',
    },
    inputCodec: t.exact(t.intersection([
      t.type({
        'tierId': StringNumberCodec,
        'body': t.exact(t.intersection([t.type({
          
        }), t.partial({
          /** These buyer ids will be add to the pricing tier. */
          'addBuyerIds': t.array(t.number),
          /** These buyer ids will be removed from the pricing tier. */
          'removeBuyerIds': t.array(t.number),
        })]), 'body'),
      }),
      t.partial({
        
      })
    ]), 'PATCH /v1/admin/pricingtiers/{tierId}/buyerRelationships Input Codec'),
    responseCodec: t.void,
    responseCodecs: {
      200: undefined,
    },
    
    security: [{"MableOauth2":["admin"]}],
  },
  'PATCH /v1/admin/pricingeras/{eraId}': {
    method: 'patch',
    path: '/v1/admin/pricingeras/{eraId}',
    accept: 'undefined',
    inputLocations: {
      'eraId': 'path',
      'input': 'body',
    },
    inputCodec: t.exact(t.intersection([
      t.type({
        'eraId': NumberFromString,
        'input': AdminPricingEraPatch,
      }),
      t.partial({
        
      })
    ]), 'PATCH /v1/admin/pricingeras/{eraId} Input Codec'),
    responseCodec: t.void,
    responseCodecs: {
      200: undefined,
    },
    
    security: [{"MableOauth2":["admin"]}],
  },
  'GET /v1/admin/buyerPricingTiers': {
    method: 'get',
    path: '/v1/admin/buyerPricingTiers',
    accept: 'application/json',
    inputLocations: {
      'buyerId': 'query',
      'sellerId': 'query',
      'isLandedCosts': 'query',
    },
    inputCodec: t.exact(t.intersection([
      t.type({
        
      }),
      t.partial({
        'buyerId': StringNumberCodec,
        'sellerId': StringNumberCodec,
        'isLandedCosts': StringBooleanCodec,
      })
    ]), 'GET /v1/admin/buyerPricingTiers Input Codec'),
    responseCodec: t.exact(t.intersection([t.type({
      'buyerPricingTiers': t.array(AdminBuyerPricingTier),
    }), t.partial({
      
    })])),
    responseCodecs: {
      200: t.exact(t.intersection([t.type({
        'buyerPricingTiers': t.array(AdminBuyerPricingTier),
      }), t.partial({
        
      })])),
    },
    
    security: [{"MableOauth2":["admin"]}],
  },
  'POST /v1/admin/buyerPricingTiers': {
    method: 'post',
    path: '/v1/admin/buyerPricingTiers',
    accept: 'undefined',
    inputLocations: {
      'body': 'body',
    },
    inputCodec: t.exact(t.intersection([
      t.type({
        'body': t.exact(t.intersection([t.type({
          'buyerPricingTiers': t.array(AdminBuyerPricingTierInput),
        }), t.partial({
          
        })]), 'body'),
      }),
      t.partial({
        
      })
    ]), 'POST /v1/admin/buyerPricingTiers Input Codec'),
    responseCodec: t.void,
    responseCodecs: {
      201: undefined,
    },
    
    security: [{"MableOauth2":["admin"]}],
  },
  'DELETE /v1/admin/buyerPricingTiers': {
    method: 'delete',
    path: '/v1/admin/buyerPricingTiers',
    accept: 'undefined',
    inputLocations: {
      'body': 'body',
    },
    inputCodec: t.exact(t.intersection([
      t.type({
        'body': t.exact(t.intersection([t.type({
          'buyerPricingTierIds': t.array(t.number),
        }), t.partial({
          
        })]), 'body'),
      }),
      t.partial({
        
      })
    ]), 'DELETE /v1/admin/buyerPricingTiers Input Codec'),
    responseCodec: t.void,
    responseCodecs: {
      204: undefined,
    },
    
    security: [{"MableOauth2":["admin"]}],
  },
  'POST /v1/admin/sellers/{sellerId}/externalProductPurchaseData': {
    method: 'post',
    path: '/v1/admin/sellers/{sellerId}/externalProductPurchaseData',
    accept: 'application/json',
    inputLocations: {
      'sellerId': 'path',
      'input': 'body',
    },
    inputCodec: t.exact(t.intersection([
      t.type({
        'sellerId': StringNumberCodec,
        'input': ExternalProductPurchaseData,
      }),
      t.partial({
        
      })
    ]), 'POST /v1/admin/sellers/{sellerId}/externalProductPurchaseData Input Codec'),
    responseCodec: t.exact(t.intersection([t.type({
      'message': t.string,
    }), t.partial({
      
    })])),
    responseCodecs: {
      200: t.exact(t.intersection([t.type({
        'message': t.string,
      }), t.partial({
        
      })])),
    },
    
    security: [{"MableOauth2":["admin"]}],
  },
  'DELETE /v1/admin/sellers/{sellerId}/externalProductPurchaseData': {
    method: 'delete',
    path: '/v1/admin/sellers/{sellerId}/externalProductPurchaseData',
    accept: 'application/json',
    inputLocations: {
      'sellerId': 'path',
      'input': 'body',
    },
    inputCodec: t.exact(t.intersection([
      t.type({
        'sellerId': StringNumberCodec,
        'input': t.exact(t.intersection([t.type({
          
        }), t.partial({
          /** If specified, things are deleted just for this customer number */
          'externalCustomerNumber': t.string,
        })]), 'input'),
      }),
      t.partial({
        
      })
    ]), 'DELETE /v1/admin/sellers/{sellerId}/externalProductPurchaseData Input Codec'),
    responseCodec: t.exact(t.intersection([t.type({
      'message': t.string,
    }), t.partial({
      
    })])),
    responseCodecs: {
      200: t.exact(t.intersection([t.type({
        'message': t.string,
      }), t.partial({
        
      })])),
    },
    
    security: [{"MableOauth2":["admin"]}],
  },
  'GET /v1/admin/externalProductData': {
    method: 'get',
    path: '/v1/admin/externalProductData',
    accept: 'application/json',
    inputLocations: {
      'sellerId': 'query',
      'upcs': 'query',
      'ids': 'query',
    },
    inputCodec: t.exact(t.intersection([
      t.type({
        
      }),
      t.partial({
        /** Search by seller */
        'sellerId': StringNumberCodec,
        /** Search for matching UPCs */
        'upcs': StringArrayCodec(URLStringCodec),
        /** Search by database ID */
        'ids': StringArrayCodec(StringNumberCodec),
      })
    ]), 'GET /v1/admin/externalProductData Input Codec'),
    responseCodec: t.exact(t.intersection([t.type({
      'externalProducts': t.array(ExternalProduct),
    }), t.partial({
      
    })])),
    responseCodecs: {
      200: t.exact(t.intersection([t.type({
        'externalProducts': t.array(ExternalProduct),
      }), t.partial({
        
      })])),
    },
    
    security: [{"MableOauth2":["admin"]}],
  },
  'PUT /v1/admin/externalProductData': {
    method: 'put',
    path: '/v1/admin/externalProductData',
    accept: 'application/json',
    inputLocations: {
      'body': 'body',
    },
    inputCodec: t.exact(t.intersection([
      t.type({
        'body': t.exact(t.intersection([t.type({
          'externalProducts': t.array(ExternalProductInput),
        }), t.partial({
          
        })]), 'body'),
      }),
      t.partial({
        
      })
    ]), 'PUT /v1/admin/externalProductData Input Codec'),
    responseCodec: t.exact(t.intersection([t.type({
      'externalProducts': t.array(ExternalProduct),
    }), t.partial({
      
    })])),
    responseCodecs: {
      200: t.exact(t.intersection([t.type({
        'externalProducts': t.array(ExternalProduct),
      }), t.partial({
        
      })])),
    },
    
    security: [{"MableOauth2":["admin"]}],
  },
  'POST /v1/admin/sellers/{sellerId}/externalProductData/fetch': {
    method: 'post',
    path: '/v1/admin/sellers/{sellerId}/externalProductData/fetch',
    accept: 'application/json',
    inputLocations: {
      'sellerId': 'path',
      'input': 'body',
    },
    inputCodec: t.exact(t.intersection([
      t.type({
        'sellerId': StringNumberCodec,
        'input': t.exact(t.intersection([t.type({
          'source': ExternalProductSource,
        }), t.partial({
          
        })]), 'input'),
      }),
      t.partial({
        
      })
    ]), 'POST /v1/admin/sellers/{sellerId}/externalProductData/fetch Input Codec'),
    responseCodec: t.exact(t.intersection([t.type({
      'message': t.string,
    }), t.partial({
      
    })])),
    responseCodecs: {
      200: t.exact(t.intersection([t.type({
        'message': t.string,
      }), t.partial({
        
      })])),
    },
    
    security: [{"MableOauth2":["admin"]}],
  },
  'POST /v1/admin/sellers/{sellerId}/backfillProductRelationsFromExternalProductData': {
    method: 'post',
    path: '/v1/admin/sellers/{sellerId}/backfillProductRelationsFromExternalProductData',
    accept: 'application/json',
    inputLocations: {
      'sellerId': 'path',
    },
    inputCodec: t.exact(t.intersection([
      t.type({
        'sellerId': StringNumberCodec,
      }),
      t.partial({
        
      })
    ]), 'POST /v1/admin/sellers/{sellerId}/backfillProductRelationsFromExternalProductData Input Codec'),
    responseCodec: t.exact(t.intersection([t.type({
      'message': t.string,
    }), t.partial({
      
    })])),
    responseCodecs: {
      200: t.exact(t.intersection([t.type({
        'message': t.string,
      }), t.partial({
        
      })])),
    },
    
    security: [{"MableOauth2":["admin"]}],
  },
  'POST /v1/admin/sellers/{sellerId}/bulkImageUpload': {
    method: 'post',
    path: '/v1/admin/sellers/{sellerId}/bulkImageUpload',
    accept: 'undefined',
    inputLocations: {
      'sellerId': 'path',
      'body': 'body',
    },
    inputCodec: t.exact(t.intersection([
      t.type({
        'sellerId': StringNumberCodec,
        'body': t.exact(t.intersection([t.type({
          
        }), t.partial({
          'images': t.array(Image),
        })]), 'body'),
      }),
      t.partial({
        
      })
    ]), 'POST /v1/admin/sellers/{sellerId}/bulkImageUpload Input Codec'),
    responseCodec: t.void,
    responseCodecs: {
      200: undefined,
    },
    
    security: [{"MableOauth2":["admin"]}],
  },
  'GET /v1/admin/sellers/hubSpotCompanyInfo/{hubSpotCompanyId}': {
    method: 'get',
    path: '/v1/admin/sellers/hubSpotCompanyInfo/{hubSpotCompanyId}',
    accept: 'application/json',
    inputLocations: {
      'hubSpotCompanyId': 'path',
    },
    inputCodec: t.exact(t.intersection([
      t.type({
        'hubSpotCompanyId': StringNumberCodec,
      }),
      t.partial({
        
      })
    ]), 'GET /v1/admin/sellers/hubSpotCompanyInfo/{hubSpotCompanyId} Input Codec'),
    responseCodec: t.exact(t.intersection([t.type({
      'hubSpotCompanyInfo': HubSpotCompanyInfo,
    }), t.partial({
      
    })])),
    responseCodecs: {
      200: t.exact(t.intersection([t.type({
        'hubSpotCompanyInfo': HubSpotCompanyInfo,
      }), t.partial({
        
      })])),
    },
    
    security: [{"MableOauth2":["admin"]}],
  },
  'PATCH /v1/admin/sellers/{sellerId}/categories': {
    method: 'patch',
    path: '/v1/admin/sellers/{sellerId}/categories',
    accept: 'undefined',
    inputLocations: {
      'sellerId': 'path',
      'categoryPatch': 'body',
    },
    inputCodec: t.exact(t.intersection([
      t.type({
        'sellerId': StringNumberCodec,
        'categoryPatch': CategoryMembershipPatch,
      }),
      t.partial({
        
      })
    ]), 'PATCH /v1/admin/sellers/{sellerId}/categories Input Codec'),
    responseCodec: t.void,
    responseCodecs: {
      204: undefined,
    },
    
    security: [{"MableOauth2":["admin","dataworker"]}],
  },
  'POST /v1/admin/sellers/{sellerId}/updateBasicHubSpotProperties': {
    method: 'post',
    path: '/v1/admin/sellers/{sellerId}/updateBasicHubSpotProperties',
    accept: 'undefined',
    inputLocations: {
      'sellerId': 'path',
    },
    inputCodec: t.exact(t.intersection([
      t.type({
        'sellerId': StringNumberCodec,
      }),
      t.partial({
        
      })
    ]), 'POST /v1/admin/sellers/{sellerId}/updateBasicHubSpotProperties Input Codec'),
    responseCodec: t.void,
    responseCodecs: {
      204: undefined,
    },
    
    security: [{"MableOauth2":["admin"]}],
  },
  'GET /v1/admin/sellers/{sellerId}/partnerProgramMemberships': {
    method: 'get',
    path: '/v1/admin/sellers/{sellerId}/partnerProgramMemberships',
    accept: 'application/json',
    inputLocations: {
      'sellerId': 'path',
    },
    inputCodec: t.exact(t.intersection([
      t.type({
        'sellerId': StringNumberCodec,
      }),
      t.partial({
        
      })
    ]), 'GET /v1/admin/sellers/{sellerId}/partnerProgramMemberships Input Codec'),
    responseCodec: t.exact(t.intersection([t.type({
      'programs': t.array(PartnerProgramMembership),
    }), t.partial({
      
    })])),
    responseCodecs: {
      200: t.exact(t.intersection([t.type({
        'programs': t.array(PartnerProgramMembership),
      }), t.partial({
        
      })])),
    },
    
    security: [{"MableOauth2":["admin"]}],
  },
  'DELETE /v1/admin/sellers/{sellerId}/partnerProgramMemberships/{partnerAccountId}': {
    method: 'delete',
    path: '/v1/admin/sellers/{sellerId}/partnerProgramMemberships/{partnerAccountId}',
    accept: 'undefined',
    inputLocations: {
      'sellerId': 'path',
      'partnerAccountId': 'path',
    },
    inputCodec: t.exact(t.intersection([
      t.type({
        'sellerId': StringNumberCodec,
        'partnerAccountId': StringNumberCodec,
      }),
      t.partial({
        
      })
    ]), 'DELETE /v1/admin/sellers/{sellerId}/partnerProgramMemberships/{partnerAccountId} Input Codec'),
    responseCodec: t.void,
    responseCodecs: {
      204: undefined,
    },
    
    security: [{"MableOauth2":["admin"]}],
  },
  'PUT /v1/admin/sellers/{sellerId}/partnerProgramMemberships/{partnerAccountId}': {
    method: 'put',
    path: '/v1/admin/sellers/{sellerId}/partnerProgramMemberships/{partnerAccountId}',
    accept: 'undefined',
    inputLocations: {
      'sellerId': 'path',
      'partnerAccountId': 'path',
      'input': 'body',
    },
    inputCodec: t.exact(t.intersection([
      t.type({
        'sellerId': StringNumberCodec,
        'partnerAccountId': StringNumberCodec,
        'input': t.exact(t.intersection([t.type({
          'status': PartnerProgramMembershipStatus,
        }), t.partial({
          'stuck': t.boolean,
        })]), 'input'),
      }),
      t.partial({
        
      })
    ]), 'PUT /v1/admin/sellers/{sellerId}/partnerProgramMemberships/{partnerAccountId} Input Codec'),
    responseCodec: t.void,
    responseCodecs: {
      204: undefined,
    },
    
    security: [{"MableOauth2":["admin"]}],
  },
  'DELETE /v1/admin/buyers/{buyerId}/partnerProgramMemberships/{partnerAccountName}': {
    method: 'delete',
    path: '/v1/admin/buyers/{buyerId}/partnerProgramMemberships/{partnerAccountName}',
    accept: 'undefined',
    inputLocations: {
      'buyerId': 'path',
      'partnerAccountName': 'path',
    },
    inputCodec: t.exact(t.intersection([
      t.type({
        'buyerId': StringNumberCodec,
        'partnerAccountName': URLStringCodec,
      }),
      t.partial({
        
      })
    ]), 'DELETE /v1/admin/buyers/{buyerId}/partnerProgramMemberships/{partnerAccountName} Input Codec'),
    responseCodec: t.void,
    responseCodecs: {
      204: undefined,
    },
    
    security: [{"MableOauth2":["admin"]}],
  },
  'PUT /v1/admin/buyers/{buyerId}/partnerProgramMemberships/{partnerAccountName}': {
    method: 'put',
    path: '/v1/admin/buyers/{buyerId}/partnerProgramMemberships/{partnerAccountName}',
    accept: 'undefined',
    inputLocations: {
      'buyerId': 'path',
      'partnerAccountName': 'path',
      'body': 'body',
    },
    inputCodec: t.exact(t.intersection([
      t.type({
        'buyerId': StringNumberCodec,
        'partnerAccountName': URLStringCodec,
      }),
      t.partial({
        'body': t.exact(t.intersection([t.type({
          
        }), t.partial({
          'pricingRegionId': t.string,
          'paymentTermsNetDays': t.number,
        })]), 'body'),
      })
    ]), 'PUT /v1/admin/buyers/{buyerId}/partnerProgramMemberships/{partnerAccountName} Input Codec'),
    responseCodec: t.void,
    responseCodecs: {
      204: undefined,
    },
    
    security: [{"MableOauth2":["admin"]}],
  },
  'PATCH /v1/admin/buyers/{buyerId}': {
    method: 'patch',
    path: '/v1/admin/buyers/{buyerId}',
    accept: 'application/json',
    inputLocations: {
      'buyerId': 'path',
      'input': 'body',
      'dontSendVerificationEmail': 'query',
    },
    inputCodec: t.exact(t.intersection([
      t.type({
        'buyerId': StringNumberCodec,
        'input': AdminBuyerPatch,
      }),
      t.partial({
        /** Whether to not send the buyer an email if this patch is going to change them from unverified to verified */
        'dontSendVerificationEmail': StringBooleanCodec,
      })
    ]), 'PATCH /v1/admin/buyers/{buyerId} Input Codec'),
    responseCodec: t.exact(t.intersection([t.type({
      'message': t.string,
    }), t.partial({
      
    })])),
    responseCodecs: {
      200: t.exact(t.intersection([t.type({
        'message': t.string,
      }), t.partial({
        
      })])),
    },
    
    security: [{"MableOauth2":["admin"]}],
  },
  'GET /v1/admin/buyers/{buyerId}/balances': {
    method: 'get',
    path: '/v1/admin/buyers/{buyerId}/balances',
    accept: 'application/json',
    inputLocations: {
      'buyerId': 'path',
    },
    inputCodec: t.exact(t.intersection([
      t.type({
        'buyerId': StringNumberCodec,
      }),
      t.partial({
        
      })
    ]), 'GET /v1/admin/buyers/{buyerId}/balances Input Codec'),
    responseCodec: t.exact(t.intersection([t.type({
      'balances': AccountBalances,
      'totalSpend': NumberFromString,
    }), t.partial({
      
    })])),
    responseCodecs: {
      200: t.exact(t.intersection([t.type({
        'balances': AccountBalances,
        'totalSpend': NumberFromString,
      }), t.partial({
        
      })])),
    },
    
    security: [{"MableOauth2":["admin"]}],
  },
  'GET /v1/admin/buyers/{buyerId}/landedCostReport': {
    method: 'get',
    path: '/v1/admin/buyers/{buyerId}/landedCostReport',
    accept: 'application/json',
    inputLocations: {
      'buyerId': 'path',
      'startAt': 'query',
      'endAt': 'query',
    },
    inputCodec: t.exact(t.intersection([
      t.type({
        'buyerId': StringNumberCodec,
        'startAt': DateWithoutTimeFromISOString,
        'endAt': DateWithoutTimeFromISOString,
      }),
      t.partial({
        
      })
    ]), 'GET /v1/admin/buyers/{buyerId}/landedCostReport Input Codec'),
    responseCodec: t.exact(t.intersection([t.type({
      'csv': t.string,
    }), t.partial({
      
    })])),
    responseCodecs: {
      200: t.exact(t.intersection([t.type({
        'csv': t.string,
      }), t.partial({
        
      })])),
    },
    
    security: [{"MableOauth2":["admin"]}],
  },
  'POST /v1/admin/buyers/{buyerId}/keheCustomerData': {
    method: 'post',
    path: '/v1/admin/buyers/{buyerId}/keheCustomerData',
    accept: 'undefined',
    inputLocations: {
      'buyerId': 'path',
      'body': 'body',
    },
    inputCodec: t.exact(t.intersection([
      t.type({
        'buyerId': StringNumberCodec,
        'body': t.exact(t.intersection([t.type({
          /** String of CSV file with KeHE buyer location & retailer information */
          'csv': t.string,
          /** The identifier of the partner program. Should be 'kehe' unless you're doing something interesting. */
          'partnerAccountName': t.string,
        }), t.partial({
          
        })]), 'body'),
      }),
      t.partial({
        
      })
    ]), 'POST /v1/admin/buyers/{buyerId}/keheCustomerData Input Codec'),
    responseCodec: t.void,
    responseCodecs: {
      204: undefined,
    },
    
    security: [{"MableOauth2":["admin"]}],
  },
  'POST /v1/admin/categories/{categoryId}/futureAPLCostsReport': {
    method: 'post',
    path: '/v1/admin/categories/{categoryId}/futureAPLCostsReport',
    accept: 'application/json',
    inputLocations: {
      'categoryId': 'path',
      'accountId': 'query',
      'asOf': 'query',
    },
    inputCodec: t.exact(t.intersection([
      t.type({
        'categoryId': StringNumberCodec,
        'accountId': StringNumberCodec,
        'asOf': DateWithoutTimeFromISOString,
      }),
      t.partial({
        
      })
    ]), 'POST /v1/admin/categories/{categoryId}/futureAPLCostsReport Input Codec'),
    responseCodec: t.exact(t.intersection([t.type({
      'csv': t.string,
      'fileName': t.string,
    }), t.partial({
      
    })])),
    responseCodecs: {
      200: t.exact(t.intersection([t.type({
        'csv': t.string,
        'fileName': t.string,
      }), t.partial({
        
      })])),
    },
    
    security: [{"MableOauth2":["admin"]}],
  },
  'GET /v1/admin/dashboardCards': {
    method: 'get',
    path: '/v1/admin/dashboardCards',
    accept: 'application/json',
    inputLocations: {
      'page': 'query',
      'pageSize': 'query',
    },
    inputCodec: t.exact(t.intersection([
      t.type({
        
      }),
      t.partial({
        /** Page */
        'page': StringNumberCodec,
        /** Number of results per page */
        'pageSize': StringNumberCodec,
      })
    ]), 'GET /v1/admin/dashboardCards Input Codec'),
    responseCodec: t.array(DashboardCard),
    responseCodecs: {
      200: t.array(DashboardCard),
    },
    
    security: [{"MableOauth2":["admin"]}],
  },
  'POST /v1/admin/dashboardCards': {
    method: 'post',
    path: '/v1/admin/dashboardCards',
    accept: 'application/json',
    inputLocations: {
      'dashboardCardInput': 'body',
    },
    inputCodec: t.exact(t.intersection([
      t.type({
        'dashboardCardInput': DashboardCard,
      }),
      t.partial({
        
      })
    ]), 'POST /v1/admin/dashboardCards Input Codec'),
    responseCodec: t.exact(t.intersection([t.type({
      'id': t.number,
    }), t.partial({
      
    })])),
    responseCodecs: {
      200: t.exact(t.intersection([t.type({
        'id': t.number,
      }), t.partial({
        
      })])),
    },
    
    security: [{"MableOauth2":["admin"]}],
  },
  'GET /v1/admin/dashboardCards/{id}': {
    method: 'get',
    path: '/v1/admin/dashboardCards/{id}',
    accept: 'application/json',
    inputLocations: {
      'id': 'path',
    },
    inputCodec: t.exact(t.intersection([
      t.type({
        'id': StringNumberCodec,
      }),
      t.partial({
        
      })
    ]), 'GET /v1/admin/dashboardCards/{id} Input Codec'),
    responseCodec: DashboardCard,
    responseCodecs: {
      200: DashboardCard,
    },
    
    security: [{"MableOauth2":["admin"]}],
  },
  'PUT /v1/admin/dashboardCards/{id}': {
    method: 'put',
    path: '/v1/admin/dashboardCards/{id}',
    accept: 'application/json',
    inputLocations: {
      'id': 'path',
      'dashboardCardInput': 'body',
    },
    inputCodec: t.exact(t.intersection([
      t.type({
        'id': StringNumberCodec,
        'dashboardCardInput': DashboardCard,
      }),
      t.partial({
        
      })
    ]), 'PUT /v1/admin/dashboardCards/{id} Input Codec'),
    responseCodec: DashboardCard,
    responseCodecs: {
      200: DashboardCard,
    },
    
    security: [{"MableOauth2":["admin"]}],
  },
  'DELETE /v1/admin/dashboardCards/{id}': {
    method: 'delete',
    path: '/v1/admin/dashboardCards/{id}',
    accept: 'undefined',
    inputLocations: {
      'id': 'path',
    },
    inputCodec: t.exact(t.intersection([
      t.type({
        'id': StringNumberCodec,
      }),
      t.partial({
        
      })
    ]), 'DELETE /v1/admin/dashboardCards/{id} Input Codec'),
    responseCodec: t.void,
    responseCodecs: {
      204: undefined,
    },
    
    security: [{"MableOauth2":["admin"]}],
  },
  'GET /v1/admin/dashboardProfiles': {
    method: 'get',
    path: '/v1/admin/dashboardProfiles',
    accept: 'application/json',
    inputLocations: {
      'page': 'query',
      'pageSize': 'query',
    },
    inputCodec: t.exact(t.intersection([
      t.type({
        
      }),
      t.partial({
        /** Page */
        'page': StringNumberCodec,
        /** Number of results per page */
        'pageSize': StringNumberCodec,
      })
    ]), 'GET /v1/admin/dashboardProfiles Input Codec'),
    responseCodec: t.array(DashboardProfile),
    responseCodecs: {
      200: t.array(DashboardProfile),
    },
    
    security: [{"MableOauth2":["admin"]}],
  },
  'POST /v1/admin/dashboardProfiles': {
    method: 'post',
    path: '/v1/admin/dashboardProfiles',
    accept: 'application/json',
    inputLocations: {
      'dashboardProfileInput': 'body',
    },
    inputCodec: t.exact(t.intersection([
      t.type({
        'dashboardProfileInput': DashboardProfile,
      }),
      t.partial({
        
      })
    ]), 'POST /v1/admin/dashboardProfiles Input Codec'),
    responseCodec: t.exact(t.intersection([t.type({
      'id': t.number,
    }), t.partial({
      
    })])),
    responseCodecs: {
      200: t.exact(t.intersection([t.type({
        'id': t.number,
      }), t.partial({
        
      })])),
    },
    
    security: [{"MableOauth2":["admin"]}],
  },
  'GET /v1/admin/dashboardProfiles/{id}': {
    method: 'get',
    path: '/v1/admin/dashboardProfiles/{id}',
    accept: 'application/json',
    inputLocations: {
      'id': 'path',
    },
    inputCodec: t.exact(t.intersection([
      t.type({
        'id': StringNumberCodec,
      }),
      t.partial({
        
      })
    ]), 'GET /v1/admin/dashboardProfiles/{id} Input Codec'),
    responseCodec: DashboardProfile,
    responseCodecs: {
      200: DashboardProfile,
    },
    
    security: [{"MableOauth2":["admin"]}],
  },
  'PUT /v1/admin/dashboardProfiles/{id}': {
    method: 'put',
    path: '/v1/admin/dashboardProfiles/{id}',
    accept: 'application/json',
    inputLocations: {
      'id': 'path',
      'dashboardProfileInput': 'body',
    },
    inputCodec: t.exact(t.intersection([
      t.type({
        'id': StringNumberCodec,
        'dashboardProfileInput': DashboardProfile,
      }),
      t.partial({
        
      })
    ]), 'PUT /v1/admin/dashboardProfiles/{id} Input Codec'),
    responseCodec: DashboardProfile,
    responseCodecs: {
      200: DashboardProfile,
    },
    
    security: [{"MableOauth2":["admin"]}],
  },
  'DELETE /v1/admin/dashboardProfiles/{id}': {
    method: 'delete',
    path: '/v1/admin/dashboardProfiles/{id}',
    accept: 'undefined',
    inputLocations: {
      'id': 'path',
    },
    inputCodec: t.exact(t.intersection([
      t.type({
        'id': StringNumberCodec,
      }),
      t.partial({
        
      })
    ]), 'DELETE /v1/admin/dashboardProfiles/{id} Input Codec'),
    responseCodec: t.void,
    responseCodecs: {
      204: undefined,
    },
    
    security: [{"MableOauth2":["admin"]}],
  },
  'GET /v1/sampleRequests': {
    method: 'get',
    path: '/v1/sampleRequests',
    accept: 'application/json',
    inputLocations: {
      'perspective': 'query',
      'page': 'query',
      'pageSize': 'query',
      'statuses': 'query',
    },
    inputCodec: t.exact(t.intersection([
      t.type({
        
      }),
      t.partial({
        /** Whether to show requests from buyer or seller perspective */
        'perspective': CodegenEnumClosed({
          'buyer': null,
          'seller': null,
        }, 'perspective'),
        /** Page */
        'page': StringNumberCodec,
        /** Number of results per page */
        'pageSize': StringNumberCodec,
        /** Used to filter by statuses. */
        'statuses': StringArrayCodec(TransactionStatus),
      })
    ]), 'GET /v1/sampleRequests Input Codec'),
    responseCodec: t.exact(t.intersection([t.type({
      
    }), t.partial({
      'sampleRequests': t.array(DisplaySampleRequest),
      'code': CodegenEnumClosed({
        'SampleRequestRequiresOrder': null,
      }, 'Enum'),
      'totalCount': t.number,
    })])),
    responseCodecs: {
      200: t.exact(t.intersection([t.type({
        
      }), t.partial({
        'sampleRequests': t.array(DisplaySampleRequest),
        'code': CodegenEnumClosed({
          'SampleRequestRequiresOrder': null,
        }, 'Enum'),
        'totalCount': t.number,
      })])),
    },
    
    security: [{"MableOauth2":["admin","buyer","seller"]}],
  },
  'POST /v1/sampleRequests': {
    method: 'post',
    path: '/v1/sampleRequests',
    accept: 'application/json',
    inputLocations: {
      'sampleRequestInput': 'body',
    },
    inputCodec: t.exact(t.intersection([
      t.type({
        'sampleRequestInput': BuyerSampleRequestInput,
      }),
      t.partial({
        
      })
    ]), 'POST /v1/sampleRequests Input Codec'),
    responseCodec: t.exact(t.intersection([t.type({
      
    }), t.partial({
      'id': t.number,
    })])),
    responseCodecs: {
      200: t.exact(t.intersection([t.type({
        
      }), t.partial({
        'id': t.number,
      })])),
    },
    
    security: [{"MableOauth2":["buyer"]}],
  },
  'GET /v1/sampleRequestsEligibility': {
    method: 'get',
    path: '/v1/sampleRequestsEligibility',
    accept: 'application/json',
    inputLocations: {
      'sellerId': 'query',
    },
    inputCodec: t.exact(t.intersection([
      t.type({
        'sellerId': StringNumberCodec,
      }),
      t.partial({
        
      })
    ]), 'GET /v1/sampleRequestsEligibility Input Codec'),
    responseCodec: SampleRequestEligibility,
    responseCodecs: {
      200: SampleRequestEligibility,
    },
    
    security: [{"MableOauth2":["buyer"]}],
  },
  'GET /v1/sampleRequests/{sampleRequestId}': {
    method: 'get',
    path: '/v1/sampleRequests/{sampleRequestId}',
    accept: 'application/json',
    inputLocations: {
      'sampleRequestId': 'path',
      'perspective': 'query',
    },
    inputCodec: t.exact(t.intersection([
      t.type({
        'sampleRequestId': StringNumberCodec,
        /** Whether to request an individual ID for your seller or buyer */
        'perspective': CodegenEnumClosed({
          'buyer': null,
          'seller': null,
        }, 'perspective'),
      }),
      t.partial({
        
      })
    ]), 'GET /v1/sampleRequests/{sampleRequestId} Input Codec'),
    responseCodec: DisplaySampleRequest,
    responseCodecs: {
      200: DisplaySampleRequest,
    },
    
    security: [{"MableOauth2":["admin","buyer","seller"]}],
  },
  'PUT /v1/sampleRequests/{id}/issue': {
    method: 'put',
    path: '/v1/sampleRequests/{id}/issue',
    accept: 'application/json',
    inputLocations: {
      'id': 'path',
    },
    inputCodec: t.exact(t.intersection([
      t.type({
        'id': StringNumberCodec,
      }),
      t.partial({
        
      })
    ]), 'PUT /v1/sampleRequests/{id}/issue Input Codec'),
    responseCodec: DisplaySampleRequest,
    responseCodecs: {
      200: DisplaySampleRequest,
    },
    
    security: [{"MableOauth2":["buyer"]}],
  },
  'POST /v1/admin/sampleRequests/{sampleRequestId}/issue': {
    method: 'post',
    path: '/v1/admin/sampleRequests/{sampleRequestId}/issue',
    accept: 'application/json',
    inputLocations: {
      'sampleRequestId': 'path',
    },
    inputCodec: t.exact(t.intersection([
      t.type({
        'sampleRequestId': StringNumberCodec,
      }),
      t.partial({
        
      })
    ]), 'POST /v1/admin/sampleRequests/{sampleRequestId}/issue Input Codec'),
    responseCodec: SampleRequest,
    responseCodecs: {
      200: SampleRequest,
    },
    
    security: [{"MableOauth2":["admin"]}],
  },
  'POST /v1/sampleRequests/{sampleRequestId}/accept': {
    method: 'post',
    path: '/v1/sampleRequests/{sampleRequestId}/accept',
    accept: 'application/json',
    inputLocations: {
      'sampleRequestId': 'path',
    },
    inputCodec: t.exact(t.intersection([
      t.type({
        'sampleRequestId': StringNumberCodec,
      }),
      t.partial({
        
      })
    ]), 'POST /v1/sampleRequests/{sampleRequestId}/accept Input Codec'),
    responseCodec: DisplaySampleRequest,
    responseCodecs: {
      200: DisplaySampleRequest,
    },
    
    security: [{"MableOauth2":["admin","seller"]}],
  },
  'POST /v1/sampleRequests/{sampleRequestId}/feedback': {
    method: 'post',
    path: '/v1/sampleRequests/{sampleRequestId}/feedback',
    accept: 'application/json',
    inputLocations: {
      'sampleRequestId': 'path',
      'feedbackInput': 'body',
    },
    inputCodec: t.exact(t.intersection([
      t.type({
        'sampleRequestId': StringNumberCodec,
        'feedbackInput': t.record(t.string, t.unknown),
      }),
      t.partial({
        
      })
    ]), 'POST /v1/sampleRequests/{sampleRequestId}/feedback Input Codec'),
    responseCodec: DisplaySampleRequest,
    responseCodecs: {
      200: DisplaySampleRequest,
    },
    
    security: [{"MableOauth2":["buyer"]}],
  },
  'POST /v1/sampleRequests/{sampleRequestId}/cancel': {
    method: 'post',
    path: '/v1/sampleRequests/{sampleRequestId}/cancel',
    accept: 'application/json',
    inputLocations: {
      'sampleRequestId': 'path',
      'cancellationInput': 'body',
    },
    inputCodec: t.exact(t.intersection([
      t.type({
        'sampleRequestId': StringNumberCodec,
        'cancellationInput': t.exact(t.intersection([t.type({
          'reason': SampleCancellationReason,
        }), t.partial({
          /** Free user input for cancellation comments. Not shared with brand. */
          'comment': t.string,
        })]), 'cancellationInput'),
      }),
      t.partial({
        
      })
    ]), 'POST /v1/sampleRequests/{sampleRequestId}/cancel Input Codec'),
    responseCodec: DisplaySampleRequest,
    responseCodecs: {
      200: DisplaySampleRequest,
    },
    
    security: [{"MableOauth2":["admin","seller"]}],
  },
  'GET /v1/admin/sampleRequests': {
    method: 'get',
    path: '/v1/admin/sampleRequests',
    accept: 'application/json',
    inputLocations: {
      'statuses': 'query',
      'page': 'query',
      'pageSize': 'query',
      'sellerId': 'query',
      'buyerId': 'query',
      'id': 'query',
      'requiresManualIntervention': 'query',
    },
    inputCodec: t.exact(t.intersection([
      t.type({
        
      }),
      t.partial({
        /** Used to filter by statuses. */
        'statuses': StringArrayCodec(TransactionStatus),
        /** Page */
        'page': StringNumberCodec,
        /** Number of results per page */
        'pageSize': StringNumberCodec,
        /** seller id */
        'sellerId': StringNumberCodec,
        /** buyer id */
        'buyerId': StringNumberCodec,
        /** id */
        'id': StringNumberCodec,
        /** flag if sample request needs manual intervention */
        'requiresManualIntervention': StringBooleanCodec,
      })
    ]), 'GET /v1/admin/sampleRequests Input Codec'),
    responseCodec: t.exact(t.intersection([t.type({
      
    }), t.partial({
      'sampleRequests': t.array(AdminSampleRequest),
      'totalCount': t.number,
    })])),
    responseCodecs: {
      200: t.exact(t.intersection([t.type({
        
      }), t.partial({
        'sampleRequests': t.array(AdminSampleRequest),
        'totalCount': t.number,
      })])),
    },
    
    security: [{"MableOauth2":["admin"]}],
  },
  'PUT /v1/admin/sampleRequests/{id}': {
    method: 'put',
    path: '/v1/admin/sampleRequests/{id}',
    accept: 'application/json',
    inputLocations: {
      'id': 'path',
      'sampleRequestInput': 'body',
    },
    inputCodec: t.exact(t.intersection([
      t.type({
        'id': StringNumberCodec,
        'sampleRequestInput': t.exact(t.intersection([t.type({
          
        }), t.partial({
          'noteObject': BuyerNote,
          'feedback': t.record(t.string, t.unknown),
        })]), 'sampleRequestInput'),
      }),
      t.partial({
        
      })
    ]), 'PUT /v1/admin/sampleRequests/{id} Input Codec'),
    responseCodec: SampleRequest,
    responseCodecs: {
      200: SampleRequest,
    },
    
    security: [{"MableOauth2":["admin"]}],
  },
  'POST /v1/admin/sampleRequests/{sampleRequestId}/accept': {
    method: 'post',
    path: '/v1/admin/sampleRequests/{sampleRequestId}/accept',
    accept: 'application/json',
    inputLocations: {
      'sampleRequestId': 'path',
    },
    inputCodec: t.exact(t.intersection([
      t.type({
        'sampleRequestId': StringNumberCodec,
      }),
      t.partial({
        
      })
    ]), 'POST /v1/admin/sampleRequests/{sampleRequestId}/accept Input Codec'),
    responseCodec: SampleRequest,
    responseCodecs: {
      200: SampleRequest,
    },
    
    security: [{"MableOauth2":["admin"]}],
  },
  'POST /v1/admin/sampleRequests/{sampleRequestId}/cancel': {
    method: 'post',
    path: '/v1/admin/sampleRequests/{sampleRequestId}/cancel',
    accept: 'application/json',
    inputLocations: {
      'sampleRequestId': 'path',
      'cancellationInput': 'body',
    },
    inputCodec: t.exact(t.intersection([
      t.type({
        'sampleRequestId': StringNumberCodec,
        'cancellationInput': t.exact(t.intersection([t.type({
          /** A reason for cancellation. */
          'reason': t.string,
        }), t.partial({
          
        })]), 'cancellationInput'),
      }),
      t.partial({
        
      })
    ]), 'POST /v1/admin/sampleRequests/{sampleRequestId}/cancel Input Codec'),
    responseCodec: SampleRequest,
    responseCodecs: {
      200: SampleRequest,
    },
    
    security: [{"MableOauth2":["admin"]}],
  },
  'POST /v1/admin/sampleRequests/{sampleRequestId}/uncancel': {
    method: 'post',
    path: '/v1/admin/sampleRequests/{sampleRequestId}/uncancel',
    accept: 'application/json',
    inputLocations: {
      'sampleRequestId': 'path',
      'input': 'body',
    },
    inputCodec: t.exact(t.intersection([
      t.type({
        'sampleRequestId': StringNumberCodec,
        'input': t.exact(t.intersection([t.type({
          'reason': t.string,
        }), t.partial({
          
        })]), 'input'),
      }),
      t.partial({
        
      })
    ]), 'POST /v1/admin/sampleRequests/{sampleRequestId}/uncancel Input Codec'),
    responseCodec: SampleRequest,
    responseCodecs: {
      200: SampleRequest,
    },
    
    security: [{"MableOauth2":["admin"]}],
  },
  'POST /v1/admin/sampleRequests/{sampleRequestId}/resolveIssue': {
    method: 'post',
    path: '/v1/admin/sampleRequests/{sampleRequestId}/resolveIssue',
    accept: 'application/json',
    inputLocations: {
      'sampleRequestId': 'path',
    },
    inputCodec: t.exact(t.intersection([
      t.type({
        'sampleRequestId': StringNumberCodec,
      }),
      t.partial({
        
      })
    ]), 'POST /v1/admin/sampleRequests/{sampleRequestId}/resolveIssue Input Codec'),
    responseCodec: SampleRequest,
    responseCodecs: {
      200: SampleRequest,
    },
    
    security: [{"MableOauth2":["admin"]}],
  },
  'GET /v1/sellers': {
    method: 'get',
    path: '/v1/sellers',
    accept: 'application/json',
    inputLocations: {
      'q': 'query',
      'status': 'query',
      'id': 'query',
      'ignoreWhitelist': 'query',
      'page': 'query',
      'pageSize': 'query',
      'categoryIds': 'query',
    },
    inputCodec: t.exact(t.intersection([
      t.type({
        
      }),
      t.partial({
        'q': URLStringCodec,
        /** Seller statuses to return. Dataworkers only. Defaults to available. */
        'status': StringArrayCodec(CodegenEnumClosed({
          'draft': null,
          'available': null,
          'archived': null,
          'deleted': null,
        }, 'statusItem')),
        /** Specify a list of sellers by ID */
        'id': StringArrayCodec(StringNumberCodec),
        /** If true, search doesn't enforce the whitelist. Dataworkers only. */
        'ignoreWhitelist': StringBooleanCodec,
        /** Page */
        'page': StringNumberCodec,
        /** Number of results per page */
        'pageSize': StringNumberCodec,
        /** If specified, return all the sellers associated with the listed categoryIds */
        'categoryIds': StringArrayCodec(StringNumberCodec),
      })
    ]), 'GET /v1/sellers Input Codec'),
    responseCodec: Sellers,
    responseCodecs: {
      200: Sellers,
    },
    
    security: [{"MableOauth2":["admin","buyer","seller","dataworker"]},{"NoAuth":[]}],
  },
  'GET /v1/admin/sellers/{sellerIdOrSlug}': {
    method: 'get',
    path: '/v1/admin/sellers/{sellerIdOrSlug}',
    accept: 'application/json',
    inputLocations: {
      'sellerIdOrSlug': 'path',
    },
    inputCodec: t.exact(t.intersection([
      t.type({
        'sellerIdOrSlug': URLStringCodec,
      }),
      t.partial({
        
      })
    ]), 'GET /v1/admin/sellers/{sellerIdOrSlug} Input Codec'),
    responseCodec: SellerAdminDisplayInfo,
    responseCodecs: {
      200: SellerAdminDisplayInfo,
    },
    
    security: [{"MableOauth2":["admin","dataworker"]}],
  },
  'PUT /v1/admin/sellers/{sellerIdOrSlug}': {
    method: 'put',
    path: '/v1/admin/sellers/{sellerIdOrSlug}',
    accept: 'undefined',
    inputLocations: {
      'sellerIdOrSlug': 'path',
      'sellerInput': 'body',
    },
    inputCodec: t.exact(t.intersection([
      t.type({
        'sellerIdOrSlug': URLStringCodec,
        'sellerInput': SellerInput,
      }),
      t.partial({
        
      })
    ]), 'PUT /v1/admin/sellers/{sellerIdOrSlug} Input Codec'),
    responseCodec: t.void,
    responseCodecs: {
      201: undefined,
    },
    
    security: [{"MableOauth2":["admin"]}],
  },
  'GET /v1/sellers/{sellerIdOrSlug}': {
    method: 'get',
    path: '/v1/sellers/{sellerIdOrSlug}',
    accept: 'application/json',
    inputLocations: {
      'sellerIdOrSlug': 'path',
      'previewKey': 'query',
    },
    inputCodec: t.exact(t.intersection([
      t.type({
        'sellerIdOrSlug': URLStringCodec,
      }),
      t.partial({
        /** A Magic Link type key to allow viewing price information even when logged out. */
        'previewKey': URLStringCodec,
      })
    ]), 'GET /v1/sellers/{sellerIdOrSlug} Input Codec'),
    responseCodec: SellerDisplayInfo,
    responseCodecs: {
      200: SellerDisplayInfo,
    },
    
    security: [{"MableOauth2":["admin","buyer","seller","dataworker"]},{"NoAuth":[]}],
  },
  'PATCH /v1/sellers/{sellerIdOrSlug}': {
    method: 'patch',
    path: '/v1/sellers/{sellerIdOrSlug}',
    accept: 'undefined',
    inputLocations: {
      'sellerIdOrSlug': 'path',
      'sellerPatch': 'body',
      'confirmationCodes': 'query',
    },
    inputCodec: t.exact(t.intersection([
      t.type({
        'sellerIdOrSlug': URLStringCodec,
        'sellerPatch': SellerPatch,
      }),
      t.partial({
        /** If a patch will cause some destructive behavior it will instead throw an error containing a specific confirmation code the caller needs to put here to proceed with the patch (and destruction). */
        'confirmationCodes': StringArrayCodec(SellerUpdateConfirmationCode),
      })
    ]), 'PATCH /v1/sellers/{sellerIdOrSlug} Input Codec'),
    responseCodec: t.void,
    responseCodecs: {
      204: undefined,
    },
    
    security: [{"MableOauth2":["admin","seller"]}],
  },
  'DELETE /v1/sellers/{sellerIdOrSlug}': {
    method: 'delete',
    path: '/v1/sellers/{sellerIdOrSlug}',
    accept: 'undefined',
    inputLocations: {
      'sellerIdOrSlug': 'path',
    },
    inputCodec: t.exact(t.intersection([
      t.type({
        'sellerIdOrSlug': URLStringCodec,
      }),
      t.partial({
        
      })
    ]), 'DELETE /v1/sellers/{sellerIdOrSlug} Input Codec'),
    responseCodec: t.void,
    responseCodecs: {
      204: undefined,
    },
    
    security: [{"MableOauth2":["admin"]}],
  },
  'GET /v1/sellers/{sellerIdOrSlug}/categories': {
    method: 'get',
    path: '/v1/sellers/{sellerIdOrSlug}/categories',
    accept: 'application/json',
    inputLocations: {
      'sellerIdOrSlug': 'path',
    },
    inputCodec: t.exact(t.intersection([
      t.type({
        'sellerIdOrSlug': URLStringCodec,
      }),
      t.partial({
        
      })
    ]), 'GET /v1/sellers/{sellerIdOrSlug}/categories Input Codec'),
    responseCodec: t.exact(t.intersection([t.type({
      
    }), t.partial({
      'categoryMemberships': t.array(CategoryMembership),
    })])),
    responseCodecs: {
      200: t.exact(t.intersection([t.type({
        
      }), t.partial({
        'categoryMemberships': t.array(CategoryMembership),
      })])),
    },
    
    security: [{"MableOauth2":["admin","buyer","seller","dataworker"]},{"NoAuth":[]}],
  },
  'GET /v1/sellers/{sellerShortSlug}/slug': {
    method: 'get',
    path: '/v1/sellers/{sellerShortSlug}/slug',
    accept: 'application/json',
    inputLocations: {
      'sellerShortSlug': 'path',
    },
    inputCodec: t.exact(t.intersection([
      t.type({
        'sellerShortSlug': URLStringCodec,
      }),
      t.partial({
        
      })
    ]), 'GET /v1/sellers/{sellerShortSlug}/slug Input Codec'),
    responseCodec: t.exact(t.intersection([t.type({
      'slug': Slug,
    }), t.partial({
      
    })])),
    responseCodecs: {
      200: t.exact(t.intersection([t.type({
        'slug': Slug,
      }), t.partial({
        
      })])),
    },
    
    security: [{"NoAuth":[]}],
  },
  'POST /v1/sellers/{sellerIdOrMe}/goLive': {
    method: 'post',
    path: '/v1/sellers/{sellerIdOrMe}/goLive',
    accept: 'undefined',
    inputLocations: {
      'sellerIdOrMe': 'path',
    },
    inputCodec: t.exact(t.intersection([
      t.type({
        'sellerIdOrMe': URLStringCodec,
      }),
      t.partial({
        
      })
    ]), 'POST /v1/sellers/{sellerIdOrMe}/goLive Input Codec'),
    responseCodec: t.void,
    responseCodecs: {
      200: undefined,
    },
    
    security: [{"MableOauth2":["admin","seller"]}],
  },
  'GET /v1/sellers/{sellerIdOrMe}/ordersCsv': {
    method: 'get',
    path: '/v1/sellers/{sellerIdOrMe}/ordersCsv',
    accept: 'application/json',
    inputLocations: {
      'sellerIdOrMe': 'path',
    },
    inputCodec: t.exact(t.intersection([
      t.type({
        'sellerIdOrMe': URLStringCodec,
      }),
      t.partial({
        
      })
    ]), 'GET /v1/sellers/{sellerIdOrMe}/ordersCsv Input Codec'),
    responseCodec: t.exact(t.intersection([t.type({
      
    }), t.partial({
      'csvString': t.string,
    })])),
    responseCodecs: {
      200: t.exact(t.intersection([t.type({
        
      }), t.partial({
        'csvString': t.string,
      })])),
    },
    
    security: [{"MableOauth2":["admin","seller"]}],
  },
  'GET /v1/sellers/{sellerIdOrMe}/detailedOrdersCsv': {
    method: 'get',
    path: '/v1/sellers/{sellerIdOrMe}/detailedOrdersCsv',
    accept: 'application/json',
    inputLocations: {
      'sellerIdOrMe': 'path',
    },
    inputCodec: t.exact(t.intersection([
      t.type({
        'sellerIdOrMe': URLStringCodec,
      }),
      t.partial({
        
      })
    ]), 'GET /v1/sellers/{sellerIdOrMe}/detailedOrdersCsv Input Codec'),
    responseCodec: t.exact(t.intersection([t.type({
      
    }), t.partial({
      'csvString': t.string,
    })])),
    responseCodecs: {
      200: t.exact(t.intersection([t.type({
        
      }), t.partial({
        'csvString': t.string,
      })])),
    },
    
    security: [{"MableOauth2":["admin","seller"]}],
  },
  'GET /v1/sellers/{sellerIdOrMe}/shippingSavings': {
    method: 'get',
    path: '/v1/sellers/{sellerIdOrMe}/shippingSavings',
    accept: 'application/json',
    inputLocations: {
      'sellerIdOrMe': 'path',
    },
    inputCodec: t.exact(t.intersection([
      t.type({
        /** The seller id to query or @me if you want yourself */
        'sellerIdOrMe': URLStringCodec,
      }),
      t.partial({
        
      })
    ]), 'GET /v1/sellers/{sellerIdOrMe}/shippingSavings Input Codec'),
    responseCodec: ShippingSavings,
    responseCodecs: {
      200: ShippingSavings,
    },
    
    security: [{"MableOauth2":["admin","seller"]}],
  },
  'GET /v1/sellers/{sellerIdOrMe}/modifyProduct': {
    method: 'get',
    path: '/v1/sellers/{sellerIdOrMe}/modifyProduct',
    accept: 'application/json',
    inputLocations: {
      'sellerIdOrMe': 'path',
      'productId': 'query',
    },
    inputCodec: t.exact(t.intersection([
      t.type({
        'sellerIdOrMe': URLStringCodec,
      }),
      t.partial({
        'productId': StringNumberCodec,
      })
    ]), 'GET /v1/sellers/{sellerIdOrMe}/modifyProduct Input Codec'),
    responseCodec: t.exact(t.intersection([t.type({
      'initialState': ModifyProductInitialState,
    }), t.partial({
      
    })])),
    responseCodecs: {
      200: t.exact(t.intersection([t.type({
        'initialState': ModifyProductInitialState,
      }), t.partial({
        
      })])),
    },
    
    security: [{"MableOauth2":["admin","seller"]}],
  },
  'POST /v1/sellers/{sellerIdOrMe}/modifyProduct': {
    method: 'post',
    path: '/v1/sellers/{sellerIdOrMe}/modifyProduct',
    accept: 'application/json',
    inputLocations: {
      'sellerIdOrMe': 'path',
      'body': 'body',
    },
    inputCodec: t.exact(t.intersection([
      t.type({
        'sellerIdOrMe': URLStringCodec,
        'body': ModifyProductRequest,
      }),
      t.partial({
        
      })
    ]), 'POST /v1/sellers/{sellerIdOrMe}/modifyProduct Input Codec'),
    responseCodec: t.exact(t.intersection([t.type({
      /** the product that was modified, or the productId of the newly created product */
      'productId': t.number,
    }), t.partial({
      
    })])),
    responseCodecs: {
      200: t.exact(t.intersection([t.type({
        /** the product that was modified, or the productId of the newly created product */
        'productId': t.number,
      }), t.partial({
        
      })])),
    },
    
    security: [{"MableOauth2":["admin","seller"]}],
  },
  'PUT /v1/sellers/{sellerId}/productsImport': {
    method: 'put',
    path: '/v1/sellers/{sellerId}/productsImport',
    accept: 'application/json',
    inputLocations: {
      'sellerId': 'path',
      'deleteMissingProducts': 'query',
      'patch': 'query',
      'resetSalesPrices': 'query',
      'useProductPosition': 'query',
      'csvInput': 'body',
    },
    inputCodec: t.exact(t.intersection([
      t.type({
        'sellerId': StringNumberCodec,
        'csvInput': CSVInput,
      }),
      t.partial({
        /** If true, mark all products sold by the seller that don't appear in this import as deleted. */
        'deleteMissingProducts': StringBooleanCodec,
        /** If true, the import will be done with a PATCH strategy. */
        'patch': StringBooleanCodec,
        /** If true, remove any sale pricing. */
        'resetSalesPrices': StringBooleanCodec,
        /** If true, use order of products from import for display position order */
        'useProductPosition': StringBooleanCodec,
      })
    ]), 'PUT /v1/sellers/{sellerId}/productsImport Input Codec'),
    responseCodec: t.exact(t.intersection([t.type({
      'products': t.array(Product),
    }), t.partial({
      
    })])),
    responseCodecs: {
      200: t.exact(t.intersection([t.type({
        'products': t.array(Product),
      }), t.partial({
        
      })])),
    },
    
    security: [{"MableOauth2":["admin"]}],
  },
  'PUT /v1/sellers/{sellerId}/productsImportPreview': {
    method: 'put',
    path: '/v1/sellers/{sellerId}/productsImportPreview',
    accept: 'application/json',
    inputLocations: {
      'sellerId': 'path',
      'csvInput': 'body',
    },
    inputCodec: t.exact(t.intersection([
      t.type({
        'sellerId': StringNumberCodec,
        'csvInput': CSVInput,
      }),
      t.partial({
        
      })
    ]), 'PUT /v1/sellers/{sellerId}/productsImportPreview Input Codec'),
    responseCodec: t.exact(t.intersection([t.type({
      'products': t.array(ProductInput),
    }), t.partial({
      
    })])),
    responseCodecs: {
      200: t.exact(t.intersection([t.type({
        'products': t.array(ProductInput),
      }), t.partial({
        
      })])),
    },
    
    security: [{"MableOauth2":["admin"]}],
  },
  'PATCH /v1/sellers/{sellerId}/csvVariantDataImport': {
    method: 'patch',
    path: '/v1/sellers/{sellerId}/csvVariantDataImport',
    accept: 'application/json',
    inputLocations: {
      'sellerId': 'path',
      'csvInput': 'body',
    },
    inputCodec: t.exact(t.intersection([
      t.type({
        'sellerId': StringNumberCodec,
        'csvInput': CSVInput,
      }),
      t.partial({
        
      })
    ]), 'PATCH /v1/sellers/{sellerId}/csvVariantDataImport Input Codec'),
    responseCodec: t.exact(t.intersection([t.type({
      'variants': t.array(ProductVariantListDisplayInfo),
    }), t.partial({
      
    })])),
    responseCodecs: {
      200: t.exact(t.intersection([t.type({
        'variants': t.array(ProductVariantListDisplayInfo),
      }), t.partial({
        
      })])),
    },
    
    security: [{"MableOauth2":["admin"]}],
  },
  'GET /v1/sellers/{sellerId}/status': {
    method: 'get',
    path: '/v1/sellers/{sellerId}/status',
    accept: 'application/json',
    inputLocations: {
      'sellerId': 'path',
    },
    inputCodec: t.exact(t.intersection([
      t.type({
        'sellerId': URLStringCodec,
      }),
      t.partial({
        
      })
    ]), 'GET /v1/sellers/{sellerId}/status Input Codec'),
    responseCodec: t.exact(t.intersection([t.type({
      
    }), t.partial({
      'flags': SellerDashboardFlags,
      'cards': t.array(DashboardContentItem),
      /** A list of the partner programs this seller is a member of. */
      'partnerProgramMemberships': t.array(PartnerProgramMembership),
    })])),
    responseCodecs: {
      200: t.exact(t.intersection([t.type({
        
      }), t.partial({
        'flags': SellerDashboardFlags,
        'cards': t.array(DashboardContentItem),
        /** A list of the partner programs this seller is a member of. */
        'partnerProgramMemberships': t.array(PartnerProgramMembership),
      })])),
    },
    
    security: [{"MableOauth2":["seller","admin"]}],
  },
  'POST /v1/sellers/{sellerIdOrMe}/shippingProgram/enroll': {
    method: 'post',
    path: '/v1/sellers/{sellerIdOrMe}/shippingProgram/enroll',
    accept: 'undefined',
    inputLocations: {
      'sellerIdOrMe': 'path',
      'input': 'body',
    },
    inputCodec: t.exact(t.intersection([
      t.type({
        'sellerIdOrMe': URLStringCodec,
        'input': t.exact(t.intersection([t.type({
          'freeShippingMinimum': NumberFromString,
        }), t.partial({
          
        })]), 'input'),
      }),
      t.partial({
        
      })
    ]), 'POST /v1/sellers/{sellerIdOrMe}/shippingProgram/enroll Input Codec'),
    responseCodec: t.void,
    responseCodecs: {
      204: undefined,
    },
    
    security: [{"MableOauth2":["seller","admin"]}],
  },
  'POST /v1/sellers/{sellerIdOrMe}/shippingProgram/unenroll': {
    method: 'post',
    path: '/v1/sellers/{sellerIdOrMe}/shippingProgram/unenroll',
    accept: 'undefined',
    inputLocations: {
      'sellerIdOrMe': 'path',
    },
    inputCodec: t.exact(t.intersection([
      t.type({
        'sellerIdOrMe': URLStringCodec,
      }),
      t.partial({
        
      })
    ]), 'POST /v1/sellers/{sellerIdOrMe}/shippingProgram/unenroll Input Codec'),
    responseCodec: t.void,
    responseCodecs: {
      204: undefined,
    },
    
    security: [{"MableOauth2":["seller","admin"]}],
  },
  'PATCH /v1/sellers/{sellerIdOrMe}/partnerProgram/{partnerAccountName}/onboarding': {
    method: 'patch',
    path: '/v1/sellers/{sellerIdOrMe}/partnerProgram/{partnerAccountName}/onboarding',
    accept: 'undefined',
    inputLocations: {
      'partnerAccountName': 'path',
      'sellerIdOrMe': 'path',
      'body': 'body',
    },
    inputCodec: t.exact(t.intersection([
      t.type({
        'partnerAccountName': URLStringCodec,
        'sellerIdOrMe': URLStringCodec,
        'body': t.exact(t.intersection([t.type({
          
        }), t.partial({
          'termsAccepted': t.boolean,
          'dataReviewed': t.boolean,
          'verificationProductShipped': t.boolean,
        })]), 'body'),
      }),
      t.partial({
        
      })
    ]), 'PATCH /v1/sellers/{sellerIdOrMe}/partnerProgram/{partnerAccountName}/onboarding Input Codec'),
    responseCodec: t.void,
    responseCodecs: {
      204: undefined,
    },
    
    security: [{"MableOauth2":["seller","admin"]}],
  },
  'POST /v1/sellers/@me/partnerProgram/{partnerAccountName}/productDataExport': {
    method: 'post',
    path: '/v1/sellers/@me/partnerProgram/{partnerAccountName}/productDataExport',
    accept: 'application/json',
    inputLocations: {
      'partnerAccountName': 'path',
    },
    inputCodec: t.exact(t.intersection([
      t.type({
        /** The name of the parter whose pricing you want to export. */
        'partnerAccountName': URLStringCodec,
      }),
      t.partial({
        
      })
    ]), 'POST /v1/sellers/@me/partnerProgram/{partnerAccountName}/productDataExport Input Codec'),
    responseCodec: t.exact(t.intersection([t.type({
      
    }), t.partial({
      'csvString': t.string,
    })])),
    responseCodecs: {
      200: t.exact(t.intersection([t.type({
        
      }), t.partial({
        'csvString': t.string,
      })])),
    },
    
    security: [{"MableOauth2":["seller"]}],
  },
  'GET /v1/transactions': {
    method: 'get',
    path: '/v1/transactions',
    accept: 'application/json',
    inputLocations: {
      'accountIds': 'query',
      'transactionIds': 'query',
      'actionsRequired': 'query',
      'include': 'query',
      'buyerInvoiceDueOnOrAfter': 'query',
      'buyerInvoiceDueBefore': 'query',
      'externalPurchaseOrderNumber': 'query',
      'page': 'query',
      'pageSize': 'query',
      'type': 'query',
      'partnerAccountId': 'query',
      'sellerIds': 'query',
    },
    inputCodec: t.exact(t.intersection([
      t.type({
        
      }),
      t.partial({
        /** Get transactions for these accounts instead of the authenticated user's. */
        'accountIds': StringArrayCodec(StringNumberCodec),
        /** Get transactions having any of these IDs */
        'transactionIds': StringArrayCodec(StringNumberCodec),
        /** Get only transactions requiring one of the given actions. */
        'actionsRequired': StringArrayCodec(TransactionAction),
        'include': StringArrayCodec(CodegenEnumClosed({
          'groups': null,
        }, 'includeItem')),
        /** Get only transactions with a buyer invoice due date on this day and later */
        'buyerInvoiceDueOnOrAfter': DateWithoutTimeFromISOString,
        /** Get only transactions with a buyer invoice due date earlier than this day */
        'buyerInvoiceDueBefore': DateWithoutTimeFromISOString,
        /** Get only transactions with this external purchase order number */
        'externalPurchaseOrderNumber': URLStringCodec,
        /** Page */
        'page': StringNumberCodec,
        /** Number of results per page */
        'pageSize': StringNumberCodec,
        /** Type(s) of transactions to get */
        'type': StringArrayCodec(TransactionType),
        /** Get only transactions associated with this partner account */
        'partnerAccountId': StringNumberCodec,
        /** Get transactions associated with any of these sellers */
        'sellerIds': StringArrayCodec(StringNumberCodec),
      })
    ]), 'GET /v1/transactions Input Codec'),
    responseCodec: t.exact(t.intersection([t.type({
      'transactions': t.array(Transaction),
    }), t.partial({
      'groups': t.record(t.string, TransactionGroup),
      /** Total count of transactions */
      'totalCount': t.number,
    })])),
    responseCodecs: {
      200: t.exact(t.intersection([t.type({
        'transactions': t.array(Transaction),
      }), t.partial({
        'groups': t.record(t.string, TransactionGroup),
        /** Total count of transactions */
        'totalCount': t.number,
      })])),
    },
    
    security: [{"MableOauth2":["admin","buyer"]}],
  },
  'GET /v1/admin/transactions/{transactionId}': {
    method: 'get',
    path: '/v1/admin/transactions/{transactionId}',
    accept: 'application/json',
    inputLocations: {
      'transactionId': 'path',
    },
    inputCodec: t.exact(t.intersection([
      t.type({
        'transactionId': StringNumberCodec,
      }),
      t.partial({
        
      })
    ]), 'GET /v1/admin/transactions/{transactionId} Input Codec'),
    responseCodec: t.exact(t.intersection([t.type({
      'transactionWithAssociations': TransactionWithAssociations,
    }), t.partial({
      'buyerPoContacts': t.array(Contact),
    })])),
    responseCodecs: {
      200: t.exact(t.intersection([t.type({
        'transactionWithAssociations': TransactionWithAssociations,
      }), t.partial({
        'buyerPoContacts': t.array(Contact),
      })])),
    },
    
    security: [{"MableOauth2":["admin"]}],
  },
  'POST /v1/transactions/{transactionId}/applyCommmission': {
    method: 'post',
    path: '/v1/transactions/{transactionId}/applyCommmission',
    accept: 'application/json',
    inputLocations: {
      'transactionId': 'path',
    },
    inputCodec: t.exact(t.intersection([
      t.type({
        'transactionId': StringNumberCodec,
      }),
      t.partial({
        
      })
    ]), 'POST /v1/transactions/{transactionId}/applyCommmission Input Codec'),
    responseCodec: TransactionWithAssociations,
    responseCodecs: {
      200: TransactionWithAssociations,
    },
    
    security: [{"MableOauth2":["admin"]}],
  },
  'POST /v1/transactions/{transactionId}/log': {
    method: 'post',
    path: '/v1/transactions/{transactionId}/log',
    accept: 'application/json',
    inputLocations: {
      'transactionId': 'path',
      'input': 'body',
    },
    inputCodec: t.exact(t.intersection([
      t.type({
        'transactionId': StringNumberCodec,
        'input': CreateTransactionLogEntry,
      }),
      t.partial({
        
      })
    ]), 'POST /v1/transactions/{transactionId}/log Input Codec'),
    responseCodec: TransactionWithAssociations,
    responseCodecs: {
      201: TransactionWithAssociations,
    },
    
    security: [{"MableOauth2":["admin"]}],
  },
  'GET /v1/transactions/{transactionId}/shipments': {
    method: 'get',
    path: '/v1/transactions/{transactionId}/shipments',
    accept: 'application/json',
    inputLocations: {
      'transactionId': 'path',
      'includeVoided': 'query',
      'dontNotify': 'query',
    },
    inputCodec: t.exact(t.intersection([
      t.type({
        'transactionId': StringNumberCodec,
      }),
      t.partial({
        /** Specify whether or not to return voided shipments. If omitted or false, no voided shipments will get returned. */
        'includeVoided': StringBooleanCodec,
        /** If true, don't send internal (Slack) notifications about this event. Admins only. */
        'dontNotify': StringBooleanCodec,
      })
    ]), 'GET /v1/transactions/{transactionId}/shipments Input Codec'),
    responseCodec: t.exact(t.intersection([t.type({
      'shipments': t.array(Shipment),
    }), t.partial({
      
    })])),
    responseCodecs: {
      200: t.exact(t.intersection([t.type({
        'shipments': t.array(Shipment),
      }), t.partial({
        
      })])),
    },
    
    security: [{"MableOauth2":["admin","buyer","seller"]}],
  },
  'POST /v1/transactions/{transactionId}/shipments': {
    method: 'post',
    path: '/v1/transactions/{transactionId}/shipments',
    accept: 'application/json',
    inputLocations: {
      'transactionId': 'path',
      'includeVoided': 'query',
      'dontNotify': 'query',
      'shipments': 'body',
    },
    inputCodec: t.exact(t.intersection([
      t.type({
        'transactionId': StringNumberCodec,
        'shipments': t.array(ShipmentInputOffMable),
      }),
      t.partial({
        /** Specify whether or not to return voided shipments. If omitted or false, no voided shipments will get returned. */
        'includeVoided': StringBooleanCodec,
        /** If true, don't send internal (Slack) notifications about this event. Admins only. */
        'dontNotify': StringBooleanCodec,
      })
    ]), 'POST /v1/transactions/{transactionId}/shipments Input Codec'),
    responseCodec: t.exact(t.intersection([t.type({
      'shipments': t.array(Shipment),
    }), t.partial({
      
    })])),
    responseCodecs: {
      200: t.exact(t.intersection([t.type({
        'shipments': t.array(Shipment),
      }), t.partial({
        
      })])),
    },
    
    security: [{"MableOauth2":["admin","seller"]}],
  },
  'PATCH /v1/transactions/{transactionId}/shipments/{shipmentId}': {
    method: 'patch',
    path: '/v1/transactions/{transactionId}/shipments/{shipmentId}',
    accept: 'undefined',
    inputLocations: {
      'transactionId': 'path',
      'shipmentId': 'path',
      'shipment': 'body',
    },
    inputCodec: t.exact(t.intersection([
      t.type({
        'transactionId': StringNumberCodec,
        'shipmentId': StringNumberCodec,
        'shipment': t.exact(t.intersection([t.type({
          'cost': NumberFromString,
        }), t.partial({
          
        })]), 'shipment'),
      }),
      t.partial({
        
      })
    ]), 'PATCH /v1/transactions/{transactionId}/shipments/{shipmentId} Input Codec'),
    responseCodec: t.void,
    responseCodecs: {
      204: undefined,
    },
    
    security: [{"MableOauth2":["admin","seller"]}],
  },
  'POST /v1/transactions/{transactionId}/shipments/{shipmentId}/void': {
    method: 'post',
    path: '/v1/transactions/{transactionId}/shipments/{shipmentId}/void',
    accept: 'application/json',
    inputLocations: {
      'transactionId': 'path',
      'shipmentId': 'path',
    },
    inputCodec: t.exact(t.intersection([
      t.type({
        'transactionId': StringNumberCodec,
        'shipmentId': StringNumberCodec,
      }),
      t.partial({
        
      })
    ]), 'POST /v1/transactions/{transactionId}/shipments/{shipmentId}/void Input Codec'),
    responseCodec: t.exact(t.intersection([t.type({
      'shipment': Shipment,
    }), t.partial({
      
    })])),
    responseCodecs: {
      200: t.exact(t.intersection([t.type({
        'shipment': Shipment,
      }), t.partial({
        
      })])),
    },
    
    security: [{"MableOauth2":["admin","seller"]}],
  },
  'PUT /v1/cart/note': {
    method: 'put',
    path: '/v1/cart/note',
    accept: 'undefined',
    inputLocations: {
      'items': 'body',
    },
    inputCodec: t.exact(t.intersection([
      t.type({
        'items': t.array(BuyerNoteInput),
      }),
      t.partial({
        
      })
    ]), 'PUT /v1/cart/note Input Codec'),
    responseCodec: t.void,
    responseCodecs: {
      204: undefined,
    },
    
    security: [{"MableOauth2":["buyer"]}],
  },
  'POST /v1/cart/discount': {
    method: 'post',
    path: '/v1/cart/discount',
    accept: 'undefined',
    inputLocations: {
      'code': 'query',
    },
    inputCodec: t.exact(t.intersection([
      t.type({
        'code': URLStringCodec,
      }),
      t.partial({
        
      })
    ]), 'POST /v1/cart/discount Input Codec'),
    responseCodec: t.void,
    responseCodecs: {
      204: undefined,
    },
    
    security: [{"MableOauth2":["buyer"]}],
  },
  'DELETE /v1/cart/discount': {
    method: 'delete',
    path: '/v1/cart/discount',
    accept: 'undefined',
    inputLocations: {
      'code': 'query',
    },
    inputCodec: t.exact(t.intersection([
      t.type({
        'code': URLStringCodec,
      }),
      t.partial({
        
      })
    ]), 'DELETE /v1/cart/discount Input Codec'),
    responseCodec: t.void,
    responseCodecs: {
      204: undefined,
    },
    
    security: [{"MableOauth2":["buyer"]}],
  },
  'PUT /v1/cart/update': {
    method: 'put',
    path: '/v1/cart/update',
    accept: 'application/json',
    inputLocations: {
      'items': 'body',
      'allowPartialSuccess': 'query',
      'return': 'query',
    },
    inputCodec: t.exact(t.intersection([
      t.type({
        'items': t.array(CartItemInput),
        /** Specify what you want to get returned. 'cart' returns the whole cart. 'items' returns the updated items, corresponding to what was just added. */
        'return': CodegenEnumClosed({
          'cart': null,
          'items': null,
          'empty': null,
        }, 'return'),
      }),
      t.partial({
        /** If this is `false`, the request will fail if any one of the products fail to get added to the cart. If `true`, the request can be successful even if only a subset of the products were able to be added. Defaults to `true`. For requests with only one item, success is always mandated. */
        'allowPartialSuccess': StringBooleanCodec,
      })
    ]), 'PUT /v1/cart/update Input Codec'),
    responseCodec: CartUpdateResult,
    responseCodecs: {
      200: CartUpdateResult,
    },
    
    security: [{"MableOauth2":["buyer"]}],
  },
  'DELETE /v1/cart': {
    method: 'delete',
    path: '/v1/cart',
    accept: 'undefined',
    inputLocations: {
      'clear': 'query',
      'sellerId': 'query',
    },
    inputCodec: t.exact(t.intersection([
      t.type({
        'clear': StringArrayCodec(CodegenEnumClosed({
          'items': null,
          'discounts': null,
          'buyerNotes': null,
        }, 'clearItem')),
      }),
      t.partial({
        /** If provided, restrict what is being cleared to the seller ID provided. */
        'sellerId': StringNumberCodec,
      })
    ]), 'DELETE /v1/cart Input Codec'),
    responseCodec: t.void,
    responseCodecs: {
      204: undefined,
    },
    
    security: [{"MableOauth2":["buyer"]}],
  },
  'GET /v1/cart': {
    method: 'get',
    path: '/v1/cart',
    accept: 'application/json',
    inputLocations: {
      
    },
    inputCodec: t.exact(t.intersection([
      t.type({
        
      }),
      t.partial({
        
      })
    ]), 'GET /v1/cart Input Codec'),
    responseCodec: t.exact(t.intersection([t.type({
      'cart': CartDisplay,
    }), t.partial({
      
    })])),
    responseCodecs: {
      200: t.exact(t.intersection([t.type({
        'cart': CartDisplay,
      }), t.partial({
        
      })])),
    },
    
    security: [{"MableOauth2":["buyer"]}],
  },
  'POST /v1/cart/reorder/{orderId}': {
    method: 'post',
    path: '/v1/cart/reorder/{orderId}',
    accept: 'application/json',
    inputLocations: {
      'orderId': 'path',
      'return': 'query',
    },
    inputCodec: t.exact(t.intersection([
      t.type({
        'orderId': StringNumberCodec,
        /** Specify what you want to get returned. 'cart' returns the whole cart. 'items' returns the updated items, corresponding to what was just added. */
        'return': CodegenEnumClosed({
          'cart': null,
          'items': null,
          'empty': null,
        }, 'return'),
      }),
      t.partial({
        
      })
    ]), 'POST /v1/cart/reorder/{orderId} Input Codec'),
    responseCodec: CartUpdateResult,
    responseCodecs: {
      200: CartUpdateResult,
    },
    
    security: [{"MableOauth2":["buyer"]}],
  },
  'GET /v1/checkout': {
    method: 'get',
    path: '/v1/checkout',
    accept: 'application/json',
    inputLocations: {
      'sellerIds': 'query',
      'checkoutVersion': 'query',
    },
    inputCodec: t.exact(t.intersection([
      t.type({
        /** Informs the logic the checkout client is able to handle. Minimum supported version is 2. */
        'checkoutVersion': StringNumberCodec,
      }),
      t.partial({
        /** The seller ids the user wants to check out with. If not provided, every seller above their order minimum will be included. */
        'sellerIds': StringArrayCodec(StringNumberCodec),
      })
    ]), 'GET /v1/checkout Input Codec'),
    responseCodec: t.exact(t.intersection([t.type({
      
    }), t.partial({
      'checkout': CheckoutInfo,
    })])),
    responseCodecs: {
      200: t.exact(t.intersection([t.type({
        
      }), t.partial({
        'checkout': CheckoutInfo,
      })])),
    },
    
    security: [{"MableOauth2":["buyer"]}],
  },
  'POST /v1/checkout': {
    method: 'post',
    path: '/v1/checkout',
    accept: 'application/json',
    inputLocations: {
      'input': 'body',
      'checkoutVersion': 'query',
    },
    inputCodec: t.exact(t.intersection([
      t.type({
        'input': Checkout,
        /** Informs the logic the checkout client is able to handle. Minimum supported version is 2. */
        'checkoutVersion': StringNumberCodec,
      }),
      t.partial({
        
      })
    ]), 'POST /v1/checkout Input Codec'),
    responseCodec: t.exact(t.intersection([t.type({
      'orders': t.array(OrderDisplayInfo),
    }), t.partial({
      'conversionType': BuyerConversionType,
    })])),
    responseCodecs: {
      200: t.exact(t.intersection([t.type({
        'orders': t.array(OrderDisplayInfo),
      }), t.partial({
        'conversionType': BuyerConversionType,
      })])),
    },
    
    security: [{"MableOauth2":["buyer"]}],
  },
  'POST /v1/admin/transactionGroups/{transactionGroupId}/sendPurchaseOrder': {
    method: 'post',
    path: '/v1/admin/transactionGroups/{transactionGroupId}/sendPurchaseOrder',
    accept: 'undefined',
    inputLocations: {
      'transactionGroupId': 'path',
    },
    inputCodec: t.exact(t.intersection([
      t.type({
        'transactionGroupId': StringNumberCodec,
      }),
      t.partial({
        
      })
    ]), 'POST /v1/admin/transactionGroups/{transactionGroupId}/sendPurchaseOrder Input Codec'),
    responseCodec: t.void,
    responseCodecs: {
      204: undefined,
    },
    
    security: [{"MableOauth2":["admin"]}],
  },
  'POST /v1/admin/transactionGroups/{transactionGroupId}/ediInvoice': {
    method: 'post',
    path: '/v1/admin/transactionGroups/{transactionGroupId}/ediInvoice',
    accept: 'application/json',
    inputLocations: {
      'transactionGroupId': 'path',
    },
    inputCodec: t.exact(t.intersection([
      t.type({
        'transactionGroupId': StringNumberCodec,
      }),
      t.partial({
        
      })
    ]), 'POST /v1/admin/transactionGroups/{transactionGroupId}/ediInvoice Input Codec'),
    responseCodec: t.exact(t.intersection([t.type({
      'contents': t.string,
      'filename': t.string,
    }), t.partial({
      
    })])),
    responseCodecs: {
      200: t.exact(t.intersection([t.type({
        'contents': t.string,
        'filename': t.string,
      }), t.partial({
        
      })])),
    },
    
    security: [{"MableOauth2":["admin"]}],
  },
  'POST /v1/admin/transactionGroups/{transactionGroupId}/sendEdiInvoice': {
    method: 'post',
    path: '/v1/admin/transactionGroups/{transactionGroupId}/sendEdiInvoice',
    accept: 'undefined',
    inputLocations: {
      'transactionGroupId': 'path',
    },
    inputCodec: t.exact(t.intersection([
      t.type({
        'transactionGroupId': StringNumberCodec,
      }),
      t.partial({
        
      })
    ]), 'POST /v1/admin/transactionGroups/{transactionGroupId}/sendEdiInvoice Input Codec'),
    responseCodec: t.void,
    responseCodecs: {
      204: undefined,
    },
    
    security: [{"MableOauth2":["admin"]}],
  },
  'POST /v1/admin/transactionGroups/{transactionGroupId}/sendOrderSettledEmail': {
    method: 'post',
    path: '/v1/admin/transactionGroups/{transactionGroupId}/sendOrderSettledEmail',
    accept: 'undefined',
    inputLocations: {
      'transactionGroupId': 'path',
    },
    inputCodec: t.exact(t.intersection([
      t.type({
        'transactionGroupId': StringNumberCodec,
      }),
      t.partial({
        
      })
    ]), 'POST /v1/admin/transactionGroups/{transactionGroupId}/sendOrderSettledEmail Input Codec'),
    responseCodec: t.void,
    responseCodecs: {
      204: undefined,
    },
    
    security: [{"MableOauth2":["admin"]}],
  },
  'POST /v1/admin/transactions/{transactionId}/addProductPurchase': {
    method: 'post',
    path: '/v1/admin/transactions/{transactionId}/addProductPurchase',
    accept: 'application/json',
    inputLocations: {
      'transactionId': 'path',
      'body': 'body',
    },
    inputCodec: t.exact(t.intersection([
      t.type({
        'transactionId': StringNumberCodec,
        'body': t.exact(t.intersection([t.type({
          'productId': t.number,
          'variantId': t.number,
          'quantity': t.number,
        }), t.partial({
          
        })]), 'body'),
      }),
      t.partial({
        
      })
    ]), 'POST /v1/admin/transactions/{transactionId}/addProductPurchase Input Codec'),
    responseCodec: TransactionWithAssociations,
    responseCodecs: {
      200: TransactionWithAssociations,
    },
    
    security: [{"MableOauth2":["admin"]}],
  },
  'POST /v1/admin/transactions/{transactionId}/attribution': {
    method: 'post',
    path: '/v1/admin/transactions/{transactionId}/attribution',
    accept: 'undefined',
    inputLocations: {
      'transactionId': 'path',
      'body': 'body',
    },
    inputCodec: t.exact(t.intersection([
      t.type({
        'transactionId': StringNumberCodec,
        'body': t.exact(t.intersection([t.type({
          'source': t.string,
        }), t.partial({
          
        })]), 'body'),
      }),
      t.partial({
        
      })
    ]), 'POST /v1/admin/transactions/{transactionId}/attribution Input Codec'),
    responseCodec: t.void,
    responseCodecs: {
      204: undefined,
    },
    
    security: [{"MableOauth2":["admin"]}],
  },
  'PUT /v1/admin/transactions/{transactionId}/splits/{splitId}': {
    method: 'put',
    path: '/v1/admin/transactions/{transactionId}/splits/{splitId}',
    accept: 'application/json',
    inputLocations: {
      'transactionId': 'path',
      'splitId': 'path',
      'body': 'body',
    },
    inputCodec: t.exact(t.intersection([
      t.type({
        'transactionId': StringNumberCodec,
        'splitId': StringNumberCodec,
        'body': t.exact(t.intersection([t.type({
          
        }), t.partial({
          'amount': NumberFromString,
          'quantity': NumberFromString,
          'cancel': t.boolean,
          'note': t.string,
          'isOutOfStock': t.boolean,
        })]), 'body'),
      }),
      t.partial({
        
      })
    ]), 'PUT /v1/admin/transactions/{transactionId}/splits/{splitId} Input Codec'),
    responseCodec: TransactionWithAssociations,
    responseCodecs: {
      200: TransactionWithAssociations,
    },
    
    security: [{"MableOauth2":["admin"]}],
  },
  'POST /v1/admin/transactions/{transactionId}/readyForBuyerInvoice': {
    method: 'post',
    path: '/v1/admin/transactions/{transactionId}/readyForBuyerInvoice',
    accept: 'application/json',
    inputLocations: {
      'transactionId': 'path',
    },
    inputCodec: t.exact(t.intersection([
      t.type({
        'transactionId': StringNumberCodec,
      }),
      t.partial({
        
      })
    ]), 'POST /v1/admin/transactions/{transactionId}/readyForBuyerInvoice Input Codec'),
    responseCodec: TransactionWithAssociations,
    responseCodecs: {
      200: TransactionWithAssociations,
    },
    
    security: [{"MableOauth2":["admin"]}],
  },
  'POST /v1/admin/transactions/{transactionId}/fulfillmentRouting': {
    method: 'post',
    path: '/v1/admin/transactions/{transactionId}/fulfillmentRouting',
    accept: 'undefined',
    inputLocations: {
      'transactionId': 'path',
      'fulfillmentRoutingStatus': 'query',
    },
    inputCodec: t.exact(t.intersection([
      t.type({
        'transactionId': StringNumberCodec,
        'fulfillmentRoutingStatus': FulfillmentRoutingStatus,
      }),
      t.partial({
        
      })
    ]), 'POST /v1/admin/transactions/{transactionId}/fulfillmentRouting Input Codec'),
    responseCodec: t.void,
    responseCodecs: {
      204: undefined,
    },
    
    security: [{"MableOauth2":["admin"]}],
  },
  'POST /v1/admin/transactions/{transactionId}/sendInvoice/edi': {
    method: 'post',
    path: '/v1/admin/transactions/{transactionId}/sendInvoice/edi',
    accept: 'undefined',
    inputLocations: {
      'transactionId': 'path',
    },
    inputCodec: t.exact(t.intersection([
      t.type({
        'transactionId': StringNumberCodec,
      }),
      t.partial({
        
      })
    ]), 'POST /v1/admin/transactions/{transactionId}/sendInvoice/edi Input Codec'),
    responseCodec: t.void,
    responseCodecs: {
      204: undefined,
    },
    
    security: [{"MableOauth2":["admin"]}],
  },
  'POST /v1/admin/transactions/{transactionId}/ediAck/send': {
    method: 'post',
    path: '/v1/admin/transactions/{transactionId}/ediAck/send',
    accept: 'undefined',
    inputLocations: {
      'transactionId': 'path',
    },
    inputCodec: t.exact(t.intersection([
      t.type({
        'transactionId': StringNumberCodec,
      }),
      t.partial({
        
      })
    ]), 'POST /v1/admin/transactions/{transactionId}/ediAck/send Input Codec'),
    responseCodec: t.void,
    responseCodecs: {
      204: undefined,
    },
    
    security: [{"MableOauth2":["admin"]}],
  },
  'POST /v1/admin/account/{accountId}/edi/checkForFiles': {
    method: 'post',
    path: '/v1/admin/account/{accountId}/edi/checkForFiles',
    accept: 'undefined',
    inputLocations: {
      'accountId': 'path',
    },
    inputCodec: t.exact(t.intersection([
      t.type({
        'accountId': StringNumberCodec,
      }),
      t.partial({
        
      })
    ]), 'POST /v1/admin/account/{accountId}/edi/checkForFiles Input Codec'),
    responseCodec: t.void,
    responseCodecs: {
      204: undefined,
    },
    
    security: [{"MableOauth2":["admin"]}],
  },
  'POST /v1/admin/account/{accountId}/edi/testFtpConnection': {
    method: 'post',
    path: '/v1/admin/account/{accountId}/edi/testFtpConnection',
    accept: 'undefined',
    inputLocations: {
      'accountId': 'path',
    },
    inputCodec: t.exact(t.intersection([
      t.type({
        'accountId': StringNumberCodec,
      }),
      t.partial({
        
      })
    ]), 'POST /v1/admin/account/{accountId}/edi/testFtpConnection Input Codec'),
    responseCodec: t.void,
    responseCodecs: {
      204: undefined,
    },
    
    security: [{"MableOauth2":["admin"]}],
  },
  'POST /v1/admin/transactions/{transactionId}/sendCreditInvoice/edi': {
    method: 'post',
    path: '/v1/admin/transactions/{transactionId}/sendCreditInvoice/edi',
    accept: 'undefined',
    inputLocations: {
      'transactionId': 'path',
    },
    inputCodec: t.exact(t.intersection([
      t.type({
        'transactionId': StringNumberCodec,
      }),
      t.partial({
        
      })
    ]), 'POST /v1/admin/transactions/{transactionId}/sendCreditInvoice/edi Input Codec'),
    responseCodec: t.void,
    responseCodecs: {
      204: undefined,
    },
    
    security: [{"MableOauth2":["admin"]}],
  },
  'POST /v1/admin/transactions/{transactionId}/redistributeShipping': {
    method: 'post',
    path: '/v1/admin/transactions/{transactionId}/redistributeShipping',
    accept: 'undefined',
    inputLocations: {
      'transactionId': 'path',
      'body': 'body',
    },
    inputCodec: t.exact(t.intersection([
      t.type({
        'transactionId': StringNumberCodec,
        'body': t.exact(t.intersection([t.type({
          'reason': t.string,
        }), t.partial({
          'overrideBuyerChangeBlock': t.boolean,
          'overrideSellerChangeBlock': t.boolean,
        })]), 'body'),
      }),
      t.partial({
        
      })
    ]), 'POST /v1/admin/transactions/{transactionId}/redistributeShipping Input Codec'),
    responseCodec: t.void,
    responseCodecs: {
      204: undefined,
    },
    
    security: [{"MableOauth2":["admin"]}],
  },
  'POST /v1/admin/transactions/{transactionId}/removeFreeShippingMinimum': {
    method: 'post',
    path: '/v1/admin/transactions/{transactionId}/removeFreeShippingMinimum',
    accept: 'undefined',
    inputLocations: {
      'transactionId': 'path',
    },
    inputCodec: t.exact(t.intersection([
      t.type({
        'transactionId': StringNumberCodec,
      }),
      t.partial({
        
      })
    ]), 'POST /v1/admin/transactions/{transactionId}/removeFreeShippingMinimum Input Codec'),
    responseCodec: t.void,
    responseCodecs: {
      204: undefined,
    },
    
    security: [{"MableOauth2":["admin"]}],
  },
  'POST /v1/admin/transactions/{transactionId}/applyCredits': {
    method: 'post',
    path: '/v1/admin/transactions/{transactionId}/applyCredits',
    accept: 'undefined',
    inputLocations: {
      'transactionId': 'path',
      'body': 'body',
    },
    inputCodec: t.exact(t.intersection([
      t.type({
        'transactionId': StringNumberCodec,
        'body': TransactionCreditApplicationInput,
      }),
      t.partial({
        
      })
    ]), 'POST /v1/admin/transactions/{transactionId}/applyCredits Input Codec'),
    responseCodec: t.void,
    responseCodecs: {
      204: undefined,
    },
    
    security: [{"MableOauth2":["admin"]}],
  },
  'POST /v1/admin/transactions/recordCheckPayment': {
    method: 'post',
    path: '/v1/admin/transactions/recordCheckPayment',
    accept: 'undefined',
    inputLocations: {
      'body': 'body',
    },
    inputCodec: t.exact(t.intersection([
      t.type({
        'body': t.exact(t.intersection([t.type({
          /** The transactions to pay with this check */
          'lineItems': t.array(t.exact(t.intersection([t.type({
            'transactionId': t.number,
          }), t.partial({
            /** The portion of the check allocated to this transaction */
            'amount': NumberFromString,
            /** An optional, internal-only note about the payment specific to this transaction */
            'note': t.string,
          })]), 'bodyItem')),
          /** The amount on the check. */
          'amount': NumberFromString,
          /** A reference number for the check. */
          'checkNumber': t.string,
        }), t.partial({
          /** An optional, internal-only note about the payment. */
          'note': t.string,
          /** Is this a payment from a partner? If so, allow a single check payment to cover transactions that span multiple accounts as long as they're for the same partner */
          'isPartnerPayment': t.boolean,
        })]), 'body'),
      }),
      t.partial({
        
      })
    ]), 'POST /v1/admin/transactions/recordCheckPayment Input Codec'),
    responseCodec: t.void,
    responseCodecs: {
      200: undefined,
    },
    
    security: [{"MableOauth2":["admin"]}],
  },
  'POST /v1/admin/transactions/{transactionId}/recordCheckPayment': {
    method: 'post',
    path: '/v1/admin/transactions/{transactionId}/recordCheckPayment',
    accept: 'undefined',
    inputLocations: {
      'transactionId': 'path',
      'body': 'body',
    },
    inputCodec: t.exact(t.intersection([
      t.type({
        'transactionId': StringNumberCodec,
        'body': t.exact(t.intersection([t.type({
          /** The portion of the check we want to apply to this transaction. */
          'amount': NumberFromString,
          /** A reference number for the check. */
          'checkNumber': t.string,
        }), t.partial({
          /** An optional, internal-only note about the payment. */
          'note': t.string,
        })]), 'body'),
      }),
      t.partial({
        
      })
    ]), 'POST /v1/admin/transactions/{transactionId}/recordCheckPayment Input Codec'),
    responseCodec: t.void,
    responseCodecs: {
      204: undefined,
    },
    
    security: [{"MableOauth2":["admin"]}],
  },
  'GET /v1/admin/transactions/{transactionId}/ediInvoice': {
    method: 'get',
    path: '/v1/admin/transactions/{transactionId}/ediInvoice',
    accept: 'application/json',
    inputLocations: {
      'transactionId': 'path',
    },
    inputCodec: t.exact(t.intersection([
      t.type({
        'transactionId': StringNumberCodec,
      }),
      t.partial({
        
      })
    ]), 'GET /v1/admin/transactions/{transactionId}/ediInvoice Input Codec'),
    responseCodec: t.exact(t.intersection([t.type({
      'contents': t.string,
      'filename': t.string,
    }), t.partial({
      
    })])),
    responseCodecs: {
      200: t.exact(t.intersection([t.type({
        'contents': t.string,
        'filename': t.string,
      }), t.partial({
        
      })])),
    },
    
    security: [{"MableOauth2":["admin"]}],
  },
  'GET /v1/admin/transactions/{transactionId}/ediCreditInvoice': {
    method: 'get',
    path: '/v1/admin/transactions/{transactionId}/ediCreditInvoice',
    accept: 'application/json',
    inputLocations: {
      'transactionId': 'path',
    },
    inputCodec: t.exact(t.intersection([
      t.type({
        'transactionId': StringNumberCodec,
      }),
      t.partial({
        
      })
    ]), 'GET /v1/admin/transactions/{transactionId}/ediCreditInvoice Input Codec'),
    responseCodec: t.exact(t.intersection([t.type({
      'contents': t.string,
      'filename': t.string,
    }), t.partial({
      
    })])),
    responseCodecs: {
      200: t.exact(t.intersection([t.type({
        'contents': t.string,
        'filename': t.string,
      }), t.partial({
        
      })])),
    },
    
    security: [{"MableOauth2":["admin"]}],
  },
  'GET /v1/admin/transactions/{transactionId}/ediAck': {
    method: 'get',
    path: '/v1/admin/transactions/{transactionId}/ediAck',
    accept: 'application/json',
    inputLocations: {
      'transactionId': 'path',
    },
    inputCodec: t.exact(t.intersection([
      t.type({
        'transactionId': StringNumberCodec,
      }),
      t.partial({
        
      })
    ]), 'GET /v1/admin/transactions/{transactionId}/ediAck Input Codec'),
    responseCodec: t.exact(t.intersection([t.type({
      'contents': t.string,
      'filename': t.string,
      'mableAck': t.record(t.string, t.unknown),
    }), t.partial({
      
    })])),
    responseCodecs: {
      200: t.exact(t.intersection([t.type({
        'contents': t.string,
        'filename': t.string,
        'mableAck': t.record(t.string, t.unknown),
      }), t.partial({
        
      })])),
    },
    
    security: [{"MableOauth2":["admin"]}],
  },
  'POST /v1/admin/transactions/{transactionId}/ediShipNotice': {
    method: 'post',
    path: '/v1/admin/transactions/{transactionId}/ediShipNotice',
    accept: 'application/json',
    inputLocations: {
      'transactionId': 'path',
      'body': 'body',
    },
    inputCodec: t.exact(t.intersection([
      t.type({
        'transactionId': StringNumberCodec,
        'body': t.exact(t.intersection([t.type({
          
        }), t.partial({
          /** If generating a ship notice for a single shipment, provide the ID here. */
          'shipmentId': t.number,
        })]), 'body'),
      }),
      t.partial({
        
      })
    ]), 'POST /v1/admin/transactions/{transactionId}/ediShipNotice Input Codec'),
    responseCodec: t.exact(t.intersection([t.type({
      'contents': t.string,
      'filename': t.string,
    }), t.partial({
      
    })])),
    responseCodecs: {
      200: t.exact(t.intersection([t.type({
        'contents': t.string,
        'filename': t.string,
      }), t.partial({
        
      })])),
    },
    
    security: [{"MableOauth2":["admin"]}],
  },
  'POST /v1/admin/transactions/{transactionId}/ediShipNotice/send': {
    method: 'post',
    path: '/v1/admin/transactions/{transactionId}/ediShipNotice/send',
    accept: 'undefined',
    inputLocations: {
      'transactionId': 'path',
      'body': 'body',
    },
    inputCodec: t.exact(t.intersection([
      t.type({
        'transactionId': StringNumberCodec,
        'body': t.exact(t.intersection([t.type({
          
        }), t.partial({
          /** If sending a ship notice for a single shipment, provide the ID here. */
          'shipmentId': t.number,
        })]), 'body'),
      }),
      t.partial({
        
      })
    ]), 'POST /v1/admin/transactions/{transactionId}/ediShipNotice/send Input Codec'),
    responseCodec: t.void,
    responseCodecs: {
      204: undefined,
    },
    
    security: [{"MableOauth2":["admin"]}],
  },
  'POST /v1/admin/transactions/{transactionId}/ediDeliveryBaseRecord': {
    method: 'post',
    path: '/v1/admin/transactions/{transactionId}/ediDeliveryBaseRecord',
    accept: 'application/json',
    inputLocations: {
      'transactionId': 'path',
    },
    inputCodec: t.exact(t.intersection([
      t.type({
        'transactionId': StringNumberCodec,
      }),
      t.partial({
        
      })
    ]), 'POST /v1/admin/transactions/{transactionId}/ediDeliveryBaseRecord Input Codec'),
    responseCodec: t.exact(t.intersection([t.type({
      'contents': t.string,
      'filename': t.string,
    }), t.partial({
      
    })])),
    responseCodecs: {
      200: t.exact(t.intersection([t.type({
        'contents': t.string,
        'filename': t.string,
      }), t.partial({
        
      })])),
    },
    
    security: [{"MableOauth2":["admin"]}],
  },
  'POST /v1/admin/transactions/{transactionId}/ediDeliveryBaseRecord/send': {
    method: 'post',
    path: '/v1/admin/transactions/{transactionId}/ediDeliveryBaseRecord/send',
    accept: 'undefined',
    inputLocations: {
      'transactionId': 'path',
    },
    inputCodec: t.exact(t.intersection([
      t.type({
        'transactionId': StringNumberCodec,
      }),
      t.partial({
        
      })
    ]), 'POST /v1/admin/transactions/{transactionId}/ediDeliveryBaseRecord/send Input Codec'),
    responseCodec: t.void,
    responseCodecs: {
      204: undefined,
    },
    
    security: [{"MableOauth2":["admin"]}],
  },
  'POST /v1/admin/transactions/{transactionId}/pushAutoCancelDate': {
    method: 'post',
    path: '/v1/admin/transactions/{transactionId}/pushAutoCancelDate',
    accept: 'undefined',
    inputLocations: {
      'transactionId': 'path',
      'body': 'body',
    },
    inputCodec: t.exact(t.intersection([
      t.type({
        'transactionId': StringNumberCodec,
        'body': t.exact(t.intersection([t.type({
          /** The number of business days to push the auto-cancel date */
          'days': t.number,
        }), t.partial({
          
        })]), 'body'),
      }),
      t.partial({
        
      })
    ]), 'POST /v1/admin/transactions/{transactionId}/pushAutoCancelDate Input Codec'),
    responseCodec: t.void,
    responseCodecs: {
      204: undefined,
    },
    
    security: [{"MableOauth2":["admin"]}],
  },
  'POST /v1/pay': {
    method: 'post',
    path: '/v1/pay',
    accept: 'application/json',
    inputLocations: {
      'body': 'body',
    },
    inputCodec: t.exact(t.intersection([
      t.type({
        'body': t.exact(t.intersection([t.type({
          /** The amount of money the user is expecting to pay. If this doesn't match the calculated payment total, an error will be thrown. */
          'expectedTotal': NumberFromString,
          /** An identifier for an attempt to pay, generated on the client. Prevents double-paying if there's an error and the user retries. You probably want to generate a UUID for this. */
          'idempotencyId': t.string,
        }), t.partial({
          'orderIds': t.array(t.number),
          'stripePaymentMethodId': t.string,
          'mablePaymentMethodId': t.number,
          /** Admin only, should match the account of the provided orderIds */
          'accountId': t.number,
        })]), 'body'),
      }),
      t.partial({
        
      })
    ]), 'POST /v1/pay Input Codec'),
    responseCodec: t.record(t.string, t.unknown),
    responseCodecs: {
      200: t.record(t.string, t.unknown),
    },
    
    security: [{"MableOauth2":["admin","buyer"]}],
  },
  'POST /v1/admin/products/supplement': {
    method: 'post',
    path: '/v1/admin/products/supplement',
    accept: 'undefined',
    inputLocations: {
      'productIds': 'query',
      'sellerId': 'query',
      'strategies': 'query',
      'supplementStatus': 'query',
    },
    inputCodec: t.exact(t.intersection([
      t.type({
        /** What to supplement */
        'strategies': StringArrayCodec(ProductDataSupplementStrategy),
      }),
      t.partial({
        'productIds': StringArrayCodec(StringNumberCodec),
        'sellerId': StringNumberCodec,
        /** Specify a `supplementStatus` requirement for what to supplement. e.g. Specify `['error']` to only retry the ones in error state */
        'supplementStatus': StringArrayCodec(ProductDataSupplementStatus),
      })
    ]), 'POST /v1/admin/products/supplement Input Codec'),
    responseCodec: t.void,
    responseCodecs: {
      204: undefined,
    },
    
    security: [{"MableOauth2":["admin"]}],
  },
  'POST /v1/admin/products/fetchAllIxOne': {
    method: 'post',
    path: '/v1/admin/products/fetchAllIxOne',
    accept: 'undefined',
    inputLocations: {
      
    },
    inputCodec: t.exact(t.intersection([
      t.type({
        
      }),
      t.partial({
        
      })
    ]), 'POST /v1/admin/products/fetchAllIxOne Input Codec'),
    responseCodec: t.void,
    responseCodecs: {
      204: undefined,
    },
    
    security: [{"MableOauth2":["admin"]}],
  },
  'POST /v1/admin/products/convertBrandOnboardingCsv': {
    method: 'post',
    path: '/v1/admin/products/convertBrandOnboardingCsv',
    accept: 'application/json',
    inputLocations: {
      'csvInput': 'body',
    },
    inputCodec: t.exact(t.intersection([
      t.type({
        'csvInput': CSVInput,
      }),
      t.partial({
        
      })
    ]), 'POST /v1/admin/products/convertBrandOnboardingCsv Input Codec'),
    responseCodec: t.exact(t.intersection([t.type({
      
    }), t.partial({
      'csvString': t.string,
    })])),
    responseCodecs: {
      200: t.exact(t.intersection([t.type({
        
      }), t.partial({
        'csvString': t.string,
      })])),
    },
    
    security: [{"MableOauth2":["admin"]}],
  },
  'GET /v1/pay/methods': {
    method: 'get',
    path: '/v1/pay/methods',
    accept: 'application/json',
    inputLocations: {
      'accountId': 'query',
    },
    inputCodec: t.exact(t.intersection([
      t.type({
        
      }),
      t.partial({
        /** Admins only. Get payment methods for this account instead of the authenticated user's. */
        'accountId': StringNumberCodec,
      })
    ]), 'GET /v1/pay/methods Input Codec'),
    responseCodec: t.exact(t.intersection([t.type({
      'paymentMethods': t.array(PaymentMethod),
    }), t.partial({
      
    })])),
    responseCodecs: {
      200: t.exact(t.intersection([t.type({
        'paymentMethods': t.array(PaymentMethod),
      }), t.partial({
        
      })])),
    },
    
    security: [{"MableOauth2":["admin","buyer"]}],
  },
  'POST /v1/pay/methods': {
    method: 'post',
    path: '/v1/pay/methods',
    accept: 'application/json',
    inputLocations: {
      'body': 'body',
    },
    inputCodec: t.exact(t.intersection([
      t.type({
        'body': t.exact(t.intersection([t.type({
          'stripePaymentMethodId': t.string,
        }), t.partial({
          /** Ignored unless you pass `true`, because there must always be one default payment method. */
          'isDefault': t.boolean,
        })]), 'body'),
      }),
      t.partial({
        
      })
    ]), 'POST /v1/pay/methods Input Codec'),
    responseCodec: t.exact(t.intersection([t.type({
      'paymentMethodId': t.number,
    }), t.partial({
      
    })])),
    responseCodecs: {
      201: t.exact(t.intersection([t.type({
        'paymentMethodId': t.number,
      }), t.partial({
        
      })])),
    },
    
    security: [{"MableOauth2":["admin","buyer"]}],
  },
  'DELETE /v1/pay/methods/{paymentMethodId}': {
    method: 'delete',
    path: '/v1/pay/methods/{paymentMethodId}',
    accept: 'undefined',
    inputLocations: {
      'paymentMethodId': 'path',
    },
    inputCodec: t.exact(t.intersection([
      t.type({
        'paymentMethodId': StringNumberCodec,
      }),
      t.partial({
        
      })
    ]), 'DELETE /v1/pay/methods/{paymentMethodId} Input Codec'),
    responseCodec: t.void,
    responseCodecs: {
      204: undefined,
    },
    
    security: [{"MableOauth2":["admin","buyer"]}],
  },
  'POST /v1/pay/methods/{paymentMethodId}/default': {
    method: 'post',
    path: '/v1/pay/methods/{paymentMethodId}/default',
    accept: 'undefined',
    inputLocations: {
      'paymentMethodId': 'path',
      'body': 'body',
    },
    inputCodec: t.exact(t.intersection([
      t.type({
        'paymentMethodId': StringNumberCodec,
        'body': t.exact(t.intersection([t.type({
          'idempotencyId': t.string,
        }), t.partial({
          
        })]), 'body'),
      }),
      t.partial({
        
      })
    ]), 'POST /v1/pay/methods/{paymentMethodId}/default Input Codec'),
    responseCodec: t.void,
    responseCodecs: {
      201: undefined,
    },
    
    security: [{"MableOauth2":["admin","buyer"]}],
  },
  'POST /v1/pay/refund': {
    method: 'post',
    path: '/v1/pay/refund',
    accept: 'undefined',
    inputLocations: {
      'body': 'body',
    },
    inputCodec: t.exact(t.intersection([
      t.type({
        'body': t.exact(t.intersection([t.type({
          /** The id of the split this payment intent is associated with */
          'splitId': t.number,
          /** Stripe's payment intent id */
          'paymentIntentId': t.string,
          /** Whether the invoice should require another payment after this refund, for example if the buyer wants to pay with a different card instead. */
          'requirePaymentAfterRefund': t.boolean,
        }), t.partial({
          
        })]), 'body'),
      }),
      t.partial({
        
      })
    ]), 'POST /v1/pay/refund Input Codec'),
    responseCodec: t.void,
    responseCodecs: {
      201: undefined,
    },
    
    security: [{"MableOauth2":["admin"]}],
  },
  'GET /v1/unauthHomepage': {
    method: 'get',
    path: '/v1/unauthHomepage',
    accept: 'application/json',
    inputLocations: {
      'platform': 'query',
      'limitCategories': 'query',
    },
    inputCodec: t.exact(t.intersection([
      t.type({
        'platform': CodegenEnumClosed({
          'web': null,
          'ios': null,
          'android': null,
        }, 'platform'),
      }),
      t.partial({
        /** Number of featured categories to return */
        'limitCategories': StringNumberCodec,
      })
    ]), 'GET /v1/unauthHomepage Input Codec'),
    responseCodec: t.exact(t.intersection([t.type({
      'content': t.array(DashboardContentItem),
      /** Content to be populated under Popular by Region once the user makes a region selection or we discern their region of interest */
      'popularByRegionContent': t.exact(t.intersection([t.type({
        
      }), t.partial({
        'west': DashboardContentItem,
        'midwest': DashboardContentItem,
        'southwest': DashboardContentItem,
        'south': DashboardContentItem,
        'mid-atlantic': DashboardContentItem,
        'new-england': DashboardContentItem,
      })])),
    }), t.partial({
      
    })])),
    responseCodecs: {
      200: t.exact(t.intersection([t.type({
        'content': t.array(DashboardContentItem),
        /** Content to be populated under Popular by Region once the user makes a region selection or we discern their region of interest */
        'popularByRegionContent': t.exact(t.intersection([t.type({
          
        }), t.partial({
          'west': DashboardContentItem,
          'midwest': DashboardContentItem,
          'southwest': DashboardContentItem,
          'south': DashboardContentItem,
          'mid-atlantic': DashboardContentItem,
          'new-england': DashboardContentItem,
        })])),
      }), t.partial({
        
      })])),
    },
    
    security: [{"NoAuth":[]}],
  },
  'GET /v1/buyer/dashboard': {
    method: 'get',
    path: '/v1/buyer/dashboard',
    accept: 'application/json',
    inputLocations: {
      'platform': 'query',
    },
    inputCodec: t.exact(t.intersection([
      t.type({
        'platform': CodegenEnumClosed({
          'web': null,
          'ios': null,
          'android': null,
        }, 'platform'),
      }),
      t.partial({
        
      })
    ]), 'GET /v1/buyer/dashboard Input Codec'),
    responseCodec: t.exact(t.intersection([t.type({
      'content': t.array(DashboardContentItem),
    }), t.partial({
      
    })])),
    responseCodecs: {
      200: t.exact(t.intersection([t.type({
        'content': t.array(DashboardContentItem),
      }), t.partial({
        
      })])),
    },
    
    security: [{"MableOauth2":["admin","buyer"]},{"NoAuth":[]}],
  },
  'GET /v1/buyer/marketplace': {
    method: 'get',
    path: '/v1/buyer/marketplace',
    accept: 'application/json',
    inputLocations: {
      'platform': 'query',
    },
    inputCodec: t.exact(t.intersection([
      t.type({
        'platform': CodegenEnumClosed({
          'web': null,
          'ios': null,
          'android': null,
        }, 'platform'),
      }),
      t.partial({
        
      })
    ]), 'GET /v1/buyer/marketplace Input Codec'),
    responseCodec: t.exact(t.intersection([t.type({
      'content': t.array(DashboardContentItem),
      /** 'Shop by category' categories. */
      'categories': t.array(Category),
      /** 'Shop by diet' diets. */
      'diets': t.array(t.exact(t.intersection([t.type({
        'id': t.string,
        'name': t.string,
      }), t.partial({
        
      })]))),
    }), t.partial({
      /** 'Shop by values' values. */
      'values': t.array(t.exact(t.intersection([t.type({
        'id': t.string,
        'name': t.string,
      }), t.partial({
        
      })]))),
    })])),
    responseCodecs: {
      200: t.exact(t.intersection([t.type({
        'content': t.array(DashboardContentItem),
        /** 'Shop by category' categories. */
        'categories': t.array(Category),
        /** 'Shop by diet' diets. */
        'diets': t.array(t.exact(t.intersection([t.type({
          'id': t.string,
          'name': t.string,
        }), t.partial({
          
        })]))),
      }), t.partial({
        /** 'Shop by values' values. */
        'values': t.array(t.exact(t.intersection([t.type({
          'id': t.string,
          'name': t.string,
        }), t.partial({
          
        })]))),
      })])),
    },
    
    security: [{"MableOauth2":["admin","buyer"]},{"NoAuth":[]}],
  },
  'GET /v1/buyer/postCheckout': {
    method: 'get',
    path: '/v1/buyer/postCheckout',
    accept: 'application/json',
    inputLocations: {
      'platform': 'query',
    },
    inputCodec: t.exact(t.intersection([
      t.type({
        'platform': CodegenEnumClosed({
          'web': null,
          'ios': null,
          'android': null,
        }, 'platform'),
      }),
      t.partial({
        
      })
    ]), 'GET /v1/buyer/postCheckout Input Codec'),
    responseCodec: t.exact(t.intersection([t.type({
      'content': t.array(DashboardContentItem),
    }), t.partial({
      /** If true, the buyer needs to submit their verification form */
      'buyerNeedsVerification': t.boolean,
      /** If true, ops has verified the buyer and the order(s) have been sent to the seller. */
      'buyerVerified': t.boolean,
    })])),
    responseCodecs: {
      200: t.exact(t.intersection([t.type({
        'content': t.array(DashboardContentItem),
      }), t.partial({
        /** If true, the buyer needs to submit their verification form */
        'buyerNeedsVerification': t.boolean,
        /** If true, ops has verified the buyer and the order(s) have been sent to the seller. */
        'buyerVerified': t.boolean,
      })])),
    },
    
    security: [{"MableOauth2":["admin","buyer"]},{"NoAuth":[]}],
  },
  'GET /v1/buyer/partnerApprovedSellers': {
    method: 'get',
    path: '/v1/buyer/partnerApprovedSellers',
    accept: 'application/json',
    inputLocations: {
      
    },
    inputCodec: t.exact(t.intersection([
      t.type({
        
      }),
      t.partial({
        
      })
    ]), 'GET /v1/buyer/partnerApprovedSellers Input Codec'),
    responseCodec: t.exact(t.intersection([t.type({
      'sellers': t.array(SellerDisplayInfo),
    }), t.partial({
      
    })])),
    responseCodecs: {
      200: t.exact(t.intersection([t.type({
        'sellers': t.array(SellerDisplayInfo),
      }), t.partial({
        
      })])),
    },
    
    security: [{"MableOauth2":["admin","buyer"]},{"NoAuth":[]}],
  },
  'GET /v1/buyer/reorder': {
    method: 'get',
    path: '/v1/buyer/reorder',
    accept: 'application/json',
    inputLocations: {
      
    },
    inputCodec: t.exact(t.intersection([
      t.type({
        
      }),
      t.partial({
        
      })
    ]), 'GET /v1/buyer/reorder Input Codec'),
    responseCodec: t.exact(t.intersection([t.type({
      'sellers': t.array(SellerReorderInfo),
    }), t.partial({
      
    })])),
    responseCodecs: {
      200: t.exact(t.intersection([t.type({
        'sellers': t.array(SellerReorderInfo),
      }), t.partial({
        
      })])),
    },
    
    security: [{"MableOauth2":["buyer"]}],
  },
  'PATCH /v1/buyer/reorder/productVariants': {
    method: 'patch',
    path: '/v1/buyer/reorder/productVariants',
    accept: 'undefined',
    inputLocations: {
      'body': 'body',
    },
    inputCodec: t.exact(t.intersection([
      t.type({
        'body': t.exact(t.intersection([t.type({
          
        }), t.partial({
          'addVariants': t.array(t.number),
          'removeVariants': t.array(t.number),
        })]), 'body'),
      }),
      t.partial({
        
      })
    ]), 'PATCH /v1/buyer/reorder/productVariants Input Codec'),
    responseCodec: t.void,
    responseCodecs: {
      200: undefined,
    },
    
    security: [{"MableOauth2":["admin","buyer"]}],
  },
  'GET /v1/buyerLocations': {
    method: 'get',
    path: '/v1/buyerLocations',
    accept: 'application/json',
    inputLocations: {
      'page': 'query',
      'pageSize': 'query',
    },
    inputCodec: t.exact(t.intersection([
      t.type({
        
      }),
      t.partial({
        /** Page */
        'page': StringNumberCodec,
        /** Number of results per page */
        'pageSize': StringNumberCodec,
      })
    ]), 'GET /v1/buyerLocations Input Codec'),
    responseCodec: t.exact(t.intersection([t.type({
      'buyerLocations': t.array(BuyerLocation),
    }), t.partial({
      
    })])),
    responseCodecs: {
      200: t.exact(t.intersection([t.type({
        'buyerLocations': t.array(BuyerLocation),
      }), t.partial({
        
      })])),
    },
    
    security: [{"MableOauth2":["admin","buyer"]}],
  },
  'DELETE /v1/buyerLocations/{buyerLocationId}/addresses/{addressId}': {
    method: 'delete',
    path: '/v1/buyerLocations/{buyerLocationId}/addresses/{addressId}',
    accept: 'undefined',
    inputLocations: {
      'buyerLocationId': 'path',
      'addressId': 'path',
    },
    inputCodec: t.exact(t.intersection([
      t.type({
        'buyerLocationId': URLStringCodec,
        'addressId': StringNumberCodec,
      }),
      t.partial({
        
      })
    ]), 'DELETE /v1/buyerLocations/{buyerLocationId}/addresses/{addressId} Input Codec'),
    responseCodec: t.void,
    responseCodecs: {
      204: undefined,
    },
    
    security: [{"MableOauth2":["admin","buyer"]}],
  },
  'GET /v1/geolocate': {
    method: 'get',
    path: '/v1/geolocate',
    accept: 'application/json',
    inputLocations: {
      'X-Forwarded-For': 'header',
    },
    inputCodec: t.exact(t.intersection([
      t.type({
        
      }),
      t.partial({
        /** Our load balancer will set this automatically, so you probably shouldn't set it explicitly. */
        'X-Forwarded-For': t.string,
      })
    ]), 'GET /v1/geolocate Input Codec'),
    responseCodec: t.exact(t.intersection([t.type({
      
    }), t.partial({
      /** Matches the keys of `unauthHomepage`'s `popularByRegionContent` object. */
      'regionSlug': t.string,
    })])),
    responseCodecs: {
      200: t.exact(t.intersection([t.type({
        
      }), t.partial({
        /** Matches the keys of `unauthHomepage`'s `popularByRegionContent` object. */
        'regionSlug': t.string,
      })])),
    },
    
    security: [{"NoAuth":[]}],
  },
  'GET /v1/admin/omnisearch': {
    method: 'get',
    path: '/v1/admin/omnisearch',
    accept: 'application/json',
    inputLocations: {
      'commands': 'query',
      'query': 'query',
      'pageSize': 'query',
      'includeFullObject': 'query',
    },
    inputCodec: t.exact(t.intersection([
      t.type({
        /** The part before the colon */
        'commands': StringArrayCodec(URLStringCodec),
        /** The part after the colon */
        'query': URLStringCodec,
      }),
      t.partial({
        /** Optional page size for each action type. Default 5 */
        'pageSize': StringNumberCodec,
        /** If true, the a full object will be returned in results for certain searches (e.g. a full Buyer object) */
        'includeFullObject': StringBooleanCodec,
      })
    ]), 'GET /v1/admin/omnisearch Input Codec'),
    responseCodec: t.exact(t.intersection([t.type({
      'results': t.array(OmnisearchResult),
    }), t.partial({
      
    })])),
    responseCodecs: {
      200: t.exact(t.intersection([t.type({
        'results': t.array(OmnisearchResult),
      }), t.partial({
        
      })])),
    },
    
    security: [{"MableOauth2":["admin","dataworker"]}],
  },
  'GET /v1/admin/messagingModeration': {
    method: 'get',
    path: '/v1/admin/messagingModeration',
    accept: 'application/json',
    inputLocations: {
      
    },
    inputCodec: t.exact(t.intersection([
      t.type({
        
      }),
      t.partial({
        
      })
    ]), 'GET /v1/admin/messagingModeration Input Codec'),
    responseCodec: t.exact(t.intersection([t.type({
      'moderationLists': t.array(ModerationList),
    }), t.partial({
      
    })])),
    responseCodecs: {
      200: t.exact(t.intersection([t.type({
        'moderationLists': t.array(ModerationList),
      }), t.partial({
        
      })])),
    },
    
    security: [{"MableOauth2":["admin"]}],
  },
  'POST /v1/admin/messagingModeration': {
    method: 'post',
    path: '/v1/admin/messagingModeration',
    accept: 'application/json',
    inputLocations: {
      'body': 'body',
    },
    inputCodec: t.exact(t.intersection([
      t.type({
        'body': t.exact(t.intersection([t.type({
          'name': t.string,
          'tokens': t.array(t.string),
        }), t.partial({
          
        })]), 'body'),
      }),
      t.partial({
        
      })
    ]), 'POST /v1/admin/messagingModeration Input Codec'),
    responseCodec: ModerationList,
    responseCodecs: {
      200: ModerationList,
    },
    
    security: [{"MableOauth2":["admin"]}],
  },
  'GET /v1/admin/messagingModeration/{moderationListId}': {
    method: 'get',
    path: '/v1/admin/messagingModeration/{moderationListId}',
    accept: 'application/json',
    inputLocations: {
      'moderationListId': 'path',
    },
    inputCodec: t.exact(t.intersection([
      t.type({
        'moderationListId': URLStringCodec,
      }),
      t.partial({
        
      })
    ]), 'GET /v1/admin/messagingModeration/{moderationListId} Input Codec'),
    responseCodec: ModerationList,
    responseCodecs: {
      200: ModerationList,
    },
    
    security: [{"MableOauth2":["admin"]}],
  },
  'PUT /v1/admin/messagingModeration/{moderationListId}': {
    method: 'put',
    path: '/v1/admin/messagingModeration/{moderationListId}',
    accept: 'application/json',
    inputLocations: {
      'moderationListId': 'path',
      'body': 'body',
    },
    inputCodec: t.exact(t.intersection([
      t.type({
        'moderationListId': URLStringCodec,
        'body': ModerationList,
      }),
      t.partial({
        
      })
    ]), 'PUT /v1/admin/messagingModeration/{moderationListId} Input Codec'),
    responseCodec: ModerationList,
    responseCodecs: {
      200: ModerationList,
    },
    
    security: [{"MableOauth2":["admin"]}],
  },
  'POST /v1/admin/search/sync': {
    method: 'post',
    path: '/v1/admin/search/sync',
    accept: 'undefined',
    inputLocations: {
      
    },
    inputCodec: t.exact(t.intersection([
      t.type({
        
      }),
      t.partial({
        
      })
    ]), 'POST /v1/admin/search/sync Input Codec'),
    responseCodec: t.void,
    responseCodecs: {
      202: undefined,
      409: undefined,
    },
    
    security: [{"MableOauth2":["admin"]}],
  },
  'POST /v1/admin/createHubspotProperties': {
    method: 'post',
    path: '/v1/admin/createHubspotProperties',
    accept: 'undefined',
    inputLocations: {
      
    },
    inputCodec: t.exact(t.intersection([
      t.type({
        
      }),
      t.partial({
        
      })
    ]), 'POST /v1/admin/createHubspotProperties Input Codec'),
    responseCodec: t.void,
    responseCodecs: {
      202: undefined,
    },
    
    security: [{"MableOauth2":["admin"]}],
  },
  'GET /v1/customers': {
    method: 'get',
    path: '/v1/customers',
    accept: 'application/json',
    inputLocations: {
      'q': 'query',
      'page': 'query',
      'pageSize': 'query',
      'sortBy': 'query',
      'sortOrder': 'query',
      'accountIds': 'query',
    },
    inputCodec: t.exact(t.intersection([
      t.type({
        
      }),
      t.partial({
        /** Text matched against customer names */
        'q': URLStringCodec,
        /** Page */
        'page': StringNumberCodec,
        /** Number of results per page */
        'pageSize': StringNumberCodec,
        'sortBy': CustomerDetailSort,
        'sortOrder': SortOrder,
        /** The account IDs to restrict this search for, mimicing /search */
        'accountIds': StringArrayCodec(StringNumberCodec),
      })
    ]), 'GET /v1/customers Input Codec'),
    responseCodec: t.exact(t.intersection([t.type({
      'totalCount': t.number,
      'sellerType': SellerType,
      'customers': t.array(Customer),
    }), t.partial({
      
    })])),
    responseCodecs: {
      200: t.exact(t.intersection([t.type({
        'totalCount': t.number,
        'sellerType': SellerType,
        'customers': t.array(Customer),
      }), t.partial({
        
      })])),
    },
    
    security: [{"MableOauth2":["seller"]}],
  },
  'GET /v1/customers/{buyerId}': {
    method: 'get',
    path: '/v1/customers/{buyerId}',
    accept: 'application/json',
    inputLocations: {
      'buyerId': 'path',
    },
    inputCodec: t.exact(t.intersection([
      t.type({
        /** The buyer to get the customer information for */
        'buyerId': StringNumberCodec,
      }),
      t.partial({
        
      })
    ]), 'GET /v1/customers/{buyerId} Input Codec'),
    responseCodec: CustomerDetail,
    responseCodecs: {
      200: CustomerDetail,
    },
    
    security: [{"MableOauth2":["seller"]}],
  },
  'PATCH /v1/customers/{buyerId}': {
    method: 'patch',
    path: '/v1/customers/{buyerId}',
    accept: 'application/json',
    inputLocations: {
      'buyerId': 'path',
      'body': 'body',
    },
    inputCodec: t.exact(t.intersection([
      t.type({
        'buyerId': StringNumberCodec,
        'body': SellerCustomerPatch,
      }),
      t.partial({
        
      })
    ]), 'PATCH /v1/customers/{buyerId} Input Codec'),
    responseCodec: CustomerDetail,
    responseCodecs: {
      200: CustomerDetail,
    },
    
    security: [{"MableOauth2":["seller"]}],
  },
  'GET /v1/admin/sellers/{sellerId}/googleSheet': {
    method: 'get',
    path: '/v1/admin/sellers/{sellerId}/googleSheet',
    accept: 'application/json',
    inputLocations: {
      'sellerId': 'path',
      'type': 'query',
    },
    inputCodec: t.exact(t.intersection([
      t.type({
        'sellerId': StringNumberCodec,
      }),
      t.partial({
        'type': CodegenEnumClosed({
          'full': null,
          'variantData': null,
        }, 'type'),
      })
    ]), 'GET /v1/admin/sellers/{sellerId}/googleSheet Input Codec'),
    responseCodec: t.exact(t.intersection([t.type({
      
    }), t.partial({
      'sellerSheets': t.array(SellerSpreadsheet),
    })])),
    responseCodecs: {
      200: t.exact(t.intersection([t.type({
        
      }), t.partial({
        'sellerSheets': t.array(SellerSpreadsheet),
      })])),
    },
    
    security: [{"MableOauth2":["admin"]}],
  },
  'POST /v1/admin/sellers/{sellerId}/googleSheet': {
    method: 'post',
    path: '/v1/admin/sellers/{sellerId}/googleSheet',
    accept: 'application/json',
    inputLocations: {
      'sellerId': 'path',
      'body': 'body',
    },
    inputCodec: t.exact(t.intersection([
      t.type({
        'sellerId': StringNumberCodec,
        'body': t.exact(t.intersection([t.type({
          'type': CodegenEnumClosed({
            'full': null,
            'variantData': null,
          }, 'body'),
        }), t.partial({
          
        })]), 'body'),
      }),
      t.partial({
        
      })
    ]), 'POST /v1/admin/sellers/{sellerId}/googleSheet Input Codec'),
    responseCodec: t.exact(t.intersection([t.type({
      
    }), t.partial({
      'url': t.string,
      'shareSuccess': t.array(t.string),
      'shareError': t.array(t.string),
    })])),
    responseCodecs: {
      201: t.exact(t.intersection([t.type({
        
      }), t.partial({
        'url': t.string,
        'shareSuccess': t.array(t.string),
        'shareError': t.array(t.string),
      })])),
    },
    
    security: [{"MableOauth2":["admin"]}],
  },
  'POST /v1/admin/sellers/{sellerId}/exportHubspotSheet': {
    method: 'post',
    path: '/v1/admin/sellers/{sellerId}/exportHubspotSheet',
    accept: 'undefined',
    inputLocations: {
      'sellerId': 'path',
      'body': 'body',
    },
    inputCodec: t.exact(t.intersection([
      t.type({
        'sellerId': StringNumberCodec,
        'body': t.exact(t.intersection([t.type({
          'type': CodegenEnumClosed({
            'full': null,
            'variantData': null,
          }, 'body'),
        }), t.partial({
          
        })]), 'body'),
      }),
      t.partial({
        
      })
    ]), 'POST /v1/admin/sellers/{sellerId}/exportHubspotSheet Input Codec'),
    responseCodec: t.void,
    responseCodecs: {
      202: undefined,
    },
    
    security: [{"MableOauth2":["admin"]}],
  },
  'POST /v1/admin/accounts/{accountId}/auditLog': {
    method: 'post',
    path: '/v1/admin/accounts/{accountId}/auditLog',
    accept: 'undefined',
    inputLocations: {
      'accountId': 'path',
      'body': 'body',
    },
    inputCodec: t.exact(t.intersection([
      t.type({
        'accountId': StringNumberCodec,
        'body': AccountAuditLogInput,
      }),
      t.partial({
        
      })
    ]), 'POST /v1/admin/accounts/{accountId}/auditLog Input Codec'),
    responseCodec: t.void,
    responseCodecs: {
      201: undefined,
    },
    
    security: [{"MableOauth2":["admin"]}],
  },
  'POST /v1/admin/accounts/{accountId}/writeOff': {
    method: 'post',
    path: '/v1/admin/accounts/{accountId}/writeOff',
    accept: 'undefined',
    inputLocations: {
      'accountId': 'path',
      'body': 'body',
    },
    inputCodec: t.exact(t.intersection([
      t.type({
        'accountId': StringNumberCodec,
        'body': AccountWriteoffInput,
      }),
      t.partial({
        
      })
    ]), 'POST /v1/admin/accounts/{accountId}/writeOff Input Codec'),
    responseCodec: t.void,
    responseCodecs: {
      201: undefined,
    },
    
    security: [{"MableOauth2":["admin"]}],
  },
  'GET /v1/admin/sellers/{sellerId}/auditLog': {
    method: 'get',
    path: '/v1/admin/sellers/{sellerId}/auditLog',
    accept: 'application/json',
    inputLocations: {
      'sellerId': 'path',
      'page': 'query',
      'pageSize': 'query',
    },
    inputCodec: t.exact(t.intersection([
      t.type({
        'sellerId': StringNumberCodec,
      }),
      t.partial({
        /** Page */
        'page': StringNumberCodec,
        /** Number of results per page */
        'pageSize': StringNumberCodec,
      })
    ]), 'GET /v1/admin/sellers/{sellerId}/auditLog Input Codec'),
    responseCodec: t.exact(t.intersection([t.type({
      
    }), t.partial({
      'totalCount': t.number,
      'logs': t.array(SellerAuditLogEntry),
    })])),
    responseCodecs: {
      200: t.exact(t.intersection([t.type({
        
      }), t.partial({
        'totalCount': t.number,
        'logs': t.array(SellerAuditLogEntry),
      })])),
    },
    
    security: [{"MableOauth2":["admin"]}],
  },
  'GET /v1/admin/sellers/{sellerId}/slugs': {
    method: 'get',
    path: '/v1/admin/sellers/{sellerId}/slugs',
    accept: 'application/json',
    inputLocations: {
      'sellerId': 'path',
    },
    inputCodec: t.exact(t.intersection([
      t.type({
        'sellerId': StringNumberCodec,
      }),
      t.partial({
        
      })
    ]), 'GET /v1/admin/sellers/{sellerId}/slugs Input Codec'),
    responseCodec: t.exact(t.intersection([t.type({
      
    }), t.partial({
      'slugs': t.array(Slug),
    })])),
    responseCodecs: {
      200: t.exact(t.intersection([t.type({
        
      }), t.partial({
        'slugs': t.array(Slug),
      })])),
    },
    
    security: [{"MableOauth2":["admin","dataworker"]}],
  },
  'GET /v1/admin/variants/{variantId}/mableSkus': {
    method: 'get',
    path: '/v1/admin/variants/{variantId}/mableSkus',
    accept: 'application/json',
    inputLocations: {
      'variantId': 'path',
    },
    inputCodec: t.exact(t.intersection([
      t.type({
        'variantId': StringNumberCodec,
      }),
      t.partial({
        
      })
    ]), 'GET /v1/admin/variants/{variantId}/mableSkus Input Codec'),
    responseCodec: t.exact(t.intersection([t.type({
      
    }), t.partial({
      'mableSkus': t.array(t.string),
    })])),
    responseCodecs: {
      200: t.exact(t.intersection([t.type({
        
      }), t.partial({
        'mableSkus': t.array(t.string),
      })])),
    },
    
    security: [{"MableOauth2":["admin","dataworker"]}],
  },
  'POST /v1/admin/oneWorldSync/syncRetailUnits': {
    method: 'post',
    path: '/v1/admin/oneWorldSync/syncRetailUnits',
    accept: 'application/json',
    inputLocations: {
      'body': 'body',
    },
    inputCodec: t.exact(t.intersection([
      t.type({
        'body': t.intersection([
          t.exact(t.intersection([t.type({
            'partnerName': t.string,
          }), t.partial({
            
          })]), 'body'),
          withExactlyOneKey(t.intersection([t.type({
            'retailUnitIds': t.array(t.number),
            'sellerId': t.number,
          }), t.partial({
            
          })]), 'body')
        ], 'body'),
      }),
      t.partial({
        
      })
    ]), 'POST /v1/admin/oneWorldSync/syncRetailUnits Input Codec'),
    responseCodec: t.exact(t.intersection([t.type({
      'results': t.array(AdminOneWorldSyncResult),
    }), t.partial({
      
    })])),
    responseCodecs: {
      200: t.exact(t.intersection([t.type({
        'results': t.array(AdminOneWorldSyncResult),
      }), t.partial({
        
      })])),
    },
    
    security: [{"MableOauth2":["admin"]}],
  },
  'POST /v1/admin/oneWorldSync/publishRetailUnits': {
    method: 'post',
    path: '/v1/admin/oneWorldSync/publishRetailUnits',
    accept: 'application/json',
    inputLocations: {
      'body': 'body',
    },
    inputCodec: t.exact(t.intersection([
      t.type({
        'body': t.intersection([
          t.exact(t.intersection([t.type({
            'partnerName': t.string,
          }), t.partial({
            
          })]), 'body'),
          withExactlyOneKey(t.intersection([t.type({
            'retailUnitIds': t.array(t.number),
            'sellerId': t.number,
          }), t.partial({
            
          })]), 'body')
        ], 'body'),
      }),
      t.partial({
        
      })
    ]), 'POST /v1/admin/oneWorldSync/publishRetailUnits Input Codec'),
    responseCodec: t.exact(t.intersection([t.type({
      'results': t.array(AdminOneWorldSyncResult),
    }), t.partial({
      
    })])),
    responseCodecs: {
      200: t.exact(t.intersection([t.type({
        'results': t.array(AdminOneWorldSyncResult),
      }), t.partial({
        
      })])),
    },
    
    security: [{"MableOauth2":["admin"]}],
  },
  'GET /v1/admin/oneWorldSync/status/{retailUnitId}': {
    method: 'get',
    path: '/v1/admin/oneWorldSync/status/{retailUnitId}',
    accept: 'application/json',
    inputLocations: {
      'retailUnitId': 'path',
    },
    inputCodec: t.exact(t.intersection([
      t.type({
        'retailUnitId': StringNumberCodec,
      }),
      t.partial({
        
      })
    ]), 'GET /v1/admin/oneWorldSync/status/{retailUnitId} Input Codec'),
    responseCodec: t.exact(t.intersection([t.type({
      'results': t.array(PackagingOneWorldSyncStatus),
    }), t.partial({
      
    })])),
    responseCodecs: {
      200: t.exact(t.intersection([t.type({
        'results': t.array(PackagingOneWorldSyncStatus),
      }), t.partial({
        
      })])),
    },
    
    security: [{"MableOauth2":["admin","dataworker"]}],
  },
  'POST /v1/admin/oneWorldSync/getProductIntroductionStatus': {
    method: 'post',
    path: '/v1/admin/oneWorldSync/getProductIntroductionStatus',
    accept: 'application/json',
    inputLocations: {
      'body': 'body',
    },
    inputCodec: t.exact(t.intersection([
      t.type({
        'body': t.exact(t.intersection([t.type({
          
        }), t.partial({
          'sellerIds': t.array(t.number),
          'variantIds': t.array(t.number),
        })]), 'body'),
      }),
      t.partial({
        
      })
    ]), 'POST /v1/admin/oneWorldSync/getProductIntroductionStatus Input Codec'),
    responseCodec: t.exact(t.intersection([t.type({
      
    }), t.partial({
      'message': t.string,
    })])),
    responseCodecs: {
      200: t.exact(t.intersection([t.type({
        
      }), t.partial({
        'message': t.string,
      })])),
    },
    
    security: [{"MableOauth2":["admin"]}],
  },
  'POST /v1/admin/oneWorldSync/unpublishRetailUnits': {
    method: 'post',
    path: '/v1/admin/oneWorldSync/unpublishRetailUnits',
    accept: 'undefined',
    inputLocations: {
      'body': 'body',
    },
    inputCodec: t.exact(t.intersection([
      t.type({
        'body': t.exact(t.intersection([t.type({
          'type': OneWorldSyncUnPublishType,
          'retailUnitIds': t.array(t.number),
          'partnerName': t.string,
        }), t.partial({
          
        })]), 'body'),
      }),
      t.partial({
        
      })
    ]), 'POST /v1/admin/oneWorldSync/unpublishRetailUnits Input Codec'),
    responseCodec: t.void,
    responseCodecs: {
      200: undefined,
    },
    
    security: [{"MableOauth2":["admin"]}],
  },
  'GET /v1/quickbooks/authorizationUrl': {
    method: 'get',
    path: '/v1/quickbooks/authorizationUrl',
    accept: 'application/json',
    inputLocations: {
      
    },
    inputCodec: t.exact(t.intersection([
      t.type({
        
      }),
      t.partial({
        
      })
    ]), 'GET /v1/quickbooks/authorizationUrl Input Codec'),
    responseCodec: t.exact(t.intersection([t.type({
      'authorizationUrl': t.string,
    }), t.partial({
      
    })])),
    responseCodecs: {
      200: t.exact(t.intersection([t.type({
        'authorizationUrl': t.string,
      }), t.partial({
        
      })])),
    },
    
    security: [{"MableOauth2":["admin"]}],
  },
  'POST /v1/quickbooks/authorize': {
    method: 'post',
    path: '/v1/quickbooks/authorize',
    accept: 'undefined',
    inputLocations: {
      'body': 'body',
    },
    inputCodec: t.exact(t.intersection([
      t.type({
        'body': t.exact(t.intersection([t.type({
          /** The full URL that you were redirected to after completing the QuickBooks auth flow. */
          'redirectedUrl': t.string,
        }), t.partial({
          'accountId': t.number,
        })]), 'body'),
      }),
      t.partial({
        
      })
    ]), 'POST /v1/quickbooks/authorize Input Codec'),
    responseCodec: t.void,
    responseCodecs: {
      204: undefined,
    },
    
    security: [{"MableOauth2":["admin"]}],
  },
  'POST /v1/admin/quickbooks/bills': {
    method: 'post',
    path: '/v1/admin/quickbooks/bills',
    accept: 'undefined',
    inputLocations: {
      'body': 'body',
    },
    inputCodec: t.exact(t.intersection([
      t.type({
        'body': t.exact(t.intersection([t.type({
          'transactionId': t.number,
          /** The date for the QuickBooks bill to be effeective on. Payment terms (in QuickBooks) will start counting from this date. */
          'date': DateFromISOString,
        }), t.partial({
          /** The amount to create the bill for. Defaults to the transaction's remittanceTotal. */
          'amount': NumberFromString,
          /** The ID of the QuickBooks vendor to make this bill for. Required if we don't already know the vendor ID of the transaction's seller. Will be saved for future use automatically. */
          'quickBooksVendorId': t.string,
        })]), 'body'),
      }),
      t.partial({
        
      })
    ]), 'POST /v1/admin/quickbooks/bills Input Codec'),
    responseCodec: t.void,
    responseCodecs: {
      202: undefined,
    },
    
    security: [{"MableOauth2":["admin"]}],
  },
  'PUT /v1/admin/sellers/{sellerId}/googleSheetImport': {
    method: 'put',
    path: '/v1/admin/sellers/{sellerId}/googleSheetImport',
    accept: 'application/json',
    inputLocations: {
      'sellerId': 'path',
      'deleteMissingProducts': 'query',
      'patch': 'query',
      'resetSalesPrices': 'query',
      'spreadsheetId': 'query',
    },
    inputCodec: t.exact(t.intersection([
      t.type({
        'sellerId': StringNumberCodec,
        'spreadsheetId': StringNumberCodec,
      }),
      t.partial({
        /** If true, mark all products sold by the seller that don't appear in this import as deleted. */
        'deleteMissingProducts': StringBooleanCodec,
        /** If true, the import will be done with a PATCH strategy. */
        'patch': StringBooleanCodec,
        /** If true, remove any sale pricing. */
        'resetSalesPrices': StringBooleanCodec,
      })
    ]), 'PUT /v1/admin/sellers/{sellerId}/googleSheetImport Input Codec'),
    responseCodec: t.exact(t.intersection([t.type({
      'spreadsheet': SellerSpreadsheet,
    }), t.partial({
      'products': t.array(Product),
      'variantIds': t.array(t.number),
    })])),
    responseCodecs: {
      200: t.exact(t.intersection([t.type({
        'spreadsheet': SellerSpreadsheet,
      }), t.partial({
        'products': t.array(Product),
        'variantIds': t.array(t.number),
      })])),
    },
    
    security: [{"MableOauth2":["admin"]}],
  },
  'GET /v1/alerts': {
    method: 'get',
    path: '/v1/alerts',
    accept: 'application/json',
    inputLocations: {
      
    },
    inputCodec: t.exact(t.intersection([
      t.type({
        
      }),
      t.partial({
        
      })
    ]), 'GET /v1/alerts Input Codec'),
    responseCodec: t.exact(t.intersection([t.type({
      
    }), t.partial({
      'siteAlerts': t.array(AlertDetail),
      'cartAlerts': t.array(AlertDetail),
      'checkoutAlerts': t.array(AlertDetail),
    })])),
    responseCodecs: {
      200: t.exact(t.intersection([t.type({
        
      }), t.partial({
        'siteAlerts': t.array(AlertDetail),
        'cartAlerts': t.array(AlertDetail),
        'checkoutAlerts': t.array(AlertDetail),
      })])),
    },
    
    security: [{"MableOauth2":["admin","buyer","seller"]},{"NoAuth":[]}],
  },
  'GET /v1/allergens': {
    method: 'get',
    path: '/v1/allergens',
    accept: 'application/json',
    inputLocations: {
      'ids': 'query',
      'names': 'query',
      'isSuggestionGenerated': 'query',
    },
    inputCodec: t.exact(t.intersection([
      t.type({
        
      }),
      t.partial({
        /** If you want specific allergens by ID */
        'ids': StringArrayCodec(StringNumberCodec),
        /** If you want specific allergens by name (case insensitive) */
        'names': StringArrayCodec(URLStringCodec),
        /** Filter by if it was a suggestion or not */
        'isSuggestionGenerated': StringBooleanCodec,
      })
    ]), 'GET /v1/allergens Input Codec'),
    responseCodec: t.exact(t.intersection([t.type({
      
    }), t.partial({
      'allergens': t.array(Allergen),
    })])),
    responseCodecs: {
      200: t.exact(t.intersection([t.type({
        
      }), t.partial({
        'allergens': t.array(Allergen),
      })])),
    },
    
    security: [{"MableOauth2":["admin","dataworker","seller"]}],
  },
  'POST /v1/admin/sellers/calculateReputationScores': {
    method: 'post',
    path: '/v1/admin/sellers/calculateReputationScores',
    accept: 'undefined',
    inputLocations: {
      
    },
    inputCodec: t.exact(t.intersection([
      t.type({
        
      }),
      t.partial({
        
      })
    ]), 'POST /v1/admin/sellers/calculateReputationScores Input Codec'),
    responseCodec: t.void,
    responseCodecs: {
      200: undefined,
    },
    
    security: [{"MableOauth2":["admin"]}],
  },
  'PUT /v1/admin/sellers/shippingProgramImport': {
    method: 'put',
    path: '/v1/admin/sellers/shippingProgramImport',
    accept: 'application/json',
    inputLocations: {
      'csvInput': 'body',
    },
    inputCodec: t.exact(t.intersection([
      t.type({
        'csvInput': CSVInput,
      }),
      t.partial({
        
      })
    ]), 'PUT /v1/admin/sellers/shippingProgramImport Input Codec'),
    responseCodec: t.exact(t.intersection([t.type({
      'totalInvited': t.number,
      'totalOptedIn': t.number,
      'totalOptedOut': t.number,
      'slugsWithNoMatchingSeller': t.array(t.string),
      'companiesWithNoSlug': t.array(t.string),
    }), t.partial({
      
    })])),
    responseCodecs: {
      200: t.exact(t.intersection([t.type({
        'totalInvited': t.number,
        'totalOptedIn': t.number,
        'totalOptedOut': t.number,
        'slugsWithNoMatchingSeller': t.array(t.string),
        'companiesWithNoSlug': t.array(t.string),
      }), t.partial({
        
      })])),
    },
    
    security: [{"MableOauth2":["admin",""]}],
  },
  'GET /v1/buyer/navigation': {
    method: 'get',
    path: '/v1/buyer/navigation',
    accept: 'application/json',
    inputLocations: {
      
    },
    inputCodec: t.exact(t.intersection([
      t.type({
        
      }),
      t.partial({
        
      })
    ]), 'GET /v1/buyer/navigation Input Codec'),
    responseCodec: t.exact(t.intersection([t.type({
      'marketplaceNavTree': t.array(NavTreeNode),
    }), t.partial({
      
    })])),
    responseCodecs: {
      200: t.exact(t.intersection([t.type({
        'marketplaceNavTree': t.array(NavTreeNode),
      }), t.partial({
        
      })])),
    },
    
    security: [{"NoAuth":[]}],
  },
  'GET /v1/buyer/referralCode/{referralCode}': {
    method: 'get',
    path: '/v1/buyer/referralCode/{referralCode}',
    accept: 'application/json',
    inputLocations: {
      'referralCode': 'path',
    },
    inputCodec: t.exact(t.intersection([
      t.type({
        'referralCode': URLStringCodec,
      }),
      t.partial({
        
      })
    ]), 'GET /v1/buyer/referralCode/{referralCode} Input Codec'),
    responseCodec: t.exact(t.intersection([t.type({
      
    }), t.partial({
      'buyerName': t.string,
    })])),
    responseCodecs: {
      200: t.exact(t.intersection([t.type({
        
      }), t.partial({
        'buyerName': t.string,
      })])),
    },
    
    security: [{"NoAuth":[]}],
  },
  'PATCH /v1/buyers/{buyerIdOrMe}': {
    method: 'patch',
    path: '/v1/buyers/{buyerIdOrMe}',
    accept: 'undefined',
    inputLocations: {
      'buyerIdOrMe': 'path',
      'buyerPatch': 'body',
    },
    inputCodec: t.exact(t.intersection([
      t.type({
        /** The buyer id to manipulate if your an admin or @me if you want to patch yourself */
        'buyerIdOrMe': URLStringCodec,
        'buyerPatch': BuyerPatch,
      }),
      t.partial({
        
      })
    ]), 'PATCH /v1/buyers/{buyerIdOrMe} Input Codec'),
    responseCodec: t.void,
    responseCodecs: {
      200: undefined,
    },
    
    security: [{"MableOauth2":["buyer","admin"]}],
  },
  'GET /v1/buyers/@me/pricingRegions': {
    method: 'get',
    path: '/v1/buyers/@me/pricingRegions',
    accept: 'application/json',
    inputLocations: {
      
    },
    inputCodec: t.exact(t.intersection([
      t.type({
        
      }),
      t.partial({
        
      })
    ]), 'GET /v1/buyers/@me/pricingRegions Input Codec'),
    responseCodec: t.exact(t.intersection([t.type({
      'pricingRegions': t.array(PricingRegion),
    }), t.partial({
      
    })])),
    responseCodecs: {
      200: t.exact(t.intersection([t.type({
        'pricingRegions': t.array(PricingRegion),
      }), t.partial({
        
      })])),
    },
    
    security: [{"MableOauth2":["buyer"]}],
  },
  'POST /v1/buyers/{buyerIdOrMe}/certificateSignedUrl': {
    method: 'post',
    path: '/v1/buyers/{buyerIdOrMe}/certificateSignedUrl',
    accept: 'application/json',
    inputLocations: {
      'buyerIdOrMe': 'path',
      'params': 'body',
    },
    inputCodec: t.exact(t.intersection([
      t.type({
        /** The buyer id to manipulate if your an admin or @me */
        'buyerIdOrMe': URLStringCodec,
        'params': t.exact(t.intersection([t.type({
          'extension': t.string,
        }), t.partial({
          
        })]), 'params'),
      }),
      t.partial({
        
      })
    ]), 'POST /v1/buyers/{buyerIdOrMe}/certificateSignedUrl Input Codec'),
    responseCodec: t.exact(t.intersection([t.type({
      
    }), t.partial({
      'signedUrl': t.string,
      's3Key': t.string,
    })])),
    responseCodecs: {
      200: t.exact(t.intersection([t.type({
        
      }), t.partial({
        'signedUrl': t.string,
        's3Key': t.string,
      })])),
    },
    
    security: [{"MableOauth2":["buyer","admin"]}],
  },
  'GET /v1/buyers/{buyerIdOrMe}/shippingSavings': {
    method: 'get',
    path: '/v1/buyers/{buyerIdOrMe}/shippingSavings',
    accept: 'application/json',
    inputLocations: {
      'buyerIdOrMe': 'path',
    },
    inputCodec: t.exact(t.intersection([
      t.type({
        /** The buyer id to query or @me if you want yourself */
        'buyerIdOrMe': URLStringCodec,
      }),
      t.partial({
        
      })
    ]), 'GET /v1/buyers/{buyerIdOrMe}/shippingSavings Input Codec'),
    responseCodec: ShippingSavings,
    responseCodecs: {
      200: ShippingSavings,
    },
    
    security: [{"MableOauth2":["buyer","admin"]}],
  },
  'POST /v1/buyers/@me/partner/{partnerName}/regionalPricing': {
    method: 'post',
    path: '/v1/buyers/@me/partner/{partnerName}/regionalPricing',
    accept: 'application/json',
    inputLocations: {
      'partnerName': 'path',
      'productId': 'query',
      'categoryId': 'query',
      'sellerId': 'query',
    },
    inputCodec: t.exact(t.intersection([
      t.type({
        /** The name of the parter whose pricing you want to export. */
        'partnerName': URLStringCodec,
      }),
      t.partial({
        'productId': StringNumberCodec,
        /** A category this buyer has permission to export pricing for, like an order guide or an APL. */
        'categoryId': StringNumberCodec,
        'sellerId': StringNumberCodec,
      })
    ]), 'POST /v1/buyers/@me/partner/{partnerName}/regionalPricing Input Codec'),
    responseCodec: t.exact(t.intersection([t.type({
      
    }), t.partial({
      'csvString': t.string,
    })])),
    responseCodecs: {
      200: t.exact(t.intersection([t.type({
        
      }), t.partial({
        'csvString': t.string,
      })])),
    },
    
    security: [{"MableOauth2":["buyer"]}],
  },
  'POST /v1/dismissShippingSavingsNotitification': {
    method: 'post',
    path: '/v1/dismissShippingSavingsNotitification',
    accept: 'undefined',
    inputLocations: {
      
    },
    inputCodec: t.exact(t.intersection([
      t.type({
        
      }),
      t.partial({
        
      })
    ]), 'POST /v1/dismissShippingSavingsNotitification Input Codec'),
    responseCodec: t.void,
    responseCodecs: {
      204: undefined,
    },
    
    security: [{"MableOauth2":["admin","seller","buyer"]}],
  },
  'PATCH /v1/users/{userIdOrMe}': {
    method: 'patch',
    path: '/v1/users/{userIdOrMe}',
    accept: 'undefined',
    inputLocations: {
      'userIdOrMe': 'path',
      'body': 'body',
    },
    inputCodec: t.exact(t.intersection([
      t.type({
        'userIdOrMe': URLStringCodec,
        'body': t.exact(t.intersection([t.type({
          'pricingRegionId': t.string,
        }), t.partial({
          
        })]), 'body'),
      }),
      t.partial({
        
      })
    ]), 'PATCH /v1/users/{userIdOrMe} Input Codec'),
    responseCodec: t.void,
    responseCodecs: {
      204: undefined,
    },
    
    security: [{"MableOauth2":["admin","buyer"]}],
  },
  'GET /v1/users/{userIdOrMe}/list/{identifier}': {
    method: 'get',
    path: '/v1/users/{userIdOrMe}/list/{identifier}',
    accept: 'application/json',
    inputLocations: {
      'userIdOrMe': 'path',
      'identifier': 'path',
      'appliedFilters': 'query',
      'sortMethodId': 'query',
      'page': 'query',
      'pageSize': 'query',
    },
    inputCodec: t.exact(t.intersection([
      t.type({
        'userIdOrMe': URLStringCodec,
        'identifier': ListIdentifier,
      }),
      t.partial({
        /** Encoded string of applied filters */
        'appliedFilters': URLStringCodec,
        /** How variant results should be sorted. Acceptable searchMethods are returned in the search response. Defaults to 'recommended' */
        'sortMethodId': URLStringCodec,
        /** Current page of variants if requesting a non-parent category */
        'page': StringNumberCodec,
        /** Variants per page if requesting a non-parent category */
        'pageSize': StringNumberCodec,
      })
    ]), 'GET /v1/users/{userIdOrMe}/list/{identifier} Input Codec'),
    responseCodec: FullCategory,
    responseCodecs: {
      200: FullCategory,
    },
    
    security: [{"MableOauth2":["buyer"]}],
  },
  'PATCH /v1/users/{userIdOrMe}/list/{identifier}': {
    method: 'patch',
    path: '/v1/users/{userIdOrMe}/list/{identifier}',
    accept: 'undefined',
    inputLocations: {
      'userIdOrMe': 'path',
      'identifier': 'path',
      'changes': 'body',
    },
    inputCodec: t.exact(t.intersection([
      t.type({
        'userIdOrMe': URLStringCodec,
        'identifier': ListIdentifier,
        'changes': t.exact(t.intersection([t.type({
          
        }), t.partial({
          'productVariantChanges': ListProductVariantChanges,
        })]), 'changes'),
      }),
      t.partial({
        
      })
    ]), 'PATCH /v1/users/{userIdOrMe}/list/{identifier} Input Codec'),
    responseCodec: t.void,
    responseCodecs: {
      200: undefined,
    },
    
    security: [{"MableOauth2":["buyer"]}],
  },
  'GET /v1/admin/reports': {
    method: 'get',
    path: '/v1/admin/reports',
    accept: 'application/json',
    inputLocations: {
      
    },
    inputCodec: t.exact(t.intersection([
      t.type({
        
      }),
      t.partial({
        
      })
    ]), 'GET /v1/admin/reports Input Codec'),
    responseCodec: t.exact(t.intersection([t.type({
      'definitions': t.array(AdminReportDefinition),
    }), t.partial({
      
    })])),
    responseCodecs: {
      200: t.exact(t.intersection([t.type({
        'definitions': t.array(AdminReportDefinition),
      }), t.partial({
        
      })])),
    },
    
    security: [{"MableOauth2":["admin"]}],
  },
  'POST /v1/admin/reports/{reportId}': {
    method: 'post',
    path: '/v1/admin/reports/{reportId}',
    accept: 'application/json',
    inputLocations: {
      'reportId': 'path',
      'body': 'body',
    },
    inputCodec: t.exact(t.intersection([
      t.type({
        'reportId': URLStringCodec,
        'body': t.exact(t.intersection([t.type({
          'parameters': t.record(t.string, t.string),
          'resultType': CodegenEnumOpen({
            'display': null,
            'csv': null,
          }, 'body'),
        }), t.partial({
          
        })]), 'body'),
      }),
      t.partial({
        
      })
    ]), 'POST /v1/admin/reports/{reportId} Input Codec'),
    responseCodec: t.exact(t.intersection([t.type({
      'report': AdminReport,
      'definition': AdminReportDefinition,
    }), t.partial({
      
    })])),
    responseCodecs: {
      200: t.exact(t.intersection([t.type({
        'report': AdminReport,
        'definition': AdminReportDefinition,
      }), t.partial({
        
      })])),
    },
    
    security: [{"MableOauth2":["admin"]}],
  },
  'POST /v1/sellers/@me/emailCampaigns': {
    method: 'post',
    path: '/v1/sellers/@me/emailCampaigns',
    accept: 'undefined',
    inputLocations: {
      'emailCampaignInput': 'body',
    },
    inputCodec: t.exact(t.intersection([
      t.type({
        'emailCampaignInput': SellerEmailCampaignInput,
      }),
      t.partial({
        
      })
    ]), 'POST /v1/sellers/@me/emailCampaigns Input Codec'),
    responseCodec: t.void,
    responseCodecs: {
      201: undefined,
    },
    
    security: [{"MableOauth2":["admin","seller"]}],
  },
  'GET /v1/sellers/@me/emailCampaignsDashboard': {
    method: 'get',
    path: '/v1/sellers/@me/emailCampaignsDashboard',
    accept: 'application/json',
    inputLocations: {
      
    },
    inputCodec: t.exact(t.intersection([
      t.type({
        
      }),
      t.partial({
        
      })
    ]), 'GET /v1/sellers/@me/emailCampaignsDashboard Input Codec'),
    responseCodec: t.exact(t.intersection([t.type({
      
    }), t.partial({
      'cards': t.array(DashboardContentItem),
    })])),
    responseCodecs: {
      200: t.exact(t.intersection([t.type({
        
      }), t.partial({
        'cards': t.array(DashboardContentItem),
      })])),
    },
    
    security: [{"MableOauth2":["admin","seller"]}],
  },
  'GET /v1/sellers/@me/shopify/status': {
    method: 'get',
    path: '/v1/sellers/@me/shopify/status',
    accept: 'application/json',
    inputLocations: {
      
    },
    inputCodec: t.exact(t.intersection([
      t.type({
        
      }),
      t.partial({
        
      })
    ]), 'GET /v1/sellers/@me/shopify/status Input Codec'),
    responseCodec: ShopifyAuthorization,
    responseCodecs: {
      200: ShopifyAuthorization,
    },
    
    security: [{"MableOauth2":["seller","admin"]}],
  },
  'POST /v1/sellers/@me/shopify/status': {
    method: 'post',
    path: '/v1/sellers/@me/shopify/status',
    accept: 'application/json',
    inputLocations: {
      'body': 'body',
    },
    inputCodec: t.exact(t.intersection([
      t.type({
        'body': t.exact(t.intersection([t.type({
          
        }), t.partial({
          'syncOrdersToShopify': t.boolean,
          'commissionRepresentation': ShopifyOrderCommissionRepresentation,
          'lineItemCountStyle': ShopifyOrderLineItemCountStyle,
          'orderSyncStyle': ShopifyOrderState,
          'customerStyle': ShopifyCustomerStyle,
          'shippingSyncStyle': ShopifyShippingSyncStyle,
        })]), 'body'),
      }),
      t.partial({
        
      })
    ]), 'POST /v1/sellers/@me/shopify/status Input Codec'),
    responseCodec: t.exact(t.intersection([t.type({
      
    }), t.partial({
      'authUrl': t.string,
    })])),
    responseCodecs: {
      200: t.exact(t.intersection([t.type({
        
      }), t.partial({
        'authUrl': t.string,
      })])),
      404: undefined,
    },
    
    security: [{"MableOauth2":["seller","admin"]}],
  },
  'POST /v1/sellers/@me/shopify/auth': {
    method: 'post',
    path: '/v1/sellers/@me/shopify/auth',
    accept: 'application/json',
    inputLocations: {
      'shopifyShop': 'query',
    },
    inputCodec: t.exact(t.intersection([
      t.type({
        /** The shopify shop name to link the seller to */
        'shopifyShop': URLStringCodec,
      }),
      t.partial({
        
      })
    ]), 'POST /v1/sellers/@me/shopify/auth Input Codec'),
    responseCodec: t.exact(t.intersection([t.type({
      'authUrl': t.string,
    }), t.partial({
      
    })])),
    responseCodecs: {
      200: t.exact(t.intersection([t.type({
        'authUrl': t.string,
      }), t.partial({
        
      })])),
    },
    
    security: [{"MableOauth2":["seller","admin"]}],
  },
  'DELETE /v1/sellers/@me/shopify/auth': {
    method: 'delete',
    path: '/v1/sellers/@me/shopify/auth',
    accept: 'undefined',
    inputLocations: {
      
    },
    inputCodec: t.exact(t.intersection([
      t.type({
        
      }),
      t.partial({
        
      })
    ]), 'DELETE /v1/sellers/@me/shopify/auth Input Codec'),
    responseCodec: t.void,
    responseCodecs: {
      200: undefined,
      404: undefined,
    },
    
    security: [{"MableOauth2":["seller","admin"]}],
  },
  'POST /v1/sellers/@me/shopify/finish': {
    method: 'post',
    path: '/v1/sellers/@me/shopify/finish',
    accept: 'undefined',
    inputLocations: {
      'shopifyShop': 'query',
      'nonce': 'query',
    },
    inputCodec: t.exact(t.intersection([
      t.type({
        /** the shopify shop name that is partially linked */
        'shopifyShop': URLStringCodec,
        /** the randomized nonce associated with the partially-linked shop */
        'nonce': URLStringCodec,
      }),
      t.partial({
        
      })
    ]), 'POST /v1/sellers/@me/shopify/finish Input Codec'),
    responseCodec: t.void,
    responseCodecs: {
      204: undefined,
    },
    
    security: [{"MableOauth2":["seller","admin"]}],
  },
  'POST /v1/sellers/@me/shopify/testOrder': {
    method: 'post',
    path: '/v1/sellers/@me/shopify/testOrder',
    accept: 'application/json',
    inputLocations: {
      
    },
    inputCodec: t.exact(t.intersection([
      t.type({
        
      }),
      t.partial({
        
      })
    ]), 'POST /v1/sellers/@me/shopify/testOrder Input Codec'),
    responseCodec: t.exact(t.intersection([t.type({
      'shopifyOrderUrl': t.string,
    }), t.partial({
      
    })])),
    responseCodecs: {
      201: t.exact(t.intersection([t.type({
        'shopifyOrderUrl': t.string,
      }), t.partial({
        
      })])),
    },
    
    security: [{"MableOauth2":["seller","admin"]}],
  },
  'GET /v1/sellers/@me/shipFromAddress': {
    method: 'get',
    path: '/v1/sellers/@me/shipFromAddress',
    accept: 'application/json',
    inputLocations: {
      
    },
    inputCodec: t.exact(t.intersection([
      t.type({
        
      }),
      t.partial({
        
      })
    ]), 'GET /v1/sellers/@me/shipFromAddress Input Codec'),
    responseCodec: t.exact(t.intersection([t.type({
      
    }), t.partial({
      'shipFromAddress': Address,
    })])),
    responseCodecs: {
      200: t.exact(t.intersection([t.type({
        
      }), t.partial({
        'shipFromAddress': Address,
      })])),
    },
    
    security: [{"MableOauth2":["seller"]}],
  },
  'DELETE /v1/admin/{sellerId}/priceReplacementRules': {
    method: 'delete',
    path: '/v1/admin/{sellerId}/priceReplacementRules',
    accept: 'undefined',
    inputLocations: {
      'sellerId': 'path',
      'input': 'body',
    },
    inputCodec: t.exact(t.intersection([
      t.type({
        'sellerId': StringNumberCodec,
        'input': t.exact(t.intersection([t.type({
          'variantSpecification': PricingVariantSpecification,
        }), t.partial({
          
        })]), 'input'),
      }),
      t.partial({
        
      })
    ]), 'DELETE /v1/admin/{sellerId}/priceReplacementRules Input Codec'),
    responseCodec: t.void,
    responseCodecs: {
      204: undefined,
    },
    
    security: [{"MableOauth2":["admin"]}],
  },
  'POST /v1/admin/{sellerId}/priceReplacementRules': {
    method: 'post',
    path: '/v1/admin/{sellerId}/priceReplacementRules',
    accept: 'undefined',
    inputLocations: {
      'sellerId': 'path',
      'input': 'body',
    },
    inputCodec: t.exact(t.intersection([
      t.type({
        'sellerId': StringNumberCodec,
        'input': t.exact(t.intersection([t.type({
          'variantSpecification': PricingVariantSpecification,
          /** The percentage to set the discounted price. If you send "0.8" a $100 product will be changed to cost $80. Values between 0 & 1 are accepted. */
          'priceFactor': NumberFromString,
        }), t.partial({
          
        })]), 'input'),
      }),
      t.partial({
        
      })
    ]), 'POST /v1/admin/{sellerId}/priceReplacementRules Input Codec'),
    responseCodec: t.void,
    responseCodecs: {
      204: undefined,
    },
    
    security: [{"MableOauth2":["admin"]}],
  },
  'GET /v1/admin/sellers/{sellerId}/productPackagingCSV': {
    method: 'get',
    path: '/v1/admin/sellers/{sellerId}/productPackagingCSV',
    accept: 'application/json',
    inputLocations: {
      'sellerId': 'path',
    },
    inputCodec: t.exact(t.intersection([
      t.type({
        'sellerId': StringNumberCodec,
      }),
      t.partial({
        
      })
    ]), 'GET /v1/admin/sellers/{sellerId}/productPackagingCSV Input Codec'),
    responseCodec: t.exact(t.intersection([t.type({
      'csv': t.string,
    }), t.partial({
      
    })])),
    responseCodecs: {
      200: t.exact(t.intersection([t.type({
        'csv': t.string,
      }), t.partial({
        
      })])),
    },
    
    security: [{"MableOauth2":["admin","dataworker"]}],
  },
  'POST /v1/admin/sellers/{sellerId}/productPackagingCSV': {
    method: 'post',
    path: '/v1/admin/sellers/{sellerId}/productPackagingCSV',
    accept: 'undefined',
    inputLocations: {
      'sellerId': 'path',
      'body': 'body',
    },
    inputCodec: t.exact(t.intersection([
      t.type({
        'sellerId': StringNumberCodec,
        'body': t.exact(t.intersection([t.type({
          'csv': t.string,
        }), t.partial({
          
        })]), 'body'),
      }),
      t.partial({
        
      })
    ]), 'POST /v1/admin/sellers/{sellerId}/productPackagingCSV Input Codec'),
    responseCodec: t.void,
    responseCodecs: {
      204: undefined,
    },
    
    security: [{"MableOauth2":["admin","dataworker"]}],
  },
  'POST /v1/orders/{orderId}/shopifySync': {
    method: 'post',
    path: '/v1/orders/{orderId}/shopifySync',
    accept: 'undefined',
    inputLocations: {
      'orderId': 'path',
    },
    inputCodec: t.exact(t.intersection([
      t.type({
        'orderId': StringNumberCodec,
      }),
      t.partial({
        
      })
    ]), 'POST /v1/orders/{orderId}/shopifySync Input Codec'),
    responseCodec: t.void,
    responseCodecs: {
      204: undefined,
      409: undefined,
    },
    
    security: [{"MableOauth2":["seller","admin"]}],
  },
  'GET /v1/sellers/{sellerIdOrMe}/shopify/productMatch': {
    method: 'get',
    path: '/v1/sellers/{sellerIdOrMe}/shopify/productMatch',
    accept: 'application/json',
    inputLocations: {
      'sellerIdOrMe': 'path',
      'automaticallyMatch': 'query',
    },
    inputCodec: t.exact(t.intersection([
      t.type({
        /** The seller id to query or @me if you want yourself */
        'sellerIdOrMe': URLStringCodec,
      }),
      t.partial({
        /** Find automatic matches by SKU */
        'automaticallyMatch': StringBooleanCodec,
      })
    ]), 'GET /v1/sellers/{sellerIdOrMe}/shopify/productMatch Input Codec'),
    responseCodec: t.exact(t.intersection([t.type({
      /** All the Shopify variants that belong to the given seller */
      'shopifyVariants': t.array(ShopifyProductMatchVariant),
      /** All the Mable variants that belong to the given seller */
      'mableVariants': t.array(ProductVariantDisplayInfoWithShopify),
      /** The suggested automatic matches. Empty if automaticallyMatch is not sent */
      'automaticMatches': t.array(ShopifyProductMatch),
    }), t.partial({
      
    })])),
    responseCodecs: {
      200: t.exact(t.intersection([t.type({
        /** All the Shopify variants that belong to the given seller */
        'shopifyVariants': t.array(ShopifyProductMatchVariant),
        /** All the Mable variants that belong to the given seller */
        'mableVariants': t.array(ProductVariantDisplayInfoWithShopify),
        /** The suggested automatic matches. Empty if automaticallyMatch is not sent */
        'automaticMatches': t.array(ShopifyProductMatch),
      }), t.partial({
        
      })])),
    },
    
    security: [{"MableOauth2":["seller"]}],
  },
  'POST /v1/sellers/{sellerIdOrMe}/shopify/productMatch': {
    method: 'post',
    path: '/v1/sellers/{sellerIdOrMe}/shopify/productMatch',
    accept: 'undefined',
    inputLocations: {
      'sellerIdOrMe': 'path',
      'body': 'body',
    },
    inputCodec: t.exact(t.intersection([
      t.type({
        /** The seller id to query or @me if you want yourself */
        'sellerIdOrMe': URLStringCodec,
        'body': t.exact(t.intersection([t.type({
          /** The matches that the user has chosen to match that are were not SKU matches sent back in the automaticMatches of the GET */
          'manualMatches': t.array(ShopifyProductMatch),
          /** Confirmed automaticMatches from the GET. Pairs that were not present in that variable will cause an error. */
          'automaticMatches': t.array(ShopifyProductMatch),
          /** Mable product variant IDs that the user confirmed they do not want to have linked to a Shopify product variant and will appear unlinked in sync'd orders */
          'unmatchedVariantIds': t.array(t.number),
        }), t.partial({
          
        })]), 'body'),
      }),
      t.partial({
        
      })
    ]), 'POST /v1/sellers/{sellerIdOrMe}/shopify/productMatch Input Codec'),
    responseCodec: t.void,
    responseCodecs: {
      201: undefined,
    },
    
    security: [{"MableOauth2":["seller"]}],
  },
  'POST /v1/sellers/{sellerIdOrMe}/upload/certification': {
    method: 'post',
    path: '/v1/sellers/{sellerIdOrMe}/upload/certification',
    accept: 'application/json',
    inputLocations: {
      'sellerIdOrMe': 'path',
      'params': 'body',
    },
    inputCodec: t.exact(t.intersection([
      t.type({
        /** The sellerId to query or @me */
        'sellerIdOrMe': URLStringCodec,
        'params': t.intersection([
          S3SignedLocation,
          t.exact(t.intersection([t.type({
            'type': SellerCertificationType,
          }), t.partial({
            
          })]), 'params')
        ], 'params'),
      }),
      t.partial({
        
      })
    ]), 'POST /v1/sellers/{sellerIdOrMe}/upload/certification Input Codec'),
    responseCodec: t.exact(t.intersection([t.type({
      'certification': SellerCertificationFileUpload,
    }), t.partial({
      
    })])),
    responseCodecs: {
      200: t.exact(t.intersection([t.type({
        'certification': SellerCertificationFileUpload,
      }), t.partial({
        
      })])),
    },
    
    security: [{"MableOauth2":["seller","admin"]}],
  },
  'POST /v1/sellers/{sellerIdOrMe}/upload/certificationSignedUrl': {
    method: 'post',
    path: '/v1/sellers/{sellerIdOrMe}/upload/certificationSignedUrl',
    accept: 'application/json',
    inputLocations: {
      'sellerIdOrMe': 'path',
      'params': 'body',
    },
    inputCodec: t.exact(t.intersection([
      t.type({
        /** The sellerId to query or @me */
        'sellerIdOrMe': URLStringCodec,
        'params': t.exact(t.intersection([t.type({
          'filename': t.string,
        }), t.partial({
          
        })]), 'params'),
      }),
      t.partial({
        
      })
    ]), 'POST /v1/sellers/{sellerIdOrMe}/upload/certificationSignedUrl Input Codec'),
    responseCodec: S3SignedLocation,
    responseCodecs: {
      200: S3SignedLocation,
    },
    
    security: [{"MableOauth2":["seller","admin"]}],
  },
  'POST /v1/sellers/{sellerIdOrMe}/upload/productSubmission': {
    method: 'post',
    path: '/v1/sellers/{sellerIdOrMe}/upload/productSubmission',
    accept: 'undefined',
    inputLocations: {
      'sellerIdOrMe': 'path',
      's3Location': 'body',
    },
    inputCodec: t.exact(t.intersection([
      t.type({
        /** The sellerId to query or @me */
        'sellerIdOrMe': URLStringCodec,
        's3Location': S3SignedLocation,
      }),
      t.partial({
        
      })
    ]), 'POST /v1/sellers/{sellerIdOrMe}/upload/productSubmission Input Codec'),
    responseCodec: t.void,
    responseCodecs: {
      201: undefined,
    },
    
    security: [{"MableOauth2":["seller","admin"]}],
  },
  'PATCH /v1/sellers/{sellerIdOrMe}/productSubmission/{productSubmissionId}': {
    method: 'patch',
    path: '/v1/sellers/{sellerIdOrMe}/productSubmission/{productSubmissionId}',
    accept: 'undefined',
    inputLocations: {
      'sellerIdOrMe': 'path',
      'productSubmissionId': 'path',
      'patch': 'body',
    },
    inputCodec: t.exact(t.intersection([
      t.type({
        /** The sellerId to query or @me */
        'sellerIdOrMe': URLStringCodec,
        /** ID of product submission */
        'productSubmissionId': StringNumberCodec,
        'patch': SellerProductSubmissionFileUploadPatch,
      }),
      t.partial({
        
      })
    ]), 'PATCH /v1/sellers/{sellerIdOrMe}/productSubmission/{productSubmissionId} Input Codec'),
    responseCodec: t.void,
    responseCodecs: {
      201: undefined,
    },
    
    security: [{"MableOauth2":["seller","admin"]}],
  },
  'DELETE /v1/sellers/{sellerIdOrMe}/productSubmission/{productSubmissionId}': {
    method: 'delete',
    path: '/v1/sellers/{sellerIdOrMe}/productSubmission/{productSubmissionId}',
    accept: 'undefined',
    inputLocations: {
      'sellerIdOrMe': 'path',
      'productSubmissionId': 'path',
    },
    inputCodec: t.exact(t.intersection([
      t.type({
        /** The sellerId to query or @me */
        'sellerIdOrMe': URLStringCodec,
        /** ID of product submission */
        'productSubmissionId': StringNumberCodec,
      }),
      t.partial({
        
      })
    ]), 'DELETE /v1/sellers/{sellerIdOrMe}/productSubmission/{productSubmissionId} Input Codec'),
    responseCodec: t.void,
    responseCodecs: {
      201: undefined,
    },
    
    security: [{"MableOauth2":["seller","admin"]}],
  },
  'POST /v1/sellers/{sellerIdOrMe}/upload/productSubmissionSignedUrl': {
    method: 'post',
    path: '/v1/sellers/{sellerIdOrMe}/upload/productSubmissionSignedUrl',
    accept: 'application/json',
    inputLocations: {
      'sellerIdOrMe': 'path',
      'params': 'body',
    },
    inputCodec: t.exact(t.intersection([
      t.type({
        /** The sellerId to query or @me */
        'sellerIdOrMe': URLStringCodec,
        'params': t.exact(t.intersection([t.type({
          'filename': t.string,
        }), t.partial({
          
        })]), 'params'),
      }),
      t.partial({
        
      })
    ]), 'POST /v1/sellers/{sellerIdOrMe}/upload/productSubmissionSignedUrl Input Codec'),
    responseCodec: S3SignedLocation,
    responseCodecs: {
      200: S3SignedLocation,
    },
    
    security: [{"MableOauth2":["seller","admin"]}],
  },
  'DELETE /v1/sellers/{sellerIdOrMe}/sellerCertification/{sellerCertificationId}': {
    method: 'delete',
    path: '/v1/sellers/{sellerIdOrMe}/sellerCertification/{sellerCertificationId}',
    accept: 'undefined',
    inputLocations: {
      'sellerIdOrMe': 'path',
      'sellerCertificationId': 'path',
    },
    inputCodec: t.exact(t.intersection([
      t.type({
        /** The sellerId to query or @me */
        'sellerIdOrMe': URLStringCodec,
        'sellerCertificationId': StringNumberCodec,
      }),
      t.partial({
        
      })
    ]), 'DELETE /v1/sellers/{sellerIdOrMe}/sellerCertification/{sellerCertificationId} Input Codec'),
    responseCodec: t.void,
    responseCodecs: {
      204: undefined,
    },
    
    security: [{"MableOauth2":["seller","admin"]}],
  },
  'GET /v1/admin/emailReview': {
    method: 'get',
    path: '/v1/admin/emailReview',
    accept: 'application/json',
    inputLocations: {
      
    },
    inputCodec: t.exact(t.intersection([
      t.type({
        
      }),
      t.partial({
        
      })
    ]), 'GET /v1/admin/emailReview Input Codec'),
    responseCodec: t.exact(t.intersection([t.type({
      /** All the email campaigns that have a paused enrollment */
      'emailCampaigns': t.array(t.exact(t.intersection([t.type({
        
      }), t.partial({
        'id': t.number,
        'name': t.string,
        'sellerId': t.number,
        'sellerName': t.string,
        'createdAt': DateFromISOString,
      })]))),
      /** All of the paused email enrollments */
      'emailEnrollments': t.array(t.exact(t.intersection([t.type({
        
      }), t.partial({
        'id': t.number,
        'email': t.string,
        'campaignId': t.number,
      })]))),
    }), t.partial({
      
    })])),
    responseCodecs: {
      200: t.exact(t.intersection([t.type({
        /** All the email campaigns that have a paused enrollment */
        'emailCampaigns': t.array(t.exact(t.intersection([t.type({
          
        }), t.partial({
          'id': t.number,
          'name': t.string,
          'sellerId': t.number,
          'sellerName': t.string,
          'createdAt': DateFromISOString,
        })]))),
        /** All of the paused email enrollments */
        'emailEnrollments': t.array(t.exact(t.intersection([t.type({
          
        }), t.partial({
          'id': t.number,
          'email': t.string,
          'campaignId': t.number,
        })]))),
      }), t.partial({
        
      })])),
    },
    
    security: [{"MableOauth2":["admin"]}],
  },
  'PATCH /v1/admin/emailReview': {
    method: 'patch',
    path: '/v1/admin/emailReview',
    accept: 'undefined',
    inputLocations: {
      'body': 'body',
    },
    inputCodec: t.exact(t.intersection([
      t.type({
        'body': t.exact(t.intersection([t.type({
          /** If true execute the campaign for the approved emails immediately */
          'executeApprovals': t.boolean,
          /** Email enrollment ids to approve */
          'approvals': t.array(t.number),
          /** Email enrollment ids to deny */
          'denials': t.array(t.number),
        }), t.partial({
          
        })]), 'body'),
      }),
      t.partial({
        
      })
    ]), 'PATCH /v1/admin/emailReview Input Codec'),
    responseCodec: t.void,
    responseCodecs: {
      200: undefined,
    },
    
    security: [{"MableOauth2":["admin"]}],
  },
  'PATCH /v1/admin/categories/repopulateNewCategories': {
    method: 'patch',
    path: '/v1/admin/categories/repopulateNewCategories',
    accept: 'undefined',
    inputLocations: {
      'partner': 'query',
    },
    inputCodec: t.exact(t.intersection([
      t.type({
        /** The marketplace/whitelabel partner to target for update */
        'partner': CodegenEnumOpen({
          'mableMarketplace': null,
          'mclane': null,
          'kehe': null,
        }, 'partner'),
      }),
      t.partial({
        
      })
    ]), 'PATCH /v1/admin/categories/repopulateNewCategories Input Codec'),
    responseCodec: t.void,
    responseCodecs: {
      200: undefined,
    },
    
    security: [{"MableOauth2":["admin"]}],
  },
  'GET /v1/messaging/channels': {
    method: 'get',
    path: '/v1/messaging/channels',
    accept: 'application/json',
    inputLocations: {
      
    },
    inputCodec: t.exact(t.intersection([
      t.type({
        
      }),
      t.partial({
        
      })
    ]), 'GET /v1/messaging/channels Input Codec'),
    responseCodec: t.exact(t.intersection([t.type({
      'channels': t.array(SendbirdGroupMessageChannelDisplay),
    }), t.partial({
      
    })])),
    responseCodecs: {
      200: t.exact(t.intersection([t.type({
        'channels': t.array(SendbirdGroupMessageChannelDisplay),
      }), t.partial({
        
      })])),
    },
    
    security: [{"MableOauth2":["buyer","seller"]}],
  },
  'POST /v1/messaging/channels': {
    method: 'post',
    path: '/v1/messaging/channels',
    accept: 'application/json',
    inputLocations: {
      'body': 'body',
    },
    inputCodec: t.exact(t.intersection([
      t.type({
        'body': OpenMessageChannelParams,
      }),
      t.partial({
        
      })
    ]), 'POST /v1/messaging/channels Input Codec'),
    responseCodec: t.exact(t.intersection([t.type({
      /** URL of the message channel that was created or fetched. Can be passed to Sendbird JS SDK. */
      'messageChannelUrl': t.string,
    }), t.partial({
      
    })])),
    responseCodecs: {
      200: t.exact(t.intersection([t.type({
        /** URL of the message channel that was created or fetched. Can be passed to Sendbird JS SDK. */
        'messageChannelUrl': t.string,
      }), t.partial({
        
      })])),
    },
    
    security: [{"MableOauth2":["buyer","seller"]}],
  },
  'GET /v1/messaging/channels/{sendbirdChannelUrl}': {
    method: 'get',
    path: '/v1/messaging/channels/{sendbirdChannelUrl}',
    accept: 'application/json',
    inputLocations: {
      'sendbirdChannelUrl': 'path',
    },
    inputCodec: t.exact(t.intersection([
      t.type({
        /** sendbird channel URL */
        'sendbirdChannelUrl': URLStringCodec,
      }),
      t.partial({
        
      })
    ]), 'GET /v1/messaging/channels/{sendbirdChannelUrl} Input Codec'),
    responseCodec: MessageChannelDisplayInfo,
    responseCodecs: {
      200: MessageChannelDisplayInfo,
    },
    
    security: [{"MableOauth2":["buyer","seller"]}],
  },
  'PUT /v1/messaging/channels/{sendbirdChannelUrl}/join': {
    method: 'put',
    path: '/v1/messaging/channels/{sendbirdChannelUrl}/join',
    accept: 'undefined',
    inputLocations: {
      'sendbirdChannelUrl': 'path',
    },
    inputCodec: t.exact(t.intersection([
      t.type({
        /** sendbird channel URL to join */
        'sendbirdChannelUrl': URLStringCodec,
      }),
      t.partial({
        
      })
    ]), 'PUT /v1/messaging/channels/{sendbirdChannelUrl}/join Input Codec'),
    responseCodec: t.void,
    responseCodecs: {
      204: undefined,
    },
    
    security: [{"MableOauth2":["buyer","seller"]}],
  },
  'PUT /v1/messaging/channels/{sendbirdChannelUrl}/leave': {
    method: 'put',
    path: '/v1/messaging/channels/{sendbirdChannelUrl}/leave',
    accept: 'undefined',
    inputLocations: {
      'sendbirdChannelUrl': 'path',
    },
    inputCodec: t.exact(t.intersection([
      t.type({
        /** sendbird channel URL to leave */
        'sendbirdChannelUrl': URLStringCodec,
      }),
      t.partial({
        
      })
    ]), 'PUT /v1/messaging/channels/{sendbirdChannelUrl}/leave Input Codec'),
    responseCodec: t.void,
    responseCodecs: {
      204: undefined,
    },
    
    security: [{"MableOauth2":["buyer","seller"]}],
  },
  'POST /v1/messaging/channels/{sendbirdChannelUrl}/remind': {
    method: 'post',
    path: '/v1/messaging/channels/{sendbirdChannelUrl}/remind',
    accept: 'undefined',
    inputLocations: {
      'sendbirdChannelUrl': 'path',
      'body': 'body',
    },
    inputCodec: t.exact(t.intersection([
      t.type({
        /** sendbird channel URL to schedule a reminder for */
        'sendbirdChannelUrl': URLStringCodec,
        'body': t.exact(t.intersection([t.type({
          /** How long to wait before sending the reminder for the user */
          'delayHours': t.number,
        }), t.partial({
          
        })]), 'body'),
      }),
      t.partial({
        
      })
    ]), 'POST /v1/messaging/channels/{sendbirdChannelUrl}/remind Input Codec'),
    responseCodec: t.void,
    responseCodecs: {
      204: undefined,
    },
    
    security: [{"MableOauth2":["buyer","seller"]}],
  },
  'POST /v1/messaging/users/@me/token': {
    method: 'post',
    path: '/v1/messaging/users/@me/token',
    accept: 'application/json',
    inputLocations: {
      
    },
    inputCodec: t.exact(t.intersection([
      t.type({
        
      }),
      t.partial({
        
      })
    ]), 'POST /v1/messaging/users/@me/token Input Codec'),
    responseCodec: SendbirdSessionToken,
    responseCodecs: {
      200: SendbirdSessionToken,
    },
    
    security: [{"MableOauth2":["buyer","seller"]}],
  },
  'GET /v1/messaging/users/@me/unreadMessagesCount': {
    method: 'get',
    path: '/v1/messaging/users/@me/unreadMessagesCount',
    accept: 'application/json',
    inputLocations: {
      
    },
    inputCodec: t.exact(t.intersection([
      t.type({
        
      }),
      t.partial({
        
      })
    ]), 'GET /v1/messaging/users/@me/unreadMessagesCount Input Codec'),
    responseCodec: t.exact(t.intersection([t.type({
      'unreadMessagesCount': t.number,
    }), t.partial({
      
    })])),
    responseCodecs: {
      200: t.exact(t.intersection([t.type({
        'unreadMessagesCount': t.number,
      }), t.partial({
        
      })])),
    },
    
    security: [{"MableOauth2":["buyer","seller"]}],
  },
  'POST /v1/admin/messageChannel': {
    method: 'post',
    path: '/v1/admin/messageChannel',
    accept: 'application/json',
    inputLocations: {
      'input': 'body',
    },
    inputCodec: t.exact(t.intersection([
      t.type({
        'input': t.exact(t.intersection([t.type({
          /** ID of the seller whose users will be added to the channel. */
          'sellerId': t.number,
        }), t.partial({
          /** ID of the buyer who will be added to the channel. All of this buyer's users will be added. Only one of buyerId or buyerUserId should be supplied. */
          'buyerId': t.number,
          /** ID of the buyer user who will be added to the channel. Only one of buyerId or buyerUserId should be supplied. */
          'buyerUserId': t.number,
        })]), 'input'),
      }),
      t.partial({
        
      })
    ]), 'POST /v1/admin/messageChannel Input Codec'),
    responseCodec: t.exact(t.intersection([t.type({
      /** URL of the message channel that was created or fetched. Can be passed to Sendbird JS SDK. */
      'messageChannelUrl': t.string,
    }), t.partial({
      
    })])),
    responseCodecs: {
      200: t.exact(t.intersection([t.type({
        /** URL of the message channel that was created or fetched. Can be passed to Sendbird JS SDK. */
        'messageChannelUrl': t.string,
      }), t.partial({
        
      })])),
    },
    
    security: [{"MableOauth2":["admin"]}],
  },
  'POST /v1/admin/messageChannels/{sellerId}/backfill': {
    method: 'post',
    path: '/v1/admin/messageChannels/{sellerId}/backfill',
    accept: 'application/json',
    inputLocations: {
      'sellerId': 'query',
    },
    inputCodec: t.exact(t.intersection([
      t.type({
        /** ID of the seller who will have message channels created based on their order history. */
        'sellerId': StringNumberCodec,
      }),
      t.partial({
        
      })
    ]), 'POST /v1/admin/messageChannels/{sellerId}/backfill Input Codec'),
    responseCodec: t.exact(t.intersection([t.type({
      /** URL of the message channels that were created or fetched. */
      'messageChannelUrls': t.array(t.string),
    }), t.partial({
      /** Errors encountered in the creation of message channels. */
      'errorMessages': t.array(t.string),
    })])),
    responseCodecs: {
      200: t.exact(t.intersection([t.type({
        /** URL of the message channels that were created or fetched. */
        'messageChannelUrls': t.array(t.string),
      }), t.partial({
        /** Errors encountered in the creation of message channels. */
        'errorMessages': t.array(t.string),
      })])),
    },
    
    security: [{"MableOauth2":["admin"]}],
  },
  'GET /v1/admin/messageChannels/{sendbirdChannelUrl}': {
    method: 'get',
    path: '/v1/admin/messageChannels/{sendbirdChannelUrl}',
    accept: 'application/json',
    inputLocations: {
      'sendbirdChannelUrl': 'path',
    },
    inputCodec: t.exact(t.intersection([
      t.type({
        /** sendbird channel URL to join */
        'sendbirdChannelUrl': URLStringCodec,
      }),
      t.partial({
        
      })
    ]), 'GET /v1/admin/messageChannels/{sendbirdChannelUrl} Input Codec'),
    responseCodec: t.exact(t.intersection([t.type({
      'channel': SendbirdGroupMessageChannelAdminDisplay,
    }), t.partial({
      
    })])),
    responseCodecs: {
      200: t.exact(t.intersection([t.type({
        'channel': SendbirdGroupMessageChannelAdminDisplay,
      }), t.partial({
        
      })])),
    },
    
    security: [{"MableOauth2":["admin"]}],
  },
  'PUT /v1/admin/messaging/channels/{sendbirdChannelUrl}/leave': {
    method: 'put',
    path: '/v1/admin/messaging/channels/{sendbirdChannelUrl}/leave',
    accept: 'undefined',
    inputLocations: {
      'sendbirdChannelUrl': 'path',
      'input': 'body',
    },
    inputCodec: t.exact(t.intersection([
      t.type({
        /** sendbird channel URL to leave */
        'sendbirdChannelUrl': URLStringCodec,
        'input': t.exact(t.intersection([t.type({
          /** The user ID to remove from this channel */
          'userId': t.number,
        }), t.partial({
          
        })]), 'input'),
      }),
      t.partial({
        
      })
    ]), 'PUT /v1/admin/messaging/channels/{sendbirdChannelUrl}/leave Input Codec'),
    responseCodec: t.void,
    responseCodecs: {
      204: undefined,
    },
    
    security: [{"MableOauth2":["admin"]}],
  },
  'GET /v1/buyers/@me/verification': {
    method: 'get',
    path: '/v1/buyers/@me/verification',
    accept: 'application/json',
    inputLocations: {
      
    },
    inputCodec: t.exact(t.intersection([
      t.type({
        
      }),
      t.partial({
        
      })
    ]), 'GET /v1/buyers/@me/verification Input Codec'),
    responseCodec: BuyerVerificationResponse,
    responseCodecs: {
      200: BuyerVerificationResponse,
    },
    
    security: [{"MableOauth2":["admin","buyer"]}],
  },
  'PUT /v1/buyers/@me/verification': {
    method: 'put',
    path: '/v1/buyers/@me/verification',
    accept: 'undefined',
    inputLocations: {
      'input': 'body',
    },
    inputCodec: t.exact(t.intersection([
      t.type({
        'input': BuyerVerificationInput,
      }),
      t.partial({
        
      })
    ]), 'PUT /v1/buyers/@me/verification Input Codec'),
    responseCodec: t.void,
    responseCodecs: {
      200: undefined,
    },
    
    security: [{"MableOauth2":["admin","buyer"]}],
  },
  'GET /v1/admin/buyers/{buyerId}/verification': {
    method: 'get',
    path: '/v1/admin/buyers/{buyerId}/verification',
    accept: 'application/json',
    inputLocations: {
      'buyerId': 'path',
    },
    inputCodec: t.exact(t.intersection([
      t.type({
        'buyerId': StringNumberCodec,
      }),
      t.partial({
        
      })
    ]), 'GET /v1/admin/buyers/{buyerId}/verification Input Codec'),
    responseCodec: BuyerVerificationResponse,
    responseCodecs: {
      200: BuyerVerificationResponse,
    },
    
    security: [{"MableOauth2":["admin"]}],
  },
  'GET /v1/admin/manualTasks': {
    method: 'get',
    path: '/v1/admin/manualTasks',
    accept: 'application/json',
    inputLocations: {
      'types': 'query',
      'statuses': 'query',
      'page': 'query',
      'pageSize': 'query',
    },
    inputCodec: t.exact(t.intersection([
      t.type({
        /** Page, 1 is first page. */
        'page': StringNumberCodec,
        /** Number of results per page */
        'pageSize': StringNumberCodec,
      }),
      t.partial({
        'types': StringArrayCodec(ManualTaskType),
        'statuses': StringArrayCodec(ManualTaskStatus),
      })
    ]), 'GET /v1/admin/manualTasks Input Codec'),
    responseCodec: t.exact(t.intersection([t.type({
      'tasks': t.array(ManualTaskDisplayInfo),
      'totalCount': t.number,
    }), t.partial({
      
    })])),
    responseCodecs: {
      200: t.exact(t.intersection([t.type({
        'tasks': t.array(ManualTaskDisplayInfo),
        'totalCount': t.number,
      }), t.partial({
        
      })])),
    },
    
    security: [{"MableOauth2":["admin","dataworker"]}],
  },
  'GET /v1/admin/manualTasks/summary': {
    method: 'get',
    path: '/v1/admin/manualTasks/summary',
    accept: 'application/json',
    inputLocations: {
      
    },
    inputCodec: t.exact(t.intersection([
      t.type({
        
      }),
      t.partial({
        
      })
    ]), 'GET /v1/admin/manualTasks/summary Input Codec'),
    responseCodec: t.exact(t.intersection([t.type({
      'counts': t.array(t.exact(t.intersection([t.type({
        'type': ManualTaskType,
        'inProgress': t.number,
        'new': t.number,
      }), t.partial({
        
      })]))),
    }), t.partial({
      
    })])),
    responseCodecs: {
      200: t.exact(t.intersection([t.type({
        'counts': t.array(t.exact(t.intersection([t.type({
          'type': ManualTaskType,
          'inProgress': t.number,
          'new': t.number,
        }), t.partial({
          
        })]))),
      }), t.partial({
        
      })])),
    },
    
    security: [{"MableOauth2":["admin","dataworker"]}],
  },
  'GET /v1/admin/manualTasks/{manualTaskId}': {
    method: 'get',
    path: '/v1/admin/manualTasks/{manualTaskId}',
    accept: 'application/json',
    inputLocations: {
      'manualTaskId': 'path',
    },
    inputCodec: t.exact(t.intersection([
      t.type({
        'manualTaskId': URLStringCodec,
      }),
      t.partial({
        
      })
    ]), 'GET /v1/admin/manualTasks/{manualTaskId} Input Codec'),
    responseCodec: t.exact(t.intersection([t.type({
      'task': ManualTaskDisplayInfo,
    }), t.partial({
      
    })])),
    responseCodecs: {
      200: t.exact(t.intersection([t.type({
        'task': ManualTaskDisplayInfo,
      }), t.partial({
        
      })])),
    },
    
    security: [{"MableOauth2":["admin","dataworker"]}],
  },
  'DELETE /v1/admin/manualTasks/{manualTaskId}': {
    method: 'delete',
    path: '/v1/admin/manualTasks/{manualTaskId}',
    accept: 'undefined',
    inputLocations: {
      'manualTaskId': 'path',
    },
    inputCodec: t.exact(t.intersection([
      t.type({
        'manualTaskId': URLStringCodec,
      }),
      t.partial({
        
      })
    ]), 'DELETE /v1/admin/manualTasks/{manualTaskId} Input Codec'),
    responseCodec: t.void,
    responseCodecs: {
      204: undefined,
    },
    
    security: [{"MableOauth2":["admin","dataworker"]}],
  },
  'POST /v1/admin/manualTasks/{manualTaskId}/claim': {
    method: 'post',
    path: '/v1/admin/manualTasks/{manualTaskId}/claim',
    accept: 'undefined',
    inputLocations: {
      'manualTaskId': 'path',
    },
    inputCodec: t.exact(t.intersection([
      t.type({
        'manualTaskId': URLStringCodec,
      }),
      t.partial({
        
      })
    ]), 'POST /v1/admin/manualTasks/{manualTaskId}/claim Input Codec'),
    responseCodec: t.void,
    responseCodecs: {
      204: undefined,
    },
    
    security: [{"MableOauth2":["admin","dataworker"]}],
  },
  'POST /v1/admin/manualTasks/{manualTaskId}/unclaim': {
    method: 'post',
    path: '/v1/admin/manualTasks/{manualTaskId}/unclaim',
    accept: 'undefined',
    inputLocations: {
      'manualTaskId': 'path',
    },
    inputCodec: t.exact(t.intersection([
      t.type({
        'manualTaskId': URLStringCodec,
      }),
      t.partial({
        
      })
    ]), 'POST /v1/admin/manualTasks/{manualTaskId}/unclaim Input Codec'),
    responseCodec: t.void,
    responseCodecs: {
      204: undefined,
    },
    
    security: [{"MableOauth2":["admin","dataworker"]}],
  },
  'POST /v1/admin/manualTasks/{manualTaskId}/complete': {
    method: 'post',
    path: '/v1/admin/manualTasks/{manualTaskId}/complete',
    accept: 'undefined',
    inputLocations: {
      'manualTaskId': 'path',
    },
    inputCodec: t.exact(t.intersection([
      t.type({
        'manualTaskId': URLStringCodec,
      }),
      t.partial({
        
      })
    ]), 'POST /v1/admin/manualTasks/{manualTaskId}/complete Input Codec'),
    responseCodec: t.void,
    responseCodecs: {
      204: undefined,
    },
    
    security: [{"MableOauth2":["admin","dataworker"]}],
  },
  'GET /v1/support/orderIssueFormUrl': {
    method: 'get',
    path: '/v1/support/orderIssueFormUrl',
    accept: 'application/json',
    inputLocations: {
      'orderId': 'query',
      'email': 'query',
    },
    inputCodec: t.exact(t.intersection([
      t.type({
        'orderId': StringNumberCodec,
        'email': URLStringCodec,
      }),
      t.partial({
        
      })
    ]), 'GET /v1/support/orderIssueFormUrl Input Codec'),
    responseCodec: t.exact(t.intersection([t.type({
      
    }), t.partial({
      'url': t.string,
    })])),
    responseCodecs: {
      200: t.exact(t.intersection([t.type({
        
      }), t.partial({
        'url': t.string,
      })])),
    },
    
    security: [{"MableOauth2":["admin","buyer","seller"]}],
  },
  'GET /v1/support/messagingAssistanceFormUrl': {
    method: 'get',
    path: '/v1/support/messagingAssistanceFormUrl',
    accept: 'application/json',
    inputLocations: {
      'reporterAccountId': 'query',
      'reporterName': 'query',
      'reporterEmail': 'query',
      'otherPartyAccountId': 'query',
      'otherPartyName': 'query',
    },
    inputCodec: t.exact(t.intersection([
      t.type({
        'reporterAccountId': StringNumberCodec,
        'reporterName': URLStringCodec,
        'reporterEmail': URLStringCodec,
        'otherPartyAccountId': StringNumberCodec,
        'otherPartyName': URLStringCodec,
      }),
      t.partial({
        
      })
    ]), 'GET /v1/support/messagingAssistanceFormUrl Input Codec'),
    responseCodec: t.exact(t.intersection([t.type({
      
    }), t.partial({
      'url': t.string,
    })])),
    responseCodecs: {
      200: t.exact(t.intersection([t.type({
        
      }), t.partial({
        'url': t.string,
      })])),
    },
    
    security: [{"MableOauth2":["admin","buyer","seller"]}],
  },
  'POST /v1/admin/search/terms': {
    method: 'post',
    path: '/v1/admin/search/terms',
    accept: 'application/json',
    inputLocations: {
      'input': 'body',
    },
    inputCodec: t.exact(t.intersection([
      t.type({
        'input': t.exact(t.intersection([t.type({
          'term': t.string,
        }), t.partial({
          
        })]), 'input'),
      }),
      t.partial({
        
      })
    ]), 'POST /v1/admin/search/terms Input Codec'),
    responseCodec: Category,
    responseCodecs: {
      201: Category,
    },
    
    security: [{"MableOauth2":["admin","dataworker"]}],
  },
  'POST /v1/admin/landedCostCalculator': {
    method: 'post',
    path: '/v1/admin/landedCostCalculator',
    accept: 'application/json',
    inputLocations: {
      'body': 'body',
    },
    inputCodec: t.exact(t.intersection([
      t.type({
        'body': LandedCostCalculatorInput,
      }),
      t.partial({
        
      })
    ]), 'POST /v1/admin/landedCostCalculator Input Codec'),
    responseCodec: LandedCostCalculatorResult,
    responseCodecs: {
      200: LandedCostCalculatorResult,
    },
    
    security: [{"MableOauth2":["admin"]}],
  },
  'GET /v1/admin/partnerPricingRegions': {
    method: 'get',
    path: '/v1/admin/partnerPricingRegions',
    accept: 'application/json',
    inputLocations: {
      
    },
    inputCodec: t.exact(t.intersection([
      t.type({
        
      }),
      t.partial({
        
      })
    ]), 'GET /v1/admin/partnerPricingRegions Input Codec'),
    responseCodec: t.exact(t.intersection([t.type({
      'partnerPricingRegions': t.array(AdminPartnerPricingRegion),
    }), t.partial({
      
    })])),
    responseCodecs: {
      200: t.exact(t.intersection([t.type({
        'partnerPricingRegions': t.array(AdminPartnerPricingRegion),
      }), t.partial({
        
      })])),
    },
    
    security: [{"MableOauth2":["admin"]}],
  },
  'POST /v1/admin/lambda/invoke': {
    method: 'post',
    path: '/v1/admin/lambda/invoke',
    accept: 'undefined',
    inputLocations: {
      'payload': 'body',
    },
    inputCodec: t.exact(t.intersection([
      t.type({
        'payload': LambdaAdminInvocationPayload,
      }),
      t.partial({
        
      })
    ]), 'POST /v1/admin/lambda/invoke Input Codec'),
    responseCodec: t.void,
    responseCodecs: {
      204: undefined,
    },
    
    security: [{"MableOauth2":["admin"]}],
  },
  'POST /v1/admin/lambda/callback': {
    method: 'post',
    path: '/v1/admin/lambda/callback',
    accept: 'undefined',
    inputLocations: {
      'body': 'body',
    },
    inputCodec: t.exact(t.intersection([
      t.type({
        'body': LambdaCallback,
      }),
      t.partial({
        
      })
    ]), 'POST /v1/admin/lambda/callback Input Codec'),
    responseCodec: t.void,
    responseCodecs: {
      204: undefined,
    },
    
    security: [{"MableOauth2":["admin"]}],
  },
  'POST /v1/admin/nacsAutoCategoriztion/generate': {
    method: 'post',
    path: '/v1/admin/nacsAutoCategoriztion/generate',
    accept: 'application/json',
    inputLocations: {
      
    },
    inputCodec: t.exact(t.intersection([
      t.type({
        
      }),
      t.partial({
        
      })
    ]), 'POST /v1/admin/nacsAutoCategoriztion/generate Input Codec'),
    responseCodec: CSVInput,
    responseCodecs: {
      200: CSVInput,
    },
    
    security: [{"MableOauth2":["admin"]}],
  },
  'PATCH /v1/admin/nacsAutoCategoriztion/import': {
    method: 'patch',
    path: '/v1/admin/nacsAutoCategoriztion/import',
    accept: 'undefined',
    inputLocations: {
      'csvInput': 'body',
    },
    inputCodec: t.exact(t.intersection([
      t.type({
        'csvInput': CSVInput,
      }),
      t.partial({
        
      })
    ]), 'PATCH /v1/admin/nacsAutoCategoriztion/import Input Codec'),
    responseCodec: t.void,
    responseCodecs: {
      204: undefined,
    },
    
    security: [{"MableOauth2":["admin"]}],
  },
  'POST /v1/admin/ediFiles/{ediFileId}/functionalAck': {
    method: 'post',
    path: '/v1/admin/ediFiles/{ediFileId}/functionalAck',
    accept: 'application/json',
    inputLocations: {
      'ediFileId': 'path',
    },
    inputCodec: t.exact(t.intersection([
      t.type({
        'ediFileId': StringNumberCodec,
      }),
      t.partial({
        
      })
    ]), 'POST /v1/admin/ediFiles/{ediFileId}/functionalAck Input Codec'),
    responseCodec: t.exact(t.intersection([t.type({
      'contents': t.string,
      'filename': t.string,
    }), t.partial({
      
    })])),
    responseCodecs: {
      200: t.exact(t.intersection([t.type({
        'contents': t.string,
        'filename': t.string,
      }), t.partial({
        
      })])),
    },
    
    security: [{"MableOauth2":["admin"]}],
  },
  'POST /v1/admin/ediFiles/{ediFileId}/functionalAck/send': {
    method: 'post',
    path: '/v1/admin/ediFiles/{ediFileId}/functionalAck/send',
    accept: 'application/json',
    inputLocations: {
      'ediFileId': 'path',
    },
    inputCodec: t.exact(t.intersection([
      t.type({
        'ediFileId': StringNumberCodec,
      }),
      t.partial({
        
      })
    ]), 'POST /v1/admin/ediFiles/{ediFileId}/functionalAck/send Input Codec'),
    responseCodec: t.exact(t.intersection([t.type({
      'message': t.string,
    }), t.partial({
      
    })])),
    responseCodecs: {
      200: t.exact(t.intersection([t.type({
        'message': t.string,
      }), t.partial({
        
      })])),
    },
    
    security: [{"MableOauth2":["admin"]}],
  },
  'POST /v1/admin/ediFiles/search': {
    method: 'post',
    path: '/v1/admin/ediFiles/search',
    accept: 'application/json',
    inputLocations: {
      'input': 'body',
    },
    inputCodec: t.exact(t.intersection([
      t.type({
        'input': t.exact(t.intersection([t.type({
          
        }), t.partial({
          'accountId': t.number,
          'transactionId': t.number,
          'transactionGroupId': t.number,
          'limit': t.number,
          'offset': t.number,
        })]), 'input'),
      }),
      t.partial({
        
      })
    ]), 'POST /v1/admin/ediFiles/search Input Codec'),
    responseCodec: t.exact(t.intersection([t.type({
      
    }), t.partial({
      'ediFiles': t.array(EdiFileFull),
    })])),
    responseCodecs: {
      200: t.exact(t.intersection([t.type({
        
      }), t.partial({
        'ediFiles': t.array(EdiFileFull),
      })])),
    },
    
    security: [{"MableOauth2":["admin"]}],
  },
  'POST /v1/admin/ediFiles/{ediFileId}/reprocess': {
    method: 'post',
    path: '/v1/admin/ediFiles/{ediFileId}/reprocess',
    accept: 'undefined',
    inputLocations: {
      'ediFileId': 'path',
    },
    inputCodec: t.exact(t.intersection([
      t.type({
        'ediFileId': StringNumberCodec,
      }),
      t.partial({
        
      })
    ]), 'POST /v1/admin/ediFiles/{ediFileId}/reprocess Input Codec'),
    responseCodec: t.void,
    responseCodecs: {
      201: undefined,
    },
    
    security: [{"MableOauth2":["admin"]}],
  },
  'GET /v1/admin/sellers/{sellerId}/partner/{partnerName}/priceReview': {
    method: 'get',
    path: '/v1/admin/sellers/{sellerId}/partner/{partnerName}/priceReview',
    accept: 'application/json',
    inputLocations: {
      'sellerId': 'path',
      'partnerName': 'path',
    },
    inputCodec: t.exact(t.intersection([
      t.type({
        'sellerId': StringNumberCodec,
        'partnerName': URLStringCodec,
      }),
      t.partial({
        
      })
    ]), 'GET /v1/admin/sellers/{sellerId}/partner/{partnerName}/priceReview Input Codec'),
    responseCodec: PartnerPriceReviewInfo,
    responseCodecs: {
      200: PartnerPriceReviewInfo,
    },
    
    security: [{"MableOauth2":["admin"]}],
  },
  'PUT /v1/admin/sellers/{sellerId}/partner/{partnerName}/priceReview': {
    method: 'put',
    path: '/v1/admin/sellers/{sellerId}/partner/{partnerName}/priceReview',
    accept: 'undefined',
    inputLocations: {
      'sellerId': 'path',
      'partnerName': 'path',
      'body': 'body',
    },
    inputCodec: t.exact(t.intersection([
      t.type({
        'sellerId': StringNumberCodec,
        'partnerName': URLStringCodec,
        'body': t.exact(t.intersection([t.type({
          'reviewedVariantIds': t.array(t.number),
          'pricingRegionMarkups': t.array(t.exact(t.intersection([t.type({
            'regionId': t.string,
            /** the factor_discount value associated with the pricing_tier with the sign flipped */
            'markup': NumberFromString,
          }), t.partial({
            
          })]), 'bodyItem')),
        }), t.partial({
          
        })]), 'body'),
      }),
      t.partial({
        
      })
    ]), 'PUT /v1/admin/sellers/{sellerId}/partner/{partnerName}/priceReview Input Codec'),
    responseCodec: t.void,
    responseCodecs: {
      204: undefined,
    },
    
    security: [{"MableOauth2":["admin"]}],
  },
  'POST /v1/admin/sellers/{sellerId}/accountSetupEmailCampaign': {
    method: 'post',
    path: '/v1/admin/sellers/{sellerId}/accountSetupEmailCampaign',
    accept: 'undefined',
    inputLocations: {
      'sellerId': 'path',
      'body': 'body',
    },
    inputCodec: t.exact(t.intersection([
      t.type({
        'sellerId': StringNumberCodec,
        'body': t.exact(t.intersection([t.type({
          'emails': t.array(Email),
        }), t.partial({
          
        })]), 'body'),
      }),
      t.partial({
        
      })
    ]), 'POST /v1/admin/sellers/{sellerId}/accountSetupEmailCampaign Input Codec'),
    responseCodec: t.void,
    responseCodecs: {
      204: undefined,
    },
    
    security: [{"MableOauth2":["admin"]}],
  },
  'GET /v1/admin/externalBuyerLocationOperations': {
    method: 'get',
    path: '/v1/admin/externalBuyerLocationOperations',
    accept: 'application/json',
    inputLocations: {
      'partnerAccountId': 'query',
      'buyerIdentifier': 'query',
      'sourceFile': 'query',
      'processed': 'query',
      'ids': 'query',
    },
    inputCodec: t.exact(t.intersection([
      t.type({
        
      }),
      t.partial({
        'partnerAccountId': StringNumberCodec,
        'buyerIdentifier': URLStringCodec,
        'sourceFile': URLStringCodec,
        'processed': StringBooleanCodec,
        'ids': StringArrayCodec(StringNumberCodec),
      })
    ]), 'GET /v1/admin/externalBuyerLocationOperations Input Codec'),
    responseCodec: t.exact(t.intersection([t.type({
      'externalBuyerLocationOperations': t.array(ExternalBuyerLocationOperation),
    }), t.partial({
      
    })])),
    responseCodecs: {
      200: t.exact(t.intersection([t.type({
        'externalBuyerLocationOperations': t.array(ExternalBuyerLocationOperation),
      }), t.partial({
        
      })])),
    },
    
    security: [{"MableOauth2":["admin"]}],
  },
  'POST /v1/admin/externalBuyerLocationOperations': {
    method: 'post',
    path: '/v1/admin/externalBuyerLocationOperations',
    accept: 'undefined',
    inputLocations: {
      'payload': 'body',
    },
    inputCodec: t.exact(t.intersection([
      t.type({
        'payload': t.exact(t.intersection([t.type({
          'externalBuyerLocationOperationIds': t.array(t.number),
          /** The target account ID these location operations should be associated to. If these locations are to be ignored, send `null` here. */
          'targetAccountId': t.union([t.null, t.number]),
        }), t.partial({
          'unblockAddressOperationIds': t.array(t.number),
        })]), 'payload'),
      }),
      t.partial({
        
      })
    ]), 'POST /v1/admin/externalBuyerLocationOperations Input Codec'),
    responseCodec: t.void,
    responseCodecs: {
      204: undefined,
    },
    
    security: [{"MableOauth2":["admin"]}],
  },
  'POST /v1/admin/usfoods/productData': {
    method: 'post',
    path: '/v1/admin/usfoods/productData',
    accept: 'undefined',
    inputLocations: {
      'body': 'body',
    },
    inputCodec: t.exact(t.intersection([
      t.type({
        'body': t.exact(t.intersection([t.type({
          'csv': t.string,
        }), t.partial({
          
        })]), 'body'),
      }),
      t.partial({
        
      })
    ]), 'POST /v1/admin/usfoods/productData Input Codec'),
    responseCodec: t.void,
    responseCodecs: {
      201: undefined,
    },
    
    security: [{"MableOauth2":["admin"]}],
  },
  'GET /v1/admin/usfoods/pricingReports/{pricingReportId}/imageArchive/plan': {
    method: 'get',
    path: '/v1/admin/usfoods/pricingReports/{pricingReportId}/imageArchive/plan',
    accept: 'application/json',
    inputLocations: {
      'pricingReportId': 'query',
    },
    inputCodec: t.exact(t.intersection([
      t.type({
        'pricingReportId': StringNumberCodec,
      }),
      t.partial({
        
      })
    ]), 'GET /v1/admin/usfoods/pricingReports/{pricingReportId}/imageArchive/plan Input Codec'),
    responseCodec: t.exact(t.intersection([t.type({
      'plan': UsFoodsImageArchivePlan,
    }), t.partial({
      
    })])),
    responseCodecs: {
      200: t.exact(t.intersection([t.type({
        'plan': UsFoodsImageArchivePlan,
      }), t.partial({
        
      })])),
    },
    
    security: [{"MableOauth2":["admin"]}],
  },
  'POST /v1/admin/productVariantIdentifiers': {
    method: 'post',
    path: '/v1/admin/productVariantIdentifiers',
    accept: 'undefined',
    inputLocations: {
      'body': 'body',
    },
    inputCodec: t.exact(t.intersection([
      t.type({
        'body': t.exact(t.intersection([t.type({
          'variantIdentifiers': t.array(ProductVariantDataImporterInput),
        }), t.partial({
          
        })]), 'body'),
      }),
      t.partial({
        
      })
    ]), 'POST /v1/admin/productVariantIdentifiers Input Codec'),
    responseCodec: t.void,
    responseCodecs: {
      201: undefined,
    },
    
    security: [{"MableOauth2":["admin"]}],
  },
  'GET /v1/dataStandardProperties': {
    method: 'get',
    path: '/v1/dataStandardProperties',
    accept: 'application/json',
    inputLocations: {
      'dataStandardPropertyIds': 'query',
      'dataStandardPropertyNames': 'query',
      'editableBy': 'query',
    },
    inputCodec: t.exact(t.intersection([
      t.type({
        
      }),
      t.partial({
        'dataStandardPropertyIds': StringArrayCodec(StringNumberCodec),
        'dataStandardPropertyNames': StringArrayCodec(DataStandardPropertyName),
        /** Include only properties that are editable by any of the given kinds of users */
        'editableBy': StringArrayCodec(DataStandardPropertyEditableBy),
      })
    ]), 'GET /v1/dataStandardProperties Input Codec'),
    responseCodec: t.exact(t.intersection([t.type({
      'dataStandardProperties': DataStandardProperties,
    }), t.partial({
      
    })])),
    responseCodecs: {
      200: t.exact(t.intersection([t.type({
        'dataStandardProperties': DataStandardProperties,
      }), t.partial({
        
      })])),
    },
    
    security: [{"MableOauth2":["admin","dataworker","seller"]}],
  },
  'GET /v1/dataStandardPropertyValues': {
    method: 'get',
    path: '/v1/dataStandardPropertyValues',
    accept: 'application/json',
    inputLocations: {
      'retailUnitIds': 'query',
      'unitPackagingIds': 'query',
      'imageIds': 'query',
      'include': 'query',
    },
    inputCodec: t.exact(t.intersection([
      t.type({
        
      }),
      t.partial({
        'retailUnitIds': StringArrayCodec(StringNumberCodec),
        'unitPackagingIds': StringArrayCodec(StringNumberCodec),
        'imageIds': StringArrayCodec(StringNumberCodec),
        'include': StringArrayCodec(CodegenEnumOpen({
          'dataStandardProperties': null,
        }, 'includeItem')),
      })
    ]), 'GET /v1/dataStandardPropertyValues Input Codec'),
    responseCodec: t.exact(t.intersection([t.type({
      'dataStandardPropertyValues': DataStandardPropertyValues,
    }), t.partial({
      'dataStandardProperties': DataStandardProperties,
    })])),
    responseCodecs: {
      200: t.exact(t.intersection([t.type({
        'dataStandardPropertyValues': DataStandardPropertyValues,
      }), t.partial({
        'dataStandardProperties': DataStandardProperties,
      })])),
    },
    
    security: [{"MableOauth2":["admin","dataworker","seller"]}],
  },
  'POST /v1/dataStandardPropertyValues': {
    method: 'post',
    path: '/v1/dataStandardPropertyValues',
    accept: 'undefined',
    inputLocations: {
      'body': 'body',
    },
    inputCodec: t.exact(t.intersection([
      t.type({
        'body': SetDataStandardPropertyValues,
      }),
      t.partial({
        
      })
    ]), 'POST /v1/dataStandardPropertyValues Input Codec'),
    responseCodec: t.void,
    responseCodecs: {
      204: undefined,
    },
    
    security: [{"MableOauth2":["admin","dataworker","seller"]}],
  },
  'POST /v1/admin/aiPrompts/schedule': {
    method: 'post',
    path: '/v1/admin/aiPrompts/schedule',
    accept: 'application/json',
    inputLocations: {
      'body': 'body',
    },
    inputCodec: t.exact(t.intersection([
      t.type({
        'body': t.exact(t.intersection([t.type({
          'promptInfo': withExactlyOneKey(t.intersection([t.type({
            'predefinedPromptType': AiPredefinedPromptType,
            'customPrompt': t.exact(t.intersection([t.type({
              'prompt': t.string,
              'promptIdentifier': t.string,
            }), t.partial({
              
            })]), 'body'),
          }), t.partial({
            
          })]), 'body'),
          'target': withExactlyOneKey(t.intersection([t.type({
            'predefinedTargetType': AiPromptTargetType,
            'variantIds': t.array(t.number),
          }), t.partial({
            
          })]), 'body'),
        }), t.partial({
          'invocationIdentifier': t.string,
        })]), 'body'),
      }),
      t.partial({
        
      })
    ]), 'POST /v1/admin/aiPrompts/schedule Input Codec'),
    responseCodec: t.exact(t.intersection([t.type({
      'message': t.string,
    }), t.partial({
      'reportUrl': t.string,
    })])),
    responseCodecs: {
      200: t.exact(t.intersection([t.type({
        'message': t.string,
      }), t.partial({
        'reportUrl': t.string,
      })])),
    },
    
    security: [{"MableOauth2":["admin"]}],
  },
  'POST /v1/sellers/{sellerIdOrMe}/upload/mediaSignedUrl': {
    method: 'post',
    path: '/v1/sellers/{sellerIdOrMe}/upload/mediaSignedUrl',
    accept: 'application/json',
    inputLocations: {
      'sellerIdOrMe': 'path',
      'params': 'body',
    },
    inputCodec: t.exact(t.intersection([
      t.type({
        /** The sellerId to query or @me */
        'sellerIdOrMe': URLStringCodec,
        'params': t.exact(t.intersection([t.type({
          'filename': t.string,
        }), t.partial({
          
        })]), 'params'),
      }),
      t.partial({
        
      })
    ]), 'POST /v1/sellers/{sellerIdOrMe}/upload/mediaSignedUrl Input Codec'),
    responseCodec: S3SignedLocation,
    responseCodecs: {
      200: S3SignedLocation,
    },
    
    security: [{"MableOauth2":["seller","admin","dataworker"]}],
  },
  'POST /v1/sellers/{sellerIdOrMe}/media': {
    method: 'post',
    path: '/v1/sellers/{sellerIdOrMe}/media',
    accept: 'undefined',
    inputLocations: {
      'sellerIdOrMe': 'path',
      'mediaInput': 'body',
    },
    inputCodec: t.exact(t.intersection([
      t.type({
        'sellerIdOrMe': URLStringCodec,
        'mediaInput': MediaInput,
      }),
      t.partial({
        
      })
    ]), 'POST /v1/sellers/{sellerIdOrMe}/media Input Codec'),
    responseCodec: t.void,
    responseCodecs: {
      204: undefined,
    },
    
    security: [{"MableOauth2":["seller","admin","dataworker"]}],
  },
  'PATCH /v1/sellers/{sellerIdOrMe}/media/{mediaId}': {
    method: 'patch',
    path: '/v1/sellers/{sellerIdOrMe}/media/{mediaId}',
    accept: 'undefined',
    inputLocations: {
      'sellerIdOrMe': 'path',
      'mediaId': 'path',
      'mediaPatch': 'body',
    },
    inputCodec: t.exact(t.intersection([
      t.type({
        'sellerIdOrMe': URLStringCodec,
        'mediaId': StringNumberCodec,
        'mediaPatch': MediaPatch,
      }),
      t.partial({
        
      })
    ]), 'PATCH /v1/sellers/{sellerIdOrMe}/media/{mediaId} Input Codec'),
    responseCodec: t.void,
    responseCodecs: {
      204: undefined,
    },
    
    security: [{"MableOauth2":["seller","admin","dataworker"]}],
  },
  'DELETE /v1/sellers/{sellerIdOrMe}/media/{mediaId}': {
    method: 'delete',
    path: '/v1/sellers/{sellerIdOrMe}/media/{mediaId}',
    accept: 'undefined',
    inputLocations: {
      'sellerIdOrMe': 'path',
      'mediaId': 'path',
    },
    inputCodec: t.exact(t.intersection([
      t.type({
        'sellerIdOrMe': URLStringCodec,
        'mediaId': StringNumberCodec,
      }),
      t.partial({
        
      })
    ]), 'DELETE /v1/sellers/{sellerIdOrMe}/media/{mediaId} Input Codec'),
    responseCodec: t.void,
    responseCodecs: {
      204: undefined,
    },
    
    security: [{"MableOauth2":["seller","admin","dataworker"]}],
  },
  'POST /v1/sellers/{sellerId}/media/{mediaId}/signedDownloadUrl': {
    method: 'post',
    path: '/v1/sellers/{sellerId}/media/{mediaId}/signedDownloadUrl',
    accept: 'application/json',
    inputLocations: {
      'sellerId': 'path',
      'mediaId': 'path',
    },
    inputCodec: t.exact(t.intersection([
      t.type({
        'sellerId': StringNumberCodec,
        'mediaId': StringNumberCodec,
      }),
      t.partial({
        
      })
    ]), 'POST /v1/sellers/{sellerId}/media/{mediaId}/signedDownloadUrl Input Codec'),
    responseCodec: FileDownload,
    responseCodecs: {
      200: FileDownload,
    },
    
    security: [{"MableOauth2":["buyer","seller","admin","dataworker"]},{"NoAuth":[]}],
  },
  'GET /v1/planograms/{planogramId}': {
    method: 'get',
    path: '/v1/planograms/{planogramId}',
    accept: 'application/json',
    inputLocations: {
      'planogramId': 'path',
    },
    inputCodec: t.exact(t.intersection([
      t.type({
        'planogramId': StringNumberCodec,
      }),
      t.partial({
        
      })
    ]), 'GET /v1/planograms/{planogramId} Input Codec'),
    responseCodec: t.exact(t.intersection([t.type({
      'planogram': PlanogramDisplayInfo,
      'sellers': t.array(SellerDisplayInfo),
    }), t.partial({
      
    })])),
    responseCodecs: {
      200: t.exact(t.intersection([t.type({
        'planogram': PlanogramDisplayInfo,
        'sellers': t.array(SellerDisplayInfo),
      }), t.partial({
        
      })])),
    },
    
    security: [{"MableOauth2":["buyer","seller","admin","dataworker"]},{"NoAuth":[]}],
  },
  'POST /v1/admin/planograms': {
    method: 'post',
    path: '/v1/admin/planograms',
    accept: 'application/json',
    inputLocations: {
      'planogramInput': 'body',
    },
    inputCodec: t.exact(t.intersection([
      t.type({
        'planogramInput': PlanogramBase,
      }),
      t.partial({
        
      })
    ]), 'POST /v1/admin/planograms Input Codec'),
    responseCodec: t.exact(t.intersection([t.type({
      'planogram': Planogram,
    }), t.partial({
      
    })])),
    responseCodecs: {
      200: t.exact(t.intersection([t.type({
        'planogram': Planogram,
      }), t.partial({
        
      })])),
    },
    
    security: [{"MableOauth2":["admin"]}],
  },
  'GET /v1/admin/planograms/{planogramId}': {
    method: 'get',
    path: '/v1/admin/planograms/{planogramId}',
    accept: 'application/json',
    inputLocations: {
      'planogramId': 'path',
    },
    inputCodec: t.exact(t.intersection([
      t.type({
        'planogramId': StringNumberCodec,
      }),
      t.partial({
        
      })
    ]), 'GET /v1/admin/planograms/{planogramId} Input Codec'),
    responseCodec: t.exact(t.intersection([t.type({
      'planogram': Planogram,
    }), t.partial({
      
    })])),
    responseCodecs: {
      200: t.exact(t.intersection([t.type({
        'planogram': Planogram,
      }), t.partial({
        
      })])),
    },
    
    security: [{"MableOauth2":["admin"]}],
  },
  'PATCH /v1/admin/planograms/{planogramId}': {
    method: 'patch',
    path: '/v1/admin/planograms/{planogramId}',
    accept: 'undefined',
    inputLocations: {
      'planogramId': 'path',
      'planogramPatch': 'body',
    },
    inputCodec: t.exact(t.intersection([
      t.type({
        'planogramId': StringNumberCodec,
        'planogramPatch': PlanogramPatch,
      }),
      t.partial({
        
      })
    ]), 'PATCH /v1/admin/planograms/{planogramId} Input Codec'),
    responseCodec: t.void,
    responseCodecs: {
      204: undefined,
    },
    
    security: [{"MableOauth2":["admin"]}],
  },
  'DELETE /v1/admin/planograms/{planogramId}': {
    method: 'delete',
    path: '/v1/admin/planograms/{planogramId}',
    accept: 'undefined',
    inputLocations: {
      'planogramId': 'path',
    },
    inputCodec: t.exact(t.intersection([
      t.type({
        'planogramId': StringNumberCodec,
      }),
      t.partial({
        
      })
    ]), 'DELETE /v1/admin/planograms/{planogramId} Input Codec'),
    responseCodec: t.void,
    responseCodecs: {
      204: undefined,
    },
    
    security: [{"MableOauth2":["admin"]}],
  },
} as const;
export const AddressesGetResponse = routeInfo['GET /v1/addresses']['responseCodec'];
export type AddressesGetResponse = t.TypeOf<typeof AddressesGetResponse>;
export const AddressesGetInput = routeInfo['GET /v1/addresses']['inputCodec'];
export type AddressesGetInput = t.TypeOf<typeof AddressesGetInput>;
export const AddressesPostResponse = routeInfo['POST /v1/addresses']['responseCodec'];
export type AddressesPostResponse = t.TypeOf<typeof AddressesPostResponse>;
export const AddressesPostInput = routeInfo['POST /v1/addresses']['inputCodec'];
export type AddressesPostInput = t.TypeOf<typeof AddressesPostInput>;
export const VerifyAddressPostResponse = routeInfo['POST /v1/verifyAddress']['responseCodec'];
export type VerifyAddressPostResponse = t.TypeOf<typeof VerifyAddressPostResponse>;
export const VerifyAddressPostInput = routeInfo['POST /v1/verifyAddress']['inputCodec'];
export type VerifyAddressPostInput = t.TypeOf<typeof VerifyAddressPostInput>;
export const AddressesAddressIdGetResponse = routeInfo['GET /v1/addresses/{addressId}']['responseCodec'];
export type AddressesAddressIdGetResponse = t.TypeOf<typeof AddressesAddressIdGetResponse>;
export const AddressesAddressIdGetInput = routeInfo['GET /v1/addresses/{addressId}']['inputCodec'];
export type AddressesAddressIdGetInput = t.TypeOf<typeof AddressesAddressIdGetInput>;
export const AddressesAddressIdPutResponse = routeInfo['PUT /v1/addresses/{addressId}']['responseCodec'];
export type AddressesAddressIdPutResponse = t.TypeOf<typeof AddressesAddressIdPutResponse>;
export const AddressesAddressIdPutInput = routeInfo['PUT /v1/addresses/{addressId}']['inputCodec'];
export type AddressesAddressIdPutInput = t.TypeOf<typeof AddressesAddressIdPutInput>;
export const AddressesAddressIdDeleteResponse = routeInfo['DELETE /v1/addresses/{addressId}']['responseCodec'];
export type AddressesAddressIdDeleteResponse = t.TypeOf<typeof AddressesAddressIdDeleteResponse>;
export const AddressesAddressIdDeleteInput = routeInfo['DELETE /v1/addresses/{addressId}']['inputCodec'];
export type AddressesAddressIdDeleteInput = t.TypeOf<typeof AddressesAddressIdDeleteInput>;
export const AddressesAddressIdConfirmPutResponse = routeInfo['PUT /v1/addresses/{addressId}/confirm']['responseCodec'];
export type AddressesAddressIdConfirmPutResponse = t.TypeOf<typeof AddressesAddressIdConfirmPutResponse>;
export const AddressesAddressIdConfirmPutInput = routeInfo['PUT /v1/addresses/{addressId}/confirm']['inputCodec'];
export type AddressesAddressIdConfirmPutInput = t.TypeOf<typeof AddressesAddressIdConfirmPutInput>;
export const AccountGetResponse = routeInfo['GET /v1/account']['responseCodec'];
export type AccountGetResponse = t.TypeOf<typeof AccountGetResponse>;
export const AccountGetInput = routeInfo['GET /v1/account']['inputCodec'];
export type AccountGetInput = t.TypeOf<typeof AccountGetInput>;
export const AccountEmailInvitationsPostResponse = routeInfo['POST /v1/account/emailInvitations']['responseCodec'];
export type AccountEmailInvitationsPostResponse = t.TypeOf<typeof AccountEmailInvitationsPostResponse>;
export const AccountEmailInvitationsPostInput = routeInfo['POST /v1/account/emailInvitations']['inputCodec'];
export type AccountEmailInvitationsPostInput = t.TypeOf<typeof AccountEmailInvitationsPostInput>;
export const AccountEmailInviteEmailPutResponse = routeInfo['PUT /v1/account/emailInvite/{email}']['responseCodec'];
export type AccountEmailInviteEmailPutResponse = t.TypeOf<typeof AccountEmailInviteEmailPutResponse>;
export const AccountEmailInviteEmailPutInput = routeInfo['PUT /v1/account/emailInvite/{email}']['inputCodec'];
export type AccountEmailInviteEmailPutInput = t.TypeOf<typeof AccountEmailInviteEmailPutInput>;
export const AccountEmailInviteEmailDeleteResponse = routeInfo['DELETE /v1/account/emailInvite/{email}']['responseCodec'];
export type AccountEmailInviteEmailDeleteResponse = t.TypeOf<typeof AccountEmailInviteEmailDeleteResponse>;
export const AccountEmailInviteEmailDeleteInput = routeInfo['DELETE /v1/account/emailInvite/{email}']['inputCodec'];
export type AccountEmailInviteEmailDeleteInput = t.TypeOf<typeof AccountEmailInviteEmailDeleteInput>;
export const AccountMeUserIdDeleteResponse = routeInfo['DELETE /v1/account/@me/{userId}']['responseCodec'];
export type AccountMeUserIdDeleteResponse = t.TypeOf<typeof AccountMeUserIdDeleteResponse>;
export const AccountMeUserIdDeleteInput = routeInfo['DELETE /v1/account/@me/{userId}']['inputCodec'];
export type AccountMeUserIdDeleteInput = t.TypeOf<typeof AccountMeUserIdDeleteInput>;
export const AccountMergeByInvitationPutResponse = routeInfo['PUT /v1/account/mergeByInvitation']['responseCodec'];
export type AccountMergeByInvitationPutResponse = t.TypeOf<typeof AccountMergeByInvitationPutResponse>;
export const AccountMergeByInvitationPutInput = routeInfo['PUT /v1/account/mergeByInvitation']['inputCodec'];
export type AccountMergeByInvitationPutInput = t.TypeOf<typeof AccountMergeByInvitationPutInput>;
export const AccountUsersAutoAddToSellerMessageChannelsPostResponse = routeInfo['POST /v1/account/users/autoAddToSellerMessageChannels']['responseCodec'];
export type AccountUsersAutoAddToSellerMessageChannelsPostResponse = t.TypeOf<typeof AccountUsersAutoAddToSellerMessageChannelsPostResponse>;
export const AccountUsersAutoAddToSellerMessageChannelsPostInput = routeInfo['POST /v1/account/users/autoAddToSellerMessageChannels']['inputCodec'];
export type AccountUsersAutoAddToSellerMessageChannelsPostInput = t.TypeOf<typeof AccountUsersAutoAddToSellerMessageChannelsPostInput>;
export const AuthLoginPostResponse = routeInfo['POST /v1/auth/login']['responseCodec'];
export type AuthLoginPostResponse = t.TypeOf<typeof AuthLoginPostResponse>;
export const AuthLoginPostInput = routeInfo['POST /v1/auth/login']['inputCodec'];
export type AuthLoginPostInput = t.TypeOf<typeof AuthLoginPostInput>;
export const AuthSessionPostResponse = routeInfo['POST /v1/auth/session']['responseCodec'];
export type AuthSessionPostResponse = t.TypeOf<typeof AuthSessionPostResponse>;
export const AuthSessionPostInput = routeInfo['POST /v1/auth/session']['inputCodec'];
export type AuthSessionPostInput = t.TypeOf<typeof AuthSessionPostInput>;
export const AuthLogoutGetResponse = routeInfo['GET /v1/auth/logout']['responseCodec'];
export type AuthLogoutGetResponse = t.TypeOf<typeof AuthLogoutGetResponse>;
export const AuthLogoutGetInput = routeInfo['GET /v1/auth/logout']['inputCodec'];
export type AuthLogoutGetInput = t.TypeOf<typeof AuthLogoutGetInput>;
export const AuthResetPasswordPostResponse = routeInfo['POST /v1/auth/resetPassword']['responseCodec'];
export type AuthResetPasswordPostResponse = t.TypeOf<typeof AuthResetPasswordPostResponse>;
export const AuthResetPasswordPostInput = routeInfo['POST /v1/auth/resetPassword']['inputCodec'];
export type AuthResetPasswordPostInput = t.TypeOf<typeof AuthResetPasswordPostInput>;
export const AuthPasswordlessStartPostResponse = routeInfo['POST /v1/auth/passwordless/start']['responseCodec'];
export type AuthPasswordlessStartPostResponse = t.TypeOf<typeof AuthPasswordlessStartPostResponse>;
export const AuthPasswordlessStartPostInput = routeInfo['POST /v1/auth/passwordless/start']['inputCodec'];
export type AuthPasswordlessStartPostInput = t.TypeOf<typeof AuthPasswordlessStartPostInput>;
export const AuthOauthTokenPostResponse = routeInfo['POST /v1/auth/oauth/token']['responseCodec'];
export type AuthOauthTokenPostResponse = t.TypeOf<typeof AuthOauthTokenPostResponse>;
export const AuthOauthTokenPostInput = routeInfo['POST /v1/auth/oauth/token']['inputCodec'];
export type AuthOauthTokenPostInput = t.TypeOf<typeof AuthOauthTokenPostInput>;
export const ColdStartSellerPostResponse = routeInfo['POST /v1/coldStartSeller']['responseCodec'];
export type ColdStartSellerPostResponse = t.TypeOf<typeof ColdStartSellerPostResponse>;
export const ColdStartSellerPostInput = routeInfo['POST /v1/coldStartSeller']['inputCodec'];
export type ColdStartSellerPostInput = t.TypeOf<typeof ColdStartSellerPostInput>;
export const ColdStartSellerSellerIdOrSlugPatchResponse = routeInfo['PATCH /v1/coldStartSeller/{sellerIdOrSlug}']['responseCodec'];
export type ColdStartSellerSellerIdOrSlugPatchResponse = t.TypeOf<typeof ColdStartSellerSellerIdOrSlugPatchResponse>;
export const ColdStartSellerSellerIdOrSlugPatchInput = routeInfo['PATCH /v1/coldStartSeller/{sellerIdOrSlug}']['inputCodec'];
export type ColdStartSellerSellerIdOrSlugPatchInput = t.TypeOf<typeof ColdStartSellerSellerIdOrSlugPatchInput>;
export const ContactsGetResponse = routeInfo['GET /v1/contacts']['responseCodec'];
export type ContactsGetResponse = t.TypeOf<typeof ContactsGetResponse>;
export const ContactsGetInput = routeInfo['GET /v1/contacts']['inputCodec'];
export type ContactsGetInput = t.TypeOf<typeof ContactsGetInput>;
export const ContactsPutResponse = routeInfo['PUT /v1/contacts']['responseCodec'];
export type ContactsPutResponse = t.TypeOf<typeof ContactsPutResponse>;
export const ContactsPutInput = routeInfo['PUT /v1/contacts']['inputCodec'];
export type ContactsPutInput = t.TypeOf<typeof ContactsPutInput>;
export const AdminBulkImportAccountsPostResponse = routeInfo['POST /v1/admin/bulkImportAccounts']['responseCodec'];
export type AdminBulkImportAccountsPostResponse = t.TypeOf<typeof AdminBulkImportAccountsPostResponse>;
export const AdminBulkImportAccountsPostInput = routeInfo['POST /v1/admin/bulkImportAccounts']['inputCodec'];
export type AdminBulkImportAccountsPostInput = t.TypeOf<typeof AdminBulkImportAccountsPostInput>;
export const AdminBulkImportVariantIdentifiersPostResponse = routeInfo['POST /v1/admin/bulkImportVariantIdentifiers']['responseCodec'];
export type AdminBulkImportVariantIdentifiersPostResponse = t.TypeOf<typeof AdminBulkImportVariantIdentifiersPostResponse>;
export const AdminBulkImportVariantIdentifiersPostInput = routeInfo['POST /v1/admin/bulkImportVariantIdentifiers']['inputCodec'];
export type AdminBulkImportVariantIdentifiersPostInput = t.TypeOf<typeof AdminBulkImportVariantIdentifiersPostInput>;
export const AdminContactsGetResponse = routeInfo['GET /v1/admin/contacts']['responseCodec'];
export type AdminContactsGetResponse = t.TypeOf<typeof AdminContactsGetResponse>;
export const AdminContactsGetInput = routeInfo['GET /v1/admin/contacts']['inputCodec'];
export type AdminContactsGetInput = t.TypeOf<typeof AdminContactsGetInput>;
export const AdminContactsPostResponse = routeInfo['POST /v1/admin/contacts']['responseCodec'];
export type AdminContactsPostResponse = t.TypeOf<typeof AdminContactsPostResponse>;
export const AdminContactsPostInput = routeInfo['POST /v1/admin/contacts']['inputCodec'];
export type AdminContactsPostInput = t.TypeOf<typeof AdminContactsPostInput>;
export const AdminContactsContactIdGetResponse = routeInfo['GET /v1/admin/contacts/{contactId}']['responseCodec'];
export type AdminContactsContactIdGetResponse = t.TypeOf<typeof AdminContactsContactIdGetResponse>;
export const AdminContactsContactIdGetInput = routeInfo['GET /v1/admin/contacts/{contactId}']['inputCodec'];
export type AdminContactsContactIdGetInput = t.TypeOf<typeof AdminContactsContactIdGetInput>;
export const AdminContactsContactIdDeleteResponse = routeInfo['DELETE /v1/admin/contacts/{contactId}']['responseCodec'];
export type AdminContactsContactIdDeleteResponse = t.TypeOf<typeof AdminContactsContactIdDeleteResponse>;
export const AdminContactsContactIdDeleteInput = routeInfo['DELETE /v1/admin/contacts/{contactId}']['inputCodec'];
export type AdminContactsContactIdDeleteInput = t.TypeOf<typeof AdminContactsContactIdDeleteInput>;
export const AdminContactsContactIdPutResponse = routeInfo['PUT /v1/admin/contacts/{contactId}']['responseCodec'];
export type AdminContactsContactIdPutResponse = t.TypeOf<typeof AdminContactsContactIdPutResponse>;
export const AdminContactsContactIdPutInput = routeInfo['PUT /v1/admin/contacts/{contactId}']['inputCodec'];
export type AdminContactsContactIdPutInput = t.TypeOf<typeof AdminContactsContactIdPutInput>;
export const AdminContactsBusinessContactIdDeleteResponse = routeInfo['DELETE /v1/admin/contacts/business/{contactId}']['responseCodec'];
export type AdminContactsBusinessContactIdDeleteResponse = t.TypeOf<typeof AdminContactsBusinessContactIdDeleteResponse>;
export const AdminContactsBusinessContactIdDeleteInput = routeInfo['DELETE /v1/admin/contacts/business/{contactId}']['inputCodec'];
export type AdminContactsBusinessContactIdDeleteInput = t.TypeOf<typeof AdminContactsBusinessContactIdDeleteInput>;
export const HomepageGetResponse = routeInfo['GET /v1/homepage']['responseCodec'];
export type HomepageGetResponse = t.TypeOf<typeof HomepageGetResponse>;
export const HomepageGetInput = routeInfo['GET /v1/homepage']['inputCodec'];
export type HomepageGetInput = t.TypeOf<typeof HomepageGetInput>;
export const CategoriesGetResponse = routeInfo['GET /v1/categories']['responseCodec'];
export type CategoriesGetResponse = t.TypeOf<typeof CategoriesGetResponse>;
export const CategoriesGetInput = routeInfo['GET /v1/categories']['inputCodec'];
export type CategoriesGetInput = t.TypeOf<typeof CategoriesGetInput>;
export const CategoriesCategoryIdOrSlugGetResponse = routeInfo['GET /v1/categories/{categoryIdOrSlug}']['responseCodec'];
export type CategoriesCategoryIdOrSlugGetResponse = t.TypeOf<typeof CategoriesCategoryIdOrSlugGetResponse>;
export const CategoriesCategoryIdOrSlugGetInput = routeInfo['GET /v1/categories/{categoryIdOrSlug}']['inputCodec'];
export type CategoriesCategoryIdOrSlugGetInput = t.TypeOf<typeof CategoriesCategoryIdOrSlugGetInput>;
export const CategoriesCategoryIdOrSlugCsvGetResponse = routeInfo['GET /v1/categories/{categoryIdOrSlug}/csv']['responseCodec'];
export type CategoriesCategoryIdOrSlugCsvGetResponse = t.TypeOf<typeof CategoriesCategoryIdOrSlugCsvGetResponse>;
export const CategoriesCategoryIdOrSlugCsvGetInput = routeInfo['GET /v1/categories/{categoryIdOrSlug}/csv']['inputCodec'];
export type CategoriesCategoryIdOrSlugCsvGetInput = t.TypeOf<typeof CategoriesCategoryIdOrSlugCsvGetInput>;
export const AdminCategoriesCategoryIdOrSlugCsvGetResponse = routeInfo['GET /v1/admin/categories/{categoryIdOrSlug}/csv']['responseCodec'];
export type AdminCategoriesCategoryIdOrSlugCsvGetResponse = t.TypeOf<typeof AdminCategoriesCategoryIdOrSlugCsvGetResponse>;
export const AdminCategoriesCategoryIdOrSlugCsvGetInput = routeInfo['GET /v1/admin/categories/{categoryIdOrSlug}/csv']['inputCodec'];
export type AdminCategoriesCategoryIdOrSlugCsvGetInput = t.TypeOf<typeof AdminCategoriesCategoryIdOrSlugCsvGetInput>;
export const AdminCategoriesCategoryIdOrSlugEdiPricebookGetResponse = routeInfo['GET /v1/admin/categories/{categoryIdOrSlug}/ediPricebook']['responseCodec'];
export type AdminCategoriesCategoryIdOrSlugEdiPricebookGetResponse = t.TypeOf<typeof AdminCategoriesCategoryIdOrSlugEdiPricebookGetResponse>;
export const AdminCategoriesCategoryIdOrSlugEdiPricebookGetInput = routeInfo['GET /v1/admin/categories/{categoryIdOrSlug}/ediPricebook']['inputCodec'];
export type AdminCategoriesCategoryIdOrSlugEdiPricebookGetInput = t.TypeOf<typeof AdminCategoriesCategoryIdOrSlugEdiPricebookGetInput>;
export const AdminCategoriesCategoryIdFeedbackGetResponse = routeInfo['GET /v1/admin/categories/{categoryId}/feedback']['responseCodec'];
export type AdminCategoriesCategoryIdFeedbackGetResponse = t.TypeOf<typeof AdminCategoriesCategoryIdFeedbackGetResponse>;
export const AdminCategoriesCategoryIdFeedbackGetInput = routeInfo['GET /v1/admin/categories/{categoryId}/feedback']['inputCodec'];
export type AdminCategoriesCategoryIdFeedbackGetInput = t.TypeOf<typeof AdminCategoriesCategoryIdFeedbackGetInput>;
export const CollectionsCategoryIdOrSlugGetResponse = routeInfo['GET /v1/collections/{categoryIdOrSlug}']['responseCodec'];
export type CollectionsCategoryIdOrSlugGetResponse = t.TypeOf<typeof CollectionsCategoryIdOrSlugGetResponse>;
export const CollectionsCategoryIdOrSlugGetInput = routeInfo['GET /v1/collections/{categoryIdOrSlug}']['inputCodec'];
export type CollectionsCategoryIdOrSlugGetInput = t.TypeOf<typeof CollectionsCategoryIdOrSlugGetInput>;
export const CategoriesCategoryIdSellersSellerIdFeedbackPutResponse = routeInfo['PUT /v1/categories/{categoryId}/sellers/{sellerId}/feedback']['responseCodec'];
export type CategoriesCategoryIdSellersSellerIdFeedbackPutResponse = t.TypeOf<typeof CategoriesCategoryIdSellersSellerIdFeedbackPutResponse>;
export const CategoriesCategoryIdSellersSellerIdFeedbackPutInput = routeInfo['PUT /v1/categories/{categoryId}/sellers/{sellerId}/feedback']['inputCodec'];
export type CategoriesCategoryIdSellersSellerIdFeedbackPutInput = t.TypeOf<typeof CategoriesCategoryIdSellersSellerIdFeedbackPutInput>;
export const CategoriesCategoryIdOrSlugFeedbackGetResponse = routeInfo['GET /v1/categories/{categoryIdOrSlug}/feedback']['responseCodec'];
export type CategoriesCategoryIdOrSlugFeedbackGetResponse = t.TypeOf<typeof CategoriesCategoryIdOrSlugFeedbackGetResponse>;
export const CategoriesCategoryIdOrSlugFeedbackGetInput = routeInfo['GET /v1/categories/{categoryIdOrSlug}/feedback']['inputCodec'];
export type CategoriesCategoryIdOrSlugFeedbackGetInput = t.TypeOf<typeof CategoriesCategoryIdOrSlugFeedbackGetInput>;
export const BuyersMeOrderGuideGetResponse = routeInfo['GET /v1/buyers/@me/orderGuide']['responseCodec'];
export type BuyersMeOrderGuideGetResponse = t.TypeOf<typeof BuyersMeOrderGuideGetResponse>;
export const BuyersMeOrderGuideGetInput = routeInfo['GET /v1/buyers/@me/orderGuide']['inputCodec'];
export type BuyersMeOrderGuideGetInput = t.TypeOf<typeof BuyersMeOrderGuideGetInput>;
export const BuyersMeOrderGuideRequestAuthorizationPostResponse = routeInfo['POST /v1/buyers/@me/orderGuide/requestAuthorization']['responseCodec'];
export type BuyersMeOrderGuideRequestAuthorizationPostResponse = t.TypeOf<typeof BuyersMeOrderGuideRequestAuthorizationPostResponse>;
export const BuyersMeOrderGuideRequestAuthorizationPostInput = routeInfo['POST /v1/buyers/@me/orderGuide/requestAuthorization']['inputCodec'];
export type BuyersMeOrderGuideRequestAuthorizationPostInput = t.TypeOf<typeof BuyersMeOrderGuideRequestAuthorizationPostInput>;
export const AdminBackfillTransactionSalesOwnersPostResponse = routeInfo['POST /v1/admin/backfillTransactionSalesOwners']['responseCodec'];
export type AdminBackfillTransactionSalesOwnersPostResponse = t.TypeOf<typeof AdminBackfillTransactionSalesOwnersPostResponse>;
export const AdminBackfillTransactionSalesOwnersPostInput = routeInfo['POST /v1/admin/backfillTransactionSalesOwners']['inputCodec'];
export type AdminBackfillTransactionSalesOwnersPostInput = t.TypeOf<typeof AdminBackfillTransactionSalesOwnersPostInput>;
export const AdminAttributeBuyersPostResponse = routeInfo['POST /v1/admin/attributeBuyers']['responseCodec'];
export type AdminAttributeBuyersPostResponse = t.TypeOf<typeof AdminAttributeBuyersPostResponse>;
export const AdminAttributeBuyersPostInput = routeInfo['POST /v1/admin/attributeBuyers']['inputCodec'];
export type AdminAttributeBuyersPostInput = t.TypeOf<typeof AdminAttributeBuyersPostInput>;
export const AdminBackfillCommissionPostResponse = routeInfo['POST /v1/admin/backfillCommission']['responseCodec'];
export type AdminBackfillCommissionPostResponse = t.TypeOf<typeof AdminBackfillCommissionPostResponse>;
export const AdminBackfillCommissionPostInput = routeInfo['POST /v1/admin/backfillCommission']['inputCodec'];
export type AdminBackfillCommissionPostInput = t.TypeOf<typeof AdminBackfillCommissionPostInput>;
export const AdminAccountFlagDefinitionsGetResponse = routeInfo['GET /v1/admin/account_flag_definitions']['responseCodec'];
export type AdminAccountFlagDefinitionsGetResponse = t.TypeOf<typeof AdminAccountFlagDefinitionsGetResponse>;
export const AdminAccountFlagDefinitionsGetInput = routeInfo['GET /v1/admin/account_flag_definitions']['inputCodec'];
export type AdminAccountFlagDefinitionsGetInput = t.TypeOf<typeof AdminAccountFlagDefinitionsGetInput>;
export const AdminAccountFlagDefinitionsPatchResponse = routeInfo['PATCH /v1/admin/account_flag_definitions']['responseCodec'];
export type AdminAccountFlagDefinitionsPatchResponse = t.TypeOf<typeof AdminAccountFlagDefinitionsPatchResponse>;
export const AdminAccountFlagDefinitionsPatchInput = routeInfo['PATCH /v1/admin/account_flag_definitions']['inputCodec'];
export type AdminAccountFlagDefinitionsPatchInput = t.TypeOf<typeof AdminAccountFlagDefinitionsPatchInput>;
export const AdminAccountTagsGetResponse = routeInfo['GET /v1/admin/account_tags']['responseCodec'];
export type AdminAccountTagsGetResponse = t.TypeOf<typeof AdminAccountTagsGetResponse>;
export const AdminAccountTagsGetInput = routeInfo['GET /v1/admin/account_tags']['inputCodec'];
export type AdminAccountTagsGetInput = t.TypeOf<typeof AdminAccountTagsGetInput>;
export const AdminCategoriesGetResponse = routeInfo['GET /v1/admin/categories']['responseCodec'];
export type AdminCategoriesGetResponse = t.TypeOf<typeof AdminCategoriesGetResponse>;
export const AdminCategoriesGetInput = routeInfo['GET /v1/admin/categories']['inputCodec'];
export type AdminCategoriesGetInput = t.TypeOf<typeof AdminCategoriesGetInput>;
export const AdminCategoriesPostResponse = routeInfo['POST /v1/admin/categories']['responseCodec'];
export type AdminCategoriesPostResponse = t.TypeOf<typeof AdminCategoriesPostResponse>;
export const AdminCategoriesPostInput = routeInfo['POST /v1/admin/categories']['inputCodec'];
export type AdminCategoriesPostInput = t.TypeOf<typeof AdminCategoriesPostInput>;
export const AdminCategoriesCategoryIdGetResponse = routeInfo['GET /v1/admin/categories/{categoryId}']['responseCodec'];
export type AdminCategoriesCategoryIdGetResponse = t.TypeOf<typeof AdminCategoriesCategoryIdGetResponse>;
export const AdminCategoriesCategoryIdGetInput = routeInfo['GET /v1/admin/categories/{categoryId}']['inputCodec'];
export type AdminCategoriesCategoryIdGetInput = t.TypeOf<typeof AdminCategoriesCategoryIdGetInput>;
export const AdminCategoriesCategoryIdPutResponse = routeInfo['PUT /v1/admin/categories/{categoryId}']['responseCodec'];
export type AdminCategoriesCategoryIdPutResponse = t.TypeOf<typeof AdminCategoriesCategoryIdPutResponse>;
export const AdminCategoriesCategoryIdPutInput = routeInfo['PUT /v1/admin/categories/{categoryId}']['inputCodec'];
export type AdminCategoriesCategoryIdPutInput = t.TypeOf<typeof AdminCategoriesCategoryIdPutInput>;
export const AdminCategoriesCategoryIdMigratePutResponse = routeInfo['PUT /v1/admin/categories/{categoryId}/migrate']['responseCodec'];
export type AdminCategoriesCategoryIdMigratePutResponse = t.TypeOf<typeof AdminCategoriesCategoryIdMigratePutResponse>;
export const AdminCategoriesCategoryIdMigratePutInput = routeInfo['PUT /v1/admin/categories/{categoryId}/migrate']['inputCodec'];
export type AdminCategoriesCategoryIdMigratePutInput = t.TypeOf<typeof AdminCategoriesCategoryIdMigratePutInput>;
export const AdminCategoriesCategoryIdClearPutResponse = routeInfo['PUT /v1/admin/categories/{categoryId}/clear']['responseCodec'];
export type AdminCategoriesCategoryIdClearPutResponse = t.TypeOf<typeof AdminCategoriesCategoryIdClearPutResponse>;
export const AdminCategoriesCategoryIdClearPutInput = routeInfo['PUT /v1/admin/categories/{categoryId}/clear']['inputCodec'];
export type AdminCategoriesCategoryIdClearPutInput = t.TypeOf<typeof AdminCategoriesCategoryIdClearPutInput>;
export const AdminCategoriesCategoryIdVariantRelationshipsPatchResponse = routeInfo['PATCH /v1/admin/categories/{categoryId}/variantRelationships']['responseCodec'];
export type AdminCategoriesCategoryIdVariantRelationshipsPatchResponse = t.TypeOf<typeof AdminCategoriesCategoryIdVariantRelationshipsPatchResponse>;
export const AdminCategoriesCategoryIdVariantRelationshipsPatchInput = routeInfo['PATCH /v1/admin/categories/{categoryId}/variantRelationships']['inputCodec'];
export type AdminCategoriesCategoryIdVariantRelationshipsPatchInput = t.TypeOf<typeof AdminCategoriesCategoryIdVariantRelationshipsPatchInput>;
export const AdminCategoryAccountRelationshipsGetResponse = routeInfo['GET /v1/admin/categoryAccountRelationships']['responseCodec'];
export type AdminCategoryAccountRelationshipsGetResponse = t.TypeOf<typeof AdminCategoryAccountRelationshipsGetResponse>;
export const AdminCategoryAccountRelationshipsGetInput = routeInfo['GET /v1/admin/categoryAccountRelationships']['inputCodec'];
export type AdminCategoryAccountRelationshipsGetInput = t.TypeOf<typeof AdminCategoryAccountRelationshipsGetInput>;
export const AdminCategoriesCategoryIdAccountRelationshipsPatchResponse = routeInfo['PATCH /v1/admin/categories/{categoryId}/accountRelationships']['responseCodec'];
export type AdminCategoriesCategoryIdAccountRelationshipsPatchResponse = t.TypeOf<typeof AdminCategoriesCategoryIdAccountRelationshipsPatchResponse>;
export const AdminCategoriesCategoryIdAccountRelationshipsPatchInput = routeInfo['PATCH /v1/admin/categories/{categoryId}/accountRelationships']['inputCodec'];
export type AdminCategoriesCategoryIdAccountRelationshipsPatchInput = t.TypeOf<typeof AdminCategoriesCategoryIdAccountRelationshipsPatchInput>;
export const AdminCategoryBuyerLocationRelationshipsGetResponse = routeInfo['GET /v1/admin/categoryBuyerLocationRelationships']['responseCodec'];
export type AdminCategoryBuyerLocationRelationshipsGetResponse = t.TypeOf<typeof AdminCategoryBuyerLocationRelationshipsGetResponse>;
export const AdminCategoryBuyerLocationRelationshipsGetInput = routeInfo['GET /v1/admin/categoryBuyerLocationRelationships']['inputCodec'];
export type AdminCategoryBuyerLocationRelationshipsGetInput = t.TypeOf<typeof AdminCategoryBuyerLocationRelationshipsGetInput>;
export const AdminCategoriesCategoryIdBuyerLocationRelationshipsPatchResponse = routeInfo['PATCH /v1/admin/categories/{categoryId}/buyerLocationRelationships']['responseCodec'];
export type AdminCategoriesCategoryIdBuyerLocationRelationshipsPatchResponse = t.TypeOf<typeof AdminCategoriesCategoryIdBuyerLocationRelationshipsPatchResponse>;
export const AdminCategoriesCategoryIdBuyerLocationRelationshipsPatchInput = routeInfo['PATCH /v1/admin/categories/{categoryId}/buyerLocationRelationships']['inputCodec'];
export type AdminCategoriesCategoryIdBuyerLocationRelationshipsPatchInput = t.TypeOf<typeof AdminCategoriesCategoryIdBuyerLocationRelationshipsPatchInput>;
export const AdminCategoriesCategoryIdDuplicatePostResponse = routeInfo['POST /v1/admin/categories/{categoryId}/duplicate']['responseCodec'];
export type AdminCategoriesCategoryIdDuplicatePostResponse = t.TypeOf<typeof AdminCategoriesCategoryIdDuplicatePostResponse>;
export const AdminCategoriesCategoryIdDuplicatePostInput = routeInfo['POST /v1/admin/categories/{categoryId}/duplicate']['inputCodec'];
export type AdminCategoriesCategoryIdDuplicatePostInput = t.TypeOf<typeof AdminCategoriesCategoryIdDuplicatePostInput>;
export const AdminBulkCategoriesPutResponse = routeInfo['PUT /v1/admin/bulkCategories']['responseCodec'];
export type AdminBulkCategoriesPutResponse = t.TypeOf<typeof AdminBulkCategoriesPutResponse>;
export const AdminBulkCategoriesPutInput = routeInfo['PUT /v1/admin/bulkCategories']['inputCodec'];
export type AdminBulkCategoriesPutInput = t.TypeOf<typeof AdminBulkCategoriesPutInput>;
export const AdminPartnerPartnerAccountNamePricingReportsGetResponse = routeInfo['GET /v1/admin/partner/{partnerAccountName}/pricingReports']['responseCodec'];
export type AdminPartnerPartnerAccountNamePricingReportsGetResponse = t.TypeOf<typeof AdminPartnerPartnerAccountNamePricingReportsGetResponse>;
export const AdminPartnerPartnerAccountNamePricingReportsGetInput = routeInfo['GET /v1/admin/partner/{partnerAccountName}/pricingReports']['inputCodec'];
export type AdminPartnerPartnerAccountNamePricingReportsGetInput = t.TypeOf<typeof AdminPartnerPartnerAccountNamePricingReportsGetInput>;
export const AdminPartnerPartnerAccountNameRenderPricingCsvPostResponse = routeInfo['POST /v1/admin/partner/{partnerAccountName}/renderPricingCsv']['responseCodec'];
export type AdminPartnerPartnerAccountNameRenderPricingCsvPostResponse = t.TypeOf<typeof AdminPartnerPartnerAccountNameRenderPricingCsvPostResponse>;
export const AdminPartnerPartnerAccountNameRenderPricingCsvPostInput = routeInfo['POST /v1/admin/partner/{partnerAccountName}/renderPricingCsv']['inputCodec'];
export type AdminPartnerPartnerAccountNameRenderPricingCsvPostInput = t.TypeOf<typeof AdminPartnerPartnerAccountNameRenderPricingCsvPostInput>;
export const AdminPartnerPartnerAccountNameRenderPricingCsvPricingReportIdGetResponse = routeInfo['GET /v1/admin/partner/{partnerAccountName}/renderPricingCsv/{pricingReportId}']['responseCodec'];
export type AdminPartnerPartnerAccountNameRenderPricingCsvPricingReportIdGetResponse = t.TypeOf<typeof AdminPartnerPartnerAccountNameRenderPricingCsvPricingReportIdGetResponse>;
export const AdminPartnerPartnerAccountNameRenderPricingCsvPricingReportIdGetInput = routeInfo['GET /v1/admin/partner/{partnerAccountName}/renderPricingCsv/{pricingReportId}']['inputCodec'];
export type AdminPartnerPartnerAccountNameRenderPricingCsvPricingReportIdGetInput = t.TypeOf<typeof AdminPartnerPartnerAccountNameRenderPricingCsvPricingReportIdGetInput>;
export const AdminMclaneCommissionAuditCsvPostResponse = routeInfo['POST /v1/admin/mclaneCommissionAuditCSV']['responseCodec'];
export type AdminMclaneCommissionAuditCsvPostResponse = t.TypeOf<typeof AdminMclaneCommissionAuditCsvPostResponse>;
export const AdminMclaneCommissionAuditCsvPostInput = routeInfo['POST /v1/admin/mclaneCommissionAuditCSV']['inputCodec'];
export type AdminMclaneCommissionAuditCsvPostInput = t.TypeOf<typeof AdminMclaneCommissionAuditCsvPostInput>;
export const AdminPartnerNamePriceConfirmationPostResponse = routeInfo['POST /v1/admin/{partnerName}/priceConfirmation']['responseCodec'];
export type AdminPartnerNamePriceConfirmationPostResponse = t.TypeOf<typeof AdminPartnerNamePriceConfirmationPostResponse>;
export const AdminPartnerNamePriceConfirmationPostInput = routeInfo['POST /v1/admin/{partnerName}/priceConfirmation']['inputCodec'];
export type AdminPartnerNamePriceConfirmationPostInput = t.TypeOf<typeof AdminPartnerNamePriceConfirmationPostInput>;
export const AdminMclaneUinImportPostResponse = routeInfo['POST /v1/admin/mclaneUinImport']['responseCodec'];
export type AdminMclaneUinImportPostResponse = t.TypeOf<typeof AdminMclaneUinImportPostResponse>;
export const AdminMclaneUinImportPostInput = routeInfo['POST /v1/admin/mclaneUinImport']['inputCodec'];
export type AdminMclaneUinImportPostInput = t.TypeOf<typeof AdminMclaneUinImportPostInput>;
export const AdminDashboardGetResponse = routeInfo['GET /v1/admin/dashboard']['responseCodec'];
export type AdminDashboardGetResponse = t.TypeOf<typeof AdminDashboardGetResponse>;
export const AdminDashboardGetInput = routeInfo['GET /v1/admin/dashboard']['inputCodec'];
export type AdminDashboardGetInput = t.TypeOf<typeof AdminDashboardGetInput>;
export const AdminJobsSummaryGetResponse = routeInfo['GET /v1/admin/jobsSummary']['responseCodec'];
export type AdminJobsSummaryGetResponse = t.TypeOf<typeof AdminJobsSummaryGetResponse>;
export const AdminJobsSummaryGetInput = routeInfo['GET /v1/admin/jobsSummary']['inputCodec'];
export type AdminJobsSummaryGetInput = t.TypeOf<typeof AdminJobsSummaryGetInput>;
export const AdminJobsGetResponse = routeInfo['GET /v1/admin/jobs']['responseCodec'];
export type AdminJobsGetResponse = t.TypeOf<typeof AdminJobsGetResponse>;
export const AdminJobsGetInput = routeInfo['GET /v1/admin/jobs']['inputCodec'];
export type AdminJobsGetInput = t.TypeOf<typeof AdminJobsGetInput>;
export const AdminJobsPostResponse = routeInfo['POST /v1/admin/jobs']['responseCodec'];
export type AdminJobsPostResponse = t.TypeOf<typeof AdminJobsPostResponse>;
export const AdminJobsPostInput = routeInfo['POST /v1/admin/jobs']['inputCodec'];
export type AdminJobsPostInput = t.TypeOf<typeof AdminJobsPostInput>;
export const AdminFileUploadsFileUploadIdSignedDownloadUrlPostResponse = routeInfo['POST /v1/admin/fileUploads/{fileUploadId}/signedDownloadUrl']['responseCodec'];
export type AdminFileUploadsFileUploadIdSignedDownloadUrlPostResponse = t.TypeOf<typeof AdminFileUploadsFileUploadIdSignedDownloadUrlPostResponse>;
export const AdminFileUploadsFileUploadIdSignedDownloadUrlPostInput = routeInfo['POST /v1/admin/fileUploads/{fileUploadId}/signedDownloadUrl']['inputCodec'];
export type AdminFileUploadsFileUploadIdSignedDownloadUrlPostInput = t.TypeOf<typeof AdminFileUploadsFileUploadIdSignedDownloadUrlPostInput>;
export const MobileConfigPostResponse = routeInfo['POST /v1/mobile-config']['responseCodec'];
export type MobileConfigPostResponse = t.TypeOf<typeof MobileConfigPostResponse>;
export const MobileConfigPostInput = routeInfo['POST /v1/mobile-config']['inputCodec'];
export type MobileConfigPostInput = t.TypeOf<typeof MobileConfigPostInput>;
export const OrdersPostResponse = routeInfo['POST /v1/orders']['responseCodec'];
export type OrdersPostResponse = t.TypeOf<typeof OrdersPostResponse>;
export const OrdersPostInput = routeInfo['POST /v1/orders']['inputCodec'];
export type OrdersPostInput = t.TypeOf<typeof OrdersPostInput>;
export const OrdersGetResponse = routeInfo['GET /v1/orders']['responseCodec'];
export type OrdersGetResponse = t.TypeOf<typeof OrdersGetResponse>;
export const OrdersGetInput = routeInfo['GET /v1/orders']['inputCodec'];
export type OrdersGetInput = t.TypeOf<typeof OrdersGetInput>;
export const OrdersOrderIdGetResponse = routeInfo['GET /v1/orders/{orderId}']['responseCodec'];
export type OrdersOrderIdGetResponse = t.TypeOf<typeof OrdersOrderIdGetResponse>;
export const OrdersOrderIdGetInput = routeInfo['GET /v1/orders/{orderId}']['inputCodec'];
export type OrdersOrderIdGetInput = t.TypeOf<typeof OrdersOrderIdGetInput>;
export const OrdersOrderIdAttachmentsAttachmentIdSignedUrlGetResponse = routeInfo['GET /v1/orders/{orderId}/attachments/{attachmentId}/signedUrl']['responseCodec'];
export type OrdersOrderIdAttachmentsAttachmentIdSignedUrlGetResponse = t.TypeOf<typeof OrdersOrderIdAttachmentsAttachmentIdSignedUrlGetResponse>;
export const OrdersOrderIdAttachmentsAttachmentIdSignedUrlGetInput = routeInfo['GET /v1/orders/{orderId}/attachments/{attachmentId}/signedUrl']['inputCodec'];
export type OrdersOrderIdAttachmentsAttachmentIdSignedUrlGetInput = t.TypeOf<typeof OrdersOrderIdAttachmentsAttachmentIdSignedUrlGetInput>;
export const AdminOrdersOrderIdSendPurchaseOrderEmailPostResponse = routeInfo['POST /v1/admin/orders/{orderId}/sendPurchaseOrder/email']['responseCodec'];
export type AdminOrdersOrderIdSendPurchaseOrderEmailPostResponse = t.TypeOf<typeof AdminOrdersOrderIdSendPurchaseOrderEmailPostResponse>;
export const AdminOrdersOrderIdSendPurchaseOrderEmailPostInput = routeInfo['POST /v1/admin/orders/{orderId}/sendPurchaseOrder/email']['inputCodec'];
export type AdminOrdersOrderIdSendPurchaseOrderEmailPostInput = t.TypeOf<typeof AdminOrdersOrderIdSendPurchaseOrderEmailPostInput>;
export const OrdersOrderIdShipToAddressPostResponse = routeInfo['POST /v1/orders/{orderId}/shipToAddress']['responseCodec'];
export type OrdersOrderIdShipToAddressPostResponse = t.TypeOf<typeof OrdersOrderIdShipToAddressPostResponse>;
export const OrdersOrderIdShipToAddressPostInput = routeInfo['POST /v1/orders/{orderId}/shipToAddress']['inputCodec'];
export type OrdersOrderIdShipToAddressPostInput = t.TypeOf<typeof OrdersOrderIdShipToAddressPostInput>;
export const OrdersOrderIdBuyShippingMetaDataGetResponse = routeInfo['GET /v1/orders/{orderId}/buyShippingMetaData']['responseCodec'];
export type OrdersOrderIdBuyShippingMetaDataGetResponse = t.TypeOf<typeof OrdersOrderIdBuyShippingMetaDataGetResponse>;
export const OrdersOrderIdBuyShippingMetaDataGetInput = routeInfo['GET /v1/orders/{orderId}/buyShippingMetaData']['inputCodec'];
export type OrdersOrderIdBuyShippingMetaDataGetInput = t.TypeOf<typeof OrdersOrderIdBuyShippingMetaDataGetInput>;
export const OrdersOrderIdShipmentGroupPostResponse = routeInfo['POST /v1/orders/{orderId}/shipmentGroup']['responseCodec'];
export type OrdersOrderIdShipmentGroupPostResponse = t.TypeOf<typeof OrdersOrderIdShipmentGroupPostResponse>;
export const OrdersOrderIdShipmentGroupPostInput = routeInfo['POST /v1/orders/{orderId}/shipmentGroup']['inputCodec'];
export type OrdersOrderIdShipmentGroupPostInput = t.TypeOf<typeof OrdersOrderIdShipmentGroupPostInput>;
export const OrdersOrderIdShipmentGroupBuyPostResponse = routeInfo['POST /v1/orders/{orderId}/shipmentGroup/buy']['responseCodec'];
export type OrdersOrderIdShipmentGroupBuyPostResponse = t.TypeOf<typeof OrdersOrderIdShipmentGroupBuyPostResponse>;
export const OrdersOrderIdShipmentGroupBuyPostInput = routeInfo['POST /v1/orders/{orderId}/shipmentGroup/buy']['inputCodec'];
export type OrdersOrderIdShipmentGroupBuyPostInput = t.TypeOf<typeof OrdersOrderIdShipmentGroupBuyPostInput>;
export const AdminOrdersOrderIdSendBuyerConfirmationPostResponse = routeInfo['POST /v1/admin/orders/{orderId}/sendBuyerConfirmation']['responseCodec'];
export type AdminOrdersOrderIdSendBuyerConfirmationPostResponse = t.TypeOf<typeof AdminOrdersOrderIdSendBuyerConfirmationPostResponse>;
export const AdminOrdersOrderIdSendBuyerConfirmationPostInput = routeInfo['POST /v1/admin/orders/{orderId}/sendBuyerConfirmation']['inputCodec'];
export type AdminOrdersOrderIdSendBuyerConfirmationPostInput = t.TypeOf<typeof AdminOrdersOrderIdSendBuyerConfirmationPostInput>;
export const AdminOrdersOrderIdShipToAddressPostResponse = routeInfo['POST /v1/admin/orders/{orderId}/shipToAddress']['responseCodec'];
export type AdminOrdersOrderIdShipToAddressPostResponse = t.TypeOf<typeof AdminOrdersOrderIdShipToAddressPostResponse>;
export const AdminOrdersOrderIdShipToAddressPostInput = routeInfo['POST /v1/admin/orders/{orderId}/shipToAddress']['inputCodec'];
export type AdminOrdersOrderIdShipToAddressPostInput = t.TypeOf<typeof AdminOrdersOrderIdShipToAddressPostInput>;
export const AdminOrdersOrderIdSendBuyerConfirmationSftpPostResponse = routeInfo['POST /v1/admin/orders/{orderId}/sendBuyerConfirmation/sftp']['responseCodec'];
export type AdminOrdersOrderIdSendBuyerConfirmationSftpPostResponse = t.TypeOf<typeof AdminOrdersOrderIdSendBuyerConfirmationSftpPostResponse>;
export const AdminOrdersOrderIdSendBuyerConfirmationSftpPostInput = routeInfo['POST /v1/admin/orders/{orderId}/sendBuyerConfirmation/sftp']['inputCodec'];
export type AdminOrdersOrderIdSendBuyerConfirmationSftpPostInput = t.TypeOf<typeof AdminOrdersOrderIdSendBuyerConfirmationSftpPostInput>;
export const AdminOrdersOrderIdSendPurchaseOrderSftpPostResponse = routeInfo['POST /v1/admin/orders/{orderId}/sendPurchaseOrder/sftp']['responseCodec'];
export type AdminOrdersOrderIdSendPurchaseOrderSftpPostResponse = t.TypeOf<typeof AdminOrdersOrderIdSendPurchaseOrderSftpPostResponse>;
export const AdminOrdersOrderIdSendPurchaseOrderSftpPostInput = routeInfo['POST /v1/admin/orders/{orderId}/sendPurchaseOrder/sftp']['inputCodec'];
export type AdminOrdersOrderIdSendPurchaseOrderSftpPostInput = t.TypeOf<typeof AdminOrdersOrderIdSendPurchaseOrderSftpPostInput>;
export const OrdersOrderIdForwardInvoicePostResponse = routeInfo['POST /v1/orders/{orderId}/forwardInvoice']['responseCodec'];
export type OrdersOrderIdForwardInvoicePostResponse = t.TypeOf<typeof OrdersOrderIdForwardInvoicePostResponse>;
export const OrdersOrderIdForwardInvoicePostInput = routeInfo['POST /v1/orders/{orderId}/forwardInvoice']['inputCodec'];
export type OrdersOrderIdForwardInvoicePostInput = t.TypeOf<typeof OrdersOrderIdForwardInvoicePostInput>;
export const OrdersOrderIdDraftInvoicePostResponse = routeInfo['POST /v1/orders/{orderId}/draftInvoice']['responseCodec'];
export type OrdersOrderIdDraftInvoicePostResponse = t.TypeOf<typeof OrdersOrderIdDraftInvoicePostResponse>;
export const OrdersOrderIdDraftInvoicePostInput = routeInfo['POST /v1/orders/{orderId}/draftInvoice']['inputCodec'];
export type OrdersOrderIdDraftInvoicePostInput = t.TypeOf<typeof OrdersOrderIdDraftInvoicePostInput>;
export const OrdersOrderIdInvoiceUploadedPutResponse = routeInfo['PUT /v1/orders/{orderId}/invoiceUploaded']['responseCodec'];
export type OrdersOrderIdInvoiceUploadedPutResponse = t.TypeOf<typeof OrdersOrderIdInvoiceUploadedPutResponse>;
export const OrdersOrderIdInvoiceUploadedPutInput = routeInfo['PUT /v1/orders/{orderId}/invoiceUploaded']['inputCodec'];
export type OrdersOrderIdInvoiceUploadedPutInput = t.TypeOf<typeof OrdersOrderIdInvoiceUploadedPutInput>;
export const OrdersOrderIdAcceptPutResponse = routeInfo['PUT /v1/orders/{orderId}/accept']['responseCodec'];
export type OrdersOrderIdAcceptPutResponse = t.TypeOf<typeof OrdersOrderIdAcceptPutResponse>;
export const OrdersOrderIdAcceptPutInput = routeInfo['PUT /v1/orders/{orderId}/accept']['inputCodec'];
export type OrdersOrderIdAcceptPutInput = t.TypeOf<typeof OrdersOrderIdAcceptPutInput>;
export const OrdersOrderIdModifyPostResponse = routeInfo['POST /v1/orders/{orderId}/modify']['responseCodec'];
export type OrdersOrderIdModifyPostResponse = t.TypeOf<typeof OrdersOrderIdModifyPostResponse>;
export const OrdersOrderIdModifyPostInput = routeInfo['POST /v1/orders/{orderId}/modify']['inputCodec'];
export type OrdersOrderIdModifyPostInput = t.TypeOf<typeof OrdersOrderIdModifyPostInput>;
export const SellersSellerIdOrMeRetailUnitsGetResponse = routeInfo['GET /v1/sellers/{sellerIdOrMe}/retailUnits']['responseCodec'];
export type SellersSellerIdOrMeRetailUnitsGetResponse = t.TypeOf<typeof SellersSellerIdOrMeRetailUnitsGetResponse>;
export const SellersSellerIdOrMeRetailUnitsGetInput = routeInfo['GET /v1/sellers/{sellerIdOrMe}/retailUnits']['inputCodec'];
export type SellersSellerIdOrMeRetailUnitsGetInput = t.TypeOf<typeof SellersSellerIdOrMeRetailUnitsGetInput>;
export const SellersSellerIdOrMeRetailUnitsRetailUnitIdGetResponse = routeInfo['GET /v1/sellers/{sellerIdOrMe}/retailUnits/{retailUnitId}']['responseCodec'];
export type SellersSellerIdOrMeRetailUnitsRetailUnitIdGetResponse = t.TypeOf<typeof SellersSellerIdOrMeRetailUnitsRetailUnitIdGetResponse>;
export const SellersSellerIdOrMeRetailUnitsRetailUnitIdGetInput = routeInfo['GET /v1/sellers/{sellerIdOrMe}/retailUnits/{retailUnitId}']['inputCodec'];
export type SellersSellerIdOrMeRetailUnitsRetailUnitIdGetInput = t.TypeOf<typeof SellersSellerIdOrMeRetailUnitsRetailUnitIdGetInput>;
export const SellersSellerIdOrMeRetailUnitsRetailUnitIdPatchResponse = routeInfo['PATCH /v1/sellers/{sellerIdOrMe}/retailUnits/{retailUnitId}']['responseCodec'];
export type SellersSellerIdOrMeRetailUnitsRetailUnitIdPatchResponse = t.TypeOf<typeof SellersSellerIdOrMeRetailUnitsRetailUnitIdPatchResponse>;
export const SellersSellerIdOrMeRetailUnitsRetailUnitIdPatchInput = routeInfo['PATCH /v1/sellers/{sellerIdOrMe}/retailUnits/{retailUnitId}']['inputCodec'];
export type SellersSellerIdOrMeRetailUnitsRetailUnitIdPatchInput = t.TypeOf<typeof SellersSellerIdOrMeRetailUnitsRetailUnitIdPatchInput>;
export const SellersSellerIdOrMeDataIssuesGetResponse = routeInfo['GET /v1/sellers/{sellerIdOrMe}/dataIssues']['responseCodec'];
export type SellersSellerIdOrMeDataIssuesGetResponse = t.TypeOf<typeof SellersSellerIdOrMeDataIssuesGetResponse>;
export const SellersSellerIdOrMeDataIssuesGetInput = routeInfo['GET /v1/sellers/{sellerIdOrMe}/dataIssues']['inputCodec'];
export type SellersSellerIdOrMeDataIssuesGetInput = t.TypeOf<typeof SellersSellerIdOrMeDataIssuesGetInput>;
export const SellersSellerIdOrMeDataIssuesRetailUnitsRetailUnitIdGetResponse = routeInfo['GET /v1/sellers/{sellerIdOrMe}/dataIssues/retailUnits/{retailUnitId}']['responseCodec'];
export type SellersSellerIdOrMeDataIssuesRetailUnitsRetailUnitIdGetResponse = t.TypeOf<typeof SellersSellerIdOrMeDataIssuesRetailUnitsRetailUnitIdGetResponse>;
export const SellersSellerIdOrMeDataIssuesRetailUnitsRetailUnitIdGetInput = routeInfo['GET /v1/sellers/{sellerIdOrMe}/dataIssues/retailUnits/{retailUnitId}']['inputCodec'];
export type SellersSellerIdOrMeDataIssuesRetailUnitsRetailUnitIdGetInput = t.TypeOf<typeof SellersSellerIdOrMeDataIssuesRetailUnitsRetailUnitIdGetInput>;
export const ProductsGetResponse = routeInfo['GET /v1/products']['responseCodec'];
export type ProductsGetResponse = t.TypeOf<typeof ProductsGetResponse>;
export const ProductsGetInput = routeInfo['GET /v1/products']['inputCodec'];
export type ProductsGetInput = t.TypeOf<typeof ProductsGetInput>;
export const ProductsPostResponse = routeInfo['POST /v1/products']['responseCodec'];
export type ProductsPostResponse = t.TypeOf<typeof ProductsPostResponse>;
export const ProductsPostInput = routeInfo['POST /v1/products']['inputCodec'];
export type ProductsPostInput = t.TypeOf<typeof ProductsPostInput>;
export const ProductVariantsPatchResponse = routeInfo['PATCH /v1/productVariants']['responseCodec'];
export type ProductVariantsPatchResponse = t.TypeOf<typeof ProductVariantsPatchResponse>;
export const ProductVariantsPatchInput = routeInfo['PATCH /v1/productVariants']['inputCodec'];
export type ProductVariantsPatchInput = t.TypeOf<typeof ProductVariantsPatchInput>;
export const AdminProductVariantsPatchResponse = routeInfo['PATCH /v1/admin/productVariants']['responseCodec'];
export type AdminProductVariantsPatchResponse = t.TypeOf<typeof AdminProductVariantsPatchResponse>;
export const AdminProductVariantsPatchInput = routeInfo['PATCH /v1/admin/productVariants']['inputCodec'];
export type AdminProductVariantsPatchInput = t.TypeOf<typeof AdminProductVariantsPatchInput>;
export const ProductsProductIdDetailGetResponse = routeInfo['GET /v1/products/{productId}/detail']['responseCodec'];
export type ProductsProductIdDetailGetResponse = t.TypeOf<typeof ProductsProductIdDetailGetResponse>;
export const ProductsProductIdDetailGetInput = routeInfo['GET /v1/products/{productId}/detail']['inputCodec'];
export type ProductsProductIdDetailGetInput = t.TypeOf<typeof ProductsProductIdDetailGetInput>;
export const ProductsProductIdGetResponse = routeInfo['GET /v1/products/{productId}']['responseCodec'];
export type ProductsProductIdGetResponse = t.TypeOf<typeof ProductsProductIdGetResponse>;
export const ProductsProductIdGetInput = routeInfo['GET /v1/products/{productId}']['inputCodec'];
export type ProductsProductIdGetInput = t.TypeOf<typeof ProductsProductIdGetInput>;
export const ProductsProductIdPutResponse = routeInfo['PUT /v1/products/{productId}']['responseCodec'];
export type ProductsProductIdPutResponse = t.TypeOf<typeof ProductsProductIdPutResponse>;
export const ProductsProductIdPutInput = routeInfo['PUT /v1/products/{productId}']['inputCodec'];
export type ProductsProductIdPutInput = t.TypeOf<typeof ProductsProductIdPutInput>;
export const ProductsProductIdPatchResponse = routeInfo['PATCH /v1/products/{productId}']['responseCodec'];
export type ProductsProductIdPatchResponse = t.TypeOf<typeof ProductsProductIdPatchResponse>;
export const ProductsProductIdPatchInput = routeInfo['PATCH /v1/products/{productId}']['inputCodec'];
export type ProductsProductIdPatchInput = t.TypeOf<typeof ProductsProductIdPatchInput>;
export const ProductsLegacyProductSlugGetResponse = routeInfo['GET /v1/products/legacy/{productSlug}']['responseCodec'];
export type ProductsLegacyProductSlugGetResponse = t.TypeOf<typeof ProductsLegacyProductSlugGetResponse>;
export const ProductsLegacyProductSlugGetInput = routeInfo['GET /v1/products/legacy/{productSlug}']['inputCodec'];
export type ProductsLegacyProductSlugGetInput = t.TypeOf<typeof ProductsLegacyProductSlugGetInput>;
export const ReferralPostResponse = routeInfo['POST /v1/referral']['responseCodec'];
export type ReferralPostResponse = t.TypeOf<typeof ReferralPostResponse>;
export const ReferralPostInput = routeInfo['POST /v1/referral']['inputCodec'];
export type ReferralPostInput = t.TypeOf<typeof ReferralPostInput>;
export const BuyerReferralPostResponse = routeInfo['POST /v1/buyerReferral']['responseCodec'];
export type BuyerReferralPostResponse = t.TypeOf<typeof BuyerReferralPostResponse>;
export const BuyerReferralPostInput = routeInfo['POST /v1/buyerReferral']['inputCodec'];
export type BuyerReferralPostInput = t.TypeOf<typeof BuyerReferralPostInput>;
export const CloudinaryUploadSignaturePostResponse = routeInfo['POST /v1/cloudinaryUploadSignature']['responseCodec'];
export type CloudinaryUploadSignaturePostResponse = t.TypeOf<typeof CloudinaryUploadSignaturePostResponse>;
export const CloudinaryUploadSignaturePostInput = routeInfo['POST /v1/cloudinaryUploadSignature']['inputCodec'];
export type CloudinaryUploadSignaturePostInput = t.TypeOf<typeof CloudinaryUploadSignaturePostInput>;
export const S3SignedUrlPostResponse = routeInfo['POST /v1/s3SignedUrl']['responseCodec'];
export type S3SignedUrlPostResponse = t.TypeOf<typeof S3SignedUrlPostResponse>;
export const S3SignedUrlPostInput = routeInfo['POST /v1/s3SignedUrl']['inputCodec'];
export type S3SignedUrlPostInput = t.TypeOf<typeof S3SignedUrlPostInput>;
export const InvoiceSignedUrlPostResponse = routeInfo['POST /v1/invoiceSignedUrl']['responseCodec'];
export type InvoiceSignedUrlPostResponse = t.TypeOf<typeof InvoiceSignedUrlPostResponse>;
export const InvoiceSignedUrlPostInput = routeInfo['POST /v1/invoiceSignedUrl']['inputCodec'];
export type InvoiceSignedUrlPostInput = t.TypeOf<typeof InvoiceSignedUrlPostInput>;
export const SearchPostResponse = routeInfo['POST /v1/search']['responseCodec'];
export type SearchPostResponse = t.TypeOf<typeof SearchPostResponse>;
export const SearchPostInput = routeInfo['POST /v1/search']['inputCodec'];
export type SearchPostInput = t.TypeOf<typeof SearchPostInput>;
export const SearchBarcodeGetResponse = routeInfo['GET /v1/search/barcode']['responseCodec'];
export type SearchBarcodeGetResponse = t.TypeOf<typeof SearchBarcodeGetResponse>;
export const SearchBarcodeGetInput = routeInfo['GET /v1/search/barcode']['inputCodec'];
export type SearchBarcodeGetInput = t.TypeOf<typeof SearchBarcodeGetInput>;
export const SearchSuggestGetResponse = routeInfo['GET /v1/search/suggest']['responseCodec'];
export type SearchSuggestGetResponse = t.TypeOf<typeof SearchSuggestGetResponse>;
export const SearchSuggestGetInput = routeInfo['GET /v1/search/suggest']['inputCodec'];
export type SearchSuggestGetInput = t.TypeOf<typeof SearchSuggestGetInput>;
export const AdminSearchSuggestionGetResponse = routeInfo['GET /v1/admin/search/suggestion']['responseCodec'];
export type AdminSearchSuggestionGetResponse = t.TypeOf<typeof AdminSearchSuggestionGetResponse>;
export const AdminSearchSuggestionGetInput = routeInfo['GET /v1/admin/search/suggestion']['inputCodec'];
export type AdminSearchSuggestionGetInput = t.TypeOf<typeof AdminSearchSuggestionGetInput>;
export const AdminSearchSuggestionPostResponse = routeInfo['POST /v1/admin/search/suggestion']['responseCodec'];
export type AdminSearchSuggestionPostResponse = t.TypeOf<typeof AdminSearchSuggestionPostResponse>;
export const AdminSearchSuggestionPostInput = routeInfo['POST /v1/admin/search/suggestion']['inputCodec'];
export type AdminSearchSuggestionPostInput = t.TypeOf<typeof AdminSearchSuggestionPostInput>;
export const AdminSearchSuggestionDeleteResponse = routeInfo['DELETE /v1/admin/search/suggestion']['responseCodec'];
export type AdminSearchSuggestionDeleteResponse = t.TypeOf<typeof AdminSearchSuggestionDeleteResponse>;
export const AdminSearchSuggestionDeleteInput = routeInfo['DELETE /v1/admin/search/suggestion']['inputCodec'];
export type AdminSearchSuggestionDeleteInput = t.TypeOf<typeof AdminSearchSuggestionDeleteInput>;
export const UsersPostResponse = routeInfo['POST /v1/users']['responseCodec'];
export type UsersPostResponse = t.TypeOf<typeof UsersPostResponse>;
export const UsersPostInput = routeInfo['POST /v1/users']['inputCodec'];
export type UsersPostInput = t.TypeOf<typeof UsersPostInput>;
export const AdminAccountsGetResponse = routeInfo['GET /v1/admin/accounts']['responseCodec'];
export type AdminAccountsGetResponse = t.TypeOf<typeof AdminAccountsGetResponse>;
export const AdminAccountsGetInput = routeInfo['GET /v1/admin/accounts']['inputCodec'];
export type AdminAccountsGetInput = t.TypeOf<typeof AdminAccountsGetInput>;
export const AdminAccountAccountIdOrNameGetResponse = routeInfo['GET /v1/admin/account/{accountIdOrName}']['responseCodec'];
export type AdminAccountAccountIdOrNameGetResponse = t.TypeOf<typeof AdminAccountAccountIdOrNameGetResponse>;
export const AdminAccountAccountIdOrNameGetInput = routeInfo['GET /v1/admin/account/{accountIdOrName}']['inputCodec'];
export type AdminAccountAccountIdOrNameGetInput = t.TypeOf<typeof AdminAccountAccountIdOrNameGetInput>;
export const AdminAccountAccountIdPatchResponse = routeInfo['PATCH /v1/admin/account/{accountId}']['responseCodec'];
export type AdminAccountAccountIdPatchResponse = t.TypeOf<typeof AdminAccountAccountIdPatchResponse>;
export const AdminAccountAccountIdPatchInput = routeInfo['PATCH /v1/admin/account/{accountId}']['inputCodec'];
export type AdminAccountAccountIdPatchInput = t.TypeOf<typeof AdminAccountAccountIdPatchInput>;
export const AdminAccountAccountIdRelationshipsPostResponse = routeInfo['POST /v1/admin/account/{accountId}/relationships']['responseCodec'];
export type AdminAccountAccountIdRelationshipsPostResponse = t.TypeOf<typeof AdminAccountAccountIdRelationshipsPostResponse>;
export const AdminAccountAccountIdRelationshipsPostInput = routeInfo['POST /v1/admin/account/{accountId}/relationships']['inputCodec'];
export type AdminAccountAccountIdRelationshipsPostInput = t.TypeOf<typeof AdminAccountAccountIdRelationshipsPostInput>;
export const AdminAccountAccountIdRelationshipsPutResponse = routeInfo['PUT /v1/admin/account/{accountId}/relationships']['responseCodec'];
export type AdminAccountAccountIdRelationshipsPutResponse = t.TypeOf<typeof AdminAccountAccountIdRelationshipsPutResponse>;
export const AdminAccountAccountIdRelationshipsPutInput = routeInfo['PUT /v1/admin/account/{accountId}/relationships']['inputCodec'];
export type AdminAccountAccountIdRelationshipsPutInput = t.TypeOf<typeof AdminAccountAccountIdRelationshipsPutInput>;
export const AdminAccountAccountIdRelationshipsDeleteResponse = routeInfo['DELETE /v1/admin/account/{accountId}/relationships']['responseCodec'];
export type AdminAccountAccountIdRelationshipsDeleteResponse = t.TypeOf<typeof AdminAccountAccountIdRelationshipsDeleteResponse>;
export const AdminAccountAccountIdRelationshipsDeleteInput = routeInfo['DELETE /v1/admin/account/{accountId}/relationships']['inputCodec'];
export type AdminAccountAccountIdRelationshipsDeleteInput = t.TypeOf<typeof AdminAccountAccountIdRelationshipsDeleteInput>;
export const AdminAccountAccountIdOneTimeInvitationLinkPostResponse = routeInfo['POST /v1/admin/account/{accountId}/oneTimeInvitationLink']['responseCodec'];
export type AdminAccountAccountIdOneTimeInvitationLinkPostResponse = t.TypeOf<typeof AdminAccountAccountIdOneTimeInvitationLinkPostResponse>;
export const AdminAccountAccountIdOneTimeInvitationLinkPostInput = routeInfo['POST /v1/admin/account/{accountId}/oneTimeInvitationLink']['inputCodec'];
export type AdminAccountAccountIdOneTimeInvitationLinkPostInput = t.TypeOf<typeof AdminAccountAccountIdOneTimeInvitationLinkPostInput>;
export const AdminAccountAccountIdFlagOverridesPatchResponse = routeInfo['PATCH /v1/admin/account/{accountId}/flagOverrides']['responseCodec'];
export type AdminAccountAccountIdFlagOverridesPatchResponse = t.TypeOf<typeof AdminAccountAccountIdFlagOverridesPatchResponse>;
export const AdminAccountAccountIdFlagOverridesPatchInput = routeInfo['PATCH /v1/admin/account/{accountId}/flagOverrides']['inputCodec'];
export type AdminAccountAccountIdFlagOverridesPatchInput = t.TypeOf<typeof AdminAccountAccountIdFlagOverridesPatchInput>;
export const AdminAccountAccountIdApplyConfigurationPostResponse = routeInfo['POST /v1/admin/account/{accountId}/applyConfiguration']['responseCodec'];
export type AdminAccountAccountIdApplyConfigurationPostResponse = t.TypeOf<typeof AdminAccountAccountIdApplyConfigurationPostResponse>;
export const AdminAccountAccountIdApplyConfigurationPostInput = routeInfo['POST /v1/admin/account/{accountId}/applyConfiguration']['inputCodec'];
export type AdminAccountAccountIdApplyConfigurationPostInput = t.TypeOf<typeof AdminAccountAccountIdApplyConfigurationPostInput>;
export const AdminAccountAccountIdApplyPartnerConfigurationToPartneredBuyersPostResponse = routeInfo['POST /v1/admin/account/{accountId}/applyPartnerConfigurationToPartneredBuyers']['responseCodec'];
export type AdminAccountAccountIdApplyPartnerConfigurationToPartneredBuyersPostResponse = t.TypeOf<typeof AdminAccountAccountIdApplyPartnerConfigurationToPartneredBuyersPostResponse>;
export const AdminAccountAccountIdApplyPartnerConfigurationToPartneredBuyersPostInput = routeInfo['POST /v1/admin/account/{accountId}/applyPartnerConfigurationToPartneredBuyers']['inputCodec'];
export type AdminAccountAccountIdApplyPartnerConfigurationToPartneredBuyersPostInput = t.TypeOf<typeof AdminAccountAccountIdApplyPartnerConfigurationToPartneredBuyersPostInput>;
export const AdminAccountAccountIdCreateMessagingUsersPostResponse = routeInfo['POST /v1/admin/account/{accountId}/createMessagingUsers']['responseCodec'];
export type AdminAccountAccountIdCreateMessagingUsersPostResponse = t.TypeOf<typeof AdminAccountAccountIdCreateMessagingUsersPostResponse>;
export const AdminAccountAccountIdCreateMessagingUsersPostInput = routeInfo['POST /v1/admin/account/{accountId}/createMessagingUsers']['inputCodec'];
export type AdminAccountAccountIdCreateMessagingUsersPostInput = t.TypeOf<typeof AdminAccountAccountIdCreateMessagingUsersPostInput>;
export const AdminAccountAccountIdUpdateMessagingProfilesPostResponse = routeInfo['POST /v1/admin/account/{accountId}/updateMessagingProfiles']['responseCodec'];
export type AdminAccountAccountIdUpdateMessagingProfilesPostResponse = t.TypeOf<typeof AdminAccountAccountIdUpdateMessagingProfilesPostResponse>;
export const AdminAccountAccountIdUpdateMessagingProfilesPostInput = routeInfo['POST /v1/admin/account/{accountId}/updateMessagingProfiles']['inputCodec'];
export type AdminAccountAccountIdUpdateMessagingProfilesPostInput = t.TypeOf<typeof AdminAccountAccountIdUpdateMessagingProfilesPostInput>;
export const AdminAccountAccountIdMessageChannelsGetResponse = routeInfo['GET /v1/admin/account/{accountId}/messageChannels']['responseCodec'];
export type AdminAccountAccountIdMessageChannelsGetResponse = t.TypeOf<typeof AdminAccountAccountIdMessageChannelsGetResponse>;
export const AdminAccountAccountIdMessageChannelsGetInput = routeInfo['GET /v1/admin/account/{accountId}/messageChannels']['inputCodec'];
export type AdminAccountAccountIdMessageChannelsGetInput = t.TypeOf<typeof AdminAccountAccountIdMessageChannelsGetInput>;
export const AdminAccountAccountIdMessageChannelsDeleteResponse = routeInfo['DELETE /v1/admin/account/{accountId}/messageChannels']['responseCodec'];
export type AdminAccountAccountIdMessageChannelsDeleteResponse = t.TypeOf<typeof AdminAccountAccountIdMessageChannelsDeleteResponse>;
export const AdminAccountAccountIdMessageChannelsDeleteInput = routeInfo['DELETE /v1/admin/account/{accountId}/messageChannels']['inputCodec'];
export type AdminAccountAccountIdMessageChannelsDeleteInput = t.TypeOf<typeof AdminAccountAccountIdMessageChannelsDeleteInput>;
export const AdminAccountAccountIdBuyerLocationsGetResponse = routeInfo['GET /v1/admin/account/{accountId}/buyerLocations']['responseCodec'];
export type AdminAccountAccountIdBuyerLocationsGetResponse = t.TypeOf<typeof AdminAccountAccountIdBuyerLocationsGetResponse>;
export const AdminAccountAccountIdBuyerLocationsGetInput = routeInfo['GET /v1/admin/account/{accountId}/buyerLocations']['inputCodec'];
export type AdminAccountAccountIdBuyerLocationsGetInput = t.TypeOf<typeof AdminAccountAccountIdBuyerLocationsGetInput>;
export const AdminAccountAccountIdBuyerLocationsPostResponse = routeInfo['POST /v1/admin/account/{accountId}/buyerLocations']['responseCodec'];
export type AdminAccountAccountIdBuyerLocationsPostResponse = t.TypeOf<typeof AdminAccountAccountIdBuyerLocationsPostResponse>;
export const AdminAccountAccountIdBuyerLocationsPostInput = routeInfo['POST /v1/admin/account/{accountId}/buyerLocations']['inputCodec'];
export type AdminAccountAccountIdBuyerLocationsPostInput = t.TypeOf<typeof AdminAccountAccountIdBuyerLocationsPostInput>;
export const AdminAccountAccountIdAddressesGetResponse = routeInfo['GET /v1/admin/account/{accountId}/addresses']['responseCodec'];
export type AdminAccountAccountIdAddressesGetResponse = t.TypeOf<typeof AdminAccountAccountIdAddressesGetResponse>;
export const AdminAccountAccountIdAddressesGetInput = routeInfo['GET /v1/admin/account/{accountId}/addresses']['inputCodec'];
export type AdminAccountAccountIdAddressesGetInput = t.TypeOf<typeof AdminAccountAccountIdAddressesGetInput>;
export const AdminAccountAccountIdEdiConfigurationGetResponse = routeInfo['GET /v1/admin/account/{accountId}/ediConfiguration']['responseCodec'];
export type AdminAccountAccountIdEdiConfigurationGetResponse = t.TypeOf<typeof AdminAccountAccountIdEdiConfigurationGetResponse>;
export const AdminAccountAccountIdEdiConfigurationGetInput = routeInfo['GET /v1/admin/account/{accountId}/ediConfiguration']['inputCodec'];
export type AdminAccountAccountIdEdiConfigurationGetInput = t.TypeOf<typeof AdminAccountAccountIdEdiConfigurationGetInput>;
export const AdminAccountAccountIdEdiConfigurationPutResponse = routeInfo['PUT /v1/admin/account/{accountId}/ediConfiguration']['responseCodec'];
export type AdminAccountAccountIdEdiConfigurationPutResponse = t.TypeOf<typeof AdminAccountAccountIdEdiConfigurationPutResponse>;
export const AdminAccountAccountIdEdiConfigurationPutInput = routeInfo['PUT /v1/admin/account/{accountId}/ediConfiguration']['inputCodec'];
export type AdminAccountAccountIdEdiConfigurationPutInput = t.TypeOf<typeof AdminAccountAccountIdEdiConfigurationPutInput>;
export const AdminAccountAccountIdBulkLandedCostsPostResponse = routeInfo['POST /v1/admin/account/{accountId}/bulkLandedCosts']['responseCodec'];
export type AdminAccountAccountIdBulkLandedCostsPostResponse = t.TypeOf<typeof AdminAccountAccountIdBulkLandedCostsPostResponse>;
export const AdminAccountAccountIdBulkLandedCostsPostInput = routeInfo['POST /v1/admin/account/{accountId}/bulkLandedCosts']['inputCodec'];
export type AdminAccountAccountIdBulkLandedCostsPostInput = t.TypeOf<typeof AdminAccountAccountIdBulkLandedCostsPostInput>;
export const AdminAccountAccountIdVariantPartnershipsGetResponse = routeInfo['GET /v1/admin/account/{accountId}/variantPartnerships']['responseCodec'];
export type AdminAccountAccountIdVariantPartnershipsGetResponse = t.TypeOf<typeof AdminAccountAccountIdVariantPartnershipsGetResponse>;
export const AdminAccountAccountIdVariantPartnershipsGetInput = routeInfo['GET /v1/admin/account/{accountId}/variantPartnerships']['inputCodec'];
export type AdminAccountAccountIdVariantPartnershipsGetInput = t.TypeOf<typeof AdminAccountAccountIdVariantPartnershipsGetInput>;
export const AdminAccountAccountIdVariantPartnershipsPatchResponse = routeInfo['PATCH /v1/admin/account/{accountId}/variantPartnerships']['responseCodec'];
export type AdminAccountAccountIdVariantPartnershipsPatchResponse = t.TypeOf<typeof AdminAccountAccountIdVariantPartnershipsPatchResponse>;
export const AdminAccountAccountIdVariantPartnershipsPatchInput = routeInfo['PATCH /v1/admin/account/{accountId}/variantPartnerships']['inputCodec'];
export type AdminAccountAccountIdVariantPartnershipsPatchInput = t.TypeOf<typeof AdminAccountAccountIdVariantPartnershipsPatchInput>;
export const AdminAccountAccountIdVariantPartnershipsRefreshPostResponse = routeInfo['POST /v1/admin/account/{accountId}/variantPartnerships/refresh']['responseCodec'];
export type AdminAccountAccountIdVariantPartnershipsRefreshPostResponse = t.TypeOf<typeof AdminAccountAccountIdVariantPartnershipsRefreshPostResponse>;
export const AdminAccountAccountIdVariantPartnershipsRefreshPostInput = routeInfo['POST /v1/admin/account/{accountId}/variantPartnerships/refresh']['inputCodec'];
export type AdminAccountAccountIdVariantPartnershipsRefreshPostInput = t.TypeOf<typeof AdminAccountAccountIdVariantPartnershipsRefreshPostInput>;
export const AdminPartnerProgramPartnerNameUpdateLandedCostsPostResponse = routeInfo['POST /v1/admin/partnerProgram/{partnerName}/updateLandedCosts']['responseCodec'];
export type AdminPartnerProgramPartnerNameUpdateLandedCostsPostResponse = t.TypeOf<typeof AdminPartnerProgramPartnerNameUpdateLandedCostsPostResponse>;
export const AdminPartnerProgramPartnerNameUpdateLandedCostsPostInput = routeInfo['POST /v1/admin/partnerProgram/{partnerName}/updateLandedCosts']['inputCodec'];
export type AdminPartnerProgramPartnerNameUpdateLandedCostsPostInput = t.TypeOf<typeof AdminPartnerProgramPartnerNameUpdateLandedCostsPostInput>;
export const AdminBuyersBuyerIdUpdateLandedCostsPostResponse = routeInfo['POST /v1/admin/buyers/{buyerId}/updateLandedCosts']['responseCodec'];
export type AdminBuyersBuyerIdUpdateLandedCostsPostResponse = t.TypeOf<typeof AdminBuyersBuyerIdUpdateLandedCostsPostResponse>;
export const AdminBuyersBuyerIdUpdateLandedCostsPostInput = routeInfo['POST /v1/admin/buyers/{buyerId}/updateLandedCosts']['inputCodec'];
export type AdminBuyersBuyerIdUpdateLandedCostsPostInput = t.TypeOf<typeof AdminBuyersBuyerIdUpdateLandedCostsPostInput>;
export const AdminAccountAccountIdAuditLogGetResponse = routeInfo['GET /v1/admin/account/{accountId}/auditLog']['responseCodec'];
export type AdminAccountAccountIdAuditLogGetResponse = t.TypeOf<typeof AdminAccountAccountIdAuditLogGetResponse>;
export const AdminAccountAccountIdAuditLogGetInput = routeInfo['GET /v1/admin/account/{accountId}/auditLog']['inputCodec'];
export type AdminAccountAccountIdAuditLogGetInput = t.TypeOf<typeof AdminAccountAccountIdAuditLogGetInput>;
export const AdminEdiConfigurationsEdiConfigurationIdPatchResponse = routeInfo['PATCH /v1/admin/ediConfigurations/{ediConfigurationId}']['responseCodec'];
export type AdminEdiConfigurationsEdiConfigurationIdPatchResponse = t.TypeOf<typeof AdminEdiConfigurationsEdiConfigurationIdPatchResponse>;
export const AdminEdiConfigurationsEdiConfigurationIdPatchInput = routeInfo['PATCH /v1/admin/ediConfigurations/{ediConfigurationId}']['inputCodec'];
export type AdminEdiConfigurationsEdiConfigurationIdPatchInput = t.TypeOf<typeof AdminEdiConfigurationsEdiConfigurationIdPatchInput>;
export const AdminEdiConfigurationsGetResponse = routeInfo['GET /v1/admin/ediConfigurations']['responseCodec'];
export type AdminEdiConfigurationsGetResponse = t.TypeOf<typeof AdminEdiConfigurationsGetResponse>;
export const AdminEdiConfigurationsGetInput = routeInfo['GET /v1/admin/ediConfigurations']['inputCodec'];
export type AdminEdiConfigurationsGetInput = t.TypeOf<typeof AdminEdiConfigurationsGetInput>;
export const AdminEdiConfigurationsPostResponse = routeInfo['POST /v1/admin/ediConfigurations']['responseCodec'];
export type AdminEdiConfigurationsPostResponse = t.TypeOf<typeof AdminEdiConfigurationsPostResponse>;
export const AdminEdiConfigurationsPostInput = routeInfo['POST /v1/admin/ediConfigurations']['inputCodec'];
export type AdminEdiConfigurationsPostInput = t.TypeOf<typeof AdminEdiConfigurationsPostInput>;
export const AdminBuyerLocationsBuyerLocationIdGetResponse = routeInfo['GET /v1/admin/buyerLocations/{buyerLocationId}']['responseCodec'];
export type AdminBuyerLocationsBuyerLocationIdGetResponse = t.TypeOf<typeof AdminBuyerLocationsBuyerLocationIdGetResponse>;
export const AdminBuyerLocationsBuyerLocationIdGetInput = routeInfo['GET /v1/admin/buyerLocations/{buyerLocationId}']['inputCodec'];
export type AdminBuyerLocationsBuyerLocationIdGetInput = t.TypeOf<typeof AdminBuyerLocationsBuyerLocationIdGetInput>;
export const AdminBuyerLocationsBuyerLocationIdPatchResponse = routeInfo['PATCH /v1/admin/buyerLocations/{buyerLocationId}']['responseCodec'];
export type AdminBuyerLocationsBuyerLocationIdPatchResponse = t.TypeOf<typeof AdminBuyerLocationsBuyerLocationIdPatchResponse>;
export const AdminBuyerLocationsBuyerLocationIdPatchInput = routeInfo['PATCH /v1/admin/buyerLocations/{buyerLocationId}']['inputCodec'];
export type AdminBuyerLocationsBuyerLocationIdPatchInput = t.TypeOf<typeof AdminBuyerLocationsBuyerLocationIdPatchInput>;
export const AdminProductsProductIdGetResponse = routeInfo['GET /v1/admin/products/{productId}']['responseCodec'];
export type AdminProductsProductIdGetResponse = t.TypeOf<typeof AdminProductsProductIdGetResponse>;
export const AdminProductsProductIdGetInput = routeInfo['GET /v1/admin/products/{productId}']['inputCodec'];
export type AdminProductsProductIdGetInput = t.TypeOf<typeof AdminProductsProductIdGetInput>;
export const AdminOrdersOrderIdCancelPostResponse = routeInfo['POST /v1/admin/orders/{orderId}/cancel']['responseCodec'];
export type AdminOrdersOrderIdCancelPostResponse = t.TypeOf<typeof AdminOrdersOrderIdCancelPostResponse>;
export const AdminOrdersOrderIdCancelPostInput = routeInfo['POST /v1/admin/orders/{orderId}/cancel']['inputCodec'];
export type AdminOrdersOrderIdCancelPostInput = t.TypeOf<typeof AdminOrdersOrderIdCancelPostInput>;
export const AdminOrdersOrderIdVoidPostResponse = routeInfo['POST /v1/admin/orders/{orderId}/void']['responseCodec'];
export type AdminOrdersOrderIdVoidPostResponse = t.TypeOf<typeof AdminOrdersOrderIdVoidPostResponse>;
export const AdminOrdersOrderIdVoidPostInput = routeInfo['POST /v1/admin/orders/{orderId}/void']['inputCodec'];
export type AdminOrdersOrderIdVoidPostInput = t.TypeOf<typeof AdminOrdersOrderIdVoidPostInput>;
export const AdminOrdersOrderIdUncancelPostResponse = routeInfo['POST /v1/admin/orders/{orderId}/uncancel']['responseCodec'];
export type AdminOrdersOrderIdUncancelPostResponse = t.TypeOf<typeof AdminOrdersOrderIdUncancelPostResponse>;
export const AdminOrdersOrderIdUncancelPostInput = routeInfo['POST /v1/admin/orders/{orderId}/uncancel']['inputCodec'];
export type AdminOrdersOrderIdUncancelPostInput = t.TypeOf<typeof AdminOrdersOrderIdUncancelPostInput>;
export const AccountFlagsGetResponse = routeInfo['GET /v1/account/flags']['responseCodec'];
export type AccountFlagsGetResponse = t.TypeOf<typeof AccountFlagsGetResponse>;
export const AccountFlagsGetInput = routeInfo['GET /v1/account/flags']['inputCodec'];
export type AccountFlagsGetInput = t.TypeOf<typeof AccountFlagsGetInput>;
export const AccountFlagsPatchResponse = routeInfo['PATCH /v1/account/flags']['responseCodec'];
export type AccountFlagsPatchResponse = t.TypeOf<typeof AccountFlagsPatchResponse>;
export const AccountFlagsPatchInput = routeInfo['PATCH /v1/account/flags']['inputCodec'];
export type AccountFlagsPatchInput = t.TypeOf<typeof AccountFlagsPatchInput>;
export const AdminUsersUserIdDeleteResponse = routeInfo['DELETE /v1/admin/users/{userId}']['responseCodec'];
export type AdminUsersUserIdDeleteResponse = t.TypeOf<typeof AdminUsersUserIdDeleteResponse>;
export const AdminUsersUserIdDeleteInput = routeInfo['DELETE /v1/admin/users/{userId}']['inputCodec'];
export type AdminUsersUserIdDeleteInput = t.TypeOf<typeof AdminUsersUserIdDeleteInput>;
export const AdminUsersUserIdUpdateEmailPostResponse = routeInfo['POST /v1/admin/users/{userId}/updateEmail']['responseCodec'];
export type AdminUsersUserIdUpdateEmailPostResponse = t.TypeOf<typeof AdminUsersUserIdUpdateEmailPostResponse>;
export const AdminUsersUserIdUpdateEmailPostInput = routeInfo['POST /v1/admin/users/{userId}/updateEmail']['inputCodec'];
export type AdminUsersUserIdUpdateEmailPostInput = t.TypeOf<typeof AdminUsersUserIdUpdateEmailPostInput>;
export const AdminUsersUserIdUserLocationsPutResponse = routeInfo['PUT /v1/admin/users/{userId}/userLocations']['responseCodec'];
export type AdminUsersUserIdUserLocationsPutResponse = t.TypeOf<typeof AdminUsersUserIdUserLocationsPutResponse>;
export const AdminUsersUserIdUserLocationsPutInput = routeInfo['PUT /v1/admin/users/{userId}/userLocations']['inputCodec'];
export type AdminUsersUserIdUserLocationsPutInput = t.TypeOf<typeof AdminUsersUserIdUserLocationsPutInput>;
export const AdminUsersUserIdMigratePostResponse = routeInfo['POST /v1/admin/users/{userId}/migrate']['responseCodec'];
export type AdminUsersUserIdMigratePostResponse = t.TypeOf<typeof AdminUsersUserIdMigratePostResponse>;
export const AdminUsersUserIdMigratePostInput = routeInfo['POST /v1/admin/users/{userId}/migrate']['inputCodec'];
export type AdminUsersUserIdMigratePostInput = t.TypeOf<typeof AdminUsersUserIdMigratePostInput>;
export const AdminSellersPostResponse = routeInfo['POST /v1/admin/sellers']['responseCodec'];
export type AdminSellersPostResponse = t.TypeOf<typeof AdminSellersPostResponse>;
export const AdminSellersPostInput = routeInfo['POST /v1/admin/sellers']['inputCodec'];
export type AdminSellersPostInput = t.TypeOf<typeof AdminSellersPostInput>;
export const AdminSellersSellerIdPricingBuyerIdGetResponse = routeInfo['GET /v1/admin/sellers/{sellerId}/pricing/{buyerId}']['responseCodec'];
export type AdminSellersSellerIdPricingBuyerIdGetResponse = t.TypeOf<typeof AdminSellersSellerIdPricingBuyerIdGetResponse>;
export const AdminSellersSellerIdPricingBuyerIdGetInput = routeInfo['GET /v1/admin/sellers/{sellerId}/pricing/{buyerId}']['inputCodec'];
export type AdminSellersSellerIdPricingBuyerIdGetInput = t.TypeOf<typeof AdminSellersSellerIdPricingBuyerIdGetInput>;
export const AdminSellersSellerIdPricingtiersGetResponse = routeInfo['GET /v1/admin/sellers/{sellerId}/pricingtiers']['responseCodec'];
export type AdminSellersSellerIdPricingtiersGetResponse = t.TypeOf<typeof AdminSellersSellerIdPricingtiersGetResponse>;
export const AdminSellersSellerIdPricingtiersGetInput = routeInfo['GET /v1/admin/sellers/{sellerId}/pricingtiers']['inputCodec'];
export type AdminSellersSellerIdPricingtiersGetInput = t.TypeOf<typeof AdminSellersSellerIdPricingtiersGetInput>;
export const AdminSellersSellerIdPricingtiersPostResponse = routeInfo['POST /v1/admin/sellers/{sellerId}/pricingtiers']['responseCodec'];
export type AdminSellersSellerIdPricingtiersPostResponse = t.TypeOf<typeof AdminSellersSellerIdPricingtiersPostResponse>;
export const AdminSellersSellerIdPricingtiersPostInput = routeInfo['POST /v1/admin/sellers/{sellerId}/pricingtiers']['inputCodec'];
export type AdminSellersSellerIdPricingtiersPostInput = t.TypeOf<typeof AdminSellersSellerIdPricingtiersPostInput>;
export const AdminPricingtiersTierIdPatchResponse = routeInfo['PATCH /v1/admin/pricingtiers/{tierId}']['responseCodec'];
export type AdminPricingtiersTierIdPatchResponse = t.TypeOf<typeof AdminPricingtiersTierIdPatchResponse>;
export const AdminPricingtiersTierIdPatchInput = routeInfo['PATCH /v1/admin/pricingtiers/{tierId}']['inputCodec'];
export type AdminPricingtiersTierIdPatchInput = t.TypeOf<typeof AdminPricingtiersTierIdPatchInput>;
export const AdminPricingtiersTierIdDeleteResponse = routeInfo['DELETE /v1/admin/pricingtiers/{tierId}']['responseCodec'];
export type AdminPricingtiersTierIdDeleteResponse = t.TypeOf<typeof AdminPricingtiersTierIdDeleteResponse>;
export const AdminPricingtiersTierIdDeleteInput = routeInfo['DELETE /v1/admin/pricingtiers/{tierId}']['inputCodec'];
export type AdminPricingtiersTierIdDeleteInput = t.TypeOf<typeof AdminPricingtiersTierIdDeleteInput>;
export const AdminPricingtiersTierIdBuyerRelationshipsPatchResponse = routeInfo['PATCH /v1/admin/pricingtiers/{tierId}/buyerRelationships']['responseCodec'];
export type AdminPricingtiersTierIdBuyerRelationshipsPatchResponse = t.TypeOf<typeof AdminPricingtiersTierIdBuyerRelationshipsPatchResponse>;
export const AdminPricingtiersTierIdBuyerRelationshipsPatchInput = routeInfo['PATCH /v1/admin/pricingtiers/{tierId}/buyerRelationships']['inputCodec'];
export type AdminPricingtiersTierIdBuyerRelationshipsPatchInput = t.TypeOf<typeof AdminPricingtiersTierIdBuyerRelationshipsPatchInput>;
export const AdminPricingerasEraIdPatchResponse = routeInfo['PATCH /v1/admin/pricingeras/{eraId}']['responseCodec'];
export type AdminPricingerasEraIdPatchResponse = t.TypeOf<typeof AdminPricingerasEraIdPatchResponse>;
export const AdminPricingerasEraIdPatchInput = routeInfo['PATCH /v1/admin/pricingeras/{eraId}']['inputCodec'];
export type AdminPricingerasEraIdPatchInput = t.TypeOf<typeof AdminPricingerasEraIdPatchInput>;
export const AdminBuyerPricingTiersGetResponse = routeInfo['GET /v1/admin/buyerPricingTiers']['responseCodec'];
export type AdminBuyerPricingTiersGetResponse = t.TypeOf<typeof AdminBuyerPricingTiersGetResponse>;
export const AdminBuyerPricingTiersGetInput = routeInfo['GET /v1/admin/buyerPricingTiers']['inputCodec'];
export type AdminBuyerPricingTiersGetInput = t.TypeOf<typeof AdminBuyerPricingTiersGetInput>;
export const AdminBuyerPricingTiersPostResponse = routeInfo['POST /v1/admin/buyerPricingTiers']['responseCodec'];
export type AdminBuyerPricingTiersPostResponse = t.TypeOf<typeof AdminBuyerPricingTiersPostResponse>;
export const AdminBuyerPricingTiersPostInput = routeInfo['POST /v1/admin/buyerPricingTiers']['inputCodec'];
export type AdminBuyerPricingTiersPostInput = t.TypeOf<typeof AdminBuyerPricingTiersPostInput>;
export const AdminBuyerPricingTiersDeleteResponse = routeInfo['DELETE /v1/admin/buyerPricingTiers']['responseCodec'];
export type AdminBuyerPricingTiersDeleteResponse = t.TypeOf<typeof AdminBuyerPricingTiersDeleteResponse>;
export const AdminBuyerPricingTiersDeleteInput = routeInfo['DELETE /v1/admin/buyerPricingTiers']['inputCodec'];
export type AdminBuyerPricingTiersDeleteInput = t.TypeOf<typeof AdminBuyerPricingTiersDeleteInput>;
export const AdminSellersSellerIdExternalProductPurchaseDataPostResponse = routeInfo['POST /v1/admin/sellers/{sellerId}/externalProductPurchaseData']['responseCodec'];
export type AdminSellersSellerIdExternalProductPurchaseDataPostResponse = t.TypeOf<typeof AdminSellersSellerIdExternalProductPurchaseDataPostResponse>;
export const AdminSellersSellerIdExternalProductPurchaseDataPostInput = routeInfo['POST /v1/admin/sellers/{sellerId}/externalProductPurchaseData']['inputCodec'];
export type AdminSellersSellerIdExternalProductPurchaseDataPostInput = t.TypeOf<typeof AdminSellersSellerIdExternalProductPurchaseDataPostInput>;
export const AdminSellersSellerIdExternalProductPurchaseDataDeleteResponse = routeInfo['DELETE /v1/admin/sellers/{sellerId}/externalProductPurchaseData']['responseCodec'];
export type AdminSellersSellerIdExternalProductPurchaseDataDeleteResponse = t.TypeOf<typeof AdminSellersSellerIdExternalProductPurchaseDataDeleteResponse>;
export const AdminSellersSellerIdExternalProductPurchaseDataDeleteInput = routeInfo['DELETE /v1/admin/sellers/{sellerId}/externalProductPurchaseData']['inputCodec'];
export type AdminSellersSellerIdExternalProductPurchaseDataDeleteInput = t.TypeOf<typeof AdminSellersSellerIdExternalProductPurchaseDataDeleteInput>;
export const AdminExternalProductDataGetResponse = routeInfo['GET /v1/admin/externalProductData']['responseCodec'];
export type AdminExternalProductDataGetResponse = t.TypeOf<typeof AdminExternalProductDataGetResponse>;
export const AdminExternalProductDataGetInput = routeInfo['GET /v1/admin/externalProductData']['inputCodec'];
export type AdminExternalProductDataGetInput = t.TypeOf<typeof AdminExternalProductDataGetInput>;
export const AdminExternalProductDataPutResponse = routeInfo['PUT /v1/admin/externalProductData']['responseCodec'];
export type AdminExternalProductDataPutResponse = t.TypeOf<typeof AdminExternalProductDataPutResponse>;
export const AdminExternalProductDataPutInput = routeInfo['PUT /v1/admin/externalProductData']['inputCodec'];
export type AdminExternalProductDataPutInput = t.TypeOf<typeof AdminExternalProductDataPutInput>;
export const AdminSellersSellerIdExternalProductDataFetchPostResponse = routeInfo['POST /v1/admin/sellers/{sellerId}/externalProductData/fetch']['responseCodec'];
export type AdminSellersSellerIdExternalProductDataFetchPostResponse = t.TypeOf<typeof AdminSellersSellerIdExternalProductDataFetchPostResponse>;
export const AdminSellersSellerIdExternalProductDataFetchPostInput = routeInfo['POST /v1/admin/sellers/{sellerId}/externalProductData/fetch']['inputCodec'];
export type AdminSellersSellerIdExternalProductDataFetchPostInput = t.TypeOf<typeof AdminSellersSellerIdExternalProductDataFetchPostInput>;
export const AdminSellersSellerIdBackfillProductRelationsFromExternalProductDataPostResponse = routeInfo['POST /v1/admin/sellers/{sellerId}/backfillProductRelationsFromExternalProductData']['responseCodec'];
export type AdminSellersSellerIdBackfillProductRelationsFromExternalProductDataPostResponse = t.TypeOf<typeof AdminSellersSellerIdBackfillProductRelationsFromExternalProductDataPostResponse>;
export const AdminSellersSellerIdBackfillProductRelationsFromExternalProductDataPostInput = routeInfo['POST /v1/admin/sellers/{sellerId}/backfillProductRelationsFromExternalProductData']['inputCodec'];
export type AdminSellersSellerIdBackfillProductRelationsFromExternalProductDataPostInput = t.TypeOf<typeof AdminSellersSellerIdBackfillProductRelationsFromExternalProductDataPostInput>;
export const AdminSellersSellerIdBulkImageUploadPostResponse = routeInfo['POST /v1/admin/sellers/{sellerId}/bulkImageUpload']['responseCodec'];
export type AdminSellersSellerIdBulkImageUploadPostResponse = t.TypeOf<typeof AdminSellersSellerIdBulkImageUploadPostResponse>;
export const AdminSellersSellerIdBulkImageUploadPostInput = routeInfo['POST /v1/admin/sellers/{sellerId}/bulkImageUpload']['inputCodec'];
export type AdminSellersSellerIdBulkImageUploadPostInput = t.TypeOf<typeof AdminSellersSellerIdBulkImageUploadPostInput>;
export const AdminSellersHubSpotCompanyInfoHubSpotCompanyIdGetResponse = routeInfo['GET /v1/admin/sellers/hubSpotCompanyInfo/{hubSpotCompanyId}']['responseCodec'];
export type AdminSellersHubSpotCompanyInfoHubSpotCompanyIdGetResponse = t.TypeOf<typeof AdminSellersHubSpotCompanyInfoHubSpotCompanyIdGetResponse>;
export const AdminSellersHubSpotCompanyInfoHubSpotCompanyIdGetInput = routeInfo['GET /v1/admin/sellers/hubSpotCompanyInfo/{hubSpotCompanyId}']['inputCodec'];
export type AdminSellersHubSpotCompanyInfoHubSpotCompanyIdGetInput = t.TypeOf<typeof AdminSellersHubSpotCompanyInfoHubSpotCompanyIdGetInput>;
export const AdminSellersSellerIdCategoriesPatchResponse = routeInfo['PATCH /v1/admin/sellers/{sellerId}/categories']['responseCodec'];
export type AdminSellersSellerIdCategoriesPatchResponse = t.TypeOf<typeof AdminSellersSellerIdCategoriesPatchResponse>;
export const AdminSellersSellerIdCategoriesPatchInput = routeInfo['PATCH /v1/admin/sellers/{sellerId}/categories']['inputCodec'];
export type AdminSellersSellerIdCategoriesPatchInput = t.TypeOf<typeof AdminSellersSellerIdCategoriesPatchInput>;
export const AdminSellersSellerIdUpdateBasicHubSpotPropertiesPostResponse = routeInfo['POST /v1/admin/sellers/{sellerId}/updateBasicHubSpotProperties']['responseCodec'];
export type AdminSellersSellerIdUpdateBasicHubSpotPropertiesPostResponse = t.TypeOf<typeof AdminSellersSellerIdUpdateBasicHubSpotPropertiesPostResponse>;
export const AdminSellersSellerIdUpdateBasicHubSpotPropertiesPostInput = routeInfo['POST /v1/admin/sellers/{sellerId}/updateBasicHubSpotProperties']['inputCodec'];
export type AdminSellersSellerIdUpdateBasicHubSpotPropertiesPostInput = t.TypeOf<typeof AdminSellersSellerIdUpdateBasicHubSpotPropertiesPostInput>;
export const AdminSellersSellerIdPartnerProgramMembershipsGetResponse = routeInfo['GET /v1/admin/sellers/{sellerId}/partnerProgramMemberships']['responseCodec'];
export type AdminSellersSellerIdPartnerProgramMembershipsGetResponse = t.TypeOf<typeof AdminSellersSellerIdPartnerProgramMembershipsGetResponse>;
export const AdminSellersSellerIdPartnerProgramMembershipsGetInput = routeInfo['GET /v1/admin/sellers/{sellerId}/partnerProgramMemberships']['inputCodec'];
export type AdminSellersSellerIdPartnerProgramMembershipsGetInput = t.TypeOf<typeof AdminSellersSellerIdPartnerProgramMembershipsGetInput>;
export const AdminSellersSellerIdPartnerProgramMembershipsPartnerAccountIdDeleteResponse = routeInfo['DELETE /v1/admin/sellers/{sellerId}/partnerProgramMemberships/{partnerAccountId}']['responseCodec'];
export type AdminSellersSellerIdPartnerProgramMembershipsPartnerAccountIdDeleteResponse = t.TypeOf<typeof AdminSellersSellerIdPartnerProgramMembershipsPartnerAccountIdDeleteResponse>;
export const AdminSellersSellerIdPartnerProgramMembershipsPartnerAccountIdDeleteInput = routeInfo['DELETE /v1/admin/sellers/{sellerId}/partnerProgramMemberships/{partnerAccountId}']['inputCodec'];
export type AdminSellersSellerIdPartnerProgramMembershipsPartnerAccountIdDeleteInput = t.TypeOf<typeof AdminSellersSellerIdPartnerProgramMembershipsPartnerAccountIdDeleteInput>;
export const AdminSellersSellerIdPartnerProgramMembershipsPartnerAccountIdPutResponse = routeInfo['PUT /v1/admin/sellers/{sellerId}/partnerProgramMemberships/{partnerAccountId}']['responseCodec'];
export type AdminSellersSellerIdPartnerProgramMembershipsPartnerAccountIdPutResponse = t.TypeOf<typeof AdminSellersSellerIdPartnerProgramMembershipsPartnerAccountIdPutResponse>;
export const AdminSellersSellerIdPartnerProgramMembershipsPartnerAccountIdPutInput = routeInfo['PUT /v1/admin/sellers/{sellerId}/partnerProgramMemberships/{partnerAccountId}']['inputCodec'];
export type AdminSellersSellerIdPartnerProgramMembershipsPartnerAccountIdPutInput = t.TypeOf<typeof AdminSellersSellerIdPartnerProgramMembershipsPartnerAccountIdPutInput>;
export const AdminBuyersBuyerIdPartnerProgramMembershipsPartnerAccountNameDeleteResponse = routeInfo['DELETE /v1/admin/buyers/{buyerId}/partnerProgramMemberships/{partnerAccountName}']['responseCodec'];
export type AdminBuyersBuyerIdPartnerProgramMembershipsPartnerAccountNameDeleteResponse = t.TypeOf<typeof AdminBuyersBuyerIdPartnerProgramMembershipsPartnerAccountNameDeleteResponse>;
export const AdminBuyersBuyerIdPartnerProgramMembershipsPartnerAccountNameDeleteInput = routeInfo['DELETE /v1/admin/buyers/{buyerId}/partnerProgramMemberships/{partnerAccountName}']['inputCodec'];
export type AdminBuyersBuyerIdPartnerProgramMembershipsPartnerAccountNameDeleteInput = t.TypeOf<typeof AdminBuyersBuyerIdPartnerProgramMembershipsPartnerAccountNameDeleteInput>;
export const AdminBuyersBuyerIdPartnerProgramMembershipsPartnerAccountNamePutResponse = routeInfo['PUT /v1/admin/buyers/{buyerId}/partnerProgramMemberships/{partnerAccountName}']['responseCodec'];
export type AdminBuyersBuyerIdPartnerProgramMembershipsPartnerAccountNamePutResponse = t.TypeOf<typeof AdminBuyersBuyerIdPartnerProgramMembershipsPartnerAccountNamePutResponse>;
export const AdminBuyersBuyerIdPartnerProgramMembershipsPartnerAccountNamePutInput = routeInfo['PUT /v1/admin/buyers/{buyerId}/partnerProgramMemberships/{partnerAccountName}']['inputCodec'];
export type AdminBuyersBuyerIdPartnerProgramMembershipsPartnerAccountNamePutInput = t.TypeOf<typeof AdminBuyersBuyerIdPartnerProgramMembershipsPartnerAccountNamePutInput>;
export const AdminBuyersBuyerIdPatchResponse = routeInfo['PATCH /v1/admin/buyers/{buyerId}']['responseCodec'];
export type AdminBuyersBuyerIdPatchResponse = t.TypeOf<typeof AdminBuyersBuyerIdPatchResponse>;
export const AdminBuyersBuyerIdPatchInput = routeInfo['PATCH /v1/admin/buyers/{buyerId}']['inputCodec'];
export type AdminBuyersBuyerIdPatchInput = t.TypeOf<typeof AdminBuyersBuyerIdPatchInput>;
export const AdminBuyersBuyerIdBalancesGetResponse = routeInfo['GET /v1/admin/buyers/{buyerId}/balances']['responseCodec'];
export type AdminBuyersBuyerIdBalancesGetResponse = t.TypeOf<typeof AdminBuyersBuyerIdBalancesGetResponse>;
export const AdminBuyersBuyerIdBalancesGetInput = routeInfo['GET /v1/admin/buyers/{buyerId}/balances']['inputCodec'];
export type AdminBuyersBuyerIdBalancesGetInput = t.TypeOf<typeof AdminBuyersBuyerIdBalancesGetInput>;
export const AdminBuyersBuyerIdLandedCostReportGetResponse = routeInfo['GET /v1/admin/buyers/{buyerId}/landedCostReport']['responseCodec'];
export type AdminBuyersBuyerIdLandedCostReportGetResponse = t.TypeOf<typeof AdminBuyersBuyerIdLandedCostReportGetResponse>;
export const AdminBuyersBuyerIdLandedCostReportGetInput = routeInfo['GET /v1/admin/buyers/{buyerId}/landedCostReport']['inputCodec'];
export type AdminBuyersBuyerIdLandedCostReportGetInput = t.TypeOf<typeof AdminBuyersBuyerIdLandedCostReportGetInput>;
export const AdminBuyersBuyerIdKeheCustomerDataPostResponse = routeInfo['POST /v1/admin/buyers/{buyerId}/keheCustomerData']['responseCodec'];
export type AdminBuyersBuyerIdKeheCustomerDataPostResponse = t.TypeOf<typeof AdminBuyersBuyerIdKeheCustomerDataPostResponse>;
export const AdminBuyersBuyerIdKeheCustomerDataPostInput = routeInfo['POST /v1/admin/buyers/{buyerId}/keheCustomerData']['inputCodec'];
export type AdminBuyersBuyerIdKeheCustomerDataPostInput = t.TypeOf<typeof AdminBuyersBuyerIdKeheCustomerDataPostInput>;
export const AdminCategoriesCategoryIdFutureAplCostsReportPostResponse = routeInfo['POST /v1/admin/categories/{categoryId}/futureAPLCostsReport']['responseCodec'];
export type AdminCategoriesCategoryIdFutureAplCostsReportPostResponse = t.TypeOf<typeof AdminCategoriesCategoryIdFutureAplCostsReportPostResponse>;
export const AdminCategoriesCategoryIdFutureAplCostsReportPostInput = routeInfo['POST /v1/admin/categories/{categoryId}/futureAPLCostsReport']['inputCodec'];
export type AdminCategoriesCategoryIdFutureAplCostsReportPostInput = t.TypeOf<typeof AdminCategoriesCategoryIdFutureAplCostsReportPostInput>;
export const AdminDashboardCardsGetResponse = routeInfo['GET /v1/admin/dashboardCards']['responseCodec'];
export type AdminDashboardCardsGetResponse = t.TypeOf<typeof AdminDashboardCardsGetResponse>;
export const AdminDashboardCardsGetInput = routeInfo['GET /v1/admin/dashboardCards']['inputCodec'];
export type AdminDashboardCardsGetInput = t.TypeOf<typeof AdminDashboardCardsGetInput>;
export const AdminDashboardCardsPostResponse = routeInfo['POST /v1/admin/dashboardCards']['responseCodec'];
export type AdminDashboardCardsPostResponse = t.TypeOf<typeof AdminDashboardCardsPostResponse>;
export const AdminDashboardCardsPostInput = routeInfo['POST /v1/admin/dashboardCards']['inputCodec'];
export type AdminDashboardCardsPostInput = t.TypeOf<typeof AdminDashboardCardsPostInput>;
export const AdminDashboardCardsIdGetResponse = routeInfo['GET /v1/admin/dashboardCards/{id}']['responseCodec'];
export type AdminDashboardCardsIdGetResponse = t.TypeOf<typeof AdminDashboardCardsIdGetResponse>;
export const AdminDashboardCardsIdGetInput = routeInfo['GET /v1/admin/dashboardCards/{id}']['inputCodec'];
export type AdminDashboardCardsIdGetInput = t.TypeOf<typeof AdminDashboardCardsIdGetInput>;
export const AdminDashboardCardsIdPutResponse = routeInfo['PUT /v1/admin/dashboardCards/{id}']['responseCodec'];
export type AdminDashboardCardsIdPutResponse = t.TypeOf<typeof AdminDashboardCardsIdPutResponse>;
export const AdminDashboardCardsIdPutInput = routeInfo['PUT /v1/admin/dashboardCards/{id}']['inputCodec'];
export type AdminDashboardCardsIdPutInput = t.TypeOf<typeof AdminDashboardCardsIdPutInput>;
export const AdminDashboardCardsIdDeleteResponse = routeInfo['DELETE /v1/admin/dashboardCards/{id}']['responseCodec'];
export type AdminDashboardCardsIdDeleteResponse = t.TypeOf<typeof AdminDashboardCardsIdDeleteResponse>;
export const AdminDashboardCardsIdDeleteInput = routeInfo['DELETE /v1/admin/dashboardCards/{id}']['inputCodec'];
export type AdminDashboardCardsIdDeleteInput = t.TypeOf<typeof AdminDashboardCardsIdDeleteInput>;
export const AdminDashboardProfilesGetResponse = routeInfo['GET /v1/admin/dashboardProfiles']['responseCodec'];
export type AdminDashboardProfilesGetResponse = t.TypeOf<typeof AdminDashboardProfilesGetResponse>;
export const AdminDashboardProfilesGetInput = routeInfo['GET /v1/admin/dashboardProfiles']['inputCodec'];
export type AdminDashboardProfilesGetInput = t.TypeOf<typeof AdminDashboardProfilesGetInput>;
export const AdminDashboardProfilesPostResponse = routeInfo['POST /v1/admin/dashboardProfiles']['responseCodec'];
export type AdminDashboardProfilesPostResponse = t.TypeOf<typeof AdminDashboardProfilesPostResponse>;
export const AdminDashboardProfilesPostInput = routeInfo['POST /v1/admin/dashboardProfiles']['inputCodec'];
export type AdminDashboardProfilesPostInput = t.TypeOf<typeof AdminDashboardProfilesPostInput>;
export const AdminDashboardProfilesIdGetResponse = routeInfo['GET /v1/admin/dashboardProfiles/{id}']['responseCodec'];
export type AdminDashboardProfilesIdGetResponse = t.TypeOf<typeof AdminDashboardProfilesIdGetResponse>;
export const AdminDashboardProfilesIdGetInput = routeInfo['GET /v1/admin/dashboardProfiles/{id}']['inputCodec'];
export type AdminDashboardProfilesIdGetInput = t.TypeOf<typeof AdminDashboardProfilesIdGetInput>;
export const AdminDashboardProfilesIdPutResponse = routeInfo['PUT /v1/admin/dashboardProfiles/{id}']['responseCodec'];
export type AdminDashboardProfilesIdPutResponse = t.TypeOf<typeof AdminDashboardProfilesIdPutResponse>;
export const AdminDashboardProfilesIdPutInput = routeInfo['PUT /v1/admin/dashboardProfiles/{id}']['inputCodec'];
export type AdminDashboardProfilesIdPutInput = t.TypeOf<typeof AdminDashboardProfilesIdPutInput>;
export const AdminDashboardProfilesIdDeleteResponse = routeInfo['DELETE /v1/admin/dashboardProfiles/{id}']['responseCodec'];
export type AdminDashboardProfilesIdDeleteResponse = t.TypeOf<typeof AdminDashboardProfilesIdDeleteResponse>;
export const AdminDashboardProfilesIdDeleteInput = routeInfo['DELETE /v1/admin/dashboardProfiles/{id}']['inputCodec'];
export type AdminDashboardProfilesIdDeleteInput = t.TypeOf<typeof AdminDashboardProfilesIdDeleteInput>;
export const SampleRequestsGetResponse = routeInfo['GET /v1/sampleRequests']['responseCodec'];
export type SampleRequestsGetResponse = t.TypeOf<typeof SampleRequestsGetResponse>;
export const SampleRequestsGetInput = routeInfo['GET /v1/sampleRequests']['inputCodec'];
export type SampleRequestsGetInput = t.TypeOf<typeof SampleRequestsGetInput>;
export const SampleRequestsPostResponse = routeInfo['POST /v1/sampleRequests']['responseCodec'];
export type SampleRequestsPostResponse = t.TypeOf<typeof SampleRequestsPostResponse>;
export const SampleRequestsPostInput = routeInfo['POST /v1/sampleRequests']['inputCodec'];
export type SampleRequestsPostInput = t.TypeOf<typeof SampleRequestsPostInput>;
export const SampleRequestsEligibilityGetResponse = routeInfo['GET /v1/sampleRequestsEligibility']['responseCodec'];
export type SampleRequestsEligibilityGetResponse = t.TypeOf<typeof SampleRequestsEligibilityGetResponse>;
export const SampleRequestsEligibilityGetInput = routeInfo['GET /v1/sampleRequestsEligibility']['inputCodec'];
export type SampleRequestsEligibilityGetInput = t.TypeOf<typeof SampleRequestsEligibilityGetInput>;
export const SampleRequestsSampleRequestIdGetResponse = routeInfo['GET /v1/sampleRequests/{sampleRequestId}']['responseCodec'];
export type SampleRequestsSampleRequestIdGetResponse = t.TypeOf<typeof SampleRequestsSampleRequestIdGetResponse>;
export const SampleRequestsSampleRequestIdGetInput = routeInfo['GET /v1/sampleRequests/{sampleRequestId}']['inputCodec'];
export type SampleRequestsSampleRequestIdGetInput = t.TypeOf<typeof SampleRequestsSampleRequestIdGetInput>;
export const SampleRequestsIdIssuePutResponse = routeInfo['PUT /v1/sampleRequests/{id}/issue']['responseCodec'];
export type SampleRequestsIdIssuePutResponse = t.TypeOf<typeof SampleRequestsIdIssuePutResponse>;
export const SampleRequestsIdIssuePutInput = routeInfo['PUT /v1/sampleRequests/{id}/issue']['inputCodec'];
export type SampleRequestsIdIssuePutInput = t.TypeOf<typeof SampleRequestsIdIssuePutInput>;
export const AdminSampleRequestsSampleRequestIdIssuePostResponse = routeInfo['POST /v1/admin/sampleRequests/{sampleRequestId}/issue']['responseCodec'];
export type AdminSampleRequestsSampleRequestIdIssuePostResponse = t.TypeOf<typeof AdminSampleRequestsSampleRequestIdIssuePostResponse>;
export const AdminSampleRequestsSampleRequestIdIssuePostInput = routeInfo['POST /v1/admin/sampleRequests/{sampleRequestId}/issue']['inputCodec'];
export type AdminSampleRequestsSampleRequestIdIssuePostInput = t.TypeOf<typeof AdminSampleRequestsSampleRequestIdIssuePostInput>;
export const SampleRequestsSampleRequestIdAcceptPostResponse = routeInfo['POST /v1/sampleRequests/{sampleRequestId}/accept']['responseCodec'];
export type SampleRequestsSampleRequestIdAcceptPostResponse = t.TypeOf<typeof SampleRequestsSampleRequestIdAcceptPostResponse>;
export const SampleRequestsSampleRequestIdAcceptPostInput = routeInfo['POST /v1/sampleRequests/{sampleRequestId}/accept']['inputCodec'];
export type SampleRequestsSampleRequestIdAcceptPostInput = t.TypeOf<typeof SampleRequestsSampleRequestIdAcceptPostInput>;
export const SampleRequestsSampleRequestIdFeedbackPostResponse = routeInfo['POST /v1/sampleRequests/{sampleRequestId}/feedback']['responseCodec'];
export type SampleRequestsSampleRequestIdFeedbackPostResponse = t.TypeOf<typeof SampleRequestsSampleRequestIdFeedbackPostResponse>;
export const SampleRequestsSampleRequestIdFeedbackPostInput = routeInfo['POST /v1/sampleRequests/{sampleRequestId}/feedback']['inputCodec'];
export type SampleRequestsSampleRequestIdFeedbackPostInput = t.TypeOf<typeof SampleRequestsSampleRequestIdFeedbackPostInput>;
export const SampleRequestsSampleRequestIdCancelPostResponse = routeInfo['POST /v1/sampleRequests/{sampleRequestId}/cancel']['responseCodec'];
export type SampleRequestsSampleRequestIdCancelPostResponse = t.TypeOf<typeof SampleRequestsSampleRequestIdCancelPostResponse>;
export const SampleRequestsSampleRequestIdCancelPostInput = routeInfo['POST /v1/sampleRequests/{sampleRequestId}/cancel']['inputCodec'];
export type SampleRequestsSampleRequestIdCancelPostInput = t.TypeOf<typeof SampleRequestsSampleRequestIdCancelPostInput>;
export const AdminSampleRequestsGetResponse = routeInfo['GET /v1/admin/sampleRequests']['responseCodec'];
export type AdminSampleRequestsGetResponse = t.TypeOf<typeof AdminSampleRequestsGetResponse>;
export const AdminSampleRequestsGetInput = routeInfo['GET /v1/admin/sampleRequests']['inputCodec'];
export type AdminSampleRequestsGetInput = t.TypeOf<typeof AdminSampleRequestsGetInput>;
export const AdminSampleRequestsIdPutResponse = routeInfo['PUT /v1/admin/sampleRequests/{id}']['responseCodec'];
export type AdminSampleRequestsIdPutResponse = t.TypeOf<typeof AdminSampleRequestsIdPutResponse>;
export const AdminSampleRequestsIdPutInput = routeInfo['PUT /v1/admin/sampleRequests/{id}']['inputCodec'];
export type AdminSampleRequestsIdPutInput = t.TypeOf<typeof AdminSampleRequestsIdPutInput>;
export const AdminSampleRequestsSampleRequestIdAcceptPostResponse = routeInfo['POST /v1/admin/sampleRequests/{sampleRequestId}/accept']['responseCodec'];
export type AdminSampleRequestsSampleRequestIdAcceptPostResponse = t.TypeOf<typeof AdminSampleRequestsSampleRequestIdAcceptPostResponse>;
export const AdminSampleRequestsSampleRequestIdAcceptPostInput = routeInfo['POST /v1/admin/sampleRequests/{sampleRequestId}/accept']['inputCodec'];
export type AdminSampleRequestsSampleRequestIdAcceptPostInput = t.TypeOf<typeof AdminSampleRequestsSampleRequestIdAcceptPostInput>;
export const AdminSampleRequestsSampleRequestIdCancelPostResponse = routeInfo['POST /v1/admin/sampleRequests/{sampleRequestId}/cancel']['responseCodec'];
export type AdminSampleRequestsSampleRequestIdCancelPostResponse = t.TypeOf<typeof AdminSampleRequestsSampleRequestIdCancelPostResponse>;
export const AdminSampleRequestsSampleRequestIdCancelPostInput = routeInfo['POST /v1/admin/sampleRequests/{sampleRequestId}/cancel']['inputCodec'];
export type AdminSampleRequestsSampleRequestIdCancelPostInput = t.TypeOf<typeof AdminSampleRequestsSampleRequestIdCancelPostInput>;
export const AdminSampleRequestsSampleRequestIdUncancelPostResponse = routeInfo['POST /v1/admin/sampleRequests/{sampleRequestId}/uncancel']['responseCodec'];
export type AdminSampleRequestsSampleRequestIdUncancelPostResponse = t.TypeOf<typeof AdminSampleRequestsSampleRequestIdUncancelPostResponse>;
export const AdminSampleRequestsSampleRequestIdUncancelPostInput = routeInfo['POST /v1/admin/sampleRequests/{sampleRequestId}/uncancel']['inputCodec'];
export type AdminSampleRequestsSampleRequestIdUncancelPostInput = t.TypeOf<typeof AdminSampleRequestsSampleRequestIdUncancelPostInput>;
export const AdminSampleRequestsSampleRequestIdResolveIssuePostResponse = routeInfo['POST /v1/admin/sampleRequests/{sampleRequestId}/resolveIssue']['responseCodec'];
export type AdminSampleRequestsSampleRequestIdResolveIssuePostResponse = t.TypeOf<typeof AdminSampleRequestsSampleRequestIdResolveIssuePostResponse>;
export const AdminSampleRequestsSampleRequestIdResolveIssuePostInput = routeInfo['POST /v1/admin/sampleRequests/{sampleRequestId}/resolveIssue']['inputCodec'];
export type AdminSampleRequestsSampleRequestIdResolveIssuePostInput = t.TypeOf<typeof AdminSampleRequestsSampleRequestIdResolveIssuePostInput>;
export const SellersGetResponse = routeInfo['GET /v1/sellers']['responseCodec'];
export type SellersGetResponse = t.TypeOf<typeof SellersGetResponse>;
export const SellersGetInput = routeInfo['GET /v1/sellers']['inputCodec'];
export type SellersGetInput = t.TypeOf<typeof SellersGetInput>;
export const AdminSellersSellerIdOrSlugGetResponse = routeInfo['GET /v1/admin/sellers/{sellerIdOrSlug}']['responseCodec'];
export type AdminSellersSellerIdOrSlugGetResponse = t.TypeOf<typeof AdminSellersSellerIdOrSlugGetResponse>;
export const AdminSellersSellerIdOrSlugGetInput = routeInfo['GET /v1/admin/sellers/{sellerIdOrSlug}']['inputCodec'];
export type AdminSellersSellerIdOrSlugGetInput = t.TypeOf<typeof AdminSellersSellerIdOrSlugGetInput>;
export const AdminSellersSellerIdOrSlugPutResponse = routeInfo['PUT /v1/admin/sellers/{sellerIdOrSlug}']['responseCodec'];
export type AdminSellersSellerIdOrSlugPutResponse = t.TypeOf<typeof AdminSellersSellerIdOrSlugPutResponse>;
export const AdminSellersSellerIdOrSlugPutInput = routeInfo['PUT /v1/admin/sellers/{sellerIdOrSlug}']['inputCodec'];
export type AdminSellersSellerIdOrSlugPutInput = t.TypeOf<typeof AdminSellersSellerIdOrSlugPutInput>;
export const SellersSellerIdOrSlugGetResponse = routeInfo['GET /v1/sellers/{sellerIdOrSlug}']['responseCodec'];
export type SellersSellerIdOrSlugGetResponse = t.TypeOf<typeof SellersSellerIdOrSlugGetResponse>;
export const SellersSellerIdOrSlugGetInput = routeInfo['GET /v1/sellers/{sellerIdOrSlug}']['inputCodec'];
export type SellersSellerIdOrSlugGetInput = t.TypeOf<typeof SellersSellerIdOrSlugGetInput>;
export const SellersSellerIdOrSlugPatchResponse = routeInfo['PATCH /v1/sellers/{sellerIdOrSlug}']['responseCodec'];
export type SellersSellerIdOrSlugPatchResponse = t.TypeOf<typeof SellersSellerIdOrSlugPatchResponse>;
export const SellersSellerIdOrSlugPatchInput = routeInfo['PATCH /v1/sellers/{sellerIdOrSlug}']['inputCodec'];
export type SellersSellerIdOrSlugPatchInput = t.TypeOf<typeof SellersSellerIdOrSlugPatchInput>;
export const SellersSellerIdOrSlugDeleteResponse = routeInfo['DELETE /v1/sellers/{sellerIdOrSlug}']['responseCodec'];
export type SellersSellerIdOrSlugDeleteResponse = t.TypeOf<typeof SellersSellerIdOrSlugDeleteResponse>;
export const SellersSellerIdOrSlugDeleteInput = routeInfo['DELETE /v1/sellers/{sellerIdOrSlug}']['inputCodec'];
export type SellersSellerIdOrSlugDeleteInput = t.TypeOf<typeof SellersSellerIdOrSlugDeleteInput>;
export const SellersSellerIdOrSlugCategoriesGetResponse = routeInfo['GET /v1/sellers/{sellerIdOrSlug}/categories']['responseCodec'];
export type SellersSellerIdOrSlugCategoriesGetResponse = t.TypeOf<typeof SellersSellerIdOrSlugCategoriesGetResponse>;
export const SellersSellerIdOrSlugCategoriesGetInput = routeInfo['GET /v1/sellers/{sellerIdOrSlug}/categories']['inputCodec'];
export type SellersSellerIdOrSlugCategoriesGetInput = t.TypeOf<typeof SellersSellerIdOrSlugCategoriesGetInput>;
export const SellersSellerShortSlugSlugGetResponse = routeInfo['GET /v1/sellers/{sellerShortSlug}/slug']['responseCodec'];
export type SellersSellerShortSlugSlugGetResponse = t.TypeOf<typeof SellersSellerShortSlugSlugGetResponse>;
export const SellersSellerShortSlugSlugGetInput = routeInfo['GET /v1/sellers/{sellerShortSlug}/slug']['inputCodec'];
export type SellersSellerShortSlugSlugGetInput = t.TypeOf<typeof SellersSellerShortSlugSlugGetInput>;
export const SellersSellerIdOrMeGoLivePostResponse = routeInfo['POST /v1/sellers/{sellerIdOrMe}/goLive']['responseCodec'];
export type SellersSellerIdOrMeGoLivePostResponse = t.TypeOf<typeof SellersSellerIdOrMeGoLivePostResponse>;
export const SellersSellerIdOrMeGoLivePostInput = routeInfo['POST /v1/sellers/{sellerIdOrMe}/goLive']['inputCodec'];
export type SellersSellerIdOrMeGoLivePostInput = t.TypeOf<typeof SellersSellerIdOrMeGoLivePostInput>;
export const SellersSellerIdOrMeOrdersCsvGetResponse = routeInfo['GET /v1/sellers/{sellerIdOrMe}/ordersCsv']['responseCodec'];
export type SellersSellerIdOrMeOrdersCsvGetResponse = t.TypeOf<typeof SellersSellerIdOrMeOrdersCsvGetResponse>;
export const SellersSellerIdOrMeOrdersCsvGetInput = routeInfo['GET /v1/sellers/{sellerIdOrMe}/ordersCsv']['inputCodec'];
export type SellersSellerIdOrMeOrdersCsvGetInput = t.TypeOf<typeof SellersSellerIdOrMeOrdersCsvGetInput>;
export const SellersSellerIdOrMeDetailedOrdersCsvGetResponse = routeInfo['GET /v1/sellers/{sellerIdOrMe}/detailedOrdersCsv']['responseCodec'];
export type SellersSellerIdOrMeDetailedOrdersCsvGetResponse = t.TypeOf<typeof SellersSellerIdOrMeDetailedOrdersCsvGetResponse>;
export const SellersSellerIdOrMeDetailedOrdersCsvGetInput = routeInfo['GET /v1/sellers/{sellerIdOrMe}/detailedOrdersCsv']['inputCodec'];
export type SellersSellerIdOrMeDetailedOrdersCsvGetInput = t.TypeOf<typeof SellersSellerIdOrMeDetailedOrdersCsvGetInput>;
export const SellersSellerIdOrMeShippingSavingsGetResponse = routeInfo['GET /v1/sellers/{sellerIdOrMe}/shippingSavings']['responseCodec'];
export type SellersSellerIdOrMeShippingSavingsGetResponse = t.TypeOf<typeof SellersSellerIdOrMeShippingSavingsGetResponse>;
export const SellersSellerIdOrMeShippingSavingsGetInput = routeInfo['GET /v1/sellers/{sellerIdOrMe}/shippingSavings']['inputCodec'];
export type SellersSellerIdOrMeShippingSavingsGetInput = t.TypeOf<typeof SellersSellerIdOrMeShippingSavingsGetInput>;
export const SellersSellerIdOrMeModifyProductGetResponse = routeInfo['GET /v1/sellers/{sellerIdOrMe}/modifyProduct']['responseCodec'];
export type SellersSellerIdOrMeModifyProductGetResponse = t.TypeOf<typeof SellersSellerIdOrMeModifyProductGetResponse>;
export const SellersSellerIdOrMeModifyProductGetInput = routeInfo['GET /v1/sellers/{sellerIdOrMe}/modifyProduct']['inputCodec'];
export type SellersSellerIdOrMeModifyProductGetInput = t.TypeOf<typeof SellersSellerIdOrMeModifyProductGetInput>;
export const SellersSellerIdOrMeModifyProductPostResponse = routeInfo['POST /v1/sellers/{sellerIdOrMe}/modifyProduct']['responseCodec'];
export type SellersSellerIdOrMeModifyProductPostResponse = t.TypeOf<typeof SellersSellerIdOrMeModifyProductPostResponse>;
export const SellersSellerIdOrMeModifyProductPostInput = routeInfo['POST /v1/sellers/{sellerIdOrMe}/modifyProduct']['inputCodec'];
export type SellersSellerIdOrMeModifyProductPostInput = t.TypeOf<typeof SellersSellerIdOrMeModifyProductPostInput>;
export const SellersSellerIdProductsImportPutResponse = routeInfo['PUT /v1/sellers/{sellerId}/productsImport']['responseCodec'];
export type SellersSellerIdProductsImportPutResponse = t.TypeOf<typeof SellersSellerIdProductsImportPutResponse>;
export const SellersSellerIdProductsImportPutInput = routeInfo['PUT /v1/sellers/{sellerId}/productsImport']['inputCodec'];
export type SellersSellerIdProductsImportPutInput = t.TypeOf<typeof SellersSellerIdProductsImportPutInput>;
export const SellersSellerIdProductsImportPreviewPutResponse = routeInfo['PUT /v1/sellers/{sellerId}/productsImportPreview']['responseCodec'];
export type SellersSellerIdProductsImportPreviewPutResponse = t.TypeOf<typeof SellersSellerIdProductsImportPreviewPutResponse>;
export const SellersSellerIdProductsImportPreviewPutInput = routeInfo['PUT /v1/sellers/{sellerId}/productsImportPreview']['inputCodec'];
export type SellersSellerIdProductsImportPreviewPutInput = t.TypeOf<typeof SellersSellerIdProductsImportPreviewPutInput>;
export const SellersSellerIdCsvVariantDataImportPatchResponse = routeInfo['PATCH /v1/sellers/{sellerId}/csvVariantDataImport']['responseCodec'];
export type SellersSellerIdCsvVariantDataImportPatchResponse = t.TypeOf<typeof SellersSellerIdCsvVariantDataImportPatchResponse>;
export const SellersSellerIdCsvVariantDataImportPatchInput = routeInfo['PATCH /v1/sellers/{sellerId}/csvVariantDataImport']['inputCodec'];
export type SellersSellerIdCsvVariantDataImportPatchInput = t.TypeOf<typeof SellersSellerIdCsvVariantDataImportPatchInput>;
export const SellersSellerIdStatusGetResponse = routeInfo['GET /v1/sellers/{sellerId}/status']['responseCodec'];
export type SellersSellerIdStatusGetResponse = t.TypeOf<typeof SellersSellerIdStatusGetResponse>;
export const SellersSellerIdStatusGetInput = routeInfo['GET /v1/sellers/{sellerId}/status']['inputCodec'];
export type SellersSellerIdStatusGetInput = t.TypeOf<typeof SellersSellerIdStatusGetInput>;
export const SellersSellerIdOrMeShippingProgramEnrollPostResponse = routeInfo['POST /v1/sellers/{sellerIdOrMe}/shippingProgram/enroll']['responseCodec'];
export type SellersSellerIdOrMeShippingProgramEnrollPostResponse = t.TypeOf<typeof SellersSellerIdOrMeShippingProgramEnrollPostResponse>;
export const SellersSellerIdOrMeShippingProgramEnrollPostInput = routeInfo['POST /v1/sellers/{sellerIdOrMe}/shippingProgram/enroll']['inputCodec'];
export type SellersSellerIdOrMeShippingProgramEnrollPostInput = t.TypeOf<typeof SellersSellerIdOrMeShippingProgramEnrollPostInput>;
export const SellersSellerIdOrMeShippingProgramUnenrollPostResponse = routeInfo['POST /v1/sellers/{sellerIdOrMe}/shippingProgram/unenroll']['responseCodec'];
export type SellersSellerIdOrMeShippingProgramUnenrollPostResponse = t.TypeOf<typeof SellersSellerIdOrMeShippingProgramUnenrollPostResponse>;
export const SellersSellerIdOrMeShippingProgramUnenrollPostInput = routeInfo['POST /v1/sellers/{sellerIdOrMe}/shippingProgram/unenroll']['inputCodec'];
export type SellersSellerIdOrMeShippingProgramUnenrollPostInput = t.TypeOf<typeof SellersSellerIdOrMeShippingProgramUnenrollPostInput>;
export const SellersSellerIdOrMePartnerProgramPartnerAccountNameOnboardingPatchResponse = routeInfo['PATCH /v1/sellers/{sellerIdOrMe}/partnerProgram/{partnerAccountName}/onboarding']['responseCodec'];
export type SellersSellerIdOrMePartnerProgramPartnerAccountNameOnboardingPatchResponse = t.TypeOf<typeof SellersSellerIdOrMePartnerProgramPartnerAccountNameOnboardingPatchResponse>;
export const SellersSellerIdOrMePartnerProgramPartnerAccountNameOnboardingPatchInput = routeInfo['PATCH /v1/sellers/{sellerIdOrMe}/partnerProgram/{partnerAccountName}/onboarding']['inputCodec'];
export type SellersSellerIdOrMePartnerProgramPartnerAccountNameOnboardingPatchInput = t.TypeOf<typeof SellersSellerIdOrMePartnerProgramPartnerAccountNameOnboardingPatchInput>;
export const SellersMePartnerProgramPartnerAccountNameProductDataExportPostResponse = routeInfo['POST /v1/sellers/@me/partnerProgram/{partnerAccountName}/productDataExport']['responseCodec'];
export type SellersMePartnerProgramPartnerAccountNameProductDataExportPostResponse = t.TypeOf<typeof SellersMePartnerProgramPartnerAccountNameProductDataExportPostResponse>;
export const SellersMePartnerProgramPartnerAccountNameProductDataExportPostInput = routeInfo['POST /v1/sellers/@me/partnerProgram/{partnerAccountName}/productDataExport']['inputCodec'];
export type SellersMePartnerProgramPartnerAccountNameProductDataExportPostInput = t.TypeOf<typeof SellersMePartnerProgramPartnerAccountNameProductDataExportPostInput>;
export const TransactionsGetResponse = routeInfo['GET /v1/transactions']['responseCodec'];
export type TransactionsGetResponse = t.TypeOf<typeof TransactionsGetResponse>;
export const TransactionsGetInput = routeInfo['GET /v1/transactions']['inputCodec'];
export type TransactionsGetInput = t.TypeOf<typeof TransactionsGetInput>;
export const AdminTransactionsTransactionIdGetResponse = routeInfo['GET /v1/admin/transactions/{transactionId}']['responseCodec'];
export type AdminTransactionsTransactionIdGetResponse = t.TypeOf<typeof AdminTransactionsTransactionIdGetResponse>;
export const AdminTransactionsTransactionIdGetInput = routeInfo['GET /v1/admin/transactions/{transactionId}']['inputCodec'];
export type AdminTransactionsTransactionIdGetInput = t.TypeOf<typeof AdminTransactionsTransactionIdGetInput>;
export const TransactionsTransactionIdApplyCommmissionPostResponse = routeInfo['POST /v1/transactions/{transactionId}/applyCommmission']['responseCodec'];
export type TransactionsTransactionIdApplyCommmissionPostResponse = t.TypeOf<typeof TransactionsTransactionIdApplyCommmissionPostResponse>;
export const TransactionsTransactionIdApplyCommmissionPostInput = routeInfo['POST /v1/transactions/{transactionId}/applyCommmission']['inputCodec'];
export type TransactionsTransactionIdApplyCommmissionPostInput = t.TypeOf<typeof TransactionsTransactionIdApplyCommmissionPostInput>;
export const TransactionsTransactionIdLogPostResponse = routeInfo['POST /v1/transactions/{transactionId}/log']['responseCodec'];
export type TransactionsTransactionIdLogPostResponse = t.TypeOf<typeof TransactionsTransactionIdLogPostResponse>;
export const TransactionsTransactionIdLogPostInput = routeInfo['POST /v1/transactions/{transactionId}/log']['inputCodec'];
export type TransactionsTransactionIdLogPostInput = t.TypeOf<typeof TransactionsTransactionIdLogPostInput>;
export const TransactionsTransactionIdShipmentsGetResponse = routeInfo['GET /v1/transactions/{transactionId}/shipments']['responseCodec'];
export type TransactionsTransactionIdShipmentsGetResponse = t.TypeOf<typeof TransactionsTransactionIdShipmentsGetResponse>;
export const TransactionsTransactionIdShipmentsGetInput = routeInfo['GET /v1/transactions/{transactionId}/shipments']['inputCodec'];
export type TransactionsTransactionIdShipmentsGetInput = t.TypeOf<typeof TransactionsTransactionIdShipmentsGetInput>;
export const TransactionsTransactionIdShipmentsPostResponse = routeInfo['POST /v1/transactions/{transactionId}/shipments']['responseCodec'];
export type TransactionsTransactionIdShipmentsPostResponse = t.TypeOf<typeof TransactionsTransactionIdShipmentsPostResponse>;
export const TransactionsTransactionIdShipmentsPostInput = routeInfo['POST /v1/transactions/{transactionId}/shipments']['inputCodec'];
export type TransactionsTransactionIdShipmentsPostInput = t.TypeOf<typeof TransactionsTransactionIdShipmentsPostInput>;
export const TransactionsTransactionIdShipmentsShipmentIdPatchResponse = routeInfo['PATCH /v1/transactions/{transactionId}/shipments/{shipmentId}']['responseCodec'];
export type TransactionsTransactionIdShipmentsShipmentIdPatchResponse = t.TypeOf<typeof TransactionsTransactionIdShipmentsShipmentIdPatchResponse>;
export const TransactionsTransactionIdShipmentsShipmentIdPatchInput = routeInfo['PATCH /v1/transactions/{transactionId}/shipments/{shipmentId}']['inputCodec'];
export type TransactionsTransactionIdShipmentsShipmentIdPatchInput = t.TypeOf<typeof TransactionsTransactionIdShipmentsShipmentIdPatchInput>;
export const TransactionsTransactionIdShipmentsShipmentIdVoidPostResponse = routeInfo['POST /v1/transactions/{transactionId}/shipments/{shipmentId}/void']['responseCodec'];
export type TransactionsTransactionIdShipmentsShipmentIdVoidPostResponse = t.TypeOf<typeof TransactionsTransactionIdShipmentsShipmentIdVoidPostResponse>;
export const TransactionsTransactionIdShipmentsShipmentIdVoidPostInput = routeInfo['POST /v1/transactions/{transactionId}/shipments/{shipmentId}/void']['inputCodec'];
export type TransactionsTransactionIdShipmentsShipmentIdVoidPostInput = t.TypeOf<typeof TransactionsTransactionIdShipmentsShipmentIdVoidPostInput>;
export const CartNotePutResponse = routeInfo['PUT /v1/cart/note']['responseCodec'];
export type CartNotePutResponse = t.TypeOf<typeof CartNotePutResponse>;
export const CartNotePutInput = routeInfo['PUT /v1/cart/note']['inputCodec'];
export type CartNotePutInput = t.TypeOf<typeof CartNotePutInput>;
export const CartDiscountPostResponse = routeInfo['POST /v1/cart/discount']['responseCodec'];
export type CartDiscountPostResponse = t.TypeOf<typeof CartDiscountPostResponse>;
export const CartDiscountPostInput = routeInfo['POST /v1/cart/discount']['inputCodec'];
export type CartDiscountPostInput = t.TypeOf<typeof CartDiscountPostInput>;
export const CartDiscountDeleteResponse = routeInfo['DELETE /v1/cart/discount']['responseCodec'];
export type CartDiscountDeleteResponse = t.TypeOf<typeof CartDiscountDeleteResponse>;
export const CartDiscountDeleteInput = routeInfo['DELETE /v1/cart/discount']['inputCodec'];
export type CartDiscountDeleteInput = t.TypeOf<typeof CartDiscountDeleteInput>;
export const CartUpdatePutResponse = routeInfo['PUT /v1/cart/update']['responseCodec'];
export type CartUpdatePutResponse = t.TypeOf<typeof CartUpdatePutResponse>;
export const CartUpdatePutInput = routeInfo['PUT /v1/cart/update']['inputCodec'];
export type CartUpdatePutInput = t.TypeOf<typeof CartUpdatePutInput>;
export const CartDeleteResponse = routeInfo['DELETE /v1/cart']['responseCodec'];
export type CartDeleteResponse = t.TypeOf<typeof CartDeleteResponse>;
export const CartDeleteInput = routeInfo['DELETE /v1/cart']['inputCodec'];
export type CartDeleteInput = t.TypeOf<typeof CartDeleteInput>;
export const CartGetResponse = routeInfo['GET /v1/cart']['responseCodec'];
export type CartGetResponse = t.TypeOf<typeof CartGetResponse>;
export const CartGetInput = routeInfo['GET /v1/cart']['inputCodec'];
export type CartGetInput = t.TypeOf<typeof CartGetInput>;
export const CartReorderOrderIdPostResponse = routeInfo['POST /v1/cart/reorder/{orderId}']['responseCodec'];
export type CartReorderOrderIdPostResponse = t.TypeOf<typeof CartReorderOrderIdPostResponse>;
export const CartReorderOrderIdPostInput = routeInfo['POST /v1/cart/reorder/{orderId}']['inputCodec'];
export type CartReorderOrderIdPostInput = t.TypeOf<typeof CartReorderOrderIdPostInput>;
export const CheckoutGetResponse = routeInfo['GET /v1/checkout']['responseCodec'];
export type CheckoutGetResponse = t.TypeOf<typeof CheckoutGetResponse>;
export const CheckoutGetInput = routeInfo['GET /v1/checkout']['inputCodec'];
export type CheckoutGetInput = t.TypeOf<typeof CheckoutGetInput>;
export const CheckoutPostResponse = routeInfo['POST /v1/checkout']['responseCodec'];
export type CheckoutPostResponse = t.TypeOf<typeof CheckoutPostResponse>;
export const CheckoutPostInput = routeInfo['POST /v1/checkout']['inputCodec'];
export type CheckoutPostInput = t.TypeOf<typeof CheckoutPostInput>;
export const AdminTransactionGroupsTransactionGroupIdSendPurchaseOrderPostResponse = routeInfo['POST /v1/admin/transactionGroups/{transactionGroupId}/sendPurchaseOrder']['responseCodec'];
export type AdminTransactionGroupsTransactionGroupIdSendPurchaseOrderPostResponse = t.TypeOf<typeof AdminTransactionGroupsTransactionGroupIdSendPurchaseOrderPostResponse>;
export const AdminTransactionGroupsTransactionGroupIdSendPurchaseOrderPostInput = routeInfo['POST /v1/admin/transactionGroups/{transactionGroupId}/sendPurchaseOrder']['inputCodec'];
export type AdminTransactionGroupsTransactionGroupIdSendPurchaseOrderPostInput = t.TypeOf<typeof AdminTransactionGroupsTransactionGroupIdSendPurchaseOrderPostInput>;
export const AdminTransactionGroupsTransactionGroupIdEdiInvoicePostResponse = routeInfo['POST /v1/admin/transactionGroups/{transactionGroupId}/ediInvoice']['responseCodec'];
export type AdminTransactionGroupsTransactionGroupIdEdiInvoicePostResponse = t.TypeOf<typeof AdminTransactionGroupsTransactionGroupIdEdiInvoicePostResponse>;
export const AdminTransactionGroupsTransactionGroupIdEdiInvoicePostInput = routeInfo['POST /v1/admin/transactionGroups/{transactionGroupId}/ediInvoice']['inputCodec'];
export type AdminTransactionGroupsTransactionGroupIdEdiInvoicePostInput = t.TypeOf<typeof AdminTransactionGroupsTransactionGroupIdEdiInvoicePostInput>;
export const AdminTransactionGroupsTransactionGroupIdSendEdiInvoicePostResponse = routeInfo['POST /v1/admin/transactionGroups/{transactionGroupId}/sendEdiInvoice']['responseCodec'];
export type AdminTransactionGroupsTransactionGroupIdSendEdiInvoicePostResponse = t.TypeOf<typeof AdminTransactionGroupsTransactionGroupIdSendEdiInvoicePostResponse>;
export const AdminTransactionGroupsTransactionGroupIdSendEdiInvoicePostInput = routeInfo['POST /v1/admin/transactionGroups/{transactionGroupId}/sendEdiInvoice']['inputCodec'];
export type AdminTransactionGroupsTransactionGroupIdSendEdiInvoicePostInput = t.TypeOf<typeof AdminTransactionGroupsTransactionGroupIdSendEdiInvoicePostInput>;
export const AdminTransactionGroupsTransactionGroupIdSendOrderSettledEmailPostResponse = routeInfo['POST /v1/admin/transactionGroups/{transactionGroupId}/sendOrderSettledEmail']['responseCodec'];
export type AdminTransactionGroupsTransactionGroupIdSendOrderSettledEmailPostResponse = t.TypeOf<typeof AdminTransactionGroupsTransactionGroupIdSendOrderSettledEmailPostResponse>;
export const AdminTransactionGroupsTransactionGroupIdSendOrderSettledEmailPostInput = routeInfo['POST /v1/admin/transactionGroups/{transactionGroupId}/sendOrderSettledEmail']['inputCodec'];
export type AdminTransactionGroupsTransactionGroupIdSendOrderSettledEmailPostInput = t.TypeOf<typeof AdminTransactionGroupsTransactionGroupIdSendOrderSettledEmailPostInput>;
export const AdminTransactionsTransactionIdAddProductPurchasePostResponse = routeInfo['POST /v1/admin/transactions/{transactionId}/addProductPurchase']['responseCodec'];
export type AdminTransactionsTransactionIdAddProductPurchasePostResponse = t.TypeOf<typeof AdminTransactionsTransactionIdAddProductPurchasePostResponse>;
export const AdminTransactionsTransactionIdAddProductPurchasePostInput = routeInfo['POST /v1/admin/transactions/{transactionId}/addProductPurchase']['inputCodec'];
export type AdminTransactionsTransactionIdAddProductPurchasePostInput = t.TypeOf<typeof AdminTransactionsTransactionIdAddProductPurchasePostInput>;
export const AdminTransactionsTransactionIdAttributionPostResponse = routeInfo['POST /v1/admin/transactions/{transactionId}/attribution']['responseCodec'];
export type AdminTransactionsTransactionIdAttributionPostResponse = t.TypeOf<typeof AdminTransactionsTransactionIdAttributionPostResponse>;
export const AdminTransactionsTransactionIdAttributionPostInput = routeInfo['POST /v1/admin/transactions/{transactionId}/attribution']['inputCodec'];
export type AdminTransactionsTransactionIdAttributionPostInput = t.TypeOf<typeof AdminTransactionsTransactionIdAttributionPostInput>;
export const AdminTransactionsTransactionIdSplitsSplitIdPutResponse = routeInfo['PUT /v1/admin/transactions/{transactionId}/splits/{splitId}']['responseCodec'];
export type AdminTransactionsTransactionIdSplitsSplitIdPutResponse = t.TypeOf<typeof AdminTransactionsTransactionIdSplitsSplitIdPutResponse>;
export const AdminTransactionsTransactionIdSplitsSplitIdPutInput = routeInfo['PUT /v1/admin/transactions/{transactionId}/splits/{splitId}']['inputCodec'];
export type AdminTransactionsTransactionIdSplitsSplitIdPutInput = t.TypeOf<typeof AdminTransactionsTransactionIdSplitsSplitIdPutInput>;
export const AdminTransactionsTransactionIdReadyForBuyerInvoicePostResponse = routeInfo['POST /v1/admin/transactions/{transactionId}/readyForBuyerInvoice']['responseCodec'];
export type AdminTransactionsTransactionIdReadyForBuyerInvoicePostResponse = t.TypeOf<typeof AdminTransactionsTransactionIdReadyForBuyerInvoicePostResponse>;
export const AdminTransactionsTransactionIdReadyForBuyerInvoicePostInput = routeInfo['POST /v1/admin/transactions/{transactionId}/readyForBuyerInvoice']['inputCodec'];
export type AdminTransactionsTransactionIdReadyForBuyerInvoicePostInput = t.TypeOf<typeof AdminTransactionsTransactionIdReadyForBuyerInvoicePostInput>;
export const AdminTransactionsTransactionIdFulfillmentRoutingPostResponse = routeInfo['POST /v1/admin/transactions/{transactionId}/fulfillmentRouting']['responseCodec'];
export type AdminTransactionsTransactionIdFulfillmentRoutingPostResponse = t.TypeOf<typeof AdminTransactionsTransactionIdFulfillmentRoutingPostResponse>;
export const AdminTransactionsTransactionIdFulfillmentRoutingPostInput = routeInfo['POST /v1/admin/transactions/{transactionId}/fulfillmentRouting']['inputCodec'];
export type AdminTransactionsTransactionIdFulfillmentRoutingPostInput = t.TypeOf<typeof AdminTransactionsTransactionIdFulfillmentRoutingPostInput>;
export const AdminTransactionsTransactionIdSendInvoiceEdiPostResponse = routeInfo['POST /v1/admin/transactions/{transactionId}/sendInvoice/edi']['responseCodec'];
export type AdminTransactionsTransactionIdSendInvoiceEdiPostResponse = t.TypeOf<typeof AdminTransactionsTransactionIdSendInvoiceEdiPostResponse>;
export const AdminTransactionsTransactionIdSendInvoiceEdiPostInput = routeInfo['POST /v1/admin/transactions/{transactionId}/sendInvoice/edi']['inputCodec'];
export type AdminTransactionsTransactionIdSendInvoiceEdiPostInput = t.TypeOf<typeof AdminTransactionsTransactionIdSendInvoiceEdiPostInput>;
export const AdminTransactionsTransactionIdEdiAckSendPostResponse = routeInfo['POST /v1/admin/transactions/{transactionId}/ediAck/send']['responseCodec'];
export type AdminTransactionsTransactionIdEdiAckSendPostResponse = t.TypeOf<typeof AdminTransactionsTransactionIdEdiAckSendPostResponse>;
export const AdminTransactionsTransactionIdEdiAckSendPostInput = routeInfo['POST /v1/admin/transactions/{transactionId}/ediAck/send']['inputCodec'];
export type AdminTransactionsTransactionIdEdiAckSendPostInput = t.TypeOf<typeof AdminTransactionsTransactionIdEdiAckSendPostInput>;
export const AdminAccountAccountIdEdiCheckForFilesPostResponse = routeInfo['POST /v1/admin/account/{accountId}/edi/checkForFiles']['responseCodec'];
export type AdminAccountAccountIdEdiCheckForFilesPostResponse = t.TypeOf<typeof AdminAccountAccountIdEdiCheckForFilesPostResponse>;
export const AdminAccountAccountIdEdiCheckForFilesPostInput = routeInfo['POST /v1/admin/account/{accountId}/edi/checkForFiles']['inputCodec'];
export type AdminAccountAccountIdEdiCheckForFilesPostInput = t.TypeOf<typeof AdminAccountAccountIdEdiCheckForFilesPostInput>;
export const AdminAccountAccountIdEdiTestFtpConnectionPostResponse = routeInfo['POST /v1/admin/account/{accountId}/edi/testFtpConnection']['responseCodec'];
export type AdminAccountAccountIdEdiTestFtpConnectionPostResponse = t.TypeOf<typeof AdminAccountAccountIdEdiTestFtpConnectionPostResponse>;
export const AdminAccountAccountIdEdiTestFtpConnectionPostInput = routeInfo['POST /v1/admin/account/{accountId}/edi/testFtpConnection']['inputCodec'];
export type AdminAccountAccountIdEdiTestFtpConnectionPostInput = t.TypeOf<typeof AdminAccountAccountIdEdiTestFtpConnectionPostInput>;
export const AdminTransactionsTransactionIdSendCreditInvoiceEdiPostResponse = routeInfo['POST /v1/admin/transactions/{transactionId}/sendCreditInvoice/edi']['responseCodec'];
export type AdminTransactionsTransactionIdSendCreditInvoiceEdiPostResponse = t.TypeOf<typeof AdminTransactionsTransactionIdSendCreditInvoiceEdiPostResponse>;
export const AdminTransactionsTransactionIdSendCreditInvoiceEdiPostInput = routeInfo['POST /v1/admin/transactions/{transactionId}/sendCreditInvoice/edi']['inputCodec'];
export type AdminTransactionsTransactionIdSendCreditInvoiceEdiPostInput = t.TypeOf<typeof AdminTransactionsTransactionIdSendCreditInvoiceEdiPostInput>;
export const AdminTransactionsTransactionIdRedistributeShippingPostResponse = routeInfo['POST /v1/admin/transactions/{transactionId}/redistributeShipping']['responseCodec'];
export type AdminTransactionsTransactionIdRedistributeShippingPostResponse = t.TypeOf<typeof AdminTransactionsTransactionIdRedistributeShippingPostResponse>;
export const AdminTransactionsTransactionIdRedistributeShippingPostInput = routeInfo['POST /v1/admin/transactions/{transactionId}/redistributeShipping']['inputCodec'];
export type AdminTransactionsTransactionIdRedistributeShippingPostInput = t.TypeOf<typeof AdminTransactionsTransactionIdRedistributeShippingPostInput>;
export const AdminTransactionsTransactionIdRemoveFreeShippingMinimumPostResponse = routeInfo['POST /v1/admin/transactions/{transactionId}/removeFreeShippingMinimum']['responseCodec'];
export type AdminTransactionsTransactionIdRemoveFreeShippingMinimumPostResponse = t.TypeOf<typeof AdminTransactionsTransactionIdRemoveFreeShippingMinimumPostResponse>;
export const AdminTransactionsTransactionIdRemoveFreeShippingMinimumPostInput = routeInfo['POST /v1/admin/transactions/{transactionId}/removeFreeShippingMinimum']['inputCodec'];
export type AdminTransactionsTransactionIdRemoveFreeShippingMinimumPostInput = t.TypeOf<typeof AdminTransactionsTransactionIdRemoveFreeShippingMinimumPostInput>;
export const AdminTransactionsTransactionIdApplyCreditsPostResponse = routeInfo['POST /v1/admin/transactions/{transactionId}/applyCredits']['responseCodec'];
export type AdminTransactionsTransactionIdApplyCreditsPostResponse = t.TypeOf<typeof AdminTransactionsTransactionIdApplyCreditsPostResponse>;
export const AdminTransactionsTransactionIdApplyCreditsPostInput = routeInfo['POST /v1/admin/transactions/{transactionId}/applyCredits']['inputCodec'];
export type AdminTransactionsTransactionIdApplyCreditsPostInput = t.TypeOf<typeof AdminTransactionsTransactionIdApplyCreditsPostInput>;
export const AdminTransactionsRecordCheckPaymentPostResponse = routeInfo['POST /v1/admin/transactions/recordCheckPayment']['responseCodec'];
export type AdminTransactionsRecordCheckPaymentPostResponse = t.TypeOf<typeof AdminTransactionsRecordCheckPaymentPostResponse>;
export const AdminTransactionsRecordCheckPaymentPostInput = routeInfo['POST /v1/admin/transactions/recordCheckPayment']['inputCodec'];
export type AdminTransactionsRecordCheckPaymentPostInput = t.TypeOf<typeof AdminTransactionsRecordCheckPaymentPostInput>;
export const AdminTransactionsTransactionIdRecordCheckPaymentPostResponse = routeInfo['POST /v1/admin/transactions/{transactionId}/recordCheckPayment']['responseCodec'];
export type AdminTransactionsTransactionIdRecordCheckPaymentPostResponse = t.TypeOf<typeof AdminTransactionsTransactionIdRecordCheckPaymentPostResponse>;
export const AdminTransactionsTransactionIdRecordCheckPaymentPostInput = routeInfo['POST /v1/admin/transactions/{transactionId}/recordCheckPayment']['inputCodec'];
export type AdminTransactionsTransactionIdRecordCheckPaymentPostInput = t.TypeOf<typeof AdminTransactionsTransactionIdRecordCheckPaymentPostInput>;
export const AdminTransactionsTransactionIdEdiInvoiceGetResponse = routeInfo['GET /v1/admin/transactions/{transactionId}/ediInvoice']['responseCodec'];
export type AdminTransactionsTransactionIdEdiInvoiceGetResponse = t.TypeOf<typeof AdminTransactionsTransactionIdEdiInvoiceGetResponse>;
export const AdminTransactionsTransactionIdEdiInvoiceGetInput = routeInfo['GET /v1/admin/transactions/{transactionId}/ediInvoice']['inputCodec'];
export type AdminTransactionsTransactionIdEdiInvoiceGetInput = t.TypeOf<typeof AdminTransactionsTransactionIdEdiInvoiceGetInput>;
export const AdminTransactionsTransactionIdEdiCreditInvoiceGetResponse = routeInfo['GET /v1/admin/transactions/{transactionId}/ediCreditInvoice']['responseCodec'];
export type AdminTransactionsTransactionIdEdiCreditInvoiceGetResponse = t.TypeOf<typeof AdminTransactionsTransactionIdEdiCreditInvoiceGetResponse>;
export const AdminTransactionsTransactionIdEdiCreditInvoiceGetInput = routeInfo['GET /v1/admin/transactions/{transactionId}/ediCreditInvoice']['inputCodec'];
export type AdminTransactionsTransactionIdEdiCreditInvoiceGetInput = t.TypeOf<typeof AdminTransactionsTransactionIdEdiCreditInvoiceGetInput>;
export const AdminTransactionsTransactionIdEdiAckGetResponse = routeInfo['GET /v1/admin/transactions/{transactionId}/ediAck']['responseCodec'];
export type AdminTransactionsTransactionIdEdiAckGetResponse = t.TypeOf<typeof AdminTransactionsTransactionIdEdiAckGetResponse>;
export const AdminTransactionsTransactionIdEdiAckGetInput = routeInfo['GET /v1/admin/transactions/{transactionId}/ediAck']['inputCodec'];
export type AdminTransactionsTransactionIdEdiAckGetInput = t.TypeOf<typeof AdminTransactionsTransactionIdEdiAckGetInput>;
export const AdminTransactionsTransactionIdEdiShipNoticePostResponse = routeInfo['POST /v1/admin/transactions/{transactionId}/ediShipNotice']['responseCodec'];
export type AdminTransactionsTransactionIdEdiShipNoticePostResponse = t.TypeOf<typeof AdminTransactionsTransactionIdEdiShipNoticePostResponse>;
export const AdminTransactionsTransactionIdEdiShipNoticePostInput = routeInfo['POST /v1/admin/transactions/{transactionId}/ediShipNotice']['inputCodec'];
export type AdminTransactionsTransactionIdEdiShipNoticePostInput = t.TypeOf<typeof AdminTransactionsTransactionIdEdiShipNoticePostInput>;
export const AdminTransactionsTransactionIdEdiShipNoticeSendPostResponse = routeInfo['POST /v1/admin/transactions/{transactionId}/ediShipNotice/send']['responseCodec'];
export type AdminTransactionsTransactionIdEdiShipNoticeSendPostResponse = t.TypeOf<typeof AdminTransactionsTransactionIdEdiShipNoticeSendPostResponse>;
export const AdminTransactionsTransactionIdEdiShipNoticeSendPostInput = routeInfo['POST /v1/admin/transactions/{transactionId}/ediShipNotice/send']['inputCodec'];
export type AdminTransactionsTransactionIdEdiShipNoticeSendPostInput = t.TypeOf<typeof AdminTransactionsTransactionIdEdiShipNoticeSendPostInput>;
export const AdminTransactionsTransactionIdEdiDeliveryBaseRecordPostResponse = routeInfo['POST /v1/admin/transactions/{transactionId}/ediDeliveryBaseRecord']['responseCodec'];
export type AdminTransactionsTransactionIdEdiDeliveryBaseRecordPostResponse = t.TypeOf<typeof AdminTransactionsTransactionIdEdiDeliveryBaseRecordPostResponse>;
export const AdminTransactionsTransactionIdEdiDeliveryBaseRecordPostInput = routeInfo['POST /v1/admin/transactions/{transactionId}/ediDeliveryBaseRecord']['inputCodec'];
export type AdminTransactionsTransactionIdEdiDeliveryBaseRecordPostInput = t.TypeOf<typeof AdminTransactionsTransactionIdEdiDeliveryBaseRecordPostInput>;
export const AdminTransactionsTransactionIdEdiDeliveryBaseRecordSendPostResponse = routeInfo['POST /v1/admin/transactions/{transactionId}/ediDeliveryBaseRecord/send']['responseCodec'];
export type AdminTransactionsTransactionIdEdiDeliveryBaseRecordSendPostResponse = t.TypeOf<typeof AdminTransactionsTransactionIdEdiDeliveryBaseRecordSendPostResponse>;
export const AdminTransactionsTransactionIdEdiDeliveryBaseRecordSendPostInput = routeInfo['POST /v1/admin/transactions/{transactionId}/ediDeliveryBaseRecord/send']['inputCodec'];
export type AdminTransactionsTransactionIdEdiDeliveryBaseRecordSendPostInput = t.TypeOf<typeof AdminTransactionsTransactionIdEdiDeliveryBaseRecordSendPostInput>;
export const AdminTransactionsTransactionIdPushAutoCancelDatePostResponse = routeInfo['POST /v1/admin/transactions/{transactionId}/pushAutoCancelDate']['responseCodec'];
export type AdminTransactionsTransactionIdPushAutoCancelDatePostResponse = t.TypeOf<typeof AdminTransactionsTransactionIdPushAutoCancelDatePostResponse>;
export const AdminTransactionsTransactionIdPushAutoCancelDatePostInput = routeInfo['POST /v1/admin/transactions/{transactionId}/pushAutoCancelDate']['inputCodec'];
export type AdminTransactionsTransactionIdPushAutoCancelDatePostInput = t.TypeOf<typeof AdminTransactionsTransactionIdPushAutoCancelDatePostInput>;
export const PayPostResponse = routeInfo['POST /v1/pay']['responseCodec'];
export type PayPostResponse = t.TypeOf<typeof PayPostResponse>;
export const PayPostInput = routeInfo['POST /v1/pay']['inputCodec'];
export type PayPostInput = t.TypeOf<typeof PayPostInput>;
export const AdminProductsSupplementPostResponse = routeInfo['POST /v1/admin/products/supplement']['responseCodec'];
export type AdminProductsSupplementPostResponse = t.TypeOf<typeof AdminProductsSupplementPostResponse>;
export const AdminProductsSupplementPostInput = routeInfo['POST /v1/admin/products/supplement']['inputCodec'];
export type AdminProductsSupplementPostInput = t.TypeOf<typeof AdminProductsSupplementPostInput>;
export const AdminProductsFetchAllIxOnePostResponse = routeInfo['POST /v1/admin/products/fetchAllIxOne']['responseCodec'];
export type AdminProductsFetchAllIxOnePostResponse = t.TypeOf<typeof AdminProductsFetchAllIxOnePostResponse>;
export const AdminProductsFetchAllIxOnePostInput = routeInfo['POST /v1/admin/products/fetchAllIxOne']['inputCodec'];
export type AdminProductsFetchAllIxOnePostInput = t.TypeOf<typeof AdminProductsFetchAllIxOnePostInput>;
export const AdminProductsConvertBrandOnboardingCsvPostResponse = routeInfo['POST /v1/admin/products/convertBrandOnboardingCsv']['responseCodec'];
export type AdminProductsConvertBrandOnboardingCsvPostResponse = t.TypeOf<typeof AdminProductsConvertBrandOnboardingCsvPostResponse>;
export const AdminProductsConvertBrandOnboardingCsvPostInput = routeInfo['POST /v1/admin/products/convertBrandOnboardingCsv']['inputCodec'];
export type AdminProductsConvertBrandOnboardingCsvPostInput = t.TypeOf<typeof AdminProductsConvertBrandOnboardingCsvPostInput>;
export const PayMethodsGetResponse = routeInfo['GET /v1/pay/methods']['responseCodec'];
export type PayMethodsGetResponse = t.TypeOf<typeof PayMethodsGetResponse>;
export const PayMethodsGetInput = routeInfo['GET /v1/pay/methods']['inputCodec'];
export type PayMethodsGetInput = t.TypeOf<typeof PayMethodsGetInput>;
export const PayMethodsPostResponse = routeInfo['POST /v1/pay/methods']['responseCodec'];
export type PayMethodsPostResponse = t.TypeOf<typeof PayMethodsPostResponse>;
export const PayMethodsPostInput = routeInfo['POST /v1/pay/methods']['inputCodec'];
export type PayMethodsPostInput = t.TypeOf<typeof PayMethodsPostInput>;
export const PayMethodsPaymentMethodIdDeleteResponse = routeInfo['DELETE /v1/pay/methods/{paymentMethodId}']['responseCodec'];
export type PayMethodsPaymentMethodIdDeleteResponse = t.TypeOf<typeof PayMethodsPaymentMethodIdDeleteResponse>;
export const PayMethodsPaymentMethodIdDeleteInput = routeInfo['DELETE /v1/pay/methods/{paymentMethodId}']['inputCodec'];
export type PayMethodsPaymentMethodIdDeleteInput = t.TypeOf<typeof PayMethodsPaymentMethodIdDeleteInput>;
export const PayMethodsPaymentMethodIdDefaultPostResponse = routeInfo['POST /v1/pay/methods/{paymentMethodId}/default']['responseCodec'];
export type PayMethodsPaymentMethodIdDefaultPostResponse = t.TypeOf<typeof PayMethodsPaymentMethodIdDefaultPostResponse>;
export const PayMethodsPaymentMethodIdDefaultPostInput = routeInfo['POST /v1/pay/methods/{paymentMethodId}/default']['inputCodec'];
export type PayMethodsPaymentMethodIdDefaultPostInput = t.TypeOf<typeof PayMethodsPaymentMethodIdDefaultPostInput>;
export const PayRefundPostResponse = routeInfo['POST /v1/pay/refund']['responseCodec'];
export type PayRefundPostResponse = t.TypeOf<typeof PayRefundPostResponse>;
export const PayRefundPostInput = routeInfo['POST /v1/pay/refund']['inputCodec'];
export type PayRefundPostInput = t.TypeOf<typeof PayRefundPostInput>;
export const UnauthHomepageGetResponse = routeInfo['GET /v1/unauthHomepage']['responseCodec'];
export type UnauthHomepageGetResponse = t.TypeOf<typeof UnauthHomepageGetResponse>;
export const UnauthHomepageGetInput = routeInfo['GET /v1/unauthHomepage']['inputCodec'];
export type UnauthHomepageGetInput = t.TypeOf<typeof UnauthHomepageGetInput>;
export const BuyerDashboardGetResponse = routeInfo['GET /v1/buyer/dashboard']['responseCodec'];
export type BuyerDashboardGetResponse = t.TypeOf<typeof BuyerDashboardGetResponse>;
export const BuyerDashboardGetInput = routeInfo['GET /v1/buyer/dashboard']['inputCodec'];
export type BuyerDashboardGetInput = t.TypeOf<typeof BuyerDashboardGetInput>;
export const BuyerMarketplaceGetResponse = routeInfo['GET /v1/buyer/marketplace']['responseCodec'];
export type BuyerMarketplaceGetResponse = t.TypeOf<typeof BuyerMarketplaceGetResponse>;
export const BuyerMarketplaceGetInput = routeInfo['GET /v1/buyer/marketplace']['inputCodec'];
export type BuyerMarketplaceGetInput = t.TypeOf<typeof BuyerMarketplaceGetInput>;
export const BuyerPostCheckoutGetResponse = routeInfo['GET /v1/buyer/postCheckout']['responseCodec'];
export type BuyerPostCheckoutGetResponse = t.TypeOf<typeof BuyerPostCheckoutGetResponse>;
export const BuyerPostCheckoutGetInput = routeInfo['GET /v1/buyer/postCheckout']['inputCodec'];
export type BuyerPostCheckoutGetInput = t.TypeOf<typeof BuyerPostCheckoutGetInput>;
export const BuyerPartnerApprovedSellersGetResponse = routeInfo['GET /v1/buyer/partnerApprovedSellers']['responseCodec'];
export type BuyerPartnerApprovedSellersGetResponse = t.TypeOf<typeof BuyerPartnerApprovedSellersGetResponse>;
export const BuyerPartnerApprovedSellersGetInput = routeInfo['GET /v1/buyer/partnerApprovedSellers']['inputCodec'];
export type BuyerPartnerApprovedSellersGetInput = t.TypeOf<typeof BuyerPartnerApprovedSellersGetInput>;
export const BuyerReorderGetResponse = routeInfo['GET /v1/buyer/reorder']['responseCodec'];
export type BuyerReorderGetResponse = t.TypeOf<typeof BuyerReorderGetResponse>;
export const BuyerReorderGetInput = routeInfo['GET /v1/buyer/reorder']['inputCodec'];
export type BuyerReorderGetInput = t.TypeOf<typeof BuyerReorderGetInput>;
export const BuyerReorderProductVariantsPatchResponse = routeInfo['PATCH /v1/buyer/reorder/productVariants']['responseCodec'];
export type BuyerReorderProductVariantsPatchResponse = t.TypeOf<typeof BuyerReorderProductVariantsPatchResponse>;
export const BuyerReorderProductVariantsPatchInput = routeInfo['PATCH /v1/buyer/reorder/productVariants']['inputCodec'];
export type BuyerReorderProductVariantsPatchInput = t.TypeOf<typeof BuyerReorderProductVariantsPatchInput>;
export const BuyerLocationsGetResponse = routeInfo['GET /v1/buyerLocations']['responseCodec'];
export type BuyerLocationsGetResponse = t.TypeOf<typeof BuyerLocationsGetResponse>;
export const BuyerLocationsGetInput = routeInfo['GET /v1/buyerLocations']['inputCodec'];
export type BuyerLocationsGetInput = t.TypeOf<typeof BuyerLocationsGetInput>;
export const BuyerLocationsBuyerLocationIdAddressesAddressIdDeleteResponse = routeInfo['DELETE /v1/buyerLocations/{buyerLocationId}/addresses/{addressId}']['responseCodec'];
export type BuyerLocationsBuyerLocationIdAddressesAddressIdDeleteResponse = t.TypeOf<typeof BuyerLocationsBuyerLocationIdAddressesAddressIdDeleteResponse>;
export const BuyerLocationsBuyerLocationIdAddressesAddressIdDeleteInput = routeInfo['DELETE /v1/buyerLocations/{buyerLocationId}/addresses/{addressId}']['inputCodec'];
export type BuyerLocationsBuyerLocationIdAddressesAddressIdDeleteInput = t.TypeOf<typeof BuyerLocationsBuyerLocationIdAddressesAddressIdDeleteInput>;
export const GeolocateGetResponse = routeInfo['GET /v1/geolocate']['responseCodec'];
export type GeolocateGetResponse = t.TypeOf<typeof GeolocateGetResponse>;
export const GeolocateGetInput = routeInfo['GET /v1/geolocate']['inputCodec'];
export type GeolocateGetInput = t.TypeOf<typeof GeolocateGetInput>;
export const AdminOmnisearchGetResponse = routeInfo['GET /v1/admin/omnisearch']['responseCodec'];
export type AdminOmnisearchGetResponse = t.TypeOf<typeof AdminOmnisearchGetResponse>;
export const AdminOmnisearchGetInput = routeInfo['GET /v1/admin/omnisearch']['inputCodec'];
export type AdminOmnisearchGetInput = t.TypeOf<typeof AdminOmnisearchGetInput>;
export const AdminMessagingModerationGetResponse = routeInfo['GET /v1/admin/messagingModeration']['responseCodec'];
export type AdminMessagingModerationGetResponse = t.TypeOf<typeof AdminMessagingModerationGetResponse>;
export const AdminMessagingModerationGetInput = routeInfo['GET /v1/admin/messagingModeration']['inputCodec'];
export type AdminMessagingModerationGetInput = t.TypeOf<typeof AdminMessagingModerationGetInput>;
export const AdminMessagingModerationPostResponse = routeInfo['POST /v1/admin/messagingModeration']['responseCodec'];
export type AdminMessagingModerationPostResponse = t.TypeOf<typeof AdminMessagingModerationPostResponse>;
export const AdminMessagingModerationPostInput = routeInfo['POST /v1/admin/messagingModeration']['inputCodec'];
export type AdminMessagingModerationPostInput = t.TypeOf<typeof AdminMessagingModerationPostInput>;
export const AdminMessagingModerationModerationListIdGetResponse = routeInfo['GET /v1/admin/messagingModeration/{moderationListId}']['responseCodec'];
export type AdminMessagingModerationModerationListIdGetResponse = t.TypeOf<typeof AdminMessagingModerationModerationListIdGetResponse>;
export const AdminMessagingModerationModerationListIdGetInput = routeInfo['GET /v1/admin/messagingModeration/{moderationListId}']['inputCodec'];
export type AdminMessagingModerationModerationListIdGetInput = t.TypeOf<typeof AdminMessagingModerationModerationListIdGetInput>;
export const AdminMessagingModerationModerationListIdPutResponse = routeInfo['PUT /v1/admin/messagingModeration/{moderationListId}']['responseCodec'];
export type AdminMessagingModerationModerationListIdPutResponse = t.TypeOf<typeof AdminMessagingModerationModerationListIdPutResponse>;
export const AdminMessagingModerationModerationListIdPutInput = routeInfo['PUT /v1/admin/messagingModeration/{moderationListId}']['inputCodec'];
export type AdminMessagingModerationModerationListIdPutInput = t.TypeOf<typeof AdminMessagingModerationModerationListIdPutInput>;
export const AdminSearchSyncPostResponse = routeInfo['POST /v1/admin/search/sync']['responseCodec'];
export type AdminSearchSyncPostResponse = t.TypeOf<typeof AdminSearchSyncPostResponse>;
export const AdminSearchSyncPostInput = routeInfo['POST /v1/admin/search/sync']['inputCodec'];
export type AdminSearchSyncPostInput = t.TypeOf<typeof AdminSearchSyncPostInput>;
export const AdminCreateHubspotPropertiesPostResponse = routeInfo['POST /v1/admin/createHubspotProperties']['responseCodec'];
export type AdminCreateHubspotPropertiesPostResponse = t.TypeOf<typeof AdminCreateHubspotPropertiesPostResponse>;
export const AdminCreateHubspotPropertiesPostInput = routeInfo['POST /v1/admin/createHubspotProperties']['inputCodec'];
export type AdminCreateHubspotPropertiesPostInput = t.TypeOf<typeof AdminCreateHubspotPropertiesPostInput>;
export const CustomersGetResponse = routeInfo['GET /v1/customers']['responseCodec'];
export type CustomersGetResponse = t.TypeOf<typeof CustomersGetResponse>;
export const CustomersGetInput = routeInfo['GET /v1/customers']['inputCodec'];
export type CustomersGetInput = t.TypeOf<typeof CustomersGetInput>;
export const CustomersBuyerIdGetResponse = routeInfo['GET /v1/customers/{buyerId}']['responseCodec'];
export type CustomersBuyerIdGetResponse = t.TypeOf<typeof CustomersBuyerIdGetResponse>;
export const CustomersBuyerIdGetInput = routeInfo['GET /v1/customers/{buyerId}']['inputCodec'];
export type CustomersBuyerIdGetInput = t.TypeOf<typeof CustomersBuyerIdGetInput>;
export const CustomersBuyerIdPatchResponse = routeInfo['PATCH /v1/customers/{buyerId}']['responseCodec'];
export type CustomersBuyerIdPatchResponse = t.TypeOf<typeof CustomersBuyerIdPatchResponse>;
export const CustomersBuyerIdPatchInput = routeInfo['PATCH /v1/customers/{buyerId}']['inputCodec'];
export type CustomersBuyerIdPatchInput = t.TypeOf<typeof CustomersBuyerIdPatchInput>;
export const AdminSellersSellerIdGoogleSheetGetResponse = routeInfo['GET /v1/admin/sellers/{sellerId}/googleSheet']['responseCodec'];
export type AdminSellersSellerIdGoogleSheetGetResponse = t.TypeOf<typeof AdminSellersSellerIdGoogleSheetGetResponse>;
export const AdminSellersSellerIdGoogleSheetGetInput = routeInfo['GET /v1/admin/sellers/{sellerId}/googleSheet']['inputCodec'];
export type AdminSellersSellerIdGoogleSheetGetInput = t.TypeOf<typeof AdminSellersSellerIdGoogleSheetGetInput>;
export const AdminSellersSellerIdGoogleSheetPostResponse = routeInfo['POST /v1/admin/sellers/{sellerId}/googleSheet']['responseCodec'];
export type AdminSellersSellerIdGoogleSheetPostResponse = t.TypeOf<typeof AdminSellersSellerIdGoogleSheetPostResponse>;
export const AdminSellersSellerIdGoogleSheetPostInput = routeInfo['POST /v1/admin/sellers/{sellerId}/googleSheet']['inputCodec'];
export type AdminSellersSellerIdGoogleSheetPostInput = t.TypeOf<typeof AdminSellersSellerIdGoogleSheetPostInput>;
export const AdminSellersSellerIdExportHubspotSheetPostResponse = routeInfo['POST /v1/admin/sellers/{sellerId}/exportHubspotSheet']['responseCodec'];
export type AdminSellersSellerIdExportHubspotSheetPostResponse = t.TypeOf<typeof AdminSellersSellerIdExportHubspotSheetPostResponse>;
export const AdminSellersSellerIdExportHubspotSheetPostInput = routeInfo['POST /v1/admin/sellers/{sellerId}/exportHubspotSheet']['inputCodec'];
export type AdminSellersSellerIdExportHubspotSheetPostInput = t.TypeOf<typeof AdminSellersSellerIdExportHubspotSheetPostInput>;
export const AdminAccountsAccountIdAuditLogPostResponse = routeInfo['POST /v1/admin/accounts/{accountId}/auditLog']['responseCodec'];
export type AdminAccountsAccountIdAuditLogPostResponse = t.TypeOf<typeof AdminAccountsAccountIdAuditLogPostResponse>;
export const AdminAccountsAccountIdAuditLogPostInput = routeInfo['POST /v1/admin/accounts/{accountId}/auditLog']['inputCodec'];
export type AdminAccountsAccountIdAuditLogPostInput = t.TypeOf<typeof AdminAccountsAccountIdAuditLogPostInput>;
export const AdminAccountsAccountIdWriteOffPostResponse = routeInfo['POST /v1/admin/accounts/{accountId}/writeOff']['responseCodec'];
export type AdminAccountsAccountIdWriteOffPostResponse = t.TypeOf<typeof AdminAccountsAccountIdWriteOffPostResponse>;
export const AdminAccountsAccountIdWriteOffPostInput = routeInfo['POST /v1/admin/accounts/{accountId}/writeOff']['inputCodec'];
export type AdminAccountsAccountIdWriteOffPostInput = t.TypeOf<typeof AdminAccountsAccountIdWriteOffPostInput>;
export const AdminSellersSellerIdAuditLogGetResponse = routeInfo['GET /v1/admin/sellers/{sellerId}/auditLog']['responseCodec'];
export type AdminSellersSellerIdAuditLogGetResponse = t.TypeOf<typeof AdminSellersSellerIdAuditLogGetResponse>;
export const AdminSellersSellerIdAuditLogGetInput = routeInfo['GET /v1/admin/sellers/{sellerId}/auditLog']['inputCodec'];
export type AdminSellersSellerIdAuditLogGetInput = t.TypeOf<typeof AdminSellersSellerIdAuditLogGetInput>;
export const AdminSellersSellerIdSlugsGetResponse = routeInfo['GET /v1/admin/sellers/{sellerId}/slugs']['responseCodec'];
export type AdminSellersSellerIdSlugsGetResponse = t.TypeOf<typeof AdminSellersSellerIdSlugsGetResponse>;
export const AdminSellersSellerIdSlugsGetInput = routeInfo['GET /v1/admin/sellers/{sellerId}/slugs']['inputCodec'];
export type AdminSellersSellerIdSlugsGetInput = t.TypeOf<typeof AdminSellersSellerIdSlugsGetInput>;
export const AdminVariantsVariantIdMableSkusGetResponse = routeInfo['GET /v1/admin/variants/{variantId}/mableSkus']['responseCodec'];
export type AdminVariantsVariantIdMableSkusGetResponse = t.TypeOf<typeof AdminVariantsVariantIdMableSkusGetResponse>;
export const AdminVariantsVariantIdMableSkusGetInput = routeInfo['GET /v1/admin/variants/{variantId}/mableSkus']['inputCodec'];
export type AdminVariantsVariantIdMableSkusGetInput = t.TypeOf<typeof AdminVariantsVariantIdMableSkusGetInput>;
export const AdminOneWorldSyncSyncRetailUnitsPostResponse = routeInfo['POST /v1/admin/oneWorldSync/syncRetailUnits']['responseCodec'];
export type AdminOneWorldSyncSyncRetailUnitsPostResponse = t.TypeOf<typeof AdminOneWorldSyncSyncRetailUnitsPostResponse>;
export const AdminOneWorldSyncSyncRetailUnitsPostInput = routeInfo['POST /v1/admin/oneWorldSync/syncRetailUnits']['inputCodec'];
export type AdminOneWorldSyncSyncRetailUnitsPostInput = t.TypeOf<typeof AdminOneWorldSyncSyncRetailUnitsPostInput>;
export const AdminOneWorldSyncPublishRetailUnitsPostResponse = routeInfo['POST /v1/admin/oneWorldSync/publishRetailUnits']['responseCodec'];
export type AdminOneWorldSyncPublishRetailUnitsPostResponse = t.TypeOf<typeof AdminOneWorldSyncPublishRetailUnitsPostResponse>;
export const AdminOneWorldSyncPublishRetailUnitsPostInput = routeInfo['POST /v1/admin/oneWorldSync/publishRetailUnits']['inputCodec'];
export type AdminOneWorldSyncPublishRetailUnitsPostInput = t.TypeOf<typeof AdminOneWorldSyncPublishRetailUnitsPostInput>;
export const AdminOneWorldSyncStatusRetailUnitIdGetResponse = routeInfo['GET /v1/admin/oneWorldSync/status/{retailUnitId}']['responseCodec'];
export type AdminOneWorldSyncStatusRetailUnitIdGetResponse = t.TypeOf<typeof AdminOneWorldSyncStatusRetailUnitIdGetResponse>;
export const AdminOneWorldSyncStatusRetailUnitIdGetInput = routeInfo['GET /v1/admin/oneWorldSync/status/{retailUnitId}']['inputCodec'];
export type AdminOneWorldSyncStatusRetailUnitIdGetInput = t.TypeOf<typeof AdminOneWorldSyncStatusRetailUnitIdGetInput>;
export const AdminOneWorldSyncGetProductIntroductionStatusPostResponse = routeInfo['POST /v1/admin/oneWorldSync/getProductIntroductionStatus']['responseCodec'];
export type AdminOneWorldSyncGetProductIntroductionStatusPostResponse = t.TypeOf<typeof AdminOneWorldSyncGetProductIntroductionStatusPostResponse>;
export const AdminOneWorldSyncGetProductIntroductionStatusPostInput = routeInfo['POST /v1/admin/oneWorldSync/getProductIntroductionStatus']['inputCodec'];
export type AdminOneWorldSyncGetProductIntroductionStatusPostInput = t.TypeOf<typeof AdminOneWorldSyncGetProductIntroductionStatusPostInput>;
export const AdminOneWorldSyncUnpublishRetailUnitsPostResponse = routeInfo['POST /v1/admin/oneWorldSync/unpublishRetailUnits']['responseCodec'];
export type AdminOneWorldSyncUnpublishRetailUnitsPostResponse = t.TypeOf<typeof AdminOneWorldSyncUnpublishRetailUnitsPostResponse>;
export const AdminOneWorldSyncUnpublishRetailUnitsPostInput = routeInfo['POST /v1/admin/oneWorldSync/unpublishRetailUnits']['inputCodec'];
export type AdminOneWorldSyncUnpublishRetailUnitsPostInput = t.TypeOf<typeof AdminOneWorldSyncUnpublishRetailUnitsPostInput>;
export const QuickbooksAuthorizationUrlGetResponse = routeInfo['GET /v1/quickbooks/authorizationUrl']['responseCodec'];
export type QuickbooksAuthorizationUrlGetResponse = t.TypeOf<typeof QuickbooksAuthorizationUrlGetResponse>;
export const QuickbooksAuthorizationUrlGetInput = routeInfo['GET /v1/quickbooks/authorizationUrl']['inputCodec'];
export type QuickbooksAuthorizationUrlGetInput = t.TypeOf<typeof QuickbooksAuthorizationUrlGetInput>;
export const QuickbooksAuthorizePostResponse = routeInfo['POST /v1/quickbooks/authorize']['responseCodec'];
export type QuickbooksAuthorizePostResponse = t.TypeOf<typeof QuickbooksAuthorizePostResponse>;
export const QuickbooksAuthorizePostInput = routeInfo['POST /v1/quickbooks/authorize']['inputCodec'];
export type QuickbooksAuthorizePostInput = t.TypeOf<typeof QuickbooksAuthorizePostInput>;
export const AdminQuickbooksBillsPostResponse = routeInfo['POST /v1/admin/quickbooks/bills']['responseCodec'];
export type AdminQuickbooksBillsPostResponse = t.TypeOf<typeof AdminQuickbooksBillsPostResponse>;
export const AdminQuickbooksBillsPostInput = routeInfo['POST /v1/admin/quickbooks/bills']['inputCodec'];
export type AdminQuickbooksBillsPostInput = t.TypeOf<typeof AdminQuickbooksBillsPostInput>;
export const AdminSellersSellerIdGoogleSheetImportPutResponse = routeInfo['PUT /v1/admin/sellers/{sellerId}/googleSheetImport']['responseCodec'];
export type AdminSellersSellerIdGoogleSheetImportPutResponse = t.TypeOf<typeof AdminSellersSellerIdGoogleSheetImportPutResponse>;
export const AdminSellersSellerIdGoogleSheetImportPutInput = routeInfo['PUT /v1/admin/sellers/{sellerId}/googleSheetImport']['inputCodec'];
export type AdminSellersSellerIdGoogleSheetImportPutInput = t.TypeOf<typeof AdminSellersSellerIdGoogleSheetImportPutInput>;
export const AlertsGetResponse = routeInfo['GET /v1/alerts']['responseCodec'];
export type AlertsGetResponse = t.TypeOf<typeof AlertsGetResponse>;
export const AlertsGetInput = routeInfo['GET /v1/alerts']['inputCodec'];
export type AlertsGetInput = t.TypeOf<typeof AlertsGetInput>;
export const AllergensGetResponse = routeInfo['GET /v1/allergens']['responseCodec'];
export type AllergensGetResponse = t.TypeOf<typeof AllergensGetResponse>;
export const AllergensGetInput = routeInfo['GET /v1/allergens']['inputCodec'];
export type AllergensGetInput = t.TypeOf<typeof AllergensGetInput>;
export const AdminSellersCalculateReputationScoresPostResponse = routeInfo['POST /v1/admin/sellers/calculateReputationScores']['responseCodec'];
export type AdminSellersCalculateReputationScoresPostResponse = t.TypeOf<typeof AdminSellersCalculateReputationScoresPostResponse>;
export const AdminSellersCalculateReputationScoresPostInput = routeInfo['POST /v1/admin/sellers/calculateReputationScores']['inputCodec'];
export type AdminSellersCalculateReputationScoresPostInput = t.TypeOf<typeof AdminSellersCalculateReputationScoresPostInput>;
export const AdminSellersShippingProgramImportPutResponse = routeInfo['PUT /v1/admin/sellers/shippingProgramImport']['responseCodec'];
export type AdminSellersShippingProgramImportPutResponse = t.TypeOf<typeof AdminSellersShippingProgramImportPutResponse>;
export const AdminSellersShippingProgramImportPutInput = routeInfo['PUT /v1/admin/sellers/shippingProgramImport']['inputCodec'];
export type AdminSellersShippingProgramImportPutInput = t.TypeOf<typeof AdminSellersShippingProgramImportPutInput>;
export const BuyerNavigationGetResponse = routeInfo['GET /v1/buyer/navigation']['responseCodec'];
export type BuyerNavigationGetResponse = t.TypeOf<typeof BuyerNavigationGetResponse>;
export const BuyerNavigationGetInput = routeInfo['GET /v1/buyer/navigation']['inputCodec'];
export type BuyerNavigationGetInput = t.TypeOf<typeof BuyerNavigationGetInput>;
export const BuyerReferralCodeReferralCodeGetResponse = routeInfo['GET /v1/buyer/referralCode/{referralCode}']['responseCodec'];
export type BuyerReferralCodeReferralCodeGetResponse = t.TypeOf<typeof BuyerReferralCodeReferralCodeGetResponse>;
export const BuyerReferralCodeReferralCodeGetInput = routeInfo['GET /v1/buyer/referralCode/{referralCode}']['inputCodec'];
export type BuyerReferralCodeReferralCodeGetInput = t.TypeOf<typeof BuyerReferralCodeReferralCodeGetInput>;
export const BuyersBuyerIdOrMePatchResponse = routeInfo['PATCH /v1/buyers/{buyerIdOrMe}']['responseCodec'];
export type BuyersBuyerIdOrMePatchResponse = t.TypeOf<typeof BuyersBuyerIdOrMePatchResponse>;
export const BuyersBuyerIdOrMePatchInput = routeInfo['PATCH /v1/buyers/{buyerIdOrMe}']['inputCodec'];
export type BuyersBuyerIdOrMePatchInput = t.TypeOf<typeof BuyersBuyerIdOrMePatchInput>;
export const BuyersMePricingRegionsGetResponse = routeInfo['GET /v1/buyers/@me/pricingRegions']['responseCodec'];
export type BuyersMePricingRegionsGetResponse = t.TypeOf<typeof BuyersMePricingRegionsGetResponse>;
export const BuyersMePricingRegionsGetInput = routeInfo['GET /v1/buyers/@me/pricingRegions']['inputCodec'];
export type BuyersMePricingRegionsGetInput = t.TypeOf<typeof BuyersMePricingRegionsGetInput>;
export const BuyersBuyerIdOrMeCertificateSignedUrlPostResponse = routeInfo['POST /v1/buyers/{buyerIdOrMe}/certificateSignedUrl']['responseCodec'];
export type BuyersBuyerIdOrMeCertificateSignedUrlPostResponse = t.TypeOf<typeof BuyersBuyerIdOrMeCertificateSignedUrlPostResponse>;
export const BuyersBuyerIdOrMeCertificateSignedUrlPostInput = routeInfo['POST /v1/buyers/{buyerIdOrMe}/certificateSignedUrl']['inputCodec'];
export type BuyersBuyerIdOrMeCertificateSignedUrlPostInput = t.TypeOf<typeof BuyersBuyerIdOrMeCertificateSignedUrlPostInput>;
export const BuyersBuyerIdOrMeShippingSavingsGetResponse = routeInfo['GET /v1/buyers/{buyerIdOrMe}/shippingSavings']['responseCodec'];
export type BuyersBuyerIdOrMeShippingSavingsGetResponse = t.TypeOf<typeof BuyersBuyerIdOrMeShippingSavingsGetResponse>;
export const BuyersBuyerIdOrMeShippingSavingsGetInput = routeInfo['GET /v1/buyers/{buyerIdOrMe}/shippingSavings']['inputCodec'];
export type BuyersBuyerIdOrMeShippingSavingsGetInput = t.TypeOf<typeof BuyersBuyerIdOrMeShippingSavingsGetInput>;
export const BuyersMePartnerPartnerNameRegionalPricingPostResponse = routeInfo['POST /v1/buyers/@me/partner/{partnerName}/regionalPricing']['responseCodec'];
export type BuyersMePartnerPartnerNameRegionalPricingPostResponse = t.TypeOf<typeof BuyersMePartnerPartnerNameRegionalPricingPostResponse>;
export const BuyersMePartnerPartnerNameRegionalPricingPostInput = routeInfo['POST /v1/buyers/@me/partner/{partnerName}/regionalPricing']['inputCodec'];
export type BuyersMePartnerPartnerNameRegionalPricingPostInput = t.TypeOf<typeof BuyersMePartnerPartnerNameRegionalPricingPostInput>;
export const DismissShippingSavingsNotitificationPostResponse = routeInfo['POST /v1/dismissShippingSavingsNotitification']['responseCodec'];
export type DismissShippingSavingsNotitificationPostResponse = t.TypeOf<typeof DismissShippingSavingsNotitificationPostResponse>;
export const DismissShippingSavingsNotitificationPostInput = routeInfo['POST /v1/dismissShippingSavingsNotitification']['inputCodec'];
export type DismissShippingSavingsNotitificationPostInput = t.TypeOf<typeof DismissShippingSavingsNotitificationPostInput>;
export const UsersUserIdOrMePatchResponse = routeInfo['PATCH /v1/users/{userIdOrMe}']['responseCodec'];
export type UsersUserIdOrMePatchResponse = t.TypeOf<typeof UsersUserIdOrMePatchResponse>;
export const UsersUserIdOrMePatchInput = routeInfo['PATCH /v1/users/{userIdOrMe}']['inputCodec'];
export type UsersUserIdOrMePatchInput = t.TypeOf<typeof UsersUserIdOrMePatchInput>;
export const UsersUserIdOrMeListIdentifierGetResponse = routeInfo['GET /v1/users/{userIdOrMe}/list/{identifier}']['responseCodec'];
export type UsersUserIdOrMeListIdentifierGetResponse = t.TypeOf<typeof UsersUserIdOrMeListIdentifierGetResponse>;
export const UsersUserIdOrMeListIdentifierGetInput = routeInfo['GET /v1/users/{userIdOrMe}/list/{identifier}']['inputCodec'];
export type UsersUserIdOrMeListIdentifierGetInput = t.TypeOf<typeof UsersUserIdOrMeListIdentifierGetInput>;
export const UsersUserIdOrMeListIdentifierPatchResponse = routeInfo['PATCH /v1/users/{userIdOrMe}/list/{identifier}']['responseCodec'];
export type UsersUserIdOrMeListIdentifierPatchResponse = t.TypeOf<typeof UsersUserIdOrMeListIdentifierPatchResponse>;
export const UsersUserIdOrMeListIdentifierPatchInput = routeInfo['PATCH /v1/users/{userIdOrMe}/list/{identifier}']['inputCodec'];
export type UsersUserIdOrMeListIdentifierPatchInput = t.TypeOf<typeof UsersUserIdOrMeListIdentifierPatchInput>;
export const AdminReportsGetResponse = routeInfo['GET /v1/admin/reports']['responseCodec'];
export type AdminReportsGetResponse = t.TypeOf<typeof AdminReportsGetResponse>;
export const AdminReportsGetInput = routeInfo['GET /v1/admin/reports']['inputCodec'];
export type AdminReportsGetInput = t.TypeOf<typeof AdminReportsGetInput>;
export const AdminReportsReportIdPostResponse = routeInfo['POST /v1/admin/reports/{reportId}']['responseCodec'];
export type AdminReportsReportIdPostResponse = t.TypeOf<typeof AdminReportsReportIdPostResponse>;
export const AdminReportsReportIdPostInput = routeInfo['POST /v1/admin/reports/{reportId}']['inputCodec'];
export type AdminReportsReportIdPostInput = t.TypeOf<typeof AdminReportsReportIdPostInput>;
export const SellersMeEmailCampaignsPostResponse = routeInfo['POST /v1/sellers/@me/emailCampaigns']['responseCodec'];
export type SellersMeEmailCampaignsPostResponse = t.TypeOf<typeof SellersMeEmailCampaignsPostResponse>;
export const SellersMeEmailCampaignsPostInput = routeInfo['POST /v1/sellers/@me/emailCampaigns']['inputCodec'];
export type SellersMeEmailCampaignsPostInput = t.TypeOf<typeof SellersMeEmailCampaignsPostInput>;
export const SellersMeEmailCampaignsDashboardGetResponse = routeInfo['GET /v1/sellers/@me/emailCampaignsDashboard']['responseCodec'];
export type SellersMeEmailCampaignsDashboardGetResponse = t.TypeOf<typeof SellersMeEmailCampaignsDashboardGetResponse>;
export const SellersMeEmailCampaignsDashboardGetInput = routeInfo['GET /v1/sellers/@me/emailCampaignsDashboard']['inputCodec'];
export type SellersMeEmailCampaignsDashboardGetInput = t.TypeOf<typeof SellersMeEmailCampaignsDashboardGetInput>;
export const SellersMeShopifyStatusGetResponse = routeInfo['GET /v1/sellers/@me/shopify/status']['responseCodec'];
export type SellersMeShopifyStatusGetResponse = t.TypeOf<typeof SellersMeShopifyStatusGetResponse>;
export const SellersMeShopifyStatusGetInput = routeInfo['GET /v1/sellers/@me/shopify/status']['inputCodec'];
export type SellersMeShopifyStatusGetInput = t.TypeOf<typeof SellersMeShopifyStatusGetInput>;
export const SellersMeShopifyStatusPostResponse = routeInfo['POST /v1/sellers/@me/shopify/status']['responseCodec'];
export type SellersMeShopifyStatusPostResponse = t.TypeOf<typeof SellersMeShopifyStatusPostResponse>;
export const SellersMeShopifyStatusPostInput = routeInfo['POST /v1/sellers/@me/shopify/status']['inputCodec'];
export type SellersMeShopifyStatusPostInput = t.TypeOf<typeof SellersMeShopifyStatusPostInput>;
export const SellersMeShopifyAuthPostResponse = routeInfo['POST /v1/sellers/@me/shopify/auth']['responseCodec'];
export type SellersMeShopifyAuthPostResponse = t.TypeOf<typeof SellersMeShopifyAuthPostResponse>;
export const SellersMeShopifyAuthPostInput = routeInfo['POST /v1/sellers/@me/shopify/auth']['inputCodec'];
export type SellersMeShopifyAuthPostInput = t.TypeOf<typeof SellersMeShopifyAuthPostInput>;
export const SellersMeShopifyAuthDeleteResponse = routeInfo['DELETE /v1/sellers/@me/shopify/auth']['responseCodec'];
export type SellersMeShopifyAuthDeleteResponse = t.TypeOf<typeof SellersMeShopifyAuthDeleteResponse>;
export const SellersMeShopifyAuthDeleteInput = routeInfo['DELETE /v1/sellers/@me/shopify/auth']['inputCodec'];
export type SellersMeShopifyAuthDeleteInput = t.TypeOf<typeof SellersMeShopifyAuthDeleteInput>;
export const SellersMeShopifyFinishPostResponse = routeInfo['POST /v1/sellers/@me/shopify/finish']['responseCodec'];
export type SellersMeShopifyFinishPostResponse = t.TypeOf<typeof SellersMeShopifyFinishPostResponse>;
export const SellersMeShopifyFinishPostInput = routeInfo['POST /v1/sellers/@me/shopify/finish']['inputCodec'];
export type SellersMeShopifyFinishPostInput = t.TypeOf<typeof SellersMeShopifyFinishPostInput>;
export const SellersMeShopifyTestOrderPostResponse = routeInfo['POST /v1/sellers/@me/shopify/testOrder']['responseCodec'];
export type SellersMeShopifyTestOrderPostResponse = t.TypeOf<typeof SellersMeShopifyTestOrderPostResponse>;
export const SellersMeShopifyTestOrderPostInput = routeInfo['POST /v1/sellers/@me/shopify/testOrder']['inputCodec'];
export type SellersMeShopifyTestOrderPostInput = t.TypeOf<typeof SellersMeShopifyTestOrderPostInput>;
export const SellersMeShipFromAddressGetResponse = routeInfo['GET /v1/sellers/@me/shipFromAddress']['responseCodec'];
export type SellersMeShipFromAddressGetResponse = t.TypeOf<typeof SellersMeShipFromAddressGetResponse>;
export const SellersMeShipFromAddressGetInput = routeInfo['GET /v1/sellers/@me/shipFromAddress']['inputCodec'];
export type SellersMeShipFromAddressGetInput = t.TypeOf<typeof SellersMeShipFromAddressGetInput>;
export const AdminSellerIdPriceReplacementRulesDeleteResponse = routeInfo['DELETE /v1/admin/{sellerId}/priceReplacementRules']['responseCodec'];
export type AdminSellerIdPriceReplacementRulesDeleteResponse = t.TypeOf<typeof AdminSellerIdPriceReplacementRulesDeleteResponse>;
export const AdminSellerIdPriceReplacementRulesDeleteInput = routeInfo['DELETE /v1/admin/{sellerId}/priceReplacementRules']['inputCodec'];
export type AdminSellerIdPriceReplacementRulesDeleteInput = t.TypeOf<typeof AdminSellerIdPriceReplacementRulesDeleteInput>;
export const AdminSellerIdPriceReplacementRulesPostResponse = routeInfo['POST /v1/admin/{sellerId}/priceReplacementRules']['responseCodec'];
export type AdminSellerIdPriceReplacementRulesPostResponse = t.TypeOf<typeof AdminSellerIdPriceReplacementRulesPostResponse>;
export const AdminSellerIdPriceReplacementRulesPostInput = routeInfo['POST /v1/admin/{sellerId}/priceReplacementRules']['inputCodec'];
export type AdminSellerIdPriceReplacementRulesPostInput = t.TypeOf<typeof AdminSellerIdPriceReplacementRulesPostInput>;
export const AdminSellersSellerIdProductPackagingCsvGetResponse = routeInfo['GET /v1/admin/sellers/{sellerId}/productPackagingCSV']['responseCodec'];
export type AdminSellersSellerIdProductPackagingCsvGetResponse = t.TypeOf<typeof AdminSellersSellerIdProductPackagingCsvGetResponse>;
export const AdminSellersSellerIdProductPackagingCsvGetInput = routeInfo['GET /v1/admin/sellers/{sellerId}/productPackagingCSV']['inputCodec'];
export type AdminSellersSellerIdProductPackagingCsvGetInput = t.TypeOf<typeof AdminSellersSellerIdProductPackagingCsvGetInput>;
export const AdminSellersSellerIdProductPackagingCsvPostResponse = routeInfo['POST /v1/admin/sellers/{sellerId}/productPackagingCSV']['responseCodec'];
export type AdminSellersSellerIdProductPackagingCsvPostResponse = t.TypeOf<typeof AdminSellersSellerIdProductPackagingCsvPostResponse>;
export const AdminSellersSellerIdProductPackagingCsvPostInput = routeInfo['POST /v1/admin/sellers/{sellerId}/productPackagingCSV']['inputCodec'];
export type AdminSellersSellerIdProductPackagingCsvPostInput = t.TypeOf<typeof AdminSellersSellerIdProductPackagingCsvPostInput>;
export const OrdersOrderIdShopifySyncPostResponse = routeInfo['POST /v1/orders/{orderId}/shopifySync']['responseCodec'];
export type OrdersOrderIdShopifySyncPostResponse = t.TypeOf<typeof OrdersOrderIdShopifySyncPostResponse>;
export const OrdersOrderIdShopifySyncPostInput = routeInfo['POST /v1/orders/{orderId}/shopifySync']['inputCodec'];
export type OrdersOrderIdShopifySyncPostInput = t.TypeOf<typeof OrdersOrderIdShopifySyncPostInput>;
export const SellersSellerIdOrMeShopifyProductMatchGetResponse = routeInfo['GET /v1/sellers/{sellerIdOrMe}/shopify/productMatch']['responseCodec'];
export type SellersSellerIdOrMeShopifyProductMatchGetResponse = t.TypeOf<typeof SellersSellerIdOrMeShopifyProductMatchGetResponse>;
export const SellersSellerIdOrMeShopifyProductMatchGetInput = routeInfo['GET /v1/sellers/{sellerIdOrMe}/shopify/productMatch']['inputCodec'];
export type SellersSellerIdOrMeShopifyProductMatchGetInput = t.TypeOf<typeof SellersSellerIdOrMeShopifyProductMatchGetInput>;
export const SellersSellerIdOrMeShopifyProductMatchPostResponse = routeInfo['POST /v1/sellers/{sellerIdOrMe}/shopify/productMatch']['responseCodec'];
export type SellersSellerIdOrMeShopifyProductMatchPostResponse = t.TypeOf<typeof SellersSellerIdOrMeShopifyProductMatchPostResponse>;
export const SellersSellerIdOrMeShopifyProductMatchPostInput = routeInfo['POST /v1/sellers/{sellerIdOrMe}/shopify/productMatch']['inputCodec'];
export type SellersSellerIdOrMeShopifyProductMatchPostInput = t.TypeOf<typeof SellersSellerIdOrMeShopifyProductMatchPostInput>;
export const SellersSellerIdOrMeUploadCertificationPostResponse = routeInfo['POST /v1/sellers/{sellerIdOrMe}/upload/certification']['responseCodec'];
export type SellersSellerIdOrMeUploadCertificationPostResponse = t.TypeOf<typeof SellersSellerIdOrMeUploadCertificationPostResponse>;
export const SellersSellerIdOrMeUploadCertificationPostInput = routeInfo['POST /v1/sellers/{sellerIdOrMe}/upload/certification']['inputCodec'];
export type SellersSellerIdOrMeUploadCertificationPostInput = t.TypeOf<typeof SellersSellerIdOrMeUploadCertificationPostInput>;
export const SellersSellerIdOrMeUploadCertificationSignedUrlPostResponse = routeInfo['POST /v1/sellers/{sellerIdOrMe}/upload/certificationSignedUrl']['responseCodec'];
export type SellersSellerIdOrMeUploadCertificationSignedUrlPostResponse = t.TypeOf<typeof SellersSellerIdOrMeUploadCertificationSignedUrlPostResponse>;
export const SellersSellerIdOrMeUploadCertificationSignedUrlPostInput = routeInfo['POST /v1/sellers/{sellerIdOrMe}/upload/certificationSignedUrl']['inputCodec'];
export type SellersSellerIdOrMeUploadCertificationSignedUrlPostInput = t.TypeOf<typeof SellersSellerIdOrMeUploadCertificationSignedUrlPostInput>;
export const SellersSellerIdOrMeUploadProductSubmissionPostResponse = routeInfo['POST /v1/sellers/{sellerIdOrMe}/upload/productSubmission']['responseCodec'];
export type SellersSellerIdOrMeUploadProductSubmissionPostResponse = t.TypeOf<typeof SellersSellerIdOrMeUploadProductSubmissionPostResponse>;
export const SellersSellerIdOrMeUploadProductSubmissionPostInput = routeInfo['POST /v1/sellers/{sellerIdOrMe}/upload/productSubmission']['inputCodec'];
export type SellersSellerIdOrMeUploadProductSubmissionPostInput = t.TypeOf<typeof SellersSellerIdOrMeUploadProductSubmissionPostInput>;
export const SellersSellerIdOrMeProductSubmissionProductSubmissionIdPatchResponse = routeInfo['PATCH /v1/sellers/{sellerIdOrMe}/productSubmission/{productSubmissionId}']['responseCodec'];
export type SellersSellerIdOrMeProductSubmissionProductSubmissionIdPatchResponse = t.TypeOf<typeof SellersSellerIdOrMeProductSubmissionProductSubmissionIdPatchResponse>;
export const SellersSellerIdOrMeProductSubmissionProductSubmissionIdPatchInput = routeInfo['PATCH /v1/sellers/{sellerIdOrMe}/productSubmission/{productSubmissionId}']['inputCodec'];
export type SellersSellerIdOrMeProductSubmissionProductSubmissionIdPatchInput = t.TypeOf<typeof SellersSellerIdOrMeProductSubmissionProductSubmissionIdPatchInput>;
export const SellersSellerIdOrMeProductSubmissionProductSubmissionIdDeleteResponse = routeInfo['DELETE /v1/sellers/{sellerIdOrMe}/productSubmission/{productSubmissionId}']['responseCodec'];
export type SellersSellerIdOrMeProductSubmissionProductSubmissionIdDeleteResponse = t.TypeOf<typeof SellersSellerIdOrMeProductSubmissionProductSubmissionIdDeleteResponse>;
export const SellersSellerIdOrMeProductSubmissionProductSubmissionIdDeleteInput = routeInfo['DELETE /v1/sellers/{sellerIdOrMe}/productSubmission/{productSubmissionId}']['inputCodec'];
export type SellersSellerIdOrMeProductSubmissionProductSubmissionIdDeleteInput = t.TypeOf<typeof SellersSellerIdOrMeProductSubmissionProductSubmissionIdDeleteInput>;
export const SellersSellerIdOrMeUploadProductSubmissionSignedUrlPostResponse = routeInfo['POST /v1/sellers/{sellerIdOrMe}/upload/productSubmissionSignedUrl']['responseCodec'];
export type SellersSellerIdOrMeUploadProductSubmissionSignedUrlPostResponse = t.TypeOf<typeof SellersSellerIdOrMeUploadProductSubmissionSignedUrlPostResponse>;
export const SellersSellerIdOrMeUploadProductSubmissionSignedUrlPostInput = routeInfo['POST /v1/sellers/{sellerIdOrMe}/upload/productSubmissionSignedUrl']['inputCodec'];
export type SellersSellerIdOrMeUploadProductSubmissionSignedUrlPostInput = t.TypeOf<typeof SellersSellerIdOrMeUploadProductSubmissionSignedUrlPostInput>;
export const SellersSellerIdOrMeSellerCertificationSellerCertificationIdDeleteResponse = routeInfo['DELETE /v1/sellers/{sellerIdOrMe}/sellerCertification/{sellerCertificationId}']['responseCodec'];
export type SellersSellerIdOrMeSellerCertificationSellerCertificationIdDeleteResponse = t.TypeOf<typeof SellersSellerIdOrMeSellerCertificationSellerCertificationIdDeleteResponse>;
export const SellersSellerIdOrMeSellerCertificationSellerCertificationIdDeleteInput = routeInfo['DELETE /v1/sellers/{sellerIdOrMe}/sellerCertification/{sellerCertificationId}']['inputCodec'];
export type SellersSellerIdOrMeSellerCertificationSellerCertificationIdDeleteInput = t.TypeOf<typeof SellersSellerIdOrMeSellerCertificationSellerCertificationIdDeleteInput>;
export const AdminEmailReviewGetResponse = routeInfo['GET /v1/admin/emailReview']['responseCodec'];
export type AdminEmailReviewGetResponse = t.TypeOf<typeof AdminEmailReviewGetResponse>;
export const AdminEmailReviewGetInput = routeInfo['GET /v1/admin/emailReview']['inputCodec'];
export type AdminEmailReviewGetInput = t.TypeOf<typeof AdminEmailReviewGetInput>;
export const AdminEmailReviewPatchResponse = routeInfo['PATCH /v1/admin/emailReview']['responseCodec'];
export type AdminEmailReviewPatchResponse = t.TypeOf<typeof AdminEmailReviewPatchResponse>;
export const AdminEmailReviewPatchInput = routeInfo['PATCH /v1/admin/emailReview']['inputCodec'];
export type AdminEmailReviewPatchInput = t.TypeOf<typeof AdminEmailReviewPatchInput>;
export const AdminCategoriesRepopulateNewCategoriesPatchResponse = routeInfo['PATCH /v1/admin/categories/repopulateNewCategories']['responseCodec'];
export type AdminCategoriesRepopulateNewCategoriesPatchResponse = t.TypeOf<typeof AdminCategoriesRepopulateNewCategoriesPatchResponse>;
export const AdminCategoriesRepopulateNewCategoriesPatchInput = routeInfo['PATCH /v1/admin/categories/repopulateNewCategories']['inputCodec'];
export type AdminCategoriesRepopulateNewCategoriesPatchInput = t.TypeOf<typeof AdminCategoriesRepopulateNewCategoriesPatchInput>;
export const MessagingChannelsGetResponse = routeInfo['GET /v1/messaging/channels']['responseCodec'];
export type MessagingChannelsGetResponse = t.TypeOf<typeof MessagingChannelsGetResponse>;
export const MessagingChannelsGetInput = routeInfo['GET /v1/messaging/channels']['inputCodec'];
export type MessagingChannelsGetInput = t.TypeOf<typeof MessagingChannelsGetInput>;
export const MessagingChannelsPostResponse = routeInfo['POST /v1/messaging/channels']['responseCodec'];
export type MessagingChannelsPostResponse = t.TypeOf<typeof MessagingChannelsPostResponse>;
export const MessagingChannelsPostInput = routeInfo['POST /v1/messaging/channels']['inputCodec'];
export type MessagingChannelsPostInput = t.TypeOf<typeof MessagingChannelsPostInput>;
export const MessagingChannelsSendbirdChannelUrlGetResponse = routeInfo['GET /v1/messaging/channels/{sendbirdChannelUrl}']['responseCodec'];
export type MessagingChannelsSendbirdChannelUrlGetResponse = t.TypeOf<typeof MessagingChannelsSendbirdChannelUrlGetResponse>;
export const MessagingChannelsSendbirdChannelUrlGetInput = routeInfo['GET /v1/messaging/channels/{sendbirdChannelUrl}']['inputCodec'];
export type MessagingChannelsSendbirdChannelUrlGetInput = t.TypeOf<typeof MessagingChannelsSendbirdChannelUrlGetInput>;
export const MessagingChannelsSendbirdChannelUrlJoinPutResponse = routeInfo['PUT /v1/messaging/channels/{sendbirdChannelUrl}/join']['responseCodec'];
export type MessagingChannelsSendbirdChannelUrlJoinPutResponse = t.TypeOf<typeof MessagingChannelsSendbirdChannelUrlJoinPutResponse>;
export const MessagingChannelsSendbirdChannelUrlJoinPutInput = routeInfo['PUT /v1/messaging/channels/{sendbirdChannelUrl}/join']['inputCodec'];
export type MessagingChannelsSendbirdChannelUrlJoinPutInput = t.TypeOf<typeof MessagingChannelsSendbirdChannelUrlJoinPutInput>;
export const MessagingChannelsSendbirdChannelUrlLeavePutResponse = routeInfo['PUT /v1/messaging/channels/{sendbirdChannelUrl}/leave']['responseCodec'];
export type MessagingChannelsSendbirdChannelUrlLeavePutResponse = t.TypeOf<typeof MessagingChannelsSendbirdChannelUrlLeavePutResponse>;
export const MessagingChannelsSendbirdChannelUrlLeavePutInput = routeInfo['PUT /v1/messaging/channels/{sendbirdChannelUrl}/leave']['inputCodec'];
export type MessagingChannelsSendbirdChannelUrlLeavePutInput = t.TypeOf<typeof MessagingChannelsSendbirdChannelUrlLeavePutInput>;
export const MessagingChannelsSendbirdChannelUrlRemindPostResponse = routeInfo['POST /v1/messaging/channels/{sendbirdChannelUrl}/remind']['responseCodec'];
export type MessagingChannelsSendbirdChannelUrlRemindPostResponse = t.TypeOf<typeof MessagingChannelsSendbirdChannelUrlRemindPostResponse>;
export const MessagingChannelsSendbirdChannelUrlRemindPostInput = routeInfo['POST /v1/messaging/channels/{sendbirdChannelUrl}/remind']['inputCodec'];
export type MessagingChannelsSendbirdChannelUrlRemindPostInput = t.TypeOf<typeof MessagingChannelsSendbirdChannelUrlRemindPostInput>;
export const MessagingUsersMeTokenPostResponse = routeInfo['POST /v1/messaging/users/@me/token']['responseCodec'];
export type MessagingUsersMeTokenPostResponse = t.TypeOf<typeof MessagingUsersMeTokenPostResponse>;
export const MessagingUsersMeTokenPostInput = routeInfo['POST /v1/messaging/users/@me/token']['inputCodec'];
export type MessagingUsersMeTokenPostInput = t.TypeOf<typeof MessagingUsersMeTokenPostInput>;
export const MessagingUsersMeUnreadMessagesCountGetResponse = routeInfo['GET /v1/messaging/users/@me/unreadMessagesCount']['responseCodec'];
export type MessagingUsersMeUnreadMessagesCountGetResponse = t.TypeOf<typeof MessagingUsersMeUnreadMessagesCountGetResponse>;
export const MessagingUsersMeUnreadMessagesCountGetInput = routeInfo['GET /v1/messaging/users/@me/unreadMessagesCount']['inputCodec'];
export type MessagingUsersMeUnreadMessagesCountGetInput = t.TypeOf<typeof MessagingUsersMeUnreadMessagesCountGetInput>;
export const AdminMessageChannelPostResponse = routeInfo['POST /v1/admin/messageChannel']['responseCodec'];
export type AdminMessageChannelPostResponse = t.TypeOf<typeof AdminMessageChannelPostResponse>;
export const AdminMessageChannelPostInput = routeInfo['POST /v1/admin/messageChannel']['inputCodec'];
export type AdminMessageChannelPostInput = t.TypeOf<typeof AdminMessageChannelPostInput>;
export const AdminMessageChannelsSellerIdBackfillPostResponse = routeInfo['POST /v1/admin/messageChannels/{sellerId}/backfill']['responseCodec'];
export type AdminMessageChannelsSellerIdBackfillPostResponse = t.TypeOf<typeof AdminMessageChannelsSellerIdBackfillPostResponse>;
export const AdminMessageChannelsSellerIdBackfillPostInput = routeInfo['POST /v1/admin/messageChannels/{sellerId}/backfill']['inputCodec'];
export type AdminMessageChannelsSellerIdBackfillPostInput = t.TypeOf<typeof AdminMessageChannelsSellerIdBackfillPostInput>;
export const AdminMessageChannelsSendbirdChannelUrlGetResponse = routeInfo['GET /v1/admin/messageChannels/{sendbirdChannelUrl}']['responseCodec'];
export type AdminMessageChannelsSendbirdChannelUrlGetResponse = t.TypeOf<typeof AdminMessageChannelsSendbirdChannelUrlGetResponse>;
export const AdminMessageChannelsSendbirdChannelUrlGetInput = routeInfo['GET /v1/admin/messageChannels/{sendbirdChannelUrl}']['inputCodec'];
export type AdminMessageChannelsSendbirdChannelUrlGetInput = t.TypeOf<typeof AdminMessageChannelsSendbirdChannelUrlGetInput>;
export const AdminMessagingChannelsSendbirdChannelUrlLeavePutResponse = routeInfo['PUT /v1/admin/messaging/channels/{sendbirdChannelUrl}/leave']['responseCodec'];
export type AdminMessagingChannelsSendbirdChannelUrlLeavePutResponse = t.TypeOf<typeof AdminMessagingChannelsSendbirdChannelUrlLeavePutResponse>;
export const AdminMessagingChannelsSendbirdChannelUrlLeavePutInput = routeInfo['PUT /v1/admin/messaging/channels/{sendbirdChannelUrl}/leave']['inputCodec'];
export type AdminMessagingChannelsSendbirdChannelUrlLeavePutInput = t.TypeOf<typeof AdminMessagingChannelsSendbirdChannelUrlLeavePutInput>;
export const BuyersMeVerificationGetResponse = routeInfo['GET /v1/buyers/@me/verification']['responseCodec'];
export type BuyersMeVerificationGetResponse = t.TypeOf<typeof BuyersMeVerificationGetResponse>;
export const BuyersMeVerificationGetInput = routeInfo['GET /v1/buyers/@me/verification']['inputCodec'];
export type BuyersMeVerificationGetInput = t.TypeOf<typeof BuyersMeVerificationGetInput>;
export const BuyersMeVerificationPutResponse = routeInfo['PUT /v1/buyers/@me/verification']['responseCodec'];
export type BuyersMeVerificationPutResponse = t.TypeOf<typeof BuyersMeVerificationPutResponse>;
export const BuyersMeVerificationPutInput = routeInfo['PUT /v1/buyers/@me/verification']['inputCodec'];
export type BuyersMeVerificationPutInput = t.TypeOf<typeof BuyersMeVerificationPutInput>;
export const AdminBuyersBuyerIdVerificationGetResponse = routeInfo['GET /v1/admin/buyers/{buyerId}/verification']['responseCodec'];
export type AdminBuyersBuyerIdVerificationGetResponse = t.TypeOf<typeof AdminBuyersBuyerIdVerificationGetResponse>;
export const AdminBuyersBuyerIdVerificationGetInput = routeInfo['GET /v1/admin/buyers/{buyerId}/verification']['inputCodec'];
export type AdminBuyersBuyerIdVerificationGetInput = t.TypeOf<typeof AdminBuyersBuyerIdVerificationGetInput>;
export const AdminManualTasksGetResponse = routeInfo['GET /v1/admin/manualTasks']['responseCodec'];
export type AdminManualTasksGetResponse = t.TypeOf<typeof AdminManualTasksGetResponse>;
export const AdminManualTasksGetInput = routeInfo['GET /v1/admin/manualTasks']['inputCodec'];
export type AdminManualTasksGetInput = t.TypeOf<typeof AdminManualTasksGetInput>;
export const AdminManualTasksSummaryGetResponse = routeInfo['GET /v1/admin/manualTasks/summary']['responseCodec'];
export type AdminManualTasksSummaryGetResponse = t.TypeOf<typeof AdminManualTasksSummaryGetResponse>;
export const AdminManualTasksSummaryGetInput = routeInfo['GET /v1/admin/manualTasks/summary']['inputCodec'];
export type AdminManualTasksSummaryGetInput = t.TypeOf<typeof AdminManualTasksSummaryGetInput>;
export const AdminManualTasksManualTaskIdGetResponse = routeInfo['GET /v1/admin/manualTasks/{manualTaskId}']['responseCodec'];
export type AdminManualTasksManualTaskIdGetResponse = t.TypeOf<typeof AdminManualTasksManualTaskIdGetResponse>;
export const AdminManualTasksManualTaskIdGetInput = routeInfo['GET /v1/admin/manualTasks/{manualTaskId}']['inputCodec'];
export type AdminManualTasksManualTaskIdGetInput = t.TypeOf<typeof AdminManualTasksManualTaskIdGetInput>;
export const AdminManualTasksManualTaskIdDeleteResponse = routeInfo['DELETE /v1/admin/manualTasks/{manualTaskId}']['responseCodec'];
export type AdminManualTasksManualTaskIdDeleteResponse = t.TypeOf<typeof AdminManualTasksManualTaskIdDeleteResponse>;
export const AdminManualTasksManualTaskIdDeleteInput = routeInfo['DELETE /v1/admin/manualTasks/{manualTaskId}']['inputCodec'];
export type AdminManualTasksManualTaskIdDeleteInput = t.TypeOf<typeof AdminManualTasksManualTaskIdDeleteInput>;
export const AdminManualTasksManualTaskIdClaimPostResponse = routeInfo['POST /v1/admin/manualTasks/{manualTaskId}/claim']['responseCodec'];
export type AdminManualTasksManualTaskIdClaimPostResponse = t.TypeOf<typeof AdminManualTasksManualTaskIdClaimPostResponse>;
export const AdminManualTasksManualTaskIdClaimPostInput = routeInfo['POST /v1/admin/manualTasks/{manualTaskId}/claim']['inputCodec'];
export type AdminManualTasksManualTaskIdClaimPostInput = t.TypeOf<typeof AdminManualTasksManualTaskIdClaimPostInput>;
export const AdminManualTasksManualTaskIdUnclaimPostResponse = routeInfo['POST /v1/admin/manualTasks/{manualTaskId}/unclaim']['responseCodec'];
export type AdminManualTasksManualTaskIdUnclaimPostResponse = t.TypeOf<typeof AdminManualTasksManualTaskIdUnclaimPostResponse>;
export const AdminManualTasksManualTaskIdUnclaimPostInput = routeInfo['POST /v1/admin/manualTasks/{manualTaskId}/unclaim']['inputCodec'];
export type AdminManualTasksManualTaskIdUnclaimPostInput = t.TypeOf<typeof AdminManualTasksManualTaskIdUnclaimPostInput>;
export const AdminManualTasksManualTaskIdCompletePostResponse = routeInfo['POST /v1/admin/manualTasks/{manualTaskId}/complete']['responseCodec'];
export type AdminManualTasksManualTaskIdCompletePostResponse = t.TypeOf<typeof AdminManualTasksManualTaskIdCompletePostResponse>;
export const AdminManualTasksManualTaskIdCompletePostInput = routeInfo['POST /v1/admin/manualTasks/{manualTaskId}/complete']['inputCodec'];
export type AdminManualTasksManualTaskIdCompletePostInput = t.TypeOf<typeof AdminManualTasksManualTaskIdCompletePostInput>;
export const SupportOrderIssueFormUrlGetResponse = routeInfo['GET /v1/support/orderIssueFormUrl']['responseCodec'];
export type SupportOrderIssueFormUrlGetResponse = t.TypeOf<typeof SupportOrderIssueFormUrlGetResponse>;
export const SupportOrderIssueFormUrlGetInput = routeInfo['GET /v1/support/orderIssueFormUrl']['inputCodec'];
export type SupportOrderIssueFormUrlGetInput = t.TypeOf<typeof SupportOrderIssueFormUrlGetInput>;
export const SupportMessagingAssistanceFormUrlGetResponse = routeInfo['GET /v1/support/messagingAssistanceFormUrl']['responseCodec'];
export type SupportMessagingAssistanceFormUrlGetResponse = t.TypeOf<typeof SupportMessagingAssistanceFormUrlGetResponse>;
export const SupportMessagingAssistanceFormUrlGetInput = routeInfo['GET /v1/support/messagingAssistanceFormUrl']['inputCodec'];
export type SupportMessagingAssistanceFormUrlGetInput = t.TypeOf<typeof SupportMessagingAssistanceFormUrlGetInput>;
export const AdminSearchTermsPostResponse = routeInfo['POST /v1/admin/search/terms']['responseCodec'];
export type AdminSearchTermsPostResponse = t.TypeOf<typeof AdminSearchTermsPostResponse>;
export const AdminSearchTermsPostInput = routeInfo['POST /v1/admin/search/terms']['inputCodec'];
export type AdminSearchTermsPostInput = t.TypeOf<typeof AdminSearchTermsPostInput>;
export const AdminLandedCostCalculatorPostResponse = routeInfo['POST /v1/admin/landedCostCalculator']['responseCodec'];
export type AdminLandedCostCalculatorPostResponse = t.TypeOf<typeof AdminLandedCostCalculatorPostResponse>;
export const AdminLandedCostCalculatorPostInput = routeInfo['POST /v1/admin/landedCostCalculator']['inputCodec'];
export type AdminLandedCostCalculatorPostInput = t.TypeOf<typeof AdminLandedCostCalculatorPostInput>;
export const AdminPartnerPricingRegionsGetResponse = routeInfo['GET /v1/admin/partnerPricingRegions']['responseCodec'];
export type AdminPartnerPricingRegionsGetResponse = t.TypeOf<typeof AdminPartnerPricingRegionsGetResponse>;
export const AdminPartnerPricingRegionsGetInput = routeInfo['GET /v1/admin/partnerPricingRegions']['inputCodec'];
export type AdminPartnerPricingRegionsGetInput = t.TypeOf<typeof AdminPartnerPricingRegionsGetInput>;
export const AdminLambdaInvokePostResponse = routeInfo['POST /v1/admin/lambda/invoke']['responseCodec'];
export type AdminLambdaInvokePostResponse = t.TypeOf<typeof AdminLambdaInvokePostResponse>;
export const AdminLambdaInvokePostInput = routeInfo['POST /v1/admin/lambda/invoke']['inputCodec'];
export type AdminLambdaInvokePostInput = t.TypeOf<typeof AdminLambdaInvokePostInput>;
export const AdminLambdaCallbackPostResponse = routeInfo['POST /v1/admin/lambda/callback']['responseCodec'];
export type AdminLambdaCallbackPostResponse = t.TypeOf<typeof AdminLambdaCallbackPostResponse>;
export const AdminLambdaCallbackPostInput = routeInfo['POST /v1/admin/lambda/callback']['inputCodec'];
export type AdminLambdaCallbackPostInput = t.TypeOf<typeof AdminLambdaCallbackPostInput>;
export const AdminNacsAutoCategoriztionGeneratePostResponse = routeInfo['POST /v1/admin/nacsAutoCategoriztion/generate']['responseCodec'];
export type AdminNacsAutoCategoriztionGeneratePostResponse = t.TypeOf<typeof AdminNacsAutoCategoriztionGeneratePostResponse>;
export const AdminNacsAutoCategoriztionGeneratePostInput = routeInfo['POST /v1/admin/nacsAutoCategoriztion/generate']['inputCodec'];
export type AdminNacsAutoCategoriztionGeneratePostInput = t.TypeOf<typeof AdminNacsAutoCategoriztionGeneratePostInput>;
export const AdminNacsAutoCategoriztionImportPatchResponse = routeInfo['PATCH /v1/admin/nacsAutoCategoriztion/import']['responseCodec'];
export type AdminNacsAutoCategoriztionImportPatchResponse = t.TypeOf<typeof AdminNacsAutoCategoriztionImportPatchResponse>;
export const AdminNacsAutoCategoriztionImportPatchInput = routeInfo['PATCH /v1/admin/nacsAutoCategoriztion/import']['inputCodec'];
export type AdminNacsAutoCategoriztionImportPatchInput = t.TypeOf<typeof AdminNacsAutoCategoriztionImportPatchInput>;
export const AdminEdiFilesEdiFileIdFunctionalAckPostResponse = routeInfo['POST /v1/admin/ediFiles/{ediFileId}/functionalAck']['responseCodec'];
export type AdminEdiFilesEdiFileIdFunctionalAckPostResponse = t.TypeOf<typeof AdminEdiFilesEdiFileIdFunctionalAckPostResponse>;
export const AdminEdiFilesEdiFileIdFunctionalAckPostInput = routeInfo['POST /v1/admin/ediFiles/{ediFileId}/functionalAck']['inputCodec'];
export type AdminEdiFilesEdiFileIdFunctionalAckPostInput = t.TypeOf<typeof AdminEdiFilesEdiFileIdFunctionalAckPostInput>;
export const AdminEdiFilesEdiFileIdFunctionalAckSendPostResponse = routeInfo['POST /v1/admin/ediFiles/{ediFileId}/functionalAck/send']['responseCodec'];
export type AdminEdiFilesEdiFileIdFunctionalAckSendPostResponse = t.TypeOf<typeof AdminEdiFilesEdiFileIdFunctionalAckSendPostResponse>;
export const AdminEdiFilesEdiFileIdFunctionalAckSendPostInput = routeInfo['POST /v1/admin/ediFiles/{ediFileId}/functionalAck/send']['inputCodec'];
export type AdminEdiFilesEdiFileIdFunctionalAckSendPostInput = t.TypeOf<typeof AdminEdiFilesEdiFileIdFunctionalAckSendPostInput>;
export const AdminEdiFilesSearchPostResponse = routeInfo['POST /v1/admin/ediFiles/search']['responseCodec'];
export type AdminEdiFilesSearchPostResponse = t.TypeOf<typeof AdminEdiFilesSearchPostResponse>;
export const AdminEdiFilesSearchPostInput = routeInfo['POST /v1/admin/ediFiles/search']['inputCodec'];
export type AdminEdiFilesSearchPostInput = t.TypeOf<typeof AdminEdiFilesSearchPostInput>;
export const AdminEdiFilesEdiFileIdReprocessPostResponse = routeInfo['POST /v1/admin/ediFiles/{ediFileId}/reprocess']['responseCodec'];
export type AdminEdiFilesEdiFileIdReprocessPostResponse = t.TypeOf<typeof AdminEdiFilesEdiFileIdReprocessPostResponse>;
export const AdminEdiFilesEdiFileIdReprocessPostInput = routeInfo['POST /v1/admin/ediFiles/{ediFileId}/reprocess']['inputCodec'];
export type AdminEdiFilesEdiFileIdReprocessPostInput = t.TypeOf<typeof AdminEdiFilesEdiFileIdReprocessPostInput>;
export const AdminSellersSellerIdPartnerPartnerNamePriceReviewGetResponse = routeInfo['GET /v1/admin/sellers/{sellerId}/partner/{partnerName}/priceReview']['responseCodec'];
export type AdminSellersSellerIdPartnerPartnerNamePriceReviewGetResponse = t.TypeOf<typeof AdminSellersSellerIdPartnerPartnerNamePriceReviewGetResponse>;
export const AdminSellersSellerIdPartnerPartnerNamePriceReviewGetInput = routeInfo['GET /v1/admin/sellers/{sellerId}/partner/{partnerName}/priceReview']['inputCodec'];
export type AdminSellersSellerIdPartnerPartnerNamePriceReviewGetInput = t.TypeOf<typeof AdminSellersSellerIdPartnerPartnerNamePriceReviewGetInput>;
export const AdminSellersSellerIdPartnerPartnerNamePriceReviewPutResponse = routeInfo['PUT /v1/admin/sellers/{sellerId}/partner/{partnerName}/priceReview']['responseCodec'];
export type AdminSellersSellerIdPartnerPartnerNamePriceReviewPutResponse = t.TypeOf<typeof AdminSellersSellerIdPartnerPartnerNamePriceReviewPutResponse>;
export const AdminSellersSellerIdPartnerPartnerNamePriceReviewPutInput = routeInfo['PUT /v1/admin/sellers/{sellerId}/partner/{partnerName}/priceReview']['inputCodec'];
export type AdminSellersSellerIdPartnerPartnerNamePriceReviewPutInput = t.TypeOf<typeof AdminSellersSellerIdPartnerPartnerNamePriceReviewPutInput>;
export const AdminSellersSellerIdAccountSetupEmailCampaignPostResponse = routeInfo['POST /v1/admin/sellers/{sellerId}/accountSetupEmailCampaign']['responseCodec'];
export type AdminSellersSellerIdAccountSetupEmailCampaignPostResponse = t.TypeOf<typeof AdminSellersSellerIdAccountSetupEmailCampaignPostResponse>;
export const AdminSellersSellerIdAccountSetupEmailCampaignPostInput = routeInfo['POST /v1/admin/sellers/{sellerId}/accountSetupEmailCampaign']['inputCodec'];
export type AdminSellersSellerIdAccountSetupEmailCampaignPostInput = t.TypeOf<typeof AdminSellersSellerIdAccountSetupEmailCampaignPostInput>;
export const AdminExternalBuyerLocationOperationsGetResponse = routeInfo['GET /v1/admin/externalBuyerLocationOperations']['responseCodec'];
export type AdminExternalBuyerLocationOperationsGetResponse = t.TypeOf<typeof AdminExternalBuyerLocationOperationsGetResponse>;
export const AdminExternalBuyerLocationOperationsGetInput = routeInfo['GET /v1/admin/externalBuyerLocationOperations']['inputCodec'];
export type AdminExternalBuyerLocationOperationsGetInput = t.TypeOf<typeof AdminExternalBuyerLocationOperationsGetInput>;
export const AdminExternalBuyerLocationOperationsPostResponse = routeInfo['POST /v1/admin/externalBuyerLocationOperations']['responseCodec'];
export type AdminExternalBuyerLocationOperationsPostResponse = t.TypeOf<typeof AdminExternalBuyerLocationOperationsPostResponse>;
export const AdminExternalBuyerLocationOperationsPostInput = routeInfo['POST /v1/admin/externalBuyerLocationOperations']['inputCodec'];
export type AdminExternalBuyerLocationOperationsPostInput = t.TypeOf<typeof AdminExternalBuyerLocationOperationsPostInput>;
export const AdminUsfoodsProductDataPostResponse = routeInfo['POST /v1/admin/usfoods/productData']['responseCodec'];
export type AdminUsfoodsProductDataPostResponse = t.TypeOf<typeof AdminUsfoodsProductDataPostResponse>;
export const AdminUsfoodsProductDataPostInput = routeInfo['POST /v1/admin/usfoods/productData']['inputCodec'];
export type AdminUsfoodsProductDataPostInput = t.TypeOf<typeof AdminUsfoodsProductDataPostInput>;
export const AdminUsfoodsPricingReportsPricingReportIdImageArchivePlanGetResponse = routeInfo['GET /v1/admin/usfoods/pricingReports/{pricingReportId}/imageArchive/plan']['responseCodec'];
export type AdminUsfoodsPricingReportsPricingReportIdImageArchivePlanGetResponse = t.TypeOf<typeof AdminUsfoodsPricingReportsPricingReportIdImageArchivePlanGetResponse>;
export const AdminUsfoodsPricingReportsPricingReportIdImageArchivePlanGetInput = routeInfo['GET /v1/admin/usfoods/pricingReports/{pricingReportId}/imageArchive/plan']['inputCodec'];
export type AdminUsfoodsPricingReportsPricingReportIdImageArchivePlanGetInput = t.TypeOf<typeof AdminUsfoodsPricingReportsPricingReportIdImageArchivePlanGetInput>;
export const AdminProductVariantIdentifiersPostResponse = routeInfo['POST /v1/admin/productVariantIdentifiers']['responseCodec'];
export type AdminProductVariantIdentifiersPostResponse = t.TypeOf<typeof AdminProductVariantIdentifiersPostResponse>;
export const AdminProductVariantIdentifiersPostInput = routeInfo['POST /v1/admin/productVariantIdentifiers']['inputCodec'];
export type AdminProductVariantIdentifiersPostInput = t.TypeOf<typeof AdminProductVariantIdentifiersPostInput>;
export const DataStandardPropertiesGetResponse = routeInfo['GET /v1/dataStandardProperties']['responseCodec'];
export type DataStandardPropertiesGetResponse = t.TypeOf<typeof DataStandardPropertiesGetResponse>;
export const DataStandardPropertiesGetInput = routeInfo['GET /v1/dataStandardProperties']['inputCodec'];
export type DataStandardPropertiesGetInput = t.TypeOf<typeof DataStandardPropertiesGetInput>;
export const DataStandardPropertyValuesGetResponse = routeInfo['GET /v1/dataStandardPropertyValues']['responseCodec'];
export type DataStandardPropertyValuesGetResponse = t.TypeOf<typeof DataStandardPropertyValuesGetResponse>;
export const DataStandardPropertyValuesGetInput = routeInfo['GET /v1/dataStandardPropertyValues']['inputCodec'];
export type DataStandardPropertyValuesGetInput = t.TypeOf<typeof DataStandardPropertyValuesGetInput>;
export const DataStandardPropertyValuesPostResponse = routeInfo['POST /v1/dataStandardPropertyValues']['responseCodec'];
export type DataStandardPropertyValuesPostResponse = t.TypeOf<typeof DataStandardPropertyValuesPostResponse>;
export const DataStandardPropertyValuesPostInput = routeInfo['POST /v1/dataStandardPropertyValues']['inputCodec'];
export type DataStandardPropertyValuesPostInput = t.TypeOf<typeof DataStandardPropertyValuesPostInput>;
export const AdminAiPromptsSchedulePostResponse = routeInfo['POST /v1/admin/aiPrompts/schedule']['responseCodec'];
export type AdminAiPromptsSchedulePostResponse = t.TypeOf<typeof AdminAiPromptsSchedulePostResponse>;
export const AdminAiPromptsSchedulePostInput = routeInfo['POST /v1/admin/aiPrompts/schedule']['inputCodec'];
export type AdminAiPromptsSchedulePostInput = t.TypeOf<typeof AdminAiPromptsSchedulePostInput>;
export const SellersSellerIdOrMeUploadMediaSignedUrlPostResponse = routeInfo['POST /v1/sellers/{sellerIdOrMe}/upload/mediaSignedUrl']['responseCodec'];
export type SellersSellerIdOrMeUploadMediaSignedUrlPostResponse = t.TypeOf<typeof SellersSellerIdOrMeUploadMediaSignedUrlPostResponse>;
export const SellersSellerIdOrMeUploadMediaSignedUrlPostInput = routeInfo['POST /v1/sellers/{sellerIdOrMe}/upload/mediaSignedUrl']['inputCodec'];
export type SellersSellerIdOrMeUploadMediaSignedUrlPostInput = t.TypeOf<typeof SellersSellerIdOrMeUploadMediaSignedUrlPostInput>;
export const SellersSellerIdOrMeMediaPostResponse = routeInfo['POST /v1/sellers/{sellerIdOrMe}/media']['responseCodec'];
export type SellersSellerIdOrMeMediaPostResponse = t.TypeOf<typeof SellersSellerIdOrMeMediaPostResponse>;
export const SellersSellerIdOrMeMediaPostInput = routeInfo['POST /v1/sellers/{sellerIdOrMe}/media']['inputCodec'];
export type SellersSellerIdOrMeMediaPostInput = t.TypeOf<typeof SellersSellerIdOrMeMediaPostInput>;
export const SellersSellerIdOrMeMediaMediaIdPatchResponse = routeInfo['PATCH /v1/sellers/{sellerIdOrMe}/media/{mediaId}']['responseCodec'];
export type SellersSellerIdOrMeMediaMediaIdPatchResponse = t.TypeOf<typeof SellersSellerIdOrMeMediaMediaIdPatchResponse>;
export const SellersSellerIdOrMeMediaMediaIdPatchInput = routeInfo['PATCH /v1/sellers/{sellerIdOrMe}/media/{mediaId}']['inputCodec'];
export type SellersSellerIdOrMeMediaMediaIdPatchInput = t.TypeOf<typeof SellersSellerIdOrMeMediaMediaIdPatchInput>;
export const SellersSellerIdOrMeMediaMediaIdDeleteResponse = routeInfo['DELETE /v1/sellers/{sellerIdOrMe}/media/{mediaId}']['responseCodec'];
export type SellersSellerIdOrMeMediaMediaIdDeleteResponse = t.TypeOf<typeof SellersSellerIdOrMeMediaMediaIdDeleteResponse>;
export const SellersSellerIdOrMeMediaMediaIdDeleteInput = routeInfo['DELETE /v1/sellers/{sellerIdOrMe}/media/{mediaId}']['inputCodec'];
export type SellersSellerIdOrMeMediaMediaIdDeleteInput = t.TypeOf<typeof SellersSellerIdOrMeMediaMediaIdDeleteInput>;
export const SellersSellerIdMediaMediaIdSignedDownloadUrlPostResponse = routeInfo['POST /v1/sellers/{sellerId}/media/{mediaId}/signedDownloadUrl']['responseCodec'];
export type SellersSellerIdMediaMediaIdSignedDownloadUrlPostResponse = t.TypeOf<typeof SellersSellerIdMediaMediaIdSignedDownloadUrlPostResponse>;
export const SellersSellerIdMediaMediaIdSignedDownloadUrlPostInput = routeInfo['POST /v1/sellers/{sellerId}/media/{mediaId}/signedDownloadUrl']['inputCodec'];
export type SellersSellerIdMediaMediaIdSignedDownloadUrlPostInput = t.TypeOf<typeof SellersSellerIdMediaMediaIdSignedDownloadUrlPostInput>;
export const PlanogramsPlanogramIdGetResponse = routeInfo['GET /v1/planograms/{planogramId}']['responseCodec'];
export type PlanogramsPlanogramIdGetResponse = t.TypeOf<typeof PlanogramsPlanogramIdGetResponse>;
export const PlanogramsPlanogramIdGetInput = routeInfo['GET /v1/planograms/{planogramId}']['inputCodec'];
export type PlanogramsPlanogramIdGetInput = t.TypeOf<typeof PlanogramsPlanogramIdGetInput>;
export const AdminPlanogramsPostResponse = routeInfo['POST /v1/admin/planograms']['responseCodec'];
export type AdminPlanogramsPostResponse = t.TypeOf<typeof AdminPlanogramsPostResponse>;
export const AdminPlanogramsPostInput = routeInfo['POST /v1/admin/planograms']['inputCodec'];
export type AdminPlanogramsPostInput = t.TypeOf<typeof AdminPlanogramsPostInput>;
export const AdminPlanogramsPlanogramIdGetResponse = routeInfo['GET /v1/admin/planograms/{planogramId}']['responseCodec'];
export type AdminPlanogramsPlanogramIdGetResponse = t.TypeOf<typeof AdminPlanogramsPlanogramIdGetResponse>;
export const AdminPlanogramsPlanogramIdGetInput = routeInfo['GET /v1/admin/planograms/{planogramId}']['inputCodec'];
export type AdminPlanogramsPlanogramIdGetInput = t.TypeOf<typeof AdminPlanogramsPlanogramIdGetInput>;
export const AdminPlanogramsPlanogramIdPatchResponse = routeInfo['PATCH /v1/admin/planograms/{planogramId}']['responseCodec'];
export type AdminPlanogramsPlanogramIdPatchResponse = t.TypeOf<typeof AdminPlanogramsPlanogramIdPatchResponse>;
export const AdminPlanogramsPlanogramIdPatchInput = routeInfo['PATCH /v1/admin/planograms/{planogramId}']['inputCodec'];
export type AdminPlanogramsPlanogramIdPatchInput = t.TypeOf<typeof AdminPlanogramsPlanogramIdPatchInput>;
export const AdminPlanogramsPlanogramIdDeleteResponse = routeInfo['DELETE /v1/admin/planograms/{planogramId}']['responseCodec'];
export type AdminPlanogramsPlanogramIdDeleteResponse = t.TypeOf<typeof AdminPlanogramsPlanogramIdDeleteResponse>;
export const AdminPlanogramsPlanogramIdDeleteInput = routeInfo['DELETE /v1/admin/planograms/{planogramId}']['inputCodec'];
export type AdminPlanogramsPlanogramIdDeleteInput = t.TypeOf<typeof AdminPlanogramsPlanogramIdDeleteInput>;

export class ApiClient extends BaseApiClient {
  public async req<RouteName extends keyof typeof routeInfo>(
    routeName: RouteName,
    input: t.TypeOf<typeof routeInfo[RouteName]['inputCodec']>,
    opts?: SpeccedReqOptions,
  ): Promise<ApiResponse<t.TypeOf<typeof routeInfo[RouteName]['responseCodec']>>>;

  public async req<RouteName extends keyof typeof routeInfo>(
    routeName: RouteName,
    input: t.TypeOf<typeof routeInfo[RouteName]['inputCodec']>,
    opts?: SpeccedReqOptions,
  ): Promise<ApiResponse<t.TypeOf<typeof routeInfo[RouteName]['responseCodec']>>> {
    const { method, path, accept, inputLocations, inputCodec, responseCodec, responseCodecs } = routeInfo[routeName];
    // This doesn't typecheck without the 'as any' due to some confusing generics stuff that I don't understand.
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return super.speccedReq(method, path, accept, inputLocations, inputCodec as any, responseCodec as any, input, { ...opts, responseCodecs } as any);
  }

  public async unspeccedReq(
    method: string,
    path: string,
    opts?: UnspeccedReqOptions,
  ) {
    return super.unspeccedReq(method, path, opts);
  }
}